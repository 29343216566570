import * as React from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DropzoneComponent from "./Dropzone";
import AddRowStudent from "./AddRowStudent";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import componentStyles from "assets/theme/components/dialog.js";
// import useStore from "../../../../../../zustand";
import { useQuery } from "react-query";
import useUserApi from "api/user";
import useStore from "../../../../../../zustand";

const useStyles = makeStyles(componentStyles);

function AddStudentForm({
  handleClose,
  open,
  isSuccess,
  setOpenSnackbar,
  isLoading,
  mutate,
  isError,
  error,
  tenantIdSchool,
}) {
  const classes = { ...useStyles() };
  // const tenantId = useStore((state) => state.tenantId);
  const { fetchClasses } = useUserApi();
  const { tenantId } = useStore((state) => state);

  const { data: classesList, status: classesListStatus } = useQuery("classes", fetchClasses, {
    refetchOnWindowFocus: false,
  });

  const [studentList, setStudentList] = React.useState([
    {
      valid: false,
      class: null,
      first_name: "",
      last_name: "",
      email: "",
      school: tenantId,
    },
  ]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="lg">
      <div className={classes.dialogHeader}>
        <DialogTitle style={{ fontSize: "17px", paddingLeft: "12px" }}>
          Add Student / Student List
        </DialogTitle>
        <IconButton onClick={() => (handleClose(), setStudentList([]))}>
          <Clear style={{ height: 30, width: 30 }} />
        </IconButton>
      </div>
      <div style={{ marginTop: "30px" }}>
        <div style={{ padding: "0px 30px" }}>
          <a href={`${process.env.PUBLIC_URL}/EAI_Student_Upload_Template.csv`} download>
            Download sample import file to see the correct file format
          </a>
        </div>
        <DropzoneComponent list={studentList} setList={setStudentList} />
      </div>
      <List sx={{ pt: 0 }}>
        <AddRowStudent
          list={studentList}
          setList={setStudentList}
          classesList={classesList}
          classesListStatus={classesListStatus}
          setOpenSnackbarX={setOpenSnackbar}
          isLoading={isLoading}
          mutate={mutate}
          isError={isError}
          error={error}
          isSuccess={isSuccess}
          handleClose={handleClose}
          tenantIdSchool={tenantIdSchool}
        />
      </List>
    </Dialog>
  );
}

AddStudentForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default AddStudentForm;
