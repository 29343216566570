import React from "react";

import { Link } from "react-router-dom";

const RedirectBtn = ({ redirectTo, text }) => {
  return (
    // TODO: Can make another for headers, have a flag for if isHeader then return a header style

    <Link
      to={redirectTo}
      variant='contained'
      style={{
        background: "#06659D",
        color: "white",
        border: "none",
        cursor: "pointer",
        padding: "8px 20px",
        fontSize: "14px",
        borderRadius: "6px",
        fontWeight: "500",
        boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
      }}
    >
      {text}
    </Link>
  );
};

export default RedirectBtn;
