import React, { useState } from "react";
import { H5P as H5PStandalone } from "h5p-standalone";
import { useEffect } from "react";
// import ReactDOM from "react-dom";
// import DOMPurify from "dompurify";
// import { JSDOM } from "jsdom";
import parse from "html-react-parser";
import "./assets/styles/h5p.css";
import "./assets/styles/h5p-admin.css";
import "./assets/styles/h5p-core-button.css";
import "./assets/styles/h5p-confirmation-dialog.css";
import "./assets/styles/h5p-hub-registration.css";
import "./assets/styles/h5p-hub-sharing.css";
import "./assets/styles/h5p-admin.css";
import "./assets/styles/multichoice.css";
// import "../ /assets/styles/multichoice.css";
const H5PPlayerTempPage = () => {
  // console.log("dataJson", dataJson);
  const [folderPath, setFolderPath] = useState("");
  const [tempFolderCheck, setTempFolderCheck] = useState("");
  // const [quesNum, setquesNum] = useState(1);
  // const [div, setDiv] = useState("h5p-1");
  const [htmlElement, setHtmlElement] = useState("");
  const [showHtmlElement, setShowHtmlElement] = useState(false);
  const [tempFolderPath, setTempFolderPath] = useState("");
  // const [fetchFileName, setFetchFileName] = useState("");
  // const [fetchFileNameTemp, setFetchFileNameTemp] = useState("");
  // const [responseToFetch, setResponseToFetch] = useState({});
  // console.log(`/opt/startup/h5pextracted/5_Sibling_Nodes_TS1`);
  const handleFilePath = (event) => {
    event.preventDefault();
    setFolderPath(tempFolderPath);
    console.log(event);
  };
  const handleFilePathChange = (event) => {
    event.preventDefault();
    console.log(event.target.value);
    setTempFolderPath(event.target.value);
  };

  // fetch
  // const handleFileName = (event) => {
  //   event.preventDefault();
  //   setFetchFileName(fetchFileNameTemp);
  //   console.log(fetchFileNameTemp);
  // };
  // const handleFileNameChange = (event) => {
  //   event.preventDefault();
  //   // console.log(event.target.value);
  //   setFetchFileNameTemp(event.target.value);
  // };

  // useEffect(() => {
  //   const getData = () => {
  //     if (fetchFileName !== "") {
  //       fetch(fetchFileName, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //       })
  //         .then(function (response) {
  //           console.log(response);
  //           // setResponseToFetch(response.json());
  //           return response.json();
  //         })
  //         .then(function (myJson) {
  //           console.log("myJson", myJson);
  //           setData(myJson);
  //         });
  //     }
  //   };
  //   getData();
  // }, [fetchFileName]);

  useEffect(() => {
    console.log("folderPath", folderPath);
    // console.log("typeof  folderPath", typeof folderPath);
    if (folderPath !== "" && folderPath !== tempFolderCheck) {
      let options = {
        // h5pJsonPath: `/opt/startup/h5pextracted/${folderPath}`,
        h5pJsonPath: folderPath,
        // h5pJsonPath: "/h5p/H5PTest",
        frameJs: "/assets/frame.bundle.js",
        frameCss: "/assets/styles/h5p.css",
        customCss: "/assets/styles/multichoice.css",
      };

      // try {
      new H5PStandalone(document.getElementById("h5p-container"), options).then(
        function () {
          window.H5P.externalDispatcher.on("xAPI", async (event) => {
            // console.log("event.data.statement", event.data.statement);
            if (event.data.statement.result) {
            }
          });
        }
      );
      setTempFolderCheck(folderPath);
      // .then(
      //   function () {
      //     window.H5P.externalDispatcher.on("xAPI", (event) => {
      //       console.log("event", event);
      //     });
      //   }
      // );
    }
  }, [folderPath]);

  const handleClick = () => {
    console.log("1", document.getElementById(`h5p-container`));
    console.log("2", document.querySelector(".h5p-iframe"));
    console.log("3", document.querySelector(".h5p-iframe").contentWindow);
    console.log(
      "3.1",
      document
        .querySelector(".h5p-iframe")
        .contentWindow.document.querySelector(".h5p-iframe")
    );
    console.log(
      "4",
      document.querySelector(".h5p-iframe").contentWindow.document
    );
    console.log(
      "5",
      document
        .getElementById(`h5p-container`)
        .querySelector(".h5p-iframe")
        .contentWindow.document.querySelector(".h5p-iframe")
        .toString()
    );
    console.log(
      "document.getElementById(`h5p-container`)",
      document.getElementById(`h5p-container`)
    );

    // document.getElementById("newIdElement").innerHTML = document
    //   .querySelector(".h5p-iframe")
    //   .contentWindow.document.querySelector(".h5p-iframe")
    //   .querySelector(".h5p-content");
    const actual = document
      .querySelector(".h5p-iframe")
      .contentWindow.document.querySelector(".h5p-iframe").outerHTML;
    console.log("actual :>> ", actual);
    // DOMPurify.sanitize(actual, {FORCE_BODY: true});
    // const window = new JSDOM("").window;
    // const purify = DOMPurify.sanitize(actual, { FORCE_BODY: true });
    // const clean = purify.sanitize(actual);
    setHtmlElement(actual);
    // const cleanHtmlString = DOMPurify.sanitize(actual, {
    //   USE_PROFILES: { html: true },
    // });
    // console.log("cleanHtmlString :>> ", cleanHtmlString);
    // console.log("parse(cleanHtmlString), :>> ", parse(cleanHtmlString));
    // const rootValue = document.getElementById("newIdElement");
    // ReactDOM.render(parse(cleanHtmlString), rootValue);
  };
  const newValue = JSON.stringify(htmlElement);
  console.log("htmlElement :>> ", htmlElement);
  console.log("htmlElement :>> ", htmlElement);
  console.log("htmlElement :>> ", htmlElement);
  console.log("htmlElement.toString() :>> ", htmlElement.toString());
  console.log("htmlElement.outerHTML:>> ", htmlElement.outerHTML);
  console.log(
    "JSON.stringify({ html: htmlElement }) :>> ",
    JSON.stringify({ html: htmlElement })
  );
  console.log(" parse(htmlElement) :>> ", parse(htmlElement.toString()));
  console.log(" parse(htmlElement) :>> ", parse(htmlElement.toString()));
  return (
    <div>
      <div
        style={{
          // background: "white",
          padding: "40px",
          maxWidth: "1280px",
          width: "95%",
          margin: "auto ",
          transition: "all 0.5s ease-in-out",
        }}
      >
        <button onClick={() => handleClick()}>click</button>
        <button onClick={() => setShowHtmlElement((prev) => !prev)}>
          show
        </button>
        <div
          className=""
          style={{
            padding: "20px",
            borderRadius: "20px",
            background: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxShadow:
              " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          }}
        >
          <div className="" style={{ display: "flex", gap: "80px" }}>
            <div className="">
              <h4>fetch file:</h4>
              <form onSubmit={handleFilePath}>
                <label>
                  H5P folder path
                  <input
                    style={{
                      width: "340px",
                      marginLeft: "5px",
                      border: "2px solid #222",
                    }}
                    onChange={handleFilePathChange}
                  ></input>
                </label>
                <button
                  style={{
                    background: "#6357A5",
                    // border: "none",
                    color: "white",
                    border: "2px solid #6357A5",
                    marginLeft: "5px",
                  }}
                >
                  submit
                </button>
                <p>
                  Paths to test:
                  <ul>
                    <li>/h5p/Measure</li>
                    <li>/h5p/Byte</li>
                    <li>/h5p/Cursor</li>
                    <li>/h5p/Question</li>
                  </ul>
                </p>
              </form>
              {/* <h6>fetch response: check the console.log</h6> */}
            </div>
          </div>
          <div
            style={{
              width: "90%",
            }}
            id="h5p-container"
          ></div>
          <div
            style={{
              width: "90%",
              border: "2px solid gray",
              margin: "15px",
            }}
          ></div>
          {showHtmlElement && (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: htmlElement,
                }}
              ></div>
              {/* <div>/
                {DOMPurify(new JSDOM("<!DOCTYPE html>").window).sanitize(
                  htmlElement
                )}
              </div> */}
            </>
          )}
          {/* {showHtmlElement && <iframe src={htmlElement.contentWindow} />} */}
        </div>
        <div id="newIdElement"></div>
      </div>
    </div>
  );
};

export default H5PPlayerTempPage;
