import React from "react";

const ListItemHeaderComponent = ({ data }) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#05649D",
          color: "white",
          //   borderRadius: "199px",
          overflow: "hidden",

          padding: "10px",
          //   margin: "10px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "30%",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5
            style={{
              margin: "0 0 0 12px",
              color: "white",
              fontSize: "14px",
            }}
          >
            {data.title}
          </h5>
        </div>
        <p
          style={{
            width: "40%",
            margin: " 0 0 0 0",
            padding: " 0 0 0 0",
          }}
        >
          {/* {data.email} */}
        </p>
        <p
          style={{
            width: "10%",

            margin: " 0 0 0 0",
            padding: " 0 0 0 0",
          }}
        >
          {/* edit */}
        </p>
        <p
          style={{
            width: "10%",

            margin: " 0 0 0 0",
            padding: " 0 0 0 0",
          }}
        >
          {/* delete */}
        </p>
      </div>
    </div>
  );
};

export default ListItemHeaderComponent;
