import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import useCurriculumApi from "api/curriculum";
import H5PQuestionItem from "./H5PQuestionItem";
import ActivityPlayerTestingComp from "./ActivityPlayerTestingComp";
import ActivityPlayerBatch from "./ActivityPlayerBatch";
import H5PTestingLearnObj from "./H5PTestingLearnObj";
import useStore from "../../../zustand";
import { Link } from "react-router-dom";
// import H5PTestingQuestions from "./H5PTestingQuestions";
const H5PTestingPlayerPage = () => {
  const [allTheQuestions, setAllTheQuestions] = useState([]);
  const [allLearnObjGUIDs, setAllLearnObjGUIDs] = useState([]);
  const [selectedQuestionName, setSelectedQuestionName] = useState("");
  const [selectedQuestionNumber, setSelectedQuestionNumber] = useState("");
  const [selectedQuestionFolderName, setSelectedQuestionFolderName] =
    useState("");
  const [selectedQuestionFileName, setSelectedQuestionFileName] = useState("");
  const [selectedLearningObjective, setSelectedLearningObjective] =
    useState("");
  const [selectBatch, setSelectBatch] = useState(null);
  const [isStart, setIsStart] = useState(false);
  const [numberGroup, setNumberGroup] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [selectedQuestionGUID, setSelectedQuestionGUID] = useState("");
  const { fetchAllH5PQuestions, fetchLearningObjectives } = useCurriculumApi();

  const { status, data, isLoading } = useQuery(
    ["fetchAllH5PQuestions"],
    () => fetchAllH5PQuestions(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { setActivityBatchQuestions } = useStore();
  const arrayOf10Arrays = [];
  const arrayLearnObj = [];
  const {
    data: learningData,
    status: learningStatus,
    isLoading: isLoadingLO,
    isError: isErrorLO,
  } = useQuery("learningObjectives", () => fetchLearningObjectives(), {
    refetchOnWindowFocus: false,
  });

  const learningDataIdArray = (data) => {
    // console.log("arrayOf100Objects inside for loop", arrayOf100Objects);
    if (data?.length > 0) {
      for (let i = 0; i < data?.length; i++) {
        const guidLearn = data[i]?.id;
        arrayLearnObj.push(guidLearn);
      }
    }
    setAllLearnObjGUIDs(arrayLearnObj);
  };
  useEffect(() => {
    if (learningData) {
      learningDataIdArray(learningData?.data);
    }
  }, [learningData]);
  console.log("allLearnObjGUIDs", allLearnObjGUIDs);

  // console.log(
  //   "learningData && learningData :>> ",
  //   learningData && learningData
  // );
  console.log("selectedLearningObjective :>> ", selectedLearningObjective);
  useEffect(() => {
    if (data) {
      createTheQuestionArray(
        data?.data?.sort(function (a, b) {
          if (a.foldername.toLowerCase() < b.foldername.toLowerCase())
            return -1;
          if (a.foldername.toLowerCase() > b.foldername.toLowerCase()) return 1;
          return 0;
        })
      );
    }
  }, [data]);

  const createTheQuestionArray = (arrayOf100Objects) => {
    // console.log("arrayOf100Objects inside for loop", arrayOf100Objects);
    if (arrayOf100Objects?.length > 0) {
      for (let i = 0; i < arrayOf100Objects?.length; i += 14) {
        const sliceOf10Objects = arrayOf100Objects?.slice(i, i + 14);
        arrayOf10Arrays.push(sliceOf10Objects);
      }
    }
    setAllTheQuestions(arrayOf10Arrays);
  };

  // console.log("arrayOf10Arrays", arrayOf10Arrays);
  if (data && learningData) {
    return (
      <div
        style={{
          width: "95%",
          padding: "40px",
          background: "white",
          margin: "auto ",
          transition: "all 0.5s ease-in-out",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "20px",
          }}
        >
          <H5PTestingLearnObj
            learningData={learningData?.data}
            setSelectedLearningObjective={setSelectedLearningObjective}
            selectedLearningObjective={selectedLearningObjective}
            questionsData={data?.data}
          />
          {/* <H5PTestingQuestions
          questionsAll={data?.data}
          setSelectedQuestionName={setSelectedQuestionName}
          setSelectedQuestionNumber={setSelectedQuestionNumber}
          setSelectedQuestionFolderName={setSelectedQuestionFolderName}
          setSelectedQuestionGUID={setSelectedQuestionGUID}
          selectedQuestionGUID={selectedQuestionGUID}
          setSelectedQuestionFileName={setSelectedQuestionFileName}
          selectedQuestionFileName={selectedQuestionFileName}
          isStart={isStart}
        /> */}
          <div
            style={{
              padding: "5px",
              border: "1px solid rgba(0,0,0,0.1)",
              borderRadius: "12px",
            }}
          >
            <h3
              style={{
                padding: "5px 10px",
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: "12px",
                background: "rgba(0,0,0,0.06)",
              }}
            >
              Activity Questions: foldername
            </h3>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "80vh",
                overflow: "auto",
                width: "540px",
              }}
            >
              {data &&
                data?.data
                  ?.filter((item) =>
                    selectedLearningObjective?.length > 0
                      ? item.learning_objectives.includes(
                          selectedLearningObjective
                        )
                      : true
                  )
                  ?.map((item, index) => (
                    <div>
                      <H5PQuestionItem
                        item={item}
                        index={index + 1}
                        setSelectedQuestionName={setSelectedQuestionName}
                        setSelectedQuestionNumber={setSelectedQuestionNumber}
                        setSelectedQuestionFolderName={
                          setSelectedQuestionFolderName
                        }
                        setSelectedQuestionGUID={setSelectedQuestionGUID}
                        selectedQuestionGUID={selectedQuestionGUID}
                        setSelectedQuestionFileName={
                          setSelectedQuestionFileName
                        }
                        selectedQuestionFileName={selectedQuestionFileName}
                        isStart={isStart}
                        learningData={learningData?.data}
                        allLearnObjGUIDs={allLearnObjGUIDs}
                      />
                      <hr
                        style={{
                          margin: "5px",
                          padding: "",
                        }}
                      />
                    </div>
                  ))}
            </div>
          </div>
          <div style={{ padding: "0 20px" }}>
            <div>
              {data &&
                data?.data?.filter((item) =>
                  item.learning_objectives.includes(selectedLearningObjective)
                ).length > 0 && (
                  <Link
                    to="/activities/h5pComp/testing"
                    style={{
                      background: "rgb(99, 88, 165)",
                      color: "white",
                      border: "none",
                      cursor: "pointer",
                      padding: "20px",
                      fontSize: "14px",
                      borderRadius: "8px",
                      fontWeight: "500",
                      // width: "0px",
                      margin: "0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() =>
                      setActivityBatchQuestions(
                        data?.data?.filter((item) =>
                          item.learning_objectives.includes(
                            selectedLearningObjective
                          )
                        )
                      )
                    }
                  >
                    Start questions
                  </Link>
                )}
            </div>
            {false && (
              <ActivityPlayerBatch
                allTheQuestions={allTheQuestions}
                setSelectBatch={setSelectBatch}
              />
            )}
            {isStart && (
              <ActivityPlayerTestingComp
                selectedQuestionName={selectedQuestionName}
                selectedQuestionNumber={selectedQuestionNumber}
                selectedQuestionFolderName={selectedQuestionFolderName}
                selectedQuestionGUID={selectedQuestionGUID}
                selectedQuestionFileName={selectedQuestionFileName}
                isStart={isStart}
              />
            )}
          </div>
        </div>
      </div>
    );
  } else if (isLoading || isLoadingLO) {
    return (
      <div
        style={{
          width: "95%",
          padding: "40px",
          background: "white",
          margin: "auto ",
          transition: "all 0.5s ease-in-out",
        }}
      >
        <h1>Loading...</h1>
      </div>
    );
  } else {
    return null;
  }
};

export default H5PTestingPlayerPage;
