import React from "react";
import { Link } from "react-router-dom";


const ManagePageHeader = ({ title, buttons, links }) => {
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "space-between",
                }}
            >
                <h2 style={{ fontWeight: "400" }}>
                    <span style={{ fontWeight: "600" }}>
                        {title}
                    </span>
                </h2>
                <div>
                    {links.map((link) => (
                        <Link to={link.link}>
                            <button
                                variant='contained'
                                style={{
                                    background: '#05649D',
                                    color: 'white',
                                    padding: "7px 17px",
                                    border: "none",
                                    cursor: "pointer",
                                    fontSize: "14px",
                                    borderRadius: "6px",
                                    fontWeight: "500",
                                    boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                                    marginRight: 15
                                }}
                            >
                                {link.name}
                            </button>
                        </Link>
                    ))}

                    {buttons.map((button) => (
                        <button
                            variant='contained'
                            onClick={button.action}
                            style={{
                                background: '#05649D',
                                color: 'white',
                                padding: "7px 17px",
                                border: "none",
                                cursor: "pointer",
                                fontSize: "14px",
                                borderRadius: "6px",
                                fontWeight: "500",
                                boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                            }}
                        >
                            {button.name}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ManagePageHeader;
