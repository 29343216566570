import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
// import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
// import SchoolIcon from "@mui/icons-material/School";
import Tooltip from "@mui/material/Tooltip";
// import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import EditClass from "../EditClass/EditClass";
import { useQueryClient, useMutation, useQuery } from "react-query";
import useCurriculumApi from "api/curriculum";
import useUserApi from "api/user";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import GeneralSnackbar from "components/Snackbar/GeneralSnackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@mui/material/Snackbar";
// import CreateTest from "../../components/CreateTest/Test";

const ClassEditPanel = () => {
  const params = useParams();
  const queryClient = useQueryClient();
  const history = useHistory();

  const [editClassOpen, setEditClassOpen] = useState(false);
  const [newClassId, setNewClassId] = React.useState(null);

  const { fetchAvailableLevels } = useCurriculumApi();
  const { fetchClass, deleteClass, updateClass } = useUserApi();

  const { data: availableLevels, status: availableLevelsStatus } = useQuery(
    ["available_levels", newClassId],
    () => fetchAvailableLevels(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: classData, status: classStatus } = useQuery(
    ["class", newClassId],
    () => newClassId && fetchClass(newClassId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isLoading, isSuccess, isError, error } = useMutation(
    (classID) => deleteClass(classID)
  );

  const {
    mutate: mutateUpdate,
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
  } = useMutation((updatedInfo) => updateClass(updatedInfo), {
    onSuccess: () => queryClient.invalidateQueries("class"),
  });

  React.useEffect(() => {
    setNewClassId(params.classId);
    // console.log("666666666666");
  }, []);

  function handleCloseEdit() {
    setEditClassOpen(false);
  }

  function editClass() {
    setEditClassOpen(true);
  }

  function toDeleteClass() {
    mutate(newClassId);
    setOpenSnackbar(true);
    handleCloseEdit();
    setTimeout(() => history.push("/data/school"), 2500);
  }

  // Snackbar disappear
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  React.useEffect(() => {
    // console.log("88888888888");

    const timeout = setTimeout(() => {
      setOpenSnackbar(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [openSnackbar]);

  React.useEffect(() => {
    // console.log("9999999999999");

    if (isSuccess) {
      queryClient.invalidateQueries();
    }
  }, [isSuccess]);

  return (
    <div
      style={{
        marginBottom: "16px",
        borderRadius: "6px",
        boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
      }}
    >
      <GeneralSnackbar
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message="The class has been successfully deleted."
      />

      <GeneralSnackbar
        isLoading={isLoadingUpdate}
        isSuccess={isSuccessUpdate}
        isError={isErrorUpdate}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message="The class has been successfully edited."
      />
      <EditClass
        open={editClassOpen}
        close={handleCloseEdit}
        classData={classStatus == "success" ? classData : null}
        classStatus={classStatus}
        status={classStatus}
        availableLevels={availableLevels}
        availableLevelsStatus={availableLevelsStatus}
        mutate={mutateUpdate}
        setOpenSnackbar={setOpenSnackbar}
        isSuccess={isSuccessUpdate}
        // setCurrentLevel={setCurrentLevel}
        // setLessonPlanId={setLessonPlanId}
        toDeleteClass={toDeleteClass}
      />

      <div
        style={{
          display: "flex",
          borderRadius: "6px",
        }}
      >
        <Tooltip
          title={
            <div
              style={{
                fontSize: "14px",
                background: "white",
                color: "rgba(0,0,0,0.8)",
                borderRadius: "4px",
                padding: "10px",
                margin: "8px",
              }}
            >
              <h4
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {/* {toolTipDef(item.name, item.description)} */}
                update class, edit members of the class, transfer teachers or
                students who are already added to your school.
              </h4>
            </div>
          }
          arrow
          placement="top"
        >
          <button
            onClick={() => editClass()}
            variant="contained"
            style={{
              background: "#06659D",
              color: "white",
              // height: "43px",
              // fontSize: "14px",
              border: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              padding: "10px 14px",
              fontSize: "14px",
              borderRadius: "6px",
              fontWeight: "500",
              boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
            }}
          >
            <CreateOutlinedIcon
              style={{
                margin: "0 8px 0 0",
              }}
            />{" "}
            Edit Class
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default ClassEditPanel;
