import React from "react";
import CodeIcon from "@mui/icons-material/Code";
import usePersistStore from "../../../zustandPersist";
import { Link } from "react-router-dom";

const BlocklyPageNew = () => {
  const { setXmlCodeSelected } = usePersistStore();

  // const [xml, setXml] = React.useState();
  return (
    <div
      style={{
        maxWidth: "1480px",
        margin: "auto",
        width: "100%",
        minHeight: "400px",
      }}
    >
      <div
        style={{
          margin: "auto",
          width: "95%",
        }}
      >
        <div
          style={{
            padding: "20px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h1
            style={{
              margin: "0",
              fontSize: "2.2rem",
            }}
          >
            Blocks
          </h1>

          <Link
            to="/activities/blocks/codingBlocks"
            style={{
              margin: "0",
              // fontSize: "1.2rem",
              padding: "4px 12px",
              color: "white",
              background: "rgba(99, 88, 165,1)",
              cursor: "pointer",
              border: "none",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              boxShadow:
                " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              // margin: "0 auto",
            }}
            onClick={() =>
              setXmlCodeSelected(
                '<xml xmlns="http://www.w3.org/1999/xhtml"></xml>'
              )
            }
          >
            Start a block space <CodeIcon style={{ marginLeft: "16px" }} />
          </Link>
        </div>

        <div
          style={{
            padding: "20px 0px",
            // background: "white",
            // boxShadow:
            //   " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          }}
        >
          <h3>Example Blocks</h3>
          <div
            style={{
              // background: "white",
              padding: "20px 0px",
              display: "flex",
              // justifyContent: "space-between",
              gap: "40px",
              // boxShadow:
              //   " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            }}
          >
            <div
              style={{
                background: "white",
                padding: "20px 20px",
                boxShadow:
                  " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                borderRadius: "8px",
                width: "240px",
                // minHeight: "180px",
              }}
            >
              <h3>If statement</h3>
              <Link
                to="/activities/blocks/codingBlocks"
                style={{
                  margin: "20px 0 0 0",
                  fontSize: "1rem",
                  padding: "8px 16px",
                  color: "white",
                  background: "rgba(99, 88, 165,1)",
                  cursor: "pointer",
                  border: "none",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "600",
                  boxShadow:
                    " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                  // margin: "0 auto",
                }}
                onClick={() =>
                  setXmlCodeSelected(
                    '<xml xmlns="http://www.w3.org/1999/xhtml"><block type="controls_ifelse" x="0" y="0"></block></xml>'
                  )
                }
              >
                start
              </Link>
            </div>
            <div
              style={{
                background: "white",
                padding: "20px",
                boxShadow:
                  " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                borderRadius: "8px",
                width: "240px",
              }}
            >
              <h3>Loops</h3>
              <Link
                to="/activities/blocks/codingBlocks"
                style={{
                  margin: "20px 0 0 0",
                  padding: "8px 16px",
                  color: "white",
                  background: "rgba(99, 88, 165,1)",
                  cursor: "pointer",
                  border: "none",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "600",
                  boxShadow:
                    " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                  // margin: "0 auto",
                }}
                onClick={() =>
                  setXmlCodeSelected(
                    '<xml xmlns="https://developers.google.com/blockly/xml"><block type="controls_repeat_ext" id="-ZX}xp-a#k@gtZRFjBCq" x="10" y="90"><value name="TIMES"><block type="math_number" id="+D`1Waf8D4JQWJS~B1EO"><field name="NUM">5</field></block></value><statement name="DO"><block type="text_print" id="nSOJ}=WD`+I(dr]+9ObS"><value name="TEXT"><block type="text" id="ZYYN.lN@,(h!%7Np^sWk"><field name="TEXT">hello world</field></block></value></block></statement></block></xml>'
                  )
                }
              >
                start
              </Link>
            </div>
            <div
              style={{
                background: "white",
                padding: "20px",
                boxShadow:
                  " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                borderRadius: "8px",
                width: "240px",
              }}
            >
              <h3>Functions</h3>
              <Link
                to="/activities/blocks/codingBlocks"
                style={{
                  margin: "20px 0 0 0",
                  fontSize: "1rem",
                  padding: "8px 16px",
                  color: "white",
                  background: "rgba(99, 88, 165,1)",
                  cursor: "pointer",
                  border: "none",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "600",
                  boxShadow:
                    " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                  // margin: "0 auto",
                }}
                onClick={() =>
                  setXmlCodeSelected(
                    '<xml xmlns="https://developers.google.com/blockly/xml"><block type="procedures_defnoreturn" id="zEnA*7bX7#_fM[(z52?U" x="110" y="110"><field name="NAME">do something</field><comment pinned="false" h="80" w="160">Describe this function...</comment></block><block type="procedures_callnoreturn" id="Z(#5iL}X.}kx|XC-2(bG" x="250" y="230"><mutation name="do something"></mutation></block></xml>'
                  )
                }
              >
                start
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlocklyPageNew;
