import React, { useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
// import SignalCellularAltRoundedIcon from "@mui/icons-material/SignalCellularAltRounded";
import { Link } from "react-router-dom";
// import EastIcon from "@mui/icons-material/East";
// import ClassLevel from "./ClassLevel";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useUserApi from "api/user";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import ConfirmModal from "components/Modals/ConfirmModal";
import useStore from "../../../../../zustand";
// import ClassCardSkeleton from "./ClassCardSkeleton";s
import ClassPerformanceList from "./ClassPerformanceList";
import CardListSkeleton from "./skeletons/CardListSkeleton";
const ClassCardList = ({ classItem }) => {
  const { fetchCurrentClass, deleteClass } = useUserApi();
  const { schoolName } = useStore();
  const queryClient = useQueryClient();

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  //Confirmation
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmActionDescription, setConfirmActionDescription] =
    useState(null);
  const {
    data: currentClass,
    status: currentClassStatus,
    isLoading: isLoadingClass,
  } = useQuery(
    ["classItem", classItem.id],
    () => fetchCurrentClass(classItem.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [classLinkId, setClassLinkId] = useState(null);
  const { mutate, isLoading, isSuccess, isError } = useMutation(
    (id) => deleteClass(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("classes");
      },
    }
  );
  useEffect(() => {
    setClassLinkId(classItem.id);
  }, [classItem]);

  function toDeleteClass() {
    setConfirmAction(
      () =>
        function () {
          mutate(classItem.id);
        }
    );
    setConfirmActionDescription(
      schoolName
        ? `remove ${classItem.name} from ${schoolName}`
        : `remove ${classItem.name} from this school`
    );
    setConfirmOpen(true);
  }

  // Snackbar disappear
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenSnackbar(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [openSnackbar]);

  if (isLoadingClass) {
    return <CardListSkeleton />;
  }
  if (currentClassStatus === "success") {
    return (
      <>
        <ConfirmModal
          action={confirmAction}
          actionDescription={confirmActionDescription}
          open={confirmOpen}
          setOpen={setConfirmOpen}
        />
        <div
          style={{
            background: "white",
            borderRadius: "8px",
            width: "100%",
            padding: "5px 20px",
            margin: "14px 0",
            boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Link
            to={`/data/class/${classLinkId}`}
            style={{ textDecoration: "none", width: "20%" }}
          >
            <h5
              style={{
                fontWeight: "500",
                fontSize: "1.2rem",
                marginLeft: "0",
                marginBottom: "0",
                // marginRight: gridView ? "22px" : "",
              }}
            >
              {classItem.name}
            </h5>
          </Link>
          {/* <h1>Nice</h1> */}
          <ClassPerformanceList
            classPerformance={currentClass.data.performance}
            gridView={true}
          />
          <div></div>
          <div
            style={{
              minWidth: "25%",
              display: "flex",
              //   //   flexDirection: "column",
              // justifyContent: "start",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "135px" }}
            >
              <PersonRoundedIcon style={{ color: "#1D6F98" }} />
              <p style={{ margin: "0 0 0 10px" }}>
                {classItem?.teachers.length === 1
                  ? `${classItem?.teachers.length} Teacher`
                  : `${classItem?.teachers.length} Teachers`}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "14px",
                width: "135px",
              }}
            >
              <SchoolOutlinedIcon style={{ color: "#1D6F98" }} />
              <p style={{ margin: "0 0 0 10px" }}>
                {classItem?.students.length === 1
                  ? `${classItem?.students.length} Student`
                  : `${classItem?.students.length} Students`}
              </p>
            </div>
          </div>
          <DeleteIcon
            style={{
              color: "#7B8794",
              cursor: "pointer",
              marginRight: "20px",
              //   marginLeft: gridView ? "4px" : "",
              //   fontSize: gridView ? "24px" : "1.2rem",
            }}
            onClick={() => (toDeleteClass(), setOpenSnackbar(true))}
          />
        </div>
      </>
    );
  } else {
    return (
      <div
        style={{
          background: "white",
          borderRadius: "8px",
          width: "100%",
          padding: "5px 20px",
          margin: "14px 0",
          boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <h5
          style={{
            width: "20%",
            fontWeight: "500",
            fontSize: "1.2rem",
            marginLeft: "0",
            marginBottom: "0",
            // marginRight: gridView ? "22px" : "",
          }}
        >
          <CardListSkeleton />
        </h5>
      </div>
    );
  }
};

export default ClassCardList;
