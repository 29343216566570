import React from "react";

import {
  Box,
  Divider,
  // Grid,
  // Typography,
  List,
  ListItem,
  // ListItemIcon,
  Typography,
  // ListItemText,
} from "@material-ui/core";
// import LinearProgress from "@mui/material/LinearProgress";
// import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import InboxIcon from '@mui/icons-material/Inbox';
// import DraftsIcon from '@mui/icons-material/Drafts';
import comp from "../assets/comp.png";
import doc from "../assets/doc.png";
import { ListItemButton } from "@mui/material";
// import { ListItemButton } from '@mui/material';
import { useQuery } from "react-query";
import useUserApi from "api/user";
// import { LoadingScreen } from "views/eai/curriculum/Components/LoadingScreen";
import { ErrorScreen } from "views/eai/curriculum/Components/ErrorScreen";
import useStore from "../../../../zustand";
import ActivityStageCard from "./ActivityStageCard";
import ListComponentSkeleton from "./ListComponentSkeleton";
import EmptyStateTwo from "./EmptyStateTwo";

const stockData = [
  {
    title: `Functions - Python`,
    completion: "25",
    questionsDone: "3/12 questions complete",
    image: comp,
  },
  {
    title: `Computer Thinking Mock Test`,
    completion: "75",
    questionsDone: "4/6 questions complete",
    image: doc,
  },
  {
    title: `Functions - Python`,
    completion: "100",
    questionsDone: "12/12 questions complete",
    image: comp,
  },
];
const RecentActivity = ({ matchesTwo, matchesThree, homepage }) => {
  const { selectedClassId } = useStore((state) => state);
  const history = useHistory();

  const { fetchRecentActivities } = useUserApi();

  const { isLoading, isError, data, error } = useQuery(
    ["recentActivities", selectedClassId],
    () => fetchRecentActivities(selectedClassId),
    { enabled: selectedClassId != null, refetchOnWindowFocus: false }
  );
  let lengthAct;
  if (data) {
    if (data.data.length > 5) {
      lengthAct = 3;
    } else {
      lengthAct = data.data.length;
    }
  }
  if (isLoading) {
    return <ListComponentSkeleton />;
  }
  if (isError) {
    return <ErrorScreen error={error} />;
  }
  if (data && data.data.length === 0) {
    return (
      <div style={{ width: `${matchesTwo ? "48%" : "100%"}` }}>
        <EmptyStateTwo
          header={"Recent Activities"}
          title={"Recent work"}
          isToDo={false}
          height={"565px"}
          // height="600px"
          text={"You've yet to start any activity."}
        />
      </div>
    );
  }

  return (
    <Box
      sx={{ width: `${matchesTwo ? "48%" : "100%"}`, marginBottom: "5%" }}
      className="activitiesRecent"
    >
      <Typography
        variant="h3"
        style={{
          color: "#7B8794",
          fontSize: "18px",
          fontWeight: "600",
          marginBottom: "16px",
        }}
      >
        Recent activities
      </Typography>

      <Box
        sx={{
          width: "100%",
          // maxWidth: 700,
          borderRadius: "12px",
          boxShadow: " 0 5px 15px hsla(0, 0%, 0%, 0.2)",
        }}
        style={{ backgroundColor: "white" }}
      >
        {/* act.lesson.image ? act.lesson.image : */}
        <nav aria-label="main mailbox folders">
          <List
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "565px",
            }}
          >
            <div>
              {data &&
                data.data.slice(0, 4).map((act, index) => (
                  <>
                    <>
                      <ActivityStageCard
                        key={act.exercise.id}
                        name={act.exercise.name}
                        image={act.lesson === null ? comp : act.lesson.image}
                        // level={act.level}
                        exercise={act.exercise}
                        exerciseId={act.exercise.id}
                        lessonId={act?.lesson?.id ? act?.lesson?.id : null}
                        maxQuestion={act.max_questions}
                        questionsAnswered={act.questions_answered}
                        status={act.status}
                        lesson={act.lesson}
                        classAct={act._class}
                        homepage={homepage}
                      />
                      {index < 4 ? <Divider /> : ""}
                    </>
                    {/* <Divider /> */}
                  </>
                ))}
              {/* <Divider /> */}
              {/* <Divider />
              <Divider /> */}
            </div>
            <div className="">
              <Divider />
              <Divider />
              <ListItem disablePadding sx={{ height: "fit-content" }}>
                <ListItemButton
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    // textAlign: 'right',
                    width: "100%",
                    height: "100%",
                    padding: "5px 0px",
                  }}
                  onClick={() => history.push(`/activities/activities`)}
                >
                  <Typography
                    variant="p"
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      padding: "8px 0",
                      color: "#1D6F98",
                    }}
                  >
                    View all activity{" "}
                    <ArrowForwardIcon pb={0} sx={{ fontSize: "18px" }} />
                  </Typography>
                  {/* </Box> */}
                </ListItemButton>
              </ListItem>
            </div>
          </List>
        </nav>
      </Box>
    </Box>
  );
};

export default RecentActivity;
