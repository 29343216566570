import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Divider } from "@material-ui/core";
import { Box, Typography } from "@material-ui/core";

import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import ToDoListActPage from "./ToDoListActPage";
const ActivityRecentSkeleton = () => {
  const arr2 = new Array(6).fill(0);
  return (
    <>
      <Box sx={{ maxWidth: "1280px", margin: "24px auto", width: "95%" }} mt={4}>
        <Typography
          style={{
            marginBottom: "20px",
            fontSize: "3rem",
            fontWeight: "700",
            textAlign: "left",
          }}
          variant="h1"
        >
          My Activities
        </Typography>
        <Box>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ToDoListActPage homepage={false} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* <ToDoListActPage homepage={false} /> */}
          </div>
          <Box style={{ marginTop: "70px" }}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                margin: "20px 0",
              }}
            >
              <Typography
                style={{
                  fontSize: "1.4rem",
                  fontWeight: "600",
                  textAlign: "left",
                }}
              >
                Recent activity
              </Typography>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  // marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    marginRight: "12px",
                  }}
                >
                  <TableRowsRoundedIcon
                    //   onClick={() => setDisplayGrid(false)}
                    style={{
                      cursor: "pointer",
                      fontSize: "2rem",
                      padding: "5px",
                      borderRadius: "5px",
                      color: "#fff",
                      backgroundColor: "#6358A5",
                      boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    }}
                  />
                </div>
                <div>
                  <GridViewRoundedIcon
                    //   onClick={() => setDisplayGrid(true)}
                    style={{
                      cursor: "pointer",
                      fontSize: "2rem",
                      padding: "5px",
                      borderRadius: "5px",
                      color: "#fff",
                      backgroundColor: "#CBD2D9",
                      boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    }}
                  />
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
        <div
          style={{
            boxShadow: " 0 10px 15px hsla(0, 0%, 0%, 0.096)",
            borderRadius: "14px",
            background: "#fff",
            padding: "10px",
            marginTop: "30px",
            maxWidth: "1280px",
            width: "100%",
            margin: "24px auto",
          }}
        >
          {arr2.map((item, ind) => (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "20px ",
                }}
              >
                <Skeleton circle width={50} height={50} />
                <div
                  style={{
                    width: "80%",
                  }}
                >
                  <Skeleton count={3} />
                </div>
              </div>
              <Divider />
            </>
          ))}
        </div>
      </Box>
    </>
  );
};

export default ActivityRecentSkeleton;
