import React from "react";

const ClassPerformanceList = ({ classPerformance }) => {
  return (
    <div
      style={{
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "space-between",
        display: "flex",
        gap: "8px",
        margin: "0px 0",
      }}
    >
      <div
        style={{
          padding: "8px",
          // border: "1.5px solid #E4E7EB",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          //   boxShadow: " 0 4px 6px hsla(0,0%, 0%, .2)",
          //   boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgb(238, 194, 124)",
            width: "40px",
            height: "40px",
            borderRadius: "998px",
            color: "black",
            fontSize: "15px",
            fontWeight: "600",
            // marginRight: "8,
          }}
        >
          {classPerformance.worst}
        </div>
        {/* <h6
          style={{
            margin: "3px 0 0 10px",
            fontSize: "14px",
          }}
        >
          Help
        </h6> */}
      </div>
      <div
        style={{
          padding: "8px",
          // border: "1.5px solid #E4E7EB",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          //   boxShadow: " 0 4px 6px hsla(0,0%, 0%, .2)",
          //   boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#7Fcdee",
            width: "40px",
            height: "40px",
            borderRadius: "998px",
            color: "black",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          {classPerformance.middle}
        </div>
        {/* <h6
          style={{
            margin: "3px 0 0 10px",
            fontSize: "14px",
          }}
        >
          Middle
        </h6> */}
      </div>
      <div
        style={{
          padding: "8px",
          // border: "1.5px solid #E4E7EB",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          //   boxShadow: " 0 4px 6px hsla(0,0%, 0%, .2)",
          //   boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgb(138, 203, 95)",
            width: "40px",
            height: "40px",
            borderRadius: "998px",
            color: "black",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          {classPerformance.best}
        </div>
        {/* <h6
          style={{
            margin: "3px 0 0 10px",
            fontSize: "14px",
          }}
        >
          Challenge
        </h6> */}
      </div>
    </div>
  );
};
export default ClassPerformanceList;
