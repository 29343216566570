import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useUserApi from "api/user";
import { useQuery } from "react-query";
// import AssignedClassActivity from "./DataSiteComponents/DataSiteClassPage/AssignedClassActivity";
// import ClassPageHeader from "./DataSiteComponents/DataSiteClassPage/ClassPageHeader";
import ClassPerformance from "./DataSiteComponents/DataSiteClassPage/ClassPerformance";
import LessonPerformanceMain from "./DataSiteComponents/DataSiteClassPage/LessonPerformanceMain";
import ClassMembers from "./DataSiteComponents/DataSiteClassPage/ClassMembers";
import ClassPageHeader from "./DataSiteComponents/DataSiteClassPage/ClassPageHeader";
import useStore from "../../../zustand";
import useCurriculumApi from "api/curriculum";

const DataSiteClassPage = () => {
  const { classId } = useParams();
  const { setClassIdNavbar } = useStore();
  const { fetchLessonPlan } = useCurriculumApi();
  const { fetchClass } = useUserApi();
  const {
    data: classData,
    status: classStatus,
    isLoading: classIsLoading,
  } = useQuery(["class", classId], () => fetchClass(classId), {
    refetchOnWindowFocus: false,
    enabled: classId?.length > 0,
  });
  //fetch fetchLessonPlan
  const {
    data: lessonPlanData,
    status: lessonPlanStatus,
    isLoading: lessonPlanIsLoading,
  } = useQuery(["lessonPlanData"], () => fetchLessonPlan(), {
    refetchOnWindowFocus: false,
  });
  // console.log("classData && classData :>> ", classData && classData);
  // console.log("classData && classData :>> ", classData && classData);
  // console.log("classData && classData :>> ", classData && classData);
  // console.log("classData && classData :>> ", classData && classData);
  // console.log("classData && classData :>> ", classData && classData);
  // console.log("classId==>", classId);
  // console.log("classId==>", classId);
  // console.log("classId==>", classId);
  // console.log("classId==>", classId);
  // if (classData) console.log("classData ===>", classData.data);

  const [newClassId, setNewClassId] = useState(null);

  useEffect(() => {
    setNewClassId(classId);
    setClassIdNavbar(classId);
  }, [classId]);
  return (
    <div style={{ width: "95%", margin: "80px auto 80px" }}>
      {/* <ClassPageHeader classId={newClassId} classData={classData?.data} /> */}
      <div style={{ width: "95%", maxWidth: "1380px", margin: "auto" }}>
        <ClassPageHeader classData={classData?.data} />
        <ClassPerformance classId={newClassId} />
      </div>
      <LessonPerformanceMain
        classData={classData?.data}
        customLessonPlanData={
          lessonPlanData &&
          lessonPlanData?.data?.custom_lesson_plans?.filter(
            (item) => item.id === classData?.data?.level
          )
        }
        standardLessonPlanData={
          lessonPlanData &&
          lessonPlanData?.data?.standard_lesson_plans?.filter(
            (item) => item.level === classData?.data?.level
          )
        }
      />
      <div style={{ width: "95%", maxWidth: "1380px", margin: "auto" }}>
        <ClassMembers classData={classData?.data} />
      </div>

      {/* <AssignedClassActivity classId={newClassId} /> */}
    </div>
  );
};

export default DataSiteClassPage;
