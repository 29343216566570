import axios from "axios";
import useStore from '../zustand';

const useBuilderApi = () => {
  const { authToken } = useStore(state => state)

  const fetchMyLevels = () => {
    return axios.get(`${process.env.REACT_APP_API_PATH}/curriculum/api/levels/`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  }

  const fetchLevel = (levelId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/curriculum/api/levels/${levelId}/`,
      { headers: { Authorization: `Bearer ${authToken}` } }
    );
  }

  const fetchBuilderLessons = () => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/curriculum/api/builder_lessons/`,
      { headers: { Authorization: `Bearer ${authToken}` } }
    );
  }

  const saveLevel = (body) => {
    return axios.post(
      `${process.env.REACT_APP_API_PATH}/curriculum/api/levels/`,
      body,
      { headers: { Authorization: `Bearer ${authToken}` } }
    );
  }

  const updateLevel = (body) => {
    const levelId = body.id
    delete body.id
    return axios.put(
      `${process.env.REACT_APP_API_PATH}/curriculum/api/levels/${levelId}/`,
      body,
      { headers: { Authorization: `Bearer ${authToken}` } }
    );
  }

  const updateLevelBuilder = (id, body) => {
    return axios.put(
      `${process.env.REACT_APP_API_PATH}/curriculum/api/levels/${id}/`,
      body,
      { headers: { Authorization: `Bearer ${authToken}` } }
    );
  }

  return {
    fetchMyLevels,
    fetchLevel,
    fetchBuilderLessons,
    saveLevel,
    updateLevel,
    updateLevelBuilder
  }
}

export default useBuilderApi;