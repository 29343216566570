import React from "react";
// import Avatar from "@mui/material/Avatar";
// import AvatarGroup from "@mui/material/AvatarGroup";
import { Box } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useQuery } from "react-query";
import useUserApi from "api/user";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { LoadingScreen } from "../../curriculum/Components/LoadingScreen";
// import { ErrorScreen } from "../../curriculum/Components/ErrorScreen";
const OnlineAvatars = () => {
  const matches = useMediaQuery("(min-width:600px)");

  const { fetchOnlineStudents } = useUserApi();
  const { isLoading, isError, data, error } = useQuery(
    "onlineStudents",
    () => fetchOnlineStudents(),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (data) {
    // console.log("online Students==> ", data);
  }

  if (isLoading) {
    return <div style={{ width: "30vw" }}></div>;
  }

  if (isError) {
    return (
      // <ErrorScreen error={error} />
      <Box></Box>
    );
  }
  return (
    <Box
      style={{
        textAlign: "right",
      }}
    >
      <Typography
        style={{
          color: "#7B8794",
          fontSize: "14px",
          marginBottom: "10px",
          fontWeight: "600",
        }}
      >
        {`${data.data.length > 0 ? `${data.data}` : "No users are online"}`}
      </Typography>
      {/* {data.data.length > 0 && (
        <AvatarGroup max={matches ? 5 : 3}>
          {data.data.map((onlineStudent) => (
            <Avatar
              alt={onlineStudent?.first_name}
              src={onlineStudent.icon}
              style={{
                border: "2px solid #6358A5",
                background: "#fff",
                boxShadow: "0 1px 3px hsla(0, 0%, 0%, 0.2)",
              }}
            />
          ))}
        </AvatarGroup>
      )} */}
    </Box>
  );
};

export default OnlineAvatars;
