import React, { useState } from "react";
import { useQuery } from "react-query";
import useUserApi from "api/user";
// import ClassCard from "./ClassCard";
// import CreateClassButton from "./CreateClassButton";
// import WindowRoundedIcon from "@mui/icons-material/WindowRounded";
import GridViewSharpIcon from "@mui/icons-material/GridViewSharp";
// import AppsIcon from "@mui/icons-material/Apps";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
// import { OutlinedInput } from "@material-ui/core";

// import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
// import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
// import ArrowDropUpCircleIcon from "@mui/icons-material/ArrowDropUpCircle";
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";

import ClassesComponentSkeleton from "./skeletons/ClassesComponentSkeleton";
import DashboardEmptyState from "./DashboardEmptyState";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CreateClassButton from "./CreateClassButton";
// import ReorderRoundedIcon from "@mui/icons-material/ReorderRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClassesGridComponent from "./ClassesGridComponent";
import ClassesColumnComponent from "./ClassesColumnComponent";
import ClassesListComponent from "./ClassesListComponent";
import ControlPanelComp from "./ControlPanelComp";
import AnimateHeight from "react-animate-height";

// import { Link } from "react-router-dom";
// import ClassCardSkeleton from "./ClassCardSkeleton";

const Header = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        margin: "10px 0 30px",
      }}
    >
      <h3
        style={{
          fontSize: "24px",
          textTransform: "none",
          fontFamily: "Open Sans",
          color: "#7B8794",
          fontWeight: "400",
          margin: "0",
        }}
      >
        Classes
      </h3>

      <div>
        <ControlPanelComp />
      </div>
    </div>
  );
};

const ClassesComponent = () => {
  //   const [filteredUsers, setFilteredUsers] = React.useState({});
  const [view, setView] = useState("list");
  const [viewAll, setViewAll] = useState(true);
  const [height, setHeight] = React.useState("auto");

  const { fetchClasses, addClass, fetchTeachers, fetchStudents, fetchSchool } =
    useUserApi();
  const {
    data: classesData,
    status: classesStatus,
    isLoading: classesLoading,
  } = useQuery("classes", () => fetchClasses(), {
    refetchOnWindowFocus: false,
  });

  if (classesData) {
    console.log("classesData :", classesData?.data);
    console.log("classesStatus :", classesStatus);
  }
  const [searchWord, setSearchWord] = useState("");

  if (classesLoading) {
    return (
      <>
        <Header />
        <ClassesComponentSkeleton />
      </>
    );
  }

  const Icon = (
    <CreateNewFolderIcon
      style={{
        width: "80px",
        height: "80px",
        color: "#f8f9fe",
        backgroundColor: "#bdbdbd",
        padding: "1.2rem",
        borderRadius: "50%",
        marginBottom: "1rem",
      }}
    />
  ); // TODO: Sync With Ashraf on whether classesData.data.length changes when adding class!! - Test this out

  if (classesStatus === "success" && classesData.data.length === 0) {
    return (
      <div>
        <Header />
        <DashboardEmptyState
          icon={Icon}
          title="Create your first class"
          text="Your classes will appear here when created"
          button={<CreateClassButton />}
        />
      </div>
    );
  }

  //   const handleChange = (event) => {
  //     setSearchTerm(event.target.value);
  //   };

  if (classesStatus === "success" && classesData.data.length > 0) {
    const filterList = (item, array) => {
      return searchWord.length > 0
        ? item.name &&
            item.name.toLowerCase().indexOf(searchWord.toLowerCase()) > -1
        : array;
    };
    return (
      <div>
        <Header />
        <div
          style={{
            // height: viewAll === false ? "260px" : "100%",
            padding: "20px 20px",
            border: "0px solid  #05649D",
            //   viewAll === !false ? "4px solid  #05649D" : " 4px solid #6357A5",
            borderRadius: "8px",
            // boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
            boxShadow: "0 5px 15px hsla(0,0%, 0%, .2)",
            transition: "height 0.2s ease-out",
            // background: "rgb(255, 255, 255,0.5)",
            background: "rgb(255, 255, 255,1)",
          }}
        >
          <div style={{}}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "26px",
                padding: "0 8px",
                // marginRight: "0",
              }}
            >
              <input
                style={{
                  border: "1px solid #CBD2D9",
                  // height: "34px",
                  fontSize: "1.125rem",
                  marginRight: "10px",
                  padding: "6px 9px",
                  borderRadius: "8px",
                  width: "260px",
                }}
                placeholder="Search class name..."
                value={searchWord}
                onChange={(e) => setSearchWord(e.target.value)}
              ></input>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <button
                  style={{
                    background:
                      view === "grid" ? "#05649D" : "rgb(169,169,169)",
                    color: "white",
                    // fontSize: "30px",
                    border: "none",
                    margin: "0 5px",
                    borderRadius: "4px",
                    padding: "2px",
                    // cursor: gridView ? "pointer" : "not-allowed",
                  }}
                  onClick={() => setView("grid")}
                  // disabled={!gridView}
                >
                  <GridViewSharpIcon
                    style={{
                      padding: 0,
                      margin: "0",
                      // background: gridView ? "rgb(169,169,169)" : "#05649D",
                      // color: "white",
                      fontSize: "30px",
                    }}
                  />
                </button>
                <button
                  style={{
                    background:
                      view === "column" ? "#05649D" : "rgb(169,169,169)",
                    color: "white",
                    // fontSize: "30px",
                    border: "none",
                    margin: "0 5px",
                    borderRadius: "4px",
                    padding: "2px",
                    cursor: "pointer",
                    // cursor: !gridView ? "pointer" : "not-allowed",
                  }}
                  // onClick={() => setView("list")}
                  onClick={() => setView("column")}
                  // disabled={gridView}
                >
                  <ViewWeekIcon
                    style={{
                      padding: "0",
                      margin: "0",
                      fontSize: "30px",
                    }}
                  />
                </button>
                <button
                  style={{
                    background:
                      view === "list" ? "#05649D" : "rgb(169,169,169)",
                    color: "white",
                    // fontSize: "30px",
                    border: "none",
                    margin: "0 5px",
                    borderRadius: "4px",
                    padding: "2px",
                    // cursor: gridView ? "pointer" : "not-allowed",
                  }}
                  onClick={() => setView("list")}
                  // onClick={() => setGridView(false)}
                  // disabled={!gridView}
                >
                  <ViewListRoundedIcon
                    style={{
                      padding: "0",
                      margin: "0",
                      // background: gridView ? "rgb(169,169,169)" : "#05649D",
                      // color: "white",
                      fontSize: "30px",
                    }}
                  />
                </button>

                <button
                  style={{
                    border: "none",
                    padding: "4px 9px",
                    margin: "4px",
                    borderRadius: "4px",
                    color: "white",
                    background: viewAll ? "#05649D" : "#05649D",
                    // height: "34px",
                    width: "145px",
                    display: "flex",
                    fontSize: "1rem",
                    //   flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    //   gap: "30px",
                  }}
                  // onClick={() => setGridView("column")}
                  onClick={() => (
                    setViewAll((prev) => !prev),
                    setHeight(height === 250 ? "100%" : 250)
                  )}
                >
                  <p style={{ margin: "0 0 0 4px", fontWeight: "500" }}>
                    {viewAll === false ? "View more" : "View less"}
                  </p>
                  {viewAll === false ? (
                    <KeyboardArrowDownIcon sx={{ fontSize: 30 }} />
                  ) : (
                    <KeyboardArrowUpIcon sx={{ fontSize: 30 }} />
                  )}
                </button>
              </div>
            </div>
            <AnimateHeight
              id="example-panel"
              duration={600}
              easing="ease"
              height={height} // see props documentation below
            >
              <div
                style={{
                  margin: "0",
                  padding: "0",
                  // height: viewAll === false ? "280px" : "100%",
                  overflow: "auto",
                }}
              >
                {view === "grid" && (
                  <ClassesGridComponent
                    data={classesData.data}
                    searchWord={searchWord}
                  />
                )}

                {view === "list" && (
                  <ClassesListComponent
                    data={classesData.data}
                    searchWord={searchWord}
                  />
                )}
                {view === "column" && (
                  <ClassesColumnComponent
                    data={classesData.data}
                    searchWord={searchWord}
                  />
                )}
              </div>
            </AnimateHeight>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default ClassesComponent;
