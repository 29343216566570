import styled from "styled-components";

export const StudentHomePageWrapper = styled.div`
  padding: 80px 20px 0 0;
  width: 95%;
  max-width: 1280px;
  margin: auto;
  .image-landing {
    scrollbar-width: thin;
    scrollbar-color: #6b479c #b99edf;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      background: #6a479c1b;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #6b479c;
      border-radius: 20px;
      /* border: 3px solid #ad1010; */
    }
  }
  @media (max-width: 600px) {
    width: 85%;
    /* width: 100%; */
  }
`;
