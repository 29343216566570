import React from "react";
import H5PTestingLearnObjItem from "./H5PTestingLearnObjItem";

const H5PTestingLearnObj = ({
  learningData,
  setSelectedLearningObjective,
  selectedLearningObjective,
  questionsData,
}) => {
  const [searchWord, setSearchWord] = React.useState("");

  const filterList = (item, array) => {
    return learningData.length > 0
      ? item.objective &&
          item.objective.toLowerCase().indexOf(searchWord.toLowerCase()) > -1
      : array;
  };
  return (
    <div>
      <div
        style={{
          padding: "5px",
          border: "1px solid rgba(0,0,0,0.1)",
          borderRadius: "12px",
        }}
      >
        <h3
          style={{
            padding: "5px 10px",
            border: "1px solid rgba(0,0,0,0.1)",
            borderRadius: "12px",
            background: "rgba(0,0,0,0.06)",
          }}
        >
          Learning Objectives
        </h3>
        <div style={{ margin: "2px 0px" }}>
          {/* <span>Search: </span> */}
          <input
            style={{
              border: "1px solid #cad1d7",
              padding: "5px",
              width: "100%",
              borderRadius: "12px",
              margin: "2px 0px 12px",
            }}
            placeholder="Search learning objective"
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "80vh",
            overflow: "auto",
            width: "540px",
          }}
        >
          {learningData
            ?.filter((item, key, array) => filterList(item, array))
            ?.map((item, index) => (
              <div>
                <H5PTestingLearnObjItem
                  item={item}
                  index={index}
                  setSelectedLearningObjective={setSelectedLearningObjective}
                  selectedLearningObjective={selectedLearningObjective}
                  questionsData={questionsData}
                />

                <hr
                  style={{
                    margin: "5px",
                    padding: "",
                  }}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default H5PTestingLearnObj;
