import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const arr1 = new Array(9).fill(0);
const ManageTeachersAndStudentsSkeleton = () => {
  return (
    <div
      style={{
        margin: "50px 0 0",
        padding: "30px 30px",
        border: "0px solid rgb(31, 110, 163)",
        borderRadius: "8px",
        justifyContent: "space-between",
        boxShadow: "0 5px 15px hsla(0,0%, 0%, .2)",
        background: "rgb(255, 255, 255,0.5)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Skeleton count={1} width={180} height={28} />
        <Skeleton count={1} width={240} height={28} />
      </div>

      <div
        style={{
          boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              background: "#05649D",
              color: "white",
              //   borderRadius: "199px",
              overflow: "hidden",

              padding: "10px",
              //   margin: "10px 0",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "30%",
                // justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Skeleton count={1} width={120} height={18} />
            </div>
          </div>
        </div>
        <div
          style={{
            height: "650px",
            overflow: "auto",
          }}
        >
          {arr1.map((teacher) => {
            return (
              <div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "rgb(255, 255, 255,1)",
                    borderRadius: "199px",
                    padding: "10px 20px",
                    margin: "10px 0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "85%",
                      // justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton
                      count={1}
                      width={39}
                      height={39}
                      circle={true}
                      style={{ marginRight: "20px" }}
                    />

                    <Skeleton count={1} width={120} height={20} />
                  </div>
                  <Skeleton count={1} width={120} height={20} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ManageTeachersAndStudentsSkeleton;
