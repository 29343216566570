import React from 'react';
import clsx from "clsx";
import FormGroup from "@material-ui/core/FormGroup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Box } from "@material-ui/core";


function NameInput({
    editedClassName,
    classes,
    customValidationName,
    setEditedClassName,
    setCustomValidationName,

}) {
    return (
        <>
            <FormGroup style={{ margin: 5 }}>
                <FormLabel
                    style={{
                        fontSize: 14,
                        color: "#52606D",
                        fontFamily: 'Montserrat,sans-serif'
                    }}
                >
                    Class Name
                </FormLabel>
                <OutlinedInput
                    type='text'
                    placeholder={editedClassName}
                    value={editedClassName}
                    style={{ border: "1px solid #CBD2D9", color: "grey" }}
                    classes={{
                        notchedOutline: clsx({
                            [classes.borderWarning]:
                                customValidationName === "required",
                            [classes.borderSuccess]:
                                customValidationName === "valid",
                        }),
                    }}
                    onChange={(e) => {
                        setEditedClassName(e.target.value);
                        if (e.target.value === "") {
                            setCustomValidationName("required");
                        } else {
                            setCustomValidationName("valid");
                        }
                    }}
                />
                {customValidationName === "valid" ? (
                    <FormHelperText
                        component={Box}
                        style={{ color: "#2dce89" }}
                    >
                        Looks good!
                    </FormHelperText>
                ) : (
                    <FormHelperText
                        component={Box}
                        style={{ color: "#fb6340", marginLeft: 3 }}
                    >
                        Class Name is required.
                    </FormHelperText>
                )}
            </FormGroup>
        </>
    );
}

export default NameInput;