import React from "react";
import Iframe from "react-iframe";

const GearsPage = () => {
  return (
    <div style={{ width: "auto", height: "90vh" }}>
      <Iframe
        width="100%"
        height="100%"
        src="https://gears.aposteriori.com.sg"
      />
    </div>
  );
};

export default GearsPage;
