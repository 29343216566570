import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const ClassCardColumnSkeleton = () => {
  return (
    <div
      style={{
        background: "white",
        borderRadius: "8px",
        padding: "20px ",
        width: "220px",
        boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
      }}
    >
      <div
        style={{
          marginBottom: "8px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Skeleton count={1} width={96} height={22} />
        <Skeleton count={1} width={40} height={22} />
      </div>
      <div style={{ width: "100%" }}>
        <Skeleton count={1} width={150} height={27} />
        <Skeleton count={1} width={150} height={27} />
      </div>
      <div
        style={{
          margin: "20px 0",
          display: "grid",
          gap: "30px",
          gridTemplateColumns: "1fr 1fr 1fr",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateRows: "1fr",
            gap: "8px",
          }}
        >
          <Skeleton count={1} width={180} height={50} />
          <Skeleton count={1} width={180} height={50} />
          <Skeleton count={1} width={180} height={50} />
        </div>
      </div>
      <div style={{ width: "35%", marginTop: "10px" }}>
        <Skeleton count={1} width={98} height={28} />
      </div>
    </div>
  );
};

export default ClassCardColumnSkeleton;
