import React from "react";
import ActivityCurriculumLessonPlayer from "./ActivityCurriculumLessonPlayer";
const ActivityCurriculumPlayer = ({
  exerciseName,
  // stageNumber,
  // messageNoLesson,
  // setMessageNoLesson,
  handleClose,
  lessonId,
  // lessonIs,
  lessonResourcesData,
  classModal,
  isLoadingResources,
}) => {
  if (lessonId) {
    return (
      <div
        style={{
          width: "100%",
          height: classModal ? "100%" : "90vh",
          margin: classModal ? "0" : "30px auto 0",
          background: "white",
          padding: "10px",
          boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* {lessonResourcesData.length > 0 && ( */}
          <ActivityCurriculumLessonPlayer
            exerciseName={exerciseName}
            handleClose={handleClose}
            lessonResourcesData={lessonResourcesData}
            classModal={classModal}
            isLoadingResources={isLoadingResources}
          />
          {/* )} */}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ActivityCurriculumPlayer;
