import React from "react";
import './Style/style.css'
import SchoolMainCardLayout from "views/eai/datasite/SchoolPage/SchoolMainCardLayout";
import AddCurriculum from "./AddCurriculum";
import CurriculumHomePage from '../CurriculumHomePage'


function XSubjectsPage() {

    return (
        <div style={{ maxWidth: 1280, display: 'flex', justifyContent: 'center', margin: '0px auto', flexDirection: 'column' }}>
            <SchoolMainCardLayout
                boxShadow='none'
                name={`My Lesson Plans`}
                background="transparent"
                headerBG="transparent"
                bodyBG="transparent"
                width='100%'
                headerBoxShadow=""
                justifyContent="flex-start"
                paddingLeft="10px"
                paddingRight="10px"
                paddingLeftHeader="20px"
                paddingBottom="0px"
                paddingTopContent="0px"
                margin="30px 0px 0px"
                component={<AddCurriculum />}
            />
            <CurriculumHomePage />
        </div>
    )
}

export default XSubjectsPage;




