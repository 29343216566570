import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import componentStyles from "assets/theme/views/admin/alternative-dashboard.js";
import useUserApi from "api/user";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
// import { generateReport } from "utils/report";
import { LoadingScreen } from "views/eai/curriculum/Components/LoadingScreen";
import StudentLearningObj from "./DataSiteComponents/DataSiteStudentPage/StudentLearningObj";
import StudentCompThinkScore from "./DataSiteComponents/DataSiteStudentPage/StudentCompThinkScore";
import StudentProfileX from "./DataSiteComponents/DataSiteStudentPage/StudentProfileX";
import StudentProgressX from "./DataSiteComponents/DataSiteStudentPage/StudentProgressX";
// import StudentClasses from "./DataSiteComponents/DataSiteStudentPage/StudentClasses";

const useStyles = makeStyles(componentStyles);

const DataSiteStudentPage = () => {
  const classes = { ...useStyles() };
  const params = useParams();
  const { fetchStudent } = useUserApi();

  const {
    data: student,
    status: studentStatus,
    loading: isLoadingStudent,
  } = useQuery(
    ["student", params.studentId],
    () => fetchStudent(params.studentId),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoadingStudent) return <LoadingScreen />;

  return (
    <Box sx={{ maxWidth: 1500, margin: "auto" }}>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="60px"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12}>
            <StudentProfileX data={student} status={studentStatus} loading={isLoadingStudent} params={params} />
          </Grid>
          <Grid item lg={9} xs={12} className="studentLearningObjectve">
            <StudentLearningObj params={params} />
          </Grid>
          <Grid item lg={3} xs={12} className="studentCompThinking">
            <StudentCompThinkScore params={params} />
          </Grid>
          {/* <Grid item lg={3} xs={12} className="studentClasses">
            <StudentClasses params={params} />
          </Grid> */}
          <Grid item xs={12} className="studentProgress">
            <StudentProgressX params={params} student={student} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DataSiteStudentPage;
