import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import useCurriculumApi from "api/curriculum";
import { useQuery } from "react-query";
import ClassCurriculumPlayerTab from "./ClassCurriculumPlayerTab";

const ClassCurriculumLessonPlayer = ({
  exerciseName,
  lessonResourcesData,
  lessonName,
}) => {
  const { fetchResource } = useCurriculumApi();
  const [urlIframeMain, setUrlIframeMain] = useState("");
  const [urlIframeNew, setUrlIframeNew] = useState({});
  const [currentUrl, setCurrentUrl] = useState(null);

  useEffect(() => {
    if (lessonResourcesData.length > 0) {
      setUrlIframeMain(lessonResourcesData);
    }
  }, [lessonResourcesData]);

  useEffect(() => {
    if (urlIframeMain) {
      if (currentUrl === null) {
        setCurrentUrl(urlIframeMain[0].id);
      }
    }
  }, [urlIframeMain]);

  const {
    data: dataFrame,
    status: statusFrame,
    isLoading: isLoadingFrame,
    isError: isErrorFrame,
    error: errorFrame,
  } = useQuery(
    ["viewResource", currentUrl],
    () => currentUrl && fetchResource(currentUrl),
    { enabled: currentUrl != "", refetchOnWindowFocus: false }
  );

  const urlFrame =
    statusFrame === "success" && dataFrame && dataFrame?.data?.url;

  document.cookie =
    statusFrame == "success" &&
    `AuthToken=${dataFrame?.data?.jwt};max-age=604800;domain=educationai.co.uk;path=/`;
  document.cookie =
    statusFrame == "success" &&
    `sas="${dataFrame?.data?.sas}";max-age=604800;domain=educationai.co.uk;path=/`;
  if (urlFrame) {
    return (
      <>
        <div style={{ margin: "0 0 6px 0" }}>
          {urlIframeMain.length > 1 && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* {lessonName && ( */}
              <h1 style={{ margin: "0", padding: "0", fontSize: "18px" }}>
                {/* {lessonName} */}
              </h1>
              {/* // )} */}
              <div style={{ display: "flex", alignItems: "end" }}>
                {urlIframeMain.map((item) => (
                  <ClassCurriculumPlayerTab
                    item={item}
                    currentUrl={currentUrl}
                    setCurrentUrl={setCurrentUrl}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <div style={{ height: "100%", background: "white" }}>
          <Iframe
            url={urlFrame}
            width="100%"
            height="100%"
            allow="fullscreen"
            borderRadius="5px"
            style={{ background: "white" }}
          />
        </div>
      </>
    );
  } else if (isLoadingFrame) {
    return (
      <div
        style={{
          width: "100%",
          // margin: "auto auto",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1
          style={{
            margin: "0 auto 0",
            textAlign: "center",
            width: "80%",
            height: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0,0,0,0.04)",
            borderRadius: "12px",
          }}
        >
          Loading..
        </h1>
      </div>
    );
  } else {
    // handleClose();
    return (
      <div
        style={{
          width: "100%",
          // margin: "auto auto",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1
          style={{
            margin: "0 auto 0",
            textAlign: "center",
            width: "80%",
            height: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0,0,0,0.04)",
            borderRadius: "12px",
          }}
        >
          No lesson available
        </h1>
      </div>
    );
  }
};

export default ClassCurriculumLessonPlayer;
