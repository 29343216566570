import React from "react";
// @material-ui/core components
import usePersistStore from "../../../zustandPersist.js";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import CurriculumCard from "components/Cards/eai/CurriculumCard";
import { CurriculumSection } from "./Components/CurriculumSection";
import { LoadingScreen } from "./Components/LoadingScreen";
import { ErrorScreen } from "./Components/ErrorScreen";
import { CurriculumPage } from "./CurriculumPage";
import useCurriculumApi from "api/curriculum";

function OfflineLevelsPage() {
  const params = useParams();
  const { permission } = usePersistStore();

  const { fetchOfflineLevels } = useCurriculumApi();
  const { isLoading, isError, data, error } = useQuery(
    ["offlineLevels", params.courseId],
    () => fetchOfflineLevels(params.courseId),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <ErrorScreen error={error} />;
  }
  const sections = [];
  for (const level of data.data) {
    const cards = [];
    for (const resource of level.files) {
      cards.push(
        <CurriculumCard
          title={resource.name}
          subtitle={resource.type.name}
          image={resource.image}
          link={`/curriculum/resources/${resource?.id}/`}
          blur="0"
        />
      );
    }
    sections.push(
      <CurriculumSection key={level?.id} name={level.name} cards={cards} />
    );
  }
  return <CurriculumPage name="Offline Levels" sections={sections} />;
}

export default OfflineLevelsPage;
