// import useUserApi from "api/user";
import React from "react";
import CurriculumHomePage from "../curriculum/CurriculumHomePage";
// import { useQuery } from "react-query";
// import AvailableCurriculums from "./DataSiteComponents/DataSiteHomePage/AvailableCurriculums";
import ClassesComponent from "./DataSiteComponents/DataSiteHomePage/ClassesComponent";
import DataSiteHeader from "./DataSiteComponents/DataSiteHomePage/DataSiteHeader";
import ManageStudentsComponents from "./DataSiteComponents/DataSiteHomePage/ManageStudentsComponents";
import ManageTeachersComponent from "./DataSiteComponents/DataSiteHomePage/ManageTeachersComponent";
import SchoolStatsComponent from "./DataSiteComponents/DataSiteHomePage/SchoolStatsComponent";

const DataSiteHomePage = () => {
  //   const { fetchClasses, addClass, fetchTeachers, fetchStudents, fetchSchool } =
  //     useUserApi();
  //   const {
  //     data: classData,
  //     status: classStatus,
  //     isLoading: classLoading,
  //   } = useQuery(["classes"], () => fetchClasses());
  return (
    <div
      style={{
        maxWidth: "1380px",
        width: "95%",
        margin: "60px auto",
      }}
    >
      <DataSiteHeader />
      <ClassesComponent />

      {/* <AvailableCurriculums /> */}
      <CurriculumHomePage />

      <SchoolStatsComponent />
      <div
        style={{
          display: "flex",
          gap: "20px",
        }}
      >
        <div
          style={{
            width: "50%",
          }}
        >
          <ManageTeachersComponent />
        </div>
        <div
          style={{
            width: "50%",
          }}
        >
          <ManageStudentsComponents />
        </div>
      </div>
    </div>
  );
};

export default DataSiteHomePage;
