import { Button, IconButton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import themeColors from "assets/theme/colors";

const CustomCurriculumCard = ({ id, title, image, onDelete, link, }) => {

    return (
        <div
            style={{
                width: "350px",
                background: "rgba(31, 110, 163,0.05)",
                color: "white",
                padding: "14px ",
                borderRadius: "8px",
                boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                display: "flex",
                alignItems: 'center',
                cursor: "pointer",
                marginRight: 20,
                marginBottom: 20,
                position: 'relative',
                flexDirection: 'row'
            }}
        >
            <div style={{ position: "absolute", zIndex: 100, right: -15, top: -15 }}>
                <IconButton
                    style={{ color: themeColors.secondary.main, backgroundColor: "#eee" }}
                    size="small"
                    onClick={() => onDelete(id, title)}
                >
                    <RemoveCircleIcon />
                </IconButton>
            </div>

            <img
                src={image}
                alt=''
                srcset=''
                style={{
                    width: "145px",
                    height: "80px",
                    // maxWidth: "79px",
                    borderRadius: "12px",
                    marginRight: "8px",
                }}
            />
            <div>
                <h5
                    style={{
                        fontSize: "14px",
                        padding: "0px 12px 6px 6px",
                        margin: 0
                    }}
                >
                    {title?.length > 17 ? title?.slice(0, 17) + '...' : title}
                </h5>
                <Link to={link}>
                    <Button
                        style={{
                            margin: 5, color: 'rgb(6, 101, 157)',
                            border: '1px solid rgb(6, 101, 157)',
                            textTransform: 'none'
                        }}
                        variant="outlined"
                        size="small"
                        color="primary"
                    >
                        View
                    </Button>
                </Link>
                <Link to={`/builder/${id}`}>
                    <Button
                        style={{
                            margin: 5, textTransform: 'none',
                            background: 'rgb(6, 101, 157)',
                            border: 'none', boxShadow: 'none'
                        }}
                        variant="contained"
                        size="small"
                        color="primary"
                    >
                        Edit
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default CustomCurriculumCard;
