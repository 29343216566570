import React from "react";
import {
  useLocation,
  // Route,
  Switch,
  Redirect,
} from "react-router-dom";
// import useCurriculumApi from "api/curriculum";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import componentStyles from "assets/theme/layouts/admin.js";
import { createTheme } from "@mui/material/styles";
import DataSiteStudentPage from "views/eai/datasite/DataSiteStudentPage";
import { Container } from "@mui/material";
import CreateTest from "../views/eai/datasite/components/CreateTest/Test";
// import DataSiteSchoolPage from "../views/eai/datasite/SchoolPage/SchoolPage";
import ProtectedRouteClass from "./ProtectedRouteClass";
import ProtectedRouteManagePages from "./ProtectedRouteManagePages";
import ProtectedRouteSchool from "./ProtectedRouteSchool";
import ProtectedRouteStudent from "layouts/ProtectedRouteStudent";
import DataSiteHomePage from "views/eai/datasite/DataSiteHomePage";
import DataSiteClassPage from "views/eai/datasite/DataSiteClassPage";
import DataSiteManageStudents from "views/eai/datasite/DataSiteManageStudents";
import DataSiteManageTeachers from "views/eai/datasite/DataSiteManageTeachers";
import usePersistStore from "../zustandPersist";
// import useCurriculumApi from "api/curriculum";
// import useCurriculumApi from "api/curriculum";

const useStyles = makeStyles(componentStyles);

const Datasite = () => {
  const { permission } = usePersistStore();
  const classes = useStyles();
  const location = useLocation();
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainContent.current.scrollTop = 0;
  }, [location]);

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1736,
      },
    },
  });

  const getRoutes = () => {
    return [
      <ProtectedRouteSchool path="/data/school" component={DataSiteHomePage} />,
      
      <ProtectedRouteClass
        path="/data/class/:classId"
        component={DataSiteClassPage}
        name="Data"
      />,

      <ProtectedRouteStudent
        path="/data/student/:studentId"
        component={DataSiteStudentPage}
      />,

      <ProtectedRouteManagePages
        path="/data/students"
        component={DataSiteManageStudents}
      />,

      <ProtectedRouteManagePages
        path="/data/teachers"
        component={DataSiteManageTeachers}
      />,
      <ProtectedRouteManagePages path="/data/test" component={CreateTest} />,
    ];
  };

  return (
    <Box display="flex">
      <Box
        position="relative"
        flex="1"
        className={classes.mainContent}
        style={{ padding: 0 }}
      >
        <AdminNavbar
          // openSidebarResponsive={() => setSidebarOpenResponsive(true)}
          logo={{
            innerLink: "/data/school",
            imgSrc: require("assets/img/EducationaiLogos/Group 1540.svg")
              .default,
            imgAlt: "...",
            className: "first-element",
          }}
          // buttons={[
          //   {
          //     name: "Manage Students",
          //     innerLink: "/data/students",
          //     className: "third-element",
          //     title: "This page is for managing students",
          //     text: "Add and Delete students from the list!",
          //   },
          //   {
          //     name: "Manage Teachers",
          //     innerLink: "/data/teachers",
          //     className: "forth-element",
          //     title: "This page is for managing teachers",
          //     text: "Add and Delete teachers from the list!",
          //   },
          // ]}
          // dropdown={[<YearButton data={year} name='Year' />, <YearButton data={stage} name='Stage' />]}
          account={permission && permission.type}
        />

        <Box
          theme={theme}
          sx={{
            minHeight: { sm: "85vh", xl: "88vh" },
            marginBottom: { sm: "60px", xl: "90px" },
          }}
        >
          <Switch>
            {getRoutes()}
            <>
              <Redirect exact from="/data" to={`/data/school/`} />
            </>
          </Switch>
        </Box>
        <Container
          classes={{ root: classes.containerRoot }}
          style={
            {
              // margin: 0,
              // maxWidth: "100vw",
              // position: "absolute",
              // bottom: 0,
            }
          }
        >
          <AdminFooter />
        </Container>
      </Box>
    </Box>
  );
};

export default Datasite;
