import axios from "axios";

export const getCookieValue = (name) =>
  document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

export function deleteCookie(name, path, domain) {
  if (getCookie(name)) {
    document.cookie =
      name +
      "=" +
      (path ? ";path=" + path : "") +
      (domain ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

function getCookie(name) {
  return document.cookie.split(";").some((c) => {
    return c.trim().startsWith(name + "=");
  });
}

export function getTenantName() {
  let domain = window.location.hostname;
  const subdomain = domain.split(".")[0];
  return subdomain;
}

export function getMsalConfig(tenantId) {
  let domain = window.location.hostname;

  const subdomain = domain.split(".")[0];
  domain = domain.substring(domain.indexOf(".") + 1);
  document.cookie = `subdomain=${subdomain};domain=.educationai.co.uk;path=/`;

  if (window.location.port != "443") {
    domain += `:${window.location.port}`;
  }
  if (subdomain === "app") {
    return {
      auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        // clientId: "704195015273-64k5msqahdm0tjq347d6dimrc597g62s.apps.googleusercontent.com",
        authority: `https://login.microsoftonline.com/${tenantId}`,
        //redirectUri: `https://metis.${domain}${process.env.PUBLIC_URL}`,
        redirectUri: `https://${subdomain}.${domain}${process.env.PUBLIC_URL}`,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
      },
    };
  } else {
    return {
      auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${tenantId}`,
        //redirectUri: `https://metis.${domain}${process.env.PUBLIC_URL}`,
        redirectUri: `https://${subdomain}.${domain}${process.env.PUBLIC_URL}`,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
      },
    };
  }
}

export const keycloakConfig = {
  url: `${process.env.REACT_APP_KEYCLOAK_URL}/auth`,
  realm: "che",
  clientId: "che-public",
};

export function handleHashRedirect() {
  let domain = window.location.hostname;
  let redirectDomain = process.env.REACT_APP_DOMAIN;
  if (window.location.port != "443") {
    redirectDomain += `:${window.location.port}`;
  }

  let subdomain = domain.split(".")[0];
  console.log(domain);

  if (subdomain === "metis") {
    subdomain = getCookieValue("subdomain");
    const hash = window.location.hash;
    window.location.replace(
      `https://${subdomain}.${redirectDomain}${process.env.PUBLIC_URL}${hash}`
    );
  }
}

export function handleLogout(instance) {
  instance.logoutRedirect().catch((e) => {
    console.error(e);
  });
}

export function handleLogin(instance) {
  instance.loginRedirect(request).catch((e) => {
    console.error(e);
  });
}

export async function requestAccessToken(instance, account) {
  const loginRequest = {
    request,
    account,
  };

  // Silently acquires an access token which is then attached to a request for Microsoft Graph data
  try {
    const token = instance.acquireTokenSilent(loginRequest);
    return token;
  } catch {
    return false;
  }

  return false;
}

export const request = {
  scopes: ["User.Read", "User.ReadWrite.All", "Directory.Read.All"],
  loginHint: getCookieValue("preferred_username").replace(/^"(.*)"$/, "$1"),
};

function isStringOnlyLetters(str) {
  const regex = /^[a-zA-Z]+$/; // Regular expression to match only letters (lowercase or uppercase)
  return regex.test(str);
}

export async function getTenantId() {
  const domain = window.location.hostname;
  const subdomain = domain.split(".")[0];
  if (isStringOnlyLetters(subdomain)) {
    const apiUrl = `${process.env.REACT_APP_API_PATH}/users/api/school_tenant/${subdomain}`;
    const result = await axios.get(apiUrl);
    return result.data.id;
  }
}

export function loginBlocks() {
  // let domain = window.location.hostname;
  // const subdomain = domain.split(".")[0];
  // let returnDomain = process.env.REACT_APP_DOMAIN;
  // if (window.location.port != "443") {
  //   returnDomain += `:${window.location.port}`;
  // }
  // console.log(returnDomain);
  // window.location.href = `https://${subdomain}.learn.${process.env.REACT_APP_BLOCKS_DOMAIN}/api/auth/login?returnUrl=${window.location.href}`;
}
