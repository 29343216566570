import React from "react";

// materialUI icons
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

// materialUI
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
// import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// // import images
// import Temp from "../assets/temp.png";
// // import { relative } from 'path';
// import Castle from "../assets/castle.png";
// import Award from "../assets/award.png";

// ----------------
export const StudentStatusCard = ({
  title,
  stage,
  completion,
  currentTask,
  date,
  image,
  bgC,
  matches,
}) => {
  return (
    <Card
      sx={{
        backgroundColor: bgC,
        position: "relative",
        width: ` ${matches ? "30%" : "100%"}`,
        overflow: "visible",
        mt: "2",
        mb: "2",
        pr: "0.7rem",
        pl: "0.7rem",
        boxShadow: " 0 10px 15px hsla(0, 0%, 0%, 0.096)",
      }}
    >
      <CardMedia
        component="img"
        sx={{
          width: ` ${matches ? "25%" : "80px"}`,
          position: "absolute",
          top: "0px",
          right: "0px",
          transform: "translate(-3%,-20%)",
        }}
        style={{
          zIndex: 1,
          // maxHeight: "100px",
        }}
        // height='40'
        image={image}
        alt=""
      />
      <CardContent
      // sx={{
      //     display: 'flex',
      //     justifyContent: 'space-around',
      //     flexDirection: 'column',
      // }}
      >
        <Typography
          color="#32325D"
          gutterBottom
          variant="h6"
          component="div"
          style={{
            width: "80%",
          }}
        >
          {title}
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "800" }}>
            {stage}
          </Typography>
          <Typography
            variant="p"
            style={{
              backgroundColor: "white",
              borderRadius: "20px",
              padding: "6px 12px ",
              marginLeft: "10px",
              fontSize: "14px",
              // color: `${completion > 49 ? '#27AE60' : '#EB5757'}`,
            }}
          >
            {/* {completion > 49 ? (
              <ArrowDropUpIcon
                sx={{
                  color: `${completion > 49 ? "#27AE60" : "#EB5757"}`,
                }}
              />
            ) : (
              <ArrowDropDownIcon
                sx={{
                  color: `${completion > 49 ? "#27AE60" : "#EB5757"}`,
                }}
              />
            )}{" "} */}
            {completion}
          </Typography>
        </Box>

        {/* <Typography
          variant="body2"
          sx={{ mt: 1, mb: 1, fontWeight: "300", fontSize: "1rem" }}
        >
          <NotificationsNoneIcon sx={{ mr: 1 }} /> {currentTask}
        </Typography> */}
        {/* <Typography
                    variant='body2'
                    sx={{ mt: 1, fontWeight: '300', fontSize: '1rem' }}
                >
                    <CalendarTodayIcon sx={{ mr: 1 }} /> {date}
                </Typography> */}
      </CardContent>
    </Card>
  );
};
export default StudentStatusCard;
