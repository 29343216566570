import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useStore from "../../../../zustand";
import usePersistStore from "../../../../zustandPersist.js";
import { useQuery } from "react-query";
import useCurriculumApi from "api/curriculum";

export default function CategorySelector() {
  const { dataFromApi, setLessonFromClass } = useStore();
  const { setChosenLevel } = usePersistStore();
  const { fetchLevels } = useCurriculumApi();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { fetchLessonPlan } = useCurriculumApi();

  const { data: customLevels, isSuccess: isSuccessAll } = useQuery("myLevels", () => fetchLevels(), {
    refetchOnWindowFocus: false,
  });
  const {
    data: lessonPlanData,
    status: lessonPlanStatus,
    isError,
    isLoading,
  } = useQuery(["lessonPlanData"], () => fetchLessonPlan(), {
    refetchOnWindowFocus: false,
  });
  const customLevelId = isSuccessAll && lessonPlanData?.data?.custom_lesson_plans[0]?.id;

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => {
    setLessonFromClass(null); // we set it to null so that it chooses the first lesson when we select another path
    setChosenLevel(0);
    setAnchorEl(false);
  };

  let categoryData = [];
  dataFromApi?.map((item) => item.categories.map((course) => categoryData.push(course)));
  console.log("categoryData && categoryData :>> ", categoryData && categoryData);
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{
          background: "rgb(6, 101, 157)",
          color: "white",
          minWidth: 310,
          margin: 10,
          paddingLeft: "20px",
          paddingRight: "10px",
          textTransform: "none",
          fontSize: "1.0325rem",
          fontFamily: "Montserrat,sans-serif",
          fontWeight: "700px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {categoryData?.length > 0 &&
          categoryData.map((item) => {
            if (
              item.name === "Computational Thinking" &&
              location?.state?.from === "book" &&
              location.pathname.includes(`/categories/${item?.id}/courses`)
            ) {
              return item.name + " - Books";
            } else if (
              item.name === "Computational Thinking" &&
              location?.state?.from !== "book" &&
              location.pathname.includes(`/categories/${item?.id}/courses`)
            ) {
              return "Computational Thinking" + " - Lessons";
            } else if (location.pathname.includes(`/categories/${item?.id}/courses`)) {
              return item.name;
            }
          })}
        {location.pathname.includes(`/myLevels`) && `My Lesson Plans`}
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {categoryData?.length > 0 &&
          categoryData
            .filter(
              (item) =>
                item.name !== "Sample Category" &&
                item.name !== "Test Category" &&
                item.name !== "Custom" &&
                item.name !== "Raspberry Pi"
            )
            .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
            .map((item) => {
              if (item.name === "Computational Thinking") {
                return (
                  <div>
                    <Link
                      to={{
                        pathname: `/curriculum/categories/${item?.id}/courses/`,
                        state: { from: "book" },
                      }}
                    >
                      <MenuItem onClick={handleClose} style={{ color: "rgb(6, 101, 157)" }}>
                        {item.name} - Books
                      </MenuItem>
                    </Link>
                    <Link
                      to={{
                        pathname: `/curriculum/categories/${item?.id}/courses/`,
                        state: { from: "lessons" },
                      }}
                    >
                      <MenuItem onClick={handleClose} style={{ color: "rgb(6, 101, 157)" }}>
                        {item.name} - Lessons
                      </MenuItem>
                    </Link>
                  </div>
                );
              } else {
                return (
                  <Link
                    to={{
                      pathname: `/curriculum/categories/${item?.id}/courses/`,
                      state: { from: "lessons" },
                    }}
                  >
                    <MenuItem onClick={handleClose} style={{ color: "rgb(6, 101, 157)" }}>
                      {item.name}
                    </MenuItem>
                  </Link>
                );
              }
            })}
        <Link
          to={{
            pathname: `/curriculum/myLevels/${customLevelId}/lessons`,
            state: { from: "custom" },
          }}
        >
          <MenuItem onClick={handleClose} style={{ color: "rgb(6, 101, 157)" }}>
            My Lesson Plans
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
}
