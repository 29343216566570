import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  MuiThemeProvider,
} from "@material-ui/core";
import 'assets/css/myStyle.css'
import React from "react";
import { Link, useLocation } from "react-router-dom";
import useCurriculumApi from "api/curriculum";
import { useQuery } from "react-query";
import Avatar from '@mui/material/Avatar';
import { styled } from "@mui/material/styles";
import { createTheme } from "@mui/system";
import { CircularLoadingScreen } from "views/eai/activities/components/Loading";
import MoreInfoDescriptionPage from "./MoreInfoDescriptionPage";
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import MenuBookIcon from '@mui/icons-material/MenuBook';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    height: "85vh",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const useStyles = makeStyles(() => ({
  paper: { minWidth: "75vw" },
}));

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1936,
    },
  },
});


const MoreInfoModal = ({ levelId, open, setOpen, id, subtitle, title, link, lessonType }) => {
  const classes = useStyles();
  const location = useLocation()
  const { fetchLessons, fetchLevelResources } = useCurriculumApi();

  const [lessonItem, setLessonItem] = React.useState(null)
  const [lessonRecource, setLessonRecource] = React.useState(null)
  const [test, setTest] = React.useState(false)

  const { isLoading, data: lessonData, status: lessonStatus } = useQuery(
    ["fetchLessons", levelId],
    () => fetchLessons(levelId)
  );

  const { data: dataRecources, status: statusRecources } = useQuery(
    ["fetchRecources", levelId],
    () => fetchLevelResources(levelId)
  );

  const cRecources = statusRecources === 'success' && dataRecources?.data[0]
  const cLesson = lessonStatus === 'success' && lessonData?.data.filter(item => item.id === id)


  React.useEffect(() => {
    levelId && lessonStatus === 'success' && setLessonItem(lessonData?.data[0]);
  }, [lessonStatus])

  React.useEffect(() => {
    levelId && statusRecources === 'success' && setLessonRecource(cRecources);
  }, [statusRecources])

  return (
    <>
      <BootstrapDialog
        onClose={() => setOpen(false)}
        open={open}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth='xl'
        classes={{ paper: classes.paper }}
        disableEnforceFocus
      >
        <DialogContent style={{ overflowY: 'hidden', }}>
          <MuiThemeProvider theme={theme}>
            <h4 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderBottom: '2px solid #DADADA', width: '98%', paddingBottom: 15, marginBottom: 30, margin: '10px auto', color: '#1F2933', fontSize: 24, paddingLeft: 10, textAlign: 'center', fontFamily: 'Open Sans' }}>
              {lessonType !== 'offline' ?
                <CastForEducationIcon style={{ width: 50, height: 30, color: 'rgb(6, 101, 157)' }} />
                :
                <MenuBookIcon style={{ width: 50, height: 30, color: 'rgb(6, 101, 157)' }} />}
              {subtitle} {subtitle ? " - " + title : title}
            </h4>
            <Box style={{ padding: 10 }}>
              <Grid container>

                {location.pathname.includes('/curriculum/categories/') &&
                  <>
                    <Grid item xs={3} xl={2} style={{ marginTop: '0px', color: '#323F4B', }}>
                      <Box style={{ overflowY: 'scroll', height: 'calc(90vh - 150px)' }}>
                        {lessonType !== 'offline' ? (isLoading ? <CircularLoadingScreen /> : lessonData?.data?.map(item =>
                          <InsideView
                            key={item.id}
                            data={item}
                            id={item.id}
                            idForIframe={item.files[0]?.id}
                            lessonItem={lessonItem}
                            setLessonItem={setLessonItem}
                            setTest={setTest}
                            test={test}
                          />))
                          :
                          (<InsideView
                            key={cRecources.id}
                            id={cRecources.id}
                            idForIframe={null}
                            data={cRecources}
                            lessonItem={lessonRecource}
                            setLessonItem={setLessonItem}
                            setTest={setTest}
                            test={test}
                          />)
                        }
                      </Box>
                    </Grid>
                    <Grid item xs={9} xl={10}>
                      {lessonType !== 'offline' ?
                        <MoreInfoDescriptionPage
                          lessonItem={lessonItem}
                          useEffectDep={lessonItem}
                          lessonType={lessonType}
                          test={test}
                        />
                        :
                        <MoreInfoDescriptionPage
                          lessonItem={lessonRecource}
                          useEffectDep={lessonRecource}
                          lessonType={lessonType}
                          test={test}
                        />
                      }
                    </Grid>
                  </>
                }

                {(location.pathname.includes('/online/levels')
                  || location.pathname.includes('/curriculum/myLevels')
                  || location.pathname.includes('/builder')) &&
                  <Grid item xs={12} >
                    <MoreInfoDescriptionPage
                      lessonItem={cLesson && cLesson[0]}
                      useEffectDep={lessonStatus}
                      lessonType={lessonType}
                    />
                  </Grid>
                }
              </Grid>
            </Box>
          </MuiThemeProvider>
        </DialogContent>
        <DialogActions style={{ padding: 20 }}>
          <Link to={link}>
            <Button style={{ background: '#06659D', color: 'white', width: 102, height: 32, padding: 0 }}>View</Button>
          </Link>
          <Button onClick={() => setOpen(false)} style={{ color: '#06659D', border: '1px solid #06659D', width: 102, height: 32, padding: 0 }}>Close</Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default MoreInfoModal;


const InsideView = ({ data, setLessonItem, lessonItem, setTest, test }) => {

  return (
    <Box sx={{ marginBottom: 40, padding: 20 }}>
      <MuiThemeProvider theme={theme}>
        <h5 style={{ color: '#323F4B', marginTop: 0 }}>{data.name}</h5>
        <Avatar
          alt="Remy Sharp"
          class="card-hover"
          style={{ width: 228, height: 131.4, borderRadius: 6, margin: 'auto', transform: lessonItem?.id === data?.id ? 'scale(1.05)' : 'scale(1)', boxShadow: lessonItem?.id === data?.id ? 'rgba(30, 81, 123, 0.59) 0px 10px 20px, rgba(30, 81, 123, 0.63) 0px 6px 6px' : 'rgba(17, 17, 26, 0.1) 0px 1px 0px' }}
          onClick={() => (setLessonItem(data), setTest(!test))}
        >
          <img class="image-hover" src={data?.image} alt="icon" style={{ objectFit: 'cover', background: 'white', width: '100%', height: '100%', borderRadius: 6, opacity: lessonItem?.id === data?.id ? 1 : 0.5, cursor: 'pointer' }} />
        </Avatar>
      </MuiThemeProvider>
    </Box>
  )
}





