// import { IconButton } from "@material-ui/core";
import { PlayArrowOutlined } from "@mui/icons-material";
// import { Stack } from "@mui/material";
import React from "react";
import DarkTheme from "@blockly/theme-dark";
import ThemeHigh from "@blockly/theme-highcontrast";
import ThemeTrit from "@blockly/theme-tritanopia";
import ThemeDeuter from "@blockly/theme-deuteranopia";
import Theme from "@blockly/theme-modern";
// // import Speech from "react-speech";
// // import { useSpeechSynthesis } from "react-speech-kit";
// import VolumeUpIcon from "@mui/icons-material/VolumeUp";
// import { useQuery } from "react-query";
import useCurriculumApi from "api/curriculum";
import { useState } from "react";
// import Modal from "@mui/material/Modal";
// import CircularProgress from "@mui/material/CircularProgress";
// import ConfirmModal from "components/Modals/ConfirmModal";
// import SnackbarContent from "@material-ui/core/SnackbarContent";
// import Snackbar from "@mui/material/Snackbar";
// import GeneralSnackbar from "components/Snackbar/GeneralSnackbar";
// import EAILogoAct from "../../../../assets/img/EducationaiLogos/Group 1536.svg";
import SettingsIcon from "@mui/icons-material/Settings";
import usePersistStore from "../../../../zustandPersist";

const BlocksHeaderCustom = ({
  switchCodeView,
  runCode,
  toggleTerminal,
  running,
  setBlockTheme,
  instructions,
  getHint,
  hintData,
  resourceData,
  lessonId,
  exerciseName,
  isLoadingHint,
  submitAnswer,
  submitIsLoading,
  submitIsError,
  submitIsSuccess,
  setHintText,
  hintText,
  submitData,
}) => {
  const [ourText, setOurText] = React.useState("");
  const [langVoice, setLangVoice] = useState(null);
  const synthRef = React.useRef(window.speechSynthesis);
  const { setBlocksTheme, blocksTheme } = usePersistStore();

  // const speechHandler = (msg) => {
  //   msg.text = ourText;
  //   window.speechSynthesis.speak(msg);
  // };
  const [open, setOpen] = React.useState(false);
  const [openLesson, setOpenLesson] = React.useState(false);
  const [openSubmit, setOpenSubmit] = React.useState(false);
  const [messageNoLesson, setMessageNoLesson] = useState(true);
  const { fetchLevelResources, fetchLessonResources } = useCurriculumApi();
  const [showResults, setShowResults] = useState(false);
  const [showThemes, setShowThemes] = useState(false);

  return (
    <div>
      {/* {submitData && !submitIsLoading && ( */}

      {/* )} */}

      {/* ===================== */}
      <div
        className="header"
        style={{
          background: "white",
          borderRadius: "12px",
          padding: "6px 10px",
          marginBottom: "10px",
          // margin: "auto 0 20px",
          margin: "auto 0 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
            margin: "auto",
            height: "100%",
            gap: "5px",
          }}
        >
          {/* <strong style={{ margin: "4px 10px 0 0" }}>Question: </strong> */}

          <p
            style={{
              margin: "0",
              padding: "5px",
              //   fontWeight: "500",
              //   fontSize: "20px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <h1 style={{ margin: "0", fontSize: "22px" }}>Blocks</h1>
            {/* <p style={{ margin: "0px 4px 0 0", fontSize: "22px" }}>
              For Loops testing
            </p> */}
          </p>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            // to={`/activities/Curriculum/${exerciseData.data.name}/${exerciseData.data.stage}`}
            onClick={() => {
              //   getHint();
              //   handleOpen();
            }}
            style={{
              padding: "4px 12px",
              color: "white",
              background: "#6358A5",
              cursor: "pointer",
              border: "none",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              boxShadow:
                " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              margin: "0",
              width: "100px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: "white",
                margin: "0",
                padding: "0",
              }}
              // onChange={(e) => setOurText(e.target.value)}
            >
              Save
            </p>
          </button>
          <button
            disabled={running}
            onClick={runCode}
            style={{
              padding: "4px 12px",
              color: "white",
              background: "#6358A5",
              cursor: "pointer",
              border: "none",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              boxShadow:
                " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              margin: "0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  color: "white",
                  margin: "0",
                  padding: "0",
                }}
              >
                Run
              </p>{" "}
              <PlayArrowOutlined
                color="#fff"
                // fontSize="28px"
                style={{
                  color: "#fff",
                  margin: "0",
                  padding: "0",
                  fontSize: "28px",
                }}
              />
            </div>
          </button>
          <button
            style={{
              padding: "4px 12px",
              color: "white",
              background: "#6358A5",
              border: "none",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              boxShadow:
                " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              margin: "0",
              width: "60px",
              position: "relative",
              borderBottomLeftRadius: showThemes ? "0" : "8px",
              borderBottomRightRadius: showThemes ? "0" : "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <SettingsIcon
                color="#fff"
                // fontSize="28px"
                style={{
                  color: "#fff",
                  margin: "0",
                  padding: "0",
                  fontSize: "28px",
                }}
                onClick={() => setShowThemes((prev) => !prev)}
              />
            </div>
            {showThemes && (
              <>
                {" "}
                <div
                  style={{
                    position: "absolute",
                    top: "calc(100%)",
                    background: "#6358A5",
                    right: "0",
                    height: "10px",
                    width: "60px",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    padding: "8px 8px",
                    color: "white",
                    background: "#6358A5",
                    border: "none",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "600",
                    // boxShadow:
                    //   " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    margin: "0",
                    top: "calc(100% + 10px)",
                    right: "0",
                    zIndex: 9,
                    gap: "5px",
                    borderTopRightRadius: "0",
                  }}
                >
                  <p
                    style={{
                      padding: "4px 14px",
                      borderRadius: "8px",
                      margin: "0",
                      width: "100%",
                      cursor: "pointer",
                      background:
                        blocksTheme === Theme
                          ? "rgba(255,255,255,1)"
                          : "rgba(255,255,255,0.2)",
                      color:
                        blocksTheme === Theme
                          ? "#6358A5"
                          : "rgba(255,255,255,1)",
                      fontWeight: "500",
                      boxShadow:
                        " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    }}
                    onClick={() => setBlocksTheme(Theme)}
                  >
                    Theme
                  </p>

                  <p
                    style={{
                      padding: "4px 14px",
                      borderRadius: "8px",
                      margin: "0",
                      width: "100%",
                      cursor: "pointer",
                      background:
                        blocksTheme === DarkTheme
                          ? "rgba(255,255,255,1)"
                          : "rgba(255,255,255,0.2)",
                      color:
                        blocksTheme === DarkTheme
                          ? "#6358A5"
                          : "rgba(255,255,255,1)",
                      fontWeight: "500",
                      boxShadow:
                        " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    }}
                    onClick={() => setBlocksTheme(DarkTheme)}
                  >
                    Dark Theme
                  </p>
                  <p
                    style={{
                      padding: "4px 14px",
                      borderRadius: "8px",
                      margin: "0",
                      width: "100%",
                      cursor: "pointer",
                      background:
                        blocksTheme === ThemeHigh
                          ? "rgba(255,255,255,1)"
                          : "rgba(255,255,255,0.2)",
                      color:
                        blocksTheme === ThemeHigh
                          ? "#6358A5"
                          : "rgba(255,255,255,1)",
                      fontWeight: "500",
                      boxShadow:
                        " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    }}
                    onClick={() => setBlocksTheme(ThemeHigh)}
                  >
                    High contrast
                  </p>
                  <p
                    style={{
                      padding: "4px 14px",
                      borderRadius: "8px",
                      margin: "0",
                      width: "100%",
                      cursor: "pointer",
                      background:
                        blocksTheme === ThemeTrit
                          ? "rgba(255,255,255,1)"
                          : "rgba(255,255,255,0.2)",
                      color:
                        blocksTheme === ThemeTrit
                          ? "#6358A5"
                          : "rgba(255,255,255,1)",
                      fontWeight: "500",
                      boxShadow:
                        " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    }}
                    onClick={() => setBlocksTheme(ThemeTrit)}
                  >
                    Tritanopia
                  </p>
                  <p
                    style={{
                      padding: "4px 14px",
                      margin: "0",
                      borderRadius: "8px",
                      width: "100%",
                      cursor: "pointer",
                      background:
                        blocksTheme === ThemeDeuter
                          ? "rgba(255,255,255,1)"
                          : "rgba(255,255,255,0.2)",
                      color:
                        blocksTheme === ThemeDeuter
                          ? "#6358A5"
                          : "rgba(255,255,255,1)",
                      fontWeight: "500",
                      boxShadow:
                        " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    }}
                    onClick={() => setBlocksTheme(ThemeDeuter)}
                  >
                    Deuteranopia
                  </p>
                </div>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export default BlocksHeaderCustom;
