import React from "react";

const LearningObjItem = ({ learningObj, score }) => {
  let bgColorTheme;
  if (score <= 50) {
    bgColorTheme = "#EEC27C";
  } else if (score <= 80 && score > 50) {
    bgColorTheme = "#7FCDED";
  } else if (score > 80) {
    bgColorTheme = "#8ACB5F";
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "4px 0",
        background: "white",
        borderRadius: "90px",
        padding: "6px 12px",
      }}
    >
      <h4
        style={{
          margin: "0 12px 0 12px",
          fontWeight: "500",
          fontSize: "15px",
          width: "80%",
        }}
      >
        {learningObj}
      </h4>
      <div
        style={{
          // margin: "0 12px 0 12px",
          // width: "80%",

          width: "40px",
          height: "40px",
          borderRadius: "100px",
          background: bgColorTheme,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h4
          style={{
            margin: "0",
            fontWeight: "700",
            fontSize: "12px",
            // color:'white'
          }}
        >
          {Math.round(score)}%
        </h4>
      </div>
    </div>
  );
};

export default LearningObjItem;
