import React, { useEffect, useState, useRef } from "react";
import useCurriculumApi from "api/curriculum";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import H5PComponent from "./H5PComponent";
import { H5P as H5PStandalone } from "h5p-standalone";
import useStore from "../../../zustand";
import H5PResults from "./H5PResults";

import Confetti from "react-confetti";
import ActivityPlayerBtn from "./ActivityPlayerBtn";

const ActivityPlayerComponent = ({
  sessionId,
  numberOfQuestionsArray,
  numberOfQuestions,
  quesNum,
  setQuesNum,
  containerH5P,
  loadingDetails,
  setContainerH5P,
  isEndOfQuestions,
  setIsEndOfQuestions,
  isReviewState,
  setIsReviewState,
  selectQuestionToReview,
  setSelectQuestionToReview,
  setNumberOfQuestions,
  checkIfCompleteQuestion,
  setCheckIfFirstQuestion,
  hasStudentFinished,
  setHasStudentFinished,
}) => {
  const [tempExerciseId, setTempExerciseId] = useState("");
  const { exerciseId, lessonId } = useParams();
  const [folderPath, setFolderPath] = useState("");
  const [nextQuesFetch, setNextQuesFetch] = useState(true);
  const [btnDisableUntilAnswered, setBtnDisableUntilAnswered] = useState(true);
  const { selectedClassId, userId, tenantId } = useStore((state) => state);
  const [h5pLocation, setH5pLocation] = useState("");
  const [activityResults, setActivityResults] = useState({});
  const [activityResultsArray, setActivityResultsArray] = useState([]);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [h5pResult, setH5pResult] = useState(null);
  const [tempFolderPlayerCont, setTempFolderPlayerCont] = useState("");
  const [tempCheckResults, setTempCheckResults] = useState(null);
  const [tempFolderName, setTempFolderName] = useState("");
  const { fetchH5PQuestion } = useCurriculumApi();
  const [isVisible, setVisible] = useState(1);
  console.log("isEndOfQuestions :>> ", isEndOfQuestions);
  const [checkResults, setCheckResults] = useState(null);
  const [resultIframeOutput, setResultIframeOutput] = useState(null);
  const ref = useRef(null);
  const { status, data, isLoading, refetch } = useQuery(
    ["H5PQuestion", exerciseId],
    () =>
      fetchH5PQuestion(
        userId,
        exerciseId,
        tempExerciseId,
        sessionId,
        selectedClassId,
        tenantId,
        lessonId,
        h5pLocation,
        h5pResult,
        resultIframeOutput
      ),

    {
      enabled: sessionId.length === 36,
      // enabled: false,
      refetchOnWindowFocus: false,
    }
  );
  // console.log("status :>> ", status);
  // console.log("isLoading :>> ", isLoading);
  useEffect(() => {
    if (data) {
      console.log("fetch next_question", data && data.data);
      if (data.data.foldername === "end" && quesNum === 0) {
        setCheckIfFirstQuestion(false);
      }
      if (data.data.foldername === "end") {
        setIsLastQuestion(true);
        if (numberOfQuestions === null && quesNum !== 0) {
          setNumberOfQuestions(quesNum - 1);
          setIsEndOfQuestions(true);
          setHasStudentFinished(true);
          // setQuesNum((prev) => prev + 1);

          // refetch();
          // setQuesNum((prev) => prev + 1);
          // setHasStudentFinished(true);
        }
        setIsEndOfQuestions(true);
      }
      setFolderPath(data?.data.foldername);
      setTempExerciseId(data?.data.next_question);
      setH5pLocation(data?.data.next_question);
    }
  }, [data]);
  useEffect(() => {
    if (quesNum !== 0) setContainerH5P(() => `h5p-${quesNum}`);
  }, [quesNum]);

  // console.log("=============================================");
  // console.log("numberOfQuestions :>> ", numberOfQuestions);
  // console.log("quesNum :>> ", quesNum);
  // console.log("isEndOfQuestions :>> ", isEndOfQuestions);
  // console.log("checkIfCompleteQuestion :>> ", checkIfCompleteQuestion);
  useEffect(() => {
    if (!isEndOfQuestions) {
      if (
        containerH5P.length > 0 &&
        quesNum !== 0 &&
        // folderPath.length > 0 &&
        tempFolderName !== folderPath &&
        tempFolderPlayerCont !== containerH5P
        // quesNum <= numberOfQuestions
      ) {
        // console.log("==========INSIDE IF ==============");
        // console.log("containerH5P", containerH5P);
        // console.log("tempExerciseId :>> ", tempExerciseId);
        // console.log("quesNum", quesNum);
        // console.log("folderPath", folderPath);
        // console.log("folderPath.length", folderPath.length);
        // console.log(
        //   "quesNum <= numberOfQuestions",
        //   quesNum <= numberOfQuestions
        // );
        // console.log("========================================");
        setTempFolderPlayerCont(containerH5P);
        setTempFolderName(folderPath);
        let options = {
          h5pJsonPath: `/h5pextracted/${folderPath}`,
          // h5pJsonPath: `/h5p/Measure`,
          frameJs: "/assets/frame.bundle.js",
          frameCss: "/assets/styles/h5p.css",
          customCss: "/assets/styles/multichoice.css",
        };
        new H5PStandalone(document.getElementById(containerH5P), options).then(
          function () {
            window.H5P.externalDispatcher.on("xAPI", async (event) => {
              // console.log("event.data.statement", event.data.statement);
              if (event.data.statement.result) {
                if (tempCheckResults !== event.data.statement) {
                  setTempCheckResults(event.data.statement);
                  setActivityResults({
                    name: event.data.statement.object.definition.name["en-US"],
                    user_scores: event.data.statement.result.score.raw,
                    max_scores: event.data.statement.result.score.max,
                  });
                  // console.log("event.data.statement :>> ", event.data.statement);
                  setBtnDisableUntilAnswered(true);
                  setH5pResult(await getResults(event.data.statement));
                  // console.log(
                  //   "getResults(event.data.statement)",
                  //   getResults(event.data.statement)
                  // );
                  // setCheckResults(document.getElementById(containerH5P));
                  // console.log("checkResults :>> ", checkResults);
                  const studentAnswer = document
                    .getElementById(`h5p-${quesNum}`)
                    .querySelector(".h5p-iframe")
                    .contentWindow.document.querySelector(
                      ".h5p-iframe"
                    ).outerHTML;
                  console.log("studentAnswer :>> ", studentAnswer);
                  console.log(
                    "contentWindow.document.querySelector",
                    document.getElementById(`h5p-${quesNum}`)
                  );
                  console.log(
                    "contentWindow.querySelector",
                    document
                      .getElementById(`h5p-${quesNum}`)
                      .querySelector(".h5p-iframe").contentWindow.document
                  );

                  setResultIframeOutput(studentAnswer);
                  // //   .getElementsByTagName("html");

                  // // .querySelector(".h5p-content");
                  // console.log(
                  //   "checkContent: >>",
                  //   document.querySelector(".h5p-standalone")
                  // );
                }
              }
            });
          }
        );
      }
    }
  }, [containerH5P, folderPath]);
  // console.log("activityResults :>> ", activityResults);
  // console.log("activityResults :>> ", activityResults);
  // useEffect(() => {
  //   if (activityResults && quesNum !== 0) {
  //   }
  // }, [activityResults,]);
  // checkContent
  useEffect(() => {
    if (
      quesNum === numberOfQuestions + 1 &&
      isEndOfQuestions &&
      checkIfCompleteQuestion &&
      numberOfQuestions !== null
    ) {
      const interval = setInterval(() => {
        // setVisible(0.8);
        // setVisible(0.8);
        // setVisible(0.8);
        // setVisible(0.7);
        // setVisible(0.6);
        // setVisible(0.6);
        // setVisible(0.5);
        // setVisible(0.4);
        // setVisible(0.3);
        // setVisible(0.2);
        setVisible(0);
      }, 8000);
      return () => clearInterval(interval);
    }

    // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [numberOfQuestions, checkIfCompleteQuestion, isEndOfQuestions]);
  // console.log("checkResults", checkResults);
  // console.log("checkResults", checkResults);
  const handleClickAddArray = () => {
    if (quesNum !== 0) {
      setActivityResultsArray((prev) => [...prev, activityResults]);
      // console.log("activityResultsArray", activityResultsArray);
      // console.log("ENTERED !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    }
  };
  // console.log("checkResults :>> ", checkResults);
  console.log("activityResultsArray", activityResultsArray);

  // const handleClick = () => {
  //   setIsEndOfQuestions(true);
  //   setHasStudentFinished(true);
  //   setNumberOfQuestions(quesNum);
  //   setQuesNum((prev) => prev + 1);
  // };
  if (status === "error") {
    return (
      <div
        style={{
          width: "95%",
          minHeight: "300px",
          display: "flex",
          flexDirection: "column",
          // width: "60%",
          alignItems: "center",
          justifyContent: "Center",
          margin: "30px auto 30px",
          borderRadius: "20px",
          transition: "height 0.5s ease-in-out",
        }}
      >
        <h1>Something went wrong</h1>
      </div>
    );
  } else {
    return (
      <>
        <div
          style={{
            width: "95%",
            minHeight: "300px",
            display: "flex",
            flexDirection: "column",
            // width: "60%",
            alignItems: "center",
            justifyContent: "Center",
            margin: "20px auto 20px",
            borderRadius: "20px",
            transition: "height 0.5s ease-in-out",
          }}
        >
          {/* <h1 onClick={() => handleClick()}>Yes</h1> */}
          {/* {isVisible && ( */}
          {/* <Confetti recycle={true} opacity={isVisible} /> */}
          {/* )} */}

          {/* <h2>H5P player Question {quesNum}</h2> */}
          {!checkIfCompleteQuestion && <h1>Activity Has Been Completed</h1>}
          {quesNum === 0 && !isEndOfQuestions && checkIfCompleteQuestion && (
            <h1>Start Activity</h1>
          )}
          {!isEndOfQuestions &&
            numberOfQuestionsArray.map((item, index) => {
              if (index + 1 === quesNum) {
                // return (
                //   // <H5PComponent indexComponent={index + 1} quesNum={quesNum} />
                // );
              } else {
                return null;
              }
            })}
          {/* {quesNum === numberOfQuestions + 1 && */}
          {isEndOfQuestions &&
            checkIfCompleteQuestion &&
            numberOfQuestions !== null && (
              // (quesNum === 0 && isEndOfQuestions && (
              <>
                <H5PResults activityResultsArray={activityResultsArray} />
                <Confetti recycle={true} opacity={isVisible} />
                <hr style={{ margin: "10px" }} />
              </>
            )}
          {/* {activityResults && (
          <H5PResults activityResultsArray={activityResultsArray} />
        )} */}
          <H5PComponent
            quesNum={quesNum}
            isEndOfQuestions={isEndOfQuestions}
            numberOfQuestions={numberOfQuestions}
          />
        </div>
        {/* <h1>Test ==</h1>
        {checkResults && checkResults} */}
        {checkIfCompleteQuestion && (
          <ActivityPlayerBtn
            quesNum={quesNum}
            numberOfQuestions={numberOfQuestions}
            btnDisableUntilAnswered={btnDisableUntilAnswered}
            setQuesNum={setQuesNum}
            setBtnDisableUntilAnswered={setBtnDisableUntilAnswered}
            isLastQuestion={isLastQuestion}
            refetch={refetch}
            isLoading={isLoading}
            loadingDetails={loadingDetails}
            isEndOfQuestions={isEndOfQuestions}
            isReviewState={isReviewState}
            setIsReviewState={setIsReviewState}
            selectQuestionToReview={selectQuestionToReview}
            setSelectQuestionToReview={setSelectQuestionToReview}
            handleClickAddArray={handleClickAddArray}
            setIsEndOfQuestions={setIsEndOfQuestions}
          />
        )}
      </>
    );
  }
};

function getDuration(xapi_duration) {
  return xapi_duration.slice(2, -1);
}

//Build results object for API request
function getResults(event) {
  //Extract question type from url definition
  let type = event.context.contextActivities.category[0].id
    .split("H5P.")
    .pop()
    .split("-")[0];

  //Switch based on activity type to fetch user_response and solution structure
  let user_response;
  let solution;

  switch (type) {
    case "DragQuestion":
      user_response = event.result.response;
      solution = {
        source: event.object.definition.source,
        target: event.object.definition.target,
        response_pattern: event.object.definition.correctResponsesPattern,
      };
      break;
    case "Blanks":
      user_response = event.result.response;
      solution = event.object.definition.correctResponsesPattern;
      break;
    case "DragText":
      user_response = event.result.response;
      solution = event.object.definition.correctResponsesPattern;
      break;
    case "MarkTheWords":
      user_response = event.result.response;
      solution = {
        choices: event.object.definition.choices,
        response_pattern: event.object.definition.correctResponsesPattern,
      };
      break;
    case "MultiChoice":
      user_response = event.result.response;
      solution = {
        choices: event.object.definition.choices,
        response_pattern: event.object.definition.correctResponsesPattern,
      };
      break;
    case "TrueFalse":
      user_response = event.result.response;
      solution = event.object.definition.correctResponsesPattern;
      break;
    default:
      return null;
  }

  //Return result object
  //To do: Implement event count
  const alright = {
    max: event.result.score.max,
    raw: event.result.score.raw,
    duration: getDuration(event.result.duration),
    question_type: type,
    solution: solution,
    user_response: user_response,
  };

  // console.log("The results function return: ", alright);
  // console.log(
  //   "The results function JSON.parse(alright): ",
  //   JSON.stringify(alright)
  // );
  return JSON.stringify(alright);
}
export default ActivityPlayerComponent;
