import React, { useState } from "react";
import EditProfile from "../../components/StudentPage/EditProfile";
import Avatar from "@material-ui/core/Avatar";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Box, Skeleton, Tooltip } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import GeneralSnackbar from "components/Snackbar/GeneralSnackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@mui/material/Snackbar";
import useUserApi from "api/user";
import useStore from "../../../../../zustand.js";
import ConfirmModal from "components/Modals/ConfirmModal";
// import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMoreIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// ExpandMoreIcon;
function TeacherManageX({ teacher, classes, teachersStatus }) {
  const queryClient = useQueryClient();
  const { deleteTeacher, updateTeacherInfo, fetchClasses } = useUserApi();
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmActionDescription, setConfirmActionDescription] =
    useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { schoolName } = useStore();

  // Fetch class
  const { data: classesOfTeachers, status: classesStatus } = useQuery("classes", () =>
    fetchClasses(),
    { refetchOnWindowFocus: false, }
  );

  // Delete teacher
  const { mutate, isError, isSuccess, isLoading } = useMutation(
    (teacherID) => {
      return deleteTeacher(teacherID);
    },
    { onSuccess: () => queryClient.invalidateQueries("teachers") }
  );

  //Edit Teacher
  const {
    mutate: mutateEdit,
    isLoading: isLoadingEdit,
    isSuccess: isSuccessEdit,
    isError: isErrorEdit,
    error: errorEdit,
  } = useMutation(
    (updatedInfo) => {
      return updateTeacherInfo(updatedInfo);
    },
    { onSuccess: () => queryClient.invalidateQueries("teachers") }
  );

  function performDelete(teacher) {
    setConfirmAction(
      () =>
        function () {
          mutate(teacher);
          setOpenSnackbarDetete(true);
        }
    );
    setConfirmActionDescription(
      schoolName
        ? `remove teacher from ${schoolName}`
        : `remove teacher from this school`
    );
    setConfirmOpen(true);
  }

  // Snackbar disappear
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarDetete, setOpenSnackbarDetete] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenSnackbar(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [openSnackbar]);

  return (
    <>
      <ConfirmModal
        action={confirmAction}
        actionDescription={confirmActionDescription}
        open={confirmOpen}
        setOpen={setConfirmOpen}
      />
      <GeneralSnackbar
        isLoading={isLoadingEdit}
        isSuccess={isSuccessEdit}
        isError={isErrorEdit}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message="The profile has been successfully edited."
      />

      <GeneralSnackbar
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbarDetete}
        setOpenSnackbar={setOpenSnackbarDetete}
        message="The profile has been successfully deleted."
      />
      <GeneralSnackbar
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbarDetete}
        setOpenSnackbar={setOpenSnackbarDetete}
        message="The profile has been successfully deleted."
      />
      <TableRow>
        <TableCell
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootBodyHead,
          }}
          component="th"
          variant="head"
          scope="row"
          style={{ maxWidth: 50, paddingTop: "8px", paddingBottom: "8px" }}
        >
          <Box alignItems="center" display="flex">
            {teachersStatus === "loading" ? (
              <Skeleton count={1} width={100} height={50} />
            ) : (
              <Box
                component={Avatar}
                alt="..."
                src={teacher.icon}
                style={{
                  border: "2px solid #06659D",
                  background: "#fff",
                  boxShadow: "0 1px 3px hsla(0, 0%, 0%, 0.2)",
                }}
              />
            )}
          </Box>
        </TableCell>
        <TableCell
          style={{
            verticalAlign: "inherit",
            borderTop: "none",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          <Box
            display="flex"
            style={{ paddingLeft: "10px", alignItems: "center" }}
          >
            {teachersStatus === "loading" ? (
              <Skeleton count={1} width="90%" height={50} />
            ) : (
              <Box fontSize='.875rem' component='span' className='name' sx={{ marginLeft: 1 }}>
                {teacher?.full_name?.length > 17 ? teacher?.full_name?.slice(0, 17) + '...' : teacher.full_name}
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell
          style={{
            verticalAlign: "inherit",
            borderTop: "none",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          <Box
            display="flex"
            alignItems="flex-start"
            style={{ paddingLeft: "10px" }}
          >
            {teachersStatus === "loading" ? (
              <Skeleton count={1} width="90%" height={50} />
            ) : (
              <Box
                fontSize=".875rem"
                component="span"
                className="name"
                style={{ display: "flex" }}
              >
                {classesOfTeachers?.data?.filter((item) => item?.teachers?.includes(teacher.id))?.length > 3 && (
                  <>
                    {classesOfTeachers?.data?.filter((item) => item?.teachers?.includes(teacher.id))?.slice(0, 3)?.map((name) => (
                      <div
                        style={{
                          margin: 2,
                          padding: "5px 10px",
                          borderRadius: 12, maxWidth: 200,
                          overflow: "overlay",
                          background: '#f6f9fc',
                          fontSize: '13px',
                        }}
                      >
                        {name.name?.length > 17 ? name.name?.slice(0, 17) + '...' : name.name}

                      </div>
                    ))}
                    <Tooltip
                      title={
                        <div
                          style={{
                            background: "white",
                            color: "#525f7f",
                            borderRadius: "4px",
                            padding: "10px",
                            margin: "1px",
                            border: "1px solid white",
                            fontSize: ".875rem",
                            fontFamily: "Montserrat,sans-serif",
                            fontWeight: 400,
                            maxHeight: "calc(100vh - 100px)",
                            overflow: "auto"
                          }}
                        >
                          {classesOfTeachers?.data?.filter((item) => item?.teachers?.includes(teacher.id))?.map((name) => (
                            <div
                              style={{
                                margin: 5,
                                marginBottom: "10px",
                                padding: "5px 10px",
                                borderRadius: 12,
                                maxWidth: 200,
                                overflow: "overlay",
                                background: '#f6f9fc',
                                fontSize: '13px',
                              }}
                            >
                              {name.name}
                            </div>
                          ))}
                        </div>
                      }
                      arrow
                      placement='right'
                    >
                      <IconButton
                        style={{
                          marginLeft: 2,
                          color: "#525f7f",
                          borderRadius: 4,
                          //   border: "1px solid rgb(5, 100, 157)",
                          padding: "0px 10px",
                          height: 32,
                          fontFamily: "Montserrat,sans-serif",
                          fontWeight: 400,
                          fontSize: '13px',

                        }}
                      >
                        View All <ExpandMoreIcon margin={0} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {
                  classesOfTeachers?.data?.filter((item) => item?.teachers?.includes(teacher.id))?.length < 4 &&
                  classesOfTeachers?.data?.filter((item) => item?.teachers?.includes(teacher.id))?.map((name) => (
                    <div
                      style={{
                        margin: 5,
                        marginBottom: "10px",
                        padding: "5px 10px",
                        borderRadius: 4,
                        maxWidth: 200,
                        overflow: "overlay",
                        background: '#f6f9fc',
                        fontSize: '13px',
                      }}
                    >
                      {name.name}
                    </div>
                  ))
                }
                {
                  classesOfTeachers?.data?.filter((item) => item?.teachers?.includes(teacher.id))?.data?.length === 0 && (
                    <div
                      style={{
                        margin: 2,
                        padding: "5px 10px",
                        border: "1px solid #7b8794",
                        borderRadius: 4,
                        color: "#7b8794",
                      }}
                    >
                      No Class Assigned
                    </div>
                  )
                }
              </Box>
            )
            }
          </Box>
        </TableCell>
        <TableCell
          style={{
            borderTop: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          {teachersStatus === "loading" ? (
            <Skeleton count={1} width="90%" height={50} />
          ) : (
            <>
              <EditProfile
                data={teacher}
                name={teacher.first_name}
                lastName={teacher.last_name}
                email={teacher.email}
                id={teacher.id}
                mutate={mutateEdit}
                isLoading={isLoadingEdit}
                isSuccess={isSuccessEdit}
                isError={isErrorEdit}
                error={errorEdit}
                setOpenSnackbar={setOpenSnackbar}
                title="Update Student Info"
              />
              <IconButton style={{ marginLeft: 15 }}>
                <DeleteIcon
                  style={{ color: "rgb(123, 135, 148)" }}
                  onClick={() => performDelete(teacher.id)}
                />
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

export default TeacherManageX;
