import React from "react";
import StudentsPerformanceList from "./StudentsPerformanceList";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
const CircleLessonPerformance = ({
  data,
  dataNumStudents,
  type,
  studentNeeds,
  shadows,
  isLoading,
}) => {
  // console.log("]]]]]]]]]]]]]]]=====>data", data);
  let bgColorTheme;
  if (type === "worst") {
    bgColorTheme = "#EEC27C";
  } else if (type === "middle") {
    bgColorTheme = "#7FCDED";
  } else if (type === "best") {
    bgColorTheme = "#8ACB5F";
  } else if (type === "noscore") {
    bgColorTheme = "rgb(138, 20, 60,0.8)";
  } else {
    bgColorTheme = "rgb(220, 20, 60)";
  }
  const toolTipDef = (check) => {
    if (check === "worst") {
      return "Students need to work more on their understanding of one or more learning objectives in this lesson.";
    } else if (check === "middle") {
      return "Students are comfortable with the learning objectives in this lesson.";
    } else if (check === "best") {
      return "Students exhibit a good understand of learning objectives in this lesson and should be given stretch tasks.";
    } else if (check === "noscore") {
      return "Students have not finished the assignment for the lesson.";
    }
  };
  return (
    <div
      style={{
        // borderRadius: "8px",
        padding: "16px 20px",
        width: "100%",
      }}
    >
      <div
        style={{
          boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
          background: "white",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <div
          style={{
            // background: "rgb(223, 237, 251)",
            // background: "rgb(223, 237, 251)",
            // borderBottom: "2px solid rgba(7, 99, 156,0.4)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            marginBottom: "10px",
            margin: "auto",
            padding: "10px 20px",
          }}
        >
          <Tooltip
            title={
              <div
                style={{
                  fontSize: "14px",
                  background: bgColorTheme,
                  // color: "rgba(0,0,0,0.8)",
                  color: "white",
                  borderRadius: "4px",
                  padding: "10px",
                  margin: "8px",
                }}
              >
                <h4
                  style={{
                    fontSize: "14px",
                    borderRadius: "4px",
                    color: "white",
                    fontWeight: "400",
                    margin: "0",
                    padding: "10px 20px",
                    background: "rgba(0,0,0,0.5)",
                  }}
                >
                  {toolTipDef(type)}
                </h4>
              </div>
            }
            arrow
            placement='top'
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <h1
                style={{
                  background: bgColorTheme,
                  width: "50px",
                  height: "50px",
                  borderRadius: "100px",
                  color: "white",
                  fontSize: "22px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0",
                }}
              >
                {isLoading ? (
                  <CircularProgress
                    style={{ width: "30%", height: "30%", color: "white" }}
                    // disableShrink
                  />
                ) : (
                  // "yes"
                  dataNumStudents
                )}
              </h1>
              {/* )} */}
              <h4
                style={{
                  fontSize: "18px",
                  margin: "0 0 0 12px",
                  // color: "white",
                }}
              >
                {studentNeeds}
              </h4>
            </div>
          </Tooltip>
        </div>
        <hr
          style={{
            margin: "2px auto",
            width: "80%",
            border: " 0",
            height: " 1px",
            backgroundImage:
              " linear-gradient(to right, rgba(7, 99, 156, 0),rgba(7, 99, 156, 1),rgba(7, 99, 156, 0))",
          }}
        />
        <StudentsPerformanceList students={data} type={type} />
      </div>
    </div>
  );
};

export default CircleLessonPerformance;
