import useDataApi from "api/data";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { Card } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";

const ClassCompThinkScore = ({}) => {
  const { classId } = useParams();
  const { fetchClassComputationalThinkingScores } = useDataApi();
  const {
    data: computationalScores,
    status: computationalScoresStatus,
    isLoading: computationalScoresLoading,
  } = useQuery(
    ["class_computational_scores", classId, null],
    () => fetchClassComputationalThinkingScores(classId, null),
    {
      refetchOnWindowFocus: false,
    }
  );
  if (computationalScores) {
    // console.log(
    //   "computationalScores.data =========================>> ",
    //   computationalScores.data
    // );
  }
  const toolTipDef = (concept, description) => {
    return `${concept}: ${description}.`;
  };
  if (computationalScoresLoading ) {
    return (
      <Card
        sx={{
          boxShadow: 1,
        }}
        style={{
          // width: "360px",
          height: "370px",
          display: "flex",
          padding: "20px",
          flexDirection: "column",
          backgroundColor: "white",
          // boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
        }}
      >
        <h3
          style={{
            fontSize: "16px",
            margin: "0",
            padding: "0 0 10px 0",
            textAlign: "center",
          }}
        >
          Computational Thinking Scores
        </h3>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            margin: "20px 0 0 0",
            overflow: "hidden",
          }}
        >
          <Skeleton count={1} height={40} width={240} />
          <Skeleton count={1} height={40} width={240} />
          <Skeleton count={1} height={40} width={240} />
          <Skeleton count={1} height={40} width={240} />
        </div>
      </Card>
    );
  }

  if (computationalScores) {
    // console.log(
    //   "computationalScores.data)))))))}}}}}++++",
    //   computationalScores?.data
    // );
    return (
      <div
        style={{
          // width: "360px",
          height: "370px",
          display: "flex",
          padding: "20px",
          flexDirection: "column",
          backgroundColor: "white",
          // boxShadow:
          //   "0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)",
        }}
      >
        <h3 style={{ fontSize: "16px", margin: "0", padding: "0 0 16px 0" }}>
          Computational Thinking Scores
        </h3>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            marginTop: "14px",
            gap: "16px",
          }}
        >
          {computationalScores?.data.computational_scores?.map((item) => (
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={
                <div
                  style={{
                    fontSize: "14px",
                    background: "white",
                    color: "rgba(0,0,0,0.8)",
                    borderRadius: "4px",
                    padding: "10px",
                    margin: "8px",
                  }}
                >
                  <h4
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {toolTipDef(item.name, item.description)}
                  </h4>
                </div>
              }
              arrow
              placement="top"
            >
              <div
                style={{
                  background: "#E1EDFB",
                  padding: "6px 20px",
                  // borderRadius: "12px",
                  display: "flex",
                  // flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // padding: "10px 0",
                  boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                }}
              >
                <h3
                  style={{
                    fontSize: "14px",
                    margin: "0",
                    marginRight: "30px",
                  }}
                >
                  {item.computational_thinking_concept}
                </h3>
                <h5 style={{ fontSize: "22px", margin: "0" }}>
                  {Math.round(item.score * 100)}%
                </h5>
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        // width: "340px",
        // height: "300px",
        display: "flex",
        alignItem: "center",
        padding: "40px 0",
        boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
        justifyContent: "center",
        background: "white",
      }}
    >
      <p>No Computational thinking score.</p>
    </div>
  );
};

export default ClassCompThinkScore;
