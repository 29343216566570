import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Divider } from "@material-ui/core";

const ListComponentSkeleton = () => {
  const matches = useMediaQuery("(min-width:1240px)");
  return (
    <div
      style={{
        width: ` ${matches ? "47%" : "100%"}`,
        boxShadow: " 0 10px 15px hsla(0, 0%, 0%, 0.096)",
        borderRadius: "14px",
        background: "#fff",
        padding: "10px",
        marginTop: "30px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px ",
        }}
      >
        <Skeleton circle width={50} height={50} />
        <div
          style={{
            width: "80%",
          }}
        >
          <Skeleton count={3} />
        </div>
      </div>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px ",
        }}
      >
        <Skeleton circle width={50} height={50} />
        <div
          style={{
            width: "80%",
          }}
        >
          <Skeleton count={3} />
        </div>
      </div>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px ",
        }}
      >
        <Skeleton circle width={50} height={50} />
        <div
          style={{
            width: "80%",
          }}
        >
          <Skeleton count={3} />
        </div>
      </div>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px ",
        }}
      >
        <Skeleton circle width={50} height={50} />
        <div
          style={{
            width: "80%",
          }}
        >
          <Skeleton count={3} />
        </div>
      </div>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px ",
        }}
      >
        <Skeleton circle width={50} height={50} />
        <div
          style={{
            width: "80%",
          }}
        >
          <Skeleton count={3} />
        </div>
      </div>
    </div>
  );
};

export default ListComponentSkeleton;
