import React from "react";
import Iframe from "react-iframe";
import usePersistStore from "../../../zustandPersist";

const GameIndividualPage = () => {
  const { gamesUrl } = usePersistStore();

  return (
    <div
      style={{
        width: "95%",
        height: "90vh",
        margin: "auto",
        position: "relative",
      }}
    >
      <h1
        style={{
          width: "153px",
          height: "54px",
          top: "4px",
          left: "4px",
          position: "absolute",
          background: "white",
          padding: "10px",
          margin: "0",
          borderRadius: "8px",
        }}
      >
        Games
      </h1>
      <Iframe
        width="100%"
        height="100%"
        display="block"
        // style={{ width: "100%", height: "100%" }}
        src={gamesUrl}
      />
    </div>
  );
};

export default GameIndividualPage;
