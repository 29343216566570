import React, { useEffect, useState } from "react";
// import BuilderFilterComp from "./BuilderFilterComp";
// import BuilderLessonBasket from "./BuilderLessonBasket";
// import BuilderLessonCard from "./BuilderLessonCard";
// import BuilderLevelComponent from "./BuilderLevelComponent";
import BuilderBasketNew from "./BuilderBasketNew";
import BuilderFilterComp from "./BuilderFilterComp";
import BuilderLevelCompSub from "./BuilderLevelCompSub";

const BuilderLevels = ({
  data,
  searchWord,
  setSearchWord,
  selectedLessons,
  setSelectedLessons,
  selectedFilterItems,
  setSelectedFilterItems,
  setSelectedLessonIdPlayer,
  selectedLessonIdPlayer,
  customLessonData,
  editLevelId,
  dataCustomLevelEdit,
}) => {
  const [levelNameInput, setLevelNameInput] = useState(null);
  const [levelNameCurriculum, setLevelNameCurriculum] = useState(null);
  const [levels, setLevels] = useState(null);
  const [showFilter, setShowFilter] = useState(null);
  const [levelNames, setLevelNames] = useState([]);
  const [selectedLevelFilter, setSelectedLevelFilter] = useState([]);
  const [title, setTitle] = useState("");
  const [selectedLesson, setSelectedLesson] = useState({});
  const [expandFilter, setExpandFilter] = useState(true);
  const [levelNamesFilter, setLevelNamesFilter] = useState([
    // "Computational Thinking",
    // "Computing (Blocks - Python)",
    // "Computing (Python)",
    // "GCSE CS",
    // "Micro:Bit",
    // "Machine Learning",
  ]);
  const [levelItemNamesFilter, setLevelItemNamesFilter] = useState([]);
  useEffect(() => {
    if (dataCustomLevelEdit && editLevelId) {
      setSelectedLessons(dataCustomLevelEdit?.lessons);
      setTitle(dataCustomLevelEdit?.name);
    } else {
      setSelectedLessons([]);
    }
  }, [dataCustomLevelEdit]);

  useEffect(() => {
    if (data != null) {
      const tempLevel = data.reduce((acc, item) => {
        if (!acc[item.level_name.replace("(online)", "")]) {
          acc[item.level_name.replace("(online)", "")] = [];
        }
        acc[item.level_name.replace("(online)", "")].push(item);
        return acc;
      }, {});
      tempLevelName(data);
      setLevels(tempLevel);
    }
  }, [data]);

  //
  const tempLevelName = (dataInfo) => {
    if (dataInfo) {
      dataInfo.map((item) => {
        if (levelNames.length < data.length + 1) {
          setLevelNames((prev) => [...prev, item.level_name]);
        }
      });
    }
  };

  useEffect(() => {
    const uniqueNames = levelNames
      .filter((val, id, array) => array.indexOf(val) === id)
      .filter((val) => !val.includes("Sample"))
      .map((item) => {
        return item.replace("(online)", "");
      });
    // console.log("uniqueNames :>> ", uniqueNames);
    setLevelNameInput(
      levelNames
        .filter((val, id, array) => array.indexOf(val) === id)
        .filter((val) => !val.includes("Sample"))
        .map((item) => {
          // console.log("item", item.replace("(online)", ""));
          return item.replace("(online)", "");
        })
        .sort((a, b) => a !== null && b !== null && a.localeCompare(b))
    );
    setLevelNameCurriculum(
      levelNames
        .filter((val, id, array) => array.indexOf(val) === id)
        .filter((val) => !val.includes("Sample"))
        .map((item) => {
          // console.log("item", item.replace("(online)", ""));
          return item.replace("(online)", "");
        })
        .sort((a, b) => a !== null && b !== null && a.localeCompare(b))
        .map((item) => {
          // console.log("item", item.replace("(online)", ""));
          return item.split("/")[0];
        })
        .filter((val, id, array) => {
          return array.indexOf(val) == id;
        })
    );
  }, [levelNames]);

  // ===================================================
  function addLesson(lesson) {
    if (selectedLessons?.filter((l) => l.id === lesson.id).length === 0) {
      const newLessons = [...selectedLessons, lesson];
      setSelectedLessons(newLessons);
    }
  }

  function deleteLesson(lessonId) {
    const newLessons = selectedLessons?.filter((l) => l.id !== lessonId);
    setSelectedLessons(newLessons);
  }
  // ===================================================

  // ===================================================
  function addSelection(lesson) {
    if (selectedFilterItems?.filter((l) => l.id === lesson.id).length === 0) {
      const newLessons = [...selectedFilterItems, lesson];
      setSelectedFilterItems(newLessons);
    }
  }

  function deleteSelection(lessonId) {
    const newLessons = selectedFilterItems?.filter((l) => l.id !== lessonId);
    setSelectedFilterItems(newLessons);
  }
  // ===================================================

  // console.log("levelNamesFilter :>> ", levelNamesFilter);
  // console.log("levelNameInput :>> ", levelNameInput);
  // console.log("levelNameCurriculum :>> ", levelNameCurriculum);
  return (
    <div
      style={{
        // display: "flex",
        gap: "16px",
        height: "90vh",
        marginTop: "80px",
        // background: "white",
        // boxShadow: "0 2px 6px hsla(0,0%, 0%, .2)",
        borderRadius: "8px",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "6fr 4fr",
      }}
    >
      {/* filter section */}
      {/* <div
        style={{
          marginTop: "80px",
        }}
      >
        <BuilderFilterComp
          customLessonData={customLessonData}
          levelNameCurriculum={levelNameCurriculum}
          levelNameInput={levelNameInput}
          setLevelNamesFilter={setLevelNamesFilter}
          levelNamesFilter={levelNamesFilter}
          setLevelItemNamesFilter={setLevelItemNamesFilter}
          levelItemNamesFilter={levelItemNamesFilter}
          setSearchWord={setSearchWord}
          searchWord={searchWord}
          selectedLevelFilter={selectedLevelFilter}
          setSelectedLevelFilter={setSelectedLevelFilter}
        />
      </div> */}
      {/* lessons */}

      <div
        style={{
          height: "85vh",
          background: "white",
          borderRadius: "4px",
          boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
          padding: "0 10px ",
          overflowY: "hidden",
        }}
      >
        <div style={{}}>
          <h1 style={{ fontSize: "22px" }}>Build Your Own Lesson Plans</h1>
          <BuilderFilterComp
            customLessonData={customLessonData}
            levelNameCurriculum={levelNameCurriculum}
            levelNameInput={levelNameInput}
            setLevelNamesFilter={setLevelNamesFilter}
            levelNamesFilter={levelNamesFilter}
            setLevelItemNamesFilter={setLevelItemNamesFilter}
            levelItemNamesFilter={levelItemNamesFilter}
            setSearchWord={setSearchWord}
            searchWord={searchWord}
            selectedLevelFilter={selectedLevelFilter}
            setSelectedLevelFilter={setSelectedLevelFilter}
            expandFilter={expandFilter}
            setExpandFilter={setExpandFilter}
          />
        </div>
        <div
          style={{
            // overflow: "hidden",
            // height: "100%",
            padding: "10px 0 0px",
            borderTop: "1px solid rgba(0,0,0,0.1)",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
            // background: "white",
            marginBottom: "10px",
            height: !expandFilter ? "calc(83% - 160px)" : "80%",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              overflow: "auto",
              height: "100%",
              padding: "0 0 90px 0",
            }}
          >
            {levelNameCurriculum?.map((itemTitle, index) => (
              <BuilderLevelCompSub
                itemTitle={itemTitle}
                levelNamesFilter={levelNamesFilter}
                levelNameInput={levelNameInput}
                levels={levels}
                searchWord={searchWord}
                addLesson={addLesson}
                deleteLesson={deleteLesson}
                selectedLessons={selectedLessons}
                setShowFilter={setShowFilter}
                setSelectedLessonIdPlayer={setSelectedLessonIdPlayer}
                selectedLessonIdPlayer={selectedLessonIdPlayer}
                setLevelItemNamesFilter={setLevelItemNamesFilter}
                levelItemNamesFilter={levelItemNamesFilter}
                setSelectedLesson={setSelectedLesson}
              />
            ))}
          </div>
        </div>
      </div>

      {/* filter section */}
      <div
        style={{
          // padding: "10px",
          width: "100%",
          // maxWidth: "600px",
          // right: "30px",
        }}
      >
        <BuilderBasketNew
          selectedLessons={selectedLessons}
          setSelectedLessons={setSelectedLessons}
          editLevelId={editLevelId}
          title={title}
          setTitle={setTitle}
          setSelectedLessonIdPlayer={setSelectedLessonIdPlayer}
          selectedLessonIdPlayer={selectedLessonIdPlayer}
          addLesson={addLesson}
          deleteLesson={deleteLesson}
          setSelectedLesson={setSelectedLesson}
          selectedLesson={selectedLesson}
        />
      </div>
    </div>
  );
};

export default BuilderLevels;
