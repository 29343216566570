import { Grid, makeStyles } from "@material-ui/core";
import CurriculumCard from "components/Cards/eai/CurriculumCard";

import componentStyles from "assets/theme/views/admin/dashboard.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";

const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

export const LevelsGrid = ({ data }) => {
  const classes = { ...useStyles(), ...useStylesCardDeck() };
  return (
    <Grid container>
      {data.map((level) => (
        <Grid
          key={level.id}
          item
          xs={12}
          sm={6}
          md={4}
          classes={{ root: classes.gridItemRoot }}
        >
          <CurriculumCard
            title={level.name}
            image={level.image}
            id={level.id}
          />
        </Grid>
      ))}
    </Grid>
  );
};
