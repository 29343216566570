import React, { useState } from "react";
import ClassCards from "./HomePageComponents/ClassCards";
import StudentPageHeader from "./HomePageComponents/StudentPageHeader";
import useStore from "zustand";
import SideBarAi from "./HomePageComponents/SideBarAi";
// import NextActivity from "./HomePageComponents/NextActivity";
import progImage from "../studentpage/assets/Frame 2558.png";
import SidebarNew from "./SidebarNew";
import { StudentHomePageWrapper } from "./StudentHomepage.styles";
const StudentHomepage = () => {
  const [selectedClass, setSelectedClass] = useState("");
  const { selectedClassId, setSelectedClassId } = useStore();
  return (
    <div
      style={{
        position: "relative",
        minHeight: "100vh",
        overflowX: "hidden",
        marginTop: "-10px",
      }}
    >
      <StudentHomePageWrapper>
        <StudentPageHeader title={"homepage"} />
        <ClassCards setSelectedClassId={setSelectedClass} selectedClass={selectedClass} />
        <div
          className="image-landing"
          style={{
            width: "100%",
            overflow: "auto",
            borderRadius: "18px",
            marginTop: "40px",
            // height: "440px",
            // overflowX: "visible",
          }}
        >
          <img
            src={progImage}
            alt=""
            srcSet=""
            style={{
              width: "140%",
              padding: "0",
              // height: "95%",
              margin: "0",
            }}
          />
        </div>
      </StudentHomePageWrapper>
      {false && <SidebarNew />}
      <SideBarAi />
    </div>
  );
};

export default StudentHomepage;
