export default {
  contents: [
    {
      kind: "CATEGORY",
      contents: [
        {
          kind: "block",
          type: "controls_if",
        },
        {
          kind: "BLOCK",
          type: "logic_compare",
        },
        {
          kind: "BLOCK",
          type: "logic_operation",
        },
        {
          kind: "BLOCK",
          type: "logic_negate",
        },
        {
          kind: "BLOCK",
          type: "logic_boolean",
        },
        {
          kind: "BLOCK",
          type: "logic_null",
          // disabled: "true",
        },
        {
          kind: "BLOCK",
          type: "logic_ternary",
        },
      ],
      name: "Logic",
      categorystyle: "logic_category",
    },
    {
      kind: "CATEGORY",
      contents: [
        {
          kind: "BLOCK",
          type: "controls_repeat_ext",
        },
        // {
        //   kind: "BLOCK",
        //   type: "controls_repeat",
        //   disabled: "true",
        // },
        {
          kind: "BLOCK",
          type: "controls_whileUntil",
        },
        {
          kind: "BLOCK",
          type: "controls_for",
        },
        {
          kind: "BLOCK",
          type: "controls_forEach",
        },
        {
          kind: "BLOCK",
          type: "controls_flow_statements",
        },
      ],
      name: "Loops",
      categorystyle: "loop_category",
    },
    {
      kind: "CATEGORY",
      contents: [
        {
          kind: "BLOCK",
          type: "math_number",
          gap: "32",
        },
        {
          kind: "BLOCK",
          type: "math_arithmetic",
        },
        {
          kind: "BLOCK",
          type: "math_single",
        },
        {
          kind: "BLOCK",
          type: "math_trig",
        },
        {
          kind: "BLOCK",
          type: "math_constant",
        },
        {
          kind: "BLOCK",
          type: "math_number_property",
        },
        {
          kind: "BLOCK",
          type: "math_round",
        },
        {
          kind: "BLOCK",
          type: "math_on_list",
        },
        {
          kind: "BLOCK",
          type: "math_modulo",
        },
        {
          kind: "BLOCK",
          type: "math_constrain",
        },
        {
          kind: "BLOCK",
          type: "math_random_int",
        },
        {
          kind: "BLOCK",
          type: "math_random_float",
        },
        {
          kind: "BLOCK",
          type: "math_atan2",
        },
      ],
      name: "Math",
      categorystyle: "math_category",
    },
    {
      kind: "CATEGORY",
      contents: [
        {
          kind: "BLOCK",
          type: "text",
        },
        {
          kind: "BLOCK",
          type: "text_multiline",
        },
        {
          kind: "BLOCK",
          type: "text_join",
        },
        {
          kind: "BLOCK",
          type: "text_append",
        },
        {
          kind: "BLOCK",
          type: "text_length",
        },
        {
          kind: "BLOCK",
          type: "text_isEmpty",
        },
        {
          kind: "BLOCK",
          type: "text_indexOf",
        },
        {
          kind: "BLOCK",
          type: "text_charAt",
        },
        {
          kind: "BLOCK",
          type: "text_getSubstring",
        },
        {
          kind: "BLOCK",
          type: "text_changeCase",
        },
        {
          kind: "BLOCK",
          type: "text_trim",
        },
        {
          kind: "BLOCK",
          type: "text_count",
        },
        {
          kind: "BLOCK",
          type: "text_replace",
        },
        {
          kind: "BLOCK",
          type: "text_reverse",
        },
        {
          kind: "LABEL",
          text: "Input/Output:",
          "web-class": "ioLabel",
        },
        {
          kind: "BLOCK",
          type: "text_print",
        },
        {
          kind: "BLOCK",
          type: "text_prompt_ext",
        },
      ],
      name: "Text",
      categorystyle: "text_category",
    },
    {
      kind: "CATEGORY",
      contents: [
        {
          kind: "BLOCK",
          type: "lists_create_with",
        },
        {
          kind: "BLOCK",
          type: "lists_create_with",
        },
        {
          kind: "BLOCK",
          type: "lists_repeat",
        },
        {
          kind: "BLOCK",
          type: "lists_length",
        },
        {
          kind: "BLOCK",
          type: "lists_isEmpty",
        },
        {
          kind: "BLOCK",
          type: "lists_indexOf",
        },
        {
          kind: "BLOCK",
          type: "lists_getIndex",
        },
        {
          kind: "BLOCK",
          type: "lists_setIndex",
        },
        {
          kind: "BLOCK",
          type: "lists_getSublist",
        },
        {
          kind: "BLOCK",
          type: "lists_split",
        },
        {
          kind: "BLOCK",
          type: "lists_sort",
        },
        {
          kind: "BLOCK",
          type: "lists_reverse",
        },
      ],
      name: "Lists",
      categorystyle: "list_category",
    },
    {
      kind: "CATEGORY",
      contents: [
        {
          kind: "BLOCK",
          type: "colour_picker",
        },
        {
          kind: "BLOCK",
          type: "colour_random",
        },
        {
          kind: "BLOCK",
          type: "colour_rgb",
        },
        {
          kind: "BLOCK",
          type: "colour_blend",
        },
      ],
      name: "Colour",
      categorystyle: "colour_category",
    },
    {
      kind: "SEP",
    },
    {
      kind: "CATEGORY",
      name: "Variables",
      categorystyle: "variable_category",
      custom: "VARIABLE",
    },
    {
      kind: "CATEGORY",
      name: "Functions",
      categorystyle: "procedure_category",
      custom: "PROCEDURE",
    },
  ],
  xmlns: "https://developers.google.com/blockly/xml",
  id: "toolbox-categories",
  style: "display: none",
};
