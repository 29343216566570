import React from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import CardHeader from "@material-ui/core/CardHeader";
// @material-ui/icons components
import CardActionArea from "@material-ui/core/CardActionArea";
import 'assets/css/myStyle.css'

// core components
import { chartOptions, parseOptions } from "variables/charts.js";

import componentStyles from "assets/theme/components/cards/charts/card-total-orders.js";
import { Button, CardActions, CardMedia, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles(componentStyles);

function GeneralCurriculumCard({
  title,
  subtitle,
  image,
  link,
  secondaryButton,
  maxWidth,
  downloadUrl,
  blur,
  type,
  minWidth
}) {
  const classes = useStyles();
  const theme = useTheme();
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  return (
    <Card
      style={{ maxWidth: maxWidth ?? 345, maxHeight: "380px", minWidth: minWidth, marginBottom: 15, background: 'white' }}
      classes={{ root: classes.cardRoot }}
      class="zoom"
    >
      {type === "Misc" ? (
        <a download href={downloadUrl}>
          <CardActionArea>
            {image != null ? (
              <CardMedia
                component="img"
                style={{
                  width: "100%",
                  height: 157,
                  filter: blur,
                  borderTopLeftRadius: '0.375rem',
                  borderTopRightRadius: '0.375rem',
                  maxHeight: 100
                }}
                image={image}
                alt=""
              />
            ) : null}
            <CardContent style={{ background: 'white', padding: "1rem", filter: blur }}>
              <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                <Typography noWrap gutterBottom variant="h3" component="div">
                  {title}
                </Typography>
              </div>
              <Typography gutterBottom variant="h5" component="div">
                {subtitle}
              </Typography>
            </CardContent>
          </CardActionArea>
        </a>
      ) : (
        <Link to={link} >
          <CardActionArea >
            {image != null ? (
              <CardMedia
                component="img"
                style={{
                  width: "100%",
                  height: 157,
                  filter: blur,
                  borderTopLeftRadius: '0.375rem',
                  borderTopRightRadius: '0.375rem',
                  maxHeight: 160
                }}
                image={image}
                alt=""
              />
            ) : null}
            <CardContent style={{ padding: "1rem", filter: blur }}>
              <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                <Typography noWrap gutterBottom variant="h3" component="div">
                  {title}
                </Typography>
              </div>
              <Typography gutterBottom variant="h5" component="div">
                {subtitle}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Link>
      )}
      <CardActions style={{ filter: blur, padding: " 20px 16px " }}>
        {type === "Misc" ? (
          <a download href={downloadUrl}>
            <Button color="primary" variant="contained" size="small">
              Download
            </Button>
          </a>
        ) : (
          <Link to={link}>
            <Button color="primary" variant="contained" size="small">
              View
            </Button>
          </Link>
        )}

        {secondaryButton}
      </CardActions>
    </Card>
  );
}

export default GeneralCurriculumCard;

// {
//   (type === 'Misc') ?
//   <a download href={downloadUrl}> <Button
//     color="primary"
//     variant="contained"
//     size="small"
//   >
//     Download
//   </Button>
//   </a>
//   :
//   <Link to={link} >
//     <Button color="primary" variant="contained" size="small">
//       View
//     </Button>
//   </Link>
// }
