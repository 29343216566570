import React from "react";
import useCurriculumApi from "api/curriculum";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import DashboardEmptyState from "../datasite/DataSiteComponents/DataSiteHomePage/DashboardEmptyState";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import CurriculumCard from "./CurriculumHome/CurriculumCard";
import { Skeleton } from "@mui/material";

const CurriculumHomePage = () => {
  const location = useLocation();
  const { fetchLevels, fetchSubjects } = useCurriculumApi();
  const {
    data: dataSubjects,
    status: statusSubjects,
    isLoading: isLoadingSubject,
  } = useQuery("subjectsDatasite", () => fetchSubjects(), {
    refetchOnWindowFocus: false,
  });

  // console.log("dataSubjects :>> ", dataSubjects);
  // console.log("dataSubjects :>> ", dataSubjects);
  // console.log("dataSubjects :>> ", dataSubjects);
  if (isLoadingSubject) {
    return (
      <div>
        <div
          style={{
            maxWidth: location.pathname.includes("data") ? "none" : "1280px",
            width: location.pathname.includes("data") ? "100%" : "95%",
            margin: "auto",
          }}
        >
          <Link to={"/curriculum/subjects/"}>
            <h3
              style={{
                fontSize: location.pathname.includes("data") ? "24px" : "18px",
                textTransform: "none",
                fontFamily: "Open Sans",
                color: "#7B8794",
                fontWeight: location.pathname.includes("data") ? "400" : "600",
                letterSpacing: !location.pathname.includes("data") && 1.5,
                margin: "30px 0 20px 0",
              }}
            >
              Available Curriculums
            </h3>
          </Link>
          <div
            style={{
              gap: "20px",
              borderRadius: "8px",
              padding: "20px 10px",
              border: "0px solid  #05649D",
              boxShadow: "0 5px 15px hsla(0,0%, 0%, .2)",
              transition: "height 0.2s ease-out",
              background: "rgb(255, 255, 255,0.1)",
              display: "flex",
              flexDirection: "row",
              flexFlow: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {[1, 2, 3].map((item) => (
              <Skeleton
                count={1}
                width="350px"
                height={108}
                style={{ transform: "none", margin: "10px" }}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
  const Icon = (
    <EqualizerIcon
      style={{
        width: "80px",
        height: "80px",
        color: "#f8f9fe",
        backgroundColor: "#bdbdbd",
        padding: "1.2rem",
        borderRadius: "50%",
        marginBottom: "1rem",
      }}
    />
  );
  if (statusSubjects === "success" && dataSubjects.data.length === 0) {
    return (
      <DashboardEmptyState
        icon={Icon}
        title="No available curriculums"
        text="Please contact your adminsitrator"
      />
    );
  }
  if (dataSubjects.data.length > 0) {
    return (
      <div
        style={{
          maxWidth: location.pathname.includes("data") ? "none" : "1280px",
          width: location.pathname.includes("data") ? "100%" : "95%",
          margin: "auto",
        }}
      >
        <div
          style={{
            margin: "50px 0 10px",
          }}
        >
          <Link to={"/curriculum/subjects/"}>
            <h3
              style={{
                fontSize: location.pathname.includes("data") ? "24px" : "18px",
                textTransform: "none",
                fontFamily: "Open Sans",
                color: "#7B8794",
                fontWeight: location.pathname.includes("data") ? "400" : "600",
                letterSpacing: !location.pathname.includes("data") && 1.5,
                margin: "30px 0 20px 0",
              }}
            >
              Available Curriculums
            </h3>
          </Link>
        </div>

        <div
          style={{
            gap: "20px",
            borderRadius: "8px",
            padding: "20px 20px",
            border: "0px solid  #05649D",
            boxShadow: "0 5px 15px hsla(0,0%, 0%, .2)",
            transition: "height 0.2s ease-out",
            background: "rgb(255, 255, 255,0.1)",
          }}
        >
          {dataSubjects.data
            ?.filter((topic) => topic.name !== "Sample")
            ?.filter((topic) => topic.name !== "Custom")
            ?.sort((a, b) => (a.name < b.name ? -1 : a.name < b.name ? 1 : 0))
            ?.map((topic) => (
              <div
                style={{
                  borderRadius: "4px",
                  margin: "0px 0 20px",
                  overflow: "hidden",
                }}
                key={topic.id}
              >
                <div style={{}}>
                  <h5
                    style={{
                      background: "#06659D",
                      color: "white",
                      padding: "8px 20px",
                    }}
                  >
                    {topic.name}
                  </h5>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      padding: "30px",
                      borderRadius: "4px",
                      flexWrap: "wrap",
                    }}
                  >
                    {topic.categories?.map((cat) => (
                      <CurriculumCard cat={cat} key={cat.id} />
                    ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  } else {
    return <h1>no data</h1>;
  }
};

export default CurriculumHomePage;
