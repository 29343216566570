import useUserApi from "api/user";
import useDataApi from "api/data";
import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
// import CirclePerformCard from "./CirclePerformCard";
import ClassCompThinkScore from "./ClassCompThinkScore";
import StudentCircleCard from "../../components/DataCircles/StudentCircleCard";
import Skeleton from "react-loading-skeleton";

const ClassPerformance = () => {
  const { classId } = useParams();

  const { fetchCurrentClass } = useUserApi();
  const { fetchStudentPerformance } = useDataApi();

  const {
    data: studentPerformance,
    status: studentPerformanceStatus,
    isLoading: studentPerformanceLoading,
  } = useQuery(
    ["student_performance", classId],
    () => fetchStudentPerformance(classId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: currentClass,
    status: currentClassStatus,
    isLoading: isLoadingClass,
  } = useQuery(["classItem", classId], () => fetchCurrentClass(classId), {
    refetchOnWindowFocus: false,
  });
  if (classId === null) {
    return <h2>no val</h2>;
  }

  if (isLoadingClass || studentPerformanceLoading) {
    return (
      <div
        style={{
          display: "flex",
          margin: "25px 0",
          gap: "20px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            gap: "20px",
            width: "100%",
          }}
        >
          <div
            // key={key}
            style={{
              background: "white",
              height: "370px",
              padding: "40px 20px",
              boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Skeleton count={1} width={240} height={240} circle={true} />
          </div>
          <div
            // key={key}
            style={{
              background: "white",
              padding: "40px 20px",
              height: "370px",
              boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Skeleton count={1} width={240} height={240} circle={true} />
          </div>
          <div
            // key={key}
            style={{
              background: "white",
              padding: "40px 20px",
              height: "370px",
              boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Skeleton count={1} width={240} height={240} circle={true} />
          </div>
          <ClassCompThinkScore />
        </div>
      </div>
    );
  }

  return (
    <div style={{ margin: "20px 0 40px" }}>
      {/* <h3>Class Overview</h3> */}
      <div
        style={{
          display: "flex",
          margin: "25px 0",
          gap: "20px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            gap: "20px",
            width: "100%",
          }}
        >
          <StudentCircleCard
            data={studentPerformance}
            status={studentPerformanceStatus}
            type="worst"
            title="Need Help"
            color="#eec27c"
          />
          <StudentCircleCard
            data={studentPerformance}
            status={studentPerformanceStatus}
            type="middle"
            title="On Target"
            color="#7Fcdee"
          />
          <StudentCircleCard
            data={studentPerformance}
            status={studentPerformanceStatus}
            type="best"
            title="Need Challenge"
            color="#8acb5f"
          />
          <ClassCompThinkScore />
        </div>
      </div>
    </div>
  );
};

export default ClassPerformance;
