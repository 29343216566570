import create from "zustand";
import { devtools } from "zustand/middleware";

const moveLessonRightLeft = (id, direction, lessons) => {
  const fromIndex = lessons.findIndex((lesson) => lesson.id === id);
  const toIndexRight = fromIndex + direction;
  const element = lessons.splice(fromIndex, 1)[0];
  lessons.splice(toIndexRight, 0, element);
  return lessons;
};

const addNewLesson = (lessons, newLesson) => {
  if (!lessons.some((l) => l.id === newLesson.id)) {
    return [...lessons, newLesson];
  }
  return lessons;
};
const CHE_DOMAIN = `${process.env.REACT_APP_CHE_DOMAIN}`;
// see https://che-che.${che_domain}/api/keycloak/settings for config options like realm and clientid

const store = (set, get) => ({
  lessonLearningObjectives: {},
  setLessonLearningObjectives: (response) => set(() => ({ lessonLearningObjectives: response })),

  view: false,
  setView: () => set((state) => ({ view: !state.view })),

  lessonFromClass: "",
  setLessonFromClass: (lesson_id) => set(() => ({ lessonFromClass: lesson_id })),

  dataFromApi: [],
  setData: (response) => set(() => ({ dataFromApi: response })),

  breadCrumbData: [],
  setBreadData: (response) => set((state) => ({ breadCrumbData: response })),

  activityBatchQuestions: [],
  setActivityBatchQuestions: (response) => set((state) => ({ activityBatchQuestions: response })),

  authToken: "",
  setAuthToken: (authToken) => set(() => ({ authToken })),

  accessToken: "",
  setAccessToken: (accessToken) => set(() => ({ accessToken })),

  keycloak: null,
  setKeycloak: (keycloak) => set(() => ({ keycloak })),

  userId: "",
  setUserId: (userId) => set(() => ({ userId })),

  codeUrl: "",
  setCodeUrl: (codeUrl) => set(() => ({ codeUrl })),

  tenantId: null,
  setTenantId: (tenantId) => set(() => ({ tenantId: tenantId })),

  classIdNavbar: null,
  setClassIdNavbar: (classIdNavbar) => set(() => ({ classIdNavbar: classIdNavbar })),

  schoolName: null,
  setSchoolName: (name) => set(() => ({ schoolName: name })),

  lessonPlanId: null,
  setLessonPlanId: (lessonID) => set(() => ({ lessonPlanId: lessonID })),

  userIcon: null,
  setUserIcon: (userIcon) => set(() => ({ userIcon })),

  searchWord: "",
  setSearchWord: (value) => set(() => ({ searchWord: value })),

  mobileOpen: false,
  setMobileOpen: () => set((state) => ({ mobileOpen: !state.mobileOpen })),

  level: [],
  resetFilter: () => set(() => ({ level: [] })),
  setLevel: (newValue) => set((state) => ({ level: [...state.level, newValue] })),
  deleteLevel: (value) => set((state) => ({ level: state.level.filter((item) => item !== value) })),

  lessons: [],
  // setLesson: (newLessonArr) => set(() => ({ lessons: newLessonArr })),
  // addLesson: (newlesson) =>
  //   set((state) => ({ lessons: addNewLesson(state.lessons, newlesson) })),
  // emptyLesson: () => set(() => ({ lessons: [] })),
  // deleteLesson: (value) =>
  //   set((state) => ({
  //     lessons: state.lessons.filter((item) => item.id !== value),
  //   })),
  // moveLesson: (id, direction) =>
  //   set((state) => ({
  //     lessons: moveLessonRightLeft(id, direction, [...state.lessons]),
  //   })),

  list: [],
  setList: (file) => set(() => ({ list: file })),
  // new Additions
  // lessons: [],
  cheDomain: CHE_DOMAIN,
  exercises: {
    h5p: [],
    code: [],
  },
  workspaces: {
    custom: [],
    withExercises: [],
    all: [],
  },
  setCheDomain: (cheD) => set(() => ({ cheDomain: cheD })),

  selectedClassId: null,
  setSelectedClassId: (classID) => set(() => ({ selectedClassId: classID })),

  setLessons: (lessons) =>
    set(() => {
      const h5p = [];
      const code = [];
      lessons.map((les) => {
        if (les.exercises && les.exercises.length) {
          les.exercises.map((ex) => {
            if (les.image) ex.image = les.image;
            if (ex.type && ex.type === "H5P") {
              ex.lesson_id = les.id;
              h5p.push(ex);
              // code.push(ex);
            } else if (ex.type && ex.type === "Python") {
              code.push(ex);
            }
          });
        }
      });
      return { lessons, exercises: { h5p, code } };
    }),
  setWorkspaces: (workspaces) =>
    set(() => {
      const custom = [];
      const withExercises = [];
      const exercises = get().exercises;
      for (const ws of workspaces) {
        let classId = 0;
        let exerciseId = "";
        if (ws.devfile && ws.devfile.components && ws.devfile.components.length) {
          for (const cmp of ws.devfile.components) {
            if (cmp.env && cmp.env.length) {
              for (const env of cmp.env) {
                if (env.name && env.name === "ACTIVITY" && env.value && env.value.length) {
                  exerciseId = env.value;
                }
                if (env.name && env.name === "CLASSID" && env.value && env.value.length) {
                  classId = env.value;
                }
              }
            }
          }
        }

        if (classId === get().selectedClassId) {
          if (exerciseId.length) {
            withExercises.push(ws);

            const exIndex = exercises.code.findIndex((ex) => ex.id === exerciseId);
            if (exIndex >= 0) {
              exercises.code[exIndex].workspaceId = ws.id;
            }
          }
        } else if (!exerciseId.length) {
          custom.push(ws);
        }
      }

      return {
        workspaces: { custom, withExercises, all: workspaces },
        exercises,
      };
    }),
});

const useStore = process.env.REACT_APP_ENVIRONMENT == "local" ? create(devtools(store)) : create(store);

export default useStore;
