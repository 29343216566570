import useCurriculumApi from "api/curriculum";
import React from "react";
import { useQuery } from "react-query";
import LessonPreviewPlayerEdit from "./LessonPreviewPlayerEdit";
import CustomLessonItemEdit from "./CustomLessonItemEdit";

const LessonPreviewCustomEdit = ({
  editedClassLevel,
  selectedLessonId,
  setSelectedLessonId,
  setEditedClassLesson,
  editedClassLesson,
  setSelectedCurriculumLesson,
}) => {
  const { fetchLessonPlanId } = useCurriculumApi();
  const [selectedLessonName, setSelectedLessonName] = React.useState("");

  //fetch fetchLessonPlan
  const {
    data: lessonPlanData,
    status: lessonPlanStatus,
    isLoading,
  } = useQuery(["lessonPlanData", editedClassLevel], () => fetchLessonPlanId(editedClassLevel), {
    refetchOnWindowFocus: false,
  });
  //   console.log("lessonPlanData &&lessonPlanData.data :>> ", lessonPlanData && lessonPlanData.data);
  //   console.log("lessonPlanData &&lessonPlanData.data :>> ", lessonPlanData && lessonPlanData.data);
  //   console.log("lessonPlanData &&lessonPlanData.data :>> ", lessonPlanData && lessonPlanData.data);
  //   console.log("lessonPlanData &&lessonPlanData.data :>> ", lessonPlanData && lessonPlanData.data);
  return (
    <div
      style={{
        boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
        borderRadius: "12px",
        overflow: "hidden",
        border: "4px solid rgb(6, 101, 157)",
        // flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "5px 20px",
          justifyContent: "center",
          //   borderRadius: "12px",
          background: "rgb(6, 101, 157)",
          color: "white",
          fontWeight: "600",
          fontSize: "18px",
        }}
      >
        Preview only
      </div>
      <div
        style={{
          display: "flex",
          //   gap: "10px",
          background: "rgb(6, 101, 157)",
        }}
      >
        {isLoading ? (
          <div
            style={{
              // display: "gird",
              // gridTemplateColumns: "1fr 1fr 1fr 1fr",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              // padding: "2px",
              // justifyContent: "space-between",
              borderRadius: "6px",
              overflow: "hidden",
              padding: "5px",
              background: "white",
              margin: "5px",
              width: "230px",
            }}
          ></div>
        ) : (
          <div
            style={{
              // display: "gird",
              // gridTemplateColumns: "1fr 1fr 1fr 1fr",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              // padding: "2px",
              // justifyContent: "space-between",
              borderRadius: "6px",
              overflow: "hidden",
              padding: "5px",
              background: "white",
              margin: "5px",
              // width: "20%",
            }}
          >
            {lessonPlanData &&
              lessonPlanData?.data?.lessons?.map((lesson, index) => (
                <CustomLessonItemEdit
                  lesson={lesson}
                  index={index}
                  setSelectedLessonName={setSelectedLessonName}
                  setSelectedLessonId={setSelectedLessonId}
                  setEditedClassLesson={setEditedClassLesson}
                  setSelectedCurriculumLesson={setSelectedCurriculumLesson}
                  editedClassLesson={editedClassLesson}
                />
              ))}
          </div>
        )}
        <div
          style={{
            background: "rgb(6, 101, 157)",
            width: "80%",
            maxHeight: "450px",
            height: "450px",
            padding: "5px",
          }}
        >
          <LessonPreviewPlayerEdit lessonId={editedClassLesson} />
        </div>
      </div>
    </div>
  );
};
export default LessonPreviewCustomEdit;
