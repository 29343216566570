import React, { useState } from "react";
import BuilderBasketLessonItem from "./BuilderBasketLessonItem";
// import BuilderBasketLessonItem from "./BuilderBasketLessonItem";
// import BuilderLessonCard from "./BuilderLessonCard";
// import { Draggable } from "react-beautiful-dnd";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import RemoveIcon from "@mui/icons-material/Remove";
// import ExpandIcon from "@mui/icons-material/Expand";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@mui/material/Snackbar";
import useStore from "../../../../zustand";
import "./notifcation-builder.css";
import useBuilderApi from "api/builder";
// import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import FolderIcon from "@mui/icons-material/Folder";
// import { useMutation, useQuery } from "react-query";
// import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import GeneralSnackbar from "components/Snackbar/GeneralSnackbar";
import useCurriculumApi from "api/curriculum";
import BuilderCurriculumPlayer from "./BuilderCurriculumPlayer";
// import ArticleIcon from "@mui/icons-material/Article";
import { useQuery } from "react-query";

const BuilderBasketNew = ({
  selectedLessons,
  setSelectedLessons,
  title,
  setTitle,
  editLevelId,
  selectedLessonIdPlayer,
  addLesson,
  deleteLesson,
  selectedLesson,
}) => {
  const [expand, setExpand] = useState(true);
  const { saveLevel, updateLevel, updateLevelBuilder } = useBuilderApi();
  const { createLessonPlan, updateCustomLessonPlanId } = useCurriculumApi();
  const { searchWord, userId } = useStore((state) => state);
  // const [title, setTitle] = useState("");
  const [snackMessage, setSnackMessage] = useState("");
  const [isPosted, setIsPosted] = useState(null);
  const [isCustomNameEnter, setIsCustomNameEnter] = useState(true);
  const [openTheSnackBar, setOpenTheSnackBar] = useState(null);
  const [tempSelectedLessonNum, setTempSelectedLessonNum] = useState(selectedLessons.length);
  const [animationClass, setAnimationClass] = useState("");
  const [expandLO, setExpandLO] = useState(true);
  function deleteLesson(lessonId) {
    const newLessons = selectedLessons?.filter((l) => l.id != lessonId);
    setSelectedLessons(newLessons);
  }
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const getCustomLessons = () => {
    let customLessons = [];
    for (let i = 0; i < selectedLessons.length; i++) {
      customLessons.push({ lesson: selectedLessons[i].id, position: i });
    }
    return customLessons;
  };
  const { fetchLessonObjectives, fetchResource } = useCurriculumApi();
  const {
    isLoading: isLoadingLO,
    isError: isErrorLO,
    data: learningObjectives,
    status,
    isSuccess: isSuccessLO,
  } = useQuery(
    ["lessonLearningObjectivesBuilder", selectedLessonIdPlayer],
    () => fetchLessonObjectives(selectedLessonIdPlayer),
    {
      enabled: selectedLessonIdPlayer?.length > 0,
      refetchOnWindowFocus: false,
    }
  );

  //Add a lesson to lesson plan
  const saveMyLevel = async () => {
    try {
      let body = {
        name: title,
        type: "online",
        author: userId,
        custom_lessons: getCustomLessons(),
      };
      // console.log("body :>> ", body);
      let response = null;
      if (selectedLessons?.length > 0 && title.length > 0) {
        setIsCustomNameEnter(true);
        if (editLevelId === undefined) {
          // response = await saveLevel(body);
          response = await createLessonPlan(body);
        } else {
          response = await updateCustomLessonPlanId(editLevelId, body);
        }
        // console.log("response :>> ", response);
        // console.log("response :>> ", response);
        // console.log("response :>> ", response);
        // console.log("response :>> ", response);
        // console.log("response :>> ", response);
        // console.log("response :>> ", response);
        if (response.status >= 200 && response.status < 300) {
          setTitle("");
          setExpand(false);
          setSelectedLessons([]);
          setOpenTheSnackBar(true);
          setIsPosted(true);
          setSnackMessage("Custom lesson successfully saved");
        } else {
          setIsPosted(false);
          setOpenTheSnackBar(true);
          setSnackMessage("Custom lesson NOT saved");
        }
      } else {
        setIsCustomNameEnter(false);
      }
      // response = await updateLevelBuilder(params.levelid, body);
      // }
      // setPreviewOpen(false);
      // queryClient.invalidateQueries("myLevels");
      // queryClient.invalidateQueries("lessons");
      // empty();
      // history.push(`/builder/build`);
    } catch (error) {
      console.error(error);
    }
  };
  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newLessons = reorder(selectedLessons, result.source.index, result.destination.index);

    setSelectedLessons(newLessons);
  }
  const getItemStyle = (draggableStyle, snapshot) => {
    if (!snapshot.isDropAnimating) {
      return {
        userSelect: "none",
        margin: `0 ${16}px 0 0`,
        ...draggableStyle,
      };
    }
    const { moveTo, curve, duration } = snapshot.dropAnimation;
    // move to the right spot
    const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
    // add a bit of turn for fun
    const rotate = "rotate(0.0turn)";

    return {
      ...draggableStyle,
      userSelect: "none",
      margin: `0 ${16}px 0 0`,
      transform: `${translate} ${rotate}`,
      transition: `all ${curve} 0.1s`,
    };
  };
  const getListStyle = () => ({
    display: "flex",
    padding: "25px",
    overflow: "auto",
  });
  const renameLessonName = (name) => {
    return name
      .replace("Stage 1", "")
      .replace("Stage 2", "")
      .replace("-", "")
      .replace("Stage 3", "");
  };
  React.useEffect(() => {
    if (tempSelectedLessonNum === selectedLessons.length) {
      setAnimationClass("notification-builder-page-main");
      setTempSelectedLessonNum(selectedLessons.length);
    } else {
      setAnimationClass("notification-builder-page notification-builder-page-main");
      setTimeout(() => {
        console.log("Interval triggered");
        setTempSelectedLessonNum(selectedLessons.length);
        setAnimationClass("notification-builder-page-main");
      }, 1000);
    }
  }, [selectedLessons]);

  return (
    <div
      style={{
        width: "100%",
        // height: "75vh",
        borderRadius: "4px",
      }}
    >
      <GeneralSnackbar
        message={snackMessage}
        openSnackbar={openTheSnackBar}
        setOpenSnackbar={setOpenTheSnackBar}
        isSuccess={isPosted}
        isError={!isPosted}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        isLoading={false}
      />

      <div
        style={{
          width: "100%",
          height: "85vh",
          display: "flex",
          gap: "20px",
        }}
      >
        <div
          style={{
            width: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "10px",
            boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
            // marginRight: "10px",

            background: "white",
            borderRadius: "4px",
          }}
        >
          {selectedLessonIdPlayer?.length > 0 ? (
            <BuilderCurriculumPlayer
              selectedLessonIdPlayer={selectedLessonIdPlayer}
              //   lessonName={renameLessonName(lesson.name)}
              learningObjectives={learningObjectives && learningObjectives?.data}
              addLesson={addLesson}
              deleteLesson={deleteLesson}
              selectedLesson={selectedLesson}
              selectedLessons={selectedLessons}
              expandLO={expandLO}
              setExpandLO={setExpandLO}
            />
          ) : (
            <h2 style={{ color: "grey" }}>Choose from left to preview lessons </h2>
          )}
        </div>
        <div
          style={{
            padding: "10px",
            width: "280px",
            boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
            background: "white",
            borderRadius: "4px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "280px",
              height: "30px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <h3
                  style={{
                    margin: "0 10px 0 0",
                    fontSize: "14px",
                  }}
                >
                  My Custom Lesson
                </h3>
              </div>
              <div style={{ position: "relative", marginLeft: "4px" }} class={animationClass}>
                <FolderIcon style={{ fontSize: "40px", color: "rgba(6, 101, 157,1)" }} />
                <div
                  style={{
                    position: "absolute",
                    top: "53%",
                    right: "50%",
                    // background: "#32325D",
                    background: "rgba(6, 101, 157, 1)",
                    // color: "#32325D",
                    color: "rgba(255,255,255,1)",
                    border: "2px solid white",
                    // background: "#32325D",
                    width: "28px",
                    height: "18px",
                    borderRadius: "6px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "12px",
                    fontWeight: "600",
                    transform: "translate(50%,-50%)",
                    boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                  }}
                >
                  {selectedLessons.length}
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                // height: "fit-content",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: "10px 0",
                }}
              >
                <div>
                  <input
                    style={{
                      border: "1px solid #CBD2D9",
                      fontSize: "12px",
                      marginRight: "0",
                      padding: "5px 12px",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "4px",
                      width: "130px",
                    }}
                    placeholder="Lesson name..."
                    value={title}
                    onChangeCapture={(e) => setTitle(e.target.value)}
                  ></input>
                  <button
                    style={{
                      background: "#06659D",
                      color: "white",
                      border: "none",
                      cursor: "pointer",
                      padding: "6px 6px",
                      fontSize: "12px",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                      fontWeight: "500",
                      margin: "0",
                    }}
                    onClick={() => saveMyLevel()}
                  >
                    Save
                  </button>
                </div>
                <button
                  style={{
                    background: selectedLessons.length > 0 ? "#06659D" : "rgba(0,0,0,0.1)",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                    padding: "6px 8px",
                    margin: "0",
                    fontSize: "12px",
                    borderRadius: "4px",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    if (selectedLessons.length > 0) {
                      setSelectedLessons([]);
                      setIsCustomNameEnter(true);
                    }
                  }}
                >
                  Clear
                </button>
              </div>
              {!isCustomNameEnter && (
                <p style={{ color: "red", margin: "4px 0 0px 0", display: "block" }}>
                  Custom lesson name is required.
                </p>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "16px",
                overflowY: "auto",
                padding: "10px 10px 10px",
                margin: "0 0 20px 0",
              }}
            >
              {selectedLessons &&
                selectedLessons?.map((item, index) => (
                  <BuilderBasketLessonItem
                    lesson={item}
                    item={item}
                    id={item.id}
                    deleteLesson={deleteLesson}
                    index={selectedLessons.findIndex((l) => l.id == item.id) + 1}
                    expand={expand}
                    selectedLessons={selectedLessons}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuilderBasketNew;
