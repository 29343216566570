import React from "react";
import { useEffect, useState, useRef } from "react";
import Iframe from "react-iframe";
import useStore from "../../../zustand";
import axios from "axios";

const H5P = ({ lessonId, exercise, classId }) => {
  // const { activityId } = useParams();
  const store = useStore((state) => state);
  const [userId, setUserId] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [H5PPlayerToken, setH5PPlayerToken] = useState(null);
  const myContainer = useRef(null);
  const [frameHeight, setFrameHeight] = useState();
  // console.log("accessToken (from H5p js comp): ", store.accessToken);
  const [lessonParams, setLessonParams] = useState({});

  function fetchParams() {
    if (lessonId != null) {
      return {
        class_id: classId,
        lesson_id: lessonId,
        exercise_id: exercise.id,
      };
    } else {
      return {
        class_id: classId,
        exercise_id: exercise.id,
      };
    }
  }
  useEffect(() => {
    if (store.accessToken) {
      setUserId(store.accountId);
      setTenantId(store.tenantId);
      axios
        .get(`${process.env.REACT_APP_API_PATH}/users/api/h5p_player_token`, {
          params: fetchParams(),
          headers: { Authorization: `Bearer ${store.accessToken}` },
        })
        .then((result) => {
          setH5PPlayerToken(result.data);
          // console.log("result: ", result.data);
        })
        .catch(function (error) {
          if (error.response) {
            console.log("error.response.status: ", error.response.status);
          }
        });
    }
  }, [exercise, classId, lessonId]);
  // console.log("useRef", myContainer.current);

  // console.log("//=====================//");
  // console.log("//=====================//");
  // console.log("h5ptoken: ", H5PPlayerToken);
  // console.log("classId: ", classId);
  // console.log("lessonId: ", lessonId);
  // console.log("exercise: ", exercise);
  // console.log("classAct: ", classAct.id);
  // // console.log("tenantId: ", store.tenantId);
  // console.log(
  //   "url: ",
  //   `${process.env.REACT_APP_H5P_PLAYER_URL}/?user=${userId}&exercise=${exercise.id}&tenant=${tenantId}&class_id=${classId}&lesson=${lessonId}&token=${H5PPlayerToken}`
  // );
  // if (userId == null || tenantId == null) {
  //   return null;
  // }

  return (
    <>
      <div
        style={{
          // position: "absolute",
          // top: "50%",
          height: "95%",
          // width: "90%",
          minWidth: "560px",
          // border: "6px solid #6358A5",
          position: "relative",
          // margin: "24px 28px",
          overflow: "hidden",
          // height: "80vh",
          width: "60vw",
          borderRadius: "14px",
          // backgroundColor: "#6358A5",
          // boxShadow: "0 15px 35px hsla(0, 0%, 0%, 0.2)",
        }}
      >
        {H5PPlayerToken !== null ? (
          <Iframe
            url={`${process.env.REACT_APP_H5P_PLAYER_URL}/?user=${store.userId}&exercise=${exercise.id}&tenant=${tenantId}&class_id=${classId}&token=${H5PPlayerToken}`}
            // url={`${process.env.REACT_APP_H5P_PLAYER_URL}/?user=${store.userId}&exercise=${exercise.id}&tenant=${tenantId}&class_id=${classId}&lesson=${lessonId}&token=${H5PPlayerToken}`}
            // url={`${process.env.REACT_APP_H5P_PLAYER_URL}/?token=${H5PPlayerToken}`}
            height="100%"
            width="100%"
            id="h5pIframe"
            display="block"
            position="relative"
            frameBorder={0}
            // scrolling="no"
            style={{ overflow: "hidden" }}
          />
        ) : null}
      </div>
    </>
  );
};

export default H5P;
