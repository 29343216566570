import React from "react";
import { Box, MuiThemeProvider } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import { createTheme } from "@mui/system";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1936,
    },
  },
});

const InsideView = ({
  data,
  inputRef,
  setContent,
  content,
  setTest,
  test,
  setIframId,
  setLessonId,
  isPythonFile,
}) => {
  return (
    <Box
      ref={inputRef}
      onClick={() => (
        setContent(data),
        setTest(!test),
        setIframId && setIframId(data.id),
        setLessonId && setLessonId(data.id)
      )}
      sx={{
        marginBottom: 10,
        marginTop: 5,
        padding: 10,
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        margin: 5,
        border: content?.id === data?.id ? "1px solid rgb(6, 101, 157)" : "",
        borderRadius: 6,
        background: "#dbdbdb4f",
      }}
    >
      <MuiThemeProvider
        theme={theme}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Avatar
          alt="Remy Sharp"
          class="card-hover"
          style={{
            width: 82.7,
            height: 45,
            borderRadius: 6,
            marginRight: 20,
            transform: content?.id === data?.id ? "scale(1.05)" : "scale(1)",
          }}
        >
          <img
            class="image-hover"
            src={data?.image}
            alt="icon"
            style={{
              width: 82.7,
              height: 45,
              objectFit: "cover",
              background: "white",
              borderRadius: 4,
              opacity: content?.id === data?.id ? 1 : 0.5,
              cursor: "pointer",
            }}
          />
        </Avatar>
        <h5
          style={{
            display: "flex",
            alignItems: "center",
            margin: 0,
            opacity: content?.id === data?.id ? 1 : 0.6,
          }}
        >
          {data.name
            ?.replace(`Stage 1`, "")
            .replace(`Stage 2`, "")
            .replace(`Stage 3`, "")
            .replace(`Stage 4`, "")
            .replace(`Stage 5`, "")
            .replace(`Stage 6`, "")
            .replace(`Level 1`, "")
            .replace(`Level 2`, "")
            .replace(`Level 3`, "")
            .replace(`Level 4`, "")
            .replace(`Level 5`, "")
            .replace(`Level 6`, "")}
        </h5>
      </MuiThemeProvider>
    </Box>
  );
};

export default InsideView;
