import React, { useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box, Button, MenuItem, styled } from "@mui/material";
import CustomLessonList from "./CustomLessonList";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import { useQueryClient, useMutation } from "react-query";
import useCurriculumApi from "api/curriculum.js";

const SaveLessonList = ({ level, lessonId, mutateUpdate, index, mutation }) => {
  const [message, setMessage] = useState(false);
  const [openToolpit, setOpenToolpit] = useState(false);
  const { updateCustomLessonPlanIdMutation } = useCurriculumApi();

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(updateCustomLessonPlanIdMutation, {
    onSuccess: () => queryClient.invalidateQueries("lessonPlanData"),
  });
  // Handle Tooltip
  const handleClose = () => {
    setOpenToolpit(false);
  };

  const handleOpen = () => {
    setOpenToolpit(true);
  };

  // Set message to null on mount
  React.useEffect(() => {
    !level?.lessons?.some((item) => item.id === lessonId) && setMessage(false);
  }, [level.lessons]);
  function mutateValues(id, body) {
    mutate({ id: id, body: body });
  }

  return (
    <CustomLevelTooltip
      title={<CustomLessonList levels={level} mutate={mutateValues} />}
      arrow
      placement="right"
      open={openToolpit}
      onClose={handleClose}
      onOpen={handleOpen}
    >
      <MenuItem
        autoFocus={true}
        key={level}
        selected={index === ""}
        // onClick={() => (handleMenuItemClick(level, index))}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              minWidth: 260,
            }}
          >
            <Box sx={{ color: "rgba(0, 0, 0, 0.77)" }}>
              <AddToQueueIcon fontSize="small" sx={{ marginRight: 1 }} /> {level.name}
            </Box>
            {level?.lessons?.map((item, index) => ({ lesson: item?.id, position: index }))[0] !==
            undefined ? (
              <Button
                variant="outlined"
                onClick={() => (
                  !level?.lessons?.some((item) => item.id === lessonId) &&
                    // mutateUpdate({
                    //   id: level?.id,
                    //   name: level?.name,
                    //   type: "online",
                    //   author: level?.author,
                    //   lessons: [
                    //     ...level?.lessons?.map((item, index) => ({
                    //       lesson: item?.id,
                    //       position: index,
                    //     })),
                    //     { lesson: lessonId, position: level?.lessons?.length },
                    //   ],
                    // }),
                    mutateValues(level?.id, {
                      name: level?.name,
                      type: "online",
                      author: level?.author,
                      custom_lessons: [
                        ...level?.lessons?.map((item, index) => ({
                          lesson: item?.id,
                          position: index,
                        })),
                        { lesson: lessonId, position: level?.lessons?.length },
                      ],
                    }),
                  level?.lessons?.some((item) => item.id === lessonId) && setMessage(true)
                )}
                style={{
                  marginLeft: 25,
                  padding: 0,
                  color: "rgb(6, 101, 157)",
                  background: "white",
                  textTransform: "none",
                  transform: "translateY(-1px)",
                  cursor: "pointer",
                }}
              >
                {!isLoading ? "Add" : "Loading"}
              </Button>
            ) : null}
          </Box>
          <Box>
            <p style={{ fontSize: 11, color: "red", margin: 0 }}>
              {message &&
                level?.lessons?.some((item) => item.id === lessonId) &&
                "*This lesson is already added!"}
            </p>
          </Box>
        </Box>
      </MenuItem>
    </CustomLevelTooltip>
  );
};

export default SaveLessonList;

const CustomLevelTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} disableFocusListener />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "none",
    width: "100%",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    minWidth: "320px",
    minHeight: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
