import React from "react";

const H5PTestingLearnObjItem = ({
  setSelectedLearningObjective,
  item,
  index,
  selectedLearningObjective,
  questionsData,
}) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        background: item.id === selectedLearningObjective ? "#6358A5" : "",
        color: item.id === selectedLearningObjective ? "white" : "",
        padding: "2px 8px",
        borderRadius: item.id === selectedLearningObjective ? "6px" : "",
      }}
      onClick={() => {
        if (item.id === selectedLearningObjective) {
          setSelectedLearningObjective("");
        } else {
          setSelectedLearningObjective(item.id);
        }
      }}
    >
      <div
        style={{
          display: "flex",
          width: "80%",
        }}
      >
        <p
          style={{
            margin: "0",
            fontSize: "14px",
            padding: "0",
          }}
        >
          {index + 1}
        </p>
        <div>
          <h5
            style={{
              margin: "0 0 0 9px",
              color: item.id === selectedLearningObjective ? "white" : "",
              padding: "0",
              fontSize: "14px",
            }}
          >
            {item.objective}
            {/* <h5
              style={{
                margin: "0 0 0 9px",
                color: item.id === selectedLearningObjective ? "white" : "",
                padding: "0",
                fontSize: "14px",
              }}
            >
              {item.id}
            </h5> */}
          </h5>
        </div>
      </div>
      <h5
        style={{
          margin: "0 0 0 9px",
          color: item.id === selectedLearningObjective ? "white" : "",
          padding: "0",
          fontSize: "14px",
          //   width: "20%",
        }}
      >
        {
          questionsData.filter((learnItem) =>
            learnItem.learning_objectives.includes(item.id)
          ).length
        }{" "}
        Quest.
      </h5>
    </div>
  );
};

export default H5PTestingLearnObjItem;
