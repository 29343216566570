import React from "react";
import {
  Box,
  Grid,
  IconButton,
  DialogContent,
  Dialog,
  // Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import TestDialog from "./TestDialog";
// import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import BoltIcon from "@mui/icons-material/Bolt";
import useStore from "../../../../../zustand";
import useCurriculumApi from "api/curriculum";
import { styled } from "@mui/material/styles";
import { createTheme } from "@mui/system";
// import AssignmentIcon from '@mui/icons-material/Assignment';
import { useQuery } from "react-query";
import TaskRoundedIcon from "@mui/icons-material/TaskRounded";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    // border: "3px solid rgba(0,0,0,0.8)",
    boxShadow: theme.shadows[3],
    fontSize: 16,
    textAlign: "center",
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    // height: "85vh",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles(() => ({
  // paper: { minWidth: "75vw" },
}));

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1936,
    },
  },
});

const CreateTest = ({
  saveData,
  mutate,
  classData,
  isLoading,
  isSuccess,
  isError,
  error,
  status,
  setOpenSnackbarQuiz,
  mutatePatch,
  isLoadingUpdate,
  selectedLesCurName,
}) => {
  const classes = { ...useStyles() };
  const [editClassOpen, setEditClassOpen] = React.useState(false);
  const [component, setComponent] = React.useState(true);
  const { lessonLearningObjectives } = useStore();
  const { fetchActivityName } = useCurriculumApi();

  const { data: activity, status: activityStatus } = useQuery(
    ["fetchActivityName", lessonLearningObjectives.lessonId],
    () => fetchActivityName(lessonLearningObjectives.lessonId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const activityName = activityStatus == "success" && activity?.data[0]?.name;
  lessonLearningObjectives.toForm && component && setComponent(false);

  // Snackbar disappear
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenSnackbar(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [openSnackbar]);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setComponent(true);
  };

  return (
    <>
      <LightTooltip
        title={
          <h4
            style={{
              fontSize: "16px",
              padding: "4px 10px",
              fontWeight: "400",
              margin: 0,
            }}
          >
            Assign Homework for{" "}
            {selectedLesCurName ? <strong>{selectedLesCurName}</strong> : "the selected lesson"}.
          </h4>
        }
        arrow
        placement="top"
      >
        <div
          onClick={() => {
            // mutatePatch({
            //   id: lessonLearningObjectives.classUuid,
            //   current_lesson: lessonLearningObjectives.lessonId,
            //   has_assigned_exercise: true,
            // });
            // setOpenSnackbarQuiz(true);
            saveData();
            handleClickOpen();
          }}
          style={{
            background: "#06659D",
            color: "white",
            border: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            padding: "8px 20px",
            fontSize: "14px",
            borderRadius: "6px",
            fontWeight: "500",
            boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
            margin: 0,
          }}
        >
          <TaskRoundedIcon style={{ width: "20px", height: "20px", marginRight: 7 }} />
          Assign
        </div>
      </LightTooltip>
      <BootstrapDialog
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        maxWidth="md"
        classes={{ paper: classes.paper }}
      >
        <DialogContent style={{ overflowY: "auto" }}>
          <Box
            style={{
              height: component ? "100%" : "100%",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
            classes={{ root: classes.containerRoot }}
          >
            <Grid
              container
              spacing={2}
              style={{
                maxWidth: 700,
                background: "white",
                padding: 10,
                borderRadius: 12,
              }}
            >
              <Grid item xs={12} style={{ padding: 0, margin: "15px 0px" }}>
                <IconButton
                  onClick={() => (
                    mutatePatch({
                      id: lessonLearningObjectives.classUuid,
                      current_lesson: lessonLearningObjectives.lessonId,
                      has_assigned_exercise: true,
                    }),
                    setOpenSnackbarQuiz(true),
                    setOpen(false)
                  )}
                  variant="contained"
                  style={{
                    width: "100%",
                    height: 159,
                    borderRadius: 20,
                    background: "transparent",
                    padding: 0,
                    minHeight: 159,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "100%",
                      height: 159,
                      background: "#06659D",
                      color: "white",
                      padding: "25px 60px",
                      borderRadius: 12,
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        fontFamily: "Open Sans",
                        fontSize: 18,
                        fontWeight: 600,
                        marginBottom: 5,
                      }}
                    >
                      {" "}
                      <BoltIcon style={{ width: 30, height: 30, marginRight: 5 }} /> Assign{" "}
                      {lessonLearningObjectives.lessonName || "lesson"} Activity to all students of{" "}
                      {lessonLearningObjectives?.className}
                    </p>
                  </div>
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default CreateTest;
