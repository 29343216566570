import * as React from "react";
import "./Style/style.css";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
    Divider,
    ListItemIcon,
    ListSubheader,
    OutlinedInput,
} from "@mui/material";
import { RootRef } from "@material-ui/core";
import { IconButton } from "@mui/material";
import { Box } from "@mui/material";
// import { makeStyles } from "@material-ui/core/styles";
// import componentStyles from "assets/theme/views/admin/alternative-dashboard.js";


// const useStyles = makeStyles(componentStyles);


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    autoFocus: false,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function CreateActivityDropdown({
    name,
    apiData,
    setCurrentID,
    setTopicLearningObj,
    setClassStudents,
    filteredData,
    apiStatus,
    header,
    setCustomValidationSelect,
    customValidationSelect,
    FormHelperText,
    maxWidth,
    helperText,
    dataFromLesson,
}) {
    // const classes = { ...useStyles() };
    const [object, setObject] = React.useState([]);
    const [objectListID, setObjectListID] = React.useState([]);

    const [mainData, setMainData] = React.useState([]);

    // set main data 
    const data = apiStatus === "success" && apiData.data
    React.useEffect(() => {
        setMainData(data)
    }, [apiStatus]);


    // to add Id of items to the array objectListID
    React.useEffect(() => {
        let listID = [];
        object?.length > 0 && object.map((item) => listID.push(item?.id));

        setObjectListID(listID);
        setCurrentID(listID);
    }, [object]);


    const isAllSelected = apiStatus === "success" && apiData.data.length === objectListID.length


    // valuesFromLesson for setting setObject() so it shows the names 
    React.useEffect(() => {
        const lessonLOandCCValues = apiStatus === "success" && apiData.data.filter((item) => dataFromLesson && dataFromLesson.includes(item.id))
        lessonLOandCCValues && setObject(lessonLOandCCValues)
    }, [dataFromLesson, apiStatus, mainData])

    // learning objecgtives and computational conpects from Lesson More Info
    React.useEffect(() => {
        dataFromLesson && setObjectListID(dataFromLesson)
    }, [dataFromLesson, apiStatus, mainData])


    // Auto validation based on array of objectListID
    React.useEffect(() => {
        objectListID.length > 0 && setCustomValidationSelect("valid");
    }, [objectListID])

    //Class Students
    React.useEffect(() => {
        let studentIDs = [];
        object.length > 0 &&
            object.map((item) => (studentIDs = studentIDs.concat(item?.students)));

        setClassStudents && setClassStudents(studentIDs);
    }, [object]);

    // Topic Learning Objective
    React.useEffect(() => {
        let topicID = [];
        object.length > 0 &&
            object.map((item) => (topicID = topicID.concat(item?.id)));

        setTopicLearningObj && setTopicLearningObj(topicID);
    }, [object]);

    // Adding target.value to the object state []
    const handleChange = (event) => {
        const { value } = event.target;

        if (value.includes("all")) {
            setObject(mainData);
            return;
        }

        setObject(value);
    };

    //Search
    const [searchWord, setSearchWord] = React.useState("");

    const filterList = (item, index, array) => {
        return searchWord.length > 0 &&
            item.name ?
            item.name.toLowerCase().includes(searchWord.toLowerCase())
            :
            item.full_name ?
                item.full_name.toLowerCase().includes(searchWord.toLowerCase())
                :
                item.objective ?
                    item.objective.toLowerCase().includes(searchWord.toLowerCase())
                    : array
    };

    if (RootRef.current) {
        RootRef.current.focus();
    }

    React.useEffect(() => {
        if (object.length > 0) {
            setCustomValidationSelect("valid");
        } else {
            setCustomValidationSelect("");
        }
    }, [object]);

    return (
        <div style={{
            maxWidth: '100%'
        }}>
            <FormControl sx={{ m: 0 }} fullWidth>
                <Select
                    multiple
                    value={object}
                    renderValue={(selected) =>
                        selected.map((item) => item !== undefined && item[name]).join(",  ")
                    }
                    onChange={handleChange}
                    MenuProps={MenuProps}
                    style={{
                        border: "1px solid #CBD2D9",
                        height: 40,
                        maxWidth: maxWidth,
                        fontSize: 14,
                        color: "#7B8794",
                        fontWeight: 400,
                        fontFamily: "Open Sans",
                    }}
                >
                    <ListSubheader
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "unset",
                        }}
                    >
                        <div
                            style={{
                                margin: "10px 0px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "cengter",
                            }}
                        >
                            <OutlinedInput
                                fullWidth
                                placeholder="Search"
                                size="small"
                                style={{ border: '1px solid rgb(203, 210, 217)', margin: "0px 8px" }}
                                value={searchWord}
                                onChange={(e) => setSearchWord(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key !== "Escape") {
                                        // Prevents autoselecting item while typing (default Select behaviour)
                                        e.stopPropagation();
                                    }
                                }}
                            />
                            <IconButton
                                style={{ borderRadius: "10px", padding: "0px 10px" }}
                                onClick={() => setObject([])}
                            >
                                <p
                                    style={{
                                        margin: "0px",
                                        fontSize: 12,
                                        color: "#6358A5",
                                        fontWeight: 600,
                                    }}
                                >
                                    Clear All
                                </p>
                            </IconButton>
                        </div>
                    </ListSubheader>

                    <MenuItem
                        value="all"
                        classes={{
                            // root: isAllSelected ? classes.selectedAll : ""
                        }}
                    >
                        <ListItemIcon>
                            <Checkbox
                                // classes={{ indeterminate: classes.indeterminateColor }}
                                checked={isAllSelected}
                                indeterminate={
                                    objectListID.length > 0 && objectListID.length < (mainData)
                                }
                                style={{ color: 'rgb(6, 101, 157)' }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            // classes={{ primary: classes.selectAllText }}
                            primary="Select all"
                        />
                    </MenuItem>
                    <Divider />
                    {apiStatus === "success" && apiData.data
                        .sort(
                            (a, b) =>
                                a[name] !== null &&
                                b[name] !== null &&
                                a[name].localeCompare(b[name])
                        )
                        .filter((item, index, array) => filterList(item, index, array))
                        .filter((item, index, array) =>
                            filteredData
                                ? filteredData.indexOf(item.id) > -1 ||
                                filteredData.indexOf(item.computational_concept) > -1
                                : array
                        )
                        .map((item, index) => (
                            <MenuItem key={index} value={item}>
                                <Checkbox
                                    checked={objectListID.indexOf(item["id"]) > -1}
                                    style={{ color: 'rgb(6, 101, 157)' }}
                                />
                                <ListItemText primary={item[name]} />
                            </MenuItem>
                        ))}

                </Select>


                {customValidationSelect === "valid" ? (
                    <FormHelperText
                        component={Box}
                        style={{
                            color: "#2dce89",
                            fontFamily: "Open Sans",
                            fontSize: 12,
                            fontWeight: 400,
                        }}
                    >
                        Looks good!
                    </FormHelperText>
                ) : (
                    <FormHelperText
                        component={Box}
                        style={{
                            color: "#fb6340",
                            fontFamily: "Open Sans",
                            fontSize: 12,
                            fontWeight: 400,
                        }}
                    >
                        {helperText}
                    </FormHelperText>
                )}
            </FormControl>
        </div>
    );
}
