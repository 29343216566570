import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box, FormHelperText } from "@material-ui/core";

const CurriculumSelectorEdit = ({
  curriculumValue,
  setCurriculumValue,
  setCloseSelection,
  setSelectedCurriculum,
  setEditedClassLevel,
  setSelectedCurriculumLesson,
  setEditedClassLesson,
  setSelectedLessonId,
}) => {
  const handleChange = (event) => {
    setCurriculumValue(event.target.value);
    setEditedClassLevel("");
    setSelectedCurriculum("");
    setSelectedCurriculumLesson("");
    setEditedClassLesson("");
    setSelectedLessonId("");
    setCloseSelection(true);
    console.log("value :>> ", curriculumValue);
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        gap: "30px",
        justifyContent: "space-between",
        margin: 0,
      }}
    >
      {/* <label
                style={{
                  margin: "0",
                }}
              >
                <h3
                  style={{
                    //   fontSize: "28px",
                    fontWeight: "600",
                    padding: "0px ",
                    margin: "0",
                    color: "#52606D",
                  }}
                >
                  Curriculum
                </h3>
              </label> */}

      <FormControl
        style={{
          width: "100%",
          margin: "0 auto",
          //   marginLeft: "10px",
        }}
      >
        <FormLabel
          style={{
            fontSize: 16,
            color: "#52606D",
            fontFamily: "Montserrat,sans-serif",
            margin: "0 0 0px 0",
            fontWeight: "600",
          }}
        >
          Curriculum
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={curriculumValue}
          onChange={handleChange}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            //   display: "flex",
            width: "100%",
            gap: "12px",
            margin: "10px auto 0 10px",
          }}
        >
          <FormControlLabel
            value="EAI Lesson Plan"
            control={
              <Radio
                style={{
                  color: curriculumValue === "EAI Lesson Plan" ? "white" : "",
                }}
              />
            }
            label="EAI Lesson Plan"
            style={{
              border: "2px solid rgba(0,0,0,0.2)",
              padding: "4px",
              width: "100%",
              borderRadius: "8px",
              background: curriculumValue === "EAI Lesson Plan" ? "#06659D" : "",
              color: curriculumValue === "EAI Lesson Plan" ? "white" : "",
            }}
          />
          <FormControlLabel
            value="Custom Lesson Plan"
            control={
              <Radio
                style={{
                  color: curriculumValue === "Custom Lesson Plan" ? "white" : "",
                }}
              />
            }
            label="Custom Lesson Plan"
            style={{
              border: "2px solid rgba(0,0,0,0.2)",
              padding: "4px",
              width: "100%",
              borderRadius: "8px",
              background: curriculumValue === "Custom Lesson Plan" ? "#06659D" : "",
              color: curriculumValue === "Custom Lesson Plan" ? "white" : "",
            }}
          />
        </RadioGroup>
        {!curriculumValue?.length > 0 && (
          <FormHelperText
            component={Box}
            style={{
              color: !curriculumValue?.length > 0 ? "#fb6340" : "rgba(0,0,0,0)",
              margin: "0",
            }}
          >
            "Please Select a Curriculum"
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default CurriculumSelectorEdit;
