import React, { useState } from "react";
import { SidebarNewWrapper } from "./SidebarNew.styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useMsal } from "@azure/msal-react";

import useStore from "../../../zustand";
import SidebarItem from "./SidebarItem";
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
const SidebarNew = ({ routes }) => {
  const { userId, authToken, userIcon } = useStore();
  const { accounts } = useMsal();
  const [windowSize, setWindowSize] = React.useState(getWindowSize());
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isSidebarText, setIsSidebarText] = useState(true);

  console.log("accounts :>> ", accounts[0].name.split(" ")[0].split());
  console.log("accounts :>> ", accounts[0]);
  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const handleSidebar = () => {
    if (isSidebarOpen) {
      setIsSidebarOpen(false);
      setIsSidebarText(false);
    } else {
      setIsSidebarOpen(true);
      setTimeout(() => setIsSidebarText(true), 300);
    }
  };
  React.useEffect(() => {
    if (windowSize.innerWidth < 1430) {
      setIsSidebarOpen(false);
      setIsSidebarText(false);
    }
  }, [windowSize.innerWidth]);
  console.log("windowSize.innerWidth ===========:>> ", windowSize.innerWidth);
  console.log("windowSize.innerWidth ===========:>> ", windowSize.innerWidth);
  console.log("windowSize.innerWidth ===========:>> ", windowSize.innerWidth);
  console.log("windowSize.innerWidth ===========:>> ", windowSize.innerWidth);
  return (
    <SidebarNewWrapper isSidebarOpen={isSidebarOpen}>
      <div>
        <div
          style={{
            border: "4px solid #6258A5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "65px",
            height: "65px",
            borderRadius: "1000px",
            margin: "0 auto 20px",
            position: "relative",
          }}
        >
          <div style={{ fontWeight: "600", fontSize: "22px", color: "#6258A5" }}>
            {accounts[0].name.split(" ")[0].split()[0].charAt(0)}
            {accounts[0].name.split(" ")[1].split()[0].charAt(0)}
          </div>
          <div
            style={{
              position: "absolute",
              width: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bottom: 0,
              right: 0,
              background: "white",
              borderRadius: "1000px",
              border: "2px solid #6258A5",
              transform: "translate(50%,3px)",
            }}
          >
            <img src={userIcon} alt="" style={{ width: "100%" }} />
          </div>
        </div>
        <div>
          {routes.map((route, index) => (
            <SidebarItem
              route={route}
              isSidebarOpen={isSidebarOpen}
              index={index}
              isSidebarText={isSidebarText}
            />
          ))}
        </div>
      </div>
      <div
        className="end-new-side-bar"
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "45px 15px",
          alignItems: "center",
          // margin: "5px 10px",
          // width: "250px",
          width: "100%",
          cursor: "pointer",
          gap: "10px",
        }}
        onClick={() => {
          handleSidebar();
        }}
      >
        {isSidebarText ? (
          <>
            <ArrowBackIcon
              style={{
                backgroundColor: "#6358A5",
                borderRadius: "200px",
                // padding: "20px",
                width: "30px",
                height: "30px",
                color: "white",
              }}
            />{" "}
            Hide menu
          </>
        ) : (
          <ArrowForwardIcon
            style={{
              backgroundColor: "#6358A5",
              borderRadius: "200px",
              // padding: "20px",
              width: "30px",
              height: "30px",
              color: "white",
            }}
          />
        )}
      </div>
    </SidebarNewWrapper>
  );
};

export default SidebarNew;
