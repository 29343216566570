import React, { useEffect, useRef, useState } from "react";
import { useBlocklyWorkspace } from "react-blockly";
import "../../../assets/css/style.css";
import toolboxConfig from "blockly/toolbox";
import BlocksHeader from "./components/BlocksHeader";
// import AceEditor from "react-ace";
// import CodeMirror from "@uiw/react-codemirror";
import Editor from "@monaco-editor/react";
import "xterm/css/xterm.css";
import useJudgeApi from "api/judge";
import { FitAddon } from "xterm-addon-fit";
// import ThemeHigh from "@blockly/theme-highcontrast";
// import ThemeTrit from "@blockly/theme-tritanopia";
// import ThemeDeuter from "@blockly/theme-deuteranopia";
// import Theme from "@blockly/theme-modern";
import usePersistStore from "../../../zustandPersist";
import Blockly from "blockly";
import useCurriculumApi from "api/curriculum";
import useStore from "../../../zustand";
// import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axios from "axios";
// import axios from "axios";
function XTerm({ stdOut }) {
  const xtermRef = useRef(null);
  const [currentOutput, setCurrentOutput] = useState("");
  const fitAddon = new FitAddon();

  useEffect(() => {
    const initTerminal = async () => {
      const { Terminal } = await import("xterm");

      xtermRef.current = new Terminal({
        cursorBlink: true,
        disableStdin: true,
      });

      xtermRef.current.loadAddon(fitAddon);
      xtermRef.current.open(document.getElementById("terminal"));
      fitAddon.fit();

      var shellprompt = "-> ";
      xtermRef.current.prompt = () => {
        xtermRef.current.write("\r\n" + shellprompt);
      };
      //your greeting
      xtermRef.current.write(shellprompt);
      var cmd = "";

      xtermRef.current.onKey((key) => {
        return;
      });
    };
    initTerminal();
  }, []);

  useEffect(() => {
    fitAddon.fit();
    if (xtermRef.current !== null) {
      if (stdOut === "") {
        xtermRef.current.clear();
        return;
      }
      const newOut = stdOut.replace(currentOutput, "");
      if (newOut !== "") {
        const newOutArr = newOut.split("\n");
        // console.log("newOutArr", newOutArr);
        newOutArr.forEach((ln) => {
          xtermRef.current.write(ln);
          if (ln !== "") {
            xtermRef.current.prompt();
          }
        });
      }
    }
  }, [stdOut]);
  return <div style={{ width: "100%", height: "100%" }} id="terminal" />;
}

const fetchBlocks = (exerciseId) => {
  const body = JSON.stringify({
    exercise_id: exerciseId,
  });
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  return axios.get(
    `https://blockcodeserverdev.azurewebsites.net/api/v1/get_block_coding_exercise_data`,
    body,
    headers
  );
};

const BlocksPageExercise = () => {
  // const { xmlCodeSelected } = usePersistStore();

  // const initialXml = <xml xmlns="http://www.w3.org/1999/xhtml"></xml>;
  const [showBlocks, setShowBlocks] = useState(true);
  const [savedBlocks, setSavedBlocks] = useState(null);
  const [code, setCode] = useState("");
  const blocklyDiv = useRef(null);
  const [showTerminal, setShowTerminal] = useState(false);
  const [subToken, setSubToken] = useState(null);
  const [stdOut, setStdOut] = useState("");
  const [gridView, setGridView] = useState(true);
  const [blockTheme, setBlockTheme] = useState(null);
  const [hintText, setHintText] = useState("");
  const { createSubmission, getSubmission } = useJudgeApi();
  const { sendBlockCodeStudent, sendBlockCodeClass, fetchLessonResources } =
    useCurriculumApi();
  const queryClient = useQueryClient();
  const { setBlocksTheme, blocksTheme } = usePersistStore();

  const { selectedClassId, userId } = useStore();
  const [theData, setTheData] = useState(null);
  const { exerciseId, lessonId, exerciseName } = useParams();
  const {
    fetchBlocksExerciseQuestion,
    fetchBlocksQuestionHint,
    fetchBlocksSubmitAnswer,
  } = useCurriculumApi();
  const history = useHistory();

  const { status, data, isLoading, error } = useQuery(
    ["fetchBlocksQuestion", exerciseId],
    () => fetchBlocksExerciseQuestion(exerciseId),
    {
      enabled: exerciseId.length > 0,
      refetchOnWindowFocus: false,
    }
  );
  const {
    data: dataHint,
    isLoading: isLoadingHint,
    refetch: refetchHint,
    isSuccess,
  } = useQuery(
    ["QuestionHint", exerciseId, code],
    () => fetchBlocksQuestionHint(exerciseId, exerciseId, code),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      // keepPreviousData: true,
      // onSuccess: () => queryClient.invalidateQueries("QuestionHint"),
    }
  );
  useEffect(() => {
    if (dataHint?.data) {
      setHintText(dataHint?.data.hint);
    }
  }, [dataHint]);

  const {
    status: submitStatus,
    data: submitData,
    isLoading: submitIsLoading,
    isError: submitIsError,
    refetch: refetchSubmit,
    isSuccess: submitIsSuccess,
  } = useQuery(
    ["fetchBlocksSubmitAnswer", exerciseId, code],
    () => fetchBlocksSubmitAnswer(exerciseId, exerciseId, code),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      // keepPreviousData: true,
    }
  );
  const { data: resourceData } = useQuery(
    ["resourcesPlayer", lessonId],
    () => {
      return fetchLessonResources(lessonId);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { workspace, xml } = useBlocklyWorkspace({
    ref: blocklyDiv,
    toolboxConfiguration: toolboxConfig,
    initialXml: '<xml xmlns="http://www.w3.org/1999/xhtml"></xml>',
    onWorkspaceChange: (workspace) => blocksChanged(workspace),
    workspaceConfiguration: {
      theme: blocksTheme,
      zoom: {
        controls: true,
        wheel: true,
        startScale: 1.0,
        maxScale: 3,
        minScale: 0.2,
        scaleSpeed: 1.2,
      },
      grid: {
        spacing: 20,
        length: 3,
        colour: "#ccc",
        snap: true,
        background: "#6358a5",
      },
      scrollbars: false,
      trashcan: true,
      css: {
        width: "100%",
      },
    },
  });
  const sendCode = () => {
    const serializer = new Blockly.serialization.blocks.BlockSerializer();
    const state = serializer.save(workspace);
    const code = window.Blockly.Python.workspaceToCode(workspace);
    // console.log("state", state);
    // console.log("code", JSON.stringify(code));
    // console.log("userId", userId);
    // console.log("selectedClassId", selectedClassId);
    // sendBlockCodeStudent(code, state);
    // sendBlockCodeClass(code, state);
  };
  useEffect(() => {
    if (workspace) {
      workspace.setTheme(blocksTheme);
    }
  }, [blocksTheme]);
  const getHint = () => {
    refetchHint();
  };
  const submitAnswer = () => {
    refetchSubmit();
  };

  function blocksChanged(workspace) {
    const code = window.Blockly.Python.workspaceToCode(workspace);
    setCode(code);
    const codeDom = window.Blockly.Xml.workspaceToDom(workspace);
    const xmlText = window.Blockly.Xml.domToPrettyText(codeDom);
  }
  useEffect(() => {
    workspace &&
      setSavedBlocks(Blockly.serialization.workspaces.save(workspace));
    // workspace &&
    //     const code = window.Blockly.Python.workspaceToCode(workspace);
    //     setCode(code);
  }, [workspace]);

  useEffect(() => {
    if (workspace) {
      // const serializer = new Blockly.serialization.blocks.BlockSerializer();
      // const state = serializer.save(workspace);
      // Blockly.serialization.workspaces.load(
      //   JSON.parse(data?.workspace_json),
      //   workspace
      // );
    }
  }, [data]);
  function switchCodeView() {
    setShowBlocks(!showBlocks);
  }

  function jsonToBlock() {
    // const serializer = new Blockly.serialization.blocks.BlockSerializer();
    // const state = serializer.save(workspace);
    // console.log("state", state);
    // const serializer = new Blockly.serialization.blocks.BlockSerializer();
    // const state = serializer.save(workspace);
    // console.log("state", state);
    // queryClient.invalidateQueries("QuestionHint");
    if (workspace) {
      // const serializer = new Blockly.serialization.blocks.BlockSerializer();
      // const state = serializer.save(workspace);
      // serializer.load(newValue, workspace);
    }
  }
  // console.log("savedBlocks", savedBlocks);
  function toggleTerminal() {
    setShowTerminal(!showTerminal);
  }

  useEffect(() => {
    if (workspace) {
      Blockly.svgResize(workspace);
    }
  }, [gridView]);

  async function runCode() {
    setShowTerminal(true);
    setStdOut("");
    const sub = await createSubmission(code);
    // console.log(sub.data);
    setSubToken(sub.data.token);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (subToken !== null) {
        getSubmission(subToken).then((sub) => {
          if (![1, 2].includes(sub.data.status.id)) {
            setSubToken(null);
            // console.log("sub :>> ", sub);
          }

          if (sub.data.stdout !== null) {
            setStdOut(sub.data.stdout);
          }
        });
      }
    }, 100);
    return () => clearInterval(interval);
  }, [subToken]);

  return (
    <div style={{ width: "95%", margin: "auto" }}>
      {/* <button onClick={() => jsonToBlock()}>Json to Block</button> */}

      <BlocksHeader
        switchCodeView={switchCodeView}
        runCode={runCode}
        toggleTerminal={toggleTerminal}
        running={subToken !== null}
        setBlockTheme={setBlockTheme}
        instructions={data && data.data.instructions}
        getHint={getHint}
        hintData={dataHint && dataHint?.data}
        resourceData={resourceData && resourceData}
        lessonId={lessonId}
        exerciseName={exerciseName}
        isLoadingHint={isLoadingHint}
        submitAnswer={submitAnswer}
        submitStatus={submitStatus}
        submitIsLoading={submitIsLoading}
        submitIsError={submitIsError}
        submitIsSuccess={submitIsSuccess}
        setHintText={setHintText}
        hintText={hintText}
        submitData={submitData && submitData?.data}
        // submitIsLoading={submitIsLoading}
      />
      {/* <button onClick={() => sendCode()}>sendCode</button> */}
      <div style={{}}>
        {/* <AutoAwesomeMosaicIcon onClick={() => setGridView((prev) => !prev)} /> */}
        <div
          style={{
            display: "flex",
            // gridTemplateColumns: "3fr 1fr",
            // gap: "20px",
            borderRadius: "8px",
            height: "80vh",
            width: "100%",
            overflow: "hidden",
            boxShadow:
              " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          }}
        >
          <div
            style={{
              width: gridView ? "60%" : "100%",
              borderRight: gridView ? "12px solid rgb(99, 88, 165)" : "",
              height: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                background: "rgb(99, 88, 165)",
                padding: "6px 10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h3
                style={{
                  color: "white",
                  margin: "0",
                }}
              >
                BLOCK CODING ENVIRONMENT
              </h3>
              <div>
                {gridView ? (
                  <ChevronRightIcon
                    color="#fff"
                    fontSize="38px"
                    style={{
                      color: "#fff",
                      margin: "0",
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={
                      () => setGridView((prev) => !prev)
                      // Blockly.svgResize(workspace)
                    }
                  />
                ) : (
                  <ChevronLeftIcon
                    color="#fff"
                    fontSize="38px"
                    style={{
                      color: "#fff",
                      margin: "0",
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setGridView((prev) => !prev);
                    }}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "calc(100% - 37px)",
                position: "relative",
              }}
              id="blocklyArea"
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                }}
                className="fill-height blocklyDiv"
                id="blocklyDiv "
                ref={blocklyDiv}
                // style="height: 800px; width: 100%;"
              ></div>
            </div>
          </div>
          {gridView && (
            <div
              style={{
                // display: "flex",
                // flexDirection: "column",
                width: gridView ? "40%" : "0%",
                height: "80vh",
                display: "grid",
                gridTemplateRows: "1fr 1fr",
                // boxShadow:
                //   " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                borderBottom: "2px solid rgb(99, 88, 165)",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h3
                    style={{
                      color: "white",
                      margin: "0",
                      background: "rgb(99, 88, 165)",
                      padding: "6px 10px",
                      width: "100%",
                    }}
                  >
                    Python Code
                  </h3>
                  <Editor
                    style={{}}
                    width="100%"
                    language="python"
                    value={code}
                    theme="vs-dark"
                    height="38vh"
                    options={{
                      readOnly: true,
                      fontSize: "18px",
                      cursorStyle: "block",
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  height: "100%",
                  overflow: "hidden",
                  width: "100%",
                  // boxShadow:
                  //   " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    background: "rgb(99, 88, 165)",
                    padding: "6px 10px",
                    // overflow:'auto'
                  }}
                >
                  <h3
                    style={{
                      color: "white",
                      margin: "0",
                    }}
                  >
                    Output
                  </h3>
                </div>
                <div
                  style={{
                    overflow: "auto",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <XTerm stdOut={stdOut} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlocksPageExercise;
