import React from "react";
import clsx from "clsx";
import FormGroup from "@material-ui/core/FormGroup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Box } from "@material-ui/core";
import moment from "moment";
import DatePicker from "react-datepicker";

function DateInput({
  editedClassDate,
  setEditedClassDate,
  setCustomValidationDate,
  classes,
  customValidationDate,
  dataMessage,
}) {
  return (
    <FormGroup
      style={{
        margin: 0,
      }}
    >
      <FormLabel
        style={{
          fontSize: 14,
          color: "#52606D",
          fontFamily: "Montserrat,sans-serif",
        }}
      >
        Start Date
      </FormLabel>
      <DatePicker
        selected={editedClassDate}
        dateFormat="yyyy-MM-dd"
        onChange={(date) => {
          setEditedClassDate(date);
          if (moment(date, "YYYY-MM-DD", true).isValid() === false) {
            setCustomValidationDate("required");
          } else {
            setCustomValidationDate("valid");
          }
        }}
        customInput={
          <OutlinedInput
            fullWidth
            value={editedClassDate}
            style={{ border: "1px solid #CBD2D9", color: "grey" }}
            classes={{
              notchedOutline: clsx({
                [classes.borderWarning]: customValidationDate === "required",
                [classes.borderSuccess]: customValidationDate === "valid",
              }),
            }}
          />
        }
      />
      {customValidationDate === "valid" ? (
        <FormHelperText component={Box} style={{ color: "#2dce89" }}>
          Looks good!
        </FormHelperText>
      ) : (
        <FormHelperText component={Box} style={{ color: "#fb6340" }}>
          {dataMessage}
        </FormHelperText>
      )}
    </FormGroup>
  );
}

export default DateInput;
