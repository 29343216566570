// import React from 'react';

// import { CodeRounded, Home, WidgetsRounded } from '@material-ui/icons';
// <<<<<<< HEAD
// =======
import { Home, SmartToyTwoTone } from "@mui/icons-material";
// >>>>>>> c916773b34e5b23f312ed35694ab6c7ee8c2b725
// import { MilitaryTechRounded, ViewInArRounded } from '@mui/icons-material';
// import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ActivitiesPage from "views/eai/activities/ActivitiesPage";
// import MyAwardsPage from "views/eai/activities/MyAwardsPage";
// import CodingSpace from 'views/eai/activities/CodingSpace';
// import ActivitiesActivities from 'views/eai/activities/ActivitiesActivities';
// import GamesIcon from "@mui/icons-material/Games";
import DashboardIcon from "@mui/icons-material/Dashboard";
// import CodeIcon from "@mui/icons-material/Code";
import ExtensionIcon from "@mui/icons-material/Extension";
// import CollectionsIcon from "@mui/icons-material/Collections";
import StarIcon from "@mui/icons-material/Star";
import BorderColorIcon from "@mui/icons-material/BorderColor";
// import MemoryIcon from "@mui/icons-material/Memory";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
// import { Divider } from "@material-ui/core";
// import BlocksPage from "views/eai/activities/BlocksPage";
import WhiteboardPage from "views/eai/activities/WhiteboardPage";
// import GalleryPage from "views/eai/activities/GalleryPage";
// // import GamesPage from "views/eai/activities/GamesPage";
// import MicrobitsPage from "views/eai/activities/MicrobitsPage";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
// import ExtensionIcon from "@mui/icons-material/Extension";
// <<<<<<< HEAD
// import CodingPage from "views/eai/activities/CodingPage";
// import ActivitiesSectionPage from "views/eai/activities/ActivitiesSectionPage";
// =======
// import CodingSpacePage from "views/eai/activities/CodingSpacePage";
// import ActivitiesSectionPage from "views/eai/activities/ActivitiesSectionPage";
import GearsPage from "views/eai/activities/GearsPage";
// import CodingPageWorkspace from "views/eai/activities/CodingPageWorkspace";
import ActivitiesLearnPage from "views/eai/activities/ActivitiesLearnPage";
// import ActivityLessonPlayerPage from "views/eai/activities/ActivityLessonPlayerPage";
// import H5PPlayerPage from "views/eai/activities/H5PPlayerPage";
import H5PPlayerTempPage from "views/eai/activities/H5PPlayerTempPage";
import H5PTestingPlayerPage from "views/eai/activities/H5PTestingPlayerPage";
import ActivityLessonPlayerPage from "views/eai/activities/ActivityLessonPlayerPage";
import BlocklyPageNew from "views/eai/activities/BlocklyPageNew";
// import GamesSitePage from "views/eai/activities/GamesSitePage";
import BlocksQuestionGen from "views/eai/activities/BlocksQuestionGen";
import StudentHomepage from "views/eai/studentpage/StudentHomepage";
import ActivityPage from "views/eai/studentpage/ActivityPage/ActivityPage";

// >>>>>>> c916773b34e5b23f312ed35694ab6c7ee8c2b725

function getRoutes(isAdmin) {
  // console.log('hello')
  if (isAdmin) {
    return [
      {
        path: "/",
        name: "Home",
        iconColor: "#BAB9CC",
        icon: Home,
        component: ActivitiesPage,
        layout: "/activities",
      },
      {
        path: "/activities",
        name: "Activities",
        iconColor: "#BAB9CC",
        icon: DashboardIcon,
        // component: ActivitiesSectionPage,
        component: ActivitiesLearnPage,
        layout: "/activities",
      },
      {
        path: "/lessons",
        name: "Lessons",
        iconColor: "#BAB9CC",
        icon: LibraryBooksIcon,
        // component: ActivitiesSectionPage,
        component: ActivityLessonPlayerPage,
        layout: "/activities",
      },

      {
        path: "/blocks",
        name: "Blocks",
        iconColor: "#BAB9CC",
        icon: ExtensionIcon,
        component: BlocklyPageNew,
        layout: "/activities",
      },
      // {
      //   path: "/blocks",
      //   name: "Blocks",
      //   iconColor: "#BAB9CC",
      //   icon: ExtensionIcon,
      //   component: BlocksPage,
      //   layout: "/activities",
      // },
      {
        path: "/whiteboard",
        name: "Whiteboard",
        iconColor: "#BAB9CC",
        icon: BorderColorIcon,
        component: WhiteboardPage,
        layout: "/activities",
      },
      {
        // <<<<<<< HEAD
        // =======
        path: "/robotics",
        name: "Robotics",
        iconColor: "#BAB9CC",
        icon: SmartToyTwoTone,
        component: GearsPage,
        layout: "/activities",
      },
      // {
      //   // >>>>>>> c916773b34e5b23f312ed35694ab6c7ee8c2b725
      //   path: "/gallery",
      //   name: "Gallery",
      //   iconColor: "#BAB9CC",
      //   icon: CollectionsIcon,
      //   component: GalleryPage,
      //   layout: "/activities",
      // },
      // // {
      // //   path: "/games",
      // //   name: "Games",
      // //   iconColor: "#BAB9CC",
      // //   icon: GamesIcon,
      // //   component: GamesPage,
      // //   // component: GamesSitePage,
      // //   layout: "/activities",
      // // },
      // {
      //   path: "/microbits",
      //   name: "Microbits",
      //   iconColor: "#BAB9CC",
      //   icon: MemoryIcon,
      //   component: MicrobitsPage,
      //   layout: "/activities",
      // },
      {
        path: "/h5pComp",
        name: "Activity player",
        icon: AccountTreeIcon,
        iconColor: "#BAB9CC",
        component: H5PTestingPlayerPage,
        layout: "/activities",
      },
      {
        path: "/h5pFile",
        name: "Activity Question Manual",
        icon: AccountTreeIcon,
        iconColor: "#BAB9CC",
        component: H5PPlayerTempPage,
        layout: "/activities",
      },

      {
        path: "/blockCheck",
        name: "Blocks ques. generation",
        icon: AccountTreeIcon,
        iconColor: "#BAB9CC",
        component: BlocksQuestionGen,
        layout: "/activities",
      },
      {
        path: "/studentPage",
        name: "Student Page (New)",
        icon: StarIcon,
        iconColor: "#BAB9CC",
        component: StudentHomepage,
        layout: "/activities",
      },
      {
        path: "/ActivityPage",
        name: "Activity Page (New)",
        icon: StarIcon,
        iconColor: "#BAB9CC",
        component: ActivityPage,
        layout: "/activities",
      },
    ];
  } else {
    return [
      {
        path: "/",
        name: "Home",
        iconColor: "#BAB9CC",
        icon: Home,
        component: ActivitiesPage,
        layout: "/activities",
      },
      {
        path: "/activities",
        name: "Activities",
        iconColor: "#BAB9CC",
        icon: DashboardIcon,
        // component: ActivitiesSectionPage,
        component: ActivitiesLearnPage,
        layout: "/activities",
      },
      {
        path: "/lessons",
        name: "Lessons",
        iconColor: "#BAB9CC",
        icon: LibraryBooksIcon,
        // component: ActivitiesSectionPage,
        component: ActivityLessonPlayerPage,
        layout: "/activities",
      },

      {
        path: "/blocks",
        name: "Blocks",
        iconColor: "#BAB9CC",
        icon: ExtensionIcon,
        component: BlocklyPageNew,
        layout: "/activities",
      },
      // {
      //   path: "/blocks",
      //   name: "Blocks",
      //   iconColor: "#BAB9CC",
      //   icon: ExtensionIcon,
      //   component: BlocksPage,
      //   layout: "/activities",
      // },
      {
        path: "/whiteboard",
        name: "Whiteboard",
        iconColor: "#BAB9CC",
        icon: BorderColorIcon,
        component: WhiteboardPage,
        layout: "/activities",
      },
      {
        // <<<<<<< HEAD
        // =======
        path: "/robotics",
        name: "Robotics",
        iconColor: "#BAB9CC",
        icon: SmartToyTwoTone,
        component: GearsPage,
        layout: "/activities",
      },
      // {
      //   // >>>>>>> c916773b34e5b23f312ed35694ab6c7ee8c2b725
      //   path: "/gallery",
      //   name: "Gallery",
      //   iconColor: "#BAB9CC",
      //   icon: CollectionsIcon,
      //   component: GalleryPage,
      //   layout: "/activities",
      // },
      // // {
      // //   path: "/games",
      // //   name: "Games",
      // //   iconColor: "#BAB9CC",
      // //   icon: GamesIcon,
      // //   component: GamesPage,
      // //   layout: "/activities",
      // // },
      // {
      //   path: "/microbits",
      //   name: "Microbits",
      //   iconColor: "#BAB9CC",
      //   icon: MemoryIcon,
      //   component: MicrobitsPage,
      //   layout: "/activities",
      // },
      // {
      //   path: "/h5p",
      //   name: "Activity player",
      //   icon: AccountTreeIcon,
      //   iconColor: "#BAB9CC",
      //   component: H5PTestingPlayerPage,
      //   layout: "/activities",
      // },
      // {
      //   path: "/h5pFile",
      //   name: "Activity Question Manual",
      //   icon: AccountTreeIcon,
      //   iconColor: "#BAB9CC",
      //   component: H5PPlayerTempPage,
      //   layout: "/activities",
      // },
    ];
  }
}

export default getRoutes;
