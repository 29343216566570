import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  OutlinedInput,
  Paper,
  Popper,
} from "@mui/material";
import useCurriculumApi from "api/curriculum.js";
import React, { useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import useStore from "../../zustand.js";
import { useEffect } from "react";
// import GeneralCurriculumCard from "components/Cards/eai/GeneralCurriculumCard.jsx";
// import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveLessonList from "./CreateActivity/Components/SaveLessonList.jsx";

function SaveLesson({
  lessonId,
  mutateAddLesson,
  mutateUpdate,
  setOpenSnackbar,
  isSuccessAddLesson,
  isSuccessUpdate,
  updateCustomLessonPlanId,
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [customLesson, setCustomLesson] = React.useState(false);
  const [title, setTitle] = useState("");
  const { userId } = useStore((state) => state);
  const queryClient = useQueryClient();
  const { fetchLessonPlan, createLessonPlan } = useCurriculumApi();

  //Add a lesson to lesson plan
  const {
    mutate: mutateCreateLesson,
    isSuccess: isSuccessCreateLesson,
    isLoading: isLoadingCreateLesson,
    isError: isErrorCreateLesson,
  } = useMutation(["saveNewLevel"], createLessonPlan, {
    onSuccess: () => queryClient.invalidateQueries("lessonPlanData"),
  });

  // Fetch custom levels list
  const { fetchLevels } = useCurriculumApi();
  const { data, isSuccess } = useQuery("myLevels", () => fetchLevels(true));
  //fetch fetchLessonPlan
  const {
    data: lessonPlanData,
    status: lessonPlanStatus,
    isError,
    isLoading,
    isSuccess: isSuccessLesson,
  } = useQuery(["lessonPlanData"], () => fetchLessonPlan(), {
    refetchOnWindowFocus: false,
  });

  const [newLessonList, setNewLessonList] = useState([]);
  const levels = isSuccessLesson && lessonPlanData?.data?.custom_lesson_plans;
  //   const {
  //     mutate: mutateUpdateLesson,
  //     isSuccess: isSuccessUpdated,
  //     isLoading: isLoadingUpdated,
  //     isError: isErrorUpdated,
  //   } = useMutation(["saveLevelUpdate"], (id, body) => updateCustomLessonPlanId(id, body));

  //   // Mutations
  //   const mutation = useQuery(updateCustomLessonPlanId, {
  //     onSuccess: () => {
  //       // Invalidate and refetch
  //       queryClient.invalidateQueries("lessonPlanData");
  //     },
  //   });

  useEffect(() => {
    setNewLessonList(levels);
  }, [levels]);

  // Open & Close functionality
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // Refresh the levels list
  React.useEffect(() => {
    if (isSuccessAddLesson || isSuccessUpdate) {
      queryClient.invalidateQueries("myLevels");
    }
  }, [isSuccessAddLesson, isSuccessUpdate]);

  // Save button functionality
  const saveNewLessonPlan = () => (
    setTitle(""),
    setOpen(false),
    setCustomLesson(false),
    setOpenSnackbar(true),
    mutateCreateLesson({
      name: title,
      type: "online",
      author: userId,
      custom_lessons: [{ lesson: lessonId, position: 0 }],
    })
  );

  return (
    <span>
      <Button
        ref={anchorRef}
        onClick={handleToggle}
        size="small"
        aria-controls={open ? "split-button-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        endIcon={<ArrowDropDownIcon />}
        // onClick={handleClick}
        style={{
          fontFamily: "Open Sans, sans-serif",
          height: 40,
          border: "1px solid rgb(6, 101, 157)",
          color: "white",
          width: 207,
          fontSize: ".875rem",
          borderRadius: 6,
          background: "rgb(6, 101, 157)",
          margin: "10px 20px 10px 0px",
          fontWeight: 600,
        }}
      >
        Add to Lesson Plan
      </Button>
      <Popper
        style={{ zIndex: 999 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper>
              <ClickAwayListener
              // onClickAway={handleClose}
              >
                <MenuList id="split-button-menu" autoFocus={false}>
                  <MenuItem autoFocus={false} style={{ padding: "0px 16px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        minWidth: 260,
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 17,
                          fontWeight: 600,
                          color: "rgba(0, 0, 0, 0.77)",
                          margin: 0,
                          fontFamily: "Open Sans",
                        }}
                      >
                        Lesson Plans
                      </p>
                      <IconButton onClick={handleClose}>
                        <ExpandMoreIcon style={{ color: "grey", width: 30, height: 30 }} />
                      </IconButton>
                    </Box>
                  </MenuItem>
                  <Divider />
                  {lessonPlanData &&
                    lessonPlanData?.data?.custom_lesson_plans?.map((level, index) => (
                      <SaveLessonList
                        index={index}
                        level={level}
                        levels={levels}
                        lessonId={lessonId}
                        mutateUpdate={mutateUpdate}
                      />
                    ))}
                  {!customLesson ? (
                    <MenuItem
                      // key={option}
                      // selected={index === ''}
                      style={{
                        background: "rgb(6, 101, 157)",
                        color: "white",
                        padding: "10px 20px",
                      }}
                      onClick={() => setCustomLesson(!customLesson)}
                    >
                      Create New Plan +
                    </MenuItem>
                  ) : (
                    <Box p={1} sx={{ background: "#7fcdee2e" }}>
                      <OutlinedInput
                        style={{ border: "1px solid #CBD2D9", height: 38 }}
                        placeholder="Title"
                        value={title}
                        onChangeCapture={(e) => setTitle(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                      <>
                        <Button
                          variant="contained"
                          onClick={saveNewLessonPlan}
                          style={{
                            marginLeft: 5,
                            background: "rgb(6, 101, 157)",
                            textTransform: "none",
                            transform: "translateY(-1px)",
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => setCustomLesson(!customLesson)}
                          style={{
                            marginLeft: 5,
                            color: "rgb(6, 101, 157)",
                            textTransform: "none",
                            transform: "translateY(-1px)",
                          }}
                        >
                          Back
                        </Button>
                      </>
                    </Box>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </span>
  );
}

export default SaveLesson;
