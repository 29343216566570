import React, { useEffect, useState } from "react";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import ClassPerformance from "./ClassPerformance";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useUserApi from "api/user";
// import CoPresentIcon from "@mui/icons-material/CoPresent";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import SignalCellularAltRoundedIcon from "@mui/icons-material/SignalCellularAltRounded";
import { Link } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import ClassLevel from "./ClassLevel";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "components/Modals/ConfirmModal";
import useStore from "../../../../../zustand";
import ClassCardSkeleton from "./ClassCardSkeleton";
import ClassCardColumnSkeleton from "./skeletons/ClassCardColumnSkeleton";
// import CardListSkeleton from "./CardListSkeleton";
// import useCurriculumApi from "api/curriculum";
const ClassCard = ({ classItem, gridView }) => {
  const { fetchCurrentClass, deleteClass } = useUserApi();
  const { schoolName } = useStore();
  const queryClient = useQueryClient();
  console.log("hellllo");
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  //Confirmation
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmActionDescription, setConfirmActionDescription] =
    useState(null);
  const {
    data: currentClass,
    status: currentClassStatus,
    isLoading: isLoadingClass,
  } = useQuery(
    ["classItem", classItem.id],
    () => fetchCurrentClass(classItem.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  console.log("classItem", classItem);
  const [classLinkId, setClassLinkId] = useState(null);
  const { mutate, isLoading, isSuccess, isError } = useMutation(
    (id) => deleteClass(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("classes");
      },
    }
  );

  useEffect(() => {
    setClassLinkId(classItem.id);
  }, [classItem]);

  function toDeleteClass() {
    setConfirmAction(
      () =>
        function () {
          mutate(classItem.id);
        }
    );
    setConfirmActionDescription(
      schoolName
        ? `remove ${classItem.name} from ${schoolName}`
        : `remove ${classItem.name} from this school`
    );
    setConfirmOpen(true);
  }

  // Snackbar disappear
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenSnackbar(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [openSnackbar]);

  if (currentClassStatus === "success") {
    // console.log(
    //   "currentClass ============================:>> ",
    //   currentClass?.data
    // );
    return (
      <div
        style={{
          background: "white",
          borderRadius: "8px",
          width: gridView ? "" : "330px",
          padding: "20px ",
          //   marginBottom: "30px",
          //   boxShadow: " 0 4px 6px hsla(0,0%, 0%, .2)",
          boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
          //   boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        }}
      >
        <ConfirmModal
          action={confirmAction}
          actionDescription={confirmActionDescription}
          open={confirmOpen}
          setOpen={setConfirmOpen}
        />
        <div>
          <div
            style={{
              display: "flex",
              marginBottom: gridView ? "8px" : "15px",
              justifyContent: "space-between",
            }}
          >
            <h5
              style={{
                fontWeight: "500",
                fontSize: gridView ? "16px" : "1.2rem",
                marginRight: gridView ? "22px" : "",
              }}
            >
              {classItem.name}
            </h5>
            <DeleteIcon
              style={{
                color: "#7B8794",
                cursor: "pointer",
                marginLeft: gridView ? "4px" : "",
                fontSize: gridView ? "24px" : "1.2rem",
              }}
              onClick={() => (toDeleteClass(), setOpenSnackbar(true))}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: gridView ? "column" : "column",
              justifyContent: "space-between",
            }}
          >
            {gridView ? (
              ""
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <SignalCellularAltRoundedIcon style={{ color: "#1D6F98" }} />
                {/* <p style={{ margin: "0 0 0 10px" }}>{.name}</p> */}
                <ClassLevel dataLevel={classItem?.level} />
              </div>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              <PersonRoundedIcon style={{ color: "#1D6F98" }} />
              <p style={{ margin: "0 0 0 10px" }}>
                {classItem?.teachers.length === 1
                  ? `${classItem?.teachers.length} Teacher`
                  : `${classItem?.teachers.length} Teachers`}
              </p>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <SchoolOutlinedIcon style={{ color: "#1D6F98" }} />
              <p style={{ margin: "0 0 0 10px" }}>
                {classItem?.students.length === 1
                  ? `${classItem?.students.length} Student`
                  : `${classItem?.students.length} Students`}
              </p>
            </div>
          </div>
          {/* <h1>Nice</h1> */}
          <ClassPerformance
            classPerformance={currentClass.data.performance}
            gridView={gridView}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <Link
              to={`/data/class/${classLinkId}`}
              style={{ textDecoration: "none" }}
            >
              <button
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  borderRadius: "4px",
                  background: isHovering ? "#1F6EA3" : "white",
                  border: "none",
                  margin: "0",
                  color: isHovering ? "white" : "#1D6F98",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "4px 10px",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                View Class{" "}
                <EastIcon style={{ width: 15, height: 15, marginLeft: 5 }} />
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  } else if (isLoadingClass) {
    return (
      <div>
        {!gridView ? <ClassCardSkeleton /> : <ClassCardColumnSkeleton />}
      </div>
    );
  } else {
    return <div></div>;
  }

  //   }
};

export default ClassCard;
