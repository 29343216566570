import React from "react";

const BuilderFilterCompLevelsItem = ({
  level,
  levelItemNamesFilter,
  setLevelItemNamesFilter,
  selectedLevelFilter,
  setSelectedLevelFilter,
  item,
}) => {
  const filterfunc = (tempName) => {
    // console.log("before levelNamesFilter :>> ", levelItemNamesFilter);

    if (levelItemNamesFilter.includes(tempName)) {
      if (levelItemNamesFilter.length > 1) {
        setLevelItemNamesFilter((prev) => prev.filter((item) => item !== tempName));
      } else {
        setLevelItemNamesFilter([]);
      }
    } else {
      setLevelItemNamesFilter((prev) => [...prev, tempName]);
    }
    // console.log("after levelNamesFilter :>> ", levelItemNamesFilter);
  };

  return (
    <p
      style={{
        background:
          levelItemNamesFilter.length === 0
            ? "rgba(6, 101, 157,0.15)"
            : levelItemNamesFilter.includes(item)
            ? "rgba(6, 101, 157,1)"
            : "rgba(6, 101, 157,0.15)",
        color:
          levelItemNamesFilter.length === 0
            ? ""
            : levelItemNamesFilter.includes(item)
            ? "white"
            : "",

        padding: "2px 5px",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "10px",
        margin: "0",
        fontWeight: "500",
        minWidth: "60px",
      }}
      onClick={() => filterfunc(item)}
    >
      {level.length > 12 ? `${level.slice(0, 12)}...` : level}
    </p>
  );
};

export default BuilderFilterCompLevelsItem;
