import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import useCurriculumApi from "api/curriculum";
import SubjectLessonView from "./CurriculumSiteComponents/SubjectHomePage/SubjectLessonView";

const SubjectHomePage = () => {
  const { subjectName } = useParams();
  const [subjectLevel, setSubjectLevel] = useState(1);
  const { fetchLevels, fetchSubjects } = useCurriculumApi();
  const {
    data: dataSubjects,
    status: statusSubjects,
    isLoading: isLoadingSubject,
  } = useQuery("subjectsDatasite", () => fetchSubjects(), {
    refetchOnWindowFocus: false,
  });
  console.log("subjectName", subjectName);
  if (dataSubjects && dataSubjects?.data.length > 0) {
    console.log("dataSubjects.data", dataSubjects?.data);
    // function rowTemp() {
    //   return dataSubjects?.data.map((topic) =>
    //     topic.categories.filter((cat) => cat.name === subjectName)
    //   ).courses[0].levels.length;
    // }

    return (
      <div>
        <div>
          <div
            style={{
              fontSize: "2rem",
              padding: "12px",
              background: "#eee",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {subjectName}
          </div>
          <div
            style={{
              background: "rgb(175, 204, 224)",
            }}
          >
            <div
              style={{
                //   height: "40px",
                maxWidth: "1280px",
                margin: "auto",
                // gridTemplateColumns: `repeat(5,1fr)`,
              }}
            >
              <div
                style={{
                  //   height: "40px",
                  display: "grid",
                  gap: "2px",
                  gridTemplateColumns: `repeat(5,1fr)`,
                }}
              >
                {dataSubjects?.data.map((topic) =>
                  topic.categories.map(
                    (cat) =>
                      cat.name === subjectName &&
                      cat.courses[0].levels
                        .filter((level) => level.name !== "Level 6")
                        .map((level) => (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "16px",
                              padding: "8px 4px",
                              background:
                                subjectLevel === level?.id
                                  ? "rgba(7, 101, 157)"
                                  : "rgb(175, 204, 224)",
                              color: "rgb(245,245,245)",
                              fontWeight: "600",
                              cursor: "pointer",
                            }}
                            onClick={() => setSubjectLevel(level?.id)}
                          >
                            <h2
                              style={{
                                margin: "0",
                                color:
                                  subjectLevel === level?.id
                                    ? "white"
                                    : "#32325D",
                              }}
                            >
                              {level.name}
                            </h2>
                          </div>
                        ))
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <SubjectLessonView subjectLevel={subjectLevel} />
      </div>
    );
  } else {
    return <h1>No data</h1>;
  }
};

export default SubjectHomePage;
