import React, { useState } from "react";
// import ActivityLessonPlayer from "./ActivityLessonPlayer";
import { useQuery } from "react-query";
import useCurriculumApi from "api/curriculum";
import ActivityCurriculumPlayer from "./ActivityCurriculumPlayer";
import Modal from "@mui/material/Modal";
// import { Box } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  // height: "80vh",
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 0,
  m: 0,
};
const ActivityLessonCardItem = ({ lessonData }) => {
  //   console.log("lessonData :>> ", lessonData);
  console.log("lessonData ", lessonData);
  const [open, setOpen] = React.useState(false);
  const [messageNoLesson, setMessageNoLesson] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { fetchLevels, fetchLessonResources } = useCurriculumApi();
  const { isLoading, isError, data, error } = useQuery(
    ["LessonResources", lessonData],
    () => fetchLessonResources(lessonData),
    {
      refetchOnWindowFocus: false,
    }
  );
  // console.log("ActivityLessonCardItem, data && data.data", data && data?.data);
  if (data) {
    return (
      <div>
        <div
          style={{
            background: "white",
            width: "260px",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            // alignItems: "center",
            borderRadius: "8px",
            boxShadow: " 0 1px 3px rgba(0,0,0,0.2)",
            overflow: "hidden",
            //   paddingBottom: "10px",
            //   gap: "10px",
          }}
        >
          <img src={data?.data[0].image} style={{ width: "100%" }} alt="" srcset="" />

          <div
            style={{
              padding: "10px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <h3>{data?.data[0].name}</h3>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                style={{
                  padding: "6px 18px",
                  color: "white",
                  background: "#6358A5",
                  cursor: "pointer",
                  border: "none",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: " 0 1px 3px rgba(0,0,0,0.2)",
                  fontWeight: "500",
                  margin: "10px 0",
                }}
                onClick={handleOpen}
              >
                Revise
              </button>
            </div>
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            margin: " 20px auto 0",
            //   // marginTop: "0",
            //   // height: "30vh",
            width: "90vw",
          }}
        >
          <>
            <ActivityCurriculumPlayer
              exerciseName={data?.data[0]?.name}
              // stageNumber={exerciseData.data?.stage}
              messageNoLesson={messageNoLesson}
              lessonPage={true}
              setMessageNoLesson={setMessageNoLesson}
              lessonId={lessonData}
              lessonIs={data?.data}
              lessonResourcesData={
                data &&
                data?.data.filter(
                  (item) =>
                    item.type.name.toLowerCase() === "presentation" ||
                    item.type.name.toLowerCase() === "video"
                )
              }
              handleClose={handleClose}
            />
          </>
        </Modal>
        {/* <ActivityLessonPlayer lessonData={lessonData} /> */}
      </div>
    );
  } else {
    return null;
  }
};

export default ActivityLessonCardItem;
