import React from "react";
import { useQuery } from "react-query";
import useCurriculumApi from "api/curriculum";
import ActivityCurriculumPlayer from "views/eai/activities/ActivityCurriculumPlayer";

const LessonPreviewPlayerEdit = ({ lessonId }) => {
  const { fetchLevelResources, fetchLessonResources } = useCurriculumApi();
  // console.log("lessonId :>> ", lessonId);
  // console.log("lessonId :>> ", lessonId);
  // console.log("lessonId :>> ", lessonId);
  const { isLoading, isError, data, error } = useQuery(
    ["createClassPlayer", lessonId],
    () => {
      return fetchLessonResources(lessonId);
    },
    {
      refetchOnWindowFocus: false,
      enabled: lessonId?.length > 0,
    }
  );
  // console.log("data && data.data :>> ", data && data.data);
  // console.log("data && data.data :>> ", data && data.data);
  // console.log("data && data.data :>> ", data && data.data);
  return (
    <>
      <ActivityCurriculumPlayer
        // exerciseName={exerciseName}
        lessonId={lessonId}
        lessonPage={false}
        lessonIs={data?.data}
        classModal={true}
        lessonResourcesData={
          data &&
          data?.data.filter(
            (item) =>
              item.type.name.toLowerCase() === "presentation" ||
              item.type.name.toLowerCase() === "video"
          )
        }
        isLoadingResources={isLoading}
        //   handleClose={handleClose}
      />
    </>
  );
};
export default LessonPreviewPlayerEdit;
