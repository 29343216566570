import useCurriculumApi from "api/curriculum";
import Iframe from "react-iframe";
import "assets/css/myStyle.css";
import { useMutation, useQuery } from "react-query";
import { Box } from "@material-ui/core";
import useStore from "../../../../zustand.js";
import { ErrorScreen } from "views/eai/curriculum/Components/ErrorScreen";
// import { CircularLoadingScreen } from "views/eai/activities/components/Loading";
// import { useEffect } from "react";
// import { LoadingScreen } from "views/eai/curriculum/Components/LoadingScreen";
import { useEffect, useState } from "react";
import useUserApi from "api/user";
import NewSnackbar from "components/Snackbar/NewSnackbar";
import { Button, Snackbar, SnackbarContent } from "@mui/material";
import React from "react";
// import CreateActivity from "./CreateActivity/CreateActivity";
import useBuilderApi from "api/builder";
import CreateActivityForm from "components/Modals/CreateActivity/CreateActivityForm";
import SaveLesson from "components/Modals/SaveLesson.jsx";
import Skeleton from "react-loading-skeleton";

function InfoPage({ content, test, lessonID4LO, height, iframeURL, isLoading, isError, error }) {
  const { fetchLessonObjectives } = useCurriculumApi();
  const { setLessonLearningObjectives } = useStore();
  const [activity, setActivity] = useState(false);
  const { saveLevel, updateLevel } = useBuilderApi();
  const { addQuiz } = useUserApi();

  useEffect(() => {
    setActivity(false);
  }, [test]);

  // Lesson Learning Objectives
  const {
    isLoading: isLoadingLO,
    isError: isErrorLO,
    data: learningObjectives,
    status,
    isSuccess: isSuccessLO,
  } = useQuery(
    ["lessonLearningObjectives", lessonID4LO?.id],
    () => lessonID4LO?.id && fetchLessonObjectives(lessonID4LO.id),
    {
      enabled: !!lessonID4LO?.id,
      refetchOnWindowFocus: false,
    }
  );

  // Create new Quiz
  const {
    mutate: mutateQuiz,
    isLoading: isLoadingQuiz,
    isSuccess: isSuccessQuiz,
    isError: isErrorQuiz,
    error: errorQuiz,
    status: statusQuiz,
  } = useMutation((quiz) => addQuiz(quiz));

  //Add a lesson to lesson plan
  const {
    mutate: mutateAddLesson,
    isSuccess: isSuccessAddLesson,
    isLoading: isLoadingAddLesson,
    isError: isErrorAddLesson,
  } = useMutation(["savelevel"], (body) => saveLevel(body));

  // Update Custom Level
  const {
    mutate: mutateUpdCusLev,
    isSuccess: isSuccessUpdCusLev,
    isLoading: isLoadingUpdCusLev,
    isError: isErrorUpdCusErr,
  } = useMutation((updatedCustomLevel) => updateLevel(updatedCustomLevel));

  const { createLessonPlan, updateCustomLessonPlanId } = useCurriculumApi();

  // Snackbar disappear
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenSnackbar(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [openSnackbar]);

  const compConceptArray = [];
  const learnObjArray = [];
  status == "success" &&
    learningObjectives?.data?.map((item) => {
      compConceptArray.push(item.computational_concept);
      learnObjArray.push(item.id);
    });

  return (
    <Box
      sx={{
        width: "95%",
        margin: "20px auto 100px",
        background: "transparent",
        height: height || "calc(90vh - 270px)",
        borderRadius: "0px",
        overflowY: "scroll",
      }}
    >
      <NewSnackbar
        isLoading={isLoadingQuiz}
        isSuccess={isSuccessQuiz}
        isError={isErrorQuiz}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message="The activity has been successfully added."
      />
      <NewSnackbar
        isLoading={isLoadingAddLesson}
        isSuccess={isSuccessAddLesson}
        isError={isErrorAddLesson}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message="The lesson has been successfully added to the lesson plan."
      />
      <NewSnackbar
        isLoading={isLoadingUpdCusLev}
        isSuccess={isSuccessUpdCusLev}
        isError={isErrorUpdCusErr}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message="The lesson has been successfully added to the lesson plan."
      />

      {isLoading || isLoadingLO || isLoadingQuiz ? (
        <Skeleton
          count={1}
          width="100%"
          style={{
            transform: "none",
            height: "100%",
            borderRadius: "5px",
          }}
        />
      ) : isError ? (
        <ErrorScreen error={error} />
      ) : (
        <Box
          style={{
            padding: "10px 0px",
            height: "calc(100% - 80px)",
            margin: 0,
            width: "100%",
          }}
        >
          <Iframe url={iframeURL} width="100%" height="100%" allow="fullscreen" />
        </Box>
      )}

      <Box
        style={{
          padding: "10px 0px",
          margin: "10px 0px",
          maxHeight: "200px",
          maxWidth: "fit-content",
        }}
      >
        {/* <h5 id="button-hover">Description</h5>
                <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut ratione accusamus earum saepe reiciendis adipisci asperiores id illum, repellat aliquid, eligendi fugiat laborum repellendus et libero illo a voluptatum voluptatem!</p> */}
      </Box>
      {activity ? (
        <CreateActivityForm
          hide={() => setActivity(false)}
          mutate={mutateQuiz}
          isLoading={isLoadingQuiz}
          isSuccess={isSuccessQuiz}
          isError={isErrorQuiz}
          error={errorQuiz}
          status={statusQuiz}
          setOpenSnackbarQuiz={setOpenSnackbar}
        />
      ) : (
        <Box
          style={{
            // height: "100%",
            // maxHeight: "calc(100vh - 900px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* Learning objectives of lesson */}
          <Box
            style={{
              padding: "10px 0px",
              // maxHeight: "220px",
              // margin: 0,
              // minHeight: "200px",
              // maxWidth: "none",
              marginBottom: "30px",
              // overflow: "auto",
            }}
          >
            <h5 style={{ fontSize: "18px" }}>Learning Objectives</h5>
            <Box style={{}}>
              {status == "success" && learningObjectives?.data.map((obj) => <p>{obj.objective}</p>)}
            </Box>
          </Box>

          {/* Buttons for assigning activity and addind lesson to Lesson Plan */}
          <Box
            style={{
              padding: "10px 0px",
              // maxHeight: "200px",
              margin: 0,
              maxWidth: "none",
              marginBottom: "0px",
              display: "flex",
            }}
          >
            {false && (
              <Button
                class="button-css"
                onClick={() => (
                  setActivity(!activity),
                  setLessonLearningObjectives({
                    toForm: true,
                    compConceptArray: compConceptArray,
                    learnObjArray: learnObjArray,
                  })
                )}
                style={{
                  textTransform: "uppercase",
                  fontFamily: "Open Sans, sans-serif",
                  height: 40,
                  border: "1px solid rgb(6, 101, 157)",
                  color: "rgb(6, 101, 157)",
                  width: 170,
                  fontSize: ".875rem",
                  borderRadius: 6,
                  background: "white",
                  margin: "10px 20px 10px 0px",
                  fontWeight: 600,
                }}
              >
                Assign as Activity
              </Button>
            )}
            <SaveLesson
              lessonId={content?.id}
              lessonImage={content?.image}
              lessonName={content?.name}
              mutateAddLesson={mutateAddLesson}
              isSuccessAddLesson={isSuccessAddLesson}
              mutateUpdate={mutateUpdCusLev}
              isSuccessUpdate={isSuccessUpdCusLev}
              setOpenSnackbar={setOpenSnackbar}
              updateCustomLessonPlanId={updateCustomLessonPlanId}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default InfoPage;
