import axios from "axios";

const useJudgeApi = () => {
  function createSubmission(sourceCode) {
    const body = {
      source_code: sourceCode,
      language_id: 71,
      redirect_stderr_to_stdout: true,
    };
    return axios.post(`${process.env.REACT_APP_JUDGE_URL}/submissions`, body, {
      headers: { "Content-Type": "application/json" },
    });
  }

  function getSubmission(token) {
    return axios.get(
      `${process.env.REACT_APP_JUDGE_URL}/submissions/${token}`,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  return { createSubmission, getSubmission };
};

export default useJudgeApi;
