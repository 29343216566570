import React, { useEffect, useRef, useState } from "react";
import { useBlocklyWorkspace } from "react-blockly";
import "../../../assets/css/style.css";
import toolboxConfig from "blockly/toolbox";
// import BlocksHeader from "./components/BlocksHeader";
// import AceEditor from "react-ace";
// import CodeMirror from "@uiw/react-codemirror";
import { PlayArrowOutlined } from "@mui/icons-material";
import Editor from "@monaco-editor/react";
import "xterm/css/xterm.css";
import useJudgeApi from "api/judge";
import { FitAddon } from "xterm-addon-fit";
import ThemeHigh from "@blockly/theme-highcontrast";
// import ThemeTrit from "@blockly/theme-tritanopia";
// import ThemeDeuter from "@blockly/theme-deuteranopia";
// import Theme from "@blockly/theme-modern";
import usePersistStore from "../../../zustandPersist";
import Blockly from "blockly";
import useCurriculumApi from "api/curriculum";
import useStore from "../../../zustand";
// import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
function XTermNew({ stdOut }) {
  const xtermRef = useRef(null);
  const [currentOutput, setCurrentOutput] = useState("");
  const fitAddon = new FitAddon();

  useEffect(() => {
    const initTerminal = async () => {
      const { Terminal } = await import("xterm");

      xtermRef.current = new Terminal({
        cursorBlink: true,
        disableStdin: true,
      });

      xtermRef.current.loadAddon(fitAddon);
      xtermRef.current.open(document.getElementById("terminal"));
      fitAddon.fit();

      var shellprompt = "-> ";
      xtermRef.current.prompt = () => {
        xtermRef.current.write("\r\n" + shellprompt);
      };
      //your greeting
      xtermRef.current.write(shellprompt);
      var cmd = "";

      xtermRef.current.onKey((key) => {
        return;
      });
    };
    initTerminal();
  }, []);

  useEffect(() => {
    fitAddon.fit();
    if (xtermRef.current !== null) {
      if (stdOut === "") {
        xtermRef.current.clear();
        return;
      }
      const newOut = stdOut.replace(currentOutput, "");
      if (newOut !== "") {
        const newOutArr = newOut.split("\n");
        // console.log("newOutArr", newOutArr);
        newOutArr.forEach((ln) => {
          xtermRef.current.write(ln);
          if (ln !== "") {
            xtermRef.current.prompt();
          }
        });
      }
    }
  }, [stdOut]);
  return <div style={{ width: "100%", height: "100%" }} id="terminal" />;
}

const BlocksQuestionGen = () => {
  const { xmlCodeSelected } = usePersistStore();

  const initialXml = "";
  const [showBlocks, setShowBlocks] = useState(true);
  const [savedBlocks, setSavedBlocks] = useState(null);
  const [code, setCode] = useState("");
  const blocklyDiv = useRef(null);
  const [showTerminal, setShowTerminal] = useState(false);
  const [subToken, setSubToken] = useState(null);
  const [stdOut, setStdOut] = useState("");
  const [gridView, setGridView] = useState(true);
  const [blockTheme, setBlockTheme] = useState(null);
  const { createSubmission, getSubmission } = useJudgeApi();
  const { sendBlockCodeStudent, sendBlockCodeClass } = useCurriculumApi();
  const { selectedClassId, userId } = useStore();
  const [codeAsString, setCodeAsString] = useState({});
  const history = useHistory();

  const { workspace, xml } = useBlocklyWorkspace({
    ref: blocklyDiv,
    toolboxConfiguration: toolboxConfig,
    initialXml: initialXml,
    onWorkspaceChange: (workspace) => blocksChanged(workspace),
    workspaceConfiguration: {
      theme: ThemeHigh,
      grid: {
        spacing: 20,
        length: 3,
        colour: "#ccc",
        snap: true,
        background: "#6358a5",
      },
      scrollbars: false,
      css: {
        width: "100%",
      },
    },
  });
  const sendCode = () => {
    const serializer = new Blockly.serialization.blocks.BlockSerializer();
    const state = serializer.save(workspace);
    const code = window.Blockly.Python.workspaceToCode(workspace);
    console.log("state", state);
    console.log("code", code);
    console.log("userId", userId);
    console.log("selectedClassId", selectedClassId);
    setSavedBlocks(state);
  };

  function blocksChanged(workspace) {
    const code = window.Blockly.Python.workspaceToCode(workspace);
    setCode(code);
    const codeDom = window.Blockly.Xml.workspaceToDom(workspace);
    const xmlText = window.Blockly.Xml.domToPrettyText(codeDom);
  }
  useEffect(() => {
    // workspace &
    // if (workspace) {
    //   const serializer = new Blockly.serialization.blocks.BlockSerializer();
    //   const state = serializer.save(workspace);
    //   setSavedBlocks(serializer.save(workspace));
    // }
  }, [workspace]);
  function switchCodeView() {
    setShowBlocks(!showBlocks);
  }
  console.log("savedBlocks && savedBlocks", savedBlocks && savedBlocks);
  function jsonToBlock() {
    // const serializer = new Blockly.serialization.blocks.BlockSerializer();
    // const state = serializer.save(workspace);
    // console.log("state", state);
    const serializer = new Blockly.serialization.blocks.BlockSerializer();
    const state = serializer.save(workspace);
    // console.log("state", state);
    setSavedBlocks(state);
    setCodeAsString({ code });
    // serializer.load(state, workspace);
    // console.log(
    //   "serializer.load(state, workspace)",
    //   serializer.load(state, workspace)
    // );
    // console.log(
    //   "Blockly.serialization.workspaces.load(savedBlocks)",
    //   Blockly.serialization.workspaces.load(savedBlocks)
    // );
  }
  // console.log("savedBlocks", savedBlocks);
  function toggleTerminal() {
    setShowTerminal(!showTerminal);
  }

  async function runCode() {
    setShowTerminal(true);
    setStdOut("");
    const sub = await createSubmission(code);
    // console.log(sub.data);
    setSubToken(sub.data.token);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (subToken !== null) {
        getSubmission(subToken).then((sub) => {
          if (![1, 2].includes(sub.data.status.id)) {
            setSubToken(null);
            // console.log("sub :>> ", sub);
          }

          if (sub.data.stdout !== null) {
            setStdOut(sub.data.stdout);
          }
        });
      }
    }, 100);
    return () => clearInterval(interval);
  }, [subToken]);

  return (
    <div style={{ width: "95%", margin: "auto" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 5px",
        }}
      >
        <div>
          <h1>Blocks</h1>
        </div>
        <div style={{ display: "flex" }}>
          <button
            style={{
              padding: "4px 12px",
              color: "white",
              background: "#6358A5",
              cursor: "pointer",
              border: "none",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              boxShadow:
                " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              margin: "0 12px",
              width: "160px",
            }}
            onClick={() => {
              sendCode();
              jsonToBlock();
            }}
          >
            blocks to Json
          </button>
          <button
            disabled={subToken !== null}
            onClick={runCode}
            style={{
              padding: "4px 12px",
              color: "white",
              background: "#6358A5",
              cursor: "pointer",
              border: "none",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              boxShadow:
                " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              margin: "0",
              width: "160px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <p */}
              {/* style=
              {{
                fontSize: "16px",
                color: "white",
                margin: "0",
                padding: "0",
              }}>  */}
              run code
              {/* </p>{" "} */}
              {false && (
                <PlayArrowOutlined
                  color="#fff"
                  // fontSize="28px"
                  style={{
                    color: "#fff",
                    margin: "0",
                    padding: "0",
                    fontSize: "28px",
                  }}
                />
              )}
            </div>
          </button>
        </div>
      </div>
      <div style={{}}>
        <div
          style={{
            background: "white",
            padding: "20px",
            borderRadius: "8px",
            boxShadow:
              " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          }}
        >
          <h3>code:</h3>
          {savedBlocks && code.length > 0 && (
            <p>{code.length > 0 ? JSON.stringify(codeAsString) : null}</p>
          )}
        </div>
        <div
          style={{
            background: "white",
            padding: "20px",
            margin: "10px 0",
            borderRadius: "8px",
            boxShadow:
              " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          }}
        >
          <h3>Blocks code in Json:</h3>
          {savedBlocks && <p>{JSON.stringify(savedBlocks)}</p>}
        </div>
      </div>
      {/* ============ */}

      {/* <div>
          <button
            style={{
              padding: "4px 10px 4px 0",
              // color: "white",
              // background: "rgba(99, 88, 165,0.8)",
              background: "rgba(99, 88, 165,0)",
              opacity: "1",
              cursor: "pointer",
              border: "none",
              // width: "120px",
              borderRadius: "100vw",
              borderTopRightRadius: "0",
              borderBottomRightRadius: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              // boxShadow:
              //   " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              margin: " 0 0 10px  0",
            }}
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
            <h3 style={{ margin: "0" }}> Exit Blocks</h3>
          </button>
        </div> */}
      {/* ============ */}
      {/* <BlocksHeader
        switchCodeView={switchCodeView}
        runCode={runCode}
        toggleTerminal={toggleTerminal}
        running={subToken !== null}
        setBlockTheme={setBlockTheme}
      /> */}
      <div style={{}}>
        {/* <AutoAwesomeMosaicIcon onClick={() => setGridView((prev) => !prev)} /> */}
        <div
          style={{
            display: "flex",
            // gridTemplateColumns: "3fr 1fr",
            // gap: "20px",
            borderRadius: "8px",
            height: "80vh",
            overflow: "hidden",
            boxShadow:
              " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          }}
        >
          <div
            style={{
              width: gridView ? "60%" : "100%",
              borderRight: gridView ? "12px solid rgb(99, 88, 165)" : "",
              height: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                background: "rgb(99, 88, 165)",
                padding: "6px 10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h3
                style={{
                  color: "white",
                  margin: "0",
                }}
              >
                BLOCK CODING ENVIRONMENT
              </h3>
              <div>
                {gridView ? (
                  <ChevronRightIcon
                    color="#fff"
                    fontSize="38px"
                    style={{
                      color: "#fff",
                      margin: "0",
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setGridView((prev) => !prev) &&
                      Blockly.svgResize(workspace)
                    }
                  />
                ) : (
                  <ChevronLeftIcon
                    color="#fff"
                    fontSize="38px"
                    style={{
                      color: "#fff",
                      margin: "0",
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setGridView((prev) => !prev) &&
                      Blockly.svgResize(workspace)
                    }
                  />
                )}
              </div>
            </div>
            <div
              style={{ width: "100%", height: "calc(100% - 37px)" }}
              className="fill-height blocklyDiv"
              id="blocklyDiv"
              ref={blocklyDiv}
              // style="height: 800px; width: 100%;"
            ></div>
          </div>
          {gridView && (
            <div
              style={{
                // display: "flex",
                // flexDirection: "column",
                width: gridView ? "40%" : "0%",
                height: "80vh",
                display: "grid",
                gridTemplateRows: "1fr 1fr",
                // boxShadow:
                //   " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                borderBottom: "2px solid rgb(99, 88, 165)",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h3
                    style={{
                      color: "white",
                      margin: "0",
                      background: "rgb(99, 88, 165)",
                      padding: "6px 10px",
                      width: "100%",
                    }}
                  >
                    Python Code
                  </h3>
                  <Editor
                    style={{}}
                    width="100%"
                    language="python"
                    value={code}
                    theme="vs-dark"
                    height="38vh"
                    options={{
                      readOnly: true,
                      fontSize: "18px",
                      cursorStyle: "block",
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  height: "100%",
                  overflow: "hidden",
                  width: "100%",
                  // boxShadow:
                  //   " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    background: "rgb(99, 88, 165)",
                    padding: "6px 10px",
                    // overflow:'auto'
                  }}
                >
                  <h3
                    style={{
                      color: "white",
                      margin: "0",
                    }}
                  >
                    Output
                  </h3>
                </div>
                <div
                  style={{
                    overflow: "auto",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <XTermNew stdOut={stdOut} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlocksQuestionGen;
