import React from "react";
import { handleLogout } from "auth";
import { useMsal } from "@azure/msal-react";
// import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import * as Sentry from "@sentry/react";
import EmptyState from "components/EmptyState/EmptyState";
import { PriorityHigh } from "@mui/icons-material";
import { Box } from "@material-ui/core";

export const ErrorBoundaryView = () => {
  const { instance, accounts } = useMsal();

  // const classes = useStyles();
  return (
    <Box position="relative" flex="1">
      <EmptyState
        width={12}
        widthSM={6}
        avatarIcon={<PriorityHigh style={{ width: 40, height: 40 }} />}
        title="Sorry, something went wrong!"
        text="We're working hard to fix it."
        text2="Try refreshing the page, or switching users. Otherwise, send us a quick message explaining the problem."
        buttons={[
          { text: "Logout", action: () => handleLogout(instance) },
          {
            text: "Report",
            action: () =>
              Sentry.showReportDialog({
                user: { email: accounts[0].username, name: accounts[0].name },
              }),
          },
        ]}
      />
    </Box>

    // <Grid
    //   className={classes.root}
    //   spacing={0}
    //   alignItems="center"
    //   justify="center"
    // >
    //   <Box width="300px">
    //     <Typography variant="h6">
    //       Something went wrong. We're doing our best to fix this problem. Try
    //       refreshing the page, or using a different account. Otherwise, you can
    //       report this error directly to us.
    //     </Typography>
    //     <Button
    //       color="primary"
    //       variant="contained"
    //       onClick={() => handleLogout(instance)}
    //     >
    //       Logout
    //     </Button>
    //     <Button
    //       color="primary"
    //       variant="contained"
    //       onClick={() =>
    //         Sentry.showReportDialog({
    //           user: { email: accounts[0].username, name: accounts[0].name },
    //         })
    //       }
    //     >
    //       Report Error
    //     </Button>
    //   </Box>
    // </Grid>
  );
};
