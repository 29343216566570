import React from "react";
// import AddBoxIcon from "@mui/icons-material/AddBox";
// import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
// import BuilderCurriculumPlayer from "./BuilderCurriculumPlayer";
// import Modal from "@mui/material/Modal";
import { useQuery } from "react-query";
import useCurriculumApi from "api/curriculum";
const BuilderLessonCard = ({
  lesson,
  addLesson,
  deleteLesson,
  selected,
  index,
  setSelectedLessonIdPlayer,
  selectedLessonIdPlayer,
  setSelectedLesson,
}) => {
  // console.log("selected", selected);
  const [open, setOpen] = React.useState(false);
  const { fetchLessonObjectives, fetchResource } = useCurriculumApi();
  const {
    isLoading: isLoadingLO,
    isError: isErrorLO,
    data: learningObjectives,
    status,
    isSuccess: isSuccessLO,
  } = useQuery(
    ["lessonLearningObjectivesBuilder", selectedLessonIdPlayer],
    () => fetchLessonObjectives(selectedLessonIdPlayer),
    {
      enabled: selectedLessonIdPlayer?.length > 0,
      refetchOnWindowFocus: false,
    }
  );
  const renameLessonName = (name) => {
    return name
      .replace("Stage 1", "")
      .replace("Stage 2", "")
      .replace("-", "")
      .replace("Stage 3", "");
  };
  const handleVisibility = (image, name, id) => {
    if (selected) {
      deleteLesson(id);
    } else {
      addLesson({ image: image, name: name, id: id });
    }
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        boxShadow: "0 1px 2px hsla(0,0%, 0%, .2)",
        height: "100%",
        position: "relative",
        width: "250px",
        cursor: "pointer",
        borderRadius: "4px",
      }}
      onClick={() => {
        // handleVisibility(lesson.image, lesson.name, lesson.id);
        setSelectedLessonIdPlayer(lesson.id);
        setSelectedLesson(lesson);
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "2px",
          borderRadius: "8px",
          width: "60px",
        }}
      >
        {index > 0 && (
          <div
            style={{
              background: "#06669d",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "25px",
              height: "25px",
              position: "absolute",
              top: "0",
              margin: "0",
              right: "0",
              borderRadius: "100px",
              transform: "translate(50%,-50%)",
              color: "white",
            }}
          >
            <h4 style={{ color: "white", margin: "0", padding: "0px" }}>{index}</h4>
          </div>
        )}
        <div style={{ width: "64px" }}>
          <img
            src={lesson.image}
            style={{
              width: "64px",
              borderRadius: "6px",
              opacity: selected ? "0.3" : "1",
              cursor: "pointer",
            }}
            alt=""
            srcSet=""
          />
        </div>
      </div>
      <div
        style={{
          backgroundColor: "rgba(5, 101, 158, 0.05)",
          width: "calc(100% - 64px)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "0px 0 0 10px",
            fontSize: "12px",
            margin: "0",
          }}
        >
          {renameLessonName(lesson.name)}
        </div>
      </div>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            height: "90vh",
            width: "90vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            backgroundColor: "white",
            padding: "20px",
          }}
        >
          <BuilderCurriculumPlayer
            selectedLessonIdPlayer={selectedLessonIdPlayer}
            lessonName={renameLessonName(lesson.name)}
            learningObjectives={learningObjectives && learningObjectives?.data}
          />
        </div>
      </Modal> */}
    </div>
  );
};

export default BuilderLessonCard;
