import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
// import useStore from "../../../../../../zustand";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

// core components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@material-ui/core/Button";
// @material-ui/core components
import FormGroup from "@material-ui/core/FormGroup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// core components
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { Box, Grid, MenuItem, Select } from "@material-ui/core";
import componentStyles from "assets/theme/views/admin/validation.js";
import componentStylesForms from "assets/theme/components/forms.js";
import componentStylesError from "assets/theme/components/snackbar.js";

const useStyles = makeStyles(componentStyles);
const useStylesForms = makeStyles(componentStylesForms);
const useStylesError = makeStyles(componentStylesError);

export default function AddRowTeacher({
  list,
  setList,
  teacherData,
  mutate,
  isLoading,
  isSuccess,
  isError,
  error,
  setOpenSnackbarX,
  handleClose,
  classesList,
  classesListStatus,
  tenantIdSchool,
}) {
  const classes = { ...useStyles(), ...useStylesForms(), ...useStylesError() };
  // const tenantId = useStore((state) => state.tenantId);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const buttonErrorClassesContained = { root: classes.buttonContainedError };
  const buttonSuccessClasses = { root: classes.buttonContainedSuccess };
  const [currentClass, setCurrentClass] = React.useState("");
  const [allClasses, setAllClasses] = React.useState(null);

  const setAllClass = () => {
    const cloneList = [...list];
    cloneList.forEach((item) => (item["class"] = allClasses));
    setList(cloneList);
  };

  const setAllClass2 = (value) => {
    const cloneList = [...list];
    cloneList.forEach((item) => (item["class"] = value));
    setList(cloneList);
  };

  // Teacher Rows
  const handleAddRow = () => {
    setList([
      ...list,
      {
        valid: false,
        class: null,
        first_name: "",
        last_name: "",
        email: "",
        school: tenantIdSchool,
      },
    ]);
  };

  useEffect(() => {
    if (isSuccess) {
      setList([
        {
          valid: false,
          class: null,
          first_name: "",
          last_name: "",
          email: "",
          school: tenantIdSchool,
        },
      ]);
    }
  }, [isSuccess]);

  const handleClearRow = () => {
    setList([]);
  };

  const handleDeleteRow = (index) => {
    let newList = [...list];
    newList.splice(index, 1);
    setList([...newList]);
  };

  // List Info of Teachers
  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const cloneList = [...list];
    const clonedItem = cloneList[index];

    clonedItem[name] = value;
    cloneList[index] = clonedItem;
    setList(cloneList);

    event.preventDefault();
  };

  const handleChangeClass = (value, index) => {
    const cloneList = [...list];
    const clonedItem = cloneList[index];

    clonedItem["class"] = value;
    cloneList[index] = clonedItem;
    setList(cloneList);
  };

  const handleChangeIsValid = (index, isValid) => {
    const cloneList = [...list];
    const clonedItem = cloneList[index];

    clonedItem["valid"] = isValid;
    cloneList[index] = clonedItem;

    setList(cloneList);
  };

  let validChecker = list.every((v) => v.valid === true);

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: "95%", margin: "auto", border: "none" }}
        sx={{ maxHeight: 440 }}
      >
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                style={{ color: "#525f7f", paddingBottom: 0 }}
              >
                First Name
              </TableCell>
              <TableCell
                align="left"
                style={{ color: "#525f7f", paddingBottom: 0 }}
              >
                Last Name
              </TableCell>
              <TableCell
                align="left"
                style={{ color: "#525f7f", paddingBottom: 0 }}
              >
                Email
              </TableCell>
              <TableCell
                align="left"
                style={{
                  color: "#525f7f",
                  paddingBottom: 0,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {
                  <SelectAllClass
                    setCurrentClass={setCurrentClass}
                    setAllClass2={setAllClass2}
                    index={1}
                    classesList={classesList}
                    classesListStatus={classesListStatus}
                  />
                }
              </TableCell>
              <TableCell
                align="left"
                style={{ color: "#525f7f", paddingBottom: 0 }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((person, index) => (
              <TableEachRow
                index={index}
                person={person}
                handleChange={handleChange}
                handleDeleteRow={handleDeleteRow}
                classesList={classesList}
                classesListStatus={classesListStatus}
                handleChangeClass={handleChangeClass}
                handleChangeIsValid={handleChangeIsValid}
                teacherData={teacherData}
                error={error}
                isError={isError}
                setErrorMessage={setErrorMessage}
                setAllClass={setAllClass}
                setAllClasses={setAllClasses}
                currentClass={currentClass}
                setCurrentClass={setCurrentClass}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        container
        style={{
          margin: 0,
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          padding: "20px 20px",
        }}
      >
        <Grid
          item
          sx={6}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            marginLeft: 0,
            padding: 5,
          }}
        >
          <Button
            onClick={handleAddRow}
            variant="contained"
            classes={buttonSuccessClasses}
            style={{
              margin: 5,
              minWidth: "90px",
              height: "43px",
              background: "#1D6F98",
              borderColor: "#1D6F98",
            }}
          >
            + Add
          </Button>
          <Button
            onClick={handleClearRow}
            variant="contained"
            classes={buttonErrorClassesContained}
            style={{
              margin: 5,
              minWidth: "105px",
              height: "43px",
              background: "#f5365c",
              borderColor: "#f5365c",
            }}
          >
            x Clear
          </Button>
        </Grid>
        <Grid item sx={6} style={{ padding: 5 }}>
          <div>
            {isLoading ? (
              "Loading..."
            ) : (
              <>
                <Button
                  variant="contained"
                  style={{
                    margin: 5,
                    width: "170px",
                    background: validChecker ? "#1D6F98" : "#a3a3a3",
                    borderColor: validChecker ? "#1D6F98" : "#a3a3a3",
                    color: "white",
                  }}
                  disabled={!validChecker}
                  onClick={() => (
                    // handleClose(),
                    mutate(list), setOpenSnackbarX(true)
                  )}
                >
                  Add Teacher
                </Button>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
}

const TableEachRow = ({
  currentClass,
  setCurrentClass,
  setAllClasses,
  setAllClass,
  index,
  person,
  isError,
  error,
  handleChange,
  handleChangeIsValid,
  handleDeleteRow,
  handleChangeClass,
  classesList,
  classesListStatus,
}) => {
  const classes = { ...useStyles(), ...useStylesForms() };
  const [isValid, setIsValid] = React.useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");

  // Custom validation
  const [customValidationName, setCustomValidationName] = React.useState(null);
  const [customValidationSurname, setCustomValidationSurname] =
    React.useState(null);
  const [customValidationEmail, setCustomValidationEmail] =
    React.useState(null);

  const buttonErrorClasses = {
    root: classes.buttonOutlineError,
  };

  const validateEmail = (email) => {
    const regex =
      /^(([^<>()[\]\.,;\+:\s@\"]+(\.[^<>()[\]\.,;\+:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (email === "") {
      setCustomValidationEmail("required");
      setEmailErrorMessage("Email is required.");
    } else if (!email || regex.test(email) === false) {
      setCustomValidationEmail("required");
      setEmailErrorMessage("Invalid email type.");
    } else {
      setCustomValidationEmail("valid");
    }
  };

  const errorArr = isError && error.response.data.errors;

  useEffect(() => {
    if (isError && errorArr?.length > 0) {
      if (errorArr.some((item) => item.index === index)) {
        setEmailErrorMessage("Duplicate email");
        setCustomValidationEmail("required");
      }
    } else {
      // console.log('no response')
    }
  }, [isError]);

  useEffect(() => {
    if (person && person.first_name?.trim() == "") {
      setCustomValidationName("required");
    } else {
      setCustomValidationName("valid");
    }

    if (person && person.last_name?.trim() == "") {
      setCustomValidationSurname("required");
    } else {
      setCustomValidationSurname("valid");
    }

    if (person) {
      validateEmail(person.email);
    }
  }, [person]);

  useEffect(() => {
    handleChangeIsValid(index, isValid);
  }, [isValid]);

  useEffect(() => {
    setIsValid(
      customValidationName == "valid" &&
        customValidationSurname == "valid" &&
        customValidationEmail == "valid"
    );
  }, [customValidationName, customValidationSurname, customValidationEmail]);

  return (
    <TableRow
      key={index}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell align="left">
        <FormGroup style={{ margin: "0px" }}>
          <OutlinedInput
            required
            fullWidth
            autoFocus
            type="text"
            placeholder="John"
            name="first_name"
            style={{ border: "1px solid #CBD2D9", minWidth: 130 }}
            value={person.first_name || ""}
            classes={{
              notchedOutline: clsx({
                [classes.borderWarning]: customValidationName === "required",
                [classes.borderSuccess]: customValidationName === "valid",
              }),
            }}
            onChange={(e) => {
              handleChange(e, index);
              if (e.target.value.trim() == "") {
                setCustomValidationName("required");
              } else {
                setCustomValidationName("valid");
              }
            }}
          />
          {customValidationName === "valid" ? (
            <FormHelperText component={Box} style={{ color: "#2dce89" }}>
              Looks good!
            </FormHelperText>
          ) : (
            <FormHelperText component={Box} style={{ color: "#fb6340" }}>
              First Name is required.
            </FormHelperText>
          )}
        </FormGroup>
      </TableCell>
      <TableCell align="left">
        <FormGroup style={{ margin: "0px" }}>
          <OutlinedInput
            style={{ border: "1px solid #CBD2D9", minWidth: 130 }}
            fullWidth
            type="text"
            placeholder="Snow"
            name="last_name"
            value={person.last_name || ""}
            classes={{
              notchedOutline: clsx({
                [classes.borderWarning]: customValidationSurname === "required",
                [classes.borderSuccess]: customValidationSurname === "valid",
              }),
            }}
            onChange={(e) => {
              handleChange(e, index);
              if (e.target.value.trim() == "") {
                setCustomValidationSurname("required");
              } else {
                setCustomValidationSurname("valid");
              }
            }}
          />
          {customValidationSurname === "valid" ? (
            <FormHelperText component={Box} style={{ color: "#2dce89" }}>
              Looks good!
            </FormHelperText>
          ) : (
            <FormHelperText component={Box} style={{ color: "#fb6340" }}>
              Last Name is required.
            </FormHelperText>
          )}
        </FormGroup>
      </TableCell>
      <TableCell align="left">
        <FormGroup style={{ margin: "0px" }}>
          <OutlinedInput
            fullWidth
            type="email"
            placeholder="name@example.com"
            name="email"
            style={{ border: "1px solid #CBD2D9", minWidth: 130 }}
            value={person.email || ""}
            classes={{
              notchedOutline: clsx({
                [classes.borderWarning]: customValidationEmail === "required",
                [classes.borderSuccess]: customValidationEmail === "valid",
              }),
            }}
            onChange={(e) => {
              handleChange(e, index);
              validateEmail(e.target.value);
            }}
          />
          {customValidationEmail === "valid" ? (
            <FormHelperText component={Box} style={{ color: "#2dce89" }}>
              Looks good!
            </FormHelperText>
          ) : (
            <FormHelperText component={Box} style={{ color: "#fb6340" }}>
              {emailErrorMessage}
            </FormHelperText>
          )}
        </FormGroup>
      </TableCell>

      <TableCell align="center">
        {
          <SelectClass
            currentClass={currentClass}
            setCurrentClass={setCurrentClass}
            setAllClasses={setAllClasses}
            index={index}
            classesList={classesList}
            classesListStatus={classesListStatus}
            handleChangeClass={handleChangeClass}
          />
        }
        <FormHelperText component={Box} style={{ color: "white" }}>
          s
        </FormHelperText>
      </TableCell>

      <TableCell align="center">
        <FormGroup style={{ margin: "0px" }}>
          <Button
            onClick={() => handleDeleteRow(index)}
            variant="outlined"
            classes={buttonErrorClasses}
            style={{ width: "5px" }}
          >
            X
          </Button>
        </FormGroup>
        <FormHelperText component={Box} style={{ color: "white" }}>
          s
        </FormHelperText>
      </TableCell>
    </TableRow>
  );
};

function SelectClass({
  currentClass,
  setCurrentClass,
  index,
  classesList,
  classesListStatus,
  handleChangeClass,
}) {
  const [thisClass, setThisClass] = React.useState("");

  React.useEffect(() => {
    setThisClass(currentClass);
  }, [currentClass]);

  return (
    <>
      <FormControl
        sx={{
          m: 1,
          width: { xs: 130, sm: 150 },
        }}
        variant="standard"
        size="small"
      >
        <Select
          value={thisClass}
          onChange={(e) => (
            handleChangeClass(e.target.value, index),
            setThisClass(e.target.value)
          )}
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          style={{ color: "#fff" }}
          input={<BootstrapInput />}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            Class
          </MenuItem>
          {classesListStatus === "success" &&
            classesList.data.map((item) => (
              <MenuItem value={item.id}>{item.name}</MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
}

function SelectAllClass({
  setCurrentClass,
  setAllClass2,
  classesList,
  classesListStatus,
}) {
  const [thisClass, setThisClass] = React.useState("");

  return (
    <>
      <FormControl
        sx={{
          m: 1,
          width: { xs: 130, sm: 150 },
        }}
        variant="standard"
        size="small"
      >
        <FormHelperText
          style={{
            marginLeft: 3,
            marginBottom: 4,
            fontWeight: "bold",
            color: "rgb(82, 95, 127)",
          }}
        >
          Apply to all
        </FormHelperText>
        <Select
          value={thisClass}
          placeholder="Class"
          onChange={(e) => (
            setAllClass2(e.target.value),
            setCurrentClass(e.target.value),
            setThisClass(e.target.value)
          )}
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          style={{ color: "#fff" }}
          input={<BootstrapInput />}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            Class
          </MenuItem>
          {classesListStatus === "success" &&
            classesList?.data.map((item) => (
              <MenuItem value={item.id}>{item.name}</MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    color: "#8898aa",
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "transparent",
    border: "1px solid #ced4da",
    fontSize: 16,
    textAlign: "center",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      // borderColor: 'white',
      // boxShadow: '0 0 0 0.1rem white',
    },
  },
}));
