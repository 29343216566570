import { Card } from "@material-ui/core";
import { ErrorOutlined } from "@mui/icons-material";

const { CircularProgress, Box } = require("@mui/material");

const CurriculumWrapperCard = ({ status, children }) => {
    return (
        <Card style={{ marginBottom: 0, display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
            {status == "loading" ? (
                <Box sx={{ padding: "30px" }}>
                    <CircularProgress />
                </Box>
            ) : status == "error" ? (
                <Box sx={{ padding: "30px" }}>
                    <ErrorOutlined />
                </Box>
            ) : (
                <>{children}</>
            )}
        </Card>
    );
};

export default CurriculumWrapperCard;
