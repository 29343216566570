import { Box, Container } from "@material-ui/core";
import { CurriculumGrid } from "./Components/CurriculumGrid";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons components
import componentStyles from "assets/theme/views/admin/dashboard.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
// import VerticalToggleButtons from "./ColumnPage/TogglePage";
// import MyLevelsHeader from "components/Headers/MyLevelsHeader";
// import SimpleHeader from "components/Headers/SimpleHeader";
const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

export const CurriculumPage = ({ view, sections }) => {
  const classes = { ...useStyles(), ...useStylesCardDeck() };
  return (
    <>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="0rem"
        padding="20px"
        style={{ maxWidth: 1280 }}
        classes={{ root: classes.containerRoot }}
      >
        <CurriculumGrid sections={sections} />
      </Container>
    </>
  );
};
