import React from "react";
// javascript plugin that creates a sortable object from a dom object
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Pagination from "@material-ui/lab/Pagination";
import { useTheme } from "@material-ui/core/styles";
import { Avatar, CardContent } from "@material-ui/core";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import useDataApi from "api/data";
import { useQuery } from "react-query";
import { getDateTime } from "utils/utils";
// import { getDuration } from "utils/utils";
// import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles(componentStyles);

export default function Page3List({ student, exerciseId }) {
  const classes = useStyles();
  const theme = useTheme();

  const { fetchQuestionResults } = useDataApi();

  const { data: questionResults, status: questionResultsStatus } = useQuery(
    ["question_results", student.id, exerciseId],
    () => fetchQuestionResults(student.id, exerciseId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const tableConatinerRef = React.useRef(null);

  return (
    <>
      <Card
        classes={{ root: classes.cardRoot }}
        style={{ paddingBottom: "30px", marginBottom: 0, width: "100%" }}
      >
        <CardHeader
          title={
            <Box component="span" color={theme.palette.gray[600]}>
              {student.full_name}
            </Box>
          }
          classes={{ root: classes.cardHeaderRoot }}
          titleTypographyProps={{
            component: Box,
            variant: "h1",
            letterSpacing: "2px",
            marginBottom: "0!important",
            classes: {
              root: classes.textUppercase,
            },
          }}
          style={{ margin: "auto" }}
        ></CardHeader>

        <TableContainer ref={tableConatinerRef}>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                <TableCell
                  scope="col"
                  classes={{
                    root:
                      classes.tableCellRoot +
                      " sort " +
                      classes.tableCellRootHead,
                  }}
                >
                  Name
                </TableCell>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    align="center"
                    scope="col"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " sort " +
                        classes.tableCellRootHead,
                    }}
                  >
                    {prop}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="list">
              {questionResultsStatus == "success"
                ? questionResults.data.map((question, key) => (
                    <>
                      <TableRowStudents
                        key={key}
                        question={question}
                        classes={classes}
                      />
                    </>
                  ))
                : null}
            </TableBody>
          </Box>
        </TableContainer>
      </Card>
    </>
  );
}

export function TableRowStudents({ key, question, classes, setData }) {
  const [open, setOpen] = React.useState(false);

  function handleClick(e) {
    e.preventDefault();

    setOpen(!open);
  }

  return (
    <>
      <TableRow
        hover
        key={key}
        style={{
          cursor: "pointer",
        }}
      >
        <TableCell
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootBodyHead,
          }}
          component="th"
          variant="head"
          scope="row"
          // onClick={handleClick}
        >
          <Box fontSize=".875rem" component="span" className="name">
            {question.name}
          </Box>
        </TableCell>

        <TableCell
          onClick={handleClick}
          classes={{ root: classes.tableCellRoot }}
          align="center"
        >
          <Box paddingTop=".35rem" paddingBottom=".35rem">
            {getDateTime(question.end_time * 1000)}
          </Box>
        </TableCell>

        {/* <TableCell
          onClick={handleClick}
          classes={{ root: classes.tableCellRoot }}
          align="center"
        >
          <Box paddingTop=".35rem" paddingBottom=".35rem">
            {getDuration(question.time_taken)}
          </Box>
        </TableCell> */}

        <TableCell
          onClick={handleClick}
          classes={{ root: classes.tableCellRoot }}
          align="center"
        >
          <Box paddingTop=".35rem" paddingBottom=".35rem">
            {question.score.replace("%", "").slice(0, 5) + "%"}
          </Box>
        </TableCell>
        <TableCell
          onClick={handleClick}
          classes={{ root: classes.tableCellRoot }}
          align="center"
        >
          <Box paddingTop=".35rem" paddingBottom=".35rem">
            {question.marks}
          </Box>
        </TableCell>

        <TableCell
          onClick={handleClick}
          classes={{ root: classes.tableCellRoot }}
          align="center"
        >
          <Box paddingTop=".35rem" paddingBottom=".35rem">
            {question.type}
          </Box>
        </TableCell>
        {/* <TableCell
          onClick={handleClick}
          classes={{ root: classes.tableCellRoot }}
          align="center"
        >
          <Box paddingTop=".35rem" paddingBottom=".35rem">
            {question.difficulty}
          </Box>
        </TableCell>

        <TableCell
          onClick={handleClick}
          classes={{ root: classes.tableCellRoot }}
          align="right"
        >
          <Box paddingTop=".35rem" paddingBottom=".35rem">
            {open ? (
              <i className="ni ni-bold-up"></i>
            ) : (
              <i className="ni ni-bold-down"></i>
            )}
          </Box>
        </TableCell> */}
      </TableRow>
      {/* <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, margin: "auto" }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <StudentAnswer />
          </Collapse>
        </TableCell>
      </TableRow> */}
    </>
  );
}

export function StudentAnswer() {
  const classes = useStyles();

  return (
    <>
      <Card
        classes={{
          root: classes.cardRoot,
        }}
        style={{ margin: "20px auto", width: "98%" }}
      >
        <CardContent>
          <Box position="relative" height="200px">
            <Box
              style={{
                margin: 70,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <Avatar
                  classes={{ root: classes.avatarRoot }}
                  alt="..."
                  style={{
                    width: 80,
                    height: 80,
                    marginBottom: 25,
                    border: "2px solid #06659D",
                    background: "#fff",
                    boxShadow: "0 2px 4px hsla(0, 0%, 0%, 0.2)",
                  }}
                >
                  <QuestionAnswerIcon />
                </Avatar>
              </div>
              <p
                style={{
                  color: "#7B8794",
                  fontSize: "18px",
                  fontWeight: 600,
                  marginBottom: 5,
                  textAlign: "center",
                }}
              >
                Coming Soon...
              </p>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

const tableHead = [
  "Submitted",
  // "Time Taken",
  "Score",
  "Marks",
  "Question Type",
  // "Difficulty",
  // "",
];
