import {
  Box,
  Button,
  Divider,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  OutlinedInput,
  Slider,
} from "@mui/material";
import React, { useEffect } from "react";
import "./Style/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import useUserApi from "api/user";
// import useCurriculumApi from "api/curriculum";
// import { useQuery, useMutation } from "react-query";
import BoltIcon from "@mui/icons-material/Bolt";
import { ErrorScreen } from "views/eai/curriculum/Components/ErrorScreen";
import useUserApi from "api/user";
import useCurriculumApi from "api/curriculum";
import { useQuery } from "react-query";
import moment from "moment";
import { LoadingScreen } from "views/eai/curriculum/Components/LoadingScreen";
import useStore from "../../../zustand.js";
import CreateActivityDropdown from "./CreateActivityDropdown";

function CreateActivityForm({
  hide,
  mutate,
  isSuccess,
  isError,
  isLoading,
  error,
  status,
  setOpenSnackbarQuiz,
}) {
  const { lessonLearningObjectives, setLessonLearningObjectives } = useStore();

  const [testClassName, setTestClassName] = React.useState("");
  const [testClassDate, setTestClassDate] = React.useState("");

  //Fetch data

  const { fetchClasses, fetchStudents } = useUserApi();
  const { fetchLearningObjectives, fetchTopics } = useCurriculumApi();

  const { data: classData, status: classStatus } = useQuery(
    "class",
    () => fetchClasses(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: studentData, status: studentStatus } = useQuery(
    "students",
    () => fetchStudents(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const {
    data: topicData,
    status: topicStatus,
    isLoading: isLoadingTopic,
    isError: isErrorCC,
  } = useQuery("topics", () => fetchTopics(), {
    refetchOnWindowFocus: false,
  });
  const {
    data: learningData,
    status: learningStatus,
    isLoading: isLoadingLO,
    isError: isErrorLO,
  } = useQuery("learning", () => fetchLearningObjectives(), {
    refetchOnWindowFocus: false,
  });

  // Create new Quiz
  // const { mutate, isLoading, isSuccess, isError, error, status } = useMutation(
  //   (quiz) => addQuiz(quiz)
  // );

  // Clear inputs after saving
  // React.useEffect(() => {
  //     status === 'success' && setLessonLearningObjectives({})
  // }, [status]);

  // Custom validations
  const [customValidationName, setCustomValidationName] = React.useState(null);
  const [customValidationDate, setCustomValidationDate] = React.useState(null);
  const [customValidationClass, setCustomValidationClass] =
    React.useState(null);
  const [customValidationStudents, setCustomValidationStudents] =
    React.useState(null);
  const [customValidationTopics, setCustomValidationTopics] =
    React.useState(null);
  const [customValidationLearning, setCustomValidationLearning] =
    React.useState(null);
  const [isValid, setIsValid] = React.useState(true);

  React.useEffect(() => {
    setIsValid(
      customValidationName == "valid" &&
        customValidationDate == "valid" &&
        customValidationClass == "valid" &&
        customValidationStudents == "valid" &&
        customValidationTopics == "valid" &&
        customValidationLearning == "valid"
    );
  }, [
    customValidationName,
    customValidationDate,
    customValidationClass,
    customValidationStudents,
    customValidationTopics,
    customValidationLearning,
  ]);

  const [dataMessage, setDateMessage] = React.useState("Due Date is required.");

  useEffect(() => {
    if (testClassDate.length !== 0) {
      if (
        Math.floor(moment().valueOf() / (24 * 60 * 60 * 1000) - 1) >
        Math.floor(
          moment(testClassDate, "YYYY-MM-DD").valueOf() / (24 * 60 * 60 * 1000)
        )
      ) {
        setCustomValidationDate("required");
        setDateMessage("Date must be today or in the future");
      } else {
        setCustomValidationDate("valid");
      }
    }
  }, [testClassDate]);

  React.useEffect(() => {
    moment(testClassDate).format("YYYY-MM-DD").length === 12 &&
      setCustomValidationDate("required");
  }, [testClassDate]);

  React.useEffect(() => {
    if (testClassName.length > 0) {
      setCustomValidationName("valid");
    } else {
      setCustomValidationName("");
    }
  }, [testClassName]);

  const [classStudents, setClassStudents] = React.useState([]);
  const [topicLearningObj, setTopicLearningObj] = React.useState([]);

  // Snackbar disappear
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenSnackbar(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [openSnackbar]);

  //Post API
  const [classID, setClassID] = React.useState([]);
  const [studentID, setStudentID] = React.useState([]);
  const [topicID, setTopicID] = React.useState([]);
  const [objectiveID, setObjectiveID] = React.useState([]);
  const [questions, setQuestions] = React.useState(10);
  const [duration, setDuration] = React.useState(15);

  if (isLoading || isLoadingTopic || isLoadingLO)
    return (
      <div style={{ padding: "0px 140px 140px" }}>
        <LoadingScreen />
      </div>
    );
  if (isErrorLO || isErrorCC) {
    return <ErrorScreen error={error} />;
  }

  return (
    <Grid
      style={{
        border: "2px solid rgb(6, 101, 157)",
        padding: 40,
        background: "aliceblue",
        borderRadius: "10px",
        margin: "10px auto 100px",
        width: "95%",
        maxWidth: "calc(100vw - 350px)",
      }}
      container
      spacing={2}
    >
      <Grid item xs={12} style={{ height: 100 }}>
        <FormGroup>
          <Box
            fullWidth
            color="secondary"
            variant="contained"
            style={{
              color: "#094B72",
              background: "white",
              border: "1px solid rgb(203, 210, 217)",
              fontSize: 18,
              fontFamily: "Open Sans",
              fontWeight: 500,
              fontStyle: "normal",
              boxShadow: "none",
              height: 47,
              padding: "5px 20px",
              borderRadius: 5,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "none",
            }}
          >
            <BoltIcon /> Assign Activity
          </Box>
        </FormGroup>
      </Grid>
      <Grid item xs={6} style={{ height: 120 }}>
        <FormGroup>
          <FormLabel
            style={{
              fontSize: 14,
              color: "#52606D",
              fontFamily: "Open Sans",
              fontWeight: 400,
            }}
          >
            Test Name
          </FormLabel>
          <OutlinedInput
            style={{
              border: "1px solid #CBD2D9",
              height: 47,
              fontSize: 14,
              color: "#7B8794",
              fontWeight: 400,
              fontFamily: "Open Sans",
            }}
            type="text"
            placeholder={testClassName}
            value={testClassName}
            onChange={(e) => {
              setTestClassName(e.target.value);
              if (e.target.value === "") {
                setCustomValidationName("required");
              } else {
                setCustomValidationName("valid");
              }
            }}
          />
          {customValidationName === "valid" ? (
            <FormHelperText
              component={Box}
              style={{
                color: "#2dce89",
                fontFamily: "Open Sans",
                fontSize: 12,
                fontWeight: 400,
              }}
            >
              Looks good!
            </FormHelperText>
          ) : (
            <FormHelperText
              component={Box}
              style={{
                color: "#fb6340",
                marginLeft: 3,
                fontFamily: "Open Sans",
                fontSize: 12,
                fontWeight: 400,
              }}
            >
              Use letters and numbers only
            </FormHelperText>
          )}
        </FormGroup>
      </Grid>
      <Grid item xs={6} style={{ height: 100 }}>
        <FormGroup>
          <FormLabel
            style={{
              fontSize: 14,
              color: "#52606D",
              fontFamily: "Open Sans",
              fontWeight: 400,
            }}
          >
            Due Date
          </FormLabel>
          <DatePicker
            selected={testClassDate}
            dateFormat="yyyy-MM-dd"
            onChange={(date) => {
              setTestClassDate(date);
              if (moment(date, "yyyy-MM-DD", true).isValid() === false) {
                setCustomValidationDate("required");
              } else {
                setCustomValidationDate("valid");
              }
            }}
            customInput={
              <OutlinedInput
                fullWidth
                value={testClassDate}
                style={{
                  border: "1px solid #CBD2D9",
                  color: "grey",
                  height: 47,
                }}
                // classes={{
                //     notchedOutline: clsx({
                //         [classesX.borderWarning]:
                //             customValidationDate === "required",
                //         [classesX.borderSuccess]:
                //             customValidationDate === "valid",
                //     }),
                // }}
              />
            }
          />
          {customValidationDate === "valid" ? (
            <FormHelperText component={Box} style={{ color: "#2dce89" }}>
              Looks good!
            </FormHelperText>
          ) : (
            <FormHelperText component={Box} style={{ color: "#fb6340" }}>
              {dataMessage}
            </FormHelperText>
          )}
        </FormGroup>
      </Grid>
      <Grid item xs={6} style={{ height: 120 }}>
        <FormGroup>
          <FormLabel
            style={{
              fontSize: 14,
              color: "#52606D",
              fontFamily: "Open Sans",
              fontWeight: 400,
            }}
          >
            Class
          </FormLabel>
          <CreateActivityDropdown
            dataFromLesson={lessonLearningObjectives?.classId}
            setLessonLearningObjectives={setLessonLearningObjectives}
            customValidationSelect={customValidationClass}
            FormHelperText={FormHelperText}
            setCustomValidationSelect={setCustomValidationClass}
            maxWidth=""
            apiData={classData}
            apiStatus={classStatus}
            header="CHOOSE CLASS"
            name="name"
            setClassStudents={setClassStudents}
            setCurrentID={setClassID}
            helperText="Class required"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={6} style={{ height: 120 }}>
        <FormGroup>
          <FormLabel
            style={{
              fontSize: 14,
              color: "#52606D",
              fontFamily: "Open Sans",
              fontWeight: 400,
            }}
          >
            Students
          </FormLabel>
          <CreateActivityDropdown
            dataFromLesson={lessonLearningObjectives?.studentList}
            customValidationSelect={customValidationStudents}
            FormHelperText={FormHelperText}
            setCustomValidationSelect={setCustomValidationStudents}
            maxWidth=""
            apiData={studentData}
            apiStatus={studentStatus}
            header="CHOOSE STUDENTS"
            name="full_name"
            filteredData={classStudents.length > 0 && classStudents}
            setCurrentID={setStudentID}
            helperText="Students required"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={6} style={{ height: 120 }}>
        <FormGroup>
          <FormLabel
            style={{
              fontSize: 14,
              color: "#52606D",
              fontFamily: "Open Sans",
              fontWeight: 400,
            }}
          >
            Topics
          </FormLabel>
          <CreateActivityDropdown
            dataFromLesson={lessonLearningObjectives?.compConceptArray}
            customValidationSelect={customValidationTopics}
            setLessonLearningObjectives={setLessonLearningObjectives}
            FormHelperText={FormHelperText}
            setCustomValidationSelect={setCustomValidationTopics}
            maxWidth=""
            apiData={topicData}
            apiStatus={topicStatus}
            header="TOPICS"
            name="name"
            setTopicLearningObj={setTopicLearningObj}
            setCurrentID={setTopicID}
            helperText="Topics required"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={6} style={{ height: 120 }}>
        <FormGroup>
          <FormLabel
            style={{
              fontSize: 14,
              color: "#52606D",
              fontFamily: "Open Sans",
              fontWeight: 400,
            }}
          >
            Learning Objectives
          </FormLabel>
          <CreateActivityDropdown
            dataFromLesson={lessonLearningObjectives?.learnObjArray}
            customValidationSelect={customValidationLearning}
            setLessonLearningObjectives={setLessonLearningObjectives}
            FormHelperText={FormHelperText}
            setCustomValidationSelect={setCustomValidationLearning}
            maxWidth=""
            apiData={learningData}
            apiStatus={learningStatus}
            header="VARIABLES"
            name="objective"
            filteredData={topicLearningObj.length > 0 && topicLearningObj}
            setCurrentID={setObjectiveID}
            helperText="Learning objectives required"
          />
        </FormGroup>
      </Grid>
      <Divider
        variant="middle"
        style={{
          width: "96%",
          marginBottom: 20,
          marginTop: 20,
          color: "#E4E7EB",
        }}
      />
      <Grid item xs={12}>
        <FormGroup style={{ margin: 0, width: "98.5%" }}>
          <FormLabel
            style={{
              fontSize: 14,
              color: "#52606D",
              fontFamily: "Open Sans",
            }}
          >
            No. of questions
          </FormLabel>
          <Box>
            <Slider
              disabled
              size="small"
              aria-label="Always visible"
              value={questions}
              min={0}
              max={20}
              step={1}
              valueLabelDisplay="on"
              style={{ color: "#2C6ECB", width: "100%" }}
              onChange={(event, value) => setQuestions(value)}
            />
          </Box>
        </FormGroup>
      </Grid>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "end",
          padding: "10px 43px 20px",
        }}
      >
        <Button
          onClick={hide}
          color="secondary"
          variant="contained"
          style={{
            color: "#4FA9DC",
            background: "white",
            borderColor: "#4FA9DC",
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 500,
            fontStyle: "normal",
            boxShadow: "none",
            width: 160,
            height: 40,
            padding: "5px 20px",
            margin: 5,
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => (
            mutate({
              name: testClassName,
              due_date:
                moment(testClassDate).format("YYYY-MM-DD") + "T00:00:00.493Z",
              _class: classID[0],
              students: studentID,
              learning_objectives: objectiveID,
              num_questions: questions,
              duration: duration,
            }),
            setTestClassName(""),
            setTestClassDate(""),
            setOpenSnackbarQuiz(true),
            hide()
          )}
          color="primary"
          variant="contained"
          style={{
            background: isValid ? "#1D6F98" : "#a3a3a3",
            borderColor: isValid ? "#1D6F98" : "#a3a3a3",
            color: "white",
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: 500,
            fontStyle: "normal",
            boxShadow: "none",
            width: 160,
            height: 40,
            padding: "5px 20px",
            margin: 5,
          }}
          disabled={!isValid}
        >
          Save
        </Button>
      </Box>
    </Grid>
  );
}

export default CreateActivityForm;
