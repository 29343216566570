import React from "react";
// import { Player } from "@lottiefiles/react-lottie-player";

const H5PComponent = ({ quesNum, isEndOfQuestions, numberOfQuestions }) => {
  console.log("quesNum :>> ", quesNum);
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          display:
            isEndOfQuestions && 1 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q1</h3>
      </div>
      <div
        style={{
          display:
            1 === quesNum || (isEndOfQuestions && 1 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-1`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && <hr style={{ margin: "10px" }} />}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 2 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q2</h3>
      </div>
      <div
        style={{
          display:
            2 === quesNum || (isEndOfQuestions && 2 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-2`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 3 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 3 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q3</h3>
      </div>

      <div
        style={{
          display:
            3 === quesNum || (isEndOfQuestions && 3 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-3`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 4 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 4 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q4</h3>
      </div>

      <div
        style={{
          display:
            4 === quesNum || (isEndOfQuestions && 4 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-4`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 5 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 5 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q5</h3>
      </div>

      <div
        style={{
          display:
            5 === quesNum || (isEndOfQuestions && 5 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-5`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 6 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 6 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q6</h3>
      </div>

      <div
        style={{
          display:
            6 === quesNum || (isEndOfQuestions && 6 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-6`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 7 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 7 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q7</h3>
      </div>

      <div
        style={{
          display:
            7 === quesNum || (isEndOfQuestions && 7 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-7`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 8 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 8 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q8</h3>
      </div>

      <div
        style={{
          display:
            8 === quesNum || (isEndOfQuestions && 8 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-8`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 9 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 9 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q9</h3>
      </div>

      <div
        style={{
          display:
            9 === quesNum || (isEndOfQuestions && 9 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-9`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 10 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 10 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q10</h3>
      </div>

      <div
        style={{
          display:
            10 === quesNum || (isEndOfQuestions && 10 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-10`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 11 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 11 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q11</h3>
      </div>

      <div
        style={{
          display:
            11 === quesNum || (isEndOfQuestions && 11 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-11`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 12 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 12 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q12</h3>
      </div>

      <div
        style={{
          display:
            12 === quesNum || (isEndOfQuestions && 12 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-12`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 13 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 13 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q13</h3>
      </div>

      <div
        style={{
          display:
            13 === quesNum || (isEndOfQuestions && 13 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-13`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 14 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 14 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q14</h3>
      </div>

      <div
        style={{
          display:
            14 === quesNum || (isEndOfQuestions && 14 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-14`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 15 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 15 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q15</h3>
      </div>

      <div
        style={{
          display:
            15 === quesNum || (isEndOfQuestions && 15 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-15`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 16 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 16 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q16</h3>
      </div>

      <div
        style={{
          display:
            16 === quesNum || (isEndOfQuestions && 16 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-16`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 17 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 17 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q17</h3>
      </div>

      <div
        style={{
          display:
            17 === quesNum || (isEndOfQuestions && 17 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-17`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 18 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 18 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q18</h3>
      </div>

      <div
        style={{
          display:
            18 === quesNum || (isEndOfQuestions && 18 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-18`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 19 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 19 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q19</h3>
      </div>

      <div
        style={{
          display:
            19 === quesNum || (isEndOfQuestions && 19 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-19`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 20 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 20 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q20</h3>
      </div>

      <div
        style={{
          display:
            20 === quesNum || (isEndOfQuestions && 20 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-20`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 21 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 21 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q21</h3>
      </div>

      <div
        style={{
          display:
            21 === quesNum || (isEndOfQuestions && 21 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-21`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 22 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 22 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q22</h3>
      </div>

      <div
        style={{
          display:
            22 === quesNum || (isEndOfQuestions && 22 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-22`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 23 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 23 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q23</h3>
      </div>

      <div
        style={{
          display:
            23 === quesNum || (isEndOfQuestions && 23 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-23`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 24 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 24 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q24</h3>
      </div>

      <div
        style={{
          display:
            24 === quesNum || (isEndOfQuestions && 24 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-24`}
      ></div>
      {isEndOfQuestions && quesNum !== 0 && 25 <= numberOfQuestions && (
        <hr style={{ margin: "10px" }} />
      )}
      {/* ================================================================== */}
      <div
        style={{
          display:
            isEndOfQuestions && 25 <= numberOfQuestions ? "block" : "none",
        }}
      >
        <h3>Q25</h3>
      </div>

      <div
        style={{
          display:
            25 === quesNum || (isEndOfQuestions && 25 <= numberOfQuestions)
              ? "block"
              : "none",
        }}
        id={`h5p-25`}
      ></div>
      <div
        style={{
          display: 26 === quesNum ? "block" : "none",
        }}
        id={`h5p-26`}
      ></div>
      <div
        style={{
          display: 27 === quesNum ? "block" : "none",
        }}
        id={`h5p-27`}
      ></div>
      <div
        style={{
          display: 28 === quesNum ? "block" : "none",
        }}
        id={`h5p-28`}
      ></div>
      <div
        style={{
          display: 29 === quesNum ? "block" : "none",
        }}
        id={`h5p-29`}
      ></div>
      <div
        style={{
          display: 30 === quesNum ? "block" : "none",
        }}
        id={`h5p-30`}
      ></div>
      <div
        style={{
          display: 31 === quesNum ? "block" : "none",
        }}
        id={`h5p-31`}
      ></div>
      <div
        style={{
          display: 32 === quesNum ? "block" : "none",
        }}
        id={`h5p-32`}
      ></div>
      <div
        style={{
          display: 33 === quesNum ? "block" : "none",
        }}
        id={`h5p-33`}
      ></div>
      <div
        style={{
          display: 34 === quesNum ? "block" : "none",
        }}
        id={`h5p-34`}
      ></div>
      <div
        style={{
          display: 35 === quesNum ? "block" : "none",
        }}
        id={`h5p-35`}
      ></div>
      <div
        style={{
          display: 36 === quesNum ? "block" : "none",
        }}
        id={`h5p-36`}
      ></div>
      <div
        style={{
          display: 37 === quesNum ? "block" : "none",
        }}
        id={`h5p-37`}
      ></div>
      <div
        style={{
          display: 38 === quesNum ? "block" : "none",
        }}
        id={`h5p-38`}
      ></div>
      <div
        style={{
          display: 39 === quesNum ? "block" : "none",
        }}
        id={`h5p-39`}
      ></div>
      <div
        style={{
          display: 40 === quesNum ? "block" : "none",
        }}
        id={`h5p-40`}
      ></div>
    </div>
  );
};

export default H5PComponent;
