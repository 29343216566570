import React, { useState } from "react";
// import SummarizeIcon from "@mui/icons-material/Summarize";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
// import { Link } from "react-router-dom";
import ActivityCurriculumPlayer from "./ActivityCurriculumPlayer";
import { useQuery } from "react-query";
import useCurriculumApi from "api/curriculum";
import useBuilderApi from "api/builder";
import Modal from "@mui/material/Modal";
const ActivityPlayerHeader = ({ exerciseData, lessonId }) => {
  // console.log("exerciseData?.data.name :>> ", exerciseData?.data.name);
  // console.log("exerciseData.data.stage :>> ", exerciseData.data.stage);
  const { fetchLevelResources, fetchLessonResources } = useCurriculumApi();
  const { fetchBuilderLessons, fetchLevel } = useBuilderApi();
  const [open, setOpen] = React.useState(false);
  const [messageNoLesson, setMessageNoLesson] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const { isLoading, isError, data, error } = useQuery(
  //   "builderLessons",
  //   fetchBuilderLessons,
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );
  const { isLoading, isError, data, error } = useQuery(
    ["resourcesPlayer", lessonId],
    () => {
      return fetchLessonResources(lessonId);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  // console.log("data.data lesson Resources:>> ", data && data?.data);
  // console.log("data lesson Resources:>> ", data && data);
  // console.log("lessonId :>> ", lessonId);
  return (
    <div
      className="header"
      style={{
        background: "white",
        borderRadius: "20px",
        padding: "10px 20px",
        marginBottom: "10px",
        margin: "auto 0 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
      }}
    >
      {exerciseData ? (
        <>
          <h1 style={{ margin: "0", padding: "0" }}>{exerciseData?.data.name}</h1>
          {lessonId && (
            <button
              // to={`/activities/Curriculum/${exerciseData.data.name}/${exerciseData.data.stage}`}
              onClick={handleOpen}
              style={{
                padding: "4px 12px",
                color: "white",
                background: "#6358A5",
                cursor: "pointer",
                border: "none",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "600",
                boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                margin: "0",
              }}
            >
              <LibraryBooksIcon
                style={{
                  margin: "0 10px 0 0",
                  padding: "0",
                  // fontSize: "34px",
                }}
              />{" "}
              <p
                style={{
                  margin: "0",
                  padding: "0",
                  fontWeight: "600",
                }}
              >
                Revise Lesson
              </p>
            </button>
          )}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              margin: " 20px auto 0",
              //   // marginTop: "0",
              //   // height: "30vh",
              width: "95vw",
            }}
          >
            <ActivityCurriculumPlayer
              exerciseName={exerciseData.data?.name}
              stageNumber={exerciseData.data?.stage}
              messageNoLesson={messageNoLesson}
              setMessageNoLesson={setMessageNoLesson}
              lessonId={lessonId}
              lessonPage={false}
              lessonIs={data?.data}
              lessonResourcesData={
                data &&
                data?.data.filter(
                  (item) =>
                    item.type.name.toLowerCase() === "presentation" ||
                    item.type.name.toLowerCase() === "video"
                )
              }
              handleClose={handleClose}
            />
          </Modal>
        </>
      ) : (
        <h1>Activity Question</h1>
      )}
      {/* <Timer /> */}
    </div>
  );
};

export default ActivityPlayerHeader;
