import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { HeaderWrapper } from "./Header.styles";
import ComputerIcon from "@mui/icons-material/Computer";
import { useQuery } from "react-query";
import useStore from "../../../../zustand";
import useUserApi from "api/user";
import ClassSelector from "./ClassSelector";
const StudentPageHeader = ({ title }) => {
  const [dateTimeStatus, setDateTimeStatus] = useState("");
  const [classDropDown, setClassDropDown] = useState(false);
  const { accounts } = useMsal();
  const today = new Date();
  const curHr = today.getHours();

  const { fetchClasses } = useUserApi();
  const { selectedClassId, setSelectedClassId } = useStore();

  const { data, status, isLoading, isError, error } = useQuery("classes", () => fetchClasses(), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (curHr < 12) {
      setDateTimeStatus("Good morning");
    } else if (curHr < 18) {
      setDateTimeStatus("Good afternoon");
    } else {
      setDateTimeStatus("Good evening");
    }
  }, []);
  console.log("accounts :>> ", accounts);
  return (
    <HeaderWrapper>
      <div className="div">
        <div className="left">
          {title === "homepage" ? (
            <>
              <div className="content-header">
                <h1 style={{ margin: "0", fontWeight: "700" }}>{dateTimeStatus}, </h1>
                <h2 style={{ margin: "0", fontWeight: "500" }}>{accounts && accounts[0].name}</h2>
              </div>
              <div className="main-progress">
                <div className="linear-progress">
                  <div className="progress"></div>
                </div>
                <h4>32% of Activities Completed</h4>
              </div>
            </>
          ) : (
            <h1>{title}</h1>
          )}
        </div>

        <div className="selector">
          <div className="subject" onClick={() => setClassDropDown((prev) => !prev)}>
            <ComputerIcon />
            {data && data.data?.filter((item) => item?.id === selectedClassId)[0]?.name}
          </div>
          {classDropDown && (
            <div className="dropdown">
              {data &&
                data.data.map((item) => (
                  <ClassSelector setClassDropDown={setClassDropDown} classes={item} />
                ))}
            </div>
          )}
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default StudentPageHeader;
