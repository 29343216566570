import useDataApi from "api/data";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import CircleLessonPerformance from "./CircleLessonPerformance";
// import LessonCompThinkScore from "./LessonCompThinkScore";
// import { useParams } from "react-router-dom";
// import LessonTabs from "./LessonTabs";

const LessonPerformTopics = ({ classData, lessonData, selectedLessonUUID }) => {
  const { classId } = useParams();

  const { fetchStudentPerformance } = useDataApi();
  const {
    data: studentPerformance,
    status: studentPerformanceStatus,
    isLoading: studentPerformanceLoading,
  } = useQuery(
    ["student_performance", classId, selectedLessonUUID],
    () => fetchStudentPerformance(classId, selectedLessonUUID),
    {
      refetchOnWindowFocus: false,
    }
  );
  // if (studentPerformance?.data) {
  //   console.log(
  //     "studentPerformance?.data==========>",
  //     studentPerformance?.data
  //   );
  // }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        background: "white",
        margin: "0 0 10px 0",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          width: "100%",
          zIndex: "199",

          // width: "calc(100% - 300px)",
        }}
      >
        <CircleLessonPerformance
          data={studentPerformance?.data.worst}
          isLoading={studentPerformanceLoading}
          dataNumStudents={studentPerformance?.data.worst.length}
          type={"worst"}
          studentNeeds={"Need Help"}
          shadows={false}
        />
        <CircleLessonPerformance
          data={studentPerformance?.data.middle}
          isLoading={studentPerformanceLoading}
          dataNumStudents={studentPerformance?.data.middle.length}
          type={"middle"}
          studentNeeds={"On Target"}
          shadows={false}
        />
        <CircleLessonPerformance
          data={studentPerformance?.data.best}
          isLoading={studentPerformanceLoading}
          dataNumStudents={studentPerformance?.data.best.length}
          type={"best"}
          studentNeeds={"Need Challenge"}
          shadows={false}
        />
        <CircleLessonPerformance
          data={studentPerformance?.data.noscore}
          isLoading={studentPerformanceLoading}
          dataNumStudents={studentPerformance?.data.noscore.length}
          type={"noscore"}
          studentNeeds={"Not Started"}
          shadows={false}
        />
        {/* <LessonCompThinkScore
          classData={classData}
          selectedLessonUUID={selectedLessonUUID}
        /> */}
      </div>
    </div>
  );
};

export default LessonPerformTopics;
