import React from "react";

const H5PQuestionItem = ({
  index,
  item,
  setSelectedQuestionName,
  setSelectedQuestionNumber,
  setSelectedQuestionFolderName,
  setSelectedQuestionGUID,
  selectedQuestionGUID,
  setSelectedQuestionFileName,
  isStart,
  allLearnObjGUIDs,
  learningData,
}) => {
  const [numberOfLearn, setNumberOfLearn] = React.useState(0);
  console.log("item.learning_objectives :>> ", item.learning_objectives);
  console.log(
    "allLearnObjGUIDs",
    allLearnObjGUIDs.includes(item.learning_objectives[0])
  );
  const checkNumberOfLearningObjectives = () => {
    // for (let i = 0; i < learningData.length; i++) {
    //   console.log("item.learning_objectives :>> ", item.learning_objectives);
    //   console.log("learningData[i]", learningData[i]);
    //   // if (item.learning_objectives.includes(learningData[i].id)) {
    //   //   setNumberOfLearn((prev) => prev + 1);
    //   // }
    // }
    return numberOfLearn;
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        background: item.id === selectedQuestionGUID ? "#6358A5" : "",
        color: item.id === selectedQuestionGUID ? "white" : "",
        padding: "2px 8px",
        borderRadius: item.id === selectedQuestionGUID ? "6px" : "",
      }}
      onClick={() => {
        if (isStart) {
          setSelectedQuestionName(item.name);
          setSelectedQuestionNumber(index);
          setSelectedQuestionGUID(item.id);
          setSelectedQuestionFolderName(item.foldername);
          setSelectedQuestionFileName(item.filename);
        }
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <p
          style={{
            margin: "0",
            padding: "0",
          }}
        >
          {index}
        </p>
        <h4
          style={{
            margin: "0 0 0 9px",
            color: item.id === selectedQuestionGUID ? "white" : "",
            padding: "0",
          }}
        >
          {item.foldername.slice(0, 18)}
        </h4>
      </div>
      <h5
        style={{
          margin: "0 0 0 9px",
          color: item.id === selectedQuestionGUID ? "white" : "",

          padding: "0",
          fontSize: "14px",
          width: "20%",
        }}
      >
        {item.learning_objectives.length} learn.obj.
      </h5>
      {/* <h5
        style={{
          margin: "0 0 0 9px",
          color: item.id === selectedQuestionGUID ? "white" : "",

          padding: "0",
          fontSize: "14px",
          width: "20%",
        }}
      >
        {/* {learningData.map((learnItem) =>
          item.learning_objectives.includes(learnItem.id)
            ? setNumberOfLearn((prev) => prev + 1)
            : null
        )} */}
      {/* {checkNumberOfLearningObjectives()} */}
      {/* {allLearnObjGUIDs.includes(item.learning_objectives[0])
          ? "yes"
          : "nope"} */}
      {/* </h5> */}
    </div>
  );
};

export default H5PQuestionItem;
