import React, { useState } from "react";
import useStore from "../../../zustand";
import ActivityFebLinearProgress from "./ActivityFebLinearProgress";
import ActivityFebTestingPlayer from "./ActivityFebTestingPlayer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
// const testArr = [
//   {
//     id: "1",
//     filename: "",
//     name: "Byte",
//     foldername: "Byte",
//   },
//   {
//     id: "2",
//     filename: "",
//     name: "Measure",
//     foldername: "Measure",
//   },
//   {
//     id: "3",
//     filename: "",
//     name: "Question",
//     foldername: "Question",
//   },
//   {
//     id: "4",
//     filename: "",
//     name: "Cursor",
//     foldername: "Cursor",
//   },
//   {
//     id: "2",
//     filename: "",
//     name: "Measure",
//     foldername: "Measure",
//   },
//   {
//     id: "3",
//     filename: "",
//     name: "Question",
//     foldername: "Question",
//   },
//   {
//     id: "4",
//     filename: "",
//     name: "Cursor",
//     foldername: "Cursor",
//   },
//   {
//     id: "2",
//     filename: "",
//     name: "Measure",
//     foldername: "Measure",
//   },
//   {
//     id: "3",
//     filename: "",
//     name: "Question",
//     foldername: "Question",
//   },
//   {
//     id: "4",
//     filename: "",
//     name: "Cursor",
//     foldername: "Cursor",
//   },
//   {
//     id: "2",
//     filename: "",
//     name: "Measure",
//     foldername: "Measure",
//   },
//   {
//     id: "3",
//     filename: "",
//     name: "Question",
//     foldername: "Question",
//   },
//   {
//     id: "4",
//     filename: "",
//     name: "Cursor",
//     foldername: "Cursor",
//   },
//   {
//     id: "4",
//     filename: "",
//     name: "Cursor",
//     foldername: "Cursor",
//   },
// ];
const ActivityFebTestingPage = () => {
  const { activityBatchQuestions } = useStore();
  // console.log("ActivityFebTestingPage ===>");
  //   console.log("ActivityFebTestingPage ===>");
  const [quesNum, setQuesNum] = useState(0);
  const [isEndOfQuestions, setIsEndOfQuestions] = useState(false);
  const [isStarted, setIsStarted] = useState(false);

  const [numberOfQuestions, setNumberOfQuestions] = useState(
    activityBatchQuestions.length
  );
  const history = useHistory();

  // React.useEffect(() => {
  //   setActivityBatchQuestions(testArr);
  // }, []);

  // console.log("activityBatchQuestions", activityBatchQuestions);
  if (activityBatchQuestions) {
    return (
      <div
        style={{
          // background: "white",
          padding: "40px",
          maxWidth: "1280px",
          width: "95%",
          margin: "auto ",
          transition: "all 0.5s ease-in-out",
        }}
      >
        <button
          style={{
            padding: "8px 16px",
            // color: "white",
            // background: "rgba(99, 88, 165,0.8)",
            background: "rgba(99, 88, 165,0)",
            opacity: "1",
            cursor: "pointer",
            border: "none",
            // width: "120px",
            borderRadius: "100vw",
            borderTopRightRadius: "0",
            borderBottomRightRadius: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "600",
            // boxShadow:
            //   " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            margin: " 0 0 10px  0",
          }}
          onClick={() => history.goBack()}
        >
          <ArrowBackIcon />
          <h3 style={{ margin: "0" }}> Exit Activity Player</h3>
        </button>
        <div
          style={{
            background: "white",
            borderRadius: "20px",
            padding: "15px 20px 10px",
            marginBottom: "10px",
            margin: "auto 0 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            boxShadow:
              " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          }}
        ></div>
        <div
          className=""
          style={{
            padding: "20px",
            borderRadius: "20px",
            background: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxShadow:
              " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          }}
        >
          {false && (
            <ActivityFebLinearProgress
              numberOfQuestions={numberOfQuestions}
              quesNum={quesNum}
              setQuesNum={setQuesNum}
              isEndOfQuestions={isEndOfQuestions}
            />
          )}
          {/* {!isEndOfQuestions &&
          activityBatchQuestions.map((item, index) => (
            <div
              style={{
                display: "flex",
                // flexDirection: "column",
                alignItems: "center",
                // justifyContent: "center",
                padding: "8px",
                margin: "5px 0",
                width: "100%",
                boxShadow:
                  " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              }}
            >
              <h3 style={{ margin: "0 10px 0 0" }}>Question {index + 1},</h3>
              <div>
                [<strong>foldername:</strong>
                {item.foldername}]
              </div>
            </div>
          ))} */}
          <ActivityFebTestingPlayer
            questionsArr={activityBatchQuestions}
            quesNum={quesNum}
            setQuesNum={setQuesNum}
            numberOfQuestions={numberOfQuestions}
            setIsEndOfQuestions={setIsEndOfQuestions}
            isEndOfQuestions={isEndOfQuestions}
            setIsStarted={setIsStarted}
            isStarted={isStarted}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          // background: "white",
          padding: "40px",
          maxWidth: "1280px",
          width: "95%",
          margin: "auto ",
          transition: "all 0.5s ease-in-out",
        }}
      >
        <div
          style={{
            background: "white",
            borderRadius: "20px",
            padding: "15px 20px 10px",
            marginBottom: "10px",
            margin: "auto 0 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            boxShadow:
              " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            height: "40vh",
          }}
        >
          <h1>No Questions Selected</h1>
        </div>
      </div>
    );
  }
};

export default ActivityFebTestingPage;
