import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const CardListSkeleton = () => {
  return (
      <div
        style={{
            background: "white",
            borderRadius: "8px",
            width: "100%",
            padding: "5px 20px",
            margin: "14px 0",
            boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
      >
        {/* Class Name - Link  */}
        <div
          style={{
            width: "20%",
            // margin: "0",
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "start",
            // padding: "2px 0 4px",
          }}
        >
          <Skeleton count={1} width={120} height={30} 
          style={{ marginLeft: "0", marginBottom: "0"}}
          />
        </div>

        {/* // Class performance list  */}
        <div
          style={{
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "space-between",
            display: "flex",
            gap: "8px",
            margin: "0px 0",
          }}
        >
          <div
            style={{
              padding: "8px",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Skeleton count={1} width={40} height={40} circle={true} />
            {/* {classPerformance.worst} */}
          </div>

          <div
            style={{
              padding: "8px",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Skeleton count={1} width={40} height={40} circle={true} />
            {/* {classPerformance.middle} */}
          </div>

          <div
            style={{
              padding: "8px",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Skeleton count={1} width={40} height={40} circle={true} />
            {/* {classPerformance.best} */}
          </div>
        </div>

        {/* EXTRA DIV HERE FOR SOME REASON - Ask Ashraf */}
        <div></div>   

        {/* Teacher and student Icons and numbers */}
        <div
          style={{
            width: "25%",
            display: "flex",
          }}
        >
          {/* <div style={{ display: "flex", alignItems: "center" }}>
            <SignalCellularAltRoundedIcon style={{ color: "#1D6F98" }} />
            <ClassLevel dataLevel={classItem?.level} />
          </div> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <Skeleton count={1} width={120} height={30} />
          </div>
          <div style={{ display: "flex", alignItems: "center", marginLeft: "14px", }}>
            <Skeleton count={1} width={120} height={30} />
          </div>
        </div>

        {/* Delete Icon */}
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "20px" }}
        >
          <Skeleton count={1} width={24} height={24} />
        </div>
      </div>
  );
};

export default CardListSkeleton;
