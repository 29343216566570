import React from "react";
import useStore from "../../../../zustand";
import { useQuery } from "react-query";
import useUserApi from "api/user";
import CircularProgress from "@mui/material/CircularProgress";

const ClassSelector = ({ classes, setClassDropDown }) => {
  const { selectedClassId, setSelectedClassId } = useStore();
  const { fetchActivityTask } = useUserApi();

  const { isLoading, isError, data, error } = useQuery(
    ["todoTasks", classes.id],
    () => fetchActivityTask(classes.id),
    { enabled: classes.id != null, refetchOnWindowFocus: false }
  );
  return (
    <div
      onClick={() => {
        setSelectedClassId(classes.id);
        setClassDropDown((prev) => !prev);
      }}
      className={classes.id === selectedClassId ? "selected-class class-item" : "class-item"}
    >
      {data && data.data.todo.count > 0 ? (
        <div className="task-notification-class">{data && data.data.todo.count}</div>
      ) : (
        <div style={{ width: "32px" }} className=""></div>
      )}
      {isLoading ? (
        <div className="task-notification-class">
          <CircularProgress style={{ width: "60%", height: "60%", color: "white" }} />
        </div>
      ) : null}
      {classes.name}
    </div>
  );
};

export default ClassSelector;
