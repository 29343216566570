import React, { useEffect } from "react";
import ActivityPlayerTestCompHeader from "./ActivityPlayerTestCompHeader";
import { H5P as H5PStandalone } from "h5p-standalone";

const ActivityPlayerTestingComp = ({
  selectedQuestionName,
  selectedQuestionNumber,
  selectedQuestionFolderName,
  selectedQuestionGUID,
  selectedQuestionFileName,
  isStart,
}) => {
  useEffect(() => {
    if (selectedQuestionFolderName.length > 0 && isStart) {
      const options = {
        h5pJsonPath: `/h5pextracted/${selectedQuestionFolderName}`,
        // h5pJsonPath: folderPath,
        frameJs: "/assets/frame.bundle.js",
        frameCss: "/assets/styles/h5p.css",
        customCss: "/assets/styles/multichoice.css",
      };

      // try {
      new H5PStandalone(
        document.getElementById("h5p-container-main"),
        options
      ).then(function () {
        window.H5P.externalDispatcher.on("xAPI", (event) => {
          console.log("event", event);
        });
      });
    }
  }, [selectedQuestionFolderName]);
  return (
    <div>
      <ActivityPlayerTestCompHeader
        selectedQuestionName={selectedQuestionName}
        selectedQuestionNumber={selectedQuestionNumber}
        selectedQuestionFolderName={selectedQuestionFolderName}
        selectedQuestionGUID={selectedQuestionGUID}
        selectedQuestionFileName={selectedQuestionFileName}
      />
      <div
        id="h5p-container-main"
        style={{ width: "100%", padding: "10px" }}
      ></div>
    </div>
  );
};

export default ActivityPlayerTestingComp;
