import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

// @material-ui/icons components

import componentStyles from "assets/theme/layouts/admin.js";

import AuthHeader from "components/Headers/AuthHeader";
import Iframe from "react-iframe";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import useUserApi from "api/user";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles(componentStyles);

const TermsConditionsPage = () => {
  const { setAcceptedTerms } = useUserApi();

  function accept() {
    const redirectUrl = new URLSearchParams(window.location.search).get(
      "redirectUrl"
    );
    setAcceptedTerms();

    window.location.replace(redirectUrl);
  }

  return (
    <>
      <AuthHeader title="Please accept our terms and conditions" />
      {/* Page content */}
      <Container
        component={Box}
        maxWidth="xl"
        marginTop="-8rem"
        paddingBottom="3rem"
        position="relative"
        zIndex="101"
      >
        <Stack spacing={2}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ height: "80px" }}
            >
              <Typography variant="h6">Terms of Service</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Iframe
                frameBorder={0}
                url="https://educationai.co.uk/tandcs/"
                width="100%"
                height="500vh"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ height: "80px" }}
            >
              <Typography variant="h6">Privacy Policy</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Iframe
                frameBorder={0}
                url="https://educationai.co.uk/privacy-2/"
                width="100%"
                height="500vh"
              />
            </AccordionDetails>
          </Accordion>

          <Typography variant="h6" color="white">
            By clicking the button below, you agree to these terms, on behalf of
            your students
          </Typography>
          <Button color="primary" variant="contained" onClick={() => accept()}>
            I accept
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default TermsConditionsPage;
