import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const ClassCardSkeleton = () => {
  const arr1 = new Array(3).fill(0);
  const arr2 = new Array(6).fill(0);
  return (
    <div
      style={{
        background: "white",
        borderRadius: "8px",
        width: "330px",
        padding: "20px ",
        //   marginBottom: "30px",
        //   boxShadow: " 0 4px 6px hsla(0,0%, 0%, .2)",
        boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
      }}
    >
      {/* <Skeleton count={1} /> */}
      <div
        style={{
          marginBottom: "28px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Skeleton count={1} width={160} />
        <Skeleton count={1} width={40} />
      </div>
      <div style={{ width: "100%" }}>
        <Skeleton count={3} />
      </div>
      <div
        style={{
          // background: "#fff",
          // borderRadius: "12px",
          // boxShadow: " 0 5px 15px hsla(0, 0%, 0%, 0.2)",
          margin: "20px 0",
          display: "grid",
          gap: "30px",
          gridTemplateColumns: "1fr 1fr 1fr",
        }}
      >
        {arr1.map((item) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              alignItems: "center",
              padding: "25px 20px 10px",
              boxShadow: " 0 10px 15px hsla(0, 0%, 0%, 0.096)",
              borderRadius: "12px",
              background: "#fff",
              marginTop: "10px",
            }}
          >
            <div
              style={
                {
                  // width: "80%",
                }
              }
            >
              <Skeleton circle width={38} height={38} />
              <div style={{ marginTop: "10px" }}>
                <Skeleton count={1} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div style={{ width: "35%", marginTop: "10px" }}>
        <Skeleton count={1} />
      </div>
    </div>
  );
};

export default ClassCardSkeleton;
