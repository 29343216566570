import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Badge } from "@material-ui/core";
import EqualizerIcon from "@mui/icons-material/Equalizer";
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import Page3 from "../../components/StudentProgress/Page3/StudentProgress_Page3";
import componentStylesBadge from "assets/theme/components/badge.js";
// import { getDateTime } from "utils/utils";
import { getPercent } from "utils/utils";
import { useQuery } from "react-query";
import useDataApi from "api/data";
import EmptyState from "components/EmptyState/EmptyState";

const tableHead = [
  { name: "Exercises", sort: "exercise" },
  { name: "Class", sort: "_class" },
  { name: "Progress", sort: "status" },
  // { name: "Start Time", sort: 'start_time' },
  { name: "Average Score", sort: "score" },
];
const useStylesBadge = makeStyles(componentStylesBadge);
const useStyles = makeStyles(componentStyles);

export default function StudentProgress({ params, student }) {
  const classes = { ...useStyles(), ...useStylesBadge() };
  const tableConatinerRef = React.useRef(null);
  const { fetchStudentExerciseResults } = useDataApi();
  const [sortBy, setSortBy] = React.useState("exercise");
  const [order, setOrder] = React.useState(true);

  const { data, status, loading } = useQuery(
    ["student_exercise_results", params.studentId],
    () => fetchStudentExerciseResults(params.studentId),
    {
      refetchOnWindowFocus: false,
    }
  );

  function sortFunction(a, b) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }

  if (data && data.data.length === 0) {
    return (
      <EmptyState
        className="studentProgress"
        width={12}
        widthSM={12}
        avatarIcon={<EqualizerIcon style={{ width: 40, height: 40 }} />}
        header="Student Progress"
        title="Track student progress"
        text="You will be able to view the student progress over time here"
        marginTop="30px 0px 5px 20px"
        // buttonText="+ Create a curriculum"
      />
    );
  }

  return (
    <div
      style={{
        margin: "20px",
        boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
        background: "white",
        borderRadius: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#05649D",
          color: "white",
          //   borderRadius: "199px",
          overflow: "hidden",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          padding: "10px",
          //   margin: "10px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "70%",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5
            style={{
              margin: "0 0 0 12px",
              color: "white",
              fontSize: "16px",
            }}
          >
            Student Progress
          </h5>
        </div>
      </div>

      <TableContainer ref={tableConatinerRef}>
        <Box component={Table} alignItems="center" marginBottom="0!important">
          <TableHead>
            <TableRow>
              {tableHead.map((prop, key) => (
                <TableCell
                  key={key}
                  align={prop.sort === "exercise" ? "start" : "center"}
                  onClick={() => (setSortBy(prop.sort), setOrder(!order))}
                  {...{
                    "data-sort":
                      prop.name !== "" && prop.name !== "Users"
                        ? (prop.name === "Project"
                            ? "name"
                            : prop.name
                          ).toLowerCase()
                        : undefined,
                  }}
                  scope="col"
                  classes={{
                    root:
                      classes.tableCellRoot +
                      " sort " +
                      classes.tableCellRootHead,
                  }}
                >
                  {prop.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="list">
            {status === "success" && student !== undefined
              ? data?.data
                  .sort((a, b) => {
                    if (sortBy === "exercise" || sortBy === "_class") {
                      return order
                        ? sortFunction(a[sortBy]["name"], b[sortBy]["name"])
                        : -sortFunction(a[sortBy]["name"], b[sortBy]["name"]);
                    } else {
                      return order
                        ? sortFunction(a[sortBy], b[sortBy])
                        : -sortFunction(a[sortBy], b[sortBy]);
                    }
                  })
                  .map((exercise, key) => (
                    <TableRowStudents
                      key={key}
                      exercise={exercise}
                      student={student.data}
                      classes={classes}
                    />
                  ))
              : null}
          </TableBody>
        </Box>
      </TableContainer>
    </div>
  );
}

export function TableRowStudents({ key, exercise, student, classes }) {
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(true);
  }

  return (
    <>
      <TableRow
        hover
        key={key}
        style={{
          cursor: "pointer",
        }}
      >
        <TableCell
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootBodyHead,
          }}
          component="th"
          variant="head"
          scope="row"
          onClick={handleClick}
        >
          <h4
            style={{
              margin: "0 12px 0 0px",
              fontWeight: "500",
              fontSize: "14px",
              width: "80%",
            }}
          >
            {exercise.exercise.name}
          </h4>
        </TableCell>

        <TableCell
          classes={{ root: classes.tableCellRoot }}
          align="center"
          onClick={handleClick}
        >
          <Box paddingTop=".35rem" paddingBottom=".35rem">
            {exercise._class.name}
          </Box>
        </TableCell>

        <TableCell
          classes={{ root: classes.tableCellRoot + " objective" }}
          align="center"
          onClick={handleClick}
        >
          <Badge
            badgeContent={exercise.status}
            variant="contained"
            color="primary"
            classes={{
              root: classes.badgeMargin,
              badge: clsx(classes.badgePositionRelative, classes.badgeRound, {
                [classes["badge" + "Success"]]: exercise.color !== "Error",
              }),
            }}
          ></Badge>
        </TableCell>

        {/* <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    align='center'
                    onClick={handleClick}
                >
                    <Box paddingTop='.35rem' paddingBottom='.35rem'>
                        {getDateTime(exercise.start_time)}
                    </Box>
                </TableCell> */}

        <TableCell
          classes={{ root: classes.tableCellRoot }}
          align="center"
          onClick={handleClick}
        >
          <Box paddingTop=".35rem" paddingBottom=".35rem">
            {getPercent(exercise.score)}
          </Box>
        </TableCell>
        <Page3
          open={open}
          setOpen={setOpen}
          student={student}
          exerciseId={exercise.exercise.id}
        />
      </TableRow>
    </>
  );
}
