import React from "react";

const CustomLessonLevelItem = ({
  levelPlan,
  editedClassLevel,
  setEditedClassLevel,
  setSelectedCurriculum,
  setCustomValidationSelect,
}) => {
  // console.log("levelPlan :>> ", levelPlan);
  return (
    <div
      style={{
        display: "flex",
        border: "1px solid rgba(0,0,0,0.2)",
        boxShadow: "0 1px 2px rgba(0,0,0,0.2)",
        borderRadius: "4px",
        padding: "4px 10px",
        cursor: "pointer",
        alignItems: "center",
        background: levelPlan.id == editedClassLevel ? "rgb(6, 101, 157)" : "",
        color: levelPlan.id == editedClassLevel ? "white" : "",
        fontWeight: "500",
      }}
      onClick={() => {
        setEditedClassLevel(levelPlan.id);
        setSelectedCurriculum(levelPlan.name);

        // setCloseSelection(false);
        if (levelPlan.id == null) {
          setCustomValidationSelect("required");
        } else {
          setCustomValidationSelect("valid");
        }
      }}
    >
      {levelPlan.name}
    </div>
  );
};

export default CustomLessonLevelItem;
