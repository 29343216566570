import React from "react";
// import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
// import { useQuery } from "react-query";
import useCurriculumApi from "api/curriculum";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import AirplayIcon from "@mui/icons-material/Airplay";
// import AssignmentIcon from "@mui/icons-material/Assignment";
// import DescriptionIcon from "@mui/icons-material/Description";
// import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
// import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
// import AutoStoriesIcon from "@mui/icons-material/LibraryBooks";
// import AutoStoriesIcon from "@mui/icons-material/AutoStories";
// import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
// import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import LessonTeachWhite from "../assets/LessonsWhite.svg";
import { useQuery } from "react-query";
import { useQueryClient } from "react-query";
// import ConfirmModal from "components/Modals/ConfirmModal";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    // border: "3px solid rgba(0,0,0,0.8)",
    boxShadow: theme.shadows[3],
    fontSize: 16,
    textAlign: "center",
  },
}));
const LessonPerformanceHeader = ({
  cLesson,
  curriculumPlayerLessonId,
  setLessonResourcesData,
  setCurriculumPlayerLessonId,
  lessonResourcesData,
  setCurriculumPlayerLessonIs,
  setHeight,
  height,
  assignActivityBtn,
  viewCurriculum,
  lessonId,
  classId,
}) => {
  // console.log("cLesson", cLesson);
  // console.log("cLesson", cLesson);
  // console.log("cLesson", cLesson);
  const [isComplete, setIsComplete] = React.useState(null);
  //Confirmation
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmAction, setConfirmAction] = React.useState(null);
  const [confirmActionDescription, setConfirmActionDescription] = React.useState(null);
  const [openSubmit, setOpenSubmit] = React.useState(false);

  const queryClient = useQueryClient();
  const {
    fetchLevelResources,
    fetchLessonResources,
    fetchMarkLessonComplete,
    fetchCheckLessonComplete,
  } = useCurriculumApi();
  const handleOpenSubmit = () => setOpenSubmit(true);
  const handleCloseSubmit = () => {
    setOpenSubmit(false);
    // setShowResults(false);
  };
  //
  const { isLoading: isCompletedLoading, data: isCompletedData } = useQuery(
    ["fetchCheckLessonComplete", lessonId, classId],
    () => {
      return fetchCheckLessonComplete(lessonId, classId);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const {
    isLoading: markCompletedIsLoading,
    data: markCompletedData,
    refetch: markCompleted,
  } = useQuery(
    ["fetchMarkLessonComplete", lessonId, classId],
    () => {
      return fetchMarkLessonComplete(lessonId, classId, isComplete);
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries(["fetchCheckLessonComplete", lessonId, classId]);
      },
    }
  );
  // console.log(
  //   "isCompletedData&&isCompletedData.data :>> ",
  //   isCompletedData && isCompletedData.data.lesson_complete
  // );
  React.useEffect(() => {
    if (isCompletedData) {
      setIsComplete(!isCompletedData.data.lesson_complete);
    }
  }, [isCompletedData]);
  const handleButtonClick = () => {
    markCompleted();
  };
  // function toSubmitAnswer() {
  //   setConfirmAction(
  //     () =>
  //       function () {
  //         handleOpenSubmit();
  //         handleButtonClick();
  //       }
  //   );
  //   setConfirmActionDescription("mark the lesson as complete");
  //   setConfirmOpen(true);
  // }
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px ",
        }}
      >
        <h2
          style={{
            fontSize: "26px",
            margin: "0",
            padding: "0",
          }}
        >
          {cLesson[0]?.name}
        </h2>
        {/* <ConfirmModal
          action={confirmAction}
          actionDescription={confirmActionDescription}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          undone={false}
        /> */}
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // padding: "5px ",
              gap: "6px",
              // border: "2px solid #05649D",
              boxShadow: " 0 1px 3px hsla(0,0%, 0%, .2)",
              padding: "6px 12px",
              borderRadius: "4px",
              background: "#05649D",
              cursor: "pointer",
            }}
          >
            <AutoStoriesIcon
              style={{
                background: "#05649D",
                color: "white",
                borderRadius: "3px",
                fontSize: "30px",
                cursor: "pointer",
                padding: "3px",
                margin: "0",
              }}
            />
            <p
              style={{
                padding: "0px ",
                color: "white",
                margin: "0",
                fontWeight: "500",
              }}
            >
              Mark as complete
            </p>
          </div> */}
          {/* <div
            style={{
              display: "flex",
              //   justifyContent: "space-between",
              alignItems: "center",
              // padding: "5px ",
              gap: "6px",
              // border: "2px solid #05649D",
              boxShadow: " 0 1px 3px hsla(0,0%, 0%, .2)",
              padding: "6px 12px",
              borderRadius: "4px",
              cursor: "pointer",
              background: "#05649D",
            }}
          >
            <AssignmentTurnedInIcon
              style={{
                background: "#05649D",
                color: "white",
                borderRadius: "3px",
                fontSize: "30px",
                cursor: "grab",
                padding: "3px",
              }}
            />
            <h4
              style={{
                padding: "0px ",
                color: "white",
                margin: "0",
                fontWeight: "500",
              }}
            >
              H/W Assigned
            </h4>
          </div> */}
          {/* assignActivityBtn */}
          {/* {viewCurriculum} */}
          {assignActivityBtn}
          {true && (
            <LightTooltip
              style={{ margin: "0 8" }}
              title={
                <h4
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  {" "}
                  Click to mark
                  <strong> {cLesson[0]?.name}</strong> Lesson
                  {isCompletedData?.data.lesson_complete ? " as incomplete" : " completed"}.
                </h4>
              }
              arrow
              placement="top"
            >
              <div
                // onClick={() => setHeight("60vh")}
                onClick={(e) => {
                  handleButtonClick();
                }}
                style={{
                  background: "#06659D",
                  color: "white",
                  border: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "8px 20px",
                  fontSize: "14px",
                  borderRadius: "6px",
                  fontWeight: "500",
                  boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                  // marginRight: 10,
                  minWidth: "145px",
                  gap: "8px",
                }}
              >
                {/* <PlayArrowIcon style={{ width: "20px", height: "20px", fontSize: "2rem" }} /> */}
                <img src={LessonTeachWhite} alt="" srcset="" style={{ width: "24px" }} />
                {markCompletedIsLoading || isCompletedLoading ? (
                  <CircularProgress
                    style={{
                      color: "white",
                      width: "24px",
                      height: "24px",
                      padding: "0",
                      margin: "0 0 0 10px",
                    }}
                  />
                ) : (
                  "Complete"
                )}
                {/* markCompletedIsLoading */}
              </div>
            </LightTooltip>
          )}
          {height === 0 ? (
            <LightTooltip
              title={
                <h4
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Click to play teaching resources for <strong>{cLesson[0]?.name}</strong>.
                </h4>
              }
              arrow
              placement="top"
            >
              <div
                onClick={() => setHeight("60vh")}
                style={{
                  background: "#06659D",
                  color: "white",
                  border: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "8px 20px",
                  fontSize: "14px",
                  borderRadius: "6px",
                  fontWeight: "500",
                  boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                  // marginRight: 10,
                  minWidth: "110px",
                  gap: "8px",
                }}
              >
                <PlayArrowIcon style={{ width: "20px", height: "20px", fontSize: "2rem" }} />
                Teach
              </div>
            </LightTooltip>
          ) : (
            <LightTooltip
              title={
                <h4
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Click to close teaching resources for <strong>{cLesson[0]?.name}</strong>.
                </h4>
              }
              arrow
              placement="top"
            >
              <div
                onClick={() => {
                  setHeight(0);
                }}
                style={{
                  background: "#06659D",
                  color: "white",
                  border: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "8px 20px",
                  fontSize: "14px",
                  borderRadius: "6px",
                  fontWeight: "500",
                  boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                  // marginRight: 10,
                  minWidth: "110px",
                  gap: "8px",
                }}
              >
                <CloseIcon style={{ width: "20px", height: "20px", fontSize: "2rem" }} />
                Close
              </div>
            </LightTooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default LessonPerformanceHeader;
