import React from "react";
// plugin for drag-and-drop files
import Dropzone from "dropzone";
import useStore from '../../../../../../zustand'

const DropzoneComponent = ({ list, setList }) => {
  const tenantId = useStore((state) => state.tenantId);

  function handleDropFile(response) {
    let arr = [];
    response.map((item) => {
      if (item.email?.trim().length > 0 || item.first_name?.trim().length > 0 || item.last_name?.trim().length > 0) {

        const person = {
          valid: false,
          class: null,
          first_name: item.first_name,
          last_name: item.last_name,
          email: item.email,
          school: tenantId,
        }

        return arr.push(person);
      }
    });
    setList(arr);
  }

  // single file drag-and-drop dropzone init
  React.useEffect(() => {
    if (
      !document
        .getElementById("dropzone-single")
        .classList.contains("dz-clickable")
    ) {
      Dropzone.autoDiscover = false;
      // this variable is to delete the previous image from the dropzone state
      // it is just to make the HTML DOM a bit better, and keep it light
      let currentSingleFile = undefined;
      // single dropzone file - accepts only images
      let singleDropzone = new Dropzone(
        document.getElementById("dropzone-single"),
        {
          url: "/",
          thumbnailWidth: null,
          thumbnailHeight: null,
          previewsContainer:
            document.getElementsByClassName("dz-preview-single")[0],
          previewTemplate:
            document.getElementsByClassName("dz-preview-single")[0].innerHTML,
          maxFiles: 1,
          acceptedFiles: "text/csv",
          init: function () {
            this.on("addedfile", function (file) {
              if (currentSingleFile) {
                this.removeFile(currentSingleFile);
              }
              currentSingleFile = file;
            });
          },
        }
      );

      singleDropzone.on("addedfile", function (file) {
        var reader = new FileReader();
        reader.onload = function (event) {
          // event.target.result contains base64 encoded image
          let response = csvToArray(event.target.result);
          handleDropFile(response);
        };
        reader.readAsText(file);
      });

      document.getElementsByClassName("dz-preview-single")[0].innerHTML = "";
      return function cleanup() {
        singleDropzone.destroy();
      };
    }
  }, []);

  return (
    <div style={{ width: "95%", margin: "auto", marginTop: 20 }}>
      <div className="dropzone mb-3" id="dropzone-single">
        <div className="fallback">
          <div className="custom-file">
            <input
              className="custom-file-input"
              id="projectCoverUploads"
              type="file"
            />
            <label className="custom-file-label" htmlFor="projectCoverUploads">
              Choose file
            </label>
          </div>
        </div>
        <div className="dz-preview dz-preview-single">
          <div className="dz-preview-cover">
            <img alt="..." className="dz-preview-img" data-dz-thumbnail="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropzoneComponent;

function csvToArray(str, delimiter = ",") {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  let headers = str.slice(0, str.indexOf("\n")).split(delimiter);
  headers = headers.map((h) => h.replaceAll('"', "").trim());

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = rows.map(function (row) {
    const values = row.split(delimiter);
    const el = headers.reduce(function (object, header, index) {
      object[header] = values[index]?.trim();
      return object;
    }, {});
    return el;
  });

  // return the array
  return arr;
}
