import React from "react";
import ClassCard from "./ClassCard";

const ClassesColumnComponent = ({ data, searchWord }) => {
  const filterList = (item, array) => {
    return searchWord.length > 0
      ? item.name &&
          item.name.toLowerCase().indexOf(searchWord.toLowerCase()) > -1
      : array;
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "20px",
          margin: "20px 0",
        }}
      >
        {data &&
          data
            .sort(
              (a, b) =>
                a.name !== null &&
                b.name !== null &&
                a.name.localeCompare(b.name)
            )
            .filter((item, key, array) => filterList(item, array))
            .map((classItem) => (
              <>
                <ClassCard
                  key={classItem.id}
                  classItem={classItem}
                  gridView={true}
                />
              </>
            ))}
      </div>
    </div>
  );
};

export default ClassesColumnComponent;
