import React, { useEffect, useState } from "react";
import useUserApi from "api/user";
import { Box, Divider, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";

import { useQuery } from "react-query";
// import { LoadingScreen } from "../curriculum/Components/LoadingScreen";
import { ErrorScreen } from "../curriculum/Components/ErrorScreen";
import ActivityStageCard from "./components/ActivityStageCard";
import ToDoListActPage from "./components/ToDoListActPage";
// import AwardsActPage from "./components/AwardsActPage";
// import YourAwardsAct from "./components/YourAwardsAct";
import useMediaQuery from "@mui/material/useMediaQuery";
import useStore from "../../../zustand";
import comp from "./assets/comp.png";
import RecentActItem from "./components/RecentActItem";

import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import ActivityRecentSkeleton from "./components/ActivityRecentSkeleton";
// import EmptyState from "components/EmptyState/EmptyState";
import EmptyStateTwo from "./components/EmptyStateTwo";
const ActivitiesLearnPage = () => {
  const matches = useMediaQuery("(min-width:1240px)");
  const matchesTwo = useMediaQuery("(min-width:600px)");
  const matchesThree = useMediaQuery("(min-width:1000px)");
  const [displayGrid, setDisplayGrid] = useState(true);
  const { selectedClassId } = useStore();

  const { fetchRecentActivities } = useUserApi();

  const { isLoading, isError, data, error } = useQuery(
    ["recentActivities", selectedClassId],
    () => fetchRecentActivities(selectedClassId),
    { enabled: selectedClassId != null }
  );
  // console.log("selectedClassId: ", selectedClassId);git st
  useEffect(() => {
    const handleDisplay = () => {
      setDisplayGrid((prev) => !prev);
    };
  }, [displayGrid]);
  const matchesO = useMediaQuery("(max-width:1200px)");
  const matchesN = useMediaQuery("(max-width:700px)");

  function columnTemp() {
    if (!matchesO && !matchesN) {
      return "1fr 1fr 1fr";
    } else if (matchesO && !matchesN) {
      return "1fr 1fr";
    } else if (matchesN) {
      return "1fr";
    }
  }
  if (data) {
    // console.log(" Recent data from Activitypage ==> ", data);
  }
  if (isLoading) {
    return <ActivityRecentSkeleton />;
  }
  if (isError) {
    return <ErrorScreen error={error} />;
  }
  return (
    <Box sx={{ maxWidth: "1280px", margin: "24px auto", width: "95%" }} mt={4}>
      <Typography
        style={{
          marginBottom: "20px",
          fontSize: "3rem",
          fontWeight: "700",
          textAlign: "left",
        }}
        variant="h1"
      >
        My Activities
      </Typography>
      {/* <ActivityToDoSkeleton /> */}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: `${matchesTwo ? "row" : "column"}`,
          //   flexWrap: "wrap",
        }}
      >
        <ToDoListActPage homepage={false} />
      </div>
      <Box style={{ marginTop: "70px" }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            margin: "20px 0",
          }}
        >
          <Typography
            style={{
              fontSize: "1.4rem",
              fontWeight: "600",
              textAlign: "left",
            }}
          >
            Recent activity
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              // marginBottom: "10px",
            }}
          >
            <div
              style={{
                marginRight: "12px",
              }}
            >
              <TableRowsRoundedIcon
                onClick={() => setDisplayGrid(false)}
                style={{
                  cursor: "pointer",
                  fontSize: "2rem",
                  padding: "5px",
                  borderRadius: "5px",
                  color: "#fff",
                  backgroundColor: !displayGrid ? "#6358A5" : "#CBD2D9",
                  boxShadow:
                    "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                }}
              />
            </div>
            <div>
              <GridViewRoundedIcon
                onClick={() => setDisplayGrid(true)}
                style={{
                  cursor: "pointer",
                  fontSize: "2rem",
                  padding: "5px",
                  borderRadius: "5px",
                  color: "#fff",
                  backgroundColor: displayGrid ? "#6358A5" : "#CBD2D9",
                  boxShadow:
                    "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                }}
              />
            </div>
          </Box>
        </Box>
        {displayGrid && data && data.data.length > 0 && (
          <Box
            style={{
              // background: "#fff",
              // borderRadius: "12px",
              // boxShadow: " 0 5px 15px hsla(0, 0%, 0%, 0.2)",
              margin: "20px 0",
              display: "grid",
              gridAutoRows: "1fr",
              gap: "30px",
              gridTemplateColumns: columnTemp(),
            }}
          >
            {data &&
              data.data.map((act, index) => (
                <>
                  <RecentActItem
                    key={act.exercise.id}
                    name={act.exercise.name}
                    image={act.lesson === null ? comp : act.lesson.image}
                    // level={act.level}
                    exercise={act.exercise}
                    exerciseId={act.exercise.id}
                    lessonId={act.lesson.id ? act.lesson.id : null}
                    maxQuestion={act.max_questions}
                    questionsAnswered={act.questions_answered}
                    status={act.status}
                    lesson={act.lesson}
                    classAct={act._class}
                    homepage={false}
                  />
                </>
              ))}
          </Box>
        )}
        {data && data.data.length === 0 && (
          <Box style={{ width: "100%" }}>
            <EmptyStateTwo
              title={"Recent work"}
              isToDo={false}
              height={"400px"}
              // height="600px"
              text={"You've yet to start any activity."}
            />
          </Box>
        )}

        {!displayGrid && data && data.data.length > 0 && (
          <Stack
            style={{
              background: "#fff",
              borderRadius: "12px",
              boxShadow: " 0 5px 15px hsla(0, 0%, 0%, 0.2)",
              marginTop: "20px",
              paddingBottom: "18px",
            }}
          >
            {data.data.map((act, index) => (
              <>
                <ActivityStageCard
                  key={act.exercise.id}
                  name={act.exercise.name}
                  image={act.lesson === null ? comp : act.lesson.image}
                  // level={act.level}
                  exercise={act.exercise}
                  maxQuestion={act.max_questions}
                  questionsAnswered={act.questions_answered}
                  status={act.status}
                  lesson={act.lesson}
                  classAct={act._class}
                  homepage={false}
                />
                {index < data.data.length - 1 ? <Divider /> : ""}
              </>
            ))}
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default ActivitiesLearnPage;
