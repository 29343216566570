import React, { useState } from "react";
import { useMutation, useQueryClient, useQuery } from "react-query";
import useUserApi from "api/user";
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import componentStylesError from "assets/theme/components/snackbar.js";
import GeneralSnackbar from "components/Snackbar/GeneralSnackbar";
import { Container, Box } from "@material-ui/core";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@mui/material/Snackbar";
import "assets/css/peginationStyle.css";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import { CircularProgress } from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Pagination from "@material-ui/lab/Pagination";
import ManagePageHeader from "./DataSiteComponents/DataSiteManagePage/ManagePageHeader";
import TeacherManageX from "./DataSiteComponents/DataSiteManagePage/TeacherManageX";
import AddTeacherForm from "./components/ManagePage/AddForm/AddTeacherForm";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import useStore from "../../../zustand";

const useStyles = makeStyles(componentStyles);
const useStylesError = makeStyles(componentStylesError);
const tableHead = ["", "Name", "Class", ""];

export default function DataSiteManageTeachers() {
  const classes = { ...useStyles(), ...useStylesError() };
  const tenantId = useStore((state) => state.tenantId);

  const tableConatinerRef = React.useRef(null);
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const { fetchTeachers, addTeachers, fetchClasses } = useUserApi();
  const [sortBy, setSortBy] = React.useState("Name");
  const [order, setOrder] = React.useState(true);

  console.log("tenantId :>> ", tenantId);
  // console.log("tenantId :>> ", tenantId);
  // Fetch teacher data
  const { data: teachers, status: teachersStatus } = useQuery("teachers", () => fetchTeachers(), {
    refetchOnWindowFocus: false,
  });

  // Add teacher
  const {
    mutate: addTeacher,
    isError: isErrorAdd,
    isSuccess: isSuccessAdd,
    isLoading: isLoadingAdd,
    error: errorAdd,
  } = useMutation(
    (teacherID) => {
      return addTeachers(teacherID);
    },
    { onSuccess: () => queryClient.invalidateQueries("teachers") }
  );

  // Fetch class
  const { data: schoolClasses, status: schoolClassesStatus } = useQuery("classes", () => fetchClasses());

  //Search
  const [searchWord, setSearchWord] = useState("");

  const filterList = (item, array) => {
    return searchWord.length > 0
      ? item.full_name &&
          (item.full_name.toLowerCase().indexOf(searchWord.toLowerCase()) > -1 ||
            item.email.toLowerCase().indexOf(searchWord.toLowerCase()) > -1)
      : array;
  };

  // Filter classes
  const [chosenClass, setChosenClass] = React.useState(null);

  const filterClasses = (item, array) => {
    return chosenClass !== null ? chosenClass.teachers.includes(item.id) : array;
  };

  // Pegination
  const [page, setPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(50);

  const indexOfLastPost = page * postsPerPage; // 1 * 6 = 6 | 2 * 6 = 12
  const indexOfFirstPost = indexOfLastPost - postsPerPage; // 6 - 6 = 0  | 12 - 6 = 6

  const handleChange = (event) => {
    setPostsPerPage(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const pageCount = () => {
    return Math.ceil(
      teachersStatus === "success" &&
        teachers.data.filter((item, key, array) => filterList(item, array)).length / postsPerPage
    ); // total posts / post per page
  };

  React.useEffect(() => {
    pageCount();
  }, [searchWord]);

  function handleClose() {
    setOpen(false);
  }

  // Snackbar disappear
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenSnackbar(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [openSnackbar]);

  //Sorting

  function sortFunction(a, b) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }

  return (
    <Box sx={{ maxWidth: "1280px", margin: "auto" }}>
      <GeneralSnackbar
        isLoading={isLoadingAdd}
        isSuccess={isSuccessAdd}
        isError={isErrorAdd}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message="The teacher has been successfully added."
      />

      <AddTeacherForm
        person="Teacher"
        handleClose={handleClose}
        open={open}
        teacherData={teachersStatus === "success" && teachers.data}
        mutate={addTeacher}
        isSuccess={isSuccessAdd}
        isLoading={isLoadingAdd}
        isError={isErrorAdd}
        error={errorAdd}
        setOpenSnackbar={setOpenSnackbar}
        tenantIdSchool={tenantId}
      />

      <Container maxWidth={false} component={Box} marginTop="80px" classes={{ root: classes.containerRoot }}>
        <ManagePageHeader
          buttons={[
            {
              action: () => setOpen(true),
              name: "+ Add Teacher",
            },
          ]}
          links={[
            {
              link: () => "/data/students",
              name: "Manage Students",
            },
          ]}
          title="Manage Teachers"
        />
        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
          <Grid
            item
            xs={10}
            style={{
              boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
              background: "white",
              borderRadius: "8px",
              margin: "20px 5px 20px 0px",
              height: "100%",
              padding: "0px",
              paddingBottom: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                background: "#05649D",
                color: "white",
                overflow: "hidden",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                padding: "12px 20px",
              }}
            >
              <h4 style={{ color: "white", margin: "0", fontSize: "18px" }}>
                {teachers && teachers?.data?.length === 1
                  ? `${teachers?.data?.length} Teacher`
                  : `${teachers?.data?.length} Teachers`}
              </h4>
              <div>
                <OutlinedInput
                  placeholder="Search"
                  value={searchWord}
                  blurOnSelect
                  onChange={(e) => (setSearchWord(e.target.value), setPage(1))}
                  style={{
                    height: 35,
                    overflow: "hidden",
                    width: 300,
                    background: "white",
                    margin: "0 4px",
                  }}
                />
                <Select
                  onChange={handleChange}
                  value={postsPerPage}
                  blurOnSelect
                  style={{
                    height: 35,
                    overflow: "hidden",
                    borderRadius: 4,
                    background: "white",
                    margin: "0  0 0 4px",
                    color: "white",
                    border: "0px solid white",
                  }}
                >
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={150}>150</MenuItem>
                </Select>
              </div>
            </div>
            <TableContainer ref={tableConatinerRef}>
              <Box component={Table} alignItems="center" marginBottom="0!important">
                <TableHead>
                  <TableRow>
                    {tableHead.map((prop, key) => (
                      <TableCell
                        onClick={() => prop !== "Class" && (setSortBy(prop), setOrder(!order))}
                        key={key}
                        {...{
                          "data-sort":
                            prop !== "" && prop !== "Class"
                              ? (prop === "Project" ? "name" : prop).toLowerCase()
                              : undefined,
                        }}
                        scope="col"
                        classes={{
                          root: classes.tableCellRoot + " sort " + classes.tableCellRootHead,
                        }}
                      >
                        {prop}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="list">
                  {teachersStatus == "loading"
                    ? [1, 2, 3, 4, 5].map((teacher, index) => (
                        <TeacherManageX
                          key={index}
                          teacher={teacher}
                          classes={classes}
                          teachersStatus={teachersStatus}
                        />
                      ))
                    : teachers?.data
                        .sort((a, b) => {
                          return order
                            ? sortFunction(a.full_name, b.full_name)
                            : -sortFunction(a.full_name, b.full_name);
                        })
                        .filter((item, key, array) => filterList(item, array))
                        .filter((item, key, array) => filterClasses(item, array))
                        .slice(indexOfFirstPost, indexOfLastPost)
                        .map((teacher, index) => (
                          <TeacherManageX
                            key={index}
                            teacher={teacher}
                            classes={classes}
                            teachersStatus={teachersStatus}
                          />
                        ))}
                </TableBody>
              </Box>
            </TableContainer>
            <Box justifyContent="center" display="flex" style={{ marginTop: 30 }}>
              {teachersStatus == "success" && teachers?.data.length > 5 && (
                <Pagination
                  count={pageCount()}
                  color="primary"
                  variant="outlined"
                  page={page}
                  onChange={handlePageChange}
                  style={{ height: "none", color: "red" }}
                />
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
              background: "white",
              borderRadius: "8px",
              margin: "20px 0px 20px 5px",
              height: "100%",
              padding: "0px",
              paddingBottom: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                background: "#05649D",
                color: "white",
                overflow: "hidden",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                padding: "12px 20px",
                height: 59,
              }}
            >
              {/* <OutlinedInput
                placeholder='Search teachers...'
                value={searchWord}
                blurOnSelect
                onChange={(e) => (setSearchWord(e.target.value), setPage(1))}
                style={{
                  height: 35,
                  overflow: "hidden",
                  width: '100%',
                  background: "white",
                  margin: "0 4px",
                }}
              /> */}
              <h4
                style={{
                  color: "white",
                  margin: "0",
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                <FilterAltIcon style={{ marginBottom: 3 }} /> Filter by Class
              </h4>
            </div>
            <div style={{ padding: "10px 5px" }}>
              {schoolClassesStatus === "success" &&
                schoolClasses?.data?.map((item) => (
                  <Button
                    onClick={() => (chosenClass === item ? setChosenClass(null) : setChosenClass(item))}
                    style={{
                      margin: 5,
                      border: chosenClass === item ? "2px solid rgb(5, 100, 157)" : "none",
                      marginBottom: "10px",
                      padding: "5px 10px",
                      color: "#525f7f",
                      fontWeight: chosenClass === item ? 600 : 400,
                      overflow: "overlay",
                      background: "#f6f9fc",
                      width: "-webkit-fill-available",
                      cursor: "pointer",
                      fontSize: "12px",
                      position: "relative",
                      textTransform: "none",
                      fontFamily: "Montserrat,sans-serif",
                      display: "flex",
                      justifyContent: "start",
                      borderRadius: 12,
                    }}
                  >
                    {item.name}
                    {/* {chosenClass === item && <span style={{ boderRadius: '100%', background: 'transparent', color: 'rgb(5, 100, 157)', position: 'absolute', top: 2, right: 5, zIndex: 9999, fontSize: 10 }}>
                      <strong>X</strong>
                    </span>} */}
                  </Button>
                ))}
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
