import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useLocation } from "react-router-dom";

// core components
import componentStyles from "assets/theme/components/footers/admin-footer.js";

const useStyles = makeStyles(componentStyles);

const Footer = () => {
  const classes = useStyles();
  const location = useLocation()

  return (
    <Box component="footer" width="100%" maxWidth="1470px" margin="auto" style={{ maxWidth: location.pathname.includes('curriculum') ? 1270 : 1470 }}>
      <Grid
        container
        classes={{ root: classes.justifyContentCenter }}
        style={{ padding: "0px 10px" }}
        justifyContent="flex-end"
      >
        <Grid
          item
          xs={12}
          sm={6}
          component={Box}
          display="flex"
          justifyContent="flex-start"
        >
          <Box
            item
            component={Grid}
            display="flex"
            alignItems="center"
            className={classes.justifyContentCenter}
          >
            <div className={classes.copyrightWrapper} style={{ padding: 16, textAlign: 'center' }}>
              © {new Date().getFullYear()}{" "}
              <a
                href="https://educationai.co.uk"
                className={classes.copyrightLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                Education AI
              </a>
            </div>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          component={Box}
          display="flex"
          justifyContent="flex-end"
        >
          <Box
            component={List}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={classes.flexDirectionColumn}
          >
            <ListItem
              component="a"
              href="https://educationai.co.uk"
              rel="noopener noreferrer"
              target="_blank"
              classes={{
                root: classes.listItemRoot,
              }}
            >
              <a
                style={{ textDecoration: "none", color: "inherit" }}
                href={"mailto:contactus@EducationAI.co.uk"}
              >
                Contact Us
              </a>
            </ListItem>

            {/* <ListItem
              component="a"
              href="https://educationai.co.uk/"
              rel="noopener noreferrer"
              target="_blank"
              classes={{
                root: classes.listItemRoot,
              }}
            >
              About Us
            </ListItem> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
