import React from "react";
import "../../../../../assets/css/circle.css";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";

const StudentCircle = ({ student, score, angle }) => {
  return (
    <Tooltip
      title={student.full_name}
      className="circle-student"
      placement="top"
      arrow
      style={{
        transform: `rotate(${angle}deg) translate(400%) rotate(-${angle}deg)`,
      }}
    >
      <Link to={`/data/student/${student.id}`}>
        <div className="student-link" href="{}">
          <img
            src={student.icon}
            alt="pic"
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              borderRadius: "100px",
              padding: 0,
            }}
          />
        </div>
      </Link>
    </Tooltip>
  );
};

export default StudentCircle;
