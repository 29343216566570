import React, { useState } from "react";
import { useQuery } from "react-query";
import useBuilderApi from "api/builder";
import BuilderLevels from "./builderComponents/BuilderLevels";
import useCurriculumApi from "api/curriculum";
import { useParams } from "react-router-dom";

// import BuilderFilterComponent from "./builderComponents/BuilderFilterComponent";
// import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
// import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
// import BuilderLessonBasket from "./builderComponents/BuilderLessonBasket";

const CurriculumBuilderPage = () => {
  const { fetchBuilderLessons, fetchLevel } = useBuilderApi();
  const { fetchLevels } = useCurriculumApi();
  const { levelid } = useParams();

  const { isLoading, isError, data, error } = useQuery("builderLessons", fetchBuilderLessons);
  const [expand, setExpand] = useState(true);
  const [searchWord, setSearchWord] = useState("");
  const [selectedLessonIdPlayer, setSelectedLessonIdPlayer] = useState("");
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);

  const { data: dataLevels } = useQuery("myLevels", () => fetchLevels(false));
  const { data: dataCustomLevels } = useQuery("myLevels", () => fetchLevels(true));
  const { fetchLessonPlanId } = useCurriculumApi();

  //fetch fetchLessonPlan
  const { data: lessonPlanData, status: lessonPlanStatus } = useQuery(
    ["lessonPlanData", levelid],
    () => fetchLessonPlanId(levelid),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: dataCustomLevelEdit } = useQuery(["myEditLevels", levelid], () =>
    fetchLevel(levelid)
  );
  // console.log("dataCustomLevels &&dataCustomLevels :>> ", dataCustomLevels && dataCustomLevels);
  // console.log("lessonPlanData && lessonPlanData.data :>> ", lessonPlanData && lessonPlanData.data);
  // console.log(
  //   "dataCustomLevelEdit && dataCustomLevelEdit",
  //   dataCustomLevelEdit && dataCustomLevelEdit
  // );
  return (
    <div style={{ margin: "50px auto 0", width: "95%" }}>
      {data && (
        <BuilderLevels
          data={data?.data}
          customLessonData={dataCustomLevels?.data}
          searchWord={searchWord}
          selectedLessons={selectedLessons}
          setSelectedLessons={setSelectedLessons}
          selectedFilterItems={selectedFilterItems}
          setSelectedFilterItems={setSelectedFilterItems}
          setSelectedLessonIdPlayer={setSelectedLessonIdPlayer}
          selectedLessonIdPlayer={selectedLessonIdPlayer}
          setSearchWord={setSearchWord}
          editLevelId={levelid}
          dataCustomLevelEdit={lessonPlanData?.data}
        />
      )}
    </div>
  );
};

export default CurriculumBuilderPage;
