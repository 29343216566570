import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import SingleAwardCard from "./SingleAwardCard";
// import { experimentalStyled as styled } from '@mui/material/styles';
// import Paper from '@mui/material/Paper';
import award1 from "../assets/award1.png";
import award2 from "../assets/award2.png";
import award3 from "../assets/award3.png";
import award4 from "../assets/award4.png";
import award5 from "../assets/award5.png";

const YourAwards = () => {
  const stockData = [
    {
      title: `Completed 1 Lesson`,
      image: award1,
      progress: "100",
    },
    {
      title: `First exam Completed`,
      image: award5,
      progress: "100",
    },
    {
      title: `10 day streak award`,
      image: award2,
      progress: "75",
    },
    {
      title: `Completed 1 Lesson`,
      image: award3,
      progress: "35",
    },
    {
      title: `First exam Completed`,
      image: award4,
      progress: "96",
    },
    {
      title: `10 day streak award`,
      image: award1,
      progress: "65",
    },
    {
      title: `Completed 1 Lesson`,
      image: award2,
      progress: "50",
    },
    {
      title: `First exam Completed`,
      image: award3,
      progress: "100",
    },
    {
      title: `10 day streak award`,
      image: award4,
      progress: "25",
    },
    {
      title: `Completed 1 Lesson`,
      image: award5,
      progress: "25",
    },
    {
      title: `First exam Completed`,
      image: award1,
      progress: "100",
    },
    {
      title: `10 day streak award`,
      image: award2,
      progress: "25",
    },
  ];
  return (
    <Box marginTop="50px" className="activitiesAwards">
      {/* <Typography
                variant='h3'
                style={{
                    color: '#7B8794',
                    fontSize: '18px',
                    fontWeight: '600',
                    marginBottom: '24px',
                }}
            >
                Your awards: 34/50
            </Typography> */}
      <Box marginBottom="34px">
        <Typography
          variant="h3"
          style={{
            color: "#7B8794",
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "15px",
            display: "inline-block",
          }}
        >
          Your awards
        </Typography>
      </Box>

      <Box
        // marginLeft="12px"
        // marginRight="12px"
        sx={{ flexGrow: 1 }}
        style={{ position: "relative" }}
      >
        <Box
          style={{
            position: "absolute",
            top: "45%",
            left: "50%",
            width: "100%",
            height: "100%",
            borderRadius: "14px",
            zIndex: "99",
            background: "rgba(0,0,0,0.375)",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transform: "translate(-50%,-50%)",
            // filter: "blur(8px)",
          }}
        >
          <h2 style={{ color: "#fff" }}>Coming Soon</h2>
        </Box>
        <Grid container spacing={3} style={{ filter: "blur(8px)" }}>
          {stockData.map((data, index) => (
            <Grid item spacing={3} xs={6} md={4} lg={2} key={index}>
              <SingleAwardCard
                title={data.title}
                image={data.image}
                progress={data.progress}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default YourAwards;
