import React, { useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import CurriculumCard from "components/Cards/eai/CurriculumCard";
import { CurriculumSection } from "./Components/CurriculumSection";
// import { LoadingScreen } from "./Components/LoadingScreen";
import { CurriculumPage } from "./CurriculumPage";
import useCurriculumApi from "api/curriculum";
import { useLocation } from "react-router-dom";
import VerticalToggleButtons from "./ColumnPage/TogglePage";
import { Box, MuiThemeProvider } from "@material-ui/core";
import { createTheme } from "@mui/system";
import CurColumnCustomLesson from "./ColumnPage/CurColumnCustomLesson";
import useStore from "../../../zustand.js";
import usePersistStore from "../../../zustandPersist.js";
import CategorySelector from "./ColumnPage/CategorySelector";

// import { useEffect } from "react";
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1936,
    },
  },
});

function CustomLevelLessonsPage() {
  const params = useParams();
  const { fetchCustomLevel, fetchLevels, fetchLessonPlan, fetchLessonPlanId } = useCurriculumApi();
  const location = useLocation();
  const inputRef = useRef(null);
  const [alignment, setAlignment] = React.useState("all");
  const { view, setView } = useStore();
  const { permission } = usePersistStore();
  console.log("location :>> ", location);
  // Specific custom level based on the paramId
  // const { isLoading, status, data, error, isSuccess } = useQuery(
  //   ["customLevel", params.levelId],
  //   () => fetchCustomLevel(params.levelId),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );
  //fetch fetchLessonPlan
  const { isLoading, status, data, error, isSuccess } = useQuery(
    ["lessonPlanData", params?.levelId],
    () => fetchLessonPlanId(params?.levelId),
    {
      enabled: params?.levelId?.length > 0,
      refetchOnWindowFocus: false,
    }
  );
  // console.log("============");
  // console.log("============");
  // console.log("params  :>> ", params);
  // console.log("params  :>> ", params);
  // console.log("params  :>> ", params);
  // console.log("data && data?.data :>> ", data && data?.data);
  // console.log(data?.data["lessons"]);
  // console.log("============");
  // All custom levels
  const { data: allCustomLevels, isSuccess: isSuccessAll } = useQuery("myLevels", () => fetchLevels(), {
    refetchOnWindowFocus: false,
  });

  //fetch fetchLessonPlan
  const {
    data: lessonPlanData,
    status: lessonPlanStatus,
    isSuccess: lessonPlanSuccess,
  } = useQuery(["lessonPlanData"], () => fetchLessonPlan(), {
    refetchOnWindowFocus: false,
  });
  function Scroll() {
    location?.lessonId && inputRef?.current?.scrollIntoView({ behavior: "smooth" });
  }

  React.useEffect(() => {
    isSuccess && Scroll();
  }, [location?.lessonId, lessonPlanSuccess, view]);

  const sections = [];
  if (data?.data) {
    for (const lesson of data?.data["lessons"]) {
      const cards = [];
      for (const resource of lesson.files) {
        if (true) {
          cards.push(
            <CurriculumCard
              key={resource?.id}
              id={lesson?.id}
              title={resource?.name}
              subtitle={lesson.name}
              arrayTitle="Lesson: "
              image={resource.image}
              link={`/curriculum/resources/${resource?.id}/`}
            />
          );
        } else {
          cards.push(
            <CurriculumCard
              key={resource?.id}
              id={lesson?.id}
              title={resource.name}
              subtitle={lesson.name}
              arrayTitle="Lesson: "
              image={resource.image}
              blur="blur(5px)"
              // link={`/curriculum/resources/${resource.id}/`}
            />
          );
        }
      }
      sections.push(
        <CurriculumSection
          name={lesson.name}
          cards={cards}
          key={lesson?.id}
          textInput={inputRef}
          lessonProp={location?.lessonId}
          lessonId={lesson?.id}
        />
      );
    }
  }
  return (
    <>
      {view ? (
        <CurriculumPage name="Lessons" sections={sections} />
      ) : (
        <MuiThemeProvider theme={theme}>
          <Box
            style={{
              color: "#323F4B",
              padding: 0,
              display: "flex",
              justifyContent: "center",
              background: "white",
              alignItems: "center",
              boxShadow: "rgb(0 0 0 / 20%) 0px 5px 15px",
              borderRadius: 4,
              margin: "10px",
            }}
          >
            <CategorySelector customLevels={allCustomLevels} isSuccessAll={lessonPlanSuccess} />
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                margin: "3px",
              }}
            >
              <Box
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                {/* <h5 style={{ padding: '10px 10px 0px', display: 'flex', alignItems: 'center', minWidth: 161 }}>Custom Lesson Plan</h5> */}
                {lessonPlanSuccess &&
                  lessonPlanData?.data?.custom_lesson_plans.map((customLevel) => (
                    <Link to={`/curriculum/myLevels/${customLevel?.id}/lessons`}>
                      <div
                        style={{
                          background:
                            customLevel?.id === params.levelId ? "#06659d" : "rgba(219, 219, 219, 0.31)",
                          color: customLevel?.id !== params.levelId ? "#06659d" : "white",
                          borderRadius: 4,
                          padding: "7px 30px",
                          margin: 5,
                          // filter: permission?.access?.levels?.includes(customLevel?.id) ? "blur(0px)" : "blur(4px)"
                        }}
                      >
                        {/* <h4 style={{ color: 'inherit', margin: 0 }}>{`${data?.data?.name}`}</h4> */}
                        <h4 style={{ color: "inherit", margin: 0 }}>{customLevel.name}</h4>
                      </div>
                    </Link>
                  ))}
              </Box>
            </Box>
            <VerticalToggleButtons view={view} setView={setView} />
          </Box>
          <Box
            style={{
              zIndex: 99,
              background: "#f8f9fe",
              width: "100%",
              padding: 0,
              display: "flex",
              margin: "0px auto",
              justifyContent: "center",
              flexDirection: "column",
              position: "fixed",
            }}
          >
            <CurColumnCustomLesson
              lessonType="online"
              levelId={params?.levelId}
              data={data && data?.data}
              isLoading={isLoading}
              status={status}
              alignment={alignment}
              isSuccess={isSuccess}
              componentHeight={75}
              lessonIdFromLink={location?.lessonId}
              inputRef={inputRef}
            />
          </Box>
        </MuiThemeProvider>
      )}
    </>
  );
}

export default CustomLevelLessonsPage;
