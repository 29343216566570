import React from "react";
// import { CircularLoadingScreen } from "views/eai/activities/components/Loading";
import useCurriculumApi from "api/curriculum";
import { useRef } from "react";
import { useQuery } from "react-query";
import "./CurriculumStyle.css";
import { Box } from "@material-ui/core";
// import Avatar from '@mui/material/Avatar';
import InsideView from "./InsideView";
import InfoPage from "./InfoPage";
import CurLessons from "./CurLessons";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material";
import usePersistStore from "../../../../zustandPersist";
import ErrorPage from "../ErrorPage";
import useStore from "../../../../zustand";
// import { Skeleton } from "@mui/material";

function CurColumnLesson({ lessonType, level, levelId, componentHeight, lessonIdFromLink }) {
  const { fetchLessons, fetchLevelResources, fetchResource } = useCurriculumApi();
  const { permission } = usePersistStore();
  const { lessonFromClass } = useStore();
  const [content, setContent] = React.useState(null);
  const [lessonID4LO, setLessonID4LO] = React.useState(null);
  const [fileItem, setFileItem] = React.useState(null);
  const [lessonRecource, setLessonRecource] = React.useState(null);
  const [test, setTest] = React.useState(false);
  const [iframeId, setIframId] = React.useState(null);
  const [hide, setHide] = React.useState(true);
  const inputRef = useRef(null);
  const location = useLocation();
  const [seconds, setSeconds] = React.useState(0);

  // fetch screen based lessons
  const {
    data: lessonData,
    status: lessonStatus,
    isSuccess,
  } = useQuery(["fetchLessons", levelId], () => levelId && fetchLessons(levelId), {
    refetchOnWindowFocus: false,
  });

  // fetch unplugged lessons
  const { data: dataRecources, status: statusRecources } = useQuery(
    ["fetchRecources", levelId],
    () => levelId && fetchLevelResources(levelId),
    {
      refetchOnWindowFocus: false,
    }
  );
  // const labelValue = document.querySelector(".navigation-controls__label");
  // console.log("labelValue", labelValue);
  // console.log("labelValue", labelValue);
  // console.log("labelValue", labelValue);
  useEffect(() => {
    const interval = setInterval(() => {
      const labelValue = document.querySelector(".navigation-controls_label");
      console.log("labelValue", labelValue);
      setSeconds((seconds) => seconds + 1);
    }, 2000);
    return () => clearInterval(interval);
  }, []);
  // fetch iFrame URL
  const {
    data: dataFrame,
    status: statusFrame,
    isLoading: isLoadingFrame,
    isError: isErrorFrame,
    error: errorFrame,
  } = useQuery(["viewResource", iframeId], () => iframeId && fetchResource(iframeId), {
    refetchOnWindowFocus: false,
  });

  const urlFrame = statusFrame == "success" && dataFrame?.data?.url;

  // set Recources - unplugged
  const cRecources = statusRecources === "success" && dataRecources?.data[0];

  React.useEffect(() => {
    levelId &&
      statusRecources === "success" &&
      location?.state?.from === "book" &&
      setLessonRecource(cRecources);
  }, [level, levelId, statusRecources, dataRecources, location?.state?.from]);

  // set lesson - online
  React.useEffect(() => {
    lessonIdFromLink
      ? levelId &&
        lessonStatus === "success" &&
        setContent(lessonData.data.filter((item) => item.id === lessonIdFromLink)[0]) // if the user comes from old curriculum lesson page, then it sets the lesson to the the lesson from which the user is coming from
      : !!lessonFromClass
      ? levelId &&
        lessonStatus === "success" &&
        setContent(lessonData.data.filter((item) => item.id === lessonFromClass)[0]) // if the user comes from class page, the lesson is set to the lesson chosen on class page
      : levelId &&
        lessonStatus === "success" &&
        location?.state?.from !== "book" &&
        setContent(lessonData?.data[0]); // else the first lesson is chosen
  }, [level, levelId, lessonStatus, lessonIdFromLink, location?.state?.from, location.pathname]);

  // Set file inside lesson
  React.useEffect(() => {
    lessonType !== "offline"
      ? content?.files?.length > 0 && setFileItem(content?.files[0])
      : setFileItem(content);
  }, [content, lessonType]);

  // Set Lesson based on selected file for Lesson Learnign Objective on InfoPage component
  useEffect(() => {
    setLessonID4LO(
      lessonData?.data.filter((lesson) =>
        lesson?.files.some((item) => item?.id === fileItem?.id)
      )[0]
    );
  }, [fileItem]);
  console.log("lessonData :>> ", lessonData && lessonData.data);
  // Set iFrame id to get URL
  React.useEffect(() => {
    lessonType !== "offline"
      ? content?.files?.length > 0 && setIframId(content?.files[0]?.id)
      : cRecources?.id && setIframId(cRecources.id);
  }, [lessonType, content, level, levelId, cRecources]);

  // Scroll to selected lesson when the user comes from class
  useEffect(() => {
    isSuccess && lessonIdFromLink && inputRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [lessonIdFromLink, isSuccess]);

  document.cookie =
    statusFrame == "success" &&
    `AuthToken=${dataFrame?.data?.jwt};max-age=604800;domain=educationai.co.uk;path=/`;
  document.cookie =
    statusFrame == "success" &&
    `sas="${dataFrame?.data?.sas}";max-age=604800;domain=educationai.co.uk;path=/`;

  return (
    <>
      <Box
        container
        style={{
          margin: "0px auto",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "",
        }}
      >
        <div
          className="sidebar"
          onClick={() => setHide(true)}
          style={{
            zIndex: 9999,
            visibility: !hide ? "visible" : "hidden",
            cursor: "pointer",
            position: "absolute",
            top: 80,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            padding: "5px 10px ",
            border: "1px solid rgb(6, 101, 157)",
            background: "rgb(6, 101, 157)",
            color: "white",
            fontWeight: 500,
          }}
        >
          Content <ArrowForwardIcon />
        </div>

        {/* Sidebar */}
        <Box
          style={{
            marginTop: "0px",
            color: "#323F4B",
            padding: 0,
            maxWidth: 320,
            width: hide ? "320px" : 0,
            transition: "width 0.5s ease-in-out",
          }}
        >
          <Box
            style={{
              overflowY: "scroll",
              height: `calc(100vh - 73px - ${componentHeight}px)`,
              width: 320,
              boxShadow: "rgb(0 0 0 / 20%) 0px 5px 15px",
              background: "white",
              borderRadius: 4,
              padding: 5,
              margin: "0px 10px",
              left: 500,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h5 style={{ padding: "10px 10px 0px", fontSize: 17 }}>
                {lessonType !== "offline" ? "Lessons" : "Books"}{" "}
              </h5>
              <h5
                style={{ padding: "10px 10px 0px", cursor: "pointer" }}
                onClick={() => setHide(false)}
              >
                <ArrowBackIcon />
              </h5>
            </div>

            {lessonStatus === "loading" ? (
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item) => (
                <Skeleton count={1} width={290} height={87} style={{ margin: "auto" }} />
              ))
            ) : lessonType !== "offline" ? (
              lessonData?.data.map((item, index) => (
                <CurLessons
                  index={index}
                  permission={permission}
                  lessonStatus={lessonStatus}
                  item={item}
                  fileItem={fileItem}
                  setContent={setContent}
                  content={content}
                  setTest={setTest}
                  setFileItem={setFileItem}
                  setIframId={setIframId}
                  test={test}
                  inputRef={inputRef}
                  data={lessonData?.data}
                />
              ))
            ) : (
              <Box
                style={{
                  width: "310px",
                  cursor: "pointer",
                  // filter: permission?.access?.lessons?.includes(cRecources?.id) ? "blur(0px)" : "blur(4px)"
                }}
              >
                <Box
                  onClick={() => (setContent(cRecources), setTest(!test))}
                  style={{
                    margin: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#06659d",
                    // background: content?.id === cRecources?.id ? 'black' : '#06659d',
                    color: "white",
                    alignItems: "center",
                    borderRadius: 4,
                  }}
                >
                  <h5
                    style={{
                      padding: "10px 10px 10px 20px",
                      display: "flex",
                      alignItems: "center",
                      color: "inherit",
                      margin: 0,
                      width: "100%",
                      height: 60,
                      cursor: "pointer",
                    }}
                  >
                    {cRecources.name}
                  </h5>
                </Box>
                <InsideView
                  key={cRecources?.id}
                  data={cRecources}
                  content={lessonRecource}
                  setContent={setContent}
                  setTest={setTest}
                  test={test}
                />
              </Box>
            )}
          </Box>
        </Box>

        <Box
          style={{
            background: "white",
            padding: 0,
            width: "100%",
            zIndex: 999,
            margin: !hide ? "auto 10px auto 10px" : "auto 10px auto 20px",
            boxShadow: "rgb(0 0 0 / 20%) 0px 5px 15px",
            borderRadius: 4,
            height: `calc(100vh - 73px - ${componentHeight}px)`,
            overflow: "hidden",
          }}
        >
          {lessonType !== "offline" ? (
            true ? (
              <InfoPage
                iframeURL={urlFrame}
                content={content}
                lessonType={lessonType}
                lessonID4LO={lessonID4LO}
                test={test}
                height={`calc(100vh - 83px - ${componentHeight}px)`}
                isLoading={isLoadingFrame}
                isError={isErrorFrame}
                error={errorFrame}
              />
            ) : (
              <ErrorPage />
            )
          ) : true ? (
            <InfoPage
              iframeURL={urlFrame}
              content={lessonRecource}
              lessonID4LO={lessonID4LO}
              lessonType={lessonType}
              test={test}
              height={`calc(100vh - 83px - ${componentHeight}px)`}
              isLoading={isLoadingFrame}
              isError={isErrorFrame}
              error={errorFrame}
            />
          ) : (
            <ErrorPage />
          )}
        </Box>
      </Box>
    </>
  );
}

export default CurColumnLesson;
