import React from "react";
// import "../../../../../assets/css/circle.css";
import StudentCircle from "./StudentCircle";
import "assets/plugins/nucleo/css/nucleo.css";
import GeneralDataCard from "../DataDashboard/GeneralDataCard";
import SubjectIcon from "@mui/icons-material/Subject";
import { IconButton } from "@material-ui/core";
// import StudentList from "./StudentList";
import CloseIcon from "@mui/icons-material/Close";
// import StudentsPerformanceList from "../../DataSiteComponents/DataSiteClassPage/StudentsPerformanceList";
import StudentsPerformanceListTwo from "../../DataSiteComponents/DataSiteClassPage/StudentsPerformanceListTwo";
import Tooltip from "@mui/material/Tooltip";

const StudentCircleCard = ({ title, color, data, status, type }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [main, setMain] = React.useState(true);
  const [list, setList] = React.useState(true);
  const toolTipDef = (check) => {
    if (check === "worst") {
      return "Students need to work more on their understanding of one or more learning objectives in this lesson.";
    } else if (check === "middle") {
      return "Students are comfortable with the learning objectives in this lesson.";
    } else if (check === "best") {
      return "Students exhibit a good understand of learning objectives in this lesson and should be given stretch tasks.";
    } else if (check === "noscore") {
      return "Students have not finished the assignment for the lesson.";
    }
  };
  let bgColorTheme;
  if (type === "worst") {
    bgColorTheme = "#EEC27C";
  } else if (type === "middle") {
    bgColorTheme = "#7FCDED";
  } else {
    bgColorTheme = "#8ACB5F";
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const angle = (students) => {
    return 360 / (students.length < 30 ? students.length : 30);
  };

  return (
    <GeneralDataCard status={status}>
      <IconButton
        onClick={() => setList(!list)}
        variant='contain'
        color='primary'
        style={{
          width: 25,
          height: 25,
          position: "absolute",
          right: 10,
          top: 10,
          background: "#7Fcdee",
          borderRadius: 5,
          padding: 13,
        }}
      >
        {list ? (
          <SubjectIcon style={{ color: "white" }} />
        ) : (
          <CloseIcon style={{ color: "white" }} />
        )}
      </IconButton>
      {list ? (
        <>
          {status == "success" ? (
            <div className='card-circle'>
              <div
                className='card-body'
                style={{ padding: main ? "25px" : "0px 20px" }}
              >
                <div className='row'>
                  <div
                    className='circle-outer need-help '
                    style={{ borderColor: color }}
                  >
                    {data.data[type].map((item, i) => (
                      <StudentCircle
                        key={i}
                        score={item.score}
                        student={item.student}
                        angle={angle(data.data[type]) * i}
                      />
                    ))}
                    <Tooltip
                      title={
                        <div
                          style={{
                            fontSize: "14px",
                            background: bgColorTheme,
                            // color: "rgba(0,0,0,0.8)",
                            color: "white",
                            borderRadius: "4px",
                            padding: "5px",
                            margin: "8px",
                          }}
                        >
                          <h4
                            style={{
                              fontSize: "14px",
                              borderRadius: "4px",
                              color: "white",
                              fontWeight: "400",
                              margin: "0",
                              padding: "10px 20px",
                              background: "rgba(0,0,0,0.5)",
                            }}
                          >
                            {toolTipDef(type)}
                          </h4>
                        </div>
                      }
                      arrow
                      placement='top'
                    >
                      <div
                        id='student-circle'
                        style={{
                          cursor: "pointer",
                          borderColor: color,
                          background: color,
                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "center",
                          // alignItems: "center",
                        }}
                        className='circle-inner'
                      >
                        <h3
                          style={{
                            color: "white",
                            // marginTop: "15%",
                            // margin: "0",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {data.data[type].length}
                        </h3>
                        <p
                          style={{
                            color: "white",
                            fontSize: "16px",
                            margin: "0",
                          }}
                        >
                          {title}
                        </p>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <>
          {/* {status == "success" && <StudentList data={data.data[type]} status={status} />} */}

          <StudentsPerformanceListTwo students={data.data[type]} />
        </>
      )}
    </GeneralDataCard>
  );
};

export default StudentCircleCard;
