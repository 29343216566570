import React from 'react';
import {
    Box,
    MuiThemeProvider,
} from "@material-ui/core";
import 'assets/css/myStyle.css'
import { createTheme } from "@mui/system";
import { useEffect } from 'react';
import { ReactHeight } from 'react-height';
import CurColumnLesson from './CurColumnLesson';
import CurRowLevels from './CurRowLevels';
import usePersistStore from '../../../../zustandPersist.js'
import { useLocation, useParams } from 'react-router-dom';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1936,
        },
    },
});


function CurColumn({ data, paramId, isLoading }) {
    const params = useParams();
    const [lessonType, setLessonType] = React.useState(null)
    const [level, setLevel] = React.useState(null)
    const [test, setTest] = React.useState(false)
    const [searchWord, setSearchWord] = React.useState("");
    const [componentHeight, setComponentHeight] = React.useState(null);
    const { chosenLevel } = usePersistStore()
    const location = useLocation();

    // filtering data based on type - online vs offline
    const online = data?.length > 0 && data[0]?.levels.filter((level) => level.type === 'online')
    const offline = data?.length > 0 && data[0]?.levels.filter((level) => level.type === 'offline')


    useEffect(() => {
        let levelInfo
        location?.state?.from === 'book' ? levelInfo = offline[chosenLevel] : levelInfo = online[chosenLevel]
        setLevel(levelInfo)
        setLessonType(levelInfo?.type)
    }, [paramId, location.state, params.categoryId, isLoading, location.lessonId]) // chooses level based on changes on these parameters, location.state is true when the user comes from curriculum homepage whilst location.lessonid is true when the user comes from class page 


    return (
        <MuiThemeProvider theme={theme}>
            <Box
                style={{
                    zIndex: 99,
                    background: '#f8f9fe', width: '100%',
                    padding: 0, display: 'flex', margin: '0px auto',
                    justifyContent: 'center', flexDirection: 'column', position: 'fixed'
                }}>
                <ReactHeight onHeightReady={height => setComponentHeight(height)}>
                    <CurRowLevels
                        data={data}
                        level={level}
                        setLessonType={setLessonType}
                        setLevel={setLevel}
                        setTest={setTest}
                        test={test}
                        lessonID={location.lessonId}
                    />
                </ReactHeight>
                <CurColumnLesson
                    lessonType={lessonType}
                    level={level}
                    levelId={level?.id}
                    alignment='all'
                    searchWord={searchWord}
                    setSearchWord={setSearchWord}
                    componentHeight={componentHeight}
                />
            </Box>
        </MuiThemeProvider>
    );
}


export default CurColumn;
