import React from "react";
// javascipt plugin for creating charts
import './style.css'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";

// @material-ui/icons components

// core components

import componentStyles from "assets/theme/components/cards/charts/card-total-orders.js";
// import GeneralDataCard from "../DataDashboard/GeneralDataCard";
import { Card } from "@material-ui/core";

const useStyles = makeStyles(componentStyles);

function SchoolMainCardLayout({
    name,
    component,
    flexwrap,
    overflowX,
    justifyContent,
    background,
    boxShadowBody,
    bodyBG,
    headerBoxShadow,
    headerBG,
    boxShadow,
    paddingBottom,
    button,
    paddingLeft,
    paddingRight,
    useRefScrl,
    useRefScrl3,
    scrollCheck,
    paddingLeftHeader,
    paddingTopContent,
    margin,
    className,
    scroller,
    scroller2,
    component4,
    Scroller,
    display,
    searchBar
}) {

    const classes = useStyles();
    const theme = useTheme();

    return (
        // <GeneralDataCard status={status}>
        <Card style={{ background: background, boxShadow: boxShadow, margin: margin }} className={className}>
            <CardHeader
                title={
                    <Box style={{ display: 'flex', justifyContent: 'space-between', background: headerBG, borderBottom: 'none', boxShadow: headerBoxShadow, paddingBottom: paddingBottom, paddingLeft: paddingLeftHeader }}>
                        <Box
                            component="span"
                            color={theme.palette.gray[600]}
                            style={{ fontSize: 18, textTransform: 'none', fontFamily: 'Open Sans', color: '#7B8794', display: 'flex', justifyContent: 'end', alignItems: 'end' }}
                        >
                            {name}
                        </Box>
                        <Box
                            component="span"
                            color={theme.palette.gray[500]}
                            style={{ fontSize: 18, textTransform: 'none', display: 'flex', justifyContent: 'center', alignItems: 'end' }}
                        >
                            {searchBar}{button}
                        </Box>
                    </Box>
                }
                style={{
                    background: headerBG,
                    borderBottom: 'none',
                    boxShadow: headerBoxShadow,
                    paddingBottom: paddingBottom,
                    paddingRight: '0px',
                    paddingLeft: '10px'
                }}
                // subheader={name}
                classes={{ root: classes.cardHeaderRoot }}
                titleTypographyProps={{
                    component: Box,
                    variant: "h6",
                    letterSpacing: "2px",
                    marginBottom: "0!important",
                    classes: {
                        root: classes.textUppercase,
                    },
                }}
                subheaderTypographyProps={{
                    component: Box,
                    variant: "h3",
                    marginBottom: "0!important",
                    color: "initial",
                }}
            ></CardHeader>


            <CardContent style={{ background: bodyBG, boxShadow: boxShadowBody, borderRadius: '0.375rem', paddingLeft: paddingLeft, paddingRight: paddingRight, paddingTop: paddingTopContent }}>
                <Box position="relative" height="100%" style={{ display: display, }}>
                    <Grid
                        className={Scroller}
                        ref={useRefScrl}
                        container
                        style={{
                            display: 'flex',
                            justifyContent: justifyContent,
                            flexWrap: flexwrap,
                            overflowX: overflowX,
                            margin: 0,
                            width: '100%',
                        }}
                    >
                        {component}
                    </Grid>
                    {scroller &&
                        <Box
                            component="span"
                            color={theme.palette.gray[500]}
                            style={{ fontSize: 18, textTransform: 'none', margin: '0px auto 20px' }}
                        >
                            {scroller}
                        </Box>
                    }
                    {component4 && component4 &&
                        <Grid
                            className={Scroller}
                            ref={useRefScrl3}
                            container
                            style={{
                                display: 'flex',
                                justifyContent: justifyContent,
                                flexWrap: flexwrap,
                                overflowX: overflowX,
                                margin: 0
                            }}
                        >
                            {component4 && component4}
                        </Grid>
                    }
                    {scroller2 &&
                        <Box
                            component="span"
                            color={theme.palette.gray[500]}
                            style={{ fontSize: 18, textTransform: 'none', margin: 'auto' }}
                        >
                            {scroller2}
                        </Box>}
                </Box>
            </CardContent>
        </Card>
    );
}

export default SchoolMainCardLayout;