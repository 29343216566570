import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@material-ui/core";

const HomeStatusLoadingCard = () => {
  const matches = useMediaQuery("(min-width:1100px)");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        margin: "35px 0",
      }}
    >
      <Box
        style={{
          width: ` ${matches ? "30%" : "100%"}`,
          boxShadow: " 0 10px 15px hsla(0, 0%, 0%, 0.096)",
          background: "#fff",
          padding: "20px",
          borderRadius: "14px",
        }}
      >
        <Skeleton count={5} />
      </Box>
      <Box
        style={{
          width: ` ${matches ? "30%" : "100%"}`,
          boxShadow: " 0 10px 15px hsla(0, 0%, 0%, 0.096)",
          background: "#fff",
          padding: "20px",
          borderRadius: "14px",
        }}
      >
        <Skeleton count={5} />
      </Box>
      <Box
        style={{
          width: ` ${matches ? "30%" : "100%"}`,
          boxShadow: " 0 10px 15px hsla(0, 0%, 0%, 0.096)",
          background: "#fff",
          padding: "20px",
          borderRadius: "14px",
        }}
      >
        <Skeleton count={5} />
      </Box>
    </div>
  );
};

export default HomeStatusLoadingCard;
