import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SideBarAiWrapper } from "./SideBarAi.styles";
const SideBarAi = () => {
  const [isOpenAi, setIsOpenAi] = useState(false);
  return (
    <SideBarAiWrapper>
      <div className="left-ai" onClick={() => setIsOpenAi((prev) => !prev)}>
        <MoreVertIcon style={{ fontSize: "66px", margin: "0", padding: "10px" }} />
      </div>
      <div className={!isOpenAi ? "right-ai close-ai" : "right-ai "}>
        <div
          style={{
            width: "290px",
            height: "160px",
            backgroundColor: "#EFEDFF",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyCenter: "center",
            flexDirection: "column",
            padding: "20px 10px",
            textAlign: "center",
          }}
        >
          <h1 style={{ margin: "0" }}>Ai Activities!</h1>
          <p style={{ margin: "0" }}>All the set activities are complete.</p>
        </div>
        <div
          style={{
            width: "290px",
            height: "160px",
            backgroundColor: "#EFEDFF",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyCenter: "center",
            flexDirection: "column",
            padding: "20px 10px",
            textAlign: "center",
          }}
        >
          <h1 style={{ margin: "0" }}>Ai Blocks!</h1>
          <p style={{ margin: "0" }}>All the set activities are complete.</p>
        </div>
        <div
          style={{
            width: "290px",
            height: "160px",
            backgroundColor: "#d7d7d75c",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyCenter: "center",
            flexDirection: "column",
            padding: "20px 10px",
            textAlign: "center",
          }}
        >
          <h1 style={{ margin: "0" }}>Ai Activities!</h1>
          <p style={{ margin: "0" }}>All the set activities are complete.</p>
        </div>
      </div>
    </SideBarAiWrapper>
  );
};

export default SideBarAi;
