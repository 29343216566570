import { Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons components
// import { useQuery } from "react-query";
import useCurriculumApi from "api/curriculum";

import componentStyles from "assets/theme/views/admin/dashboard.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
// import SimpleHeader from "components/Headers/SimpleHeader";

import RecentActivity from "./components/RecentActivity";
// import ToDoList from "./components/ToDoList";
// import ContinueLearning from "./components/ContinueLearning";

import YourAwards from "./components/YourAwards";
import ActivityHeader from "./components/ActivityHeader";

import useMediaQuery from "@mui/material/useMediaQuery";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import useUserApi from "api/user";
// import { useQuery } from "react-query";
// import { LoadingScreen } from "../curriculum/Components/LoadingScreen";
// import { ErrorScreen } from "../curriculum/Components/ErrorScreen";
import { useEffect, useState } from "react";
import ToDoHomePage from "./components/ToDoHomePage";
import HomeStatusCards from "./components/HomeStatusCards";
// import ListComponentSkeleton from "./components/ListComponentSkeleton";
// import useStore from "../../../zustand";
// import ActivitySidebar from './components/ActivitySidebar';
const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);
// const theme = createTheme();

// theme.typography.h1 = {
//   fontSize: "32px",
//   "@media (max-width:1000px)": {
//     fontSize: "32px",
//   },
//   "@media (max-width:800px)": {
//     fontSize: "28px",
//   },
//   "@media (max-width:660px)": {
//     fontSize: "22px",
//   },
// };

export const ActivitiesPage = () => {
  const matches = useMediaQuery("(min-width:1200px)");
  const matchesTwo = useMediaQuery("(min-width:1200px)");
  const matchesThree = useMediaQuery("(min-width:1100px)");
  const classes = { ...useStyles(), ...useStylesCardDeck() };
  const stockData = [
    {
      title: `Things need doing`,
      stage: "1",
      completion: "25",
      currentTask: "Computer Thinking",
      date: "Due This Thursday, 12th June",
      bgC: "#E7E3F9",
    },
    {
      title: `Tasks completed`,
      stage: "7",
      completion: "100",
      currentTask: "Majority are comfortable",
      date: "Majority are comfortable",
      bgC: "#FEF3E2",
    },
    {
      title: `Awards earned this week`,
      stage: "5",
      completion: "25",
      currentTask: `You've unlocked 10 day streak`,
      date: "James needs a challenge",
      bgC: "#E0F2E3",
    },
  ];

  const { fetchAllH5PQuestions } = useCurriculumApi();
  // const { status, data, isLoading } = useQuery(
  //   ["fetchAllH5PQuestions"],
  //   () => fetchAllH5PQuestions(),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );
  // console.log("data fetchAllH5PQuestions:>> ", data && data.data);
  const [randNum, setRandNum] = useState(0);
  useEffect(() => {
    setRandNum(Math.floor(Math.random() * 20) + 1);
  }, []);

  return (
    <Box>
      {/* <ActivitySidebar /> */}

      <Box sx={{ maxWidth: "1280px", margin: "auto", width: "95%" }}>
        <Container
          maxWidth={false}
          component={Box}
          marginTop="50px"
          padding="20px"
          classes={{ root: classes.containerRoot }}
          sx={{ display: "none" }}
        >
          {/* header: student name / greeting / school / online students display */}
          <ActivityHeader />

          {/* header: student name / greeting / school / online students display */}
          <div className="activitiesTasks">
            <HomeStatusCards />
          </div>
          <Box
            style={{
              display: "flex",
              flexDirection: `${matchesTwo ? "row" : "column"}`,
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            {/* Recent Activity: minimized display */}
            <RecentActivity matchesTwo={matchesTwo} matchesThree={matchesThree} homepage={true} />

            {/* Recent Activity: to do list: minimized display */}

            <ToDoHomePage matchesTwo={matchesTwo} matchesThree={matchesThree} homepage={true} />
          </Box>

          <YourAwards />
        </Container>
      </Box>
    </Box>
  );
};

export default ActivitiesPage;
