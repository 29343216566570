import React from "react";
// @material-ui/core components
import usePersistStore from "../../../zustandPersist.js";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import CurriculumCard from "components/Cards/eai/CurriculumCard";
import { CurriculumSection } from "./Components/CurriculumSection";
import { LoadingScreen } from "./Components/LoadingScreen";
import { ErrorScreen } from "./Components/ErrorScreen";
import { CurriculumPage } from "./CurriculumPage";
import useCurriculumApi from "api/curriculum";

function OnlineLevelsPage() {
  const params = useParams();
  const { fetchOnlineLevels } = useCurriculumApi();
  const { isLoading, isError, data, error, status } = useQuery(
    ["onlineLevels", params.courseId],
    () => fetchOnlineLevels(params.courseId),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { permission } = usePersistStore();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <ErrorScreen error={error} />;
  }
  const sections = [];
  for (const level of data.data) {
    const cards = [];
    for (const lesson of level.lessons) {
      cards.push(
        <CurriculumCard
          title={lesson.name}
          // subtitle={resource.type.name}
          id={lesson?.id}
          entityType="lesson"
          image={lesson.image}
          lessonList={level}
          link={`/curriculum/lessons/${lesson?.id}/resources`}
          blur="0"
          arrayTitle="Learning Objectives:"
        />
      );
    }
    if (true) {
      sections.push(
        <CurriculumSection key={level?.id} name={level.name} cards={cards} />
      );
    }
  }
  return <CurriculumPage name="Online Levels" sections={sections} />;
}

export default OnlineLevelsPage;
