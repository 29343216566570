import React from "react";
import useStore from "../../../zustand";
import ActivityFebH5PComItem from "./ActivityFebH5PComItem";

const ActivityFebH5PComponents = ({ quesNum, isEndOfQuestions }) => {
  const { activityBatchQuestions } = useStore();

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {isEndOfQuestions && activityBatchQuestions.length >= 1 && (
        <ActivityFebH5PComItem
          num={1}
          questionAct={activityBatchQuestions[0]}
        />
      )}
      <div
        style={{
          display: 1 === quesNum || isEndOfQuestions ? "block" : "none",
        }}
        id={`h5pcomp-1`}
      ></div>
      {isEndOfQuestions && <hr style={{ margin: "10px" }} />}
      {isEndOfQuestions && activityBatchQuestions.length >= 2 && (
        <ActivityFebH5PComItem
          num={2}
          questionAct={activityBatchQuestions[1]}
        />
      )}
      <div
        style={{
          display: 2 === quesNum || isEndOfQuestions ? "block" : "none",
        }}
        id={`h5pcomp-2`}
      ></div>
      {isEndOfQuestions && <hr style={{ margin: "10px" }} />}
      {isEndOfQuestions && activityBatchQuestions.length >= 3 && (
        <ActivityFebH5PComItem
          num={3}
          questionAct={activityBatchQuestions[2]}
        />
      )}
      <div
        style={{
          display: 3 === quesNum || isEndOfQuestions ? "block" : "none",
        }}
        id={`h5pcomp-3`}
      ></div>
      {isEndOfQuestions && <hr style={{ margin: "10px" }} />}
      {isEndOfQuestions && activityBatchQuestions.length >= 4 && (
        <ActivityFebH5PComItem
          num={4}
          questionAct={activityBatchQuestions[3]}
        />
      )}
      <div
        style={{
          display: 4 === quesNum || isEndOfQuestions ? "block" : "none",
        }}
        id={`h5pcomp-4`}
      ></div>
      {isEndOfQuestions && <hr style={{ margin: "10px" }} />}
      {isEndOfQuestions && activityBatchQuestions.length >= 5 && (
        <ActivityFebH5PComItem
          num={5}
          questionAct={activityBatchQuestions[4]}
        />
      )}
      <div
        style={{
          display: 5 === quesNum || isEndOfQuestions ? "block" : "none",
        }}
        id={`h5pcomp-5`}
      ></div>
      {isEndOfQuestions && <hr style={{ margin: "10px" }} />}
      {isEndOfQuestions && activityBatchQuestions.length >= 6 && (
        <ActivityFebH5PComItem
          num={6}
          questionAct={activityBatchQuestions[5]}
        />
      )}
      <div
        style={{
          display: 6 === quesNum || isEndOfQuestions ? "block" : "none",
        }}
        id={`h5pcomp-6`}
      ></div>
      {isEndOfQuestions && <hr style={{ margin: "10px" }} />}
      {isEndOfQuestions && activityBatchQuestions.length >= 7 && (
        <ActivityFebH5PComItem
          num={7}
          questionAct={activityBatchQuestions[6]}
        />
      )}
      <div
        style={{
          display: 7 === quesNum || isEndOfQuestions ? "block" : "none",
        }}
        id={`h5pcomp-7`}
      ></div>
      {isEndOfQuestions && <hr style={{ margin: "10px" }} />}
      {isEndOfQuestions && activityBatchQuestions.length >= 8 && (
        <ActivityFebH5PComItem
          num={8}
          questionAct={activityBatchQuestions[7]}
        />
      )}
      <div
        style={{
          display: 8 === quesNum || isEndOfQuestions ? "block" : "none",
        }}
        id={`h5pcomp-8`}
      ></div>
      {isEndOfQuestions && <hr style={{ margin: "10px" }} />}
      {isEndOfQuestions && activityBatchQuestions.length >= 9 && (
        <ActivityFebH5PComItem
          num={9}
          questionAct={activityBatchQuestions[8]}
        />
      )}
      <div
        style={{
          display: 9 === quesNum || isEndOfQuestions ? "block" : "none",
        }}
        id={`h5pcomp-9`}
      ></div>
      {isEndOfQuestions && <hr style={{ margin: "10px" }} />}
      {isEndOfQuestions && activityBatchQuestions.length >= 10 && (
        <ActivityFebH5PComItem
          num={10}
          questionAct={activityBatchQuestions[9]}
        />
      )}
      <div
        style={{
          display: 10 === quesNum || isEndOfQuestions ? "block" : "none",
        }}
        id={`h5pcomp-10`}
      ></div>
      {isEndOfQuestions && <hr style={{ margin: "10px" }} />}
      {isEndOfQuestions && activityBatchQuestions.length >= 11 && (
        <ActivityFebH5PComItem
          num={11}
          questionAct={activityBatchQuestions[10]}
        />
      )}
      <div
        style={{
          display: 11 === quesNum || isEndOfQuestions ? "block" : "none",
        }}
        id={`h5pcomp-11`}
      ></div>
      {isEndOfQuestions && <hr style={{ margin: "10px" }} />}
      {isEndOfQuestions && activityBatchQuestions.length >= 12 && (
        <ActivityFebH5PComItem
          num={12}
          questionAct={activityBatchQuestions[11]}
        />
      )}
      <div
        style={{
          display: 12 === quesNum || isEndOfQuestions ? "block" : "none",
        }}
        id={`h5pcomp-12`}
      ></div>
      {isEndOfQuestions && <hr style={{ margin: "10px" }} />}
      {isEndOfQuestions && activityBatchQuestions.length >= 13 && (
        <ActivityFebH5PComItem
          num={13}
          questionAct={activityBatchQuestions[12]}
        />
      )}
      <div
        style={{
          display: 13 === quesNum || isEndOfQuestions ? "block" : "none",
        }}
        id={`h5pcomp-13`}
      ></div>
      {isEndOfQuestions && <hr style={{ margin: "10px" }} />}
      {isEndOfQuestions && activityBatchQuestions.length >= 14 && (
        <ActivityFebH5PComItem
          num={14}
          questionAct={activityBatchQuestions[13]}
        />
      )}
      <div
        style={{
          display: 14 === quesNum || isEndOfQuestions ? "block" : "none",
        }}
        id={`h5pcomp-14`}
      ></div>
      {/* {isEndOfQuestions && <hr style={{ margin: "10px" }} />}
      {isEndOfQuestions && <h2 style={{ margin: "10px" }}>Q15</h2>}
      <div
        style={{
          display: 15 === quesNum || isEndOfQuestions ? "block" : "none",
        }}
        id={`h5pcomp-15`}
      ></div> */}
    </div>
  );
};

export default ActivityFebH5PComponents;
