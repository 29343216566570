import useCurriculumApi from "api/curriculum";
import React, { useEffect, useState } from "react";
// import { useState } from "react";
import { useQuery } from "react-query";
import LessonPreviewPlayer from "./LessonPreviewPlayer";

const LessonPreview = ({
  editedClassLevel,
  selectedLessonId,
  setSelectedLessonId,
}) => {
  const [selectedLessonName, setSelectedLessonName] = useState("");
  const { fetchLessons } = useCurriculumApi();
  const {
    isLoading,
    data: lessonData,
    status: lessonStatus,
  } = useQuery(
    ["fetchLessons", editedClassLevel],
    () => fetchLessons(editedClassLevel),
    {
      refetchOnWindowFocus: false,
    }
  );
  console.log(
    "lessonData && lessonData.data :>> ",
    lessonData && lessonData.data
  );
  console.log("editedClassLevel :>> ", editedClassLevel);
  useEffect(() => {
    if (lessonData && lessonData?.data) {
      setSelectedLessonId(lessonData?.data[0]?.id);
      setSelectedLessonName(lessonData?.data[0]?.name);
    }
  }, [lessonData]);

  return (
    <div
      style={{
        boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
        borderRadius: "12px",
        overflow: "hidden",
        border: "4px solid rgb(6, 101, 157)",
        // flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "5px 20px",
          justifyContent: "center",
          //   borderRadius: "12px",
          background: "rgb(6, 101, 157)",
          color: "white",
          fontWeight: "600",
          fontSize: "18px",
        }}
      >
        Preview only
      </div>
      <div
        style={{
          display: "flex",
          //   gap: "10px",
          background: "rgb(6, 101, 157)",
        }}
      >
        {isLoading ? (
          <div
            style={{
              // display: "gird",
              // gridTemplateColumns: "1fr 1fr 1fr 1fr",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              // padding: "2px",
              // justifyContent: "space-between",
              borderRadius: "6px",
              overflow: "hidden",
              padding: "5px",
              background: "white",
              margin: "5px",
              width: "230px",
            }}
          ></div>
        ) : (
          <div
            style={{
              // display: "gird",
              // gridTemplateColumns: "1fr 1fr 1fr 1fr",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              // padding: "2px",
              // justifyContent: "space-between",
              borderRadius: "6px",
              overflow: "hidden",
              padding: "5px",
              background: "white",
              margin: "5px",
              // width: "20%",
            }}
          >
            {lessonData &&
              lessonData?.data.map((lesson, index) => (
                <div
                  style={{
                    display: "flex",
                    border: "2px solid rgba(0,0,0,0.2)",
                    boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
                    borderRadius: "4px",
                    padding: "2px 10px",
                    cursor: "pointer",
                    background:
                      lesson.id == selectedLessonId ? "rgb(6, 101, 157)" : "",
                    color: lesson.id == selectedLessonId ? "white" : "",
                  }}
                  onClick={() => {
                    setSelectedLessonId(lesson.id);
                    setSelectedLessonName(lesson.name);
                  }}
                >
                  <p
                    style={{
                      margin: "0",
                      padding: "0",
                      fontSize: "12px",
                    }}
                  >
                    <strong>{index + 1}. </strong> {lesson.name}
                  </p>
                </div>
              ))}
          </div>
        )}
        <div
          style={{
            background: "rgb(6, 101, 157)",
            width: "80%",
            maxHeight: "450px",
            height: "450px",
            padding: "5px",
          }}
        >
          <LessonPreviewPlayer
            exerciseName={selectedLessonName}
            lessonId={selectedLessonId}
            selectedLessonId={selectedLessonId}
          />
        </div>
      </div>
    </div>
  );
};

export default LessonPreview;
