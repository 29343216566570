import React from "react";

const StatsTabComponent = ({ icon, title, data, dataTwo }) => {
  return (
    <div
      style={{
        minWidth: "320px",
        background: "white",
        color: "white",
        // padding: "14px 28px",
        overflow: "hidden",
        borderRadius: "8px",
        textAlign: "center",
        boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
      }}
    >
      <div
        style={{
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
          // background: "rgb(31, 110, 163)",
          // color: "white",
          padding: "10px",
          background: "rgba(31, 110, 163,0.15)",
          color: "rgb(31, 110, 163)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "95%",
          }}
        >
          <h4
            style={{
              // color: "white",
              color: "rgb(31, 110, 163)",
              margin: "0",
              fontSize: "14px",
              fontWeight: "700",
              padding: "0 10px 0",
            }}
          >
            {icon}
          </h4>
          <h5
            style={{
              // color: "white",
              color: "rgb(31, 110, 163)",
              margin: "0",
              fontWeight: "600",
              padding: "0 10px 0",
              // fontWeight: "500",
              fontSize: "1rem",
            }}
          >
            {title}
          </h5>
        </div>
      </div>
      <h5
        style={{
          fontSize: "28px",
          color: "rgb(31, 110, 163)",
          padding: "14px 5px",
          margin: "0",
        }}
      >
        {dataTwo ? (
          <>
            {data}/{dataTwo}
          </>
        ) : (
          data
        )}
      </h5>
    </div>
  );
};

export default StatsTabComponent;
