import React from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@material-ui/core";
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { IconButton } from '@mui/material';
import CustomList from "./CustomList";
// import FormGroup from "@material-ui/core/FormGroup";


function TransferList({
  checked,
  numberOfChecked,
  leftChecked,
  rightChecked,
  handleToggle,
  handleToggleAllCheck,
  handleToggleAllUncheck,
  left,
  right,
  handleCheckedLeft,
  handleCheckedRight,
  titleAll,
  titleSeleceted,
  bodyText
}) {


  return (
    <Grid container spacing={1} justifyContent="space-between" alignItems="center">

      {/* List of teachers/students outside class */}
      <Grid item xs={12} sm={5.5}>
        <CustomList
          title={titleAll}
          data={left}
          handleToggleAllCheck={handleToggleAllCheck}
          handleToggleAllUncheck={handleToggleAllUncheck}
          numberOfChecked={numberOfChecked}
          handleToggle={handleToggle}
          checked={checked}
          bodyText=''
        />
      </Grid>

      {/* Arrow buttons between lists */}
      <Grid item xs={12} sm={1}>
        <Box container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <IconButton
            style={{ margin: '10px 0px', width: 36, height: 36 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            aria-label="move selected right"
            disabled={leftChecked.length === 0}
          >
            <ArrowCircleRightOutlinedIcon
              style={{
                width: 36, height: 36, color: '#06659D',
                opacity: leftChecked.length === 0 && 0.25,
              }} />
          </IconButton>
          <IconButton
            style={{ width: 36, height: 36 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            <ArrowCircleLeftOutlinedIcon style={{
              width: 36, height: 36, color: '#06659D', opacity: rightChecked.length === 0 && 0.25,
            }} />
          </IconButton>
        </Box>
      </Grid>

      {/* List of teachers/students in class */}
      <Grid item xs={12} sm={5.5}>
        <CustomList
          title={titleSeleceted}
          data={right}
          handleToggleAllCheck={handleToggleAllCheck}
          handleToggleAllUncheck={handleToggleAllUncheck}
          numberOfChecked={numberOfChecked}
          handleToggle={handleToggle}
          checked={checked}
          bodyText={bodyText}
        />
      </Grid>
    </Grid>
  );
}


export default TransferList


