import React from "react";
import useStore from "../../../../zustand";
import { useQuery } from "react-query";
import useUserApi from "api/user";
// import ActivityCardItem from "./components/ActivityCardItem";
import NextActivity from "../HomePageComponents/NextActivity";
import StudentPageHeader from "../HomePageComponents/StudentPageHeader";
import { ActivityPageWrapper } from "./ActivityPage.styles";

const ActivityPage = () => {
  const { selectedClassId, setSelectedClassId } = useStore();

  const { fetchActivityTask } = useUserApi();

  const { isLoading, isError, data, error } = useQuery(
    ["todoTasks", selectedClassId],
    () => fetchActivityTask(selectedClassId),
    { enabled: selectedClassId != null, refetchOnWindowFocus: false }
  );
  console.log("data && data.data :>> ", data && data.data.todo.tasks);
  return (
    <div
      style={{
        position: "relative",
        minHeight: "100vh",
        overflowX: "hidden",
      }}
    >
      <div style={{ padding: "40px 40px", maxWidth: "1340px", margin: "auto" }}>
        <StudentPageHeader title={"Activities"} />

        <ActivityPageWrapper>
          {data && data.data.todo?.tasks.map((item) => <NextActivity item={item} />)}
        </ActivityPageWrapper>
      </div>
    </div>
  );
};

export default ActivityPage;
