import styled from "styled-components";

export const ClassCardsWrapper = styled.div`
  display: flex;
  gap: 10px;

  .ClassCards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    .item-activity-card:nth-child(1) {
      grid-column: span 3;
    }
    .item-activity-card:nth-child(2) {
      grid-column: span 3;
    }
    .item-activity-card:nth-child(3) {
      grid-column: span 2;
      width: 260px;
      margin: auto;
    }
    .item-activity-card:nth-child(4) {
      grid-column: span 4;
    }

    gap: 25px;
    /* width: 95%; */
    .stacked {
      position: relative;
      width: 200px;
      height: 200px;
    }
    .stacked .back {
      position: absolute;

      width: 200px;
      height: 200px;
      background-color: #d25e78;
      border-radius: 12px;
      border-radius: 20px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    .stacked .mid {
      position: absolute;
      left: 10px;
      top: -10px;
      width: 200px;
      height: 200px;
      background-color: #e49eae;
      border-radius: 12px;
      border-radius: 20px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    .stacked .card-item {
      position: absolute;
      left: 20px;
      top: -20px;
      width: 200px;
      cursor: pointer;
      height: 200px;
    }

    .card-item {
      cursor: pointer;
      width: 200px;
      height: 200px;
      background-color: #fff3f6;
      border-radius: 12px;
      border-radius: 20px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: end;
    }

    .card-item h1 {
      /* font-family: Geometreg; */
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0;
    }
    .card-item p {
      /* font-family: Geometreg; */
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      min-height: 40px;
    }
  }
  .item-activity-card .radialProgressBar {
    position: absolute;
    border-radius: 100000px;
    /* -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%); */
    width: 650px;
    height: 650px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #f1f1f1;
    /* margin: 20px; */
    top: -550px;
    float: left;
  }
  .item-activity-card .radialProgressBar .overlay {
    border-radius: 100000px;
    width: 640px;
    height: 640px;
    overflow: hidden;
    margin: auto;
    background: #f6dde3;
    text-align: center;
    display: flex;
    align-items: end;
    justify-content: center;
    /* padding-top: 30px; */
  }
  .item-activity-card .progress-20 {
    background-image: -webkit-linear-gradient(72deg, #f1f1f1 50%, transparent 50%),
      -webkit-linear-gradient(left, #6b479c 50%, #f1f1f1 50%);
    background-image: linear-gradient(180deg, #f1f1f1 50%, transparent 50%),
      linear-gradient(100deg, #6b479c 50%, #f1f1f1 50%);
  }

  .ClassCards .reduce-btn {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #d25e78;
    border: none;
    border-radius: 12px;
    padding: 10px;
    margin-left: 10px;
    color: white;
    font-weight: 700;
  }
  @media (max-width: 1380px) {
    .ClassCards {
      grid-template-columns: repeat(7, 1fr);

      .item-activity-card:nth-child(1) {
        grid-column: span 1;
      }
      .item-activity-card:nth-child(2) {
        grid-column: span 1;
      }
      .item-activity-card:nth-child(3) {
        grid-column: span 5;
      }
    }
  }
  @media (max-width: 955px) {
    .ClassCards {
      grid-template-columns: repeat(5, 1fr);

      .item-activity-card:nth-child(1) {
        grid-column: span 3;
      }
      .item-activity-card:nth-child(2) {
        grid-column: span 2;
      }
      .item-activity-card:nth-child(3) {
        grid-column: span 5;
      }
    }
  }
  @media (max-width: 600px) {
    .ClassCards {
      grid-template-columns: repeat(1, 1fr);

      .item-activity-card:nth-child(1) {
        grid-column: span 1;
      }
      .item-activity-card:nth-child(2) {
        grid-column: span 1;
      }
      .item-activity-card:nth-child(3) {
        grid-column: span 1;
      }
    }
  }
`;
