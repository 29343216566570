import useCurriculumApi from "api/curriculum";
import React from "react";
import { useQuery } from "react-query";

const ClassLevel = ({ dataLevel }) => {
  const { fetchAvailableLevels } = useCurriculumApi();
  const { data: availableLevels, status: availableLevelsStatus } = useQuery(
    ["available_levels"],
    () => fetchAvailableLevels(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const currentLevel =
    availableLevelsStatus === "success" &&
    availableLevels.data.find((item) => item.id == dataLevel);

  if (currentLevel?.name) {
    // console.log("currentLevel.name:", currentLevel.name);
    return <p style={{ margin: "0 0 0 10px" }}>{currentLevel.name}</p>;
  } else {
    return <p style={{ margin: "0 0 0 10px" }}>Loading..</p>;
  }
};

export default ClassLevel;
