import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons components
import componentStyles from "assets/theme/views/admin/dashboard.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
// import Iframe from "react-iframe";
import { Box, Container } from "@material-ui/core";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';



const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

function ErrorPage() {
    const classes = { ...useStyles(), ...useStylesCardDeck() };


    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop="0rem"
                padding="20px"
                height="80vh"
                classes={{ root: classes.containerRoot }}
                style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '2.3rem', display: 'flex', alignItems: 'center', }}> <ErrorOutlineIcon style={{ fontSize: '50px', color: '#f5365c', marginRight: 5 }} /> Access Denied</h1>
                    <p1 style={{ fontSize: 18, paddingLeft: 10, maxWidth: 550, textAlign: 'center' }}>Unfortunately you do not have access to this page.</p1>
                    <p1 style={{ fontSize: 18, paddingLeft: 10, maxWidth: 350, textAlign: 'center' }}>Please click <a href="mailto:contactus@educationai.co.uk">here</a> to contact us for more details.</p1>
                </div>
            </Container>
        </>
    );
}

export default ErrorPage;
