import React from "react";

const H5PResults = ({ activityResultsArray }) => {
  const totalScore = activityResultsArray.reduce((accumulator, object) => {
    return accumulator + object.user_scores;
  }, 0);

  const maxScore = activityResultsArray.reduce((accumulator, object) => {
    return accumulator + object.max_scores;
  }, 0);

  return (
    <div
      style={{
        // background: "white",
        padding: "40px",
        maxWidth: "1280px",
        width: "95%",
        margin: "auto ",
      }}
    >
      <div
        className=""
        style={{
          // padding: "20px",
          overflow: "hidden",
          borderRadius: "20px",
          background: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          transition: "height 0.5s ease-in-out",
          justifyContent: "center",
          boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 3fr  1fr 1fr",
            width: "100%",
            background: "#6357A5",
            color: "white",
            padding: "12px 0 6px",
            borderRadius: "20px",

            // boxShadow:
            //   " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            // textAlign: "left",
          }}
        >
          <h5
            style={{
              textAlign: "center",
              color: "white",
            }}
          >
            Question
          </h5>
          <h5
            style={{
              color: "white",
            }}
          >
            Topic
          </h5>
          <h5 style={{ textAlign: "center", color: "white" }}>Score</h5>
          <h5 style={{ textAlign: "center", color: "white" }}>Percentage</h5>
        </div>
        <div
          style={{
            padding: "10px 0",
            width: "100%",

            display: "grid",
            gridTemplateColumns: "1fr 3fr  1fr 1fr",
            // boxShadow:
            //   " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          }}
        >
          {activityResultsArray.map((res, index) => (
            <>
              <p style={{ textAlign: "center", fontWeight: 500 }}>
                {index + 1}
              </p>
              <p style={{ fontWeight: 500 }}>{res.name}</p>
              <p style={{ textAlign: "center", fontWeight: 500 }}>
                {res.user_scores}/{res.max_scores}
              </p>
              <p style={{ textAlign: "center", fontWeight: 500 }}>
                {Math.floor((res.user_scores / res.max_scores) * 100)}%
              </p>
            </>
          ))}
        </div>
        {totalScore && maxScore ? (
          <div
            style={{
              padding: "10px 0 0",
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr 3fr  1fr 1fr",
              background: "rgba(99,87,165,0.3)",
              // color: "white",
              // borderRadius: "20px",
              // boxShadow:
              //   " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            }}
          >
            <h5></h5>
            <h4 style={{ fontWeight: 500 }}>Results: </h4>
            <p style={{ textAlign: "center", fontWeight: 500 }}></p>
            <p style={{ textAlign: "center", fontWeight: 500 }}>
              {totalScore && maxScore
                ? `${Math.round((totalScore / maxScore) * 100)}%`
                : null}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default H5PResults;
