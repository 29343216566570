import React from "react";
import { useQuery } from "react-query";
import useStore from "zustand";
import useUserApi from "api/user";
import StatsTabComponent from "./StatsTabComponent";
import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";

import SchoolStatsSkeleton from "./skeletons/SchoolStatsSkeleton";
import DashboardEmptyState from "./DashboardEmptyState";
import EqualizerIcon from "@mui/icons-material/Equalizer";
// import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
// import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
// import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
// import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
// import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
const SchoolStatsComponent = () => {
  const { userId } = useStore();
  const { fetchSchoolInfo, saveSelectedData, fetchSelectedData } = useUserApi();

  const {
    data: schoolData,
    status: schoolDataStatus,
    isLoading: isLoadingSchoolData,
  } = useQuery("school", () => fetchSchoolInfo(), {
    refetchOnWindowFocus: false,
  });
  const Header = () => {
    return (
      <div
        style={{
          margin: "50px 0 30px",
        }}
      >
        <h3
          style={{
            fontSize: "24px",
            textTransform: "none",
            fontFamily: "Open Sans",
            color: "#7B8794",
            fontWeight: "400",
            margin: "0",
          }}
        >
          School Statistics
        </h3>
      </div>
    );
  };
  if (isLoadingSchoolData) {
    return (
      <>
        <Header />
        <SchoolStatsSkeleton />
      </>
    );
  }
  const Icon = (
    <EqualizerIcon
      style={{
        width: "80px",
        height: "80px",
        color: "#f8f9fe",
        backgroundColor: "#bdbdbd",
        padding: "1.2rem",
        borderRadius: "50%",
        marginBottom: "1rem",
      }}
    />
  );

  //   console.log("schoolStatus", schoolDataStatus);
  //   console.log('schoolData.data', schoolData.data)
  //   console.log('schoolData.data.length', schoolData.data.length)

  // console.log("schoolStatus", schoolDataStatus);
  // if (schoolData) {
  // console.log("school", schoolData.data);
  // TODO: What do we get back when there is no schoolData - Q for aric

  if (schoolDataStatus === "success" && !schoolData.data) {
    return (
      <DashboardEmptyState
        icon={Icon}
        title="Track school’s performance"
        text="No available stats to show"
      />
    );
  }

  if (schoolDataStatus === "success" && schoolData.data) {
    // console.log("school", schoolData.data);
    return (
      <div>
        <div
          style={{
            margin: "50px 0 30px",
          }}
        >
          <h3
            style={{
              fontSize: "24px",
              textTransform: "none",
              fontFamily: "Open Sans",
              color: "#7B8794",
              fontWeight: "400",
              margin: "0",
              //   display: "flex",
              //   justifyContent: "end",
              //   alignItems: "end",
            }}
          >
            School Statistics
          </h3>
        </div>
        <div
          style={{
            display: "flex",
            // gridTemplateColumns: "1fr 1fr 1fr ",
            gap: "20px",
            padding: "30px 30px",
            border: "0px solid rgb(31, 110, 163)",
            borderRadius: "8px",
            flexWrap: "wrap",
            justifyContent: "space-between",
            // boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
            boxShadow: "0 5px 15px hsla(0,0%, 0%, .2)",
            // background: "white",
            // background: "rgb(255, 255, 255,0.5)",
            background: "rgb(255, 255, 255,1)",

            // alignItems: "center",
          }}
        >
          <StatsTabComponent
            icon={<ClassOutlinedIcon />}
            title={"Class count"}
            data={schoolData?.data.class_count}
          />
          {/* <StatsTabComponent
            icon={<CodeOutlinedIcon />}
            title={"code spaces created"}
            data={schoolData?.data.code_spaces_created}
          />
          <StatsTabComponent
            icon={<ThumbUpOutlinedIcon />}
            title={"logins count"}
            data={schoolData?.data.logins_count}
          />
          <StatsTabComponent
            icon={<CheckCircleOutlineOutlinedIcon />}
            title={"Questions answered"}
            data={schoolData?.data.questions_answered}
          />
          <StatsTabComponent
            icon={<PublishOutlinedIcon />}
            title={"Submissions count"}
            data={schoolData?.data.submissions_count}
          /> */}

          <StatsTabComponent
            icon={<AccountBoxOutlinedIcon />}
            title={"Teacher count"}
            data={schoolData?.data.teacher_count}
          />
          <StatsTabComponent
            icon={<SchoolOutlinedIcon />}
            title={"Student count"}
            data={schoolData?.data.student_count}
          />
          {/* <StatsTabComponent
            icon={<TimelineOutlinedIcon />}
            title={"learning objectives"}
            data={schoolData?.data.learning_objectives_covered?.covered}
            dataTwo={schoolData?.data.learning_objectives_covered?.total}
          />
          <StatsTabComponent
            icon={<AccessTimeIcon />}
            title={"Average time on site"}
            data={schoolData?.data.time_on_site}
          /> */}

          {/* <StatsTabComponent
            title={""}
            data={}
          /> */}

          {/* <div>{schoolData?.data.learning_objectives_covered}</div> */}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default SchoolStatsComponent;
