import React from "react";
import { Link } from "react-router-dom";

const CurriculumCard = ({ cat }) => {
  if (cat.name === "Computational Thinking") {
    return (
      <>
        {/* Book */}
        <Link
          to={{
            pathname: `/curriculum/categories/${cat?.id}/courses/`,
            state: { from: "book" },
          }}
          style={{
            width: "350px",
            background: "rgba(31, 110, 163,0.05)",
            color: "white",
            padding: "14px ",
            borderRadius: "8px",
            boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
            display: "flex",
            cursor: "pointer",
          }}
        >
          <img
            src={cat.image}
            alt=""
            srcset=""
            style={{
              // width: "130px",
              height: "80px",
              // maxWidth: "79px",
              borderRadius: "12px",
              marginRight: "8px",
            }}
          />
          <h5
            style={{
              fontSize: "14px",
              padding: "12px 6px",
            }}
          >
            {cat.name} - Book
          </h5>
        </Link>

        {/* Online Lessons */}
        <Link
          to={{
            pathname: `/curriculum/categories/${cat?.id}/courses/`,
            state: { from: "onlineLessons" },
          }}
          style={{
            width: "350px",
            background: "rgba(31, 110, 163,0.05)",
            color: "white",
            padding: "14px ",
            borderRadius: "8px",
            boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
          }}
        >
          <img
            src={cat.image}
            alt=""
            srcset=""
            style={{
              // width: "130px",
              height: "80px",
              // maxWidth: "79px",
              borderRadius: "12px",
              marginRight: "8px",
            }}
          />
          <h5
            style={{
              fontSize: "14px",
              padding: "12px 6px",
            }}
          >
            {cat.name} - Lessons
            {/* online */}
          </h5>
        </Link>
      </>
    );
  } else {
    return (
      <>
        {cat.name !== "Raspberry Pi" && (
          <Link
            to={`/curriculum/categories/${cat?.id}/courses/`}
            style={{
              width: "350px",
              background: "rgba(31, 110, 163,0.05)",
              color: "white",
              padding: "14px ",
              borderRadius: "8px",
              boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            <img
              src={cat.image}
              alt=""
              srcset=""
              style={{
                width: "152px",
                height: "80px",
                // maxWidth: "79px",
                borderRadius: "12px",
                marginRight: "8px",
              }}
            />
            <h5
              style={{
                fontSize: "14px",
                padding: "12px 6px",
              }}
            >
              {cat.name}
            </h5>
          </Link>
        )}
      </>
    );
  }
};

export default CurriculumCard;
