import React, { useEffect, useState } from "react";
import { H5P as H5PStandalone } from "h5p-standalone";
import ActivityFebH5PComponents from "./ActivityFebH5PComponents";
import ActivityFebPlayerBtn from "./ActivityFebPlayerBtn";
import ActivityPlayerTestCompHeader from "./ActivityPlayerTestCompHeader";
import H5PResults from "./H5PResults";
const ActivityFebTestingPlayer = ({
  questionsArr,
  quesNum,
  setQuesNum,
  numberOfQuestions,
  setIsEndOfQuestions,
  checkIfCompleteQuestion,
  isEndOfQuestions,
  setIsStarted,
  isStarted,
}) => {
  const [tempQuesNum, setTempQuesNum] = useState(null);
  const [btnDisableUntilAnswered, setBtnDisableUntilAnswered] = useState(true);
  const [activityResults, setActivityResults] = useState({});
  const [activityResultsArray, setActivityResultsArray] = useState([]);
  const [tempCheckResults, setTempCheckResults] = useState(null);

  // console.log("questionsArr", questionsArr);
  // console.log("questionsArr", questionsArr);
  // console.log("questionsArr", questionsArr);
  // console.log("questionsArr[1]", questionsArr[1].foldername);
  useEffect(() => {
    if (isStarted && quesNum > 0 && !isEndOfQuestions) {
      if (tempQuesNum !== quesNum) {
        setTempQuesNum(quesNum);
        // console.log("]]]]]]]]]]]]]]]]]]]]]]]]]]]]");
        // console.log("numberOfQuestions", numberOfQuestions);
        // console.log("quesNum :>> ", quesNum);
        // console.log(
        //   "${questionsArr[quesNum-1].foldername}",
        //   questionsArr[quesNum - 1].foldername
        // );
        // console.log("]]]]]]]]]]]]]]]]]]]]]]]]]]]]");

        let options = {
          h5pJsonPath: `/h5pextracted/${questionsArr[quesNum - 1].foldername}`,
          frameJs: "/assets/frame.bundle.js",
          frameCss: "/assets/styles/h5p.css",
          customCss: "/assets/styles/multichoice.css",
        };
        new H5PStandalone(
          document.getElementById(`h5pcomp-${quesNum}`),
          options
        ).then(function () {
          window.H5P.externalDispatcher.on("xAPI", async (event) => {
            if (event.data.statement.result) {
              setBtnDisableUntilAnswered(true);

              if (tempCheckResults !== event.data.statement) {
                setTempCheckResults(event.data.statement);
                setActivityResults({
                  name: event.data.statement.object.definition.name["en-US"],
                  user_scores: event.data.statement.result.score.raw,
                  max_scores: event.data.statement.result.score.max,
                });
              }
              // if (numberOfQuestions === quesNum) {
              //   handleClickAddArray();
              //   setIsEndOfQuestions(true);
              // }
              // console.log("ppppppkjfjldjfdkfkljflskdjflksdjflkjdflk");
            }
          });
        });
      }
    }
  }, [quesNum]);
  const handleClickAddArray = () => {
    // console.log("activityResults :>> ", activityResults);
    // console.log("activityResults :>> ", activityResults);
    if (quesNum !== 0) {
      setActivityResultsArray((prev) => [...prev, activityResults]);
    }
  };
  return (
    <div
      style={{
        width: "95%",
        minHeight: "300px",
        display: "flex",
        flexDirection: "column",
        // width: "60%",
        alignItems: "center",
        // justifyContent: "Center",
        margin: "30px auto 30px",
        borderRadius: "20px",
        transition: "height 0.5s ease-in-out",
      }}
    >
      {quesNum === 0 && !isEndOfQuestions && !isStarted && (
        <h1>Start Activity</h1>
      )}
      {quesNum > 0 && (
        <ActivityPlayerTestCompHeader
          selectedQuestionName={questionsArr[quesNum - 1].name}
          selectedQuestionNumber={questionsArr[quesNum - 1].Number}
          selectedQuestionFolderName={questionsArr[quesNum - 1].foldername}
          selectedQuestionGUID={questionsArr[quesNum - 1].id}
          selectedQuestionFileName={questionsArr[quesNum - 1].filename}
        />
      )}

      {isEndOfQuestions && (
        <div>
          <H5PResults activityResultsArray={activityResultsArray} />
          <hr style={{ margin: "10px" }} />
        </div>
      )}

      <ActivityFebH5PComponents
        quesNum={quesNum}
        isEndOfQuestions={isEndOfQuestions}
      />
      <ActivityFebPlayerBtn
        quesNum={quesNum}
        numberOfQuestions={numberOfQuestions}
        isEndOfQuestions={isEndOfQuestions}
        isStarted={isStarted}
        setQuesNum={setQuesNum}
        setBtnDisableUntilAnswered={setBtnDisableUntilAnswered}
        setIsStarted={setIsStarted}
        btnDisableUntilAnswered={btnDisableUntilAnswered}
        setIsEndOfQuestions={setIsEndOfQuestions}
        handleClickAddArray={handleClickAddArray}
      />
    </div>
  );
};

export default ActivityFebTestingPlayer;
