import axios from "axios";
import useStore from "../zustand";

const useDataApi = () => {
  const { accessToken } = useStore((state) => state);

  const fetchStudentPerformanceDistribution = (classId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/data/api/classes/${classId}/student_performance_distribution/`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
  };

  const fetchStudentPerformance = (classId, lessonId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/data/api/classes/${classId}/student_performance/`,
      {
        params: { lesson_id: lessonId },
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  };

  const fetchClassLearningObjectives = (classId, lessonId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/data/api/classes/${classId}/learning_objectives/`,
      {
        params: { lesson_id: lessonId },
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  };

  const fetchClassComputationalThinkingScores = (classId, lessonId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/data/api/classes/${classId}/computational_scores/`,
      {
        params: { lesson_id: lessonId },
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  };
  const fetchClassStudents = (classId, lessonId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/data/api/classes/${classId}/students/`,
      {
        params: { lesson_id: lessonId },
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  };

  const fetchStudentLearningObjectives = (studentId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/data/api/students/${studentId}/learning_objectives/`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
  };

  const fetchStudentComputationalThinkingScores = (studentId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/data/api/students/${studentId}/computational_scores/`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
  };

  const fetchClassExerciseResults = (classId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/data/api/classes/${classId}/exercise_results/`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
  };
  const fetchClassLessonExerciseResults = (classId, lessonId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/data/api/classes/${classId}/lesson_exercise_results/`,
      {
        params: { class_id: classId, lesson_id: lessonId },
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  };

  const fetchStudentExerciseResults = (studentId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/data/api/students/${studentId}/exercise_results/`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
  };

  const fetchQuestionResults = (studentId, exerciseId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/data/api/students/${studentId}/question_results/${exerciseId}/`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
  };

  const fetchStudentReport = (studentId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/data/api/students/${studentId}/report/`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
  };

  const fetchClassReport = (classId) => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/data/api/classes/${classId}/report/`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
  };

  const fetchSchoolReport = () => {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/data/api/school/report/`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
  };

  return {
    fetchStudentPerformanceDistribution,
    fetchStudentPerformance,
    fetchClassLearningObjectives,
    fetchClassComputationalThinkingScores,
    fetchClassStudents,
    fetchStudentLearningObjectives,
    fetchStudentComputationalThinkingScores,
    fetchClassExerciseResults,
    fetchStudentExerciseResults,
    fetchQuestionResults,
    fetchStudentReport,
    fetchClassLessonExerciseResults,
    fetchClassReport,
    fetchSchoolReport,
  };
};

export default useDataApi;
