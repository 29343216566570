import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

import ApartmentIcon from "@mui/icons-material/Apartment";
import { Box } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useUserApi from "api/user";
import useStore from "../../../../zustand";
import { useQuery } from "react-query";
// import { LoadingScreen } from "../../curriculum/Components/LoadingScreen";
import { ErrorScreen } from "../../curriculum/Components/ErrorScreen";
import "./ActivityHeader.css";
import OnlineAvatars from "./OnlineAvatars";
// import { ErrorScreen } from '../..ErrorScreen/curriculum/Components/ErrorScreen';
const theme = createTheme();

// ============================

// ===========
theme.typography.h1 = {
  fontSize: "32px",
  "@media (max-width:1000px)": {
    fontSize: "32px",
  },
  "@media (max-width:800px)": {
    fontSize: "28px",
  },
  "@media (max-width:660px)": {
    fontSize: "22px",
  },
};
const ActivityHeader = ({ firstName, school }) => {
  const { accounts } = useMsal();
  const store = useStore();
  const matches = useMediaQuery("(min-width:600px)");

  const [dateTimeStatus, setDateTimeStatus] = useState("");
  const today = new Date();
  const curHr = today.getHours();
  useEffect(() => {
    if (curHr < 12) {
      setDateTimeStatus("Good morning");
    } else if (curHr < 18) {
      setDateTimeStatus("Good afternoon");
    } else {
      setDateTimeStatus("Good evening");
    }
  }, []);

  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const { fetchSchool } = useUserApi();

  const { isLoading, isError, data, error } = useQuery(
    "school",
    () => fetchSchool(store.tenantId),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (data) {
    // console.log("school==> ", data.data);
  }

  if (isLoading) {
    return <div></div>;
  }

  if (isError) {
    return <ErrorScreen error={error} />;
  }

  return (
    <ThemeProvider theme={theme}>
      {/* <Typography variant='h3'>Responsive h3</Typography> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box>
            <Typography
              variant="h1"
              style={{
                // fontSize: '32px',
                fontWeight: "300",
                display: "inline-block",
              }}
            >
              <span className="wave">👋 </span> {dateTimeStatus},{" "}
            </Typography>{" "}
            <Typography variant="h1" style={{ display: "inline-block" }}>
              {/* {capitalizeFirst(firstName)}! */}
              {accounts && accounts[0].name.split(" ")[0]}!
            </Typography>
          </Box>
          <Box
            style={{
              marginTop: "4px",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <ApartmentIcon
              style={{
                color: "#7B8794",
                marginRight: "12px",
              }}
            />{" "}
            <Typography variant="p" style={{ color: "#7B8794" }}>
              {data && data.data.name}
            </Typography>
          </Box>
        </Box>
        <OnlineAvatars />
      </Box>
    </ThemeProvider>
  );
};

export default ActivityHeader;
