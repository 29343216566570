import useUserApi from "api/user";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import useStore from "../../../zustand";
import ActivityLessonClassComp from "./ActivityLessonClassComp";
import { LoadingScreen } from "../curriculum/Components/LoadingScreen";

const ActivityLessonPlayerPage = () => {
  const [lessonData, setLessonData] = useState([]);
  const { fetchRecentActivityLessons } = useUserApi();
  const { selectedClassId, userId } = useStore();

  const { isLoading, isError, data, error } = useQuery(
    ["recentActivities", userId, selectedClassId],
    () => fetchRecentActivityLessons(userId),
    { enabled: userId != null, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (data) {
      setLessonData(Object.entries(data?.data));
    }
  }, [data]);
  // console.log("data && data.data :>> ", data && data.data);
  // console.log("lessonData :>> ", lessonData);

  return (
    <div style={{ maxWidth: "1380px", margin: "160px auto", width: "95%" }}>
      <h1>Lessons View</h1>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "300px",
            background: "white",
            borderRadius: "12px",
            boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
          }}
        >
          {" "}
          <LoadingScreen />
        </div>
      ) : data?.data ? (
        <div
          style={{
            // display: "flex",
            // gridTemplateColumns: "1fr 1fr 1fr 1fr",
            // flexWrap: "wrap",
            // justifyItems: "space-between",
            // gap: "40px",
            margin: "20px 0 0px",
          }}
        >
          {/* {lessonData &&
            lessonData.map((item) => ( */}
          {data && (
            <ActivityLessonClassComp
              classId={selectedClassId}
              lessons={data?.data[selectedClassId]}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ActivityLessonPlayerPage;
