import styled from "styled-components";

export const NextActivityWrapper = styled.div`
  /* max-width: 280px; */
  width: 100%;
  @media (max-width: 1380px) {
    width: 280px;
    /* width: 100%; */
  }
  @media (max-width: 955px) {
    /* width: 280px; */

    width: 100%;
  }
  @media (max-width: 600px) {
    width: 100%;
    /* width: 100%; */
  }
  height: 220px;
  flex-shrink: 0;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  justify-content: space-between;
  overflow: hidden;
  .next-container .info {
    /* padding: 20px 0 0 0; */
    padding: 20px 20px 10px 20px;
    height: 174px;
  }
  .next-container .start {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0px 20px;
    font-size: 16px;
    background: #e49eae;
    height: 46px;
  }
  .next-container .start h3 {
    font-size: 18px;
    margin: 0;
  }
  .next-container {
  }
`;
