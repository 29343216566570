import React from "react";
import { ClassCardsWrapper } from "./ClassCards.styles";
import { useQuery } from "react-query";
import useUserApi from "api/user";
import NextActivity from "./NextActivity";
import useStore from "../../../../zustand";
import robotImage from "../assets/My project (25) 1.png";
import awardImage1 from "../assets/Mask group.png";
import awardImage2 from "../assets/Mask group (1).png";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import CircularProgress from "@mui/material/CircularProgress";
import SmartToyIcon from "@mui/icons-material/SmartToy";
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
const ClassCards = ({}) => {
  const { fetchClasses } = useUserApi();
  const { selectedClassId, setSelectedClassId } = useStore();

  const { fetchActivityTask } = useUserApi();

  const { isLoading, isError, data, error } = useQuery(
    ["todoTasks", selectedClassId],
    () => fetchActivityTask(selectedClassId),
    { enabled: selectedClassId != null, refetchOnWindowFocus: false }
  );
  console.log("data && data.data :>> ", data && data.data.todo.tasks);
  const [windowSize, setWindowSize] = React.useState(getWindowSize());

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  console.log("windowSize.innerWidth :>> ", windowSize.innerWidth);
  return (
    <ClassCardsWrapper>
      <div className="ClassCards">
        {data && data.data.todo.tasks.length > 2 ? (
          <>
            <div className="item-activity-card">
              <NextActivity item={data.data.todo.tasks[0]} />
            </div>
            {windowSize.innerWidth > 1380 ? (
              <div className="item-activity-card">
                <NextActivity item={data.data.todo.tasks[1]} />
              </div>
            ) : null}
          </>
        ) : data && data.data.todo.tasks.length === 1 ? (
          <>
            <div className="item-activity-card">
              <NextActivity item={data.data.todo.tasks[0]} />
            </div>
            {windowSize.innerWidth > 1380 ? (
              <div
                className="item-activity-card"
                style={{
                  // width: "310px",
                  height: "220px",
                  backgroundColor: "white",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  borderRadius: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyCenter: "center",
                  flexDirection: "column",
                  padding: "20px 10px",
                  textAlign: "center",
                }}
              >
                <SmartToyIcon
                  style={{ fontSize: "60px", margin: "0", color: "rgb(46, 158, 189)" }}
                />
                <h1 style={{ margin: "0" }}>Ai Activity!</h1>
                <p style={{ margin: "0" }}>All the set activities are complete.</p>
              </div>
            ) : null}
          </>
        ) : data && data.data.todo.tasks.length === 0 ? (
          <>
            <div
              className="item-activity-card"
              style={{
                width:
                  windowSize.innerWidth > 955 && windowSize.innerWidth < 1380 ? "280px" : "100%",
                height: "220px",
                backgroundColor: "white",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                borderRadius: "20px",
                display: "flex",
                alignItems: "center",
                justifyCenter: "center",
                flexDirection: "column",
                padding: "20px 10px",
                textAlign: "center",
              }}
            >
              <ThumbUpOffAltIcon style={{ fontSize: "60px", margin: "0" }} />
              <h1 style={{ margin: "0" }}>Well done!</h1>
              <p style={{ margin: "0" }}>All the set activities are complete.</p>
            </div>
            {windowSize.innerWidth > 1380 ? (
              <div
                className="item-activity-card"
                style={{
                  // width: "310px",
                  height: "220px",
                  backgroundColor: "white",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  borderRadius: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyCenter: "center",
                  flexDirection: "column",
                  padding: "20px 10px",
                  textAlign: "center",
                }}
              >
                <SmartToyIcon
                  style={{ fontSize: "60px", margin: "0", color: "rgb(46, 158, 189)" }}
                />
                <h1 style={{ margin: "0" }}>Ai Activity!</h1>
                <p style={{ margin: "0" }}>All the set activities are complete.</p>
              </div>
            ) : null}
          </>
        ) : null}
        {isLoading ? (
          <>
            <div
              className="item-activity-card"
              style={{
                width:
                  windowSize.innerWidth > 955 && windowSize.innerWidth < 1380 ? "280px" : "100%",
                height: "220px",
                backgroundColor: "white",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                borderRadius: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                padding: "20px 10px",
                textAlign: "center",
              }}
            >
              <CircularProgress style={{ fontSize: "60px", color: "rgb(46, 158, 189)" }} />
              <h1 style={{ margin: "0" }}>Loading...</h1>
            </div>
            {windowSize.innerWidth > 1380 ? (
              <div
                className="item-activity-card"
                style={{
                  // width: "310px",
                  height: "220px",
                  backgroundColor: "white",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  borderRadius: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "20px 10px",
                  textAlign: "center",
                }}
              >
                <CircularProgress style={{ fontSize: "60px", color: "rgb(46, 158, 189)" }} />
                <h1 style={{ margin: "0" }}>Loading...</h1>
              </div>
            ) : null}
          </>
        ) : null}
        <div
          className="item-activity-card"
          style={{
            width: windowSize.innerWidth > 955 ? "220px" : "100%",
            height: "220px",
            backgroundColor: "white",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyCenter: "center",
            flexDirection: "column",
            // padding: "10px 10px",
            textAlign: "center",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div className="radialProgressBar progress-20">
            <div className="overlay">
              <img
                className="oberlay-image-student"
                src={robotImage}
                alt=""
                srcSet=""
                style={{
                  width: "80px",
                  padding: "0px ",
                  marginTop: "0px",
                }}
              />
            </div>
          </div>
          {/* <div
            className=""
            style={{
              width: "300px",
              height: "300px",
              background: "#F6DDE3",
              borderRadius: "900px",
              padding: "10px 0 20px",
              border: "6px solid #6358A5",
              overflow: "hidden",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
              top: "-190px",
              left: "50%",
              transform: "translate(-50%,0%)",
            }}
          >
            <img
              src={robotImage}
              alt=""
              srcSet=""
              style={{
                width: "75px",
                padding: "0px ",
                marginTop: "0px",
              }}
            />
          </div> */}

          <div
            className=""
            style={{
              padding: "5px",
              marginTop: "100px",
            }}
          >
            <h4 style={{ margin: "0" }}>31 minutes more to complete your daily activity goal</h4>
            <p style={{ margin: "0", fontSize: "13px" }}>
              You have set yourself a 60 minute daily activity goal
            </p>
          </div>
        </div>

        <div
          className="item-activity-card"
          style={{
            display: "flex",
            // alignItems: "space-between",
            justifyContent: windowSize.innerWidth > 955 ? "space-between" : "none",
            gap: windowSize.innerWidth > 955 ? "" : "20px",
            flexDirection: "column",
            height: windowSize.innerWidth > 955 ? "220px" : "",
            width: "100%",
          }}
        >
          <div
            className=""
            style={{
              backgroundColor: "white",
              display: "flex",
              gap: "10px",
              alignItems: "center",
              padding: "10px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
            }}
          >
            <div className="">
              {" "}
              <img
                src={awardImage1}
                alt=""
                srcSet=""
                style={{
                  width: "60px",
                  padding: "0px ",
                  marginTop: "0px",
                }}
              />
            </div>
            <div className="">
              <h4 style={{ margin: "0" }}>Achievement XYZ</h4>
              <p style={{ margin: "0", fontSize: "13px" }}>
                Explanation on how to get this achievement
              </p>
            </div>
            <div className="">
              <h3 style={{ margin: "0 0 0 20px" }}>3/10</h3>
            </div>
          </div>
          {/* /////////// */}
          <div
            className=""
            style={{
              backgroundColor: "white",
              display: "flex",
              gap: "10px",
              alignItems: "center",
              padding: "10px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
            }}
          >
            <div className="">
              {" "}
              <img
                src={awardImage2}
                alt=""
                srcSet=""
                style={{
                  width: "60px",
                  padding: "0px ",
                  marginTop: "0px",
                }}
              />
            </div>
            <div className="">
              <h4 style={{ margin: "0" }}>Achievement ABC</h4>
              <p style={{ margin: "0", fontSize: "13px" }}>
                Explanation on how to get this achievement
              </p>
            </div>
            <div className="">
              <h3 style={{ margin: "0 0 0 20px" }}>6/10</h3>
            </div>
          </div>
        </div>
      </div>
    </ClassCardsWrapper>
  );
};

export default ClassCards;
