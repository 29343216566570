import React, { useState } from "react";

const ActivityPlayerTestCompHeader = ({
  selectedQuestionName,
  selectedQuestionNumber,
  selectedQuestionFolderName,
  selectedQuestionGUID,
  selectedQuestionFileName,
}) => {
  const [expandInfor, setExpandInfor] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        padding: "5px",
        // display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // gap: "20px",
        // margin: "0px 0 20px",
      }}
    >
      <button
        style={{
          padding: "2px 4px",
          color: "white",
          background: "#6358A5",
          opacity: expandInfor ? "1" : "0.6",
          cursor: "pointer",
          border: "none",
          width: "100%",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "600",
          boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        }}
        onClick={() => setExpandInfor((prev) => !prev)}
      >
        show question Info
      </button>
      {expandInfor && (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                margin: "0",
                padding: "0",
              }}
            >
              Question Number:
            </p>
            <h3
              style={{
                margin: "0 0 0 9px",
                padding: "0",
              }}
            >
              {selectedQuestionNumber}
            </h3>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                margin: "0",
                padding: "0",
              }}
            >
              name:
            </p>
            <h3
              style={{
                margin: "0 0 0 9px",
                padding: "0",
              }}
            >
              {selectedQuestionName}
            </h3>
          </div>
          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    margin: "0",
                    padding: "0",
                  }}
                >
                  GUID:
                </p>
                <h3
                  style={{
                    margin: "0 0 0 9px",
                    padding: "0",
                  }}
                >
                  {selectedQuestionGUID}
                </h3>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    margin: "0",
                    padding: "0",
                  }}
                >
                  filename:
                </p>
                <h3
                  style={{
                    margin: "0 0 0 9px",
                    padding: "0",
                  }}
                >
                  {selectedQuestionFileName}
                </h3>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  margin: "0",
                  padding: "0",
                }}
              >
                foldername:
              </p>
              <h3
                style={{
                  margin: "0 0 0 9px",
                  padding: "0",
                }}
              >
                {selectedQuestionFolderName}
              </h3>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityPlayerTestCompHeader;
