import React from "react";
import LessonPreviewEdit from "./LessonPreviewEdit";

const LevelInputDropDownEdit = ({
  curriculumLevel,
  itemCurr,
  itemStage,
  itemLevel,
  levelNamesFilter,
  id,
  levels,
  setEditedClassLevel,
  setCustomValidationSelect,
  customValidationSelect,
  editedClassLevel,
  setSelectedCurriculum,
  setCloseSelection,
  closeSelection,
  setEditedClassLesson,
  editedClassLesson,
  selectedLessonId,
  setSelectedLessonId,
  setSelectedCurriculumLesson,
}) => {
  // console.log("closeSelection :>> ", closeSelection);

  return (
    <>
      <div
        style={{
          background: "rgba(6, 101, 157,0.2)",
          marginBottom: "6px",
        }}
      >
        <p
          style={{
            margin: 0,
            fontSize: "14px",
            padding: "2px 6px 2px 12px",
            fontWeight: "600",
          }}
        >
          {curriculumLevel}
        </p>
      </div>
      <div
        style={{
          display: "grid",
          gap: "12px",
          gridTemplateColumns: curriculumLevel === "GCSE CS" ? "1fr" : "1fr 1fr 1fr 1fr 1fr",
          maxWidth: "900px",
          margin: "auto",
          padding: "6px 0",
        }}
      >
        {levels[curriculumLevel]
          ?.filter((item) => !item?.level_name?.toLowerCase()?.includes("level 6"))
          ?.map((item) => (
            <div
              value={item.level}
              style={{
                padding: "4px 6px 4px 12px",
                margin: 0,
                border: "1px solid rgba(6, 101, 157,0.1)",
                background: editedClassLevel == item.level ? "rgba(6, 101, 157,1)" : "",
                color: editedClassLevel == item.level ? "white" : "grey",
                // color: "grey",
                //   width: "200px",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "13px",
                fontWeight: editedClassLevel == item.level ? "600" : "",
              }}
              onClick={(e) => {
                setEditedClassLevel(item.level);
                setSelectedCurriculum(item?.level_name);
                setSelectedLessonId("");
                setEditedClassLesson("");
                setSelectedCurriculumLesson("");
                // setCloseSelection(false);
                console.log(item);
                if (item.id == null) {
                  setCustomValidationSelect("required");
                } else {
                  setCustomValidationSelect("valid");
                }
              }}
            >
              {/* {curriculumLevel} */}
              {/* <img src={item.image} alt="" srcset="" /> */}
              {item?.level_name?.split("/")[2].replace("(online)", "")}
              {/* {item?.level_name} */}
              {/* {itemLevel} */}
            </div>
          ))}
      </div>
      <div>
        {levels[curriculumLevel]
          ?.filter((item) => !item?.level_name?.toLowerCase()?.includes("level 6"))
          ?.map((itemMain) => (
            <div>
              {editedClassLevel == itemMain?.level && (
                <LessonPreviewEdit
                  editedClassLevel={editedClassLevel}
                  selectedLessonId={selectedLessonId}
                  setSelectedLessonId={setSelectedLessonId}
                  setEditedClassLesson={setEditedClassLesson}
                  editedClassLesson={editedClassLesson}
                  setSelectedCurriculumLesson={setSelectedCurriculumLesson}
                />
              )}
            </div>
          ))}
      </div>
    </>
  );
};
export default LevelInputDropDownEdit;
