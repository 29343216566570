import React, { useState } from "react";
// import ItemListComponent from "../DataSiteHomePage/ItemListComponent";
import ItemListPerformance from "./ItemListPerformance";
// import ItemListComponent from "../DataSiteHomePage/ItemListComponent";
// import ListItemHeaderComponent from "../DataSiteHomePage/ListItemHeaderComponent";

const StudentsPerformanceList = ({ students, type }) => {
  // console.log("type", type);
  const [searchWord, setSearchWord] = useState("");
  // console.log("students=;=;=;=;=YYYYYYYYYY=====>", students);
  const filterList = (item, array) => {
    return students.length > 0
      ? item.student.full_name &&
          item.student.full_name
            .toLowerCase()
            .indexOf(searchWord.toLowerCase()) > -1
      : array;
  };
  if (students?.length > 0) {
    return (
      <div>
        <div
          style={{
            margin: "0px 0 0",
            padding: "30px 30px",
            border: "0px solid rgb(31, 110, 163)",
            // borderBottomLeftRadius: "8px",
            // borderBottomRightRadius: "8px",
            // justifyContent: "space-between",
            // boxShadow: "0 5px 15px hsla(0,0%, 0%, .2)",
            background: "rgb(255, 255, 255,0.5)",
          }}
        >
          {/* <div
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <h2
              style={{
                fontSize: "16px",
                margin: "0",
                padding: "0",
              }}
            >
              Students
            </h2>
            <input
              style={{
                border: "1px solid #CBD2D9",
                // height: "34px",
                fontSize: "16px",
                margin: "0px",
                padding: "2px 9px",
                borderRadius: "4px",
                minWidth: "200px",
                // marginTop: "10px",
                // marginBottom: "10px",
              }}
              placeholder='Search name...'
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
            ></input>
          </div> */}

          <div
            style={{
              boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
              borderRadius: "12px",
              overflow: "hidden",
            }}
          >
            {/* <ListItemHeaderComponent
              data={{
                title: "Name",
              }}
            /> */}
            <div
              style={{
                height: "320px",
                overflow: "auto",
              }}
            >
              {students
                ?.sort(
                  (a, b) =>
                    a.student.full_name !== null &&
                    b.student.full_name !== null &&
                    a.student.full_name.localeCompare(b.student.full_name)
                )
                .filter((item, key, array) => filterList(item, array))
                .map((student) => (
                  <ItemListPerformance
                    data={student?.student}
                    score={
                      student && (type === "noscore" ? "N/A" : student?.score)
                    }
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div
          style={{
            margin: "0px 0 0",
            padding: "30px 30px",
            border: "0px solid rgb(31, 110, 163)",
            background: "rgb(255, 255, 255,0.5)",
          }}
        >
          <div
            style={{
              boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
              borderRadius: "12px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                height: "320px",
                overflow: "auto",
              }}
            >
              {/* no students */}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default StudentsPerformanceList;
