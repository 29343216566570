import React from "react";
import { NextActivityWrapper } from "./NextActivity.styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useUserApi from "api/user";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
const NextActivity = ({ item }) => {
  const { fetchActivityTask } = useUserApi();

  const { isLoading, isError, data, error } = useQuery(
    ["todoTasks", item.id],
    () => fetchActivityTask(item.id),
    { enabled: item.id != null, refetchOnWindowFocus: false }
  );

  console.log("data && data.data :=====>> ", data && data.data);
  const returnStage = (name) => {
    if (name.includes("Stage 1")) return "Stage 1";
    else if (name.includes("Stage 2")) return "Stage 2";
    else if (name.includes("Stage 3")) return "Stage 3";
    else if (name.includes("Stage 4")) return "Stage 4";
    else if (name.includes("Stage 5")) return "Stage 5";
    else {
      return null;
    }
  };
  const returnLevel = (name) => {
    if (name.includes("Level 1")) return "Level 1";
    else if (name.includes("Level 2")) return "Level 2";
    else if (name.includes("Level 3")) return "Level 3";
    else if (name.includes("Level 4")) return "Level 4";
    else if (name.includes("Level 5")) return "Level 5";
    else {
      return null;
    }
  };

  return (
    <NextActivityWrapper>
      <div className="next-container">
        <div className="info">
          <h2>
            {item.exercise?.name
              ?.replace(`Stage 1 -`, "")
              .replace(`Stage 2 -`, "")
              .replace(`Stage 3 -`, "")
              .replace(`Stage 4 -`, "")
              .replace(`Stage 5 -`, "")
              .replace(`Stage 6 -`, "")
              .replace(`Level 1 -`, "")
              .replace(`Level 2 -`, "")
              .replace(`Level 3 -`, "")
              .replace(`Level 4 -`, "")
              .replace(`Level 5 -`, "")
              .replace(`Level 6 - `, "")}
          </h2>
          <p
            style={{
              fontSize: "16px",
            }}
          >
            {item.exercise.type.toLowerCase() === "h5p"
              ? "Interactive activity."
              : item.exercise.type.toLowerCase() === "blocks"
              ? "Blocks"
              : null}
          </p>
          <div className="" style={{ display: "flex", gap: "10px" }}>
            {returnStage(item.exercise?.name)?.length > 0 ? (
              <div
                style={{
                  padding: "4px 14px",
                  fontWeight: "500",
                  fontSize: "12px",
                  background: "#cb98e830",
                  borderRadius: "100px",
                }}
              >
                {returnStage(item.exercise?.name)}
              </div>
            ) : null}
            {returnLevel(item.exercise?.name)?.length > 0 ? (
              <div
                style={{
                  padding: "4px 14px",
                  fontWeight: "500",
                  fontSize: "12px",
                  background: "#cb98e830",
                  borderRadius: "100px",
                }}
              >
                {returnLevel(item.exercise?.name)}
              </div>
            ) : null}
          </div>
        </div>
        <Link
          to={
            item.exercise.type.toLowerCase() === "h5p"
              ? `/activities/Activity/${item.exercise.id}/${item.lesson.id}`
              : item.exercise.type.toLowerCase() === "blocks"
              ? `/activities/blocks/codingblocks/${item.exercise.id}/${item.lesson.id}/${item.exercise.name}`
              : null
          }
        >
          <div
            className="start"
            style={item.exercise.type.toLowerCase() === "blocks" ? { background: "#4e9ce4" } : null}
          >
            <h3>Start </h3>
            <ArrowForwardIcon style={{ color: "#33325D", fontSize: "24px" }} />
          </div>
        </Link>
      </div>
    </NextActivityWrapper>
  );
};

export default NextActivity;
