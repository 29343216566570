import React from "react";
import './CurriculumStyle.css'
import { Box } from "@material-ui/core";
import VerticalToggleButtons from "./TogglePage";
import CategorySelector from "./CategorySelector";
import usePersistStore from '../../../../zustandPersist.js'
import useStore from '../../../../zustand.js'
import { Skeleton, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";


function CurRowLevels({ data, isLoading, level, setLessonType, setLevel, setTest, test, lessonID }) {
    const { setChosenLevel, permission } = usePersistStore()
    const { view, setView, setLessonFromClass } = useStore()
    const location = useLocation()


    React.useEffect(() => {
        !lessonID && setChosenLevel(0) // if there is no lessonID coming from class page, ut sets the level to 0
    }, [location.pathname, location.state, test])


    return (
        <Box
            style={{
                color: '#323F4B',
                padding: 0, display: 'flex', justifyContent: 'center',
                background: 'white', alignItems: 'center',
                boxShadow: 'rgb(0 0 0 / 20%) 0px 5px 15px',
                borderRadius: 4, margin: '10px',
            }}>
            <CategorySelector />
            <Box style={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '3px', flex: 1 }}>
                <div style={{
                    display: 'flex', flexDirection: 'column'
                }}>
                    <div
                        style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}
                    >
                        {location?.state?.from === 'book' && isLoading ? (
                            [1, 2, 3].map(item => <Skeleton count={1} width={120} height={50} style={{ marginRight: 10 }} />)
                        ) : (
                            location?.state?.from === 'book' && data?.length > 0 && data[0].levels.filter(item => item.type == 'offline').map((item, index) => (
                                <Box
                                    style={{
                                        cursor: 'pointer', display: 'flex', alignItems: 'center',
                                        // filter: permission?.access?.levels?.includes(item?.id) ? "blur(0px)" : "blur(4px)"
                                    }}
                                    onClick={() => (setLessonFromClass(null), setLessonType(item.type), setLevel(item), setChosenLevel(index), setTest(!test))}
                                >
                                    <div key={item.id} style={{
                                        background: level?.id === item?.id ? '#06659d' : '#dbdbdb4f',
                                        color: level?.id === item?.id ? 'white' : '#06659d', borderRadius: 4,
                                        padding: '7px 30px', margin: 5
                                    }}>
                                        <h4 style={{ color: 'inherit', margin: 0 }}>{item.name}</h4>
                                    </div>
                                </Box>
                            )
                            )
                        )}
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                        {location?.state?.from !== 'book' && isLoading ? (
                            [1, 2, 3, 4, 5, 6].map(item => <Skeleton count={1} width={120} height={50} style={{ marginRight: 10 }} />)
                        ) : (
                            location?.state?.from !== 'book' && data?.length > 0 && data[0].levels.filter(item => item.type !== 'offline').slice(0, 5).map((item, index) => (
                                <Box
                                    style={{
                                        cursor: 'pointer', display: 'flex', alignItems: 'center',
                                        // filter: permission?.access?.levels?.includes(item?.id) ? "blur(0px)" : "blur(4px)"
                                    }}
                                    onClick={() => (setLessonFromClass(null), setLessonType(item.type), setChosenLevel(data[0]?.levels?.findIndex(level => level === item)), setLevel(item), setTest(!test))}
                                >
                                    <div
                                        key={item.id}
                                        style={{
                                            background: level?.id === item?.id ? '#06659d' : '#dbdbdb4f',
                                            color: level?.id === item?.id ? 'white' : '#06659d', borderRadius: 4,
                                            padding: '7px 30px', margin: 5
                                        }}
                                    >
                                        <Typography
                                            variant="inherit" noWrap
                                            style={{
                                                color: 'inherit', margin: 0, maxWidth: level?.id !== item?.id ? 190 : 'none',
                                                fontWeight: 600, fontFamily: 'Montserrat,sans-serif'
                                            }}
                                        >
                                            {item.name}
                                        </Typography>
                                    </div>
                                </Box>
                            ))
                        )}
                    </div>
                </div>
            </Box>
            <VerticalToggleButtons view={view} setView={setView} />
        </Box>

    );
}


export default CurRowLevels;