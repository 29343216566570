import React, { useEffect } from "react";
import BuilderLessonCard from "./BuilderLessonCard";

import "assets/css/myStyle.css";
const BuilderLevelComponent = ({
  curriculumLevel,
  levels,
  itemCurr,
  itemStage,
  itemLevel,
  searchWord,
  levelNamesFilter,
  addLesson,
  deleteLesson,
  selectedLessons,
  setShowFilter,
  setSelectedLessonIdPlayer,
  selectedLessonIdPlayer,
  setLevelItemNamesFilter,
  setSelectedLesson,
  levelItemNamesFilter,
}) => {
  const filterList = (item, array) => {
    return levels[curriculumLevel].length > 0
      ? item.name && item.name.toLowerCase().indexOf(searchWord.toLowerCase()) > -1
      : array;
  };
  useEffect(() => {
    if (levels[curriculumLevel]?.filter((item, key, array) => filterList(item, array)).length > 0) {
      setShowFilter(true);
    } else {
      setShowFilter(false);
    }
  }, [filterList]);
  return (
    <>
      {levels[curriculumLevel]
        ?.filter((item) =>
          levelItemNamesFilter.length === 0
            ? true
            : levelItemNamesFilter.includes(item.level_name.replace("(online)", "")) &&
              levelNamesFilter.includes(item.level_name?.split("/")[0])
        )
        ?.filter((item, key, array) => filterList(item, array)).length > 0 && (
        <div style={{ padding: "0 10px" }}>
          <h3
            style={{
              // margin: "0px 0 10px",
              fontSize: "16px",
            }}
          >
            {itemLevel}
          </h3>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              // flexDirection: "column",
              gap: "14px",
            }}
          >
            {levels[curriculumLevel]
              ?.filter((item, key, array) => filterList(item, array))
              ?.map((lesson) => (
                <BuilderLessonCard
                  lesson={lesson}
                  addLesson={addLesson}
                  deleteLesson={deleteLesson}
                  selected={
                    selectedLessons && selectedLessons.filter((l) => l.id === lesson.id).length > 0
                  }
                  index={selectedLessons.findIndex((l) => l.id === lesson.id) + 1}
                  setSelectedLessonIdPlayer={setSelectedLessonIdPlayer}
                  selectedLessonIdPlayer={selectedLessonIdPlayer}
                  setSelectedLesson={setSelectedLesson}
                />
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default BuilderLevelComponent;
