import React from "react";
import { useLocation, Switch, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import componentStyles from "assets/theme/layouts/admin.js";
import CoursesPage from "views/eai/curriculum/CoursesPage";
import LessonsPage from "views/eai/curriculum/LessonsPage";
import ViewResourcePage from "views/eai/curriculum/ViewResourcePage";
import ResourcesPage from "views/eai/curriculum/ResourcesPage";
import OfflineLevelsPage from "views/eai/curriculum/OfflineLevelsPage";
import CustomLevelLessonsPage from "views/eai/curriculum/CustomLevelLessonsPage";
import OnlineLevelsPage from "views/eai/curriculum/OnlineLevelsPage";
import XSubjectsPage from "views/eai/curriculum/CurriculumHome/XSubjectsPage";
import ProtectedRoute from "./ProtectedRoute";
import SubjectHomePage from "views/eai/curriculum/SubjectHomePage";

const useStyles = makeStyles(componentStyles);

const Curriculum = () => {
  const classes = useStyles();
  const location = useLocation();
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  const [account, setAccount] = React.useState(null);

  const getRoutes = () => {
    const prefix = "/curriculum/";
    const routes = [
      {
        component: XSubjectsPage,
        path: "subjects/",
      },
      {
        component: SubjectHomePage,
        path: "subjects/:subjectName",
      },
      {
        component: CoursesPage,
        path: "categories/:categoryId/courses/",
      },
      {
        component: LessonsPage,
        path: "levels/:levelId/lessons/",
      },
      {
        component: CustomLevelLessonsPage,
        path: "myLevels/:levelId/lessons/",
      },
      {
        component: ResourcesPage,
        path: "levels/:levelId/resources/",
      },
      {
        component: ResourcesPage,
        path: "lessons/:lessonId/resources/",
      },
      {
        component: OfflineLevelsPage,
        path: "courses/:courseId/offline/levels/",
      },
      {
        component: OnlineLevelsPage,
        path: "courses/:courseId/online/levels/",
      },
      {
        component: ViewResourcePage,
        path: "resources/:resourceId/",
      },
    ];

    return routes.map((route) => (
      <ProtectedRoute
        exact
        path={prefix + route.path}
        component={route.component}
        key={route.path}
        setAccount={setAccount}
      />
    ));
  };


  return (
    <>
      <Box display='flex'>
        <Box position='relative' flex='1' className={classes.mainContent}>
          <AdminNavbar
            logo={{
              innerLink: "/data/school",
              imgSrc: require("../assets/img/EducationaiLogos/Group 1540.svg")
                .default,
              imgAlt: "...",
              className: "first-element",
            }}
            account={account && account.type}
          />
          <Switch>
            {getRoutes()}
            <Redirect from='*' to='/curriculum/subjects/' />
          </Switch>
          <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
          >
            <AdminFooter />
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Curriculum;
