import React, { useEffect } from "react";
import "configurable-date-input-polyfill";
import "./style.css";
import useCurriculumApi from "api/curriculum";
import useUserApi from "api/user";
import { useQuery, useQueryClient } from "react-query";
import TransferList from "./TransferList";
import useStore from "../../../../../zustand";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
// core components
import componentStyles from "assets/theme/components/dialog.js";
import Grid from "@mui/material/Grid";
import componentStylesForms from "assets/theme/components/forms.js";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import componentStylesButton from "assets/theme/components/button.js";
import componentStylesError from "assets/theme/components/snackbar.js";
import { Delete } from "@material-ui/icons";
import NameInput from "./NameInput";
import DateInput from "./DateInput";
import FrequencyInput from "./FrequencyInput";
// import LevelInput from "./LevelInput";
// import LessonInput from "./LessonInput";
import CurriculumSelectorEdit from "./CurriculumSelectorEdit";
import NewLevelInputEdit from "./NewLevelInputEdit";

const useStyles = makeStyles(componentStyles);
const useStylesButton = makeStyles(componentStylesButton);

const useStylesForms = makeStyles(componentStylesForms);
const useStylesError = makeStyles(componentStylesError);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// funcitons for CheckBox
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function EditClass({
  open,
  close,
  classData,
  classStatus,
  status,
  availableLevels,
  availableLevelsStatus,
  mutate,
  setOpenSnackbar,
  toDeleteClass,
}) {
  const classes = { ...useStyles(), ...useStylesForms(), ...useStylesError() };
  const { fetchTeachers, fetchStudents } = useUserApi();
  const classesButton = useStylesButton();
  const buttonErrorClasses = { root: classesButton.buttonOutlineError };
  const queryClient = useQueryClient();
  const tenantId = useStore((state) => state.tenantId);

  const [viewLesson, setViewLesson] = React.useState({ author: "", id: null });

  // Custom validation
  const [customValidationName, setCustomValidationName] = React.useState(null);
  const [customValidationDate, setCustomValidationDate] = React.useState(null);
  const [customValidationFrequency, setCustomValidationFrequency] = React.useState(null);
  const [customValidationSelectLesson, setCustomValidationSelectLesson] = React.useState(null);
  const [customValidationSelect, setCustomValidationSelect] = React.useState(null);
  const [isValid, setIsValid] = React.useState(true);

  // //////////////////////////////
  const [levelNamesFilter, setLevelNamesFilter] = React.useState([
    "Computational Thinking",
    "Computing (Blocks - Python)",
    "Computing (Python)",
    "GCSE CS",
    "Micro:Bit",
    "Machine Learning",
  ]);
  const [levels, setLevels] = React.useState(null);
  const [levelNames, setLevelNames] = React.useState([]);
  const [levelNameCurriculum, setLevelNameCurriculum] = React.useState(null);
  const [curriculumValue, setCurriculumValue] = React.useState("");
  const [selectedCurriculum, setSelectedCurriculum] = React.useState(null);
  const [selectedCurriculumLesson, setSelectedCurriculumLesson] = React.useState(null);
  const [closeSelection, setCloseSelection] = React.useState(false);
  const [selectedLessonId, setSelectedLessonId] = React.useState("");

  // //////////////////////////////////////////////////
  /////////////////////////

  // console.log("availableLevels :>> ", availableLevels);
  React.useEffect(() => {
    setIsValid(
      customValidationName == "valid" &&
        customValidationFrequency == "valid" &&
        customValidationDate == "valid" &&
        customValidationSelect == "valid" &&
        customValidationSelectLesson == "valid"
    );
  }, [
    customValidationName,
    customValidationDate,
    customValidationSelect,
    customValidationFrequency,
    customValidationSelectLesson,
  ]);

  const [component, setComponent] = React.useState({
    name: "Edit Class",
    content: "main",
  });

  const [editedClassName, setEditedClassName] = React.useState("");
  const [editedClassDate, setEditedClassDate] = React.useState("");
  const [editedClassFrequency, setEditedClassFrequency] = React.useState("");
  const [editedClassLevel, setEditedClassLevel] = React.useState("");
  const [editedClassLesson, setEditedClassLesson] = React.useState("");

  const [dataMessage, setDateMessage] = React.useState("Start Date is required.");

  // Checked states for Teacher
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  // Checked states for Teacher
  const [checkedStudents, setCheckedStudents] = React.useState([]);
  const [leftStudents, setLeftStudents] = React.useState([]);
  const [rightStudents, setRightStudents] = React.useState([]);

  //Fetching the data from API
  const { data: teachers, status: teachersStatus } = useQuery(
    "teachers",
    () => fetchTeachers(),
    {
      refetchOnWindowFocus: false,
    }
    // {
    //   // onSuccess: () => queryClient.invalidateQueries("teachers"),
    // }
  );

  const { fetchLessonPlan } = useCurriculumApi();

  //fetch fetchLessonPlan
  const {
    data: lessonPlanData,
    status: lessonPlanStatus,
    isLoading: lessonPlanIsLoading,
  } = useQuery(["lessonPlanData"], () => fetchLessonPlan(), {
    refetchOnWindowFocus: false,
  });
  const { data: students, status: studentsStatus } = useQuery(
    "class_students",
    () => fetchStudents(),
    {
      refetchOnWindowFocus: false,
    }
    // {
    //   // onSuccess: () => queryClient.invalidateQueries("class_students"),
    // }
  );

  // Assigining successfully fethced data to variables
  const allTeachers = teachersStatus == "success" ? teachers.data : [];
  const classTeachers = classStatus == "success" ? classData?.data?.teachers : "";
  const allStudents = studentsStatus == "success" ? students?.data : [];
  const classStudents = classStatus == "success" ? classData?.data?.students : "";
  const classID = status == "success" ? classData?.data?.id : "";
  const className = status == "success" ? classData?.data?.name : "";
  const classStartDate = status == "success" ? classData?.data?.startdate : "";
  const classStartFrequency = status == "success" ? classData?.data?.frequency : "";
  const classLevel = status == "success" ? classData?.data?.level : "";
  const classLesson = status == "success" ? classData?.data?.current_lesson : "";
  // console.log("classData ===============>> ", classData && classData.data);
  const availableLevel =
    availableLevelsStatus == "success"
      ? availableLevels?.data
          .filter((item) => item.full_name !== "Raspberry Pi/Basics/Level 1(online)")
          .filter(
            (item) => item.full_name !== "Sample Category/Sample Course/Sample Level(offline)"
          )
          .filter(
            (item) =>
              item.full_name !== "Sample Category/Sample Course - Stage 99/Sample Level(offline)"
          )
          .filter(
            (item) => item.full_name !== "Computing (Blocks - Python)/Stage 2/Level 6(online)"
          )
          .filter((item) => item.full_name !== "GCSE CS/GCSE/Programming Project(online)")
          .filter((item) => item.full_name !== "Computational Thinking/Stage 1/Level 1(offline)")
          .filter((item) => item.full_name !== "Computational Thinking/Stage 1/Level 2(offline)")
          .filter((item) => item.full_name !== "Computational Thinking/Stage 1/Level 3(offline)")
      : [];

  //fetch levels
  const { fetchCustomLevel } = useCurriculumApi();
  // const { status: statusLevel, data: dataLevel } = useQuery(
  //   ["customLevel", editedClassLevel],
  //   () => fetchCustomLevel(editedClassLevel),
  //   {
  //     enabled: editedClassLevel.length > 0,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  //fetching lessons and levels
  const { fetchLessons } = useCurriculumApi();
  // console.log("classData&& classData :>> ", classData && classData);
  //fetch lessons
  const {
    data: lessonData,
    status: lessonStatus,
    isLoading: lessonPlanCustomIsLoading,
  } = useQuery(["fetchLessons", editedClassLevel], () => fetchLessons(editedClassLevel), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setEditedClassName(className);
    setEditedClassDate(classStartDate);
    classStartFrequency && setEditedClassFrequency(classStartFrequency);
    classLevel && setEditedClassLevel(classLevel);
    classLesson && setSelectedLessonId(classLesson);
    classLesson && setEditedClassLesson(classLesson);
  }, [status, classData, className, classStartDate, classStartFrequency, classLevel, classLesson]);

  useEffect(() => {
    if (classData && className === "") {
      setCustomValidationName("required");
    } else {
      setCustomValidationName("valid");
    }

    if (classData && classStartDate === null) {
      setCustomValidationDate("required");
    } else {
      setCustomValidationDate("valid");
    }
    if (classData && classStartFrequency === null) {
      setCustomValidationFrequency("required");
    } else {
      setCustomValidationFrequency("valid");
    }

    if (classData && classLesson === null) {
      setCustomValidationSelectLesson("required");
    } else {
      setCustomValidationSelectLesson("valid");
    }

    if (classData && classLevel === null) {
      setCustomValidationSelect("required");
    } else {
      setCustomValidationSelect("valid");
    }
  }, [status, className, classStartDate, classStartFrequency, classLevel, classLesson, classData]);

  // Orginizing the list of students in class and out of class
  const newStudentList = () => {
    let inStudent = allStudents.filter((item) => classStudents?.includes(item.id));
    setRightStudents(inStudent);

    let outStudent = allStudents.filter((item) => !classStudents?.includes(item.id));
    setLeftStudents(outStudent);
  };

  useEffect(() => {
    studentsStatus == "success" && classStatus == "success" && newStudentList();
  }, [studentsStatus, classStatus, classID]);

  // Checked functions for Students
  const leftCheckedStudents = intersection(checkedStudents, leftStudents);
  const rightCheckedStudents = intersection(checkedStudents, rightStudents);

  const handleToggleStudents = (value) => () => {
    const currentIndexStudents = checkedStudents.indexOf(value);
    const newCheckedStudents = [...checkedStudents];

    if (currentIndexStudents === -1) {
      newCheckedStudents.push(value);
    } else {
      newCheckedStudents.splice(currentIndexStudents, 1);
    }

    setCheckedStudents(newCheckedStudents);
  };

  const numberOfCheckedStudents = (items) => intersection(checkedStudents, items).length;

  const handleToggleAllCheckStudents = (items) => () => {
    setCheckedStudents(union(checkedStudents, items));
  };

  const handleToggleAllUncheckStudents = (items) => () => {
    if (numberOfCheckedStudents(items) === items.length) {
      setCheckedStudents(not(checkedStudents, items));
    }
  };

  const handleCheckedRightStudents = () => {
    setRightStudents(rightStudents.concat(leftCheckedStudents));
    setLeftStudents(not(leftStudents, leftCheckedStudents));
    setCheckedStudents(not(checkedStudents, leftCheckedStudents));
  };

  const handleCheckedLeftStudents = () => {
    setLeftStudents(leftStudents.concat(rightCheckedStudents));
    setRightStudents(not(rightStudents, rightCheckedStudents));
    setCheckedStudents(not(checkedStudents, rightCheckedStudents));
  };

  // Orginizing the list of teachers in class and out of class
  const newTeacherList = () => {
    let inTeacher = allTeachers.filter((item) => classTeachers?.includes(item.id));
    setRight(inTeacher);

    let outTeacher = allTeachers.filter((item) => !classTeachers?.includes(item.id));
    setLeft(outTeacher);
  };

  useEffect(() => {
    teachersStatus == "success" && classStatus == "success" && newTeacherList();
  }, [teachersStatus, classStatus, classID]);

  // Checked functions for Teachers
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    studentsStatus == "success" && classStatus == "success" && newStudentList();
  }, [studentsStatus, classStatus, classID]);

  // =============================================

  const tempLevelName = (dataInfo) => {
    if (dataInfo) {
      dataInfo.map((item) => {
        if (levelNames.length < lessonPlanData.data.standard_lesson_plans.length + 1) {
          setLevelNames((prev) => [...prev, item.level_name]);
        }
      });
    }
  };
  React.useEffect(() => {
    // creates and object array for the lessons, organized by curriculum => levels
    if (lessonPlanData?.data) {
      const tempLevel = lessonPlanData.data.standard_lesson_plans
        ?.filter((item) => item.level_name !== "Computational Thinking/Stage 1/Level 1(offline)")
        .filter((item) => item.level_name !== "Computational Thinking/Stage 1/Level 2(offline)")
        .filter((item) => item.level_name !== "Raspberry Pi/Basics/Level 1(online)")
        .filter((item) => item.level_name !== "Sample Category/Sample Course/Sample Level(offline)")
        .filter(
          (item) =>
            item.level_name !== "Sample Category/Sample Course - Stage 99/Sample Level(offline)"
        )
        .filter((item) => item.level_name !== "Computing (Blocks - Python)/Stage 2/Level 6(online)")
        .filter((item) => item.level_name !== "Computational Thinking/Stage 1/Level 3(offline)")
        ?.filter((item) => !item.level_name.includes("CUSTOM"))
        .filter((item) => item.level_name !== "GCSE CS/GCSE/Programming Project(online)")
        ?.sort((a, b) => a.level_name.localeCompare(b.level_name))
        .reduce((acc, item) => {
          if (!acc[item.level_name.replace("(online)", "").split("/")[0]]) {
            acc[item.level_name.replace("(online)", "").split("/")[0]] = [];
          }
          acc[item.level_name.replace("(online)", "").split("/")[0]].push(item);
          return acc;
        }, {});
      // console.log("tempLevel :>> ", tempLevel);
      tempLevelName(lessonPlanData.data.standard_lesson_plans);
      setLevels(tempLevel);
    }
  }, [lessonPlanData]);

  React.useEffect(() => {
    setLevelNameCurriculum(
      levelNames
        .filter((val, id, array) => array.indexOf(val) === id)
        .filter((val) => !val.includes("Sample"))
        .filter((val) => !val.includes("Raspberry Pi"))
        .filter((val) => !val.includes("CUSTOM"))
        .map((item) => {
          // console.log("item", item.replace("(online)", ""));
          return item.replace("(online)", "");
        })
        .sort((a, b) => a !== null && b !== null && a.localeCompare(b))
        .map((item) => {
          // console.log("item", item.replace("(online)", ""));
          return item.split("/")[0];
        })
        .filter((val, id, array) => {
          return array.indexOf(val) == id;
        })
    );
  }, [levelNames]);
  // console.log("levelNameCurriculum :>> ", levelNameCurriculum);

  useEffect(() => {
    if (lessonPlanData?.data && classLevel?.length > 0) {
      if (
        lessonPlanData?.data.standard_lesson_plans.filter((item) => item.level === classLevel)
          ?.length > 0
      ) {
        setCurriculumValue("EAI Lesson Plan");
        lessonPlanData?.data.standard_lesson_plans.map((item) => {
          if (item.level === classLevel) {
            setSelectedCurriculum(item.name);
            item.lessons.map((itemLesson) => {
              if (itemLesson.id === classLesson) {
                setSelectedCurriculumLesson(itemLesson.name);
              }
            });
          }
        });
        lessonData?.data.map((item) => {
          if (item.id === classLesson) {
            setSelectedCurriculumLesson(item.name);
            setSelectedLessonId(classLesson);
          }
        });
      } else if (
        lessonPlanData?.data.custom_lesson_plans.filter((item) => item.id === classLevel)?.length >
        0
      ) {
        setCurriculumValue("Custom Lesson Plan");
        lessonPlanData?.data.custom_lesson_plans.map((item) => {
          if (item.id === classLevel) {
            setSelectedCurriculum(item.name);
            console.log("item :>> ", item);
            console.log("classLesson :>> ", classLesson);
            item.lessons.map((itemLesson) => {
              if (itemLesson.id === classLesson) {
                setSelectedCurriculumLesson(itemLesson.name);

                setSelectedCurriculumLesson(classLesson);
              }
            });
          }
        });
      }
    }
  }, [lessonPlanData]);
  // df65ef34-6aba-4195-b2ff-c839d1b4e638
  // console.log("curriculumValue :>> ", curriculumValue);
  // console.log("curriculumValue :>> ", curriculumValue);
  // console.log("curriculumValue :>> ", curriculumValue);
  // console.log("classLevel :>> ", classLevel);
  // console.log("classLevel :>> ", classLevel);
  // console.log("classLevel :>> ", classLevel);
  // console.log("lessonData &&lessonData.data :>> ", lessonData && lessonData.data);
  // =======================================

  //Handle Add & Remove Teacher
  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAllCheck = (items) => () => {
    setChecked(union(checked, items));
  };

  const handleToggleAllUncheck = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  // Handle close
  const handleClose = () => {
    close();
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="lg"
        style={{ zIndex: 999 }}
      >
        <div
          className={classes.dialogHeader}
          style={{ padding: "30px 40px 10px", background: "white" }}
        >
          <Typography
            variant="h5"
            component="h5"
            className={classes.dialogTitle}
            style={{
              fontFamily: "Montserrat,sans-serif",
              fontStyle: "normal",
              fontWeight: 550,
              fontSize: 22,
              color: "#323F4B",
              marginLeft: 8,
            }}
          >
            {component.name}
          </Typography>
          {component.content !== "main" && (
            <div>
              <Button
                onClick={() => setComponent({ name: "Edit Class", content: "main" })}
                style={{
                  color: "white",
                  background: "#1D6F98",
                  borderColor: "#1D6F98",
                  fontSize: 12,
                  fontFamily: "Montserrat,sans-serif",
                  fontWeight: 500,
                  fontStyle: "normal",
                  boxShadow: "none",
                  marginRight: 10,
                }}
              >
                Save and Exit
              </Button>
              <Button
                onClick={() => setComponent({ name: "Edit Class", content: "main" })}
                style={{
                  color: "#4FA9DC",
                  background: "white",
                  borderColor: "#4FA9DC",
                  fontSize: 12,
                  fontFamily: "Montserrat,sans-serif",
                  fontWeight: 500,
                  fontStyle: "normal",
                  boxShadow: "none",
                }}
              >
                Cancel changes
              </Button>
            </div>
          )}
        </div>
        <DialogContent style={{ padding: "10px 24px 20px" }}>
          {component.content === "main" ? (
            <Grid style={{ display: "flex", padding: "0px 20px" }} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <NameInput
                  editedClassName={editedClassName}
                  classes={classes}
                  customValidationName={customValidationName}
                  setEditedClassName={setEditedClassName}
                  setCustomValidationName={setCustomValidationName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateInput
                  editedClassDate={editedClassDate}
                  setEditedClassDate={setEditedClassDate}
                  setCustomValidationDate={setCustomValidationDate}
                  classes={classes}
                  customValidationDate={customValidationDate}
                  dataMessage={dataMessage}
                />
              </Grid>

              <Grid item xs={12}>
                <FrequencyInput
                  editedClassFrequency={editedClassFrequency}
                  classes={classes}
                  setEditedClassFrequency={setEditedClassFrequency}
                  setCustomValidationFrequency={setCustomValidationFrequency}
                  customValidationFrequency={customValidationFrequency}
                />
              </Grid>
              <Grid
                item
                style={{
                  width: "100%",
                }}
              >
                <CurriculumSelectorEdit
                  curriculumValue={curriculumValue}
                  setCurriculumValue={setCurriculumValue}
                  setSelectedCurriculum={setSelectedCurriculum}
                  setEditedClassLevel={setSelectedCurriculum}
                  setSelectedCurriculumLesson={setSelectedCurriculumLesson}
                  setEditedClassLesson={setEditedClassLesson}
                  setSelectedLessonId={setSelectedLessonId}
                  setCloseSelection={setCloseSelection}
                />
              </Grid>
              <Grid
                item
                style={{
                  width: "100%",
                }}
              >
                <NewLevelInputEdit
                  curriculumValue={curriculumValue}
                  setCurriculumValue={setCurriculumValue}
                  editedClassLevel={editedClassLevel}
                  availableLevel={
                    lessonPlanData &&
                    (curriculumValue === "EAI Lesson Plan"
                      ? lessonPlanData?.data.standard_lesson_plans?.sort((a, b) =>
                          a.level_name.localeCompare(b.level_name)
                        )
                      : lessonPlanData?.data.custom_lesson_plans.sort((a, b) =>
                          a.name.localeCompare(b.name)
                        ))
                  }
                  levelNamesFilter={levelNamesFilter}
                  classes={classes}
                  customValidationSelect={customValidationSelect}
                  setEditedClassLevel={setEditedClassLevel}
                  setCustomValidationSelect={setCustomValidationSelect}
                  closeSelection={closeSelection}
                  setCloseSelection={setCloseSelection}
                  selectedCurriculum={selectedCurriculum}
                  setSelectedCurriculum={setSelectedCurriculum}
                  levels={levels}
                  setLevels={setLevels}
                  levelNames={levelNames}
                  setLevelNames={setLevelNames}
                  levelNameCurriculum={levelNameCurriculum}
                  setLevelNameCurriculum={setLevelNameCurriculum}
                  selectedCurriculumLesson={selectedCurriculumLesson}
                  setSelectedCurriculumLesson={setSelectedCurriculumLesson}
                  setEditedClassLesson={setEditedClassLesson}
                  editedClassLesson={editedClassLesson}
                  selectedLessonId={selectedLessonId}
                  setSelectedLessonId={setSelectedLessonId}
                  lessonPlanIsLoading={lessonPlanIsLoading}
                  lessonPlanCustomIsLoading={lessonPlanCustomIsLoading}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <LevelInput
                  editedClassLevel={editedClassLevel}
                  availableLevel={availableLevel}
                  classes={classes}
                  customValidationSelect={customValidationSelect}
                  setEditedClassLevel={setEditedClassLevel}
                  setCustomValidationSelect={setCustomValidationSelect}
                  setViewLesson={setViewLesson}
                  setCustomValidationSelectLesson={setCustomValidationSelectLesson}
                />
              </Grid>

              <Grid item xs={12}>
                <LessonInput
                  editedClassLesson={editedClassLesson}
                  lessonStatus={lessonStatus}
                  classes={classes}
                  lessonData={lessonData}
                  customValidationSelectLesson={customValidationSelectLesson}
                  setEditedClassLesson={setEditedClassLesson}
                  statusLevel={statusLevel}
                  dataLevel={dataLevel}
                  setCustomValidationSelectLesson={setCustomValidationSelectLesson}
                />
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <Button
                  onClick={() =>
                    setComponent({
                      name: "Manage Teachers",
                      content: "manageTeachers",
                    })
                  }
                  fullWidth
                  style={{
                    color: "#094B72",
                    border: "1px solid #9AA5B1",
                    boxShadow: "none",
                    fontSize: "18px",
                    fontFamily: "Montserrat,sans-serif",
                    fontWeight: 600,
                    fontStyle: "normal",
                    height: 43,
                    padding: 5,
                  }}
                >
                  <PersonOutlineOutlinedIcon style={{ marginRight: 10 }} /> Manage teachers (
                  {right.length})
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={() =>
                    setComponent({
                      name: "Manage Students",
                      content: "manageStudents",
                    })
                  }
                  fullWidth
                  style={{
                    color: "#094B72",
                    border: "1px solid #9AA5B1",
                    boxShadow: "none",
                    fontSize: "18px",
                    fontFamily: "Montserrat,sans-serif",
                    fontWeight: 600,
                    fontStyle: "normal",
                    height: 43,
                    padding: 5,
                    marginRight: 20,
                  }}
                >
                  <SchoolOutlinedIcon style={{ marginRight: 5 }} /> Manage students (
                  {rightStudents.length})
                </Button>
              </Grid>
            </Grid>
          ) : component.content === "manageTeachers" ? (
            <TransferList
              checked={checked}
              right={right}
              left={left}
              handleCheckedRight={handleCheckedRight}
              handleCheckedLeft={handleCheckedLeft}
              buttonErrorClasses={buttonErrorClasses}
              handleToggle={handleToggle}
              leftChecked={leftChecked}
              rightChecked={rightChecked}
              numberOfChecked={numberOfChecked}
              handleToggleAllCheck={handleToggleAllCheck}
              handleToggleAllUncheck={handleToggleAllUncheck}
              titleAll={"All Teachers"}
              titleSeleceted={"Selected Teachers"}
              bodyText="No teachers added"
            />
          ) : (
            <TransferList
              checked={checkedStudents}
              right={rightStudents}
              left={leftStudents}
              handleCheckedRight={handleCheckedRightStudents}
              handleCheckedLeft={handleCheckedLeftStudents}
              buttonErrorClasses={buttonErrorClasses}
              handleToggle={handleToggleStudents}
              leftChecked={leftCheckedStudents}
              rightChecked={rightCheckedStudents}
              numberOfChecked={numberOfCheckedStudents}
              handleToggleAllCheck={handleToggleAllCheckStudents}
              handleToggleAllUncheck={handleToggleAllUncheckStudents}
              titleAll={"All Students"}
              titleSeleceted={"Selected Students"}
              bodyText="No students added"
            />
          )}
        </DialogContent>
        {component.content === "main" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: { xs: "10px 38px 20px", sm: "10px 38px 20px" },
            }}
          >
            <div>
              <Button
                onClick={() => handleClose()}
                color="secondary"
                variant="contained"
                style={{
                  color: "#4FA9DC",
                  background: "white",
                  borderColor: "#4FA9DC",
                  fontSize: 18,
                  fontFamily: "Montserrat,sans-serif",
                  fontWeight: 500,
                  fontStyle: "normal",
                  boxShadow: "none",
                  margin: 5,
                  marginLeft: 8,
                  height: 43,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => (
                  handleClose(),
                  setOpenSnackbar(true),
                  mutate({
                    id: classID,
                    name: editedClassName,
                    startdate: editedClassDate,
                    level: editedClassLevel,
                    frequency: editedClassFrequency,
                    has_assigned_exercise: false,
                    current_lesson: editedClassLesson,
                    ongoing: true,
                    online: true,
                    paused: false,
                    school: tenantId,
                    students: rightStudents.map((item) => item.id),
                    teachers: right.map((item) => item.id),
                  })
                )}
                disabled={!isValid}
                color="primary"
                variant="contained"
                style={{
                  background: isValid ? "#1D6F98" : "#a3a3a3",
                  borderColor: isValid ? "#1D6F98" : "#a3a3a3",
                  color: "white",
                  fontSize: 18,
                  fontFamily: "Montserrat,sans-serif",
                  fontWeight: 500,
                  fontStyle: "normal",
                  boxShadow: "none",
                  height: 43,
                  margin: 5,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                Save and exit
              </Button>
            </div>
            <div>
              <Button
                onClick={() => toDeleteClass()}
                color="error"
                variant="contained"
                style={{
                  color: "rgb(211, 47, 47)",
                  background: "white",
                  borderColor: "rgb(211, 47, 47)",
                  fontSize: 18,
                  height: 43,
                  fontFamily: "Montserrat,sans-serif",
                  fontWeight: 500,
                  fontStyle: "normal",
                  boxShadow: "none",
                  margin: 5,
                  paddingTop: 5,
                  paddingBottom: 5,
                  marginRight: 15,
                }}
              >
                <Delete style={{ color: "rgb(211, 47, 47)", width: 25, height: 25 }} /> Delete
              </Button>
            </div>
          </Box>
        )}
      </Dialog>
    </>
  );
}
