import React, { useEffect, useState } from "react";
import {
  Redirect,
  // Redirect,
  Route,
} from "react-router-dom";
import useUserApi from "api/user";
import { useQuery } from "react-query";
import useStore from "../zustand";
import usePersistStore from "../zustandPersist";
// import useCurriculumApi from "api/curriculum";
// import useCurriculumApi from "api/curriculum";

const ProtectedRouteStudent = ({ component: Component, ...rest }) => {
  const { permission } = usePersistStore();
  const { tenantId } = useStore((state) => state);
  const { fetchStudent } = useUserApi();
  const [studentID, setStudentID] = useState("");
  const { data: student, status: studentStatus } = useQuery(
    ["student", studentID],
    () => fetchStudent(studentID)
  );

  useEffect(() => {
    setStudentID(rest.computedMatch.params.studentId);
  }, [rest.computedMatch.params.studentId]);


  return (
    <div>
      <Route
        {...rest}
        render={(props) => {
          if (tenantId === "underfined") return null;
          if (permission && permission.type === "student") {
            return <Redirect to="/activities" />;
          } else {
            return <Component {...props} />;
          }
        }}
      />
    </div>
  );
};

export default ProtectedRouteStudent;
