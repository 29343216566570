import React, { useState } from "react";
// import ItemListPerformance from "./ItemListPerformance";
import ItemListPerformanceTwo from "./ItemListPerformanceTwo";

const StudentsPerformanceListTwo = ({ students }) => {
  const [searchWord, setSearchWord] = useState("");
  // console.log("students=;=;=;=;=YYYYYYYYYY=====>", students);
  const filterList = (item, array) => {
    return students.length > 0
      ? item.student.full_name &&
          item.student.full_name
            .toLowerCase()
            .indexOf(searchWord.toLowerCase()) > -1
      : array;
  };
  if (students?.length > 0) {
    return (
      <div>
        <div
          style={{
            margin: "20px 0 0 0",
            padding: "20px 20px",

            border: "0px solid rgb(31, 110, 163)",
            background: "rgb(255, 255, 255,0.5)",
          }}
        >
          <div
            style={{
              boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
              borderRadius: "12px",
              overflow: "hidden",
            }}
          >
            {/* <ListItemHeaderComponent
              data={{
                title: "Name",
              }}
            /> */}
            <div
              style={{
                height: "310px",
                overflow: "auto",
              }}
            >
              {students
                ?.sort(
                  (a, b) =>
                    a.student.full_name !== null &&
                    b.student.full_name !== null &&
                    a.student.full_name.localeCompare(b.student.full_name)
                )
                .filter((item, key, array) => filterList(item, array))
                .map((student) => (
                  <ItemListPerformanceTwo
                    data={student?.student}
                    score={student?.score}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div
          style={{
            margin: "0px 0 0",
            padding: "30px 30px",
            border: "0px solid rgb(31, 110, 163)",
            // borderBottomLeftRadius: "8px",
            // borderBottomRightRadius: "8px",
            // justifyContent: "space-between",
            // boxShadow: "0 5px 15px hsla(0,0%, 0%, .2)",
            background: "rgb(255, 255, 255,0.5)",
          }}
        >
          <div
            style={{
              boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
              borderRadius: "12px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                height: "310px",
                overflow: "auto",
              }}
            >
              {/* no students */}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default StudentsPerformanceListTwo;
