import React from "react";
import BuilderFilterCompLevelsItem from "./BuilderFilterCompLevelsItem";

const BuilderFilterCompLevels = ({
  setLevelNamesFilter,
  levelNamesFilter,
  name,
  levels,
  levelNameInput,
  levelItemNamesFilter,
  setLevelItemNamesFilter,
  selectedLevelFilter,
  setSelectedLevelFilter,
}) => {
  // console.log("levels=============== :>> ", levels);
  // console.log("name :>> ", name);
  const filterfunc = (tempName) => {
    console.log("before levelNamesFilter :>> ", levelNamesFilter);

    if (levelNamesFilter.includes(tempName)) {
      if (levelNamesFilter.length > 1) {
        setLevelNamesFilter((prev) => prev.filter((item) => item === tempName));
      } else {
        setLevelNamesFilter([]);
      }
    } else {
      setLevelNamesFilter((prev) => [...prev, tempName]);
    }
    console.log("after levelNamesFilter :>> ", levelNamesFilter);
  };
  return (
    <div
      style={{
        background:
          levelNamesFilter.length === 0
            ? "rgba(6, 101, 157,0.08"
            : levelNamesFilter.includes(name)
            ? "rgba(6, 101, 157,0.08)"
            : "rgba(6, 101, 157,0.08)",
        // color:
        //   levelNamesFilter.length === 0
        //     ? ""
        //     : levelNamesFilter.includes(name)
        //     ? "white"
        //     : "",
      }}
    >
      <h4
        style={{
          // background:
          // levelNamesFilter.length === 0
          //   ? "rgba(6, 101, 157,0.2)"
          //   : levelNamesFilter.includes(name)
          //   ? "rgba(6, 101, 157,1)"
          //   : "rgba(6, 101, 157,0.2)",
          // background: "rgba(6, 101, 157,1)",
          background:
            levelNamesFilter.length === 0
              ? "rgba(6, 101, 157,0.2)"
              : levelNamesFilter.includes(name)
              ? "rgba(6, 101, 157,1)"
              : "rgba(6, 101, 157,0.2)",
          color: "white",
          fontSize: "12px",
          // levelNamesFilter.length === 0
          //   ? ""
          //   : levelNamesFilter.includes(name)
          //   ? "white"
          //   : "",
          borderRadius: "4px",
          padding: "4px 14px",
          margin: 0,
          // cursor:''
          cursor: "pointer",
        }}
        onClick={() => filterfunc(name)}
        // onClick={()=>setSelectedLevelFilter()}
      >
        {name}
      </h4>
      <div
        style={{
          // display: levelNamesFilter.includes(name) ? "flex" : "none",
          display: "flex",
          flexWrap: "wrap",
          // justifyContent: "space-between",
          gap: "4px",
          padding: "8px",
          display: "none",
        }}
      >
        {levelNameInput
          .filter((item) => item.split("/")[2] !== "Level 6")
          .map((item) => {
            return name === item.split("/")[0] ? (
              <BuilderFilterCompLevelsItem
                item={item}
                level={item.split("/")[2]}
                levelItemNamesFilter={levelItemNamesFilter}
                setLevelItemNamesFilter={setLevelItemNamesFilter}
                selectedLevelFilter={selectedLevelFilter}
                setSelectedLevelFilter={setSelectedLevelFilter}
              />
            ) : null;
          })}
      </div>
    </div>
  );
};

export default BuilderFilterCompLevels;
