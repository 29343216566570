import { Typography } from "@material-ui/core";
// import { ListItemButton } from "@mui/material";
import React from "react";
import comp from "../assets/comp.png";
import temp from "../assets/temp.png";
import Box from "@mui/material/Box";

// import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import useUser from "api/user";
import { useQuery } from "react-query";
// import { LoadingScreen } from "views/eai/curriculum/Components/LoadingScreen";
import { ErrorScreen } from "views/eai/curriculum/Components/ErrorScreen";
import useStore from "../../../../zustand";
import ToDoListItemActPage from "./ToDoListItemActPage";
import useMediaQuery from "@mui/material/useMediaQuery";
import ActivityToDoSkeleton from "./ActivityToDoSkeleton";
// import EmptyState from "components/EmptyState/EmptyState";
import EmptyStateTwo from "./EmptyStateTwo";

const dataM = [
  {
    title: `Character Sets - Stage 4`,
    teacher: "Set by Ms. Samson",
    time: "1 hour exam",
    due: "Due in 2 days",
    image: comp,
  },
  {
    title: `Image - Stage 4`,
    teacher: "Set by Ms. Samson",
    time: "1 hour exam",
    due: "Due in 2 days",
    image: temp,
  },
  {
    title: `Hexidecimal  - Stage 4`,
    teacher: "Set by Ms. Samson",
    time: "1 hour exam",
    due: "Due in 2 days",
    image: comp,
  },
];
const ToDoListActPage = ({ matchesTwo, matchesThree }) => {
  const { selectedClassId } = useStore((state) => state);
  const { fetchActivityTask } = useUser();
  const matches = useMediaQuery("(max-width:1000px)");
  const matchesN = useMediaQuery("(max-width:770px)");

  const { isLoading, isError, data, error } = useQuery(
    ["todoTasks", selectedClassId],
    () => fetchActivityTask(selectedClassId),
    { enabled: selectedClassId != null, refetchOnWindowFocus: false }
  );

  function columnTemp() {
    if (!matches && !matchesN) {
      return "1fr 1fr 1fr";
    } else if (matches && !matchesN) {
      return "1fr 1fr";
    } else if (matchesN) {
      return "1fr";
    }
  }
  // console.log(matches, matchesN);
  if (data) {
    // console.log(" todo Act data ==> ", data);
    // console.log(
    //   " todo Act data.data.todo.tasks.length ==> ",
    //   data.data.todo.tasks.length
    // );
    // console.log("classId==>", selectedClassId, " todolist data ==> ", data);
  }
  if (isLoading) {
    return <ActivityToDoSkeleton />;
  }
  if (isError) {
    return <ErrorScreen error={error} />;
  }

  // if (data && data.data.todo.tasks.length === 0) {
  //   return (
  //     <div style={{ width: "100%" }}>
  //       <EmptyStateTwo
  //         header={"To Do Tasks"}
  //         title={"To do tasks"}
  //         isToDo={true}
  //         text={
  //           "Your teacher hasn't set you any task or you have completed all necessary work."
  //         }
  //       />
  //     </div>
  //   );
  // }
  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <Typography
        style={{
          fontSize: "1.4rem",
          fontWeight: "600",
          textAlign: "left",
          margin: "20px 0",
        }}
      >
        To do tasks
      </Typography>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: data && data.data.todo.tasks.length > 0 ? columnTemp() : "1fr",
          gap: "30px",
          gridAutoRows: "1fr ",
        }}
      >
        {data &&
          data.data.todo.tasks.length > 0 &&
          data.data.todo.tasks
            // .filter((task) => task.exercise.type === "H5P")
            .map((todo, index) => (
              <>
                <ToDoListItemActPage
                  todo={todo}
                  index={index}
                  lessonId={todo?.lesson?.id ? todo?.lesson?.id : null}
                />
              </>
            ))}
        {data && data.data.todo.tasks.length === 0 && (
          <div style={{ width: "100%" }}>
            <EmptyStateTwo
              title={"To do tasks"}
              isToDo={true}
              height={"400px"}
              text={
                "Your teacher hasn't set you any task or you have completed all necessary work."
              }
            />
          </div>
        )}
      </div>
    </Box>
  );
};

export default ToDoListActPage;
