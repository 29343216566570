import * as React from "react";
// import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/'


export default function VerticalToggleButtons({ view, setView }) {


    return (
        <ToggleButtonGroup value={view} exclusive style={{ color: 'white', margin: 10 }}>
            <CustomLevelTooltip
                arrow
                title={<div style={{ background: 'rgb(6, 101, 157)', padding: '5px 10px', fontSize: 12 }}>Switch to new dashboard</div>}
                placement="left"
            >
                <ToggleButton onChange={() => setView(false)} value="module" aria-label="module" style={{ color: !view ? 'rgb(6, 101, 157)' : 'grey', height: 36, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                    <ViewSidebarIcon style={{ transform: "rotate(180deg)" }} />
                </ToggleButton>
            </CustomLevelTooltip>
            <CustomLevelTooltip
                arrow
                title={<div style={{ background: 'rgb(6, 101, 157)', padding: '5px 10px', fontSize: 12 }}>Switch to old dashboard</div>}
                placement="bottom-start"
            >
                <ToggleButton onChange={() => setView(true)} style={{ color: view ? 'rgb(6, 101, 157)' : 'grey', height: 36, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                    <ViewModuleIcon />
                </ToggleButton>
            </CustomLevelTooltip>
        </ToggleButtonGroup>
    );
}


const CustomLevelTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} disableFocusListener />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgb(6, 101, 157)',
        color: 'white',
        maxWidth: 'none',
        width: '100%',
        fontSize: theme.typography.pxToRem(12),
        // border: '2px solid #dadde9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));