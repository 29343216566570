import React from 'react'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SchoolStatsSkeleton = () => {

    const StatsTabSkeleton = (key) => {
        return (
            <div key={key}
      style={{
        minWidth: "320px",
        background: "white",
        color: "white",
        overflow: "hidden",
        borderRadius: "8px",
        textAlign: "center",
        boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
      }}
    >
      <div
        style={{
          padding: "10px",
          background: "rgba(31, 110, 163,0.15)",
          color: "rgb(31, 110, 163)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "95%",
            gap:"10px"
          }}
        >
            <Skeleton count={1} width={20} height={20} />
            <Skeleton count={1} width={120} height={20} />
        </div>
      </div>
      <Skeleton count={1} width={80} height={30} style={{margin:"14px"}} />
    </div>

        )
    }
  return (
    <div
          style={{
            display: "flex",
            gap: "20px",
            padding: "30px 30px",
            border: "0px solid rgb(31, 110, 163)",
            borderRadius: "8px",
            flexWrap: "wrap",
            justifyContent: "space-between",
            boxShadow: "0 5px 15px hsla(0,0%, 0%, .2)",
            background: "rgb(255, 255, 255,0.5)",
          }}
        >
            {[1,2,3].map((val) => <StatsTabSkeleton key={val}/>)}
        </div>
  )
}

export default SchoolStatsSkeleton