import React from "react";

const ClassCurriculumPlayerTab = ({
  item,
  currentUrl,
  setCurrentUrl,
  page,
}) => {
  const backgroundColorFuncBtn = () => {
    if (currentUrl === item.id) {
      return "#09639D";
    } else {
      return "rgba(9, 99, 157,0.5)";
    }
  };

  return (
    <button
      style={{
        padding: "6px 10px",
        color: "white",
        background: backgroundColorFuncBtn(),
        cursor: "pointer",
        border: "none",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // fontWeight: "500",
        boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        margin: "0 0 0 5px",
        fontSize: "14px",
      }}
      onClick={() => setCurrentUrl(item.id)}
    >
      {item.name}
    </button>
  );
};
export default ClassCurriculumPlayerTab;
