import React, { useState } from "react";
import useCurriculumApi from "api/curriculum";
import { useQuery } from "react-query";
// import SubjectLessonFiles from "./SubjectLessonFiles";
import Iframe from "react-iframe";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import SubjectLessonComponent from "./SubjectLessonComponent";

const SubjectLessonView = ({ subjectLevel }) => {
  const [expand, setExpand] = useState(false);

  const [iframeLessonUrl, setIframeLessonUrl] = useState("");

  //fetching lessons and levels
  const { fetchLessons } = useCurriculumApi();

  //fetch lessons
  const { data: lessonData, status: lessonStatus } = useQuery(
    ["fetchLessons", subjectLevel],
    () => fetchLessons(subjectLevel),
    {
      refetchOnWindowFocus: false,
    }
  );

  console.log(
    "lessonData && lessonData.data :>> ",
    lessonData && lessonData.data
  );
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div
        style={{
          width: "100%",
          height: "85vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "90%",
            margin: " 20px auto",
            height: "80vh",
            border: "2px solid black",
          }}
        >
          {/* <Iframe src={iframeLessonUrl}></Iframe> */}

          <Iframe
            width="100%"
            height="100%"
            src={iframeLessonUrl}
            allow="fullscreen"
          />
        </div>
      </div>
      <div style={{ width: expand ? "50px" : "500px" }}>
        <div>
          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              background: "#07659D",
              justifyContent: "start",
              cursor: "pointer",
            }}
            onClick={() => setExpand((prev) => !prev)}
          >
            {!expand ? (
              <>
                <h3
                  style={{
                    fontSize: "1.4rem",
                    color: "white",
                    margin: "0 10px 0 20px ",
                  }}
                >
                  Course content
                </h3>{" "}
                <KeyboardDoubleArrowRightIcon
                  style={{
                    fontSize: "2rem",
                    color: "white",
                    marginRight: "10px",
                  }}
                />
              </>
            ) : (
              <>
                <KeyboardDoubleArrowLeftIcon
                  style={{
                    fontSize: "2rem",
                    color: "white",
                    marginLeft: "8px",
                  }}
                />
              </>
            )}
          </div>
          <div
            style={{
              display: expand ? "none" : "block",
              height: "80vh",
              overflow: "auto",
            }}
          >
            {lessonData?.data.map((lesson, index) => (
              <SubjectLessonComponent
                lesson={lesson}
                lessonNum={index + 1}
                setIframeLessonUrl={setIframeLessonUrl}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectLessonView;
