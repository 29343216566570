import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  // Divider,
  ListItem,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import PersonIcon from "@mui/icons-material/Person";
// import DateRangeIcon from "@mui/icons-material/DateRange";
import Modal from "@mui/material/Modal";
import { Fade } from "@material-ui/core";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import comp from "../assets/comp.png";
import useStore from "../../../../zustand";

import H5P from "../H5P";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 4,
  bgcolor: "background.paper",
  border: "none",
  // boxShadow: 24,
  // borderRadius:'8px',
  // boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  // boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
  p: 4,
  // width:'90vw',
};
const ToDoListItemHomePages = ({ todo, index, lengthToDo, todoLessonId }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const store = useStore((state) => state);
  function getDueDate(strDate) {
    const dataNew = new Date(strDate.slice(0, -1));
    let year = dataNew.getFullYear();
    let month = dataNew.getMonth() + 1;
    let dt = dataNew.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    // console.log(year + "-" + month + "-" + dt);
    // console.log(dataNew);
    // console.log(dataNew.getDate());
    // console.log(dataNew.getMonth());
    // console.log(dataNew.getDay());
    // console.log(dataNew.getUTCFullYear());
    // console.logit g(dataNew.get);

    return year + "-" + month + "-" + dt;
  }
  return (
    <div>
      <>
        <ListItem
          style={{
            padding: "8px 25px",
          }}
        >
          <Typography
            variant="h3"
            style={{
              fontSize: "15px",
              color: "#32325D",
              margin: "0px 0 6px 0",
              display: "inline-block",
            }}
          >
            {todo.exercise.name}
          </Typography>
          <Box
            style={{
              display: "flex",
              // direction="row",
              justifyContent: "space-between",
              alignItems: "center",
              // padding: "4px",
              marginBottom: "6px",
            }}
          >
            <Box style={{ width: "10%" }}>
              <ListItemIcon>
                {todo.exercise.type.toLowerCase() === "h5p" ? (
                  <img
                    style={{
                      borderRadius: "8px",
                      width: "100%",
                      maxHeight: "100px",
                    }}
                    src={comp}
                    alt=""
                  />
                ) : todo.exercise.type.toLowerCase() === "blocks" ? (
                  <img
                    src="/puzzle.png"
                    alt=""
                    style={{
                      borderRadius: "8px",
                      width: "100%",
                      maxHeight: "100px",
                    }}
                  />
                ) : (
                  <img
                    style={{
                      borderRadius: "8px",
                      width: "100%",
                      maxHeight: "100px",
                    }}
                    src={comp}
                    alt=""
                  />
                )}
              </ListItemIcon>
            </Box>

            <Box
              style={{
                width: "48%",
                marginLeft: "16px",
              }}
            >
              <Box
                display="flex"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              ></Box>
              <Box>
                <PersonIcon />{" "}
                <Typography
                  variant="p"
                  style={{
                    fontSize: "14px",
                    margin: "0 ",
                    display: "inline-block",
                  }}
                >
                  Set by {todo.exercise.author === null ? "EAI" : todo.exercise.author?.first_name}
                </Typography>
              </Box>

              {/* <Box>
                <DateRangeIcon />{" "}
                <Typography
                  variant="p"
                  style={{
                    color: "#6358A5",
                    fontSize: "14px",
                    display: "inline-block",
                    margin: "0 ",
                  }}
                >
                  {todo.due_date === null
                    ? "Open task"
                    : getDueDate(todo.due_date)}
                </Typography>
              </Box> */}
            </Box>
            <Box
              style={{
                width: "30%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Link
                to={
                  todo.exercise.type.toLowerCase() === "h5p"
                    ? `/activities/Activity/${todo.exercise.id}/${todoLessonId}`
                    : todo.exercise.type.toLowerCase() === "blocks"
                    ? `/activities/blocks/codingblocks/${todo.exercise.id}/${todoLessonId}/${todo.exercise.name}`
                    : null
                }
              >
                <Button
                  style={{
                    maxWidth: "180px",
                    backgroundColor: "#6358A5",
                    marginBottom: "0",
                    border: "2px solid #6358A5",
                    color: "white",
                    // padding: "8px 10px",
                    padding: "7px 16px",
                  }}
                  // variant="contained"
                  // onClick={handleOpen}
                >
                  Start
                </Button>
              </Link>
            </Box>
            {/* </ListItemText> */}
          </Box>
        </ListItem>
        {/* {index < lengthToDo ? <Divider /> : ""} */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          // BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box
              sx={style}
              height="98vh"
              width="95vw"
              style={{
                position: "relative",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "8px solid #6358A5",
                borderRadius: "15px 40px 15px",
                //
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "40px",
                  right: "40px",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                <HighlightOffIcon
                  style={{
                    fontSize: "2.4rem",
                    fontWeight: "700",
                    cursor: "pointer",
                  }}
                />
              </div>
              <H5P
                lessonId={todoLessonId}
                exercise={todo.exercise}
                classId={store.selectedClassId}
                // classAct={classAct}
              />
            </Box>
          </Fade>
        </Modal>
      </>
    </div>
  );
};

export default ToDoListItemHomePages;
