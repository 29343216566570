import React, { useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@material-ui/core";
import InsideView from "./InsideView";
import { IconButton } from "@mui/material";

function CurLessons({
  inputRef,
  index,
  item,
  permission,
  setContent,
  test,
  content,
  setTest,
  fileItem,
  setFileItem,
  setIframId,
  data,
}) {
  const [drop, setDrop] = React.useState(false);

  useEffect(() => {
    content?.id === item?.id && setDrop(true);
  }, [content]);

  return (
    <div>
      <Box
        style={{
          margin: "5px",
          display: "flex",
          justifyContent: "space-between",
          background: item?.files?.some((file) => file.id === fileItem?.id) ? "#06659d" : "white",
          color: item?.files?.some((file) => file.id === fileItem?.id) ? "white" : "#06659d",
          border: "2px solid #06659d",
          alignItems: "center",
          borderRadius: 4,
          // filter: permission?.access?.lessons?.includes(item.id) ? "blur(0px)" : "blur(4px)"
        }}
      >
        <h5
          onClick={() => (setContent(item), setTest(!test), setDrop(!drop))}
          style={{
            padding: "10px 10px 10px 20px",
            display: "flex",
            alignItems: "center",
            color: "inherit",
            margin: 0,
            width: "100%",
            height: 60,
            cursor: "pointer",
          }}
        >
          {`Lesson ${index + 1} ` +
            item.name
              ?.replace(`Lesson 1`, "")
              .replace(`Stage 2`, "")
              .replace(`Stage 3`, "")
              .replace(`Stage 4`, "")
              .replace(`Stage 5`, "")
              .replace(`Stage 6`, "")
              .replace(`Lesson 1`, "")
              .replace(`Lesson 2`, "")
              .replace(`Lesson 3`, "")
              .replace(`Lesson 4`, "")
              .replace(`Lesson 5`, "")
              .replace(`Lesson 6`, "")
              .replace(`Lesson 7`, "")
              .replace(`Lesson 8`, "")
              .replace(`Stage 1`, "")
              .replace(`Stage 2`, "")
              .replace(`Stage 3`, "")
              .replace(`Stage 4`, "")
              .replace(`Stage 5`, "")
              .replace(`Stage 6`, "")
              .replace(`Stage 7`, "")
              .replace(`Stage 8`, "")}
        </h5>
        {drop ? (
          <IconButton
            onClick={() => setDrop(false)}
            style={{ width: 35, height: 35, color: "inherit", marginRight: 5 }}
          >
            <ExpandMoreIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => setDrop(true)}
            style={{ width: 35, height: 35, color: "inherit", marginRight: 5 }}
          >
            <KeyboardArrowUpIcon />
          </IconButton>
        )}
      </Box>
      <Box
        style={{
          height: drop
            ? item?.files?.filter((item) => !item?.type.includes("H5P") && !item?.type.includes("Draw"))
                ?.length * 78
            : 0,
          overflow: "hidden",
          transition: "height 0.5s ease-in-out",
        }}
      >
        {item?.files
          ?.filter((item) => !item?.type.includes("H5P") && !item?.type.includes("Draw"))
          .map((file) => (
            <>
              <InsideView
                key={file.id}
                permission={permission}
                data={file}
                isPythonFile={file?.type?.includes("Misc")}
                content={fileItem}
                setContent={setFileItem}
                setTest={setTest}
                test={test}
                setIframId={setIframId}
                inputRef={inputRef}
              />
            </>
          ))}
      </Box>
    </div>
  );
}

export default CurLessons;
