import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import CurriculumCard from "components/Cards/eai/CurriculumCard";
import { CurriculumSection } from "./Components/CurriculumSection";
import { LoadingScreen } from "./Components/LoadingScreen";
import { ErrorScreen } from "./Components/ErrorScreen";
import { CurriculumPage } from "./CurriculumPage";
import useCurriculumApi from "api/curriculum";
import { useLocation } from "react-router-dom";
import CurColumnLesson from "./ColumnPage/CurColumnLesson";
import VerticalToggleButtons from "./ColumnPage/TogglePage";
import { Box, MuiThemeProvider } from "@material-ui/core";
import { createTheme } from "@mui/system";
import useStore from "../../../zustand.js";

// import { useEffect } from "react";
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1936,
    },
  },
});

function LessonsPage() {
  const params = useParams();
  const { fetchLessons } = useCurriculumApi();
  const location = useLocation();
  const textInput = useRef(null);
  const { view, setView } = useStore();

  const [alignment, setAlignment] = React.useState("all");

  function Scroll() {
    location?.lessonId &&
      textInput?.current?.scrollIntoView({ behavior: "smooth" });
  }

  const { isLoading, isError, data, error, isSuccess } = useQuery(
    ["lessons", { levelId: params?.levelId }],
    () => fetchLessons(params?.levelId),
    {
      refetchOnWindowFocus: false,
    }
  );

  React.useEffect(() => {
    isSuccess && Scroll();
  }, [location, isSuccess, view]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <ErrorScreen error={error} />;
  }
  const sections = [];
  for (const lesson of data.data) {
    const cards = [];
    for (const resource of lesson.files) {
      cards.push(
        <CurriculumCard
          key={resource?.id}
          id={resource?.id}
          entityType="resource"
          title={resource.name}
          subtitle={lesson.name}
          image={resource.image}
          downloadUrl={resource.url}
          type={resource.type}
          link={`/curriculum/resources/${resource?.id}/`}
        // arrayList={LearningObjectives}
        // tags={tags}
        // arrayTitle="Learning Objectives"
        />
      );
    }
    sections.push(
      <CurriculumSection
        lessonProp={location?.lessonId}
        lessonId={lesson?.id}
        textInput={textInput}
        name={lesson.name}
        cards={cards}
        key={lesson?.id}
      />
    );
  }
  return (
    <>
      {view ? (
        <CurriculumPage name="Lessons" sections={sections} />
      ) : (
        <MuiThemeProvider theme={theme}>
          <Box
            style={{
              color: "#323F4B",
              padding: 0,
              display: "flex",
              justifyContent: "center",
              background: "white",
              alignItems: "center",
              boxShadow: "rgb(0 0 0 / 20%) 0px 5px 15px",
              borderRadius: 4,
              margin: "10px",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                margin: "3px",
              }}
            >
              <Box
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h5
                  style={{
                    padding: "10px 10px 0px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Screen Based
                </h5>
                <div
                  style={{
                    background: "#06659d",
                    color: "white",
                    borderRadius: 4,
                    padding: "7px 30px",
                    margin: 5,
                  }}
                >
                  <h4 style={{ color: "inherit", margin: 0 }}>
                    {`${data?.data[0]?.level_name}`}
                  </h4>
                </div>
              </Box>
            </Box>
            <VerticalToggleButtons view={view} setView={setView} />
          </Box>
          <Box
            style={{
              zIndex: 99,
              background: "#f8f9fe",
              width: "100%",
              padding: 0,
              display: "flex",
              margin: "0px auto",
              justifyContent: "center",
              flexDirection: "column",
              position: "fixed",
            }}
          >
            <CurColumnLesson
              lessonType="online"
              levelId={params?.levelId}
              alignment={alignment}
              componentHeight={75}
              lessonIdFromLink={location?.lessonId}
            />
          </Box>
        </MuiThemeProvider>
      )}
    </>
  );
}

export default LessonsPage;
