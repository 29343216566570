import { Avatar, Grid, Typography } from "@material-ui/core";
import React from "react";
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import { makeStyles } from "@material-ui/core/styles";
import TaskIcon from "@mui/icons-material/Task";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
const useStyles = makeStyles(componentStyles);

const EmptyStateTwo = ({
  header,
  height,

  title,
  text,
  isToDo,
}) => {
  const classes = { ...useStyles() };

  return (
    <Grid style={{ margin: "auto" }}>
      {header && (
        <Typography
          variant="h3"
          style={{
            color: "#7B8794",
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "16px",
          }}
        >
          {header}
        </Typography>
      )}
      <div
        style={{
          width: "100%",
          padding: "12px 0px 0",
          background: "#fff",
          boxShadow: "0 5px 15px hsla(0, 0%, 0%, 0.2)",
          borderRadius: "14px",
          height: height,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Avatar
            classes={{ root: classes.avatarRoot }}
            alt="..."
            style={{ width: 90, height: 90, marginBottom: 25 }}
          >
            {isToDo ? (
              <FormatListNumberedIcon style={{ width: 60, height: 60 }} />
            ) : (
              <TaskIcon style={{ width: 60, height: 60 }} />
            )}
          </Avatar>
        </div>
        <p
          style={{
            color: "#7B8794",
            fontSize: "18px",
            fontWeight: 600,
            marginBottom: 5,
            textAlign: "center",
          }}
        >
          {title}
        </p>
        <p
          style={{
            textAlign: "center",
            maxWidth: 650,
            color: "#9AA5B1",
            fontSize: "16px",
            width: "80%",
          }}
        >
          {text}
        </p>
      </div>
    </Grid>
  );
};

export default EmptyStateTwo;
