import { Card } from "@material-ui/core";
import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import React from "react";

const SingleAwardCard = ({ title, image, progress, outline }) => {
  return (
    <Card
      style={{
        padding: outline ? "14px 12px" : "18px 14px",
        // margin: outline ? "5px" : "",
        border: outline ? "2px solid rgba(169, 169, 169,0.5)" : "",
      }}
      sx={{
        backgroundColor: "white",
        // width: '30%',
        mt: "2",
        mb: "2",
        boxShadow: " 0 10px 15px hsla(0, 0%, 0%, 0.096)",
      }}
    >
      <Stack
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        sx={{ opacity: `${progress < 100 ? "0.6" : "1"} ` }}
      >
        <Typography
          color="#32325D"
          gutterBottom
          variant="h6"
          width="90%"
          mt={1}
          mb={1}
          style={{
            fontSize: "16px",
            fontWeight: "500",
            textAlign: "center",
            lineHeight: "18px",
          }}
        >
          {title}
        </Typography>
        <Box mt={1} mb={1}>
          <img src={image} alt="" />
        </Box>
        <Box mt={1} mb={1} sx={{ width: "90%" }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              // backgroundColor: 'rgba(45, 206, 136, 0.4)',
              borderRadius: "10px",
              height: "5px",
              backgroundColor: "#E4E7EB",
              "& .MuiLinearProgress-bar": {
                background: `linear-gradient(201.51deg, #E94D67 10.68%, #EA875F 90.09%);`,
              },
            }}
          />
        </Box>
      </Stack>
    </Card>
  );
};

export default SingleAwardCard;
