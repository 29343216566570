import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import componentStyles from "assets/theme/views/admin/dashboard.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";

const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

export const CurriculumSection = ({ name, cards, actionButton, textInput, lessonProp, lessonId }) => {

  const classes = { ...useStyles(), ...useStylesCardDeck() };
  return (
    <>
      {lessonProp === lessonId && <div ref={textInput}></div>}
      <Box sx={{ padding: "20px" }}>
        <Grid
          direction="row"
          alignItems="center"
          container
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h1" style={{ margin: "8px 0px" }}>
              {name}
            </Typography>
          </Grid>
          <Grid item>{actionButton}</Grid>
        </Grid>
      </Box>
      <Grid container style={{ minHeight: "350px", padding: '0px 20px' }}>
        {cards.map((card) => (
          <Grid
            key={card.key}
            xs={12}
            sm={6}
            md={4}
            lg={3}
            item
            classes={{ root: classes.gridItemRoot }}
          >
            {card}
          </Grid>
        ))}
      </Grid>
    </>
  );
};
