import useUserApi from "api/user";
import React from "react";
import { useQuery } from "react-query";
import useStore from "../../../../../zustand";
// import Chart from "chart.js";
import { Box, Card } from "@material-ui/core";
// import Avatar from "@material-ui/core/Avatar";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import useCurriculumApi from "api/curriculum";
// import ControlPanelComp from "./ControlPanelComp";
// core components

// import { chartOptions, parseOptions } from "variables/charts.js";
const DataSiteHeader = () => {
  const { tenantId, setSchoolName } = useStore();
  const { fetchSchool } = useUserApi();
  const { fetchLessonPlan } = useCurriculumApi();

  const {
    status: schoolDataStatus,
    data: schoolData,
    isLoading: isLoadingSchoolData,
  } = useQuery(["schoolData", tenantId], () => fetchSchool(tenantId), {
    enabled: tenantId?.length > 0,
    refetchOnWindowFocus: false,
  });
  const {
    data: lessonPlanData,
    status: lessonPlanStatus,
    refetch,
  } = useQuery(["lessonPlanData"], () => fetchLessonPlan(), {
    enabled: false,
    refetchOnWindowFocus: false,
  });
  if (isLoadingSchoolData) {
    <h1>Loading...</h1>;
  }
  if (schoolData) {
    // console.log("schoolData.data :>> ", schoolData.data);
  }
  return (
    <Card style={{ background: "transparent", boxShadow: "none", paddingLeft: 0 }}>
      <Box alignItems="center" display="flex" padding={0}>
        <Box component={AccountBalanceIcon} marginRight="1rem" alt="..." width={55} height={55} />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
          }}
        >
          <Box fontSize="24px" component="span" className="name" fontWeight={500} color="#323F4B">
            {schoolDataStatus === "success" && schoolData.data ? schoolData.data.name : "School"}
          </Box>
          {/* <ControlPanelComp /> */}
        </div>
        {/* <button
          style={{
            width: "200px",
            display: "flex",
            padding: "10px",
            borderRadius: "8px",
            border: "none",
            boxShadow: "0 1px 3px rgba(0,0,0,0.3)",
            background: "white",
            fontWeight: "500",
          }}
          onClick={() => refetch()}
        >
          Fetch lesson plan
        </button> */}
      </Box>
    </Card>
  );
};

export default DataSiteHeader;
