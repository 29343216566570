// import useDataApi from "api/data";
import useCurriculumApi from "api/curriculum";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
// import { Link } from "react-router-dom";
import ClassLessonLearningObj from "./ClassLessonLearningObj";
// import ClassLessonResults from "./ClassLessonResults";
import LessonCompThinkScore from "./LessonCompThinkScore";
// import LessonCompThinkScore from "./LessonCompThinkScore";
import LessonPerformTopics from "./LessonPerformTopics";
// import LessonTabs from "./LessonTabs";
// import { useQueryClient, useQuery, useMutation } from "react-query";
// import Tooltip from "@mui/material/Tooltip";
// import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import CreateTest from "../../components/CreateTest/Test";
import useUserApi from "api/user";
import useDataApi from "api/data";
import GeneralSnackbar from "components/Snackbar/GeneralSnackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@mui/material/Snackbar";
import useStore from "../../../../../zustand.js";
import usePersistStore from "../../../../../zustandPersist";
// import ActivityCurriculumPlayer from "views/eai/activities/ActivityCurriculumPlayer";
import ClassCurriculumPlayer from "./ClassCurriculumPlayer";
import AnimateHeight from "react-animate-height";
import LessonPerformanceHeader from "./LessonPerformanceHeader";
import LessonTab2 from "./LessonTab2";

const LessonPerformanceMain = ({ classData, customLessonPlanData, standardLessonPlanData }) => {
  const queryClient = useQueryClient();

  const {
    fetchLessons,
    fetchSubjects,
    fetchAvailableLevels,
    fetchCustomLevel,
    fetchLessonObjectives,
    fetchLessonPlanId,
  } = useCurriculumApi();
  const [selectedLesson, setSelectedLesson] = useState("");
  const { setChosenLevel } = usePersistStore();
  const { setLessonLearningObjectives, dataFromApi, setLessonFromClass } = useStore();
  const { addQuiz, patchClass } = useUserApi();
  const { fetchClassStudents } = useDataApi();
  const [height, setHeight] = React.useState(0);

  //fetch Lessons
  const { data: lessonData, status: lessonStatus } = useQuery(
    ["lessonClass", classData?.level],
    () => classData?.level && fetchLessons(classData?.level),
    {
      refetchOnWindowFocus: false,
    }
  );
  //fetch subjects
  const { data: subjectsData, status: subjectsStatus } = useQuery(
    ["subjectsClass", classData?.level],
    () => fetchSubjects(),
    {
      refetchOnWindowFocus: false,
    }
  );

  // console.log(
  //   "subjectsData && subjectsData.data",
  //   subjectsData &&
  //     subjectsData.data[0].categories.filter((item) => item.name === "Computational Thinking")[0]?.courses
  // );

  //fetch Custom Lessons
  // const { status: statusLevel, data: dataLevel } = useQuery(
  //   ["customLevel", classData?.level],
  //   () => fetchCustomLevel(classData?.level),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );
  // useEffect(() => {
  //   first

  //   return () => {
  //     second
  //   }
  // }, [lessonPlanData])

  // console.log("standardLessonPlanData :>> ", standardLessonPlanData[0]);
  // console.log("customLessonPlanData :>> ", customLessonPlanData);

  const [lessonResourcesData, setLessonResourcesData] = useState(null);
  const [curriculumPlayerLessonId, setCurriculumPlayerLessonId] = useState(null);
  const [curriculumPlayerLessonIs, setCurriculumPlayerLessonIs] = useState(null);
  const [curriculumName, setCurriculumName] = useState("");
  const [cLesson, setClesson] = useState([]);
  const [isFirst, setIsFirst] = useState(true);
  // console.log("lessonData?.data[0] :>> ", lessonData?.data[0]);
  // console.log("standardLessonPlanData :>> ", standardLessonPlanData);
  // useEffect(() => {
  //   // mutatePatch({ current_lesson: null, id: classUuid });
  //   if (lessonData?.data[0]) {
  //     setSelectedLesson(lessonData?.data[0].id);
  //     setClesson(lessonData?.data?.filter((item) => classData?.current_lesson == item.id));
  //   } else if (dataLevel?.data?.lessons) {
  //     setSelectedLesson(dataLevel?.data?.lessons?.id);
  //     setClesson(dataLevel?.data?.lessons?.filter((item) => classData?.current_lesson == item.id));
  //   }
  // }, [lessonData, dataLevel]);

  useEffect(() => {
    // mutatePatch({ current_lesson: null, id: classUuid });
    if (isFirst) {
      if (standardLessonPlanData?.length > 0) {
        setSelectedLesson(classData?.current_lesson);
        setCurriculumName(standardLessonPlanData[0]?.name);

        setClesson(
          standardLessonPlanData[0]?.lessons?.filter((item) => classData?.current_lesson == item.id)
        );
        setIsFirst(false);
      } else if (customLessonPlanData?.length > 0) {
        setSelectedLesson(classData?.current_lesson);
        setCurriculumName(customLessonPlanData[0]?.name);
        setClesson(
          customLessonPlanData[0]?.lessons?.filter((item) => classData?.current_lesson == item.id)
        );
        setIsFirst(false);
      }
    }
  }, [customLessonPlanData, standardLessonPlanData]);

  const { data: availableLevels, status: availableLevelsStatus } = useQuery(
    ["available_levels", classData?.id],
    () => fetchAvailableLevels(classData?.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  // const availableLevel = availableLevelsStatus == "success" ? availableLevels.data : [];

  // const curName =
  //   availableLevelsStatus == "success" &&
  //   availableLevel?.filter((item) => item.id === classData?.level);
  const className = classData?.name;
  const classId = classData?.id;
  const classUuid = classData?.id;
  // console.log("availableLevel && availableLevel :>> ", availableLevel && availableLevel);
  // console.log("curName :>> ", curName);
  // const currentCategory = dataFromApi?.filter((item) =>
  //   item.categories.some(
  //     (category) => curName?.length > 0 && curName[0].full_name.includes(category.name)
  //   )
  // );

  // const categoryId = currentCategory[0]?.categories?.filter(
  //   (category) => curName?.length > 0 && curName[0].full_name.includes(category.name)
  // );

  // const allCourses = dataFromApi
  //   ?.filter((item) =>
  //     item.categories.some(
  //       (category) => curName?.length > 0 && curName[0].full_name.includes(category.name)
  //     )
  //   )[0]
  //   ?.categories?.filter((category) => category.courses);

  // const indexOfLevel = allCourses
  //   ?.filter((category) =>
  //     category.courses[0].levels.some((item) => item.id === classData?.level)
  //   )[0]
  //   ?.courses[0].levels.findIndex((item) => item.id === classData?.level);
  // const lessonChecker = () => {
  //   if (curName.length > 0 && curName[0].full_name.length > 0) {
  //     if (curName[0].full_name.includes("CUSTOM") || curName[0].full_name.includes("Custom")) {
  //       return `/curriculum/myLevels/${classData?.level}/lessons`;
  //     } else {
  //       // return `/curriculum/levels/${classData?.level}/lessons`;
  //       return categoryId?.length > 0 && `/curriculum/categories/${categoryId[0]?.id}/courses/`;
  //     }
  //   }
  // };

  // fetching student data
  const {
    data: students,
    status: studentsStatus,
    isLoading: studnetsIsLoading,
  } = useQuery(
    ["class_students_lesson_performance", classId, cLesson[0]?.id],
    () => fetchClassStudents(classId, cLesson[0]?.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const studentList =
    studentsStatus == "success" && students.data.students.map((item) => item.student.id);

  //fetch lesson learning objectives
  const { data: lessonLO, status: statusLO } = useQuery(
    ["lessonLearningObjectives", cLesson[0]?.id],
    () => classData && classData.current_lesson && fetchLessonObjectives(cLesson[0]?.id),
    {
      enabled: !!cLesson[0]?.id,
      refetchOnWindowFocus: false,
    }
  );
  // console.log("classData && classData", classData && classData);
  const compConceptArray = [];
  const learnObjArray = [];
  statusLO == "success" &&
    lessonLO?.data?.map((item) => {
      compConceptArray.push(item.computational_concept);
      learnObjArray.push(item.id);
    });

  // Create new Quiz
  const {
    mutate: mutateQuiz,
    isLoading: isLoadingQuiz,
    isSuccess: isSuccessQuiz,
    isError: isErrorQuiz,
    error: errorQuiz,
    status: statusQuiz,
  } = useMutation((quiz) => addQuiz(quiz), {
    onSuccess: () => queryClient.invalidateQueries("class"),
  });

  // Assign activity to all
  const {
    mutate: mutatePatchActivity,
    isLoading: isLoadingUpdateActivity,
    isSuccess: isSuccessUpdateActivity,
    isError: isErrorUpdateActivity,
  } = useMutation((updatedInfo) => patchClass(updatedInfo), {
    onSuccess: () => queryClient.invalidateQueries("class"),
  });

  // Snackbar disappear
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenSnackbar(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [openSnackbar]);
  // console.log("dataLevel :>> ", dataLevel?.data);
  // console.log("dataLevel?.data :>> ", dataLevel?.data?.lessons);
  // console.log("dataLevel?.data :>> ", dataLevel?.data);
  // console.log("dataLevel?.data :>> ", dataLevel?.data);
  // console.log("lessonData?.data :>> ", lessonData?.data);
  // console.log("lessonData?.data :>> ", lessonData?.data);
  // console.log("lessonData?.data :>> ", lessonData?.data);
  // console.log("lessonData?.data :>> ", lessonData?.data);
  // console.log("lessonData?.data :>> ", lessonData?.data);
  // console.log("lessonData?.data :>> ", lessonData?.data);

  // console.log("  dataLevel?.data?.lessons :>> ", dataLevel?.data?.lessons);
  return (
    <div style={{ margin: "40px 0" }}>
      <GeneralSnackbar
        isLoading={isLoadingUpdateActivity}
        isSuccess={isSuccessUpdateActivity}
        isError={isErrorUpdateActivity}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message="You have assigned activity to all students."
      />

      <GeneralSnackbar
        isLoading={isLoadingQuiz}
        isSuccess={isSuccessQuiz}
        isError={isErrorQuiz}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message="The activity has been successfully added."
      />
      <div
        style={{
          // display: "flex",
          // justifyContent: "space-between",
          // alignItems: "center",
          // boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
          width: "100%",
          // width: "95%",
          maxWidth: "1380px",
          // margin: "auto",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px auto 30px",
        }}
      >
        <h2 style={{ fontSize: "24px" }}>Lesson Specific Performance</h2>{" "}
      </div>
      <div>
        <div style={{}}>
          <div
            style={{
              width: "100%",
              maxWidth: "1380px",
              boxShadow: " 0 1px 3px hsla(0,0%, 0%, .2)",
              margin: "auto",
            }}
          >
            <div>
              <div
                style={{
                  padding: "10px 20px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "18px",
                  margin: "0",
                  background: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div>
                    {curriculumName && (
                      <h4
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "start",
                          textAlign: "start",
                          fontSize: "20px",
                        }}
                      >
                        {/* Curriculum:{" "} */}
                        <span style={{ fontWeight: "600" }}>
                          {/* {curName[0]?.full_name.split("/")[0]} */}
                          {/* {curName[0]?.full_}{" "} */}
                          {/* {curName[0]?.name.length > 0 && curName[0]?.name.length < 8 && (
                            // <span style={{ fontWeight: "300" }}>
                            <> - {curName[0]?.full_name.split("/")[2].replace("(online)", "")}</>
                            // </span>
                          )} */}
                          {standardLessonPlanData?.length > 0
                            ? curriculumName.split("/")[0]
                            : curriculumName}
                        </span>
                        ,
                      </h4>
                    )}{" "}
                  </div>

                  <h6
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                      textAlign: "start",
                      fontSize: "20px",
                      fontWeight: "400",
                      margin: "0 0 0 12px",
                    }}
                  >
                    {" "}
                    Select a Lesson:
                  </h6>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* <Tooltip
                    title={
                      <div
                        style={{
                          fontSize: "14px",
                          background: "white",
                          color: "rgba(0,0,0,0.8)",
                          borderRadius: "4px",
                          padding: "10px",
                          margin: "8px",
                        }}
                      >
                        <h4
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          View the lesson material that is currently being
                          taught.
                        </h4>
                      </div>
                    }
                    arrow
                    placement="top"
                  >
                    <Link
                      to={{
                        pathname: lessonChecker(),
                        lessonId: cLesson[0]?.id,
                      }}
                    >
                      <div
                        onClick={() => (
                          setChosenLevel(indexOfLevel),
                          setLessonFromClass(cLesson[0]?.id)
                        )}
                        style={{
                          background: "#06659D",
                          color: "white",
                          border: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "8px 20px",
                          fontSize: "14px",
                          borderRadius: "6px",
                          fontWeight: "500",
                          boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                          marginRight: 10,
                        }}
                      >
                        <ListAltRoundedIcon
                          style={{
                            margin: "0 8px 0 0",
                          }}
                        />{" "}
                        My Curriculum
                      </div>
                    </Link>
                  </Tooltip> */}

                  {/* <CreateTest
                    saveData={() =>
                      setLessonLearningObjectives({
                        className: className,
                        lessonName: cLesson[0]?.name,
                        lessonId: cLesson[0]?.id,
                        classId: [classId],
                        classUuid: classUuid,
                        studentList: studentList,
                        compConceptArray: compConceptArray,
                        learnObjArray: learnObjArray,
                      })
                    }
                    mutate={mutateQuiz}
                    classData={classData}
                    isLoading={isLoadingQuiz}
                    isSuccess={isSuccessQuiz}
                    isError={isErrorQuiz}
                    error={errorQuiz}
                    status={statusQuiz}
                    setOpenSnackbarQuiz={setOpenSnackbar}
                    mutatePatch={mutatePatchActivity}
                    isLoadingUpdate={isLoadingUpdateActivity}
                    isSuccessUpdate={isSuccessUpdateActivity}
                    isErrorUpdate={isErrorUpdateActivity}
                  /> */}
                </div>
              </div>

              <div
                style={{
                  // height: "660px",
                  overflow: "auto",
                  display: "flex",
                  // margin: "0",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  padding: "6px 4px",
                  // padding: "10px 20px",
                  // flexDirection: "column",
                  // background: "rgba(1,0,0,0.02)",
                  borderTop: "2px solid",
                  borderBottom: "2px solid",
                  background: "white",
                  gap: "6px",
                }}
              >
                {standardLessonPlanData?.length > 0 ? (
                  <>
                    {standardLessonPlanData[0]?.lessons?.map((less, index) => (
                      <LessonTab2
                        key={less.id}
                        classData={classData}
                        lessonName={less.name}
                        lessId={less.id}
                        cLesson={cLesson}
                        actualLessonData={standardLessonPlanData[0].lessons}
                        lesson={less}
                        setClesson={setClesson}
                        number={index}
                        setSelectedLesson={setSelectedLesson}
                        lessonUuid={less.id}
                        selectedLesson={selectedLesson && selectedLesson}
                        setLessonResourcesData={setLessonResourcesData}
                        setCurriculumPlayerLessonIs={setCurriculumPlayerLessonIs}
                        setCurriculumPlayerLessonId={setCurriculumPlayerLessonId}
                        curriculumPlayerLessonId={curriculumPlayerLessonId}
                        setHeight={setHeight}
                        classId={classData?.id}
                        lessonId={less.id}
                        hasBeenAssigned={classData.assigned_exercise_lesson_history.includes(
                          less.id
                        )}
                        saveData={() =>
                          setLessonLearningObjectives({
                            className: classData.name,
                            lessonName: less.name,
                            lessonId: less.id,
                            classId: [classData.id],
                            classUuid: classData.id,
                            studentList: studentList,
                            compConceptArray: compConceptArray,
                            learnObjArray: learnObjArray,
                          })
                        }
                        mutate={mutateQuiz}
                        mutatePatch={mutatePatchActivity}
                        setOpenSnackbarQuiz={setOpenSnackbar}
                      />
                    ))}
                  </>
                ) : customLessonPlanData?.length > 0 ? (
                  <>
                    {customLessonPlanData[0]?.lessons?.map((less, index) => (
                      <LessonTab2
                        key={less.id}
                        classData={classData}
                        lessonName={less.name}
                        lessId={less.id}
                        cLesson={cLesson}
                        actualLessonData={customLessonPlanData[0].lessons}
                        lesson={less}
                        setClesson={setClesson}
                        number={index}
                        setSelectedLesson={setSelectedLesson}
                        lessonUuid={less.id}
                        selectedLesson={selectedLesson && selectedLesson}
                        setLessonResourcesData={setLessonResourcesData}
                        setCurriculumPlayerLessonIs={setCurriculumPlayerLessonIs}
                        setCurriculumPlayerLessonId={setCurriculumPlayerLessonId}
                        curriculumPlayerLessonId={curriculumPlayerLessonId}
                        setHeight={setHeight}
                        classId={classData?.id}
                        lessonId={less.id}
                        hasBeenAssigned={classData.assigned_exercise_lesson_history.includes(
                          less.id
                        )}
                      />
                    ))}
                  </>
                ) : null}
                {/* {lessonData?.data?.map((less, index) => (
                  <LessonTabs
                    key={less.id}
                    classData={classData}
                    lessonName={less.name}
                    lessId={less.id}
                    cLesson={cLesson}
                    actualLessonData={lessonData?.data}
                    lesson={less}
                    setClesson={setClesson}
                    number={index}
                    setSelectedLesson={setSelectedLesson}
                    lessonUuid={less.id}
                    selectedLesson={selectedLesson && selectedLesson}
                    setLessonResourcesData={setLessonResourcesData}
                    setCurriculumPlayerLessonIs={setCurriculumPlayerLessonIs}
                    setCurriculumPlayerLessonId={setCurriculumPlayerLessonId}
                    curriculumPlayerLessonId={curriculumPlayerLessonId}
                    setHeight={setHeight}
                  />
                ))} */}
                {/* 
                {statusLevel == "success" &&
                  dataLevel?.data?.lessons?.map((less, index) => (
                    <LessonTabs
                      classData={classData}
                      lessId={less.id}
                      lessonName={less.name}
                      cLesson={cLesson}
                      lesson={less}
                      setClesson={setClesson}
                      number={index}
                      actualLessonData={lessonData?.data}
                      setSelectedLesson={setSelectedLesson}
                      lessonUuid={less.id}
                      selectedLesson={selectedLesson && selectedLesson}
                      setLessonResourcesData={setLessonResourcesData}
                      setCurriculumPlayerLessonIs={setCurriculumPlayerLessonIs}
                      setCurriculumPlayerLessonId={setCurriculumPlayerLessonId}
                      curriculumPlayerLessonId={curriculumPlayerLessonId}
                      setHeight={setHeight}
                    />
                  ))} */}
              </div>
            </div>
          </div>

          <div
            style={{
              background: "white",
              width: "100%",
              maxWidth: "1380px",
              margin: "0 auto",
              padding: "10px 15px",
              borderBottom: "2px solid rgba(0,0,0,0.5)",
            }}
          >
            <LessonPerformanceHeader
              cLesson={cLesson}
              lessonId={selectedLesson}
              classId={classData?.id}
              setLessonResourcesData={setLessonResourcesData}
              setCurriculumPlayerLessonIs={setCurriculumPlayerLessonIs}
              setCurriculumPlayerLessonId={setCurriculumPlayerLessonId}
              curriculumPlayerLessonId={curriculumPlayerLessonId}
              setHeight={setHeight}
              height={height}
              lessonResourcesData={lessonResourcesData}
              assignActivityBtn={
                <CreateTest
                  saveData={() =>
                    setLessonLearningObjectives({
                      className: className,
                      lessonName: cLesson[0]?.name,
                      lessonId: cLesson[0]?.id,
                      classId: [classId],
                      classUuid: classUuid,
                      studentList: studentList,
                      compConceptArray: compConceptArray,
                      learnObjArray: learnObjArray,
                    })
                  }
                  mutate={mutateQuiz}
                  classData={classData}
                  isLoading={isLoadingQuiz}
                  isSuccess={isSuccessQuiz}
                  isError={isErrorQuiz}
                  error={errorQuiz}
                  status={statusQuiz}
                  setOpenSnackbarQuiz={setOpenSnackbar}
                  mutatePatch={mutatePatchActivity}
                  isLoadingUpdate={isLoadingUpdateActivity}
                  isSuccessUpdate={isSuccessUpdateActivity}
                  isErrorUpdate={isErrorUpdateActivity}
                  selectedLesCurName={cLesson[0]?.name}
                />
              }
            />
          </div>
          <AnimateHeight
            id="example-panel"
            // duration={600}
            // easing="ease"
            height={height} // see props documentation below
          >
            {height !== 0 && (
              <div
                style={{
                  width: "100%",
                  maxWidth: "1380px",
                  margin: "auto",
                  overflow: "auto",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  boxShadow: " 0 1px 3px hsla(0,0%, 0%, .2)",
                  padding: "6px 0",
                  background: "rgba(1,0,0,0.02)",
                  borderBottom: "2px solid",
                }}
              >
                <ClassCurriculumPlayer
                  // lessonResourcesData={cLesson[0]?.files?.filter(
                  //   (item) =>
                  //     item.type.toLowerCase() === "presentation" ||
                  //     item.type.toLowerCase() === "video"
                  // )}
                  lessonResourcesData={lessonResourcesData}
                  lessonName={cLesson[0]?.name}
                  lessonId={curriculumPlayerLessonId}
                  height={height}
                />
              </div>
            )}
          </AnimateHeight>
          <div
            style={{
              background: "white",
              width: "100%",

              boxShadow: " 0 1px 3px hsla(0,0%, 0%, .2)",
              maxWidth: "1380px",
              margin: "auto",
            }}
          >
            <LessonPerformTopics
              // lessonData={lessonData?.data}
              classData={classData}
              selectedLessonUUID={cLesson[0]?.id}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "3fr 1fr",
                gap: "20px",
              }}
            >
              <ClassLessonLearningObj data={cLesson[0]?.id} selectedLessonUUID={cLesson[0]?.id} />
              <LessonCompThinkScore classData={classData} selectedLessonUUID={cLesson[0]?.id} />
            </div>
            {/* <ClassLessonResults selectedLessonUUID={selectedLesson} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonPerformanceMain;
