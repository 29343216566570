import React from "react";
// @material-ui/core components

import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import CurriculumCard from "components/Cards/eai/CurriculumCard";
import { CurriculumSection } from "./Components/CurriculumSection";
import { LoadingScreen } from "./Components/LoadingScreen";
import { ErrorScreen } from "./Components/ErrorScreen";
import { CurriculumPage } from "./CurriculumPage";
import useCurriculumApi from "api/curriculum";
// import { ContactsOutlined } from "@mui/icons-material";

function OfflineResourcesPage() {
  const params = useParams();
  const { fetchLevelResources, fetchLessonResources } = useCurriculumApi();
  const { isLoading, isError, data, error } = useQuery(
    ["resources", params.levelId],
    () => {
      if (params.levelId != null) {
        return fetchLevelResources(params.levelId);
      }
      return fetchLessonResources(params.lessonId);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  console.log("data && data.data", data && data.data);
  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <ErrorScreen error={error} />;
  }
  const sectionsMap = {};
  for (const resource of data.data) {
    const resourceTypeName =
      resource.type.name == "H5P"
        ? "Interactive Activities"
        : resource.type.name;
    const card = (
      <CurriculumCard
        id={resource?.id}
        entityType="resource"
        title={resource.name}
        subtitle={resourceTypeName}
        image={resource.image}
        link={`/curriculum/resources/${resource?.id}/`}
      />
    );

    !(resourceTypeName in sectionsMap) && (sectionsMap[resourceTypeName] = []);
    sectionsMap[resourceTypeName].push(card);
  }
  const sections = [];
  for (const [name, cards] of Object.entries(sectionsMap)) {
    sections.push(<CurriculumSection name={name} cards={cards} />);
  }
  return <CurriculumPage name="Resources" sections={sections} />;
}

export default OfflineResourcesPage;
