import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CardListSkeleton from "./CardListSkeleton";
const ClassesComponentSkeleton = () => {
  return (
    <div className="cardListSkeletonContainer" style={{
        padding: "20px",
        border: "0px solid  #05649D",
        borderRadius: "8px",
        boxShadow: "0 5px 15px hsla(0,0%, 0%, .2)",
        background: "rgb(255, 255, 255,0.5)",
    }}>
        <div className="filters" style={{
             display: "flex",
             padding: "0px 8px",
             alignItems: "center",
             justifyContent: "space-between",
             marginBottom: "26px",
        }}>
            <div className="filters-left">
                <Skeleton count={1} width={240} height={40} />
            </div>
            <div className="filter-right" style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
            }}>
                {/* // ! Need to use 3 seperate skeletons for the buttons skeletons to be horizontal
                - if you use count={3} it will stack as columns */}
                <Skeleton count={1} width={34} height={34} />
                <Skeleton count={1} width={34} height={34} />
                <Skeleton count={1} width={34} height={34} />
                <Skeleton count={1} width={145} height={38} />
            </div>
        </div>
        <div className="cardListSkeletons" style={{padding: "20px 0px",}}>
            {[1,2,3,4].map((val => <CardListSkeleton key={val} /> ))}
            <CardListSkeleton/>
            <CardListSkeleton/>
            <CardListSkeleton/>
            <CardListSkeleton/>
        </div>

    </div>
  );
};

export default ClassesComponentSkeleton;
