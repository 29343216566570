import axios from "axios";
import useStore from "../zustand";

export async function fetchTenantId(subdomain) {
  console.log("subdomain: outside if>> ", subdomain);
  if (subdomain !== "metis") {
    console.log('if (subdomain !== "metis") ');
    console.log("subdomain:>> ", subdomain);
    console.log("===============");

    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_PATH}/users/api/school_tenant/${subdomain}`
      );
      if (resp && resp.status === 200 && resp.data) {
        return resp.data;
      }
    } catch {
      return false;
    }
  }

  return false;
}

const useUserApi = (subdomain) => {
  const { authToken, accessToken, userId } = useStore((state) => state);
  function fetchTenantIdNew() {
    // if (subdomain !== "metis") {

    console.log("subdomain fetchTenantIdNew :>> ", subdomain);
    return axios.get(`${process.env.REACT_APP_API_PATH}/users/api/school_tenant/${subdomain}`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
    // }
  }

  function setStillActive() {
    return axios.post(
      `${process.env.REACT_APP_API_PATH}/users/api/still_active/`,
      {},
      { headers: { Authorization: `Bearer ${authToken}` } }
    );
  }

  function setAcceptedTerms() {
    return axios.post(
      `${process.env.REACT_APP_API_PATH}/users/api/accepted_terms`,
      {},
      { headers: { Authorization: `Bearer ${authToken}` } }
    );
  }

  function getAcceptedTerms() {
    return axios.get(`${process.env.REACT_APP_API_PATH}/users/api/accepted_terms`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  }

  function fetchStudents() {
    return axios.get(`${process.env.REACT_APP_API_PATH}/users/api/students/`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function fetchStudent(studentId) {
    return axios.get(`${process.env.REACT_APP_API_PATH}/users/api/students/${studentId}/`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function addStudents(students) {
    return axios.post(`${process.env.REACT_APP_API_PATH}/users/api/students/`, students, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function updateStudentInfo(student) {
    const id = student.id;
    delete student["id"];
    return axios.put(`${process.env.REACT_APP_API_PATH}/users/api/students/${id}/`, student, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function deleteStudent(student) {
    return axios.delete(`${process.env.REACT_APP_API_PATH}/users/api/students/${student}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function fetchTeachers() {
    return axios.get(`${process.env.REACT_APP_API_PATH}/users/api/teachers/`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function addTeachers(teachers) {
    console.log("teachers and:>> ", teachers);
    return axios.post(`${process.env.REACT_APP_API_PATH}/users/api/teachers/`, teachers, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function updateTeacherInfo(teacher) {
    const id = teacher.id;
    delete teacher["id"];
    return axios.put(`${process.env.REACT_APP_API_PATH}/users/api/teachers/${id}/`, teacher, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function deleteTeacher(teacher) {
    return axios.delete(`${process.env.REACT_APP_API_PATH}/users/api/teachers/${teacher}/`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function fetchUser(userId) {
    return axios.get(`${process.env.REACT_APP_API_PATH}/users/api/users/${userId}/`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function fetchClasses() {
    return axios.get(`${process.env.REACT_APP_API_PATH}/users/api/classes/`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }
  function fetchActivityTask(classId) {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/users/api/activities/tasks/?class=${classId}`,
      {
        // params: {
        //   class: selectedClassId,
        // },
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }
  function fetchRecentActivities(classId) {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/users/api/activities/recent/?class=${classId}`,
      {
        // params: {
        //   class: selectedClassId,
        // },
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }

  function fetchRecentActivityLessons(userId) {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/users/api/lessonsandclassesdetails/?userId=${userId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }

  function fetchOnlineStudents() {
    return axios.get(`${process.env.REACT_APP_API_PATH}/users/api/number_online_users/`, {
      // params: {
      //   online: true,
      // },
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function fetchClass(classId) {
    return axios.get(`${process.env.REACT_APP_API_PATH}/users/api/classes/${classId}/`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }
  function fetchSchool(schoolId) {
    return axios.get(`${process.env.REACT_APP_API_PATH}/users/api/schools/${schoolId}/`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }
  function updateClass(updatedClass) {
    // console.log('updatedClass', updatedClass)
    const id = updatedClass.id;
    delete updatedClass["id"];
    return axios.put(`${process.env.REACT_APP_API_PATH}/users/api/classes/${id}/`, updatedClass, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function patchClass(updatedClass) {
    const id = updatedClass.id;
    delete updatedClass["id"];
    return axios.patch(`${process.env.REACT_APP_API_PATH}/users/api/classes/${id}/`, updatedClass, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function addClass(newClass) {
    return axios.post(`${process.env.REACT_APP_API_PATH}/users/api/classes/`, newClass, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function deleteClass(classId) {
    return axios.delete(`${process.env.REACT_APP_API_PATH}/users/api/classes/${classId}/`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function fetchSchoolInfo() {
    return axios.get(`${process.env.REACT_APP_API_PATH}/data/api/school/statistics/`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function fetchClassOverview() {
    return axios.get(`${process.env.REACT_APP_API_PATH}/data/api/school/class_overview/`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function fetchCurrentClass(classId) {
    return axios.get(
      `${process.env.REACT_APP_API_PATH}/data/api/school/class_overview/${classId}/`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }

  function fetchSelectedData() {
    return axios.get(`${process.env.REACT_APP_API_PATH}/users/api/preferences/${userId}/`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function saveSelectedData(preferences) {
    return axios.put(
      `${process.env.REACT_APP_API_PATH}/users/api/preferences/${userId}/`,
      preferences,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }

  function fetchQuiz() {
    return axios.get(`${process.env.REACT_APP_API_PATH}/users/api/test/quiz/`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function addQuiz(quiz) {
    return axios.post(`${process.env.REACT_APP_API_PATH}/users/api/test/quiz/`, quiz, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function addCodingSpace(coding) {
    return axios.post(`${process.env.REACT_APP_API_PATH}/users/api/test/coding_space/`, coding, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  function addSiteAction(action) {
    return axios.post(`${process.env.REACT_APP_API_PATH}/users/api/site_actions/`, action, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }

  return {
    setAcceptedTerms,
    getAcceptedTerms,
    fetchStudent,
    fetchActivityTask,
    fetchRecentActivities,
    fetchOnlineStudents,
    fetchStudents,
    fetchTeachers,
    fetchClasses,
    fetchClass,
    fetchUser,
    addStudents,
    updateStudentInfo,
    deleteStudent,
    addTeachers,
    updateTeacherInfo,
    deleteTeacher,
    updateClass,
    patchClass,
    addClass,
    deleteClass,
    setStillActive,
    fetchTenantIdNew,
    fetchSchool,
    fetchSchoolInfo,
    fetchClassOverview,
    fetchCurrentClass,
    fetchQuiz,
    fetchRecentActivityLessons,
    addQuiz,
    addCodingSpace,
    addSiteAction,
    fetchSelectedData,
    saveSelectedData,
  };
};

export default useUserApi;
