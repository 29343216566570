import React from "react";
import { useQuery } from "react-query";
import useCurriculumApi from "api/curriculum";
import BuilderCurriculumPlayerView from "./BuilderCurriculumPlayerView";

const BuilderCurriculumPlayer = ({
  selectedLessonIdPlayer,
  lessonName,
  learningObjectives,
  addLesson,
  deleteLesson,
  selectedLesson,
  selectedLessons,
  setExpandLO,
  expandLO,
}) => {
  const { fetchLevelResources, fetchLessonResources } = useCurriculumApi();

  const { isLoading, isError, data, error } = useQuery(
    ["resourcesBuilderPagePlayer", selectedLessonIdPlayer, selectedLesson],
    () => {
      return fetchLessonResources(selectedLessonIdPlayer);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  // console.log("selectedLessonIdPlayer :>> ", selectedLessonIdPlayer);
  // console.log("lessonName :>> ", lessonName);
  // console.log("data && data.data :>> ", data && data.data);
  // console.log("learningObjectives :>> ", learningObjectives);
  if (data) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
          // flexDirection: "column",
        }}
      >
        <BuilderCurriculumPlayerView
          lessonResourcesData={
            data &&
            data?.data.filter(
              (item) =>
                item.type.name.toLowerCase() === "presentation" ||
                item.type.name.toLowerCase() === "video"
            )
          }
          lessonName={selectedLesson.name}
          learningObjectives={learningObjectives}
          addLesson={addLesson}
          deleteLesson={deleteLesson}
          selectedLesson={selectedLesson}
          selectedLessons={selectedLessons}
          setExpandLO={setExpandLO}
          expandLO={expandLO}
          selectedLessonIdPlayer={selectedLessonIdPlayer}
        />
      </div>
    );
  } else return null;
};

export default BuilderCurriculumPlayer;
