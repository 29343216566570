import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Box, Typography } from "@material-ui/core";

const ActivityToDoSkeleton = () => {
  const arr1 = new Array(9).fill(0);
  const arr2 = new Array(6).fill(0);
  return (
    <>
      <Box
        sx={{ maxWidth: "1280px", margin: "24px auto", width: "95%" }}
        mt={4}
      >
        {/* <Typography
          style={{
            marginBottom: "20px",
            fontSize: "3rem",
            fontWeight: "700",
            textAlign: "left",
          }}
          variant="h1"
        >
          My Activities
        </Typography> */}
        <Typography
          style={{
            fontSize: "1.4rem",
            fontWeight: "600",
            textAlign: "left",
            margin: "20px 0",
          }}
        >
          To do tasks
        </Typography>
        <Box
          style={{
            // background: "#fff",
            // borderRadius: "12px",
            // boxShadow: " 0 5px 15px hsla(0, 0%, 0%, 0.2)",
            margin: "20px 0",
            display: "grid",
            gap: "30px",
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          {arr1.map((item) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px ",
                boxShadow: " 0 10px 15px hsla(0, 0%, 0%, 0.096)",
                borderRadius: "12px",
                background: "#fff",
              }}
            >
              <Skeleton circle width={50} height={50} />
              <div
                style={{
                  width: "80%",
                }}
              >
                <Skeleton count={3} />
              </div>
            </div>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ActivityToDoSkeleton;
