import React from "react";
import { Link } from "react-router-dom";
import CreateClassButton from "./CreateClassButton";

const ControlPanelComp = () => {
  return (
    <div style={{ display: "flex", gap: "4px" }}>
      <Link
        to={"/data/students"}
        variant='contained'
        style={{
          background: "#06659D",
          color: "white",
          // height: "43px",
          // fontSize: "14px",
          border: "none",
          cursor: "pointer",
          padding: "8px 20px",
          fontSize: "14px",
          borderRadius: "6px",
          fontWeight: "500",
          boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
          //   marginLeft: "10px",
          //   marginRight: "2px",
          //   marginBottom: "1px",
        }}
      >
        Manage Students
      </Link>
      <Link
        to={"/data/teachers"}
        variant='contained'
        style={{
          background: "#06659D",
          color: "white",
          // height: "43px",
          // fontSize: "14px",
          border: "none",
          cursor: "pointer",
          padding: "8px 20px",
          fontSize: "14px",
          borderRadius: "6px",
          fontWeight: "500",
          boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
          //   marginLeft: "10px",
          //   marginRight: "2px",
          //   marginBottom: "1px",
        }}
      >
        Manage Teachers
      </Link>
      <CreateClassButton />
    </div>
  );
};

export default ControlPanelComp;
