import { Box, CssBaseline, ThemeProvider } from "@material-ui/core";
import ActivitiesLayout from "layouts/Activities";
import AuthLayout from "layouts/Auth";
import BuilderLayout from "layouts/Builder";
import ThemeHigh from "@blockly/theme-highcontrast";
import CurriculumLayout from "layouts/Curriculum";
import usePersistStore from "./zustandPersist.js";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import useCurriculumApi from "api/curriculum";
import useUserApi from "api/user";
import theme from "assets/theme/theme.js";
import {
  // deleteCookie,
  // getCookieValue,
  // getCookieValue,
  request,
  requestAccessToken,
} from "auth";
import { ErrorBoundaryView } from "ErrorBoundary";
import { useEffect, useState } from "react";
import DataSiteLayout from "layouts/Datasite";
import TermsConditionsPage from "views/eai/terms/TermsConditionsPage";
import useStore from "./zustand";
// import { getCookieValue } from "auth";
// import { loginBlocks } from "auth";

const queryClient = new QueryClient();

export const App = () => {
  const { instance, accounts, inProgress } = useMsal();
  const {
    authToken,
    setData,
    setAuthToken,
    userId,
    setUserId,
    accessToken,
    setAccessToken,
    setUserIcon,
    setTenantId,
    tenantId,
    user,
    keycloak,
    setKeycloak,
  } = useStore((state) => state);
  const { setPermission, setBlocksTheme, permission } = usePersistStore();

  const { fetchPermission } = useCurriculumApi();
  const { fetchUser, setStillActive, addSiteAction } = useUserApi();

  const [firstLoad, setFirstLoad] = useState(false);
  const [defaultRoute, setDefaultRoute] = useState("");

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(async () => {
      if (accounts.length > 0 && instance) {
        const token = await requestAccessToken(instance, accounts[0]);
        if (token && token.idToken) {
          setAccessToken(token.accessToken);
          setAuthToken(token.idToken);
        }
      }
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, [firstLoad, instance, accounts]);

  function sendLoginAction() {
    if (userId?.length > 0) {
      // setStillActive();
      const action = {
        site: "metis",
        action: "login",
        user: userId,
      };
      addSiteAction(action);
    }
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      if (authToken.length > 0 && userId?.length > 0) {
        // setStillActive();
        const action = {
          site: "metis",
          action: "is_active",
          user: userId,
        };
        addSiteAction(action);
      }
    }, 1000 * 10);
    return () => clearInterval(interval);
  }, [authToken, userId]);

  const loginUser = () => {
    instance
      .handleRedirectPromise()
      .then((authResult) => {
        // Check if user signed in
        const account = instance.getActiveAccount();
        if (!account) {
          sendLoginAction();
          // redirect anonymous user to login page
          instance.loginRedirect(request);
        }
        // console.log("authResult:", authResult);
      })
      .catch((err) => {
        // TODO: Handle errors
        console.log("Error :", err);
      });
  };

  async function getDefaultRoute() {
    const permissions = await fetchPermission();
    if (permissions && permissions.status === 200 && permissions.data) {
      setPermission(permissions.data);
      if (permissions.data.type === "student") {
        return setDefaultRoute("/activities");
      } else {
        return setDefaultRoute("/data/school/");
      }
    }
  }

  useEffect(() => {
    setBlocksTheme(ThemeHigh);
  }, []);

  // useEffect(() => {
  //   if (getCookieValue("blocks_logged_in") != "true") {
  //     // loginBlocks();
  //     // setBlocksLoggedIn(true);
  //   }
  // }, []);

  useEffect(() => {
    if (userId?.length > 0) {
      const action = {
        site: "metis",
        action: "page_view",
        page: location.pathname,
        user: userId,
      };
      addSiteAction(action);
    }
  }, [location]);

  useEffect(() => {
    if (userId && authToken) {
      getDefaultRoute();
      fetchUser(userId).then((result) => {
        setUserIcon(result.data.icon);
      });
    }
  }, [userId, authToken]);

  useEffect(() => {
    if (accounts.length > 0 && instance) {
      (async () => {
        if (!authToken || !userId) {
          let token = null;
          try {
            token = await requestAccessToken(instance, accounts[0]);
            document.cookie = `preferred_username=${token.idTokenClaims.preferred_username};max-age=604800;domain=${process.env.REACT_APP_DOMAIN};path=/`;
            if (token && token.idToken) {
              setFirstLoad(true);
              setAuthToken(token.idToken);
              setAccessToken(token.accessToken);
            }
            if (token && token.uniqueId) {
              Sentry.setUser({ id: token.uniqueId });
              setUserId(token.uniqueId);
              setTenantId(token.tenantId);
            }
          } catch (error) {
            if (inProgress === "none") {
              loginUser();
            }
          }
        }
      })();
    } else if (inProgress === "none") {
      loginUser();
    }
  }, [accounts, instance, inProgress]);

  if (!authToken || !accessToken) {
    return (
      <ThemeProvider theme={theme}>
        <Box
          style={{
            height: "100vh",
            textAlign: "center",
            paddingTop: "25vh",
            background: "white",
          }}
        >
          <img style={{ width: "150px" }} alt="" src="/loader.gif"></img>
        </Box>
      </ThemeProvider>
    );
  }
  return (
    <QueryClientProvider client={queryClient}>
      <UnauthenticatedTemplate>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Switch>
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          </Switch>
        </ThemeProvider>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <ThemeProvider theme={theme}>
          <Sentry.ErrorBoundary
            fallback={<ErrorBoundaryView />}
            showDialog={false}
            // dialogOptions={{
            //   user: { email: accounts[0].username, name: accounts[0].name },
            // }}
          >
            <CssBaseline />
            <Switch>
              <>
                <Route path="/curriculum" render={(props) => <CurriculumLayout {...props} />} />
                <Route path="/terms" render={(props) => <TermsConditionsPage {...props} />} />
                <Route path="/data" render={(props) => <DataSiteLayout {...props} />} />
                <Route path="/activities" render={(props) => <ActivitiesLayout {...props} />} />
                <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                <Route path="/builder" render={(props) => <BuilderLayout {...props} />} />
                <Redirect from="*" to={defaultRoute} />
              </>
            </Switch>
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      </AuthenticatedTemplate>
      {/* <ReactQueryDevtools initialOpem={false} position="bottom-right" /> */}
    </QueryClientProvider>
  );
};
