import useUserApi from "api/user";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import ItemListComponent from "./ItemListComponent";
import ListItemHeaderComponent from "./ListItemHeaderComponent";
import ManageTeachersAndStudentsSkeleton from "./skeletons/ManageTeachersAndStudentsSkeleton";

import PersonIcon from "@mui/icons-material/Person";

import DashboardEmptyState from "./DashboardEmptyState";

import RedirectBtn from "./RedirectBtn";
const ManageTeachersComponent = () => {
  const { fetchClasses, addClass, fetchTeachers, fetchStudents, fetchSchool } =
    useUserApi();
  const [searchWord, setSearchWord] = useState("");

  const header = {
    title: "Teachers",
    email: "Email",
  };
  const {
    data: teachersData,
    status: teachersStatus,
    isLoading: teachersLoading,
  } = useQuery(["teachers"], () => fetchTeachers(), {
    refetchOnWindowFocus: false,
  });
  const filterList = (item, array) => {
    return searchWord.length > 0
      ? item.full_name &&
          item.full_name.toLowerCase().indexOf(searchWord.toLowerCase()) > -1
      : array;
  };
  if (teachersData) {
  }
  if (teachersLoading) {
    return <ManageTeachersAndStudentsSkeleton />;
  }

  const Icon = (
    <PersonIcon
      style={{
        width: "80px",

        height: "80px",

        color: "#f8f9fe",

        backgroundColor: "#bdbdbd",

        padding: "1.2rem",

        borderRadius: "50%",

        marginBottom: "1rem",
      }}
    />
  );

  if (teachersStatus === "success" && teachersData.data.length === 0) {
    return (
      <DashboardEmptyState
        icon={Icon}
        title="Add teachers to your school"
        text="All teachers will appear here when created"
        button={
          <RedirectBtn redirectTo={"/data/teachers"} text={"Add Teachers"} />
        }
        isList={true}
      />
    );
  }
  if (teachersData.data.length > 0) {
    return (
      <div
        style={{
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "space-between",
          // marginBottom: "26px",
          // padding: "0 8px",
          // marginRight: "0",
          margin: "50px 0 0",
          padding: "30px 30px",
          border: "0px solid rgb(31, 110, 163)",
          borderRadius: "8px",
          justifyContent: "space-between",
          boxShadow: "0 5px 15px hsla(0,0%, 0%, .2)",
          // background: "rgb(255, 255, 255,0.5)",
          background: "rgb(255, 255, 255,1)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <Link to={"/data/teachers"}>
            <h2
              style={{
                fontSize: "20px",
                margin: "0",
                padding: "0",
              }}
            >
              Manage Teachers
            </h2>
          </Link>
          <input
            style={{
              border: "1px solid #CBD2D9",
              // height: "34px",
              fontSize: "14px",
              margin: "0px",
              padding: "6px 9px",
              borderRadius: "8px",
              minWidth: "240px",
              // marginTop: "10px",
              // marginBottom: "10px",
            }}
            placeholder="Search name..."
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
          ></input>
        </div>

        <div
          style={{
            boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
            borderRadius: "12px",
            overflow: "hidden",
          }}
        >
          <ListItemHeaderComponent data={header} />
          <div
            style={{
              height: "650px",
              overflow: "auto",
            }}
          >
            {teachersData.data
              .sort(
                (a, b) =>
                  a.full_name !== null &&
                  b.full_name !== null &&
                  a.full_name.localeCompare(b.full_name)
              )
              .filter((item, key, array) => filterList(item, array))
              .map((teacher) => (
                <ItemListComponent data={teacher} key={teacher.id} />
              ))}
          </div>
        </div>
      </div>
    );
  } else {
    return <div>no teachers </div>;
  }
};

export default ManageTeachersComponent;
