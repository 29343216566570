import styled from "styled-components";

export const HeaderWrapper = styled.div`
  width: 100%;
  .div {
    margin-bottom: 50px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }
  @media (max-width: 600px) {
    .div {
      margin-bottom: 30px;
      padding: 0 0px;
      gap: 20px;
    }
  }

  .div .left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .div .left .content-header h1 {
    font-size: 28px;
  }
  .div .left .content-header h2 {
    font-size: 20px;
  }
  .main-progress .linear-progress {
    position: relative;
    width: 200px;
    height: 20px;
    border-radius: 300px;
    background-color: #e4dcef;
  }
  .main-progress .linear-progress .progress {
    position: absolute;

    width: 30%;
    height: 20px;
    border-radius: 300px;
    background-color: #6158a0;
  }
  .main-progress h4 {
    margin-top: 18x;
    color: #aaa;
  }

  .div .selector .subject {
    min-width: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 6px 15px;
    color: #6158a0;
    border: 2px solid #6158a0;
    border-radius: 100px;
    cursor: pointer;
  }
  .div .selector {
    position: relative;
  }
  .div .selector .dropdown {
    background-color: #f5edff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    z-index: 99;
    position: absolute;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* gap: 5px; */
    padding: 5px 0;
    border-radius: 10px;
    /* border: 2px solid #6158a0; */
    margin-top: 5px;
    overflow: hidden;
  }
  .div .selector .dropdown .class-item {
    min-width: 210px;
    width: 95%;
    padding: 10px 10px 10px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    /* justify-content: center; */
    font-weight: 500;
    position: relative;
    border-bottom: #ffffffab solid 2px;

    &:hover {
      background-color: #6358a5;
      color: white;
    }
  }
  .div .selector .dropdown .class-item:last-child {
    border-bottom: none;
  }
  .div .selector .dropdown .class-item.selected-class {
    background-color: #ffffff;
    cursor: pointer;
    &:hover {
      background-color: #6358a5;
      color: white;
    }
  }
  .div .selector .dropdown .class-item .task-notification-class {
    color: white;
    font-size: 12px;
    background-color: #6358a5;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    margin-right: 10px;
    width: 22px;
    height: 22px;
    padding: 0;
    font-weight: 600;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 800px) {
    .div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      /* background-color: #6158a0; */
    }
  }
  @media (max-width: 800px) {
    .div .left {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
      /* background-color: #6158a0; */
    }
    .div .left .content-header {
      display: flex;
      align-items: center;
      gap: 10px;
      /* background-color: #6158a0; */
    }
    .div .left .content-header h1 {
      font-size: 20px;
    }
    .div .left .content-header h2 {
      font-size: 18px;
    }
    .main-progress .linear-progress {
      position: relative;
      width: 100%;
      height: 15px;
      border-radius: 300px;
      background-color: #e4dcef;
    }
    .main-progress .linear-progress .progress {
      position: absolute;

      width: 30%;
      height: 15px;
      border-radius: 300px;
      background-color: #6158a0;
    }
    .main-progress h4 {
      margin-top: 10px;
      color: #aaa;
    }
    .div .selector .dropdown {
      background-color: #f5edff;
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 10px 0;
      border-radius: 10px;
      width: 100%;
    }
  }
`;
