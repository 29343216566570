import React from "react";
import { useLocation, Switch, Redirect, Route } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// @material-ui/icons components

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
// import AdminNavbarAlternative from "components/Navbars/AdminNavbarAlternative.js";
import AdminFooter from "components/Footers/AdminFooter.js";
// import Sidebar from "components/Sidebar/Sidebar.js";

// import getCurriculumRoutes from "routes/curriculum.js";

import componentStyles from "assets/theme/layouts/admin.js";
// import { useQuery } from "react-query";
import useCurriculumApi from "api/curriculum";
import useStore from "../zustand";
import MyLevelsPage from "views/eai/builder/MyLevelsPage";
// import BuilderPage from "views/eai/builder/BuilderPage";
import CurriculumBuilderPage from "views/eai/builder/CurriculumBuilderPage";

const useStyles = makeStyles(componentStyles);

const Builder = () => {
  const classes = useStyles();
  const location = useLocation();
  const { userId } = useStore();
  const [sidebarOpenResponsive, setSidebarOpenResponsive] = React.useState(false);
  const { fetchLevels, fetchSubjects } = useCurriculumApi();
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  const routes = [
    <Route path="/builder/myLevels" component={MyLevelsPage} key="myLevels" />,
    <Redirect exact from="/builder" to="/builder/myLevels" />,
    <Route path="/builder/build" component={CurriculumBuilderPage} key="builder" />,
    // <Route
    //   path="/builder/LessonPlan"
    //   component={CurriculumBuilderPage}
    //   key="build"
    // />,
    <Route path="/builder/:levelid" component={CurriculumBuilderPage} key="/builder/:levelid" />,
  ];

  // const { data: subjects } = useQuery("subjects", fetchSubjects);
  // const { data: myLevels } = useQuery("myLevels", () => fetchLevels(true));

  // if (subjects == undefined || myLevels == undefined) {
  //   return null;
  // } else {
  // }
  // let curriculumRoutes = getCurriculumRoutes(subjects.data, myLevels.data);
  return (
    <Box>
      <Box display="flex">
        {/* <Sidebar
          routes={curriculumRoutes}
          openResponsive={sidebarOpenResponsive}
          closeSidebarResponsive={() => setSidebarOpenResponsive(false)}
          logo={{
            innerLink: "/curriculum/subjects/",
            imgSrc: require("../assets/img/brand/eai.png").default,
            imgAlt: "...",
          }}
        /> */}
        <Box position="relative" flex="1" className={classes.mainContent}>
          {/* {location.pathname === "/admin/alternative-dashboard" ? (
            <AdminNavbarAlternative
              openSidebarResponsive={() => setSidebarOpenResponsive(true)}
            />
          ) : (
            <AdminNavbar
              openSidebarResponsive={() => setSidebarOpenResponsive(true)}
              logo={{
                innerLink: "/data/school",
                imgSrc: require("../assets/img/EducationaiLogos/Group 1540.svg")
                  .default,
                imgAlt: "...",
              }}
            />
          )} */}
          <AdminNavbar
            logo={{
              innerLink: "/curriculum/subjects/",
              imgSrc: require("../assets/img/EducationaiLogos/Group 1540.svg").default,
              imgAlt: "...",
              className: "first-element",
            }}
          />
          <Box style={{ margin: "0px auto", minHeight: "100vh" }}>
            <Switch>
              {routes}
              <Redirect from="*" to="/curriculum/subjects/" />
            </Switch>
            <Container maxWidth={false} component={Box} classes={{ root: classes.containerRoot }}>
              <AdminFooter />
            </Container>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Builder;
