import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@mui/material/Snackbar";
import useUserApi from "api/user";
import GeneralSnackbar from "components/Snackbar/GeneralSnackbar";
import React, { useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import CreateClass from "../AddClass/CreateClass";
// import CreateClassModal from "../AddClass/CreateClassModal";

const CreateClassButton = () => {
  const [addNewOpen, setAddNewOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const queryClient = useQueryClient();
  const { addClass } = useUserApi();
  const {
    mutate,
    isLoading: isLoadingX,
    isSuccess: isSuccessX,
    isError: isErrorX,
  } = useMutation((newClass) => addClass(newClass), {
    onSuccess: () => {
      queryClient.invalidateQueries("classes");
    },
  });

  function handleCloseAddNew() {
    setAddNewOpen(false);
  }

  function addNewClass() {
    setAddNewOpen(true);
  }
  return (
    <>
      <CreateClass
        mutate={mutate}
        isLoadingX={isLoadingX}
        isSuccessX={isSuccessX}
        open={addNewOpen}
        close={handleCloseAddNew}
        setOpenSnackbar={setOpenSnackbar}
      />
      <GeneralSnackbar
        isSuccess={isSuccessX}
        isError={isErrorX}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message="The class has been successfully added."
      />
      <button
        variant="contained"
        onClick={addNewClass}
        style={{
          background: "#06659D",
          color: "white",
          // height: "43px",
          // fontSize: "14px",
          border: "none",
          cursor: "pointer",
          padding: "8px 20px",
          fontSize: "14px",
          borderRadius: "6px",
          fontWeight: "500",
          boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
          margin: "0",
          //   marginLeft: "10px",
          //   marginRight: "2px",
          //   marginBottom: "1px",
        }}
      >
        + Create Class
      </button>
      {/* <CreateClassModal /> */}
    </>
  );
};

export default CreateClassButton;
