import React, { useState } from "react";

import Modal from "@mui/material/Modal";
import { Fade } from "@material-ui/core";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DateRangeIcon from "@mui/icons-material/DateRange";
import PersonIcon from "@mui/icons-material/Person";
import { Button, Divider, Typography } from "@material-ui/core";
import Box from "@mui/material/Box";
import comp from "../assets/comp.png";
import useStore from "../../../../zustand";
// import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
// import ExtensionIcon from "@mui/icons-material/Extension";
// import H5P from "../H5P";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Link } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 4,
  bgcolor: "background.paper",
  border: "none",
  // boxShadow: 24,
  // borderRadius:'8px',
  // boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  // boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
  p: 4,
};
const ToDoListItemActPage = ({ todo, index, lessonId }) => {
  const store = useStore((state) => state);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  function getDueDate(strDate) {
    const dataNew = new Date(strDate.slice(0, -1));
    let year = dataNew.getFullYear();
    let month = dataNew.getMonth() + 1;
    let dt = dataNew.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    // console.log(year + "-" + month + "-" + dt);
    // console.log(dataNew);
    // console.log(dataNew.getDate());
    // console.log(dataNew.getMonth());
    // console.log(dataNew.getDay());
    // console.log(dataNew.getUTCFullYear());
    // console.logit g(dataNew.get);

    return year + "-" + month + "-" + dt;
  }
  function removeStageLevel(title) {
    return title;
    // .replace("Stage 1 -", "")
    // .replace("Stage 2 -", "")
    // .replace("Stage 3 -", "")
    // .replace("Level 1 - ", "")
    // .replace("Level 2 - ", "")
    // .replace("Level 3 - ", "")
    // .replace("Level 4 - ", "")
    // .replace("Level 5 - ", "");
  }
  let lengthToDo;
  // console.log("todo :>> ", todo);
  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div
        style={{
          background: "#fff",
          boxShadow: "0 5px 15px hsla(0, 0%, 0%, 0.2)",
          padding: "12px 20px",
          borderRadius: "8px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h3"
          style={{
            color: "#32325D",
            margin: "0px 0 6px 0",
            display: "inline-block",
            fontSize: "16px",
          }}
        >
          {removeStageLevel(todo.exercise.name)}
        </Typography>
        <div
          style={{
            display: "flex",
            // direction="row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "6px",
            gap: "5px",
          }}
        >
          <div style={{ width: "10%" }}>
            {/* <img
                style={{
                  borderRadius: "8px",
                  width: "50px",
                  // maxHeight: "120px",
                }}
                src={comp}
                alt=""
              /> */}
            {todo.exercise.type.toLowerCase() === "h5p" ? (
              <img
                style={{
                  borderRadius: "8px",
                  // boxShadow: "0 1px 2px hsla(0, 0%, 0%, 0.2)",
                  width: "60px",
                }}
                src={comp}
                alt=""
              />
            ) : todo.exercise.type.toLowerCase() === "blocks" ? (
              <img
                src="/puzzle.png"
                alt=""
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 1px 2px hsla(0, 0%, 0%, 0.2)",
                  width: "60px",
                  background: "rgba(99, 88, 165,0.1)",
                }}
              />
            ) : (
              <img
                style={{
                  borderRadius: "8px",
                  // boxShadow: "0 1px 2px hsla(0, 0%, 0%, 0.2)",
                  width: "60px",
                }}
                src={comp}
                alt=""
              />
            )}
          </div>

          <Box
            style={{
              width: "60%",
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
              alignItems="flex-start"
              style={{ marginLeft: "15px" }}
            >
              <Box>
                <PersonIcon />{" "}
                <Typography
                  variant="p"
                  style={{
                    fontSize: "14px",
                    margin: "0 ",
                    display: "inline-block",
                  }}
                >
                  Set by {todo.exercise.author === null ? "EAI" : todo.exercise.author?.first_name}
                </Typography>
              </Box>

              <Box>
                <DateRangeIcon />{" "}
                <Typography
                  variant="p"
                  style={{
                    color: "#6358A5",
                    fontSize: "14px",
                    display: "inline-block",
                    margin: "0 ",
                  }}
                >
                  {todo.due_date === null ? "Open task" : getDueDate(todo.due_date)}
                </Typography>
              </Box>

              <Box>
                <AssignmentIcon />{" "}
                <Typography
                  variant="p"
                  style={{
                    color: "#6358A5",
                    fontSize: "14px",
                    display: "inline-block",
                    margin: "0 ",
                  }}
                >
                  {todo.exercise.type === "H5P" ? "Questions" : todo.exercise.type}
                </Typography>
              </Box>
            </Box>
          </Box>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              // marginBottom: "0",
              height: "100%",
            }}
          >
            <Link
              to={
                todo.exercise.type.toLowerCase() === "h5p"
                  ? `/activities/Activity/${todo.exercise.id}/${lessonId}`
                  : todo.exercise.type.toLowerCase() === "blocks"
                  ? `/activities/blocks/codingblocks/${todo.exercise.id}/${lessonId}/${todo.exercise.name}`
                  : null
              }
            >
              <Button
                style={{
                  maxWidth: "180px",
                  backgroundColor: "#6358A5",
                  marginBottom: "0",
                  padding: "6px 14px",
                  border: "2px solid #6358A5",
                }}
                variant="contained"
                // onClick={handleOpen}
              >
                Start
              </Button>
            </Link>
          </div>
          {/* </ListItemText> */}
        </div>
      </div>
      {index < lengthToDo ? <Divider /> : ""}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={style}
            height="98vh"
            width="95vw"
            style={{
              position: "relative",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "8px solid #6358A5",
              borderRadius: "15px 40px 15px",
              //
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "40px",
                right: "40px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <HighlightOffIcon
                style={{
                  fontSize: "2.4rem",
                  fontWeight: "700",
                  cursor: "pointer",
                }}
              />
            </div>
            {/* <H5P
              // lessonId={todoLessonId}
              exercise={todo.exercise}
              classId={store.selectedClassId}
            /> */}
            {/* </Box> */}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ToDoListItemActPage;
