import React, { useEffect } from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import useUserApi from "api/user";
import { useQuery, useQueryClient } from "react-query";
import TransferList from "./TransferList";
import useStore from "../../../../../zustand";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import useCurriculumApi from "api/curriculum";
// @material-ui/core components
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
// core components
import componentStyles from "assets/theme/components/dialog.js";
import Grid from "@mui/material/Grid";
import componentStylesForms from "assets/theme/components/forms.js";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import componentStylesButton from "assets/theme/components/button.js";
import componentStylesError from "assets/theme/components/snackbar.js";
import { ErrorScreen } from "views/eai/curriculum/Components/ErrorScreen";
import NameInput from "./NameInput";
import DateInput from "./DateInput";
import FrequencyInput from "./FrequencyInput";
// import LevelInput from "./LevelInput";
import CurriculumSelector from "./CurriculumSelector";
import AnimateHeight from "react-animate-height";
import NewLevelInput from "./NewLevelInput";

const useStyles = makeStyles(componentStyles);
const useStylesButton = makeStyles(componentStylesButton);

const useStylesForms = makeStyles(componentStylesForms);
const useStylesError = makeStyles(componentStylesError);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// funcitons for CheckBox
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function CreateClass({
  open,
  close,
  setOpenSnackbar,
  mutate,
  isSuccessX,
  isLoadingX,
}) {
  const classes = { ...useStyles(), ...useStylesForms(), ...useStylesError() };
  const classesButton = useStylesButton();
  const buttonErrorClasses = { root: classesButton.buttonOutlineError };
  const queryClient = useQueryClient();
  const tenantId = useStore((state) => state.tenantId);

  // Custom validation
  const [customValidationName, setCustomValidationName] = React.useState(null);
  const [customValidationDate, setCustomValidationDate] = React.useState(null);
  const [customValidationFrequency, setCustomValidationFrequency] = React.useState(null);
  const [customValidationSelect, setCustomValidationSelect] = React.useState(null);
  const [isValid, setIsValid] = React.useState(true);

  const [nameText, setNameText] = React.useState("Class Name is required.");
  const [closeSelection, setCloseSelection] = React.useState(false);

  React.useEffect(() => {
    setIsValid(
      customValidationName == "valid" &&
        customValidationDate == "valid" &&
        customValidationFrequency == "valid" &&
        customValidationSelect == "valid"
    );
  }, [
    customValidationName,
    customValidationDate,
    customValidationFrequency,
    customValidationSelect,
  ]);

  const [component, setComponent] = React.useState({
    name: "Add Class",
    content: "main",
  });
  const [selectedCurriculum, setSelectedCurriculum] = React.useState(null);

  const [editedClassName, setEditedClassName] = React.useState("");
  const [editedClassDate, setEditedClassDate] = React.useState("");
  const [editedClassFrequency, setEditedClassFrequency] = React.useState("");
  const [editedClassLevel, setEditedClassLevel] = React.useState("");
  const [editedClassLesson, setEditedClassLesson] = React.useState("");
  const [curriculumValue, setCurriculumValue] = React.useState("");
  const [levelNamesFilter, setLevelNamesFilter] = React.useState([
    "Computational Thinking",
    "Computing (Blocks - Python)",
    "Computing (Python)",
    "GCSE CS",
    "Micro:Bit",
    "Machine Learning",
  ]);
  // //////////////////////////////////////////////////
  const [levels, setLevels] = React.useState(null);
  const [levelNames, setLevelNames] = React.useState([]);
  const [levelNameCurriculum, setLevelNameCurriculum] = React.useState(null);
  // //////////////////////////////////////////////////

  useEffect(() => {
    setEditedClassName("");
    setEditedClassDate("");
    setEditedClassFrequency("");
    setEditedClassLevel("");
    setEditedClassLesson("");
    setCustomValidationName(null);
    setCustomValidationDate(null);
    setCustomValidationFrequency(null);
    setCustomValidationSelect(null);
    setCloseSelection(false);
    setCurriculumValue("");
  }, [isSuccessX]);
  function clearAllInputFields() {
    setEditedClassName("");
    setEditedClassDate("");
    setEditedClassFrequency("");
    setEditedClassLevel("");
    setEditedClassLesson("");
    setCustomValidationName(null);
    setCustomValidationDate(null);
    setCustomValidationFrequency(null);
    setCustomValidationSelect(null);
    setCloseSelection(false);
    setCurriculumValue("");
  }

  const [dataMessage, setDateMessage] = React.useState("Start Date is required.");

  useEffect(() => {
    if (editedClassDate?.length !== 0) {
      if (
        Math.floor(moment().valueOf() / (24 * 60 * 60 * 1000) - 30) >
        Math.floor(moment(editedClassDate, "YYYY-MM-DD").valueOf() / (24 * 60 * 60 * 1000))
      ) {
        setCustomValidationDate("required");
        setDateMessage("Date must be within 1 month or in future");
      } else {
        setCustomValidationDate("valid");
      }
    }
  }, [editedClassDate]);

  // Import custom hook to fetch data from API
  const { fetchTeachers, fetchStudents, fetchClasses } = useUserApi();

  const { fetchLessonPlan } = useCurriculumApi();

  //fetch fetchLessonPlan
  const { data: lessonPlanData, status: lessonPlanStatus } = useQuery(
    ["lessonPlanData"],
    () => fetchLessonPlan(),
    {
      refetchOnWindowFocus: false,
    }
  );
  // console.log(
  //   "lessonPlanData && lessonPlanData?.data :>> ",
  //   lessonPlanData && lessonPlanData?.data
  // );
  // console.log(
  //   "lessonPlanData && lessonPlanData?.data :>> ",
  //   lessonPlanData && lessonPlanData?.data
  // );
  // console.log(
  //   "lessonPlanData && lessonPlanData?.data :>> ",
  //   lessonPlanData && lessonPlanData?.data
  // );
  // console.log(
  //   "lessonPlanData && lessonPlanData?.data :>> ",
  //   lessonPlanData && lessonPlanData?.data
  // );
  //fetch Classes
  const { data: classData, status: classStatus } = useQuery(["class"], () => fetchClasses(), {
    refetchOnWindowFocus: false,
  });

  const { data: teachers, status: teachersStatus } = useQuery("teachers", () => fetchTeachers(), {
    refetchOnWindowFocus: false,
  });

  const { data: students, status: studentsStatus } = useQuery("students", () => fetchStudents(), {
    refetchOnWindowFocus: false,
  });

  //fetching lessons and levels
  const { fetchLessons, fetchAvailableLevels } = useCurriculumApi();

  //fetch lessons
  const {
    isLoading: isLoadingLesson,
    error: isError,
    data: lessonData,
    status: lessonStatus,
  } = useQuery(["fetchLessons", editedClassLevel], () => fetchLessons(editedClassLevel), {
    enabled: editedClassLevel.length > 0,
    refetchOnWindowFocus: false,
  });

  // useEffect(() => {
  //   const currentLessonn = lessonStatus == "success" && lessonData?.data[0]?.id;
  //   setEditedClassLesson(currentLessonn);
  // }, [editedClassLevel, lessonStatus]);

  useEffect(() => {
    if (lessonPlanData && editedClassLevel?.length > 0) {
      if (curriculumValue === "EAI Lesson Plan") {
        setEditedClassLesson(
          lessonPlanData?.data?.standard_lesson_plans?.filter(
            (item) => item?.level === editedClassLevel
          )[0]?.lessons[0]?.id
        );
      } else if (curriculumValue === "Custom Lesson Plan") {
        setEditedClassLesson(
          lessonPlanData?.data?.custom_lesson_plans?.filter(
            (item) => item?.id === editedClassLevel
          )[0]?.lessons[0]?.id
        );
      }
    }
  }, [editedClassLevel, lessonPlanData]);

  // Fetch Available Levels
  const {
    data: availableLevels,
    status: availableLevelsStatus,
    isLoading: isLoadingLevels,
    isError: isErrorLevel,
  } = useQuery(["available_levels"], () => fetchAvailableLevels(), {
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    classStatus == "success" &&
      classData.data.map(
        (item) =>
          item.name.toLowerCase() == editedClassName.toLowerCase() &&
          (setCustomValidationName("required"), setNameText("Duplicate class name"))
      );
  }, [editedClassName]);

  // Assigining successfully fethced data to variables
  const allTeachers = teachersStatus == "success" ? teachers.data : [];
  const allStudents = studentsStatus == "success" ? students.data : [];

  // Checked states for Teacher
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  // Checked states for Students
  const [checkedStudents, setCheckedStudents] = React.useState([]);
  const [leftStudents, setLeftStudents] = React.useState([]);
  const [rightStudents, setRightStudents] = React.useState([]);

  // Orginizing the list of students in class and out of class
  useEffect(() => {
    studentsStatus === "success" && setLeftStudents(allStudents);
  }, [studentsStatus]);

  // Checked functions for Students
  const leftCheckedStudents = intersection(checkedStudents, leftStudents);
  const rightCheckedStudents = intersection(checkedStudents, rightStudents);

  const handleToggleStudents = (value) => () => {
    const currentIndexStudents = checkedStudents.indexOf(value);
    const newCheckedStudents = [...checkedStudents];

    if (currentIndexStudents === -1) {
      newCheckedStudents.push(value);
    } else {
      newCheckedStudents.splice(currentIndexStudents, 1);
    }

    setCheckedStudents(newCheckedStudents);
  };

  const numberOfCheckedStudents = (items) => intersection(checkedStudents, items)?.length;

  const handleToggleAllCheckStudents = (items) => () => {
    setCheckedStudents(union(checkedStudents, items));
  };

  const handleToggleAllUncheckStudents = (items) => () => {
    if (numberOfCheckedStudents(items) === items?.length) {
      setCheckedStudents(not(checkedStudents, items));
    }
  };

  const handleCheckedRightStudents = () => {
    setRightStudents(rightStudents.concat(leftCheckedStudents));
    setLeftStudents(not(leftStudents, leftCheckedStudents));
    setCheckedStudents(not(checkedStudents, leftCheckedStudents));
  };

  const handleCheckedLeftStudents = () => {
    setLeftStudents(leftStudents.concat(rightCheckedStudents));
    setRightStudents(not(rightStudents, rightCheckedStudents));
    setCheckedStudents(not(checkedStudents, rightCheckedStudents));
  };

  // Orginizing the list of teachers in class and out of class

  useEffect(() => {
    teachersStatus == "success" && setLeft(allTeachers);
  }, [teachersStatus]);

  // Checked functions for Teachers
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items)?.length;

  const handleToggleAllCheck = (items) => () => {
    setChecked(union(checked, items));
  };

  const handleToggleAllUncheck = (items) => () => {
    if (numberOfChecked(items) === items?.length) {
      setChecked(not(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  // ====================================
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
    console.log("value :>> ", value);
  };
  // //////////////////////////////////////////////////
  // //////////////////////////////////////////////////
  const tempLevelName = (dataInfo) => {
    if (dataInfo) {
      dataInfo.map((item) => {
        if (levelNames.length < lessonPlanData.data.standard_lesson_plans.length + 1) {
          setLevelNames((prev) => [...prev, item.level_name]);
        }
      });
    }
  };
  React.useEffect(() => {
    // creates and object array for the lessons, organized by curriculum => levels
    if (lessonPlanData?.data) {
      const tempLevel = lessonPlanData.data.standard_lesson_plans
        ?.filter((item) => item.level_name !== "Computational Thinking/Stage 1/Level 1(offline)")
        .filter((item) => item.level_name !== "Computational Thinking/Stage 1/Level 2(offline)")
        .filter((item) => item.level_name !== "Raspberry Pi/Basics/Level 1(online)")
        .filter((item) => item.level_name !== "Sample Category/Sample Course/Sample Level(offline)")
        .filter(
          (item) =>
            item.level_name !== "Sample Category/Sample Course - Stage 99/Sample Level(offline)"
        )
        .filter((item) => item.level_name !== "Computing (Blocks - Python)/Stage 2/Level 6(online)")
        .filter((item) => item.level_name !== "Computational Thinking/Stage 1/Level 3(offline)")
        ?.filter((item) => !item.level_name.includes("CUSTOM"))
        .filter((item) => item.level_name !== "GCSE CS/GCSE/Programming Project(online)")
        ?.sort((a, b) => a.level_name.localeCompare(b.level_name))
        .reduce((acc, item) => {
          if (!acc[item.level_name.replace("(online)", "").split("/")[0]]) {
            acc[item.level_name.replace("(online)", "").split("/")[0]] = [];
          }
          acc[item.level_name.replace("(online)", "").split("/")[0]].push(item);
          return acc;
        }, {});
      // console.log("tempLevel :>> ", tempLevel);
      tempLevelName(lessonPlanData.data.standard_lesson_plans);
      setLevels(tempLevel);
    }
  }, [lessonPlanData]);

  React.useEffect(() => {
    setLevelNameCurriculum(
      levelNames
        .filter((val, id, array) => array.indexOf(val) === id)
        .filter((val) => !val.includes("Sample"))
        .filter((val) => !val.includes("Raspberry Pi"))
        .filter((val) => !val.includes("CUSTOM"))
        .map((item) => {
          // console.log("item", item.replace("(online)", ""));
          return item.replace("(online)", "");
        })
        .sort((a, b) => a !== null && b !== null && a.localeCompare(b))
        .map((item) => {
          // console.log("item", item.replace("(online)", ""));
          return item.split("/")[0];
        })
        .filter((val, id, array) => {
          return array.indexOf(val) == id;
        })
    );
  }, [levelNames]);
  // console.log("curriculumSubjects :>> ", curriculumSubjects);
  // console.log("levelNamesFilter :>> ", levelNamesFilter);
  // console.log("levelNameCurriculum :>> ", levelNameCurriculum);
  // console.log("levels :>> ", levels);
  // console.log("editedClassLevel :>> ", editedClassLevel);
  // console.log("curriculumValue :>> ", curriculumValue);
  // =============================================

  // Filter out items
  const availableLevel =
    availableLevelsStatus == "success"
      ? availableLevels.data
          .filter((item) => item.full_name !== "Raspberry Pi/Basics/Level 1(online)")
          .filter(
            (item) =>
              item.full_name !== "Sample Category/Sample Course - Stage 99/Sample Level(offline)"
          )
          .filter(
            (item) => item.full_name !== "Sample Category/Sample Course/Sample Level(offline)"
          )
          .filter(
            (item) => item.full_name !== "Computing (Blocks - Python)/Stage 2/Level 6(online)"
          )
          .filter((item) => item.full_name !== "GCSE CS/GCSE/Programming Project(online)")
          .filter((item) => item.full_name !== "Computational Thinking/Stage 1/Level 1(offline)")
          .filter((item) => item.full_name !== "Computational Thinking/Stage 1/Level 2(offline)")
          .filter((item) => item.full_name !== "Computational Thinking/Stage 1/Level 3(offline)")
      : [];

  // Handle close
  const handleClose = () => {
    close();
  };

  // if (isLoadingX) return <CircularLoadingScreen />

  if (isError || isErrorLevel) return <ErrorScreen />;

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="lg"
      >
        <div
          className={classes.dialogHeader}
          style={{ padding: "20px 20px 10px", background: "white" }}
        >
          <Typography
            variant="h5"
            component="h5"
            className={classes.dialogTitle}
            style={{
              fontFamily: "Montserrat,sans-serif",
              fontStyle: "normal",
              fontWeight: 550,
              fontSize: 22,
              color: "#323F4B",
              marginLeft: 8,
            }}
          >
            {component.name}
          </Typography>
          {component.content !== "main" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "end",
              }}
            >
              <Button
                onClick={() => setComponent({ name: "Add Class", content: "main" })}
                style={{
                  color: "white",
                  background: "#1D6F98",
                  borderColor: "#1D6F98",
                  fontSize: 12,
                  fontFamily: "Montserrat,sans-serif",
                  fontWeight: 500,
                  fontStyle: "normal",
                  boxShadow: "none",
                  marginRight: 10,
                  margin: 5,
                }}
              >
                Save and Exit
              </Button>
              <Button
                onClick={() => setComponent({ name: "Add Class", content: "main" })}
                style={{
                  color: "#4FA9DC",
                  background: "white",
                  borderColor: "#4FA9DC",
                  fontSize: 12,
                  fontFamily: "Open Sans",
                  fontWeight: 500,
                  fontStyle: "normal",
                  boxShadow: "none",
                  margin: 5,
                }}
              >
                Cancel changes
              </Button>
            </Box>
          )}
        </div>
        <DialogContent>
          {component.content === "main" ? (
            <>
              {/* <AnimateHeight
                id="example-panel"
                duration={600}
                easing="ease"
                height={!closeSelection ? "auto" : 0} // see props documentation below
              > */}
              <Grid
                style={{
                  display: "flex",
                  padding: "15px 20px",
                  width: "100%",
                }}
                container
                spacing={2}
              >
                <Grid item xs={12} sm={6}>
                  <NameInput
                    editedClassName={editedClassName}
                    classes={classes}
                    customValidationName={customValidationName}
                    setEditedClassName={setEditedClassName}
                    setCustomValidationName={setCustomValidationName}
                    setNameText={setNameText}
                    nameText={nameText}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateInput
                    editedClassDate={editedClassDate}
                    setEditedClassDate={setEditedClassDate}
                    setCustomValidationDate={setCustomValidationDate}
                    classes={classes}
                    customValidationDate={customValidationDate}
                    dataMessage={dataMessage}
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: 0 }}>
                  <FrequencyInput
                    editedClassFrequency={editedClassFrequency}
                    classes={classes}
                    setEditedClassFrequency={setEditedClassFrequency}
                    setCustomValidationFrequency={setCustomValidationFrequency}
                    customValidationFrequency={customValidationFrequency}
                  />
                </Grid>
              </Grid>
              {/* </AnimateHeight> */}
              <Grid
                style={{
                  display: "flex",
                  padding: "15px 20px",
                  width: "100%",
                }}
                container
                spacing={2}
              >
                <Grid
                  item
                  // xs={12}
                  // sm={6}
                  style={{
                    width: "100%",
                    // background: "rgba(0,128,0,0.2)",
                  }}
                >
                  <CurriculumSelector
                    curriculumValue={curriculumValue}
                    setCurriculumValue={setCurriculumValue}
                    setCloseSelection={setCloseSelection}
                    setEditedClassLevel={setEditedClassLevel}
                    setSelectedCurriculum={setSelectedCurriculum}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AnimateHeight
                    id="example-panel"
                    duration={400}
                    easing="ease"
                    height={curriculumValue ? "auto" : 0} // see props documentation below
                  >
                    {/* <LevelInput
                    editedClassLevel={editedClassLevel}
                    availableLevel={
                      lessonPlanData &&
                      (curriculumValue === "EAI Lesson Plan"
                        ? lessonPlanData?.data.standard_lesson_plans
                            // ?.filter((item) => !item.level_name.includes("(offline)"))
                            // ?.filter((item) => !item.level_name.includes("CUSTOM"))
                            ?.sort((a, b) =>
                              a.level_name.localeCompare(b.level_name)
                            )
                        : lessonPlanData?.custom_lesson_plans
                            // ?.filter((item) => !item.level_name.includes("(offline)"))
                            // ?.filter((item) => !item.level_name.includes("CUSTOM"))
                            ?.sort((a, b) =>
                              a.level_name.localeCompare(b.level_name)
                            ))
                    }
                    classes={classes}
                    customValidationSelect={customValidationSelect}
                    setEditedClassLevel={setEditedClassLevel}
                    setCustomValidationSelect={setCustomValidationSelect}
                  /> */}
                    <NewLevelInput
                      curriculumValue={curriculumValue}
                      setCurriculumValue={setCurriculumValue}
                      editedClassLevel={editedClassLevel}
                      availableLevel={
                        lessonPlanData &&
                        (curriculumValue === "EAI Lesson Plan"
                          ? lessonPlanData?.data.standard_lesson_plans?.sort((a, b) =>
                              a.level_name.localeCompare(b.level_name)
                            )
                          : lessonPlanData?.data.custom_lesson_plans.sort((a, b) =>
                              a.name.localeCompare(b.name)
                            ))
                      }
                      levelNamesFilter={levelNamesFilter}
                      classes={classes}
                      customValidationSelect={customValidationSelect}
                      setEditedClassLevel={setEditedClassLevel}
                      setCustomValidationSelect={setCustomValidationSelect}
                      closeSelection={closeSelection}
                      setCloseSelection={setCloseSelection}
                      selectedCurriculum={selectedCurriculum}
                      setSelectedCurriculum={setSelectedCurriculum}
                      levels={levels}
                      setLevels={setLevels}
                      levelNames={levelNames}
                      setLevelNames={setLevelNames}
                      levelNameCurriculum={levelNameCurriculum}
                      setLevelNameCurriculum={setLevelNameCurriculum}
                    />
                  </AnimateHeight>
                </Grid>
                {/* <AnimateHeight
                  id="example-panel"
                  duration={600}
                  easing="ease"
                  height={!closeSelection ? "auto" : 0} // see props documentation below
                > */}
                <Grid
                  style={{
                    display: "flex",
                    padding: "0",
                    width: "100%",
                    margin: 0,
                  }}
                  container
                  // spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setComponent({
                          name: "Manage Teachers",
                          content: "manageTeachers",
                        });
                        setCloseSelection(false);
                      }}
                      fullWidth
                      style={{
                        color: "#094B72",
                        border: "1px solid #9AA5B1",
                        boxShadow: "none",
                        fontSize: "18px",
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                        fontStyle: "normal",
                        marginRight: 20,
                      }}
                    >
                      <PersonOutlineOutlinedIcon style={{ marginRight: 10 }} /> Manage teachers (
                      {right?.length})
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      onClick={() => {
                        setComponent({
                          name: "Manage Students",
                          content: "manageStudents",
                        });
                        setCloseSelection(false);
                      }}
                      fullWidth
                      style={{
                        color: "#094B72",
                        border: "1px solid #9AA5B1",
                        boxShadow: "none",
                        fontSize: "18px",
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                        fontStyle: "normal",
                      }}
                    >
                      <SchoolOutlinedIcon style={{ marginRight: 10 }} /> Manage students (
                      {rightStudents?.length})
                    </Button>
                  </Grid>
                </Grid>
                {/* </AnimateHeight> */}
              </Grid>
            </>
          ) : component.content === "manageTeachers" ? (
            <TransferList
              checked={checked}
              right={right}
              left={left}
              handleCheckedRight={handleCheckedRight}
              handleCheckedLeft={handleCheckedLeft}
              buttonErrorClasses={buttonErrorClasses}
              handleToggle={handleToggle}
              leftChecked={leftChecked}
              rightChecked={rightChecked}
              numberOfChecked={numberOfChecked}
              handleToggleAllCheck={handleToggleAllCheck}
              handleToggleAllUncheck={handleToggleAllUncheck}
              titleAll={"All Teachers"}
              titleSeleceted={"Selected Teachers"}
              bodyText="No teachers added"
            />
          ) : (
            <TransferList
              checked={checkedStudents}
              right={rightStudents}
              left={leftStudents}
              handleCheckedRight={handleCheckedRightStudents}
              handleCheckedLeft={handleCheckedLeftStudents}
              buttonErrorClasses={buttonErrorClasses}
              handleToggle={handleToggleStudents}
              leftChecked={leftCheckedStudents}
              rightChecked={rightCheckedStudents}
              numberOfChecked={numberOfCheckedStudents}
              handleToggleAllCheck={handleToggleAllCheckStudents}
              handleToggleAllUncheck={handleToggleAllUncheckStudents}
              titleAll={"All Students"}
              titleSeleceted={"Selected Students"}
              bodyText="No students added"
            />
          )}
        </DialogContent>
        {component.content === "main" && (
          <DialogActions style={{ justifyContent: "start", padding: "10px 35px 40px" }}>
            <Button
              onClick={() => {
                handleClose();
                clearAllInputFields();
              }}
              color="secondary"
              variant="contained"
              style={{
                color: "#4FA9DC",
                background: "white",
                borderColor: "#4FA9DC",
                fontSize: 18,
                fontFamily: "Open Sans",
                fontWeight: 500,
                fontStyle: "normal",
                boxShadow: "none",
                margin: 5,
                marginLeft: 8,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => (
                setOpenSnackbar(true),
                mutate({
                  name: editedClassName,
                  startdate: moment(editedClassDate).format("YYYY-MM-DD"),
                  level: editedClassLevel,
                  frequency: editedClassFrequency,
                  has_assigned_exercise: false,
                  current_lesson: editedClassLesson,
                  ongoing: true,
                  online: true,
                  paused: false,
                  school: tenantId,
                  students: rightStudents.map((item) => item.id),
                  teachers: right.map((item) => item.id),
                }),
                handleClose()
              )}
              disabled={!isValid}
              color="primary"
              variant="contained"
              style={{
                background: isValid ? "#1D6F98" : "#a3a3a3",
                borderColor: isValid ? "#1D6F98" : "#a3a3a3",
                color: "white",
                fontSize: 18,
                fontFamily: "Open Sans",
                fontWeight: 500,
                fontStyle: "normal",
                boxShadow: "none",
                margin: 5,
              }}
            >
              Save and exit
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}
