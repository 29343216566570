import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  // IconButton,
  makeStyles,
  Slide,
  Typography,
} from "@material-ui/core";
import componentStyles from "assets/theme/components/dialog.js";
// import { Clear } from "@material-ui/icons";
import EAILogoAct from "../../assets/img/EducationaiLogos/Group 1536.svg";
import EAILogoCur from "../../assets/img/EducationaiLogos/Group 1534.svg";
const useStyles = makeStyles(componentStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ConfirmModal = ({ open, setOpen, action, actionDescription }) => {
  const classes = useStyles();
  const [isActivities, setIsActivities] = React.useState(
    window.location.href.toLowerCase().includes("activities")
  );
  function handleConfirm() {
    handleClose();
    action();
  }

  function handleClose() {
    setOpen(false);
  }
  return (
    <div
      style={
        {
          // position: "relative",
          // padding: "5px",
          zIndex: 99999
        }
      }
    >
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <img
          src={isActivities ? EAILogoAct : EAILogoCur}
          alt=""
          srcset=""
          style={{
            position: "absolute",
            width: "70px",
            opacity: "0.8",
            right: "18px",
            top: "16px",
            // transform: "translate(-50%,50%)",
          }}
        />
        <div className={classes.dialogHeader}>
          <Typography
            variant="h5"
            component="h5"
            style={{
              fontSize: "1.125rem",
              fontWeight: "500",
              padding: "4px 4px",
            }}
          >
            Are you sure? This action cannot be undone.
          </Typography>
          {/* <IconButton onClick={handleClose}>
            <Clear />
          </IconButton> */}
        </div>
        <DialogContent>
          <h4
            style={{
              fontSize: "1.125rem",
              fontWeight: "500",
              backgroundColor: "#fff",
            }}
          >
            By clicking confirm, you will{" "}
            <span style={{ fontWeight: "600" }}>{actionDescription}</span>.
          </h4>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={
              isActivities
                ? { color: "#6358A5", border: "2px solid #6358A5" }
                : { color: "#06659D", border: "2px solid #06659D" }
            }
          // variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            style={
              isActivities
                ? { backgroundColor: "#6358A5", border: "2px solid #6358A5" }
                : { backgroundColor: "#06659D", border: "2px solid #06659D" }
            }
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmModal;
