import React from "react";
// @material-ui/core components

import { useQuery } from "react-query";
import { CurriculumSection } from "./Components/CurriculumSection";
import CurriculumCard from "components/Cards/eai/CurriculumCard";
import { LoadingScreen } from "./Components/LoadingScreen";
import { ErrorScreen } from "./Components/ErrorScreen";
import { CurriculumPage } from "./CurriculumPage";
import useCurriculumApi from "api/curriculum";
import usePersistStore from "../../../zustandPersist.js";

function SubjectsPage() {
  const { fetchSubjects } = useCurriculumApi();
  const { permission } = usePersistStore();

  const { isLoading, isError, data, error } = useQuery(
    "subjects",
    fetchSubjects,
    {
      refetchOnWindowFocus: false,
    }
  );
  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <ErrorScreen error={error} />;
  }

  const sections = [];
  for (const subject of data.data) {
    const cards = [];
    for (const category of subject.categories) {
      if (true) {
        cards.push(
          <CurriculumCard
            key={category?.id}
            title={category.name}
            subtitle={subject.name}
            image={category.image}
            link={`/curriculum/categories/${category?.id}/courses/`}
            blur="0"
          />
        );
      } else {
        cards.push(
          <CurriculumCard
            key={category?.id}
            title={category.name}
            subtitle={subject.name}
            image={category.image}
            // link={`/curriculum/categories/${category.id}/courses/`}
            blur="blur(5px)"
          />
        );
      }
    }
    sections.push(
      <CurriculumSection key={subject?.id} name={subject.name} cards={cards} />
    );
  }
  return <CurriculumPage name="Subjects" sections={sections} />;
}

export default SubjectsPage;
