import React, { useEffect, useState } from "react";
import useCurriculumApi from "api/curriculum";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import uuid from "uuid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LinearProgressPlayer from "./LinearProgressPlayer";
// import Confetti from "react-confetti";
import useStore from "../../../zustand";
import ActivityPlayerComponent from "./ActivityPlayerComponent";
import ActivityPlayerHeader from "./ActivityPlayerHeader";
// import Lottie from "react-lottie-player";
import { useHistory } from "react-router-dom";

// import Lottie from "lottie-react";
const ActivityPlayerPage = () => {
  const [sessionId, setSessionId] = useState("");
  const { exerciseId, lessonId } = useParams();
  const { selectedClassId, userId, tenantId } = useStore((state) => state);
  const [quesNum, setQuesNum] = useState(0);
  const [containerH5P, setContainerH5P] = useState("");
  const [numberOfQuestions, setNumberOfQuestions] = useState(null);
  const [numberOfQuestionsArray, setNumberOfQuestionsArray] = useState([]);
  const [selectQuestionToReview, setSelectQuestionToReview] = useState(1);
  const [isReviewState, setIsReviewState] = useState(false);
  const { fetchH5PQuestionDetails, fetchLessonIdForExercise } = useCurriculumApi();
  const [isEndOfQuestions, setIsEndOfQuestions] = useState(false);
  const [checkIfCompleteQuestion, setCheckIfFirstQuestion] = useState(true);
  const [hasStudentFinished, setHasStudentFinished] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setSessionId(uuid());
  }, []);

  const {
    status: statusDetails,
    data: exerciseData,
    isLoading: loadingDetails,
  } = useQuery(
    ["questionDetailsInfo", exerciseId, userId],
    () => fetchH5PQuestionDetails(exerciseId, userId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    status,
    data: lessonIdExercise,
    isLoading,
  } = useQuery(
    ["lessonIdInfoToFetchQuestionSer", exerciseId, userId, selectedClassId],
    () => fetchLessonIdForExercise(exerciseId, userId, selectedClassId),
    {
      // enabled: exerciseId.length > 0,
      refetchOnWindowFocus: true,
    }
  );
  console.log("data  LessonId:>> ", lessonIdExercise && lessonIdExercise.data);
  // console.log("numberOfQuestions :>> ", numberOfQuestions);

  useEffect(() => {
    if (exerciseData) {
    }
  }, [exerciseData]);
  // console.log("isEndOfQuestions :>> ", isEndOfQuestions);

  if (sessionId === "") {
    return null;
  } else {
    return (
      <>
        {/* {quesNum !== 0 &&
          isEndOfQuestions &&
          numberOfQuestions !== null &&
          quesNum === numberOfQuestions + 1 && <Confetti />} */}
        <div>
          <div
            style={{
              // background: "white",
              padding: "40px",
              maxWidth: "1280px",
              width: "95%",
              margin: "auto ",
              transition: "all 0.5s ease-in-out",
            }}
          >
            <button
              style={{
                padding: "8px 16px",
                // color: "white",
                // background: "rgba(99, 88, 165,0.8)",
                background: "rgba(99, 88, 165,0)",
                opacity: "1",
                cursor: "pointer",
                border: "none",
                // width: "120px",
                borderRadius: "100vw",
                borderTopRightRadius: "0",
                borderBottomRightRadius: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "600",
                // boxShadow:
                //   " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                margin: " 0 0 10px  0",
              }}
              onClick={() => history.goBack()}
            >
              <ArrowBackIcon />
              <h3 style={{ margin: "0" }}> Exit Activity Player</h3>
            </button>

            {exerciseData && (
              <ActivityPlayerHeader
                exerciseData={exerciseData}
                lessonId={lessonIdExercise ? lessonIdExercise.data.lesson_id : null}
              />
            )}

            <div
              className=""
              style={{
                padding: "20px",
                borderRadius: "20px",
                background: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              }}
            >
              {false && (
                <div style={{ margin: "11px auto 30px", width: "90%" }}>
                  {/* quesNum === numberOfQuestions + 1 &&
          isEndOfQuestions */}
                  ( // numberOfQuestions !== null &&
                  <LinearProgressPlayer
                    quesNum={quesNum}
                    setQuesNum={setQuesNum}
                    isReviewState={isReviewState}
                    setIsReviewState={setIsReviewState}
                    selectQuestionToReview={selectQuestionToReview}
                    setSelectQuestionToReview={setSelectQuestionToReview}
                    isEndOfQuestions={isEndOfQuestions}
                    setIsEndOfQuestions={setIsEndOfQuestions}
                    setNumberOfQuestions={setNumberOfQuestions}
                    numberOfQuestions={numberOfQuestions ? numberOfQuestions : null}
                  />
                  )
                </div>
              )}
              <ActivityPlayerComponent
                quesNum={quesNum}
                setQuesNum={setQuesNum}
                sessionId={sessionId}
                numberOfQuestions={numberOfQuestions}
                numberOfQuestionsArray={numberOfQuestionsArray}
                containerH5P={containerH5P}
                loadingDetails={loadingDetails}
                setContainerH5P={setContainerH5P}
                isReviewState={isReviewState}
                setIsReviewState={setIsReviewState}
                selectQuestionToReview={selectQuestionToReview}
                setSelectQuestionToReview={setSelectQuestionToReview}
                isEndOfQuestions={isEndOfQuestions}
                setIsEndOfQuestions={setIsEndOfQuestions}
                setNumberOfQuestions={setNumberOfQuestions}
                checkIfCompleteQuestion={checkIfCompleteQuestion}
                setCheckIfFirstQuestion={setCheckIfFirstQuestion}
                hasStudentFinished={hasStudentFinished}
                setHasStudentFinished={setHasStudentFinished}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default ActivityPlayerPage;
