import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons components

import componentStyles from "assets/theme/views/admin/dashboard.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { LoadingScreen } from "./Components/LoadingScreen";
import { ErrorScreen } from "./Components/ErrorScreen";
// import Iframe from "react-iframe";
import { Box, Container } from "@material-ui/core";
// import SimpleHeader from "components/Headers/SimpleHeader";
import Iframe from "react-iframe";
import useCurriculumApi from "api/curriculum";

const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

function ViewResourcePage() {
  const classes = { ...useStyles(), ...useStylesCardDeck() };
  const params = useParams();
  const { fetchResource } = useCurriculumApi();

  const { isLoading, isError, data, error } = useQuery(
    ["viewResource", params.resourceId],
    () => fetchResource(params.resourceId),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <ErrorScreen error={error} />;
  }

  document.cookie = `AuthToken=${data.data.jwt};max-age=604800;domain=educationai.co.uk;path=/`;
  document.cookie = `sas="${data.data.sas}";max-age=604800;domain=educationai.co.uk;path=/`;

  return (
    <>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="0rem"
        padding="20px"
        height="80vh"
        classes={{ root: classes.containerRoot }}
      >
        <Iframe
          url={data.data.url}
          width="100%"
          height="100%"
          allow="fullscreen"
        />
      </Container>
    </>
  );
}

export default ViewResourcePage;
