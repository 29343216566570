import React from "react"
import List from '@mui/material/List';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { Box, Button } from "@material-ui/core";


const CustomList = (props) => {

    //Search 
    const [searchWord, setSearchWord] = React.useState('')

    const filterList = (item, array) => {
        return searchWord.length > 0 ? item.full_name && item.full_name.toLowerCase().indexOf(searchWord.toLowerCase()) > -1 : array
    }
    return (
        <Card style={{ boxShadow: 'none', padding: '0px 20px' }}>
            <Box
                sx={{
                    borderBottom: 'none', fontFamily: 'Open Sans',
                    fontStyle: 'normal', color: '#7B8794',
                    fontWeight: 600, fontSize: 16, padding: 3
                }}>
                {props.title}
            </Box>
            <div style={{ margin: '10px 0px', width: '100%' }}>
                <OutlinedInput
                    placeholder="Search by name or group"
                    fullWidth
                    value={searchWord}
                    onChange={(e) => setSearchWord(e.target.value)}
                    style={{ border: '1px solid #CBD2D9', color: 'grey' }}
                />
            </div>
            <Box
                display="flex"
                alignItems="flex-start"
                style={{
                    marginTop: 5, marginBottom: 15, paddingBottom: 15, borderBottom: '1px solid #E4E7EB'
                }}
            >
                <Button
                    style={{
                        color: "#06659D",
                        // opacity: props.checked.length === props.data.length && 0.35,
                        background: "white",
                        borderColor: "#06659D",
                        fontSize: 12,
                        fontFamily: 'Open Sans',
                        fontWeight: 600,
                        fontStyle: 'normal',
                        boxShadow: 'none',
                        padding: '5px 10px',
                        display: 'flex',

                    }}
                    onClick={props.handleToggleAllCheck(props.data)}
                    checked={
                        props.numberOfChecked(props.data) === props.data.length && props.data.length !== 0
                    }
                    indeterminate={
                        props.numberOfChecked(props.data) !== props.data.length &&
                        props.numberOfChecked(props.data) !== 0
                    }
                    disabled={props.data.length === 0}
                    inputProps={{
                        'aria-label': 'all items selected',
                    }}
                >
                    Select all
                </Button>
                <Button
                    style={{
                        color: "#06659D",
                        // opacity: props.checked.length !== props.data.length && 0.35,
                        fontSize: 12,
                        fontFamily: 'Open Sans',
                        fontWeight: 600,
                        fontStyle: 'normal',
                        boxShadow: 'none',
                        padding: '5px 10px',
                        display: 'flex',
                        marginLeft: 5

                    }}
                    onClick={props.handleToggleAllUncheck(props.data)}
                    checked={
                        props.numberOfChecked(props.data) === props.data.length && props.data.length !== 0
                    }
                    indeterminate={
                        props.numberOfChecked(props.data) !== props.data.length &&
                        props.numberOfChecked(props.data) !== 0
                    }
                    disabled={
                        props.data.length === 0 ||
                        props.numberOfChecked(props.data) === 0 ||
                        props.numberOfChecked(props.data) !== props.data.length
                    }
                    inputProps={{
                        'aria-label': 'all items selected',
                    }}
                >
                    Unselect all
                </Button>
            </Box>

            {props.data.length > 0 ? (
                <>
                    <CardHeader
                        sx={{ px: 2, py: 1, background: '#F8F8FF' }}
                        avatar={<></>}
                        title={props.title}
                        subheader={`${props.numberOfChecked(props.data)}/${props.data.length} selected`}
                    />
                    <Divider />
                    <List
                        sx={{
                            height: 380,
                            bgcolor: 'background.paper',
                            overflow: 'auto',
                        }}
                        fullWidth
                        dense
                        component="div"
                        role="list"
                    >
                        {props.data
                            .sort(
                                (a, b) =>
                                    a.full_name !== null &&
                                    b.full_name !== null &&
                                    a.full_name.localeCompare(
                                        b.full_name
                                    )
                            )
                            .filter((item, key, array) => filterList(item, array))
                            .map((value) => {
                                const labelId = `transfer-list-all-item-${value}-label`;
                                return (
                                    <ListItem
                                        key={value}
                                        role="listitem"
                                        button
                                        onClick={props.handleToggle(value)}
                                        style={{ borderBottom: '1px solid #E4E7EB', padding: '4px 0px' }}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={props.checked.indexOf(value) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                style={{ color: props.checked.indexOf(value) !== -1 ? '#1D6F98' : '#CBD2D9', borderRadius: 4 }}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            id={labelId}
                                            primary={`${value.full_name}`}
                                        />
                                    </ListItem>
                                );
                            })}
                        <ListItem />
                    </List>
                </>)
                : (
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent='center'
                        flexDirection='column'
                        style={{ background: '#F8F8FF', height: 438 }}
                    >
                        <BookmarkBorderIcon fontSize="large" style={{ color: '#7B8794', fontWeight: 200 }} />
                        <p style={{
                            fontFamily: 'Open Sans', fontSize: '16px', color: '#7B8794'
                        }}>
                            {props.bodyText}
                        </p>
                    </Box>
                )}

        </Card>
    )
}

export default CustomList