import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import componentStylesError from "assets/theme/components/snackbar.js";
import componentStyles from "assets/theme/views/admin/alternative-dashboard.js";

const useStylesError = makeStyles(componentStylesError);
const useStyles = makeStyles(componentStyles);

const GeneralSnackbar = ({ isSuccess, isLoading, isError, Snackbar, openSnackbar, setOpenSnackbar, SnackbarContent, message }) => {
    const classes = { ...useStyles(), ...useStylesError() };

    // Snackbar disappear   
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setOpenSnackbar(false)
        }, 3000)

        return () => { clearTimeout(timeout) }
    }, [openSnackbar])


    const errorSnackbarRootClasses = { root: classes.errorSnackbar }
    const successSnackbarRootClasses = { root: classes.successSnackbar }
    return (
        <div>
            {isError ? (
                <Snackbar
                    open={openSnackbar}
                >
                    <SnackbarContent
                        style={{ position: 'fixed', top: 100, left: "calc(50vw - 200px)", background: '#f75676', borderColor: '#f75676' }}
                        elevation={0}
                        classes={errorSnackbarRootClasses}
                        message={
                            <>
                                <strong style={{ marginRight: 5 }}>Error! </strong>Something went wrong.
                            </>
                        }
                    />
                </Snackbar>
            ) : null}

            {isSuccess ?
                <Snackbar
                    open={openSnackbar}
                >
                    <SnackbarContent
                        style={{ position: 'fixed', top: 100, left: "calc(50vw - 200px)", background: '#4fd69c', borderColor: '#4fd69c' }}
                        elevation={0}
                        classes={successSnackbarRootClasses}
                        message={
                            <>
                                <strong style={{ marginRight: 5 }}>Success! </strong> {message}
                            </>
                        }
                    />
                </Snackbar>
                : null}
        </div>)
}

export default GeneralSnackbar