import React, { useState } from "react";
// import ItemListComponent from "../DataSiteHomePage/ItemListComponent";
// import ListItemHeaderComponent from "../DataSiteHomePage/ListItemHeaderComponent";
import AnimateHeight from "react-animate-height";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ItemListComponentLink from "./ItemListComponentLink";
const StudentsInAClass = ({
  students,
  setExpandMembers,
  expandMembers,
  height,
  setHeight,
}) => {
  const [searchWord, setSearchWord] = useState("");
  // console.log("students=;=;=;=;=", students && students);
  const filterList = (item, array) => {
    return students.students.length > 0
      ? item.student.full_name &&
          item.student.full_name
            .toLowerCase()
            .indexOf(searchWord.toLowerCase()) > -1
      : array;
  };
  if (students) {
    return (
      <div>
        <div
          style={{
            boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
            borderRadius: "12px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              background: "#05649D",
              color: "white",
              overflow: "hidden",
              padding: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0 12px 0 12px",
              }}
            >
              <h5
                style={{
                  color: "white",
                  fontSize: "14px",
                  margin: "0 0 0 10px",
                }}
              >
                {students.students.length} Students
              </h5>
              <div
                style={{
                  display: "flex",
                  // width: "100%",
                  // justifyContent: "space-between",
                  // alignItems: "center",
                }}
              >
                {expandMembers && (
                  <input
                    style={{
                      border: "1px solid #CBD2D9",
                      // height: "34px",
                      fontSize: "14px",
                      margin: "0px",
                      padding: "4px 9px",
                      borderRadius: "8px",
                      // minWidth: "180px",
                      // marginTop: "10px",
                      // marginBottom: "10px",
                    }}
                    placeholder="Search name..."
                    value={searchWord}
                    onChange={(e) => setSearchWord(e.target.value)}
                  ></input>
                )}
                <div
                  onClick={() => (
                    setExpandMembers((prev) => !prev),
                    setHeight(height === 0 ? "auto" : 0)
                  )}
                  style={{
                    background: "#DFEDFB",
                    color: "white",
                    width: "26px",
                    height: "26px",
                    marginLeft: "10px",
                    borderRadius: "60px",
                    //   marginRight: "2px",
                    //   marginBottom: "1px",
                    cursor: "pointer",
                  }}
                >
                  {expandMembers === false ? (
                    <KeyboardArrowDownIcon
                      sx={{ fontSize: 26, color: "#07639C" }}
                    />
                  ) : (
                    <KeyboardArrowUpIcon
                      sx={{ fontSize: 26, color: "#07639C" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <AnimateHeight
            id="example-panel"
            duration={600}
            easing="ease"
            height={height} // see props documentation below
          >
            <div
              style={{
                // height: expandMembers ? "380px" : "0",
                overflow: "auto",
                maxHeight: "390px",
              }}
            >
              {students.students
                .sort(
                  (a, b) =>
                    a.student.full_name !== null &&
                    b.student.full_name !== null &&
                    a.student.full_name.localeCompare(b.student.full_name)
                )
                .filter((item, key, array) => filterList(item, array))
                .map((student) => (
                  <ItemListComponentLink
                    key={student.id}
                    data={student?.student}
                    score={student?.score}
                    addLink={true}
                  />
                ))}
            </div>
          </AnimateHeight>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div
          style={{
            boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
            borderRadius: "12px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              background: "#05649D",
              color: "white",
              overflow: "hidden",
              padding: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0 12px 0 12px",
              }}
            >
              <h5
                style={{
                  color: "white",
                  fontSize: "14px",
                  margin: "0 0 0 10px",
                }}
              >
                Students
              </h5>
              <div
                onClick={() => (
                  setExpandMembers, setHeight(height === 0 ? "auto" : 0)
                )}
                style={{
                  // background: "#DFEDFB",
                  // color: "white",
                  // width: "30px",
                  // height: "30px",
                  // marginLeft: "10px",
                  // borderRadius: "60px",
                  //   marginRight: "2px",
                  //   marginBottom: "1px",
                  // cursor: "pointer",
                  // background: "#DFEDFB",
                  // color: "white",
                  width: "26px",
                  height: "26px",
                  marginLeft: "10px",
                  borderRadius: "60px",
                  //   marginRight: "2px",
                  //   marginBottom: "1px",
                  cursor: "pointer",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default StudentsInAClass;
