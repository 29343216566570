import React, { useEffect, useState } from "react";
import TeachersInAClass from "./TeachersInAClass";
import StudentsInAClass from "./StudentsInAClass";
import { useQuery } from "react-query";
import useUserApi from "api/user";
import { useParams } from "react-router-dom";
import useDataApi from "api/data";
// import ClassControlPanel from "./ClassControlPanel";
// import ClassEditPanel from "./ClassEditPanel";
// import ClassControlPanel from "./ClassControlPanel";

const ClassMembers = ({ classData }) => {
  const [teachersInClass, setTeachersInClass] = useState([]);
  const [expandMembers, setExpandMembers] = useState(true);
  const [height, setHeight] = React.useState("auto");
  const { fetchTeachers, fetchStudents } = useUserApi();
  const { classId } = useParams();

  const { fetchClassStudents } = useDataApi();

  const {
    data: students,
    status: studentsStatus,
    isLoading: studnetsIsLoading,
    error,
  } = useQuery(
    ["class_students_members", classId],
    () => fetchClassStudents(classId),
    {
      refetchOnWindowFocus: false,
    }
  );
  if (students?.data) {
    // console.log("students ===========}}}>>", students?.data);
  }
  // console.log("studnetsIsLoading ===========}}}>>", studnetsIsLoading);
  if (error) {
    // console.log("error", error);
  }

  const {
    data: teachersData,
    status: teachersStatus,
    isLoading: teachersLoading,
  } = useQuery(["teachers_members"], () => fetchTeachers(), {
    refetchOnWindowFocus: false,
  });

  // Orginizing the list of teachers in class and out of class
  // if (teachersData && teachersStatus === "success") {
  const newTeacherList = () => {
    let inTeacher = teachersData.data.filter((item) =>
      classData?.teachers?.includes(item.id)
    );

    setTeachersInClass(inTeacher);
  };

  useEffect(() => {
    teachersStatus === "success" &&
      teachersData.data.length > 0 &&
      newTeacherList();
  }, [teachersStatus, classData]);

  return (
    <div
      style={{
        minHeight: "400px",
        margin: "20px auto 50px",
      }}
    >
      <div
        style={{
          margin: "20px auto ",
          // gap: "20px",
          // justifyContent: "space-between",
          padding: "20px 30px ",
          border: "0px solid rgb(31, 110, 163)",
          borderRadius: "8px",
          // display: "flex",
          // justifyContent: "space-between",
          boxShadow: "0 5px 15px hsla(0,0%, 0%, .2)",
          background: "rgb(255, 255, 255,0.5)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          // width: "90%",

          // gridTemplateColumns: "3fr 3fr 1fr",
        }}
      >
        {/* <ClassControlPanel /> */}
        <h3>Class Members:</h3>
        {/*  */}
        <div
          style={{
            display: "flex",
            // flexDirection: "column",
            width: "100%",
            gap: "20px",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "50%" }}>
            <TeachersInAClass
              teachers={teachersInClass}
              setExpandMembers={setExpandMembers}
              expandMembers={expandMembers}
              height={height}
              setHeight={setHeight}
            />
          </div>
          <div style={{ width: "50%" }}>
            <StudentsInAClass
              students={students?.data}
              setExpandMembers={setExpandMembers}
              expandMembers={expandMembers}
              height={height}
              setHeight={setHeight}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassMembers;
