import React from "react";

const CustomLessonItemEdit = ({
  lesson,
  setSelectedLessonId,
  selectedLessonId,
  setSelectedLessonName,
  index,
  setEditedClassLesson,
  setSelectedCurriculumLesson,
  editedClassLesson,
}) => {
  // console.log("lesson :>> ", lesson);
  // console.log("lesson :>> ", lesson);
  // console.log("lesson.id :>> ", lesson.id);
  return (
    <div>
      <div
        style={{
          display: "flex",
          border: "2px solid rgba(0,0,0,0.2)",
          boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
          borderRadius: "4px",
          padding: "2px 10px",
          cursor: "pointer",
          background: lesson.id == editedClassLesson ? "rgb(6, 101, 157)" : "",
          color: lesson.id == editedClassLesson ? "white" : "",
        }}
        onClick={() => {
          setSelectedLessonId(lesson.id);
          setSelectedLessonName(lesson.name);
          setEditedClassLesson(lesson.id);
          setSelectedCurriculumLesson(lesson.name);
          // console.log("=========================================");
          // console.log("=========================================");
          // console.log("lesson.id :>> ", lesson.id);
          // console.log("editedClassLesson :>> ", editedClassLesson);
          // console.log("=========================================");
          // console.log("=========================================");
        }}
      >
        <p
          style={{
            margin: "0",
            padding: "0",
            fontSize: "12px",
          }}
        >
          <strong>{index + 1}. </strong> {lesson.name}
        </p>
      </div>
    </div>
  );
};

export default CustomLessonItemEdit;
