import useCurriculumApi from "api/curriculum";
// import { CustomLevelCard } from "components/Cards/eai/CustomLevelCard";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import ConfirmModal from "components/Modals/ConfirmModal";
import GeneralSnackbar from "components/Snackbar/GeneralSnackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@mui/material/Snackbar";
import Image from "./Images/customised_lesson_2.png";
import { Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
// @material-ui/icons components
import Typography from "@mui/material/Typography";
import CurriculumWrapperCard from "components/Cards/eai/CurriculumWrapperCard";
import { useMutation } from "react-query";
import Grid from "@material-ui/core/Grid";
import CustomCurriculumCard from "./CustomCurriculumCard";
import { Skeleton } from "@mui/material";
import useBuilderApi from "api/builder";

const AddCurriculum = () => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmActionDescription, setConfirmActionDescription] =
    useState(null);

  const { fetchLevels } = useCurriculumApi();
  const { fetchMyLevels } = useBuilderApi();
  const { fetchLessonPlan } = useCurriculumApi();

  // const { isLoading, isError, data } = useQuery(
  //   "myLevels",
  //   () => fetchLevels(true),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );
  // const { data: testData } = useQuery("myBBBBLevels", () => fetchMyLevels(), {
  //   refetchOnWindowFocus: false,
  // });
  const {
    data: lessonPlanData,
    status: lessonPlanStatus,
    isError,
    isLoading,
  } = useQuery(["lessonPlanData"], () => fetchLessonPlan(), {
    refetchOnWindowFocus: false,
  });
  const queryClient = useQueryClient();
  const { deleteLevel, deleteCustomLessonPlanId } = useCurriculumApi();
  // console.log(
  //   "data && data.data==============/// =========>> ",
  //   data && data.data
  // );
  // console.log(
  //   "data && data.data==============/// =========>> ",
  //   data && data.data
  // );
  // console.log(
  //   "testData && testData.data==============/// =========>> ",
  //   testData && testData.data
  // );
  // console.log(
  //   "testData && testData.data==============/// =========>> ",
  //   testData && testData.data
  // );
  const {
    isLoading: isLoadingDelete,
    isSuccess: isSuccessD,
    isError: isErrorDelete,
    mutate,
    error: errorDelete,
  } = useMutation("delete", (id) => deleteCustomLessonPlanId(id));

  function clickDelete(levelId, levelName) {
    setConfirmAction(
      () =>
        function () {
          mutate(levelId);
          setOpenSnackbar(true);
        }
    );
    setConfirmActionDescription(`remove ${levelName} from your custom levels`);
    setConfirmOpen(true);
  }

  React.useEffect(() => {
    if (isSuccessD) {
      queryClient.invalidateQueries("lessonPlanData");
    }
  }, [isSuccessD]);

  // Snackbar disappear
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenSnackbar(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [openSnackbar]);

  // if (isLoading) { return null }
  if (isError) {
    return null;
  }

  return (
    <Grid
      spacing={2}
      container
      style={{ padding: 20 }}
      className="curriculumBuilder"
    >
      <ConfirmModal
        action={confirmAction}
        actionDescription={confirmActionDescription}
        open={confirmOpen}
        setOpen={setConfirmOpen}
      />
      <GeneralSnackbar
        isLoading={isLoadingDelete}
        isSuccess={isSuccessD}
        isError={isErrorDelete}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message="The curriculum has been deleted."
      />
      <Grid item xs={12}>
        <CurriculumWrapperCard>
          <Box
            style={{
              gap: "20px",
              borderRadius: "8px",
              padding: "20px 20px",
              border: "0px solid  #05649D",
              boxShadow: "0 5px 15px hsla(0,0%, 0%, .2)",
              transition: "height 0.2s ease-out",
              background: "#f9fafe",
              width: "100%",
            }}
          >
            <div style={{ display: "grid", width: "100%" }}>
              <Grid
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  padding: 30,
                  justifyContent: "none",
                }}
              >
                {isLoading ? (
                  <>
                    {[1, 2, 3].map((item) => (
                      <Skeleton
                        count={6}
                        width={350}
                        height={100}
                        style={{
                          transform: "none",
                          marginRight: 20,
                          marginBottom: 20,
                          borderRadius: 10,
                        }}
                      />
                    ))}
                  </>
                ) : lessonPlanData?.data?.custom_lesson_plans?.length > 0 ? (
                  lessonPlanData?.data?.custom_lesson_plans?.map((level) => (
                    <CustomCurriculumCard
                      key={level?.id}
                      id={level?.id}
                      title={level?.name}
                      subtitle=""
                      image={Image}
                      onDelete={clickDelete}
                      link={`/curriculum/myLevels/${level?.id}/lessons`}
                    />
                  ))
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: 18,
                        fontWeight: 600,
                        fontFamily: "Open Sans",
                        color: "#CBD2D9",
                        maxWidth: 300,
                      }}
                      component="span"
                      variant="body2"
                    >
                      <ImportContactsIcon
                        style={{
                          width: "42px",
                          height: "33px",
                          marginRight: 5,
                        }}
                      />{" "}
                      No curriculum's made
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        alignItem: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Link to="/builder/build">
                        <Button
                          variant="contained"
                          style={{
                            background: "rgb(6, 101, 157)",
                            border: "none",
                          }}
                        >
                          + Add Lesson Plan
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                )}
              </Grid>
              {lessonPlanData?.data?.custom_lesson_plans?.length > 0 && (
                <Box
                  style={{
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                  }}
                >
                  <Link to="/builder/build">
                    <Button
                      variant="contained"
                      style={{ background: "rgb(6, 101, 157)", border: "none" }}
                    >
                      + Add Lesson Plan
                    </Button>
                  </Link>
                </Box>
              )}
            </div>
          </Box>
        </CurriculumWrapperCard>
      </Grid>
    </Grid>
  );
};

export default AddCurriculum;
