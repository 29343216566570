import create from "zustand";
import { persist } from "zustand/middleware";

const usePersistStore = create(
  persist(
    (set, get) => ({
      chosenLevel: 0,
      setChosenLevel: (level) => set({ chosenLevel: level }),
      xmlCodeSelected: {},
      setXmlCodeSelected: (code) => set({ xmlCodeSelected: code }),
      blocksTheme: {},
      setBlocksTheme: (theme) => set({ blocksTheme: theme }),
      gamesUrl: {},
      setGamesUrl: (code) => set({ gamesUrl: code }),
      permission: [],
      setPermission: (permission) => set({ permission: permission }),
    }),
    {
      name: "chosen-level", // unique name
      getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
    }
  )
);

// const usePersistStore = create(
//     persist(
//         (set, get) => ({
//             chosenLevel: 0,
//             setChosenLevel: (level) => set({ chosenLevel: level }),
//             permission: [],
//             setPermission: (permission) => set({ permission: permission }),
//         }),
//         {
//             name: 'chosen-level', // unique name
//             getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
//         }
//     )

// )

export default usePersistStore;
