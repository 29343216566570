import { Card } from "@material-ui/core";
import { ErrorOutlined } from "@mui/icons-material";

const { CircularProgress, Box } = require("@mui/material");

const GeneralDataCard = ({ status, children }) => {
  return (
    <Card>
      {status == "loading" ? (
        <Box sx={{ padding: "30px", display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>          <CircularProgress />
        </Box>
      ) : status == "error" ? (
        <Box sx={{ padding: "30px" }}>
          <ErrorOutlined />
        </Box>
      ) : (
        <>{children}</>
      )}
    </Card>
  );
};

export default GeneralDataCard;
