import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons components

import componentStyles from "assets/theme/views/admin/dashboard.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
import { Box, Container } from "@material-ui/core";
import { useQuery } from "react-query";
import useBuilderApi from "api/builder";
import { LevelsGrid } from "./components/LevelsGrid";

const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

function MyLevelsPage() {
  const classes = { ...useStyles(), ...useStylesCardDeck() };
  const { fetchMyLevels } = useBuilderApi();
  const { isLoading, isError, data, error } = useQuery(
    "builderMyLevels",
    fetchMyLevels
  );
  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }
  return (
    <>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-3rem"
        classes={{ root: classes.containerRoot }}
      >
        <LevelsGrid data={data.data} link="" />
      </Container>
    </>
  );
}

export default MyLevelsPage;
