import React from "react";
import BuilderLevelComponent from "./BuilderLevelComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const BuilderLevelCompSub = ({
  itemTitle,
  levelNameInput,
  levels,
  curriculumLevel,
  addLesson,
  deleteLesson,
  setSelectedLessonIdPlayer,
  selectedLessonIdPlayer,
  setShowFilter,
  setSelectedLesson,
  selectedLessons,
  searchWord,
  setLevelItemNamesFilter,
  levelItemNamesFilter,
  levelNamesFilter,
}) => {
  const [expandSubject, setExpandSubject] = React.useState(true);

  return (
    <div
      style={{
        display:
          levelNamesFilter.length === 0
            ? "block"
            : levelNamesFilter?.includes(itemTitle)
            ? "block"
            : "none",
        // marginBottom: "28px",
      }}
    >
      <h2
        style={{
          //   margin: "5px 8px 5px",
          fontSize: "18px",
          padding: "5px 10px 5px 0",
          borderRadius: "4px",
          cursor: "pointer",
        }}
        onClick={() => setExpandSubject((prev) => !prev)}
      >
        {itemTitle}
        {expandSubject ? (
          <ExpandMoreIcon
            style={{
              margin: "0",
              fontSize: "34px",
              cursor: "pointer",
              marginLeft: "10px",
              //   color: expandSubject ? "rgba(6, 101, 157,1)" : "rgba(6, 101, 157,1)",
            }}
          />
        ) : (
          <KeyboardArrowUpIcon
            style={{
              margin: "0",
              fontSize: "34px",
              marginLeft: "10px",
              cursor: "pointer",
              //   color: expandSubject ? "rgba(6, 101, 157,1)" : "rgba(6, 101, 157,1)",
            }}
          />
        )}
      </h2>
      {expandSubject && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "14px",
          }}
        >
          {levelNameInput
            .filter((item) => item.split("/")[2] !== "Level 6")
            ?.filter((itemN) =>
              levelNamesFilter.length === 0 ? true : levelNamesFilter.includes(itemN.split("/")[0])
            )
            ?.map((item) => {
              return itemTitle === item.split("/")[0] ? (
                <BuilderLevelComponent
                  curriculumLevel={item}
                  itemCurr={item.split("/")[0]}
                  itemStage={item.split("/")[1]}
                  itemLevel={item.split("/")[2]}
                  levels={levels}
                  searchWord={searchWord}
                  addLesson={addLesson}
                  deleteLesson={deleteLesson}
                  selectedLessons={selectedLessons}
                  setShowFilter={setShowFilter}
                  setSelectedLessonIdPlayer={setSelectedLessonIdPlayer}
                  selectedLessonIdPlayer={selectedLessonIdPlayer}
                  setLevelItemNamesFilter={setLevelItemNamesFilter}
                  levelItemNamesFilter={levelItemNamesFilter}
                  levelNamesFilter={levelNamesFilter}
                  setSelectedLesson={setSelectedLesson}
                />
              ) : null;
            })}
        </div>
      )}
    </div>
  );
};

export default BuilderLevelCompSub;
