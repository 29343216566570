import React from "react"; // { useEffect }
import { useLocation, Link } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
// import InputBase from "@material-ui/core/InputBase";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuBook from "@material-ui/icons/MenuBook";
// import BarChart from "@material-ui/icons/BarChart";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
// @material-ui/icons components
// import Clear from "@material-ui/icons/Clear";
// import SearchIcon from "@material-ui/icons/Search";
// import ZoomIn from "@material-ui/icons/ZoomIn";
import HomeIcon from "@mui/icons-material/Home";
// core components
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import componentStyles from "assets/theme/components/navbars/admin-navbar.js";
import useStore from "../../zustand";
import { Button, IconButton } from "@material-ui/core";
// import TutorialTour from "components/Tutorial/TutorialTour";
import { GridMenuIcon } from "@material-ui/data-grid";
// import AppsDropdown from "components/Dropdowns/AppsDropdown";
import usePersistStore from "../../zustandPersist.js";
// import useCurriculumApi from "api/curriculum";
import uuid from "uuid";
import { useParams } from "react-router-dom";
import useUserApi from "api/user";
import { useQuery } from "react-query";
const useStyles = makeStyles(componentStyles);

const drawerWidth = 0;

export default function AdminNavbar({
  openSidebarResponsive,
  backgroundColor,
  logo,
  buttons,
  dropdown,
  account,
}) {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const { permission } = usePersistStore();
  const params = useParams();
  // console.log("params :>> ", params);
  // console.log("params :>> ", params);
  // console.log("params :>> ", params);
  // console.log("params :>> ", params);
  const { fetchClass } = useUserApi();

  const [showSearch, setShowSearch] = React.useState(false);
  // const [searchInput, setSearchInput] = React.useState(searchWord);

  const setMobileOpen = useStore((state) => state.setMobileOpen);
  const searchWord = useStore((state) => state.searchWord);
  const setSearchWord = useStore((state) => state.setSearchWord);
  const { classIdNavbar } = useStore();
  const [newClassId, setNewClassId] = React.useState(null);
  // console.log("classIdNavbar :>> ", classIdNavbar);
  const {
    data: classData,
    status: classStatus,
    isLoading: classIsLoading,
  } = useQuery(["class", classIdNavbar], () => fetchClass(classIdNavbar), {
    refetchOnWindowFocus: false,
    enabled: classIdNavbar?.length > 0,
  });

  // console.log("classData && classData :>> ", classData && classData);

  // // User check
  // const { fetchPermission } = useCurriculumApi();
  // const [response, setResponse] = useState(null);
  // const userInfo = async () => {
  //   try {
  //     const resp = await fetchPermission();
  //     if (resp && resp.status === 200 && resp.data) {
  //       return setResponse(resp.data);
  //     }
  //   } catch (error) {
  //     console.error(error.response);
  //   }
  // };
  // useEffect(() => {
  //   userInfo();
  // }, []);
  // if (response === null) {
  //   return null;
  // }

  // Logo
  let logoObject =
    logo && logo.innerLink ? (
      <Link
        to={logo.innerLink}
        className={classes.logoLinkClasses}
        style={{ display: "flex", alignItems: "center" }}
      >
        <img
          alt={logo.imgAlt}
          className={classes.logoClasses}
          src={
            location.pathname.includes("/activities/studentPage") ||
            location.pathname.includes("/activities/ActivityPage")
              ? logo.imgSrc2
              : logo.imgSrc
          }
          style={{ width: 50, height: 25 }}
        />
        {permission && permission.type === "student" ? (
          <Typography
            variant="h1"
            style={{
              color: "#fff",
              fontSize: "18px",
              fontWeight: "500",
              padding: "0 0 0 0",
              margin: "0 0 0 5px",
              // marginLeft: "10px",
            }}
          >
            EducationAI
          </Typography>
        ) : (
          ""
        )}
      </Link>
    ) : logo && logo.outterLink ? (
      <a
        href={logo.outterLink}
        className={classes.logoLinkClasses}
        style={{ display: "flex", alignItems: "center" }}
      >
        <img
          alt={logo.imgAlt}
          className={classes.logoClasses}
          src={logo.imgSrc}
          style={{ width: 60, height: 40 }}
        />
        {permission && permission.type === "student" ? (
          <Typography
            variant="h1"
            style={{
              color: "#fff",
              fontSize: "18px",
              fontWeight: "500",
              padding: "0 0 0 0",
              margin: "0 0 0 5px",
              // marginLeft: "10px",
            }}
          >
            EducationAI
          </Typography>
        ) : (
          ""
        )}
      </a>
    ) : null;

  return (
    <>
      <Box
        sx={{
          width: location.pathname === "/builder/build" && {
            sm: `calc(100% - ${drawerWidth}px)`,
          },
          ml: location.pathname === "/builder/build" && {
            sm: `${drawerWidth}px`,
          },
        }}
      >
        <AppBar
          elevation={0}
          classes={{ root: classes.appBarRoot }}
          style={{
            position:
              location.pathname.includes("activities") || location.pathname.includes("builder")
                ? "fixed"
                : "relative",
            zIndex: 999,
            background:
              location.pathname.includes("/activities/studentPage") ||
              location.pathname.includes("/activities/ActivityPage")
                ? "rgba(255,255,255,0)"
                : location.pathname.includes("/activities")
                ? "#6358A5"
                : "#06659D",
            height: 65,
            // opacity: 0,
          }}
        >
          <Toolbar disableGutters>
            <Container
              style={{ background: backgroundColor }}
              maxWidth={false}
              component={Box}
              classes={{ root: classes.containerRoot }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                marginTop="1rem"
                marginBottom="1rem"
                style={{
                  padding:
                    location.pathname.includes("/activities/studentPage") ||
                    location.pathname.includes("/activities/ActivityPage")
                      ? "0px 40px 0  280px"
                      : "",
                }}
              >
                {location.pathname.includes("/activities/studentPage") ||
                location.pathname.includes("/activities/ActivityPage") ? null : (
                  <Box
                    sx={{ display: { xs: "none", sm: "flex" } }}
                    alignItems="center"
                    width="auto"
                    // marginRight="1rem"
                    // className={logo.className && logo.className}
                  >
                    {logoObject}
                  </Box>
                )}
                <Box
                  display="flex"
                  alignItems="center"
                  width="auto"
                  marginRight="2rem"
                  marginLeft="0"
                  className="second-element"
                >
                  {permission && permission.type === "student" ? "" : <MenuNavigator />}
                </Box>

                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginLeft="auto"
                  className={clsx(classes.marginLeftNone, {
                    [classes.displayNone]: showSearch,
                  })}
                >
                  {location.pathname.includes("activities") && (
                    <Hidden xlUp implementation="css">
                      <IconButton onClick={openSidebarResponsive}>
                        <Box
                          component={GridMenuIcon}
                          color={theme.palette.white.main}
                          width="1.5rem!important"
                          height="1.5rem!important"
                          onClick={setMobileOpen}
                        />
                      </IconButton>
                    </Hidden>
                  )}
                </Box>

                {location.pathname !== "/data/school/" &&
                  false &&
                  location.pathname !== "/data/school" &&
                  location.pathname !== "/data/students" &&
                  location.pathname !== "/data/teachers" && (
                    <Box
                      display="flex"
                      alignItems="center"
                      width="auto"
                      marginRight="1rem"
                      className="dropDown"
                    >
                      {dropdown ? dropdown.map((item) => item) : null}
                    </Box>
                  )}
                {location.pathname.includes("/activities/studentPage") ||
                location.pathname.includes("/activities/ActivityPage")
                  ? null
                  : location.pathname !== "/data/school/" &&
                    location.pathname !== "/data/school" &&
                    location.pathname !== "/data/students" &&
                    location.pathname !== "/data/teachers" && (
                      <Box
                        display="flex"
                        alignItems="center"
                        width="auto"
                        marginRight="1rem"
                        className="dropDown"
                      >
                        {dropdown ? dropdown.map((item) => item) : null}
                      </Box>
                    )}

                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className={clsx(classes.marginLeftAuto, {
                    [classes.displayNone]: showSearch,
                  })}
                  style={{
                    boxShadow: "0 1px 3px hsla(0, 0%, 0%, 0.2)",
                    border: "1px solid rgba(0,0,0,0.1)",
                    borderRadius: "8px",
                  }}
                >
                  <UserDropdown account={account} />
                </Box>
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
AdminNavbar.defaultProps = {
  openSidebarResponsive: () => {},
};

AdminNavbar.propTypes = {
  // use this to make the Sidebar open on responsive mode
  openSidebarResponsive: PropTypes.func.isRequired,
};

const dataSites = [
  {
    name: "Home",
    innerLink: "/data/school",
    icon: HomeIcon,
  },
  // {
  //   name: "Home 2",
  //   innerLink: "/data/schools",
  //   icon: Hom
  // },daf
  {
    name: "Curriculums",
    innerLink: "/curriculum",
    icon: MenuBook,
  },
  {
    name: "Activities",
    innerLink: "/activities",
    icon: SportsEsportsIcon,
  },
];
const MenuNavigator = () => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const location = useLocation();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
      <Button
        onClick={handleOpenUserMenu}
        sx={{ p: 0 }}
        style={{
          display: "flex",
          padding: "5px 20px",
          color: "white",
          fontSize: "1.1rem",
          fontWeight: 500,
          boxShadow: "0 0 2rem 0 rgb(136 152 170 / 15%)",
          border:
            location.pathname.includes("/activities/studentPage") ||
            location.pathname.includes("/activities/ActivityPage")
              ? "2px solid rgba(0,0,0,0.1)"
              : "",
          color:
            location.pathname.includes("/activities/studentPage") ||
            location.pathname.includes("/activities/ActivityPage")
              ? "#31325D"
              : "white",
        }}
      >
        {location.pathname.includes("/data/")
          ? "Home"
          : location.pathname.includes("/curriculum") || location.pathname.includes("/builder")
          ? "Curriculums"
          : "Activities"}{" "}
        <KeyboardArrowDownIcon />
      </Button>
      {/* {location.pathname.includes("/categories") && <span style={{ background: 'white', width: '1px', height: '33px', marginRight: '17px' }} />}
      {location.pathname.includes("/categories") && <CategorySelector />} */}

      <Menu
        sx={{ mt: "35px", ml: 16 }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {dataSites.map((site) => (
          <Link to={site.innerLink} key={site.innerLink + uuid()}>
            <MenuItem
              key={site.name}
              onClick={() => handleCloseUserMenu()}
              style={{ background: "white" }}
            >
              <Typography
                textAlign="center"
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#06659D",
                }}
              >
                <site.icon style={{ marginRight: "7px", width: 24 }} /> {site.name}
              </Typography>
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </Box>
  );
};
