import React from "react";
import ActivityLessonCardItem from "./ActivityLessonCardItem";
import useUserApi from "api/user";
import { useQuery } from "react-query";

const ActivityLessonClassComp = ({ classId, lessons }) => {
  const { fetchClass } = useUserApi();
  const {
    data: classData,
    status: classStatus,
    isLoading: classIsLoading,
  } = useQuery(["class", classId], () => fetchClass(classId), {
    refetchOnWindowFocus: false,
  });
  // console.log("lessons", lessons);
  // console.log("classData && classData.data", classData && classData.data);
  return (
    <div
      style={{
        margin: "0 0 20px 0",
      }}
    >
      {lessons?.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "30px",
          }}
        >
          {lessons?.map((item) => (
            <ActivityLessonCardItem lessonData={item} />
          ))}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "550px",
            background: "white",
            boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
            borderRadius: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <h1
              style={{
                margin: "10px 0 20px",
                fontWeight: "400",
              }}
            >
              No lessons for class:{" "}
              <span
                style={{
                  margin: "10px 0 20px 10px",
                  fontWeight: "600",
                }}
              >
                {classData && classData?.data?.name}
              </span>
            </h1>
            {/* <h2
              style={{
                margin: "10px 0",
                fontWeight: "400",
              }}
            >
              Choose a different class.
            </h2> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityLessonClassComp;
