import React from "react";

// import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const ActivityFebPlayerBtn = ({
  quesNum,
  numberOfQuestions,
  isEndOfQuestions,
  isStarted,
  btnDisableUntilAnswered,
  handleClickAddArray,
  setQuesNum,
  setBtnDisableUntilAnswered,
  setIsStarted,
  setIsEndOfQuestions,
}) => {
  console.log("isEndOfQuestions :>> ", isEndOfQuestions);
  // console.log('object :>> ', object);
  return (
    <div
      style={{
        width: quesNum === 0 ? "" : quesNum > numberOfQuestions ? "" : "95%",
        display: "flex",
      }}
    >
      {isStarted && quesNum === null}
      {isEndOfQuestions && quesNum !== 0 ? (
        <>
          {" "}
          {/* <button
            style={{
              padding: "8px 16px",
              color: "white",
              background: quesNum > 1 ? "#6358A5" : "#222",
              opacity: quesNum > 1 ? "1" : "0.2",
              cursor: quesNum > 1 ? "pointer" : "not-allowed",
              border: "none",
              width: "50px",
              borderRadius: "100vw",
              borderTopRightRadius: "0",
              borderBottomRightRadius: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              boxShadow:
                " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            }}
            onClick={() => {
              if (quesNum > 1) {
                setQuesNum((prev) => prev - 1);
              }
            }}
          >
            <ArrowBackIosNewIcon />
          </button>
          <button
            style={{
              padding: "8px 16px",
              color: "white",
              background: quesNum < numberOfQuestions ? "#6358A5" : "#222",
              opacity: quesNum < numberOfQuestions ? "1" : "0.2",
              cursor: quesNum < numberOfQuestions ? "pointer" : "not-allowed",
              border: "none",
              width: "50px",
              borderRadius: "100vw",
              borderTopLeftRadius: "0",
              borderBottomLeftRadius: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              boxShadow:
                " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            }}
            onClick={() => {
              if (quesNum < numberOfQuestions) {
                setQuesNum((prev) => prev + 1);
              }
            }}
          >
            <ArrowForwardIosIcon />
          </button> */}
        </>
      ) : (
        <>
          {!isEndOfQuestions ? (
            <>
              {!isStarted ? (
                <button
                  style={{
                    padding: "8px 8px",
                    color: "white",
                    background: "#6358A5",
                    opacity: "1",
                    cursor: "pointer",
                    border: "none",
                    width: "120px",
                    margin: "0 3px",
                    borderRadius: "100vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "600",
                    marginLeft:
                      quesNum === 0
                        ? ""
                        : quesNum > numberOfQuestions
                        ? ""
                        : "0px",
                    boxShadow:
                      " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                  }}
                  onClick={() => {
                    console.log("hhdhahdsa");
                    setBtnDisableUntilAnswered(false);
                    setQuesNum((prev) => prev + 1);
                    setIsStarted(true);
                  }}
                >
                  Start
                </button>
              ) : (
                <button
                  style={{
                    padding: "8px 8px",
                    color: "white",
                    background: btnDisableUntilAnswered ? "#6358A5" : "#222",
                    opacity: btnDisableUntilAnswered ? "1" : "0.2",
                    cursor: btnDisableUntilAnswered ? "pointer" : "not-allowed",
                    border: "none",
                    width: "120px",
                    margin: "0 3px",
                    borderRadius: "100vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "600",
                    marginLeft:
                      quesNum === 0
                        ? ""
                        : quesNum > numberOfQuestions
                        ? ""
                        : "0px",
                    boxShadow:
                      " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                  }}
                  // disabled={btnDisableUntilAnswered}
                  onClick={() => {
                    if (
                      btnDisableUntilAnswered &&
                      numberOfQuestions > quesNum
                    ) {
                      setBtnDisableUntilAnswered(false);
                      setQuesNum((prev) => prev + 1);
                      handleClickAddArray();
                    }
                    if (numberOfQuestions === quesNum) {
                      handleClickAddArray();
                      setIsEndOfQuestions(true);
                    }
                  }}
                >
                  {quesNum === 0
                    ? "Start"
                    : isEndOfQuestions && quesNum > numberOfQuestions
                    ? "Finished"
                    : "next"}
                </button>
              )}
            </>
          ) : null}
        </>
      )}

      {/* */}
    </div>
  );
};

export default ActivityFebPlayerBtn;
