import React, { useState } from "react";
import EditProfile from "../../components/StudentPage/EditProfile";
import Avatar from "@material-ui/core/Avatar";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Box, Skeleton, Tooltip } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import GeneralSnackbar from "components/Snackbar/GeneralSnackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@mui/material/Snackbar";
import useUserApi from "api/user";
import useStore from "../../../../../zustand.js";
import ConfirmModal from "components/Modals/ConfirmModal";
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function StudentManageX({ student, classes, studentsStatus }) {
  const queryClient = useQueryClient();
  const { deleteStudent, updateStudentInfo, fetchClasses } = useUserApi();
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmActionDescription, setConfirmActionDescription] =
    useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { schoolName } = useStore();

  // Fetch class
  const { data: classData, status: classesStatus } = useQuery(
    "classes",
    () => fetchClasses(),
    {
      refetchOnWindowFocus: false,
    }
  );

  // Edit student
  const {
    mutate: mutateEdit,
    isLoading: isLoadingEdit,
    isSuccess: isSuccessEdit,
    isError: isErrorEdit,
    error: errorEdit,
  } = useMutation((studentID) => updateStudentInfo(studentID), {
    onSuccess: () => queryClient.invalidateQueries("students"),
  });

  // Delete student
  const { mutate, isLoading, isSuccess, isError, error } = useMutation(
    (studentID) => deleteStudent(studentID),
    { onSuccess: () => queryClient.invalidateQueries("students") }
  );

  const classesOfStudents =
    classesStatus == "success" &&
    classData.data.filter(
      (item) => item.students.includes(student.id) && item.name
    );

  function performDelete(student) {
    setConfirmAction(
      () =>
        function () {
          mutate(student);
          setOpenSnackbarDetete(true);
        }
    );
    setConfirmActionDescription(
      schoolName
        ? `remove student from ${schoolName}`
        : `remove student from this school`
    );
    setConfirmOpen(true);
  }

  // Snackbar disappear
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarDetete, setOpenSnackbarDetete] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenSnackbar(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [openSnackbar]);

  return (
    <>
      <ConfirmModal
        action={confirmAction}
        actionDescription={confirmActionDescription}
        open={confirmOpen}
        setOpen={setConfirmOpen}
      />
      <GeneralSnackbar
        isLoading={isLoadingEdit}
        isSuccess={isSuccessEdit}
        isError={isErrorEdit}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message="The profile has been successfully edited."
      />

      <GeneralSnackbar
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbarDetete}
        setOpenSnackbar={setOpenSnackbarDetete}
        message="The profile has been successfully deleted."
      />
      <GeneralSnackbar
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbarDetete}
        setOpenSnackbar={setOpenSnackbarDetete}
        message="The profile has been successfully deleted."
      />
      <TableRow style={{ padding: "0" }}>
        <TableCell
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootBodyHead,
          }}
          component="th"
          variant="head"
          scope="row"
          style={{ maxWidth: 50, paddingTop: "8px", paddingBottom: "8px" }}
        >
          <Link to={`/data/student/${student.id}`} style={{ color: "inherit" }}>
            <Box alignItems="center" display="flex">
              {studentsStatus === "loading" ? (
                <Skeleton count={1} width={100} height={50} />
              ) : (
                <Box
                  component={Avatar}
                  alt="..."
                  src={student.icon}
                  style={{
                    border: "2px solid #06659D",
                    background: "#fff",
                    boxShadow: "0 1px 3px hsla(0, 0%, 0%, 0.2)",
                  }}
                />
              )}
            </Box>
          </Link>
        </TableCell>
        <TableCell
          style={{
            verticalAlign: "inherit",
            borderTop: "none",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          <Link to={`/data/student/${student.id}`} style={{ color: "inherit" }}>
            <Box
              display="flex"
              style={{ paddingLeft: "10px", alignItems: "center" }}
            >
              {studentsStatus === "loading" ? (
                <Skeleton count={1} width="90%" height={50} />
              ) : (
                <Box fontSize='.875rem' component='span' className='name'>
                  {student?.full_name?.length > 17 ? student?.full_name?.slice(0, 17) + '...' : student.full_name}
                </Box>
              )}
            </Box>
          </Link>
        </TableCell>
        <TableCell
          style={{
            verticalAlign: "inherit",
            borderTop: "none",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          <Box
            display="flex"
            alignItems="flex-start"
            style={{ paddingLeft: "10px" }}
          >
            {studentsStatus === "loading" ? (
              <Skeleton count={1} width="90%" height={50} />
            ) : (
              <Box
                fontSize=".875rem"
                component="span"
                className="name"
                style={{ display: "flex", alignItems: "center" }}
              >
                {classesOfStudents.length > 3 && (
                  <>
                    {classesOfStudents?.slice(0, 3)?.map((name) => (
                      <div
                        style={{
                          margin: 2,
                          padding: "5px 10px",
                          borderRadius: 12, maxWidth: 200,
                          overflow: "overlay",
                          background: '#f6f9fc',
                          fontSize: '13px',
                        }}
                      >
                        {name.name?.length > 17 ? name.name?.slice(0, 17) + '...' : name.name}
                      </div>
                    ))}
                    <Tooltip
                      title={
                        <div
                          style={{
                            background: "white",
                            color: "#525f7f",
                            borderRadius: "4px",
                            padding: "10px",
                            margin: "1px",
                            border: "1px solid white",
                            fontSize: ".875rem",
                            fontFamily: "Montserrat,sans-serif",
                            fontWeight: 400,
                            maxHeight: "calc(100vh - 100px)",
                            overflow: "auto"
                          }}
                        >
                          {classesOfStudents?.map((item) => (
                            <div
                              style={{
                                margin: 5,
                                marginBottom: "10px",
                                padding: "5px 10px",
                                borderRadius: 12,
                                maxWidth: 200,
                                overflow: "overlay",
                                background: '#f6f9fc',
                                fontSize: '13px',
                              }}
                            >
                              {item.name}
                            </div>
                          ))}
                        </div>
                      }
                      arrow
                      placement='right'
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <IconButton
                        style={{
                          marginLeft: 2,
                          color: "#525f7f",
                          borderRadius: 4,
                          //   border: "1px solid rgb(5, 100, 157)",
                          padding: "0px 10px",
                          height: 32,
                          fontFamily: "Montserrat,sans-serif",
                          fontWeight: 400,
                          fontSize: '13px',
                        }}
                      >
                        View All <ExpandMoreIcon margin={0} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {classesOfStudents.length < 4 &&
                  classesOfStudents?.map((item) => (
                    <div
                      style={{
                        margin: 5,
                        marginBottom: "10px",
                        padding: "5px 10px",
                        borderRadius: 4,
                        maxWidth: 200,
                        overflow: "overlay",
                        background: '#f6f9fc',
                        fontSize: '13px',
                      }}
                    >
                      {item.name}
                    </div>
                  ))}
                {classesOfStudents.length === 0 && (
                  <div
                    style={{
                      margin: 2,
                      padding: "5px 10px",
                      border: "1px solid #7b8794",
                      borderRadius: 4,
                      color: "#7b8794",
                    }}
                  >
                    No Class Assigned
                  </div>
                )}
              </Box>
            )}
          </Box>
        </TableCell>
        {/* <TableCell style={{ verticalAlign: "inherit", borderTop: "none" }}>
                    <IconButton style={{ marginLeft: 15, padding: 0 }}>
                        <ContactMailOutlinedIcon
                            style={{ color: "rgb(5, 100, 157)", width: 33, height: 33 }}
                        />
                    </IconButton>
                </TableCell> */}
        <TableCell
          style={{
            borderTop: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          {studentsStatus === "loading" ? (
            <Skeleton count={1} width="90%" height={50} />
          ) : (
            <>
              <EditProfile
                data={student}
                name={student.first_name}
                lastName={student.last_name}
                email={student.email}
                id={student.id}
                mutate={mutateEdit}
                isLoading={isLoadingEdit}
                isSuccess={isSuccessEdit}
                isError={isErrorEdit}
                error={errorEdit}
                setOpenSnackbar={setOpenSnackbar}
                title="Update Student Info"
              />
              <IconButton style={{ marginLeft: 15 }}>
                <DeleteIcon
                  style={{ color: "rgb(123, 135, 148)" }}
                  onClick={() => performDelete(student.id)}
                />
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

export default StudentManageX;
