import React from "react";
import { Redirect, Route } from "react-router-dom";
import useUserApi from "api/user";
import { useQuery } from "react-query";
// import ErrorPage from "views/eai/curriculum/ErrorPage";
import { LoadingScreen } from "views/eai/curriculum/Components/LoadingScreen";
import usePersistStore from '../zustandPersist'
// import useCurriculumApi from "api/curriculum";
// import useCurriculumApi from "api/curriculum";

const ProtectedRouteClass = ({ component: Component, ...rest }) => {
  const { fetchClasses } = useUserApi();
  const { permission } = usePersistStore()


  const {
    data: classList,
    status: classListStatus,
    isLoading,
  } = useQuery("classes", () => fetchClasses());

  if (isLoading) return <LoadingScreen />;

  return (
    <div>
      <Route
        {...rest}
        render={(props) => {
          if (permission && permission.type === "student") {
            return <Redirect to="/activities" />;
            // return <ErrorPage />;
          } else {
            if (
              classListStatus === "success" &&
              classList.data.filter(
                (_class) => _class.id == props.match.params.classId
              ).length > 0
            ) {
              return <Component {...props} />;
            } else {
              return (
                <Redirect
                  to={{ pathname: "/data", state: { from: props.location } }}
                />
              );
            }
          }
        }}
      />
    </div>
  );
};

export default ProtectedRouteClass;
