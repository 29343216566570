import React from "react";
import BuilderFilterCompLevels from "./BuilderFilterCompLevels";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import BuilderCurrentLessons from "./BuilderCurrentLessons";
const BuilderFilterComp = ({
  levelNameCurriculum,
  levelNameInput,
  setLevelNamesFilter,
  levelNamesFilter,
  searchWord,
  levelItemNamesFilter,
  setLevelItemNamesFilter,
  setSearchWord,
  customLessonData,
  setSelectedLevelFilter,
  expandFilter,
  setExpandFilter,
}) => {
  return (
    <div style={{ marginBottom: "10px" }}>
      <div
        style={{
          // background: "white",
          boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
          // padding: "6px",
          background: expandFilter ? "rgba(6, 101, 157,1)" : "#ffffff",
          borderRadius: "4px",
          overflow: "hidden",
          // width: "350px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "rgba(6, 101, 157,1)",
            padding: "4px 10px",
            alignItems: "center",
            margin: expandFilter ? "0" : "0",
            cursor: "pointer",
          }}
          onClick={() => setExpandFilter((prev) => !prev)}
        >
          <h3
            style={{
              margin: "0",
              padding: "0 ",
              fontSize: "16px",
              color: expandFilter ? "#ffffff" : "#ffffff",
            }}
          >
            Filter
          </h3>
          {expandFilter ? (
            <ExpandMoreIcon
              style={{
                margin: "0",
                fontSize: "24px",
                cursor: "pointer",
                color: expandFilter ? "#ffffff" : "#ffffff",
              }}
            />
          ) : (
            <KeyboardArrowUpIcon
              style={{
                margin: "0",
                fontSize: "24px",
                cursor: "pointer",
                color: expandFilter ? "#ffffff" : "#ffffff",
              }}
            />
          )}
        </div>
        {expandFilter ? (
          <div></div>
        ) : (
          <div style={{ padding: "6px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "6px 0",
              }}
            >
              <input
                style={{
                  border: "1px solid #CBD2D9",
                  fontSize: "14px",
                  padding: "6px 9px",
                  borderRadius: "8px",
                  width: "220px",
                }}
                placeholder="Search lesson..."
                value={searchWord}
                onChange={(e) => setSearchWord(e.target.value)}
              ></input>
              <button
                style={{
                  background:
                    levelNamesFilter.length > 0 || levelItemNamesFilter.length > 0
                      ? "#06659D"
                      : "rgba(0,0,0,0.1)",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  padding: "6px 14px",
                  fontSize: "14px",
                  borderRadius: "8px",
                  fontWeight: "500",
                  margin: "0 0 0 5px",
                }}
                onClick={() => {
                  if (levelNamesFilter.length > 0 || levelItemNamesFilter.length > 0) {
                    setLevelNamesFilter([]);
                    setSearchWord("");
                  }
                }}
              >
                Clear
              </button>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                // gridTemplateColumns: "1fr 1fr 1fr",
                gap: "10px",
                margin: "15px 0 0 0 ",
                //   background: "rgba(6, 101, 157,1)",
              }}
            >
              {levelNameCurriculum?.map((itemTitle, index) => (
                <div>
                  <BuilderFilterCompLevels
                    name={itemTitle}
                    levels={levelNameInput?.filter((item) => itemTitle === item.split("/")[0])}
                    levelNamesFilter={levelNamesFilter}
                    setLevelNamesFilter={setLevelNamesFilter}
                    levelItemNamesFilter={levelItemNamesFilter}
                    setLevelItemNamesFilter={setLevelItemNamesFilter}
                    levelNameInput={levelNameInput}
                    setSelectedLevelFilter={setSelectedLevelFilter}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BuilderFilterComp;
