import React from "react";
import { Box } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { useQuery } from "react-query";
import useUserApi from "api/user";
import { Link } from "react-router-dom";
import LaunchIcon from '@mui/icons-material/Launch';
import { IconButton, Tooltip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


function StudentProfile({ data, status, params }) {
    const { fetchClasses } = useUserApi();

    // Fetch class
    const { data: classes, status: classesStatus } = useQuery("classes", () =>
        fetchClasses(),
        {
            select: (data) => {
                const classData = data.data.filter((item) => item.students.includes(params.studentId))
                return classData
            }
        }
    );
    return (
        <div
            style={{
                margin: "20px",
                boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                background: "white",
                borderRadius: "8px",
            }}
        >
            <div
                style={{
                    maxHeight: "410px",
                    overflow: "auto",
                }}
            >
                {status == "success" ? (
                    <div style={{ display: 'flex', alignItems: "center", padding: 20, justifyContent: 'space-between' }} >
                        <Box style={{ display: 'flex', alignItems: "center", width: 300, flex: 1 }}>
                            <Box
                                component={Avatar}
                                marginRight="1rem"
                                alt="..."
                                src={data.data.icon}
                                width={55}
                                height={55}
                                style={{
                                    border: "2px solid #06659D",
                                    background: "#fff",
                                    boxShadow: "0 2px 4px hsla(0, 0%, 0%, 0.2)",

                                }}
                            />
                            <div>
                                <h3 style={{
                                    marginBottom: 0, marginRight: 15, maxWidth: '320px',
                                    overflow: 'auto', width: '100%',
                                }}>
                                    {data.data.full_name}
                                </h3>
                            </div>
                        </Box>
                        <div
                            style={{
                                display: "flex",
                                flexWrap: 'wrap',
                                gap: "16px",
                                flex: 2,
                                backgroundColor: "rgba(255, 255, 255)"
                            }}
                        >
                            {classesStatus === 'success' &&
                                classes?.length > 4 ?
                                <>
                                    {classes?.slice(0, 4).map((item) => (
                                        <Link to={`/data/class/${item.id}`}>
                                            <div
                                                style={{
                                                    border: "2px solid #E1EDFB",
                                                    padding: "6px 20px",
                                                    display: "flex",
                                                    // boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                                                    width: '100%',
                                                    borderRadius: "12px"
                                                }}
                                            >
                                                <h3
                                                    style={{
                                                        fontSize: "14px",
                                                        margin: "0",
                                                        width: '100%',
                                                    }}
                                                >
                                                    <LaunchIcon style={{ height: 19 }} />  {item.name?.length > 17 ? item.name?.slice(0, 17) + '...' : item.name}
                                                </h3>
                                            </div>
                                        </Link>
                                    ))}
                                    <Tooltip
                                        title={
                                            <div
                                                style={{
                                                    background: "white",
                                                    color: "#525f7f",
                                                    borderRadius: "4px",
                                                    padding: "10px",
                                                    margin: "1px",
                                                    border: "1px solid white",
                                                    fontSize: ".875rem",
                                                    fontFamily: "Montserrat,sans-serif",
                                                    fontWeight: 400,
                                                    maxHeight: "calc(100vh - 100px)",
                                                    overflow: "auto",
                                                }}
                                            >
                                                {classes?.map((item) => (
                                                    <Link to={`/data/class/${item.id}`}>
                                                        <div
                                                            style={{
                                                                border: "2px solid #E1EDFB",
                                                                padding: "6px 20px",
                                                                display: "flex",
                                                                marginBottom: 12,
                                                                width: '100%',
                                                                borderRadius: "12px"
                                                            }}
                                                        >
                                                            <h3
                                                                style={{
                                                                    fontSize: "14px",
                                                                    margin: "0",
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <LaunchIcon style={{ height: 19 }} />  {item.name}
                                                            </h3>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>
                                        }
                                        arrow
                                        placement='right'
                                    >
                                        <IconButton
                                            style={{
                                                marginLeft: 2,
                                                color: "#525f7f",
                                                borderRadius: 4,
                                                //   border: "1px solid rgb(5, 100, 157)",
                                                padding: "0px 10px",
                                                fontFamily: "Montserrat,sans-serif",
                                                fontWeight: 400,
                                                fontSize: '13px',

                                            }}
                                        >
                                            View All <ExpandMoreIcon margin={0} />
                                        </IconButton>
                                    </Tooltip>
                                </>
                                :
                                classes?.map((item) => (
                                    <Link to={`/data/class/${item.id}`}>
                                        <div
                                            style={{
                                                border: "2px solid #E1EDFB",
                                                padding: "6px 20px",
                                                display: "flex",
                                                // boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                                                width: '100%',
                                                borderRadius: "12px"
                                            }}
                                        >
                                            <h3
                                                style={{
                                                    fontSize: "14px",
                                                    margin: "0",
                                                    width: '100%',
                                                }}
                                            >
                                                <LaunchIcon style={{ height: 19 }} />  {item.name?.length > 17 ? item.name?.slice(0, 17) + '...' : item.name}
                                            </h3>
                                        </div>
                                    </Link>
                                ))
                            }
                        </div>
                    </div>
                ) : null
                }
            </div>
        </div>
    );
}

export default StudentProfile;
