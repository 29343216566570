import useDataApi from "api/data";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import LearningObjItem from "./LearningObjItem";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// import FileDownloadSharpIcon from "@mui/icons-material/FileDownloadSharp";
// import FileUploadSharpIcon from "@mui/icons-material/FileUploadSharp";
import ArrowDropUpSharpIcon from "@mui/icons-material/ArrowDropUpSharp";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import uuid from "uuid";
const ClassLessonLearningObj = ({ selectedLessonUUID }) => {
  const { classId } = useParams();
  const { fetchClassLearningObjectives } = useDataApi();
  const [ascendSort, setAscendSort] = useState(false);
  const {
    data: learningObjectives,
    status: learningObjectivesStatus,
    isLoading: learningObjectivesLoading,
  } = useQuery(
    ["class_lesson_learning_objectives_section", classId, selectedLessonUUID],
    () => fetchClassLearningObjectives(classId, selectedLessonUUID),
    {
      refetchOnWindowFocus: false,
    }
  );
  if (learningObjectives) {
    // console.log(
    //   "learningObjectives.data :OOOOOOOOOOOOOOOO>> ",
    //   learningObjectives.data
    // );
  }
  if (learningObjectivesLoading) {
    return (
      <div
        style={{
          margin: "20px",
          boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
          background: "white",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#05649D",
            color: "white",
            //   borderRadius: "199px",
            overflow: "hidden",

            padding: "10px",
            //   margin: "10px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "40%",
              // justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                margin: "0 0 0 12px",
                color: "white",
                fontSize: "14px",
              }}
            >
              Learning Objectives
            </h5>
          </div>
        </div>
        <div
          style={{
            margin: "20px",
            //   color: "white",
            // display: "flex",
            fontSize: "22px",
          }}
        >
          <Skeleton count={10} height={30} />
        </div>
      </div>
    );
  }
  if (learningObjectives && learningObjectivesStatus === "success") {
    // console.log("learningObjectives", learningObjectives);
    return (
      <div
        style={{
          margin: "20px",
          boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
          background: "white",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#05649D",
            color: "white",
            //   borderRadius: "199px",
            overflow: "hidden",

            padding: "10px",
            //   margin: "10px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "70%",
              // justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                margin: "0 0 0 12px",
                color: "white",
                fontSize: "16px",
              }}
            >
              Learning Objectives
            </h5>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => setAscendSort((prev) => !prev)}
            >
              <h5
                style={{
                  margin: "0 2px 0 12px",
                  color: "white",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Score
              </h5>

              {ascendSort ? (
                <ArrowDropDownSharpIcon />
              ) : (
                <ArrowDropUpSharpIcon />
              )}
              {/* {ascendSort ? <FileDownloadSharpIcon /> : <FileUploadSharpIcon />} */}
            </div>
          </div>
        </div>
        <div
          style={{
            maxHeight: "410px",
            overflow: "auto",
          }}
        >
          {learningObjectives?.data?.learning_objectives
            .sort((a, b) =>
              ascendSort ? a.score - b.score : b.score - a.score
            )
            .map((item) => (
              <div key={uuid()}>
                <LearningObjItem
                  learningObj={item.learning_objective}
                  score={item.score * 100}
                />
              </div>
            ))}
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        margin: "20px",
        boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
        background: "white",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#05649D",
          color: "white",
          //   borderRadius: "199px",
          overflow: "hidden",

          padding: "10px",
          //   margin: "10px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "40%",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5
            style={{
              margin: "0 0 0 12px",
              color: "white",
              fontSize: "14px",
            }}
          >
            Learning Objectives
          </h5>
        </div>
      </div>
      <div
        style={{
          margin: "20px",
          //   color: "white",
          fontSize: "22px",
        }}
      >
        No learning objectives completed.
      </div>
    </div>
  );
};

export default ClassLessonLearningObj;
