// import useCurriculumApi from "api/curriculum";
// import useCurriculumApi from "api/curriculum";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import usePersistStore from '../zustandPersist'

const ProtectedRouteSchool = ({ component: Component, ...rest }) => {
  const { permission } = usePersistStore()


  return (
    <div>
      <Route
        {...rest}
        render={(props) => {
          if (permission && permission.type === "student") {
            return <Redirect to='/activities' />;
            // return <ErrorPage />;
          } else {
            return <Component {...props} />;
          }
        }}
      />
    </div>
  );
};

export default ProtectedRouteSchool;
