// import { IconButton } from "@material-ui/core";
import { PlayArrowOutlined } from "@mui/icons-material";
// import { Stack } from "@mui/material";
import React from "react";
import DarkTheme from "@blockly/theme-dark";
import ThemeHigh from "@blockly/theme-highcontrast";
import ThemeTrit from "@blockly/theme-tritanopia";
import ThemeDeuter from "@blockly/theme-deuteranopia";
import Theme from "@blockly/theme-modern";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
// import Speech from "react-speech";
// import { useSpeechSynthesis } from "react-speech-kit";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
// import { useQuery } from "react-query";
import useCurriculumApi from "api/curriculum";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import ActivityCurriculumPlayer from "../ActivityCurriculumPlayer";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmModal from "components/Modals/ConfirmModal";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@mui/material/Snackbar";
import GeneralSnackbar from "components/Snackbar/GeneralSnackbar";
// import StopIcon from "@mui/icons-material/Stop";
import EAILogoAct from "../../../../assets/img/EducationaiLogos/Group 1536.svg";
import usePersistStore from "../../../../zustandPersist";
import SettingsIcon from "@mui/icons-material/Settings";
import Tooltip from "@mui/material/Tooltip";

// assets/img/EducationaiLogos/Group 1536.svg";
const BlocksHeader = ({
  switchCodeView,
  runCode,
  toggleTerminal,
  running,
  setBlockTheme,
  instructions,
  getHint,
  hintData,
  resourceData,
  lessonId,
  exerciseName,
  isLoadingHint,
  submitAnswer,
  submitIsLoading,
  submitIsError,
  submitIsSuccess,
  setHintText,
  hintText,
  submitData,
  submitBlockAnswerSubmit,
}) => {
  const [ourText, setOurText] = React.useState("");
  const [langVoice, setLangVoice] = useState(null);
  const synthRef = React.useRef(window.speechSynthesis);
  const [showThemes, setShowThemes] = useState(false);
  const { setBlocksTheme, blocksTheme } = usePersistStore();

  // const speechHandler = (msg) => {
  //   msg.text = ourText;
  //   window.speechSynthesis.speak(msg);
  // };
  const [open, setOpen] = React.useState(false);
  const [openLesson, setOpenLesson] = React.useState(false);
  const [openSubmit, setOpenSubmit] = React.useState(false);
  const [messageNoLesson, setMessageNoLesson] = useState(true);
  const { fetchLevelResources, fetchLessonResources } = useCurriculumApi();
  const [showResults, setShowResults] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenLesson = () => setOpenLesson(true);
  const handleCloseLesson = () => setOpenLesson(false);

  const handleOpenSubmit = () => setOpenSubmit(true);
  const handleCloseSubmit = () => {
    setOpenSubmit(false);
    setShowResults(false);
  };

  //Confirmation
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmAction, setConfirmAction] = React.useState(null);
  const [confirmActionDescription, setConfirmActionDescription] = React.useState(null);

  // snackbar

  // Snackbar disappear
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenSnackbar(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [openSnackbar]);
  // const { isLoading, isError, data, error } = useQuery(
  //   ["resourcesPlayer", lessonId],
  //   () => {
  //     return fetchLessonResources(lessonId);
  //   },
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );

  React.useEffect(() => {
    setTimeout(() => {
      const voices = synthRef.current.getVoices();
      // console.log("voices", voices);
      // console.log("voices", voices);
      // console.log(
      //   'voices.map.voiceURI.includes("Google UK English Female") :>> ',
      //   voices.filter((item) => item.voiceURI === "Google UK English Female")
      // );
      setLangVoice(
        voices.filter((item) =>
          voices.filter((itemA) => itemA.voiceURI === "Google UK English Female").length > 0
            ? item.voiceURI === "Google UK English Female"
            : item.name === "Alex"
        )[0]
        // voices.filter((item) => item.voiceURI === "Google UK English Female")[0]
      );
    }, 100);
  }, []);

  React.useEffect(() => {
    console.log("Speakinggggggggg");
    if (synthRef.current.speaking) {
      console.log("synthRef.current.speaking :>> ", synthRef.current.speaking);
      console.log("synthRef.current.speaking :>> ", synthRef.current.speaking);
      console.log("synthRef.current.speaking :>> ", synthRef.current.speaking);
    } else if (!synthRef.current.speaking) {
      console.log("Not Speakingggggggg");
    } else {
      console.log("neither");
    }
  }, [synthRef.current.speaking]);

  React.useEffect(() => {
    setOurText("data?.instructions");
    if (synthRef.current.speaking) {
      setIsSpeaking(true);
    } else {
      setIsSpeaking(false);
    }
  }, [synthRef.current.speaking]);

  const speechHandler = (msg) => {
    const utter = new SpeechSynthesisUtterance(msg);
    utter.voice = langVoice;
    synthRef.current.speak(utter);
  };
  // const { speak } = useSpeechSynthesis();
  function toSubmitAnswer() {
    setConfirmAction(
      () =>
        function () {
          submitAnswer();
          handleOpenSubmit();
          // submitBlockAnswerSubmit();
        }
    );
    setConfirmActionDescription("submit your answer");
    setConfirmOpen(true);
  }
  console.log("==========================================");

  console.log("==========================================");

  return (
    <div>
      <ConfirmModal
        action={confirmAction}
        actionDescription={confirmActionDescription}
        open={confirmOpen}
        setOpen={setConfirmOpen}
      />

      {hintData?.hint && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            margin: "0 auto 0",
            //   // marginTop: "0",
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              margin: "0 auto 0",
              background: "white",
              minWidth: "380px",
              padding: "30px",
              borderRadius: "12px",
              width: "50vw",
              textAlign: "center",
            }}
          >
            {isLoadingHint ? (
              <div
                style={{
                  margin: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "4px",
                }}
              >
                <CircularProgress disableShrink style={{ color: "white" }} size={20} />
              </div>
            ) : (
              <p style={{ margin: "0", fontSize: "24px", fontWeight: "500" }}>{hintData?.hint}</p>
            )}
          </div>
        </Modal>
      )}
      <GeneralSnackbar
        isLoading={submitIsLoading}
        isSuccess={submitIsSuccess}
        isError={submitIsError}
        Snackbar={Snackbar}
        SnackbarContent={SnackbarContent}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message="The answer has now been submitted."
      />
      {/* {submitData && !submitIsLoading && ( */}
      <Modal
        open={openSubmit}
        onClose={handleCloseSubmit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          margin: "0px auto 0",
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          {!showResults && (
            <div
              style={{
                margin: "0 auto 0",
                background: "white",
                minWidth: "380px",
                padding: "30px",
                borderRadius: "12px",
                width: "25vw",
                textAlign: "center",
                // transform: "translate(-50%,-50%)",
                position: "relative",
              }}
            >
              <div style={{ display: "flex" }}>
                <h3>Are you sure? This action cannot be undone.</h3>
                <img
                  src={EAILogoAct}
                  alt=""
                  srcset=""
                  style={{
                    position: "absolute",
                    width: "70px",
                    opacity: "0.8",
                    right: "18px",
                    top: "16px",
                    // transform: "translate(-50%,50%)",
                  }}
                />
              </div>
              <div style={{ margin: "30px auto" }}>
                By clicking confirm, <strong>your answer will be submitted.</strong>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <button
                  onClick={handleClose}
                  style={{
                    color: "#6358A5",
                    // border: "0",
                    padding: "6px 22px",
                    // color: "white",
                    background: "white",
                    cursor: "pointer",
                    // border: "none",
                    borderRadius: "4px",
                    border: "2px solid #6358A5",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "600",
                    boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    // margin: "0",
                  }}
                  // variant="outlined"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    submitAnswer();
                    // submitBlockAnswerSubmit();
                    setShowResults(true);
                  }}
                  style={{
                    backgroundColor: "#6358A5",
                    border: "2px solid #6358A5",
                    cursor: "pointer",
                    // border: "none",
                    color: "white",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "6px 22px",
                    fontWeight: "600",
                    boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    // margin: "0",
                  }}
                  variant="contained"
                >
                  Confirm
                </button>
              </div>
            </div>
          )}
          {showResults && (
            <div
              style={{
                margin: "0 auto 0",
                background: "white",
                minWidth: "380px",
                minHeight: "180px",
                padding: "30px",
                borderRadius: "12px",
                width: "50vw",
                textAlign: "center",
                // transform: "translate(-50%,-50%)",
              }}
            >
              <>
                <h1>Results</h1>
                {submitIsLoading && (
                  <div
                    style={{
                      margin: "0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "4px",
                    }}
                  >
                    <CircularProgress
                      disableShrink
                      style={{ color: "#6358A5", margin: "20px 0 10px" }}
                      size={40}
                    />
                  </div>
                )}
                {!submitIsLoading && submitData && (
                  <ul
                    style={{
                      textAlign: "start",
                      // lineHeight: "2",
                    }}
                  >
                    {submitData?.scoring_results.bad?.length > 0 && (
                      <li
                        style={{
                          marginBottom: "4px",
                        }}
                      >
                        {submitData?.scoring_results?.bad}
                      </li>
                    )}
                    {submitData?.scoring_results.good?.length > 0 && (
                      <li
                        style={{
                          marginBottom: "4px",
                        }}
                      >
                        {submitData?.scoring_results?.good}
                      </li>
                    )}
                    {submitData?.scoring_results?.other?.length > 0 && (
                      <li
                        style={{
                          marginBottom: "4px",
                        }}
                      >
                        {submitData?.scoring_results?.other}
                      </li>
                    )}
                  </ul>
                )}
                <h2>{submitData?.scoring_results}</h2>
                <h2>{submitData?.scoring_results?.score}</h2>
              </>
            </div>
          )}
        </div>
      </Modal>
      {/* )} */}
      <Modal
        open={openLesson}
        onClose={handleCloseLesson}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          margin: " 20px auto 0",
          //   // marginTop: "0",
          //   // height: "30vh",
          width: "95vw",
        }}
      >
        <ActivityCurriculumPlayer
          exerciseName={exerciseName}
          lessonId={lessonId}
          lessonPage={false}
          lessonResourcesData={
            resourceData &&
            resourceData?.data.filter(
              (item) =>
                item.type.name.toLowerCase() === "presentation" ||
                item.type.name.toLowerCase() === "video"
            )
          }
          handleClose={handleCloseLesson}
        />
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          padding: "10px 5px 15px",
        }}
      >
        {/* <button
          class="btn btn-primary btn-lg"
          onClick={() => speak({ text: "Hello React Js" })}
        >
          Speak
        </button> */}
        <div>
          <h1 style={{ margin: "0", fontSize: "22px" }}>
            Blocks: <span style={{ margin: "0", fontWeight: "500" }}>{exerciseName}</span>
          </h1>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
          }}
        >
          {/* <button
            // disabled={running}
            // onClick={runCode}
            style={{
              padding: "4px 12px",
              color: "white",
              background: "#6358A5",
              cursor: "pointer",
              border: "none",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              boxShadow:
                " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              margin: "0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  color: "white",
                  margin: "0",
                  padding: "0",
                }}
                // onChange={(e) => setOurText(e.target.value)}
                onClick={() => speechHandler(instructions)}
              >
                Speak <VolumeUpIcon />
              </p>
            </div>
          </button> */}
          <button
            // to={`/activities/Curriculum/${exerciseData.data.name}/${exerciseData.data.stage}`}
            // onClick={handleOpen}
            onClick={() => {
              handleOpenLesson();
            }}
            style={{
              padding: "4px 12px",
              color: "white",
              background: "#6358A5",
              cursor: "pointer",
              border: "none",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              margin: "0",
              // width: "200px",
            }}
          >
            <LibraryBooksIcon
              style={{
                margin: "0 10px 0 0",
                padding: "0",
                // fontSize: "34px",
              }}
            />{" "}
            <p
              style={{
                fontSize: "16px",
                color: "white",
                margin: "0",
                padding: "0",
              }}
              // onChange={(e) => setOurText(e.target.value)}
            >
              Revise Lesson
            </p>
          </button>
          {/* <button
            // to={`/activities/Curriculum/${exerciseData.data.name}/${exerciseData.data.stage}`}
            onClick={() => {
              getHint();
              handleOpen();
            }}
            style={{
              padding: "4px 12px",
              color: "white",
              background: "#6358A5",
              cursor: "pointer",
              border: "none",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              boxShadow:
                " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              margin: "0",
              width: "100px",
            }}
          >
            {!isLoadingHint && (
              <>
                {" "}
                <LightbulbIcon
                  style={{
                    margin: "0 10px 0 0",
                    padding: "0",
                    // fontSize: "34px",
                  }}
                />{" "}
                <p
                  style={{
                    fontSize: "16px",
                    color: "white",
                    margin: "0",
                    padding: "0",
                  }}
                  // onChange={(e) => setOurText(e.target.value)}
                >
                  Hint
                </p>
              </>
            )}
            {isLoadingHint && (
              <div
                style={{
                  margin: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "4px",
                }}
              >
                <CircularProgress
                  disableShrink
                  style={{ color: "white" }}
                  size={20}
                />
              </div>
            )}
          </button> */}
          <button
            disabled={running}
            onClick={runCode}
            style={{
              padding: "4px 12px",
              color: "white",
              background: "#6358A5",
              cursor: "pointer",
              border: "none",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              margin: "0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  color: "white",
                  margin: "0",
                  padding: "0",
                }}
              >
                Run
              </p>{" "}
              <PlayArrowOutlined
                color="#fff"
                // fontSize="28px"
                style={{
                  color: "#fff",
                  margin: "0",
                  padding: "0",
                  fontSize: "28px",
                }}
              />
            </div>
          </button>
          <Tooltip
            title={
              <div
                style={{
                  fontSize: "14px",
                  background: "white",
                  color: "rgba(0,0,0,0.8)",
                  borderRadius: "4px",
                  padding: "10px 10px 8px",
                  margin: "8px",
                  textAlign: "center",
                }}
              >
                <h4
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    margin: 0,
                  }}
                >
                  Coming soon.
                </h4>
              </div>
            }
            arrow
            placement="top"
          >
            <button
              // disabled={running}
              // onClick={() => handleOpenSubmit()}
              style={{
                padding: "4px 12px",
                color: "white",
                background: "#6358A5",
                cursor: "not-allowed",
                border: "none",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "600",
                boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                margin: "0",
                width: "135px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!submitIsLoading && (
                  <>
                    <p
                      style={{
                        fontSize: "16px",
                        color: "white",
                        margin: "0",
                        padding: "0",
                      }}
                      // onChange={() => submitBlockAnswerSubmit()}
                    >
                      Submit code
                    </p>
                  </>
                )}
                {submitIsLoading && (
                  <div
                    style={{
                      margin: "0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "4px",
                    }}
                  >
                    <CircularProgress disableShrink style={{ color: "white" }} size={20} />
                  </div>
                )}
              </div>
            </button>
          </Tooltip>
        </div>
        {/* <Speech text="I want to get the sum of two arrays...Actually the sum of all their elements. I'll appreciate for yourhelp. P.S. Each array includes only integer numbers. Output is a number too." /> */}

        {false && (
          <div>
            <button onClick={() => setBlockTheme(Theme)}>Original theme</button>
            <button onClick={() => setBlockTheme(DarkTheme)}>Dark theme</button>
            <button onClick={() => setBlockTheme(ThemeHigh)}>highcontrast theme</button>
            <button onClick={() => setBlockTheme(ThemeTrit)}>tritanopia theme</button>
            <button onClick={() => setBlockTheme(ThemeDeuter)}>deuteranopia theme</button>
          </div>
        )}
      </div>
      {/* ===================== */}
      <div
        className="header"
        style={{
          background: "white",
          borderRadius: "12px",
          padding: "6px 10px",
          marginBottom: "10px",
          // margin: "auto 0 20px",
          margin: "auto 0 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
            margin: "auto",
            height: "100%",
            gap: "5px",
          }}
        >
          {/* <strong style={{ margin: "4px 10px 0 0" }}>Question: </strong> */}
          <div
            style={{
              fontSize: "16px",
              color: "white",
              margin: "auto 5px auto 0",
              borderRadius: "12px",
              background: "rgba(99, 88, 165,0.4)",
              padding: "5px",
              cursor: "pointer",
              width: "34px",
              height: "100%",
            }}
            // onChange={(e) => setOurText(e.target.value)}
            onClick={() => speechHandler(instructions)}
          >
            <VolumeUpIcon />
          </div>
          <p
            style={{
              margin: "0",
              padding: "5px",
              fontWeight: "500",
              fontSize: "20px",
            }}
          >
            <strong style={{ margin: "0px 4px 0 0", fontWeight: "700" }}>Question: </strong>
            {instructions}
          </p>
        </div>
        <div style={{ display: "flex", gap: "12px" }}>
          <button
            // to={`/activities/Curriculum/${exerciseData.data.name}/${exerciseData.data.stage}`}
            onClick={() => {
              getHint();
              handleOpen();
            }}
            style={{
              padding: "4px 12px",
              color: "white",
              background: "#6358A5",
              cursor: "pointer",
              border: "none",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              margin: "0",
              width: "100px",
            }}
          >
            {!isLoadingHint && (
              <>
                {" "}
                <LightbulbIcon
                  style={{
                    margin: "0 5px 0 0",
                    padding: "0",
                    // fontSize: "34px",
                  }}
                />{" "}
                <p
                  style={{
                    fontSize: "16px",
                    color: "white",
                    margin: "0",
                    padding: "0",
                  }}
                  // onChange={(e) => setOurText(e.target.value)}
                >
                  Hint
                </p>
              </>
            )}
            {isLoadingHint && (
              <div
                style={{
                  margin: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "4px",
                }}
              >
                <CircularProgress disableShrink style={{ color: "white" }} size={20} />
              </div>
            )}
          </button>
          <button
            style={{
              padding: "4px 12px",
              color: "white",
              background: "#6358A5",
              border: "none",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              margin: "0",
              width: "60px",
              position: "relative",
              borderBottomLeftRadius: showThemes ? "0" : "8px",
              borderBottomRightRadius: showThemes ? "0" : "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <SettingsIcon
                color="#fff"
                // fontSize="28px"
                style={{
                  color: "#fff",
                  margin: "0",
                  padding: "0",
                  fontSize: "28px",
                }}
                onClick={() => setShowThemes((prev) => !prev)}
              />
            </div>
            {showThemes && (
              <>
                {" "}
                <div
                  style={{
                    position: "absolute",
                    top: "calc(100%)",
                    background: "#6358A5",
                    right: "0",
                    height: "10px",
                    width: "60px",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    padding: "8px 8px",
                    color: "white",
                    background: "#6358A5",
                    border: "none",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "600",
                    // boxShadow:
                    //   " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    margin: "0",
                    top: "calc(100% + 10px)",
                    right: "0",
                    zIndex: 9,
                    gap: "5px",
                    borderTopRightRadius: "0",
                  }}
                >
                  <p
                    style={{
                      padding: "4px 14px",
                      borderRadius: "8px",
                      margin: "0",
                      width: "100%",
                      cursor: "pointer",
                      background:
                        blocksTheme === Theme ? "rgba(255,255,255,1)" : "rgba(255,255,255,0.2)",
                      color: blocksTheme === Theme ? "#6358A5" : "rgba(255,255,255,1)",
                      fontWeight: "500",
                      boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    }}
                    onClick={() => setBlocksTheme(Theme)}
                  >
                    Theme
                  </p>

                  <p
                    style={{
                      padding: "4px 14px",
                      borderRadius: "8px",
                      margin: "0",
                      width: "100%",
                      cursor: "pointer",
                      background:
                        blocksTheme === DarkTheme ? "rgba(255,255,255,1)" : "rgba(255,255,255,0.2)",
                      color: blocksTheme === DarkTheme ? "#6358A5" : "rgba(255,255,255,1)",
                      fontWeight: "500",
                      boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    }}
                    onClick={() => setBlocksTheme(DarkTheme)}
                  >
                    Dark Theme
                  </p>
                  <p
                    style={{
                      padding: "4px 14px",
                      borderRadius: "8px",
                      margin: "0",
                      width: "100%",
                      cursor: "pointer",
                      background:
                        blocksTheme === ThemeHigh ? "rgba(255,255,255,1)" : "rgba(255,255,255,0.2)",
                      color: blocksTheme === ThemeHigh ? "#6358A5" : "rgba(255,255,255,1)",
                      fontWeight: "500",
                      boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    }}
                    onClick={() => setBlocksTheme(ThemeHigh)}
                  >
                    High contrast
                  </p>
                  <p
                    style={{
                      padding: "4px 14px",
                      borderRadius: "8px",
                      margin: "0",
                      width: "100%",
                      cursor: "pointer",
                      background:
                        blocksTheme === ThemeTrit ? "rgba(255,255,255,1)" : "rgba(255,255,255,0.2)",
                      color: blocksTheme === ThemeTrit ? "#6358A5" : "rgba(255,255,255,1)",
                      fontWeight: "500",
                      boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    }}
                    onClick={() => setBlocksTheme(ThemeTrit)}
                  >
                    Tritanopia
                  </p>
                  <p
                    style={{
                      padding: "4px 14px",
                      margin: "0",
                      borderRadius: "8px",
                      width: "100%",
                      cursor: "pointer",
                      background:
                        blocksTheme === ThemeDeuter
                          ? "rgba(255,255,255,1)"
                          : "rgba(255,255,255,0.2)",
                      color: blocksTheme === ThemeDeuter ? "#6358A5" : "rgba(255,255,255,1)",
                      fontWeight: "500",
                      boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    }}
                    onClick={() => setBlocksTheme(ThemeDeuter)}
                  >
                    Deuteranopia
                  </p>
                </div>
              </>
            )}
          </button>
        </div>
        {/* <Timer /> */}
      </div>
      {/* <div
        style={{
          padding: "0 0px",
          // background: "rgb(99, 88, 165)",
          // borderRadius: "8px",
          fontSize: "18px",
          color: "white",
          margin: "4px 0",
          gap: "12px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          disabled={running}
          onClick={runCode}
          style={{
            background: "rgb(99, 88, 165)",
            borderRadius: "6px",
            padding: "0 8px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: "white",
                margin: "0",
                padding: "0",
              }}
            >
              run
            </p>{" "}
            <PlayArrowOutlined
              color="#fff"
              // fontSize="28px"
              style={{
                color: "#fff",
                margin: "0",
                padding: "0",
                fontSize: "28px",
              }}
            />
          </div>
        </IconButton>
        <IconButton
          // disabled={running}
          // onClick={runCode}
          style={{
            background: "rgb(99, 88, 165)",
            borderRadius: "6px",
            padding: "0 8px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: "white",
                margin: "0",
                padding: "0",
              }}
            >
              Submit code
            </p>
          </div>
        </IconButton>
      </div> */}
    </div>
  );
};

export default BlocksHeader;
