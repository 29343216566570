import React from "react";

const ActivityFebH5PComItem = ({ num, questionAct }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "8px",
        margin: "15px 0 ",
        width: "100%",
        boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        background: "rgb(99, 88, 165)",
        color: "white",
      }}
    >
      <h3 style={{ margin: "0 10px 0 0", color: "white" }}>Question {num},</h3>
      <div>
        <div>
          [<strong>foldername:</strong>
          {questionAct.foldername}]
        </div>
        <div>
          [<strong>filename:</strong>
          {questionAct.filename}]
        </div>
        <div>
          <div>
            [<strong>name:</strong>
            {questionAct.name}]
          </div>
          <div>
            [<strong>id:</strong>
            {questionAct.id}]
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityFebH5PComItem;
