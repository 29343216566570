import React, { useEffect, useState } from "react";
import useCurriculumApi from "api/curriculum";
import { useQuery } from "react-query";
import Iframe from "react-iframe";
import ActivityPlayerTab from "./ActivityPlayerTab";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { LoadingScreen } from "../curriculum/Components/LoadingScreen";
const ActivityCurriculumLessonPlayer = ({
  exerciseName,
  lessonResourcesData,
  handleClose,
  lessonPage,
  classModal,
  isLoadingResources,
}) => {
  const { fetchResource } = useCurriculumApi();
  const [urlIframeMain, setUrlIframeMain] = useState("");
  const [urlIframeNew, setUrlIframeNew] = useState({});
  const [currentUrl, setCurrentUrl] = useState(null);

  useEffect(() => {
    if (lessonResourcesData && lessonResourcesData?.length > 0) {
      setUrlIframeMain(lessonResourcesData);
    }
  }, [lessonResourcesData]);

  useEffect(() => {
    if (urlIframeMain) {
      if (currentUrl === null) {
        setCurrentUrl(urlIframeMain[0].id);
      } else {
        setCurrentUrl(urlIframeMain[0].id);
      }
    }
  }, [urlIframeMain]);
  // console.log("currentUrl :>> ", currentUrl);
  // console.log("currentUrl :>> ", currentUrl);
  // console.log("currentUrl :>> ", currentUrl);
  const {
    data: dataFrame,
    status: statusFrame,
    isLoading: isLoadingFrame,
    isError: isErrorFrame,
    error: errorFrame,
  } = useQuery(["viewResource", currentUrl], () => currentUrl && fetchResource(currentUrl), {
    enabled: currentUrl != "",
    refetchOnWindowFocus: false,
  });
  const urlFrame = statusFrame === "success" && dataFrame && dataFrame?.data?.url;

  // console.log("dataFrame && dataFrame :>> ", dataFrame && dataFrame.data);
  document.cookie =
    statusFrame == "success" &&
    `AuthToken=${dataFrame?.data?.jwt};max-age=604800;domain=educationai.co.uk;path=/`;
  document.cookie =
    statusFrame == "success" &&
    `sas="${dataFrame?.data?.sas}";max-age=604800;domain=educationai.co.uk;path=/`;
  if (urlFrame) {
    return (
      <>
        <div style={{ padding: "0px" }}>
          {urlIframeMain.length > 1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0px 0 0px",
                alignItems: "center",
              }}
            >
              {classModal ? (
                <h1 style={{ margin: "0", padding: "0", fontSize: "14px" }}>{exerciseName}</h1>
              ) : (
                <h1 style={{ margin: "0", padding: "0" }}>{exerciseName}</h1>
              )}
              <div style={{ display: "flex", alignItems: "center" }}>
                {urlIframeMain.map((item) => (
                  <ActivityPlayerTab
                    item={item}
                    currentUrl={currentUrl}
                    setCurrentUrl={setCurrentUrl}
                    classModal={classModal}
                  />
                ))}
                {classModal ? null : (
                  <div>
                    <button
                      style={{
                        padding: "3px ",
                        color: "white",
                        background: "rgba(0,0,0,0.3)",
                        cursor: "pointer",
                        border: "none",
                        borderRadius: "499px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // fontWeight: "500",
                        boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                        margin: "0 0 0 25px",
                      }}
                      onClick={handleClose}
                    >
                      <CloseRoundedIcon />
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              {classModal ? (
                <h1 style={{ margin: "0", padding: "0", fontSize: "1rem" }}>{exerciseName}</h1>
              ) : (
                <h1 style={{ margin: "0", padding: "0" }}>{exerciseName}</h1>
              )}
            </>
          )}
        </div>
        <div style={{ height: "calc(100% - 50px)" }}>
          <Iframe url={urlFrame} width="100%" height="100%" allow="fullscreen" borderRadius="5px" />
        </div>
      </>
    );
  } else if (isLoadingFrame || isLoadingResources) {
    return (
      <div>
        <LoadingScreen />
      </div>
    );
  } else {
    // handleClose();
    return (
      <div
        style={{
          width: "100%",
          margin: "auto auto",
          height: "100%",
        }}
      >
        <h1
          style={{
            margin: "7% auto 0",
            textAlign: "center",
            width: "80%",
            height: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0,0,0,0.04)",
            borderRadius: "12px",
          }}
        >
          No lesson available
        </h1>
      </div>
    );
  }
};

export default ActivityCurriculumLessonPlayer;
