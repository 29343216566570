import React, { useState } from "react";
// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import useStore from "../../../../zustand";
// import stockImg from '../assets/paint.png';
// import stockImg from '../assets/actstagemock.png';
// import Backdrop from "@mui/material/Backdrop";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import Fade from "@mui/material/Fade";
// import Button from "@mui/material/Button";
// import { Container } from "@material-ui/core";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import Iframe from "react-iframe";
import { LinearProgress } from "@mui/material";
// import useCurriculumApi from "api/curriculum";
import { Button, Fade } from "@material-ui/core";
// import Iframe from "react-iframe";
import H5P from "../H5P";
// import Iframe from "react-iframe";
// import { useQuery } from "react-query";
import useMediaQuery from "@mui/material/useMediaQuery";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
// import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 4,
  bgcolor: "background.paper",
  border: "none",
  // boxShadow: 24,
  // borderRadius:'8px',
  // boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  // boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
  p: 4,
  // width:'90vw',
};
// ----------------
const RecentActItem = ({
  name,
  image,
  level,
  exercise,
  maxQuestion,
  questionsAnswered,
  status,
  lesson,
  classAct,
  homepage,
  exerciseId,
  lessonId,
}) => {
  const store = useStore((state) => state);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const checkProgressBarColor = (percentageVal) => {
    let percentage = percentageVal > 100 ? 100 : percentageVal;
    if (percentage < 26) {
      return "#E4E7EB";
    } else if (percentage < 100) {
      return "#E4E7EB";
    } else {
      return "#E4E7EB";
    }
  };
  const checkProgressBarBgColor = (percentage) => {
    if (percentage < 10) {
      return `rgb(219, 64, 64)`;
    } else if (percentage < 100) {
      return `rgb(206, 171, 45)`;
    } else {
      return `rgb(45, 206, 136)`;
    }
  };
  const matches = useMediaQuery("(min-width:880px)");

  return (
    <Box
      // style={{
      // boxShadow: isHovering ? '0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22)' : '',}}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      // sx={{ padding: "12px 20px 5px" }}
      style={{
        background: "#fff",
        boxShadow: "0 5px 15px hsla(0, 0%, 0%, 0.2)",
        padding: "4px 4px",
        borderRadius: "8px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <CardContent
        style={{
          // display: "flex",
          // direction: "column",
          // justifyContent: "space-between",
          // alignItems: "center",
          // gap: "5px",
          padding: "8px 8px 8px 10px",
        }}
      >
        <Box>
          <Typography
            fontSize="16px"
            variant="h3"
            style={{
              color: "#323F4B",
              fontWeight: "500",
              marginBottom: "8px",
            }}
          >
            {name}
          </Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          // style={{ display: ` ${matches ? "block" : "none"}` }}
          value={
            questionsAnswered / maxQuestion >= 1 ? 100 : (questionsAnswered / maxQuestion) * 100
          }
          sx={{
            //   backgroundColor: "#2DCE89",
            width: "100%",
            backgroundColor: checkProgressBarColor((questionsAnswered / maxQuestion) * 100),
            "& .MuiLinearProgress-bar": {
              backgroundColor: checkProgressBarBgColor((questionsAnswered / maxQuestion) * 100),
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            direction: "row",
            justifyContent: "space-around",
            alignItems: "center",
            gap: "5px",
            // padding: "6px 1px",
            margin: "8px 0",
          }}
        >
          <Box
            style={{
              display: "flex",
              width: "12%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardMedia
              component="img"
              sx={{ width: "60px", borderRadius: "4px" }}
              // height='50px'
              image={image}
              alt=""
            />
          </Box>

          <Box sx={{ width: `40%` }}>
            <Box
              margin="8px 0 0"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                // opacity: "0",
              }}
            >
              <Typography
                variant="p"
                style={{
                  fontSize: "14px",
                }}
              >
                <AssignmentTurnedInIcon /> {status === "completed" ? "Completed" : "In progress"}
              </Typography>
              <Typography
                variant="p"
                style={{
                  color: "#6358A5",
                  fontSize: "14px",
                }}
              >
                <HourglassBottomIcon />
                {questionsAnswered / maxQuestion >= 1
                  ? "100"
                  : ((questionsAnswered / maxQuestion) * 100).toFixed(0)}
                %
              </Typography>
            </Box>
          </Box>
          <Link to={`activities/Activity/${exerciseId}/${lessonId}`}>
            <Button
              disabled={status === "completed"}
              style={{
                padding: "7px 12px",
                fontSize: "14px",
                color: "#fff",
                background: "#6358A5",
                borderRadius: "5px",
                border: "none",
                boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              }}
              // onClick={handleOpen}
            >
              {status === "completed" ? "Finished" : "Continue"}
            </Button>
          </Link>
          {/* <Button
            disabled={status === "completed"}
            style={{
              //   width: "165px",
              padding: "7px 16px",
              fontSize: "14px",
              color: "#fff",
              background: "#6358A5",
              borderRadius: "5px",
              border: "none",
              boxShadow:
                "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            }}
            onClick={handleOpen}
          >
            {status === "completed" ? "Finished" : "Continue"}
          </Button> */}
        </Box>

        {/* )} */}
      </CardContent>

      {/* Modal section */}
      {
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          // BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box
              sx={style}
              height="98vh"
              width="95vw"
              style={{
                position: "relative",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "8px solid #6358A5",
                borderRadius: "15px 40px 15px",
                //
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "40px",
                  right: "40px",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                <HighlightOffIcon
                  style={{
                    fontSize: "2.4rem",
                    fontWeight: "700",
                    cursor: "pointer",
                  }}
                />
              </div>
              <H5P
                lessonId={lesson === null ? null : lesson.id}
                exercise={exercise}
                classId={store.selectedClassId}
                classAct={classAct}
              />
            </Box>
          </Fade>
        </Modal>
      }
    </Box>
  );
};

export default RecentActItem;
