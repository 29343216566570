import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import Home from "@material-ui/icons/Home";

//Link
// import {NavLink} from 'react-router-dom'

// core components

import componentStyles from "assets/theme/components/headers/simple-header.js";

const useStyles = makeStyles(componentStyles);
const drawerWidth = 280;

const SimpleHeader = ({ section, subsection }) => {
  const classes = useStyles();

  // Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <div className={classes.header} style={{ padding: "0px 0px 20px" }}>
          <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
          >
            <Grid
              container
              component={Box}
              alignItems="center"
              paddingTop="1.5rem"
              paddingBottom="0rem"
            >
              <Grid item xs={7} lg={6} className={classes.gridItem}>
                <Typography
                  variant="h2"
                  component="h6"
                  className={clsx(
                    classes.displayInlineBlock,
                    classes.mb0,
                    classes.textWhite
                  )}
                >
                  {section}
                </Typography>
                <Breadcrumbs
                  separator="-"
                  aria-label="breadcrumb"
                  classes={{
                    root: classes.breadcrumbRoot,
                    li: classes.breadcrumbLi,
                    ol: classes.breadcrumbOl,
                    separator: classes.breadcrumbSeparator,
                  }}
                >
                  <Link
                    color="inherit"
                    to="/"
                  // onClick={(e) => e.preventDefault()}
                  >
                    <Box
                      component={Home}
                      width="1.25rem!important"
                      height="1.25rem!important"
                      position="relative"
                    />
                  </Link>
                  <Link
                    color="inherit"
                    href="/getting-started/installation/"
                    onClick={(e) => e.preventDefault()}
                  >
                    {subsection}
                  </Link>
                  <Typography
                    component="span"
                    className={classes.breadcrumbActive}
                  >
                    {section}
                  </Typography>
                </Breadcrumbs>
              </Grid>
              {/* <Grid item xs={5} lg={6} component={Box} textAlign="right">
              <NavLink to='/builder/build'>
                <Button
                  variant="contained"
                  size="small"
                  className={classes.buttonRoot}
                >
                    New
                </Button>
              </NavLink>
              <Button
                variant="contained"
                size="small"
                className={classes.buttonRoot}
                onClick={handleClick}
              >
                Filters
              </Button>
            </Grid> */}
            </Grid>
          </Container>
        </div>
      </Box>
    </>
  );
};

SimpleHeader.propTypes = {
  section: PropTypes.string,
  subsection: PropTypes.string,
};

export default SimpleHeader;
