import React from "react";
import ClassEditPanel from "./ClassEditPanel";
// import ClassControlPanel from "./ClassControlPanel";

const ClassPageHeader = ({ classData, classId }) => {
  return (
    <div
      style={
        {
          // margin: "40px 0",
        }
      }
    >
      <div
        style={{
          display: "flex",
          gap: "20px",
          justifyContent: "space-between",
        }}
      >
        <h2
          style={{
            // fontSize: "2rem",
            fontSize: "24px",
            fontWeight: "400",
          }}
        >
          <span
            style={{
              fontWeight: "600",
            }}
          >
            Class:{" "}
          </span>
          {classData?.name}
        </h2>
        <ClassEditPanel />
      </div>
    </div>
  );
};

export default ClassPageHeader;
