import React from "react";
// import clsx from "clsx";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Box } from "@material-ui/core";
// import MenuItem from "@material-ui/core/MenuItem";
import LevelInputDropDown from "./LevelInputDropDown";
// import uuid from "uuid";
import AnimateHeight from "react-animate-height";
import CustomLessonLevelItem from "./CustomLessonLevelItem";

const NewLevelInput = ({
  editedClassLevel,
  availableLevel,
  classes,
  customValidationSelect,
  setEditedClassLevel,
  setCustomValidationSelect,
  isEAICur,
  isSelected,
  curriculumSubjects,
  levelNamesFilter,
  closeSelection,
  setCloseSelection,
  selectedCurriculum,
  setSelectedCurriculum,
  levels,
  setLevels,
  levelNames,
  setLevelNames,
  levelNameCurriculum,
  setLevelNameCurriculum,
  curriculumValue,
}) => {
  // console.log("isEAICur :>> ", isEAICur);
  // console.log("curriculumSubjects :>> ", curriculumSubjects);
  // console.log("availableLevel :>> ", availableLevel);
  // console.log("availableLevel :>> ", availableLevel);
  // console.log("availableLevel :>> ", availableLevel);
  // console.log("levelNamesFilter :>> ", levelNamesFilter);
  // console.log("levelNameCurriculum :>> ", levelNameCurriculum);
  // console.log("levelNameCurriculum :>> ", levelNameCurriculum);
  // console.log("levels :>> ", levels);
  // console.log("levels :>> ", levels);
  // console.log("editedClassLevel :>> ", editedClassLevel);
  // console.log("editedClassLevel :>> ", editedClassLevel);
  return (
    <FormGroup>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: "10px",
          margin: "0px 0 14px",
          width: "100% ",
        }}
      >
        <FormLabel
          style={{
            fontSize: 16,
            color: "#52606D",
            fontFamily: "Montserrat,sans-serif",
            margin: 0,
            padding: 0,
          }}
        >
          Select Lesson Plan{selectedCurriculum && <>:</>}
        </FormLabel>
        <div
          style={{
            display: "flex",
            // flexDirection: "column",
            // alignItems: "end",
            justifyContent: "space-between",
            gap: "10px",
            margin: "0",
            width: "100% ",
          }}
        >
          {selectedCurriculum ? (
            <div
              style={{
                padding: "8px 8px 8px 12px",
                // border: "1px solid rgba(6, 101, 157,0.4)",
                borderRadius: "4px",
                border: closeSelection ? "1px solid rgb(6, 101, 157)" : "1px solid #CBD2D9",
                boxShadow: closeSelection ? "0 1px 4px  rgb(6, 101, 157)" : "0 1px 1px  #CBD2D9",
                cursor: "pointer",
                width: "70% ",
              }}
              onClick={() => setCloseSelection(true)}
            >
              {curriculumValue === "EAI Lesson Plan" ? (
                <>
                  {selectedCurriculum?.split("/")[0]?.replace("(online)", "")} -{" "}
                  {selectedCurriculum?.split("/")[2]?.replace("(online)", "")}
                </>
              ) : (
                <>{selectedCurriculum}</>
              )}
            </div>
          ) : (
            <div
              style={{
                padding: "8px 8x",
                // border: "1px solid rgba(6, 101, 157,0.4)",
                borderRadius: "4px",
                border: "1px solid #CBD2D9",
                width: "60% ",
                cursor: "pointer",
                color: "rgba(0,0,0,0.3)",
                display: "flex",
                alignItems: "center",
                paddingLeft: "12px",
              }}
              onClick={() => setCloseSelection(true)}
            >
              Curriculum level not chosen
            </div>
          )}
          <div
            style={{
              padding: "8px 22px",
              // border: "1px solid rgba(6, 101, 157,0.4)",
              borderRadius: "6px",
              boxShadow: closeSelection ? "0 1px 3px rgba(0,0,0,0.2)" : "0 1px 1px  #CBD2D9",
              // width: "30% ",
              background:
                (!closeSelection && selectedCurriculum) || !selectedCurriculum
                  ? "rgba(0,0,0,0.25)"
                  : " rgb(6, 101, 157)",
              color: "white",
              margin: 0,
              cursor:
                (!closeSelection && selectedCurriculum) || !selectedCurriculum
                  ? "not-allowed"
                  : "pointer",
              border: "0",
            }}
            disabled={!closeSelection && selectedCurriculum}
            onClick={() => {
              if (selectedCurriculum) {
                setCloseSelection(false);
              } else {
                return null;
              }
            }}
          >
            {!closeSelection && selectedCurriculum
              ? "Saved"
              : !selectedCurriculum
              ? "not selected"
              : "Save"}
            {/* {selectedCurriculum?.length == 0 ? "not selected" : "Save"} */}
          </div>
        </div>
      </div>
      <Box
        sx={{
          width: { xs: "260px", sm: "100%" },
        }}
      >
        <AnimateHeight
          id="example-panel"
          duration={800}
          easing="ease"
          height={closeSelection ? "auto" : 0} // see props documentation below
        >
          {curriculumValue === "EAI Lesson Plan" ? (
            <div
              style={{
                boxShadow: "0 1px 3px rgba(6, 101, 157,0.4)",
                borderRadius: "4px",
                padding: "4px 4px",
              }}
            >
              {levelNameCurriculum &&
                // closeSelection &&
                levelNameCurriculum?.map((item, key) => (
                  // item.author == null &&
                  <div style={{ padding: "8px 10px" }}>
                    <LevelInputDropDown
                      curriculumLevel={item}
                      setCustomValidationSelect={setCustomValidationSelect}
                      customValidationSelect={customValidationSelect}
                      levels={levels}
                      levelNamesFilter={levelNamesFilter}
                      setEditedClassLevel={setEditedClassLevel}
                      classes={classes}
                      editedClassLevel={editedClassLevel}
                      setSelectedCurriculum={setSelectedCurriculum}
                      setCloseSelection={setCloseSelection}
                      closeSelection={closeSelection}
                    />
                  </div>
                ))}
            </div>
          ) : (
            <>
              {availableLevel?.length > 0 ? (
                <div
                  style={{
                    // display: "grid",
                    // gridTemplateColumns: "1fr 1fr 1fr",
                    // gap: "12px",
                    padding: "16px",
                    boxShadow: "0 1px 3px rgba(6, 101, 157,0.4)",
                    borderRadius: "4px",
                    // padding: "4px 4px",
                    margin: "10px 0",
                    minHeight: "120px",
                  }}
                >
                  <p>Custom lesson plans:</p>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      gap: "12px",
                      // padding: "16px",
                    }}
                  >
                    {availableLevel?.map((levelPlan) => (
                      <CustomLessonLevelItem
                        levelPlan={levelPlan}
                        key={levelPlan.id}
                        setEditedClassLevel={setEditedClassLevel}
                        editedClassLevel={editedClassLevel}
                        setSelectedCurriculum={setSelectedCurriculum}
                        setCustomValidationSelect={setCustomValidationSelect}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // gridTemplateColumns: "1fr 1fr 1fr",
                    // gap: "12px",
                    padding: "16px",
                    boxShadow: "0 1px 3px rgba(6, 101, 157,0.4)",
                    borderRadius: "4px",
                    // padding: "4px 4px",
                    minHeight: "120px",
                    margin: "10px 0",
                  }}
                >
                  <h2 style={{ margin: 0, padding: 0 }}>No custom lessons</h2>
                </div>
              )}
            </>
          )}
        </AnimateHeight>
      </Box>
      {customValidationSelect === "valid" ? (
        <FormHelperText component={Box} style={{ color: "#2dce89" }}>
          Looks good!
        </FormHelperText>
      ) : (
        <FormHelperText component={Box} style={{ color: "#fb6340" }}>
          Curriculum level is required.
        </FormHelperText>
      )}
    </FormGroup>
  );
};

export default NewLevelInput;
