import useDataApi from "api/data";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
const LessonCompThinkScore = ({ classData, selectedLessonUUID }) => {
  const { classId } = useParams();
  const { fetchClassComputationalThinkingScores } = useDataApi();
  const {
    data: computationalScores,
    status: computationalScoresStatus,
    isLoading: computationalScoresLoading,
  } = useQuery(
    ["class_computational_scores", classId, selectedLessonUUID],
    () => fetchClassComputationalThinkingScores(classId, selectedLessonUUID),
    {
      refetchOnWindowFocus: false,
    }
  );
  const toolTipDef = (concept, description) => {
    // if (type === "Data Representation") {
    return `${concept}: ${description}.`;
    // } else if (type === "Algorithms") {
    //   return `Algorithms: ${description}`;
    // } else if (type === "Pattern Recognition") {
    //   return "Pattern Recognition: ${description}`;
    // } else if (type === "Abstraction") {
    //   return "Abstraction: ${description}`;
    // } else if (type === "Decomposition") {
    //   return "Decomposition: ${description}`;
    // }
  };
  if (computationalScores) {
    // console.log("computationalScores.data", computationalScores?.data);
    return (
      <div
        style={{
          // width: "360px",
          // display: "flex",
          // padding: "20px",
          // flexDirection: "column",
          // margin: "10px 0",
          // backgroundColor: "white",
          // // boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
          // border: "2px solid #05649D",
          padding: "16px 20px",
          width: "100%",
        }}
      >
        <div
          style={{
            boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
            background: "white",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          }}
        >
          <h3
            style={{
              fontSize: "16px",
              // margin: "0",
              // padding: "0 0 10px 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // color: "white",
              marginBottom: "10px",
              margin: "auto",
              padding: "14px 20px",
              textAlign: "center",
            }}
          >
            Computational Thinking Scores
          </h3>
          <hr
            style={{
              margin: "2px auto",
              width: "80%",
              border: " 0",
              height: " 1px",
              backgroundImage:
                " linear-gradient(to right, rgba(7, 99, 156, 0),rgba(7, 99, 156, 1),rgba(7, 99, 156, 0))",
            }}
          />
          <div
          // style={{
          //   width: "100%",
          //   display: "flex",
          //   flexWrap: "wrap",
          //   // justifyContent: "space-b",
          //   gap: "20px",
          // }}
          >
            <div
              style={{
                margin: "0px 0 0",
                padding: "30px 30px",
                border: "0px solid rgb(31, 110, 163)",
                // borderBottomLeftRadius: "8px",
                // borderBottomRightRadius: "8px",
                // justifyContent: "space-between",
                // boxShadow: "0 5px 15px hsla(0,0%, 0%, .2)",
                background: "rgb(255, 255, 255,0.5)",
              }}
            >
              <div
                style={{
                  boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                  borderRadius: "12px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    height: "320px",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    padding: "10px",
                    gap: "10px",
                  }}
                >
                  {computationalScores?.data.computational_scores?.map(
                    (item) =>
                      item.score != null && (
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          arrow
                          title={
                            <div
                              style={{
                                fontSize: "14px",
                                background: "white",
                                color: "rgba(0,0,0,0.8)",
                                borderRadius: "4px",
                                padding: "10px",
                                margin: "8px 0",
                              }}
                            >
                              <h4
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                {toolTipDef(item.name, item.description)}
                              </h4>
                            </div>
                          }
                        >
                          <div
                            style={{
                              background: "#E1EDFB",
                              padding: "4px 8px",
                              // borderRadius: "12px",
                              display: "flex",
                              // flexDirection: "column",
                              // minWidth: "260px",
                              justifyContent: "space-between",
                              alignItems: "center",
                              boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                            }}
                          >
                            <h3
                              style={{
                                fontSize: "12px",
                                margin: "0",
                                marginRight: "5px",
                              }}
                            >
                              {item.computational_thinking_concept}
                            </h3>
                            <h5 style={{ fontSize: "18px", margin: "0" }}>
                              {Math.round(item.score * 100)}%
                            </h5>
                          </div>
                        </Tooltip>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        padding: "16px 20px",
        width: "100%",
      }}
    >
      <div
        style={{
          boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
          background: "white",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <h3
          style={{
            fontSize: "16px",
            // margin: "0",
            // padding: "0 0 10px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // color: "white",
            marginBottom: "10px",
            margin: "auto",
            padding: "14px 20px",
            textAlign: "center",
          }}
        >
          Computational Thinking Scores
        </h3>
        <hr
          style={{
            margin: "2px auto",
            width: "80%",
            border: " 0",
            height: " 1px",
            backgroundImage:
              " linear-gradient(to right, rgba(7, 99, 156, 0),rgba(7, 99, 156, 1),rgba(7, 99, 156, 0))",
          }}
        />
        <div
        // style={{
        //   width: "100%",
        //   display: "flex",
        //   flexWrap: "wrap",
        //   // justifyContent: "space-b",
        //   gap: "20px",
        // }}
        >
          <div
            style={{
              margin: "0px 0 0",
              padding: "30px 30px",
              border: "0px solid rgb(31, 110, 163)",
              // borderBottomLeftRadius: "8px",
              // borderBottomRightRadius: "8px",
              // justifyContent: "space-between",
              // boxShadow: "0 5px 15px hsla(0,0%, 0%, .2)",
              background: "rgb(255, 255, 255,0.5)",
            }}
          >
            <div
              style={{
                boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                borderRadius: "12px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  height: "320px",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  padding: "10px",
                  gap: "10px",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonCompThinkScore;
