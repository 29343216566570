import React from "react";
import ClassCardList from "./ClassCardList";
// import CardListSkeleton from "./skeletons/CardListSkeleton";

const ClassesListComponent = ({ data, searchWord }) => {
  const filterList = (item, array) => {
    return searchWord.length > 0
      ? item.name &&
          item.name.toLowerCase().indexOf(searchWord.toLowerCase()) > -1
      : array;
  };
  return (
    <div>
      <div
        style={{
          margin: "20px 0",
        }}
      >
        {/* Show Skeleton for testing - by uncommenting -- will need to uncomment import */}
        {/* <CardListSkeleton/> */}
        {data &&
          data
            .sort(
              (a, b) =>
                a.name !== null &&
                b.name !== null &&
                a.name.localeCompare(b.name)
            )
            .filter((item, key, array) => filterList(item, array))
            .map((classItem) => (
              <>
                <ClassCardList key={classItem.id} classItem={classItem} />
              </>
            ))}
      </div>
    </div>
  );
};

export default ClassesListComponent;
