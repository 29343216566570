import React from "react";
import useCurriculumApi from "api/curriculum";
import { useQuery } from "react-query";
import "./CurriculumStyle.css";
import { Box } from "@material-ui/core";
// import Avatar from '@mui/material/Avatar';
import InfoPage from "./InfoPage";
import CurLessons from "./CurLessons";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect } from "react";
import usePersistStore from "../../../../zustandPersist";
import ErrorPage from "../ErrorPage";
import { Skeleton } from "@mui/material";
import { useLocation } from "react-router-dom";

function CurColumnCustomLesson({
  data,
  status,
  isLoading,
  inputRef,
  isSuccess,
  lessonType,
  level,
  levelId,
  searchWord,
  componentHeight,
  lessonIdFromLink,
}) {
  const { fetchResource } = useCurriculumApi();
  const { permission } = usePersistStore();
  const [content, setContent] = React.useState(null);
  const [fileItem, setFileItem] = React.useState(null);
  const [test, setTest] = React.useState(false);
  const [iframeId, setIframId] = React.useState(null);
  const [hide, setHide] = React.useState(true);
  const location = useLocation();

  // fetch iFrame URL
  const {
    data: dataFrame,
    status: statusFrame,
    isLoading: isLoadingFrame,
    isError: isErrorFrame,
    error: errorFrame,
  } = useQuery(["viewResource", iframeId], () => iframeId && fetchResource(iframeId), {
    refetchOnWindowFocus: false,
  });

  const urlFrame = statusFrame == "success" && dataFrame?.data?.url;

  // set lesson - online
  React.useEffect(() => {
    lessonIdFromLink
      ? setContent(data?.lessons?.filter((item) => item?.id === lessonIdFromLink)[0])
      : setContent(data?.lessons[0]);
  }, [lessonIdFromLink, levelId, level, location.pathname, location, isLoading]);

  // Set file inside lesson
  React.useEffect(() => {
    lessonType !== "offline"
      ? content?.files?.length > 0 && setFileItem(content?.files[0])
      : setFileItem(content);
  }, [content, lessonType]);

  // Set iFrame id to get URL
  React.useEffect(() => {
    lessonType !== "offline" && content?.files?.length > 0 && setIframId(content?.files[0]?.id);
  }, [lessonType, content, level, levelId]);

  // Scroll to selected lesson when the user comes from class
  useEffect(() => {
    isSuccess && lessonIdFromLink && inputRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [lessonIdFromLink, isSuccess]);

  document.cookie =
    statusFrame == "success" &&
    `AuthToken=${dataFrame?.data?.jwt};max-age=604800;domain=educationai.co.uk;path=/`;
  document.cookie =
    statusFrame == "success" &&
    `sas="${dataFrame?.data?.sas}";max-age=604800;domain=educationai.co.uk;path=/`;

  // Search bar functionality
  const filterList = (item, array) => {
    return searchWord?.length > 0
      ? item.name &&
          (item?.name?.toLowerCase().indexOf(searchWord.toLowerCase()) > -1 ||
            item.email?.toLowerCase().indexOf(searchWord.toLowerCase()) > -1)
      : array;
  };

  return (
    <>
      <Box
        container
        style={{
          margin: "0px auto",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "",
        }}
      >
        <div
          className="sidebar"
          onClick={() => setHide(true)}
          style={{
            zIndex: 9999,
            visibility: !hide ? "visible" : "hidden",
            cursor: "pointer",
            position: "absolute",
            top: 80,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            padding: "5px 10px ",
            border: "1px solid rgb(6, 101, 157)",
            background: "rgb(6, 101, 157)",
            color: "white",
            fontWeight: 500,
          }}
        >
          Content <ArrowForwardIcon />
        </div>
        <Box
          style={{
            marginTop: "0px",
            color: "#323F4B",
            padding: 0,
            maxWidth: 320,
            width: hide ? "320px" : 0,
            transition: "width 0.5s ease-in-out",
          }}
        >
          <Box
            style={{
              overflowY: "scroll",
              height: `calc(100vh - 73px - ${componentHeight}px)`,
              width: 320,
              boxShadow: "rgb(0 0 0 / 20%) 0px 5px 15px",
              background: "white",
              borderRadius: 4,
              padding: 5,
              margin: "0px 10px",
              left: 500,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h5 style={{ padding: "10px 10px 0px", fontSize: 17 }}>
                {lessonType !== "offline" ? "Lessons" : "Book"}{" "}
              </h5>
              <h5 style={{ padding: "10px 10px 0px", cursor: "pointer" }} onClick={() => setHide(false)}>
                <ArrowBackIcon />
              </h5>
            </div>
            {status === "loading"
              ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item) => (
                  <Skeleton count={1} width={290} height={87} style={{ margin: "auto" }} />
                ))
              : data?.lessons
                  ?.filter((item, key, array) => filterList(item, array))
                  ?.map((item, index) => (
                    <CurLessons
                      index={index}
                      permission={permission}
                      item={item}
                      fileItem={fileItem}
                      setContent={setContent}
                      content={content}
                      setTest={setTest}
                      setFileItem={setFileItem}
                      setIframId={setIframId}
                      test={test}
                      inputRef={inputRef}
                    />
                  ))}
          </Box>
        </Box>
        <Box
          style={{
            background: "white",
            padding: 0,
            width: "100%",
            zIndex: 999,
            margin: !hide ? "auto 10px auto 10px" : "auto 10px auto 20px",
            boxShadow: "rgb(0 0 0 / 20%) 0px 5px 15px",
            borderRadius: 4,
            height: `calc(100vh - 73px - ${componentHeight}px)`,
            overflow: "hidden",
          }}
        >
          {true ? (
            <InfoPage
              iframeURL={urlFrame}
              content={content}
              lessonType={lessonType}
              test={test}
              height={`calc(100vh - 83px - ${componentHeight}px)`}
              isLoading={isLoadingFrame}
              isError={isErrorFrame}
              error={errorFrame}
            />
          ) : (
            <ErrorPage />
          )}
        </Box>
      </Box>
    </>
  );
}

export default CurColumnCustomLesson;
