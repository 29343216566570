import React from "react";
// import ActivityCurriculumLessonPlayer from "views/eai/activities/ActivityCurriculumLessonPlayer";
import ClassCurriculumLessonPlayer from "./ClassCurriculumLessonPlayer";

const ClassCurriculumPlayer = ({ lessonResourcesData, lessonName }) => {
  // if (lessonId) {
  return (
    <div
      style={{
        width: "98%",
        height: "68vh",
        margin: "4px auto ",
        background: "white",
        padding: "10px",
        boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {lessonResourcesData && (
          <ClassCurriculumLessonPlayer
            lessonResourcesData={lessonResourcesData}
            lessonName={lessonName}
          />
        )}
      </div>
    </div>
  );
  // } else {
  //   return null;
  // }
};

export default ClassCurriculumPlayer;
