import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

export function getPercent(score) {
  const percent = Math.round(score * 100);
  return `${percent}%`;
}

export function getDateTime(timestamp) {
  if (timestamp === null) {
    return "n/a";
  }
  const dateObject = dayjs(timestamp);
  return dateObject.format("DD/MM/YYYY H:mmA");
}

export function getDuration(millis) {
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  return dayjs.duration(millis, "milliseconds").humanize();
}
