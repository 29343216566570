import { Box, CircularProgress } from "@mui/material";

export const LoadingScreen = () => {
  return (
    <Box
      style={{
        overflow: "hidden",
        paddingTop: "140px",
        width: "100%",
        textAlign: "center",
        background: 'transparent'
      }}
    >
      <CircularProgress size={40} />
    </Box>
  );
};
