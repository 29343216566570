import React from "react";
import iconSquare from "./squares.png";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import CurriculumCard from "components/Cards/eai/CurriculumCard";
import { CurriculumSection } from "./Components/CurriculumSection";
// import { LoadingScreen } from "./Components/LoadingScreen";
import { ErrorScreen } from "./Components/ErrorScreen";
import { CurriculumPage } from "./CurriculumPage";
import { Link } from "react-router-dom";
import useCurriculumApi from "api/curriculum";
import CurColumn from "./ColumnPage/CurColumn";
import useStore from "../../../zustand";
import usePersistStore from "../../../zustandPersist.js";
import { Skeleton } from "@mui/material";
// import { LoadingScreen } from "./Components/LoadingScreen";

function CoursesPage() {
  const { view } = useStore();
  const { permission } = usePersistStore();
  const params = useParams();
  const { fetchCourses } = useCurriculumApi();

  const { isLoading, isError, data, error, status } = useQuery(
    ["courses", params.categoryId],
    () => fetchCourses(params.categoryId),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isError) {
    return <ErrorScreen error={error} />;
  }

  if (status === "loading") {
    return (
      <div style={{ width: "100vw", maxHeight: "100vh" }}>
        <div style={{ width: "100%" }}>
          <Skeleton
            count={1}
            height={80}
            style={{ margin: "0px 20px", flex: 1 }}
          />
        </div>
        <div style={{ display: "flex", height: "calc(100vh - 150px )" }}>
          <div
            style={{
              margin: "5px 10px 5px 20px",
              animation: "animation-c7515d 1.5s ease-in-out 0.5s infinite",
              background: "rgba(0, 0, 0, 0.11)",
              borderRadius: "4px/6.7px",
              transformOrigin: "0 55%",
              width: "300px",
            }}
          ></div>
          <div
            style={{
              margin: "5px 20px 5px 10px",
              animation: "animation-c7515d 1.5s ease-in-out 0.5s infinite",
              background: "rgba(0, 0, 0, 0.11)",
              borderRadius: "4px/6.7px",
              transformOrigin: "0 55%",
              flex: 1,
              minWidth: "400px",
            }}
          ></div>
        </div>
      </div>
    );
  }

  const sectionsMap = {};
  if (data?.data) {
    for (const course of data?.data) {
      const cards = [];
      for (const level of course?.levels) {
        const levelItems = level.type == "offline" ? "resources" : "lessons";
        const courseType =
          level.type == "online" ? "Screen Based" : "Unplugged";
        const courseName = `${course.name} (${courseType})`;
        const card = (
          <CurriculumCard
            title={level.name}
            type={level.type}
            subtitle={course.name}
            image={level.image}
            levelId={level?.id}
            arrayTitle="Lessons:"
            blur="0"
            link={`/curriculum/levels/${level?.id}/${levelItems}`}
          />
        );
        const cardBlur = (
          <CurriculumCard
            title={level.name}
            subtitle={course.name}
            image={level.image}
            blur="blur(5px)" // Change this to blur
            link={`/curriculum/levels/${level?.id}/${levelItems}`} // disable the link
          />
        );

        !(courseName in sectionsMap) &&
          (sectionsMap[courseName] = {
            type: level.type,
            id: course?.id,
            cards: [],
          });

        if (true) {
          sectionsMap[courseName].cards.push(card);
        } else {
          sectionsMap[courseName].cards.push(cardBlur);
        }
      }
    }
  }

  const sections = [];
  for (const [courseName, course] of Object.entries(sectionsMap)) {
    const actionButton = (
      <Link to={`/curriculum/courses/${course?.id}/${course.type}/levels`}>
        <div
          style={{
            padding: "0px",
            background: "#7Fcdee",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          <img
            src={iconSquare}
            alt="..."
            style={{ width: "23px", height: "23px" }}
          />
        </div>
      </Link>
    );
    sections.push(
      <CurriculumSection
        key={courseName}
        name={courseName}
        cards={course.cards}
        actionButton={actionButton}
      />
    );
  }

  return (
    <>
      {view ? (
        <CurriculumPage name="Courses" sections={sections} view={view} />
      ) : (
        <CurColumn
          paramId={params.categoryId}
          data={data?.data}
          isLoading={isLoading}
          view={view}
        />
      )}
    </>
  );
}

export default CoursesPage;
