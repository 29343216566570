import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import useCurriculumApi from "api/curriculum";
// import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
// import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
// import SaveIcon from "@mui/icons-material/Save";
// import DescriptionIcon from "@mui/icons-material/Description";
// import { Tooltip } from "@mui/material";
// import AssignmentIcon from "@mui/icons-material/Assignment";
// import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { styled } from "@mui/material/styles";
// import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useQueryClient } from "react-query";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
// import Typography from '@mui/material/Typography';
// import FactCheckIcon from "@mui/icons-material/FactCheck";
// import CheckIcon from "@mui/icons-material/Check";
// import NoteAltIcon from "@mui/icons-material/NoteAlt";
// import BorderColorIcon from "@mui/icons-material/BorderColor";
// import EventNoteIcon from "@mui/icons-material/EventNote";
// import EventAvailableIcon from "@mui/icons-material/EventAvailable";
// import ArticleIcon from "@mui/icons-material/Article";
// import TaskIcon from "@mui/icons-material/Task";
import HomeWorkDone from "../assets/ActivitiesDone.svg";
import HomeWork from "../assets/Activities.svg";
// import LessonTeach from "../assets/Lessons.svg";
// import LessonTeachDone from "../assets/LessonsDone.svg";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import MenuBookIcon from "@mui/icons-material/MenuBook";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));

const LessonTab2 = ({
  lesson,
  cLesson,
  lessonName,
  setClesson,
  number,
  setSelectedLesson,
  lessonUuid,
  lessId,
  classData,
  selectedLesson,
  setLessonResourcesData,
  actualLessonData,
  classId,
  lessonId,
  hasBeenAssigned,
  mutate,
  mutatePatch,
  saveData,
  setOpenSnackbarQuiz,
}) => {
  const {
    fetchLevelResources,
    fetchLessonResources,
    fetchMarkLessonComplete,
    fetchCheckLessonComplete,
    setOpenSnackbar,
  } = useCurriculumApi();
  const [clickedTab, setClickedTab] = useState(false);
  const [actualLessonIndex, setActualLessonIndex] = useState(1);
  const queryClient = useQueryClient();
  const [isComplete, setIsComplete] = useState(null);

  const { isLoading, isError, data, error } = useQuery(
    ["resourcesPlayer", cLesson[0]?.id],
    () => {
      return fetchLessonResources(cLesson[0]?.id);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  //
  const { isLoading: isCompletedLoading, data: isCompletedData } = useQuery(
    ["fetchCheckLessonComplete", lessonId, classId],
    () => {
      return fetchCheckLessonComplete(lessonId, classId);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  //
  // const {
  //   isLoading: markCompletedIsLoading,
  //   data: markCompletedData,
  //   refetch: markCompleted,
  // } = useQuery(
  //   ["fetchMarkLessonComplete", lessonId, classId],
  //   () => {
  //     return fetchMarkLessonComplete(lessonId, classId, isComplete);
  //   },
  //   {
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(["fetchCheckLessonComplete", lessonId, classId]);
  //     },
  //   }
  // );
  useEffect(() => {
    if (isCompletedData) {
      setIsComplete(!isCompletedData.data.lesson_complete);
    }
  }, [isCompletedData]);

  React.useEffect(() => {
    setLessonResourcesData(
      data &&
        data?.data.filter(
          (item) =>
            item.type.name.toLowerCase() === "presentation" ||
            item.type.name.toLowerCase() === "video"
        )
    );
  }, [data]);
  React.useEffect(() => {
    if (cLesson?.length > 0 && actualLessonData?.length > 0) {
      setActualLessonIndex(
        actualLessonData?.findIndex((element) => element.id === classData.current_lesson)
      );
      console.log("actualLessonData", actualLessonData);
      console.log("cLesson", cLesson && cLesson);
    }
  }, [cLesson]);

  // console.log(
  //   "actualLessonData?.findIndex(cLesson)",
  //   actualLessonData?.findIndex(cLesson)
  // );

  const handleClick = () => {
    setLessonResourcesData(
      data &&
        data?.files?.filter(
          (item) =>
            item.type.toLowerCase() === "presentation" || item.type.toLowerCase() === "video"
        )
    );
    setClickedTab((prev) => !prev);
    setSelectedLesson(lessonUuid);
    setClesson([lesson]);
  };

  // const [loading, setLoading] = React.useState(false);
  // const [success, setSuccess] = React.useState(false);
  // const timer = React.useRef();

  // const buttonSx = {
  //   // bgcolor: "#ffffff",
  //   // color: "#05649D",
  //   "&:hover": {
  //     // bgcolor: "#a6cde3",
  //     color: "#ffffff",
  //     bgcolor: "#f6fbff",
  //     border: "2px solid #06659D",
  //   },
  // };

  // const handleButtonClick = () => {
  //   markCompleted();
  //   console.log("markCompleted() ========  called!!!!!");
  // };
  React.useEffect(() => {
    return () => {
      // clearTimeout(timer.current);
    };
  }, []);

  return (
    <div
      onClick={(e) => {
        // e.stopPropagation();
        handleClick();
      }}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 8px",
        // width: "100%",
        width: "260px",
        borderRadius: "6px",
        cursor: "pointer",
        // border: selectedLesson === lessId ? "solid 3px #085d82" : "solid 3px rgba(0,0,0,0)",

        boxShadow:
          selectedLesson === lessId
            ? "0 2px 6px hsla(0,0%, 0%, .2)"
            : "0 1px 3px hsla(0,0%, 0%, .2)",
        // classData.current_lesson === lessId
        //   ? "0 1px 3px hsla(0,0%, 0%, .2)"
        //   : lessonUuid === cLesson[0]?.id
        //   ? "0 1px 3px hsla(0,0%, 0%, .2)"
        //   : "none",

        backgroundColor:
          selectedLesson === lessId
            ? "rgb(7, 99, 156)"
            : // : hasBeenAssigned
              // ? "#05649D"
              // classData.current_lesson === lessId
              // ? "rgba(0,0,0,0)"
              "rgba(0,0,0,0)",
        opacity:
          lessonUuid === cLesson[0]?.id ? "1" : classData.current_lesson === lessId ? "1" : "0.9",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <p
          style={{
            width: "30px",
            minWidth: "30px",
            height: "30px",
            // padding: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0",
            marginRight: "14px",
            boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
            borderRadius: "4px",
            // color: "white",
            fontSize: "12px",
            // background: hasBeenAssigned ? "#021a07" : "#05649D",
            border: selectedLesson === lessId ? "2px solid #ffffff" : "2px solid #05649D",

            // background: hasBeenAssigned ? "#05649D" : "#f1f3f5",
            // color: hasBeenAssigned ? "#ffffff" : "#3D3E67",
            // background: "#f1f3f5",
            // color: "#3D3E67",
            fontWeight: "600",
            position: "relative",
            color:
              lessonUuid === cLesson[0]?.id
                ? "white"
                : classData.current_lesson === lessId
                ? "#32325D"
                : "#32325D",
          }}
        >
          {isCompletedLoading ? (
            <div
              style={{
                margin: "0",
                padding: "0",
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                style={{
                  width: "16px",
                  height: "16px",
                  color: selectedLesson === lessId ? "#ffffff" : "#05639d",
                  // fontSize: "20px",
                }}
              />
            </div>
          ) : (
            number + 1
          )}

          {isCompletedData && isCompletedData.data.lesson_complete && (
            <CheckCircleIcon
              style={{
                color: "#0ca323",
                position: "absolute",
                fontSize: "20px",
                top: "0",
                right: "0",
                margin: "0",
                padding: "0",
                transform: "translate(50%,-50%)",
                background: "white",
                boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                borderRadius: "50px",
              }}
            />
          )}
        </p>
        <h6
          style={{
            margin: "0",
            fontSize: "12px",
            // color: hasBeenAssigned ? "#ffffff" : "#3D3E67",
            color:
              lessonUuid === cLesson[0]?.id
                ? "white"
                : classData.current_lesson === lessId
                ? "#32325D"
                : "#32325D",
          }}
        >
          {lessonName.replace("Stage 1 -", "")}
        </h6>
      </div>
      <div
        style={{
          display: "flex",
          margin: "0",
          borderRadius: "8px",
          gap: "5px",
          padding: "4px",
          // boxShadow: "0 2px 2px hsla(0,0%, 0%, .2)",
          cursor: "default",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "38px",
            height: "38px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0",
            cursor: "default",
            padding: "0",
          }}
        >
          <LightTooltip title={hasBeenAssigned ? "Homework Assigned" : "Homework Not Assigned"}>
            <Fab
              aria-label="save"
              color="primary"
              style={{
                width: "38px",
                height: "38px",
                margin: "0",
                padding: "0",
                borderRadius: "6px",
                backgroundColor: "rgba(255, 255, 255, 1)",
                cursor: "default",
                boxShadow: "none",
              }}
            >
              {hasBeenAssigned ? (
                <img src={HomeWorkDone} alt="" srcset="" style={{ width: "24px" }} />
              ) : (
                <img src={HomeWork} alt="" srcset="" style={{ width: "24px", opacity: "0.5" }} />
              )}
            </Fab>
          </LightTooltip>
        </div>
      </div>
    </div>
  );
};

export default LessonTab2;
