import React, { useState } from "react";
import { useLocation, Switch, Redirect, Route } from "react-router-dom";
import useUserApi from "../api/user";
// import '../views/eai/datasite/components/EditAddClass/style.css'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// @material-ui/icons components
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import componentStyles from "assets/theme/layouts/admin.js";
import { useQuery } from "react-query";
import useStore from "../zustand";
// =======importing the activities page
import getActivitiesRoutes from "routes/activities";
// import { WorkspaceList } from "views/eai/activities/ide-portal/components";
// import Ide from "views/eai/activities/ide-portal/che/ide";
// import { CircularLoadingScreen } from "views/eai/activities/components/Loading";
import { LoadingScreen } from "views/eai/curriculum/Components/LoadingScreen";
// import EmptyState from "components/EmptyState/EmptyState";
// import HelloWorldComponent from "views/eai/activities/HelloWorldComponent";
// import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ActivityPlayerPage from "views/eai/activities/ActivityPlayerPage";
import ActivityCurriculumPlayer from "views/eai/activities/ActivityCurriculumPlayer";
import ActivityFebTestingPage from "views/eai/activities/ActivityFebTestingPage";
import BlocksPage from "views/eai/activities/BlocksPage";
import GameIndividualPage from "views/eai/activities/GameIndividualPage";
import BlocksPageExercise from "views/eai/activities/BlocksPageExercise";
import SidebarNew from "views/eai/studentpage/SidebarNew";
// import H5PPlayerPage from "views/eai/activities/H5PPlayerPage";

const useStyles = makeStyles(componentStyles);
const usersAccess = [
  "ashraf",
  "0b46e9d6-9c97-4f77-b8b2-e830af947c4c",
  "Wing Yan Wong",
  "8c3285d2-3c1f-48bc-8586-610789e84733",
  "aric",
  "6fcb57b9-bd55-472d-b08c-fd29c5ea7e62",
  "jiajia",
  "4fb24925-64f9-488b-ba32-424a88393278",
  "keith",
  "d8032847-fdab-4d9d-bad2-8bf8b240ed91",
  "Nekruz",
  "79658498-8517-4336-beb7-75268bc63319",
  "whitney",
  "ad56cfa8-8b3d-4c67-9db1-ba008c68b8e4",
];
const Activities = () => {
  const classes = useStyles();
  const location = useLocation();
  const [sidebarOpenResponsive, setSidebarOpenResponsive] = React.useState(false);
  const store = useStore((state) => state);
  const [changeSidebar, setChangeSidebar] = useState(true);
  const { fetchClasses } = useUserApi();
  const { data, status, isLoading, isError, error } = useQuery("classes", () => fetchClasses(), {
    refetchOnWindowFocus: false,
  });

  const { userId, authToken, sidebarView } = useStore();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);
  React.useEffect(() => {
    if (data) store.setSelectedClassId(data?.data[0]?.id);
  }, [data]);
  const getRoutes = (routesInput) => {
    const prefix = "/activities";
    const ideRoutes = [
      {
        path: "/blocks/codingblocks",
        component: BlocksPage,
      },

      {
        path: "/blocks/codingblocks/:exerciseId?/:lessonId?/:exerciseName?",
        component: BlocksPageExercise,
      },
      {
        path: "/games/game",
        component: GameIndividualPage,
      },
      {
        path: "/Activity/:exerciseId?/:lessonId?",
        component: ActivityPlayerPage,
      },

      {
        path: "/Curriculum/:exerciseName?/:stageNumber?",
        component: ActivityCurriculumPlayer,
      },
      {
        path: "/h5pComp/testing",
        component: ActivityFebTestingPage,
      },
    ];

    return ideRoutes
      .concat(routesInput)
      .map((route) => (
        <Route exact path={prefix + route.path} component={route.component} key={route.path} />
      ));
  };

  let routes = getActivitiesRoutes(usersAccess.includes(userId));
  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        {status === "success" && data.data.length > 0 && (
          <>
            {location.pathname.includes("/studentPage") ||
            location.pathname.includes("/ActivityPage") ? (
              <SidebarNew routes={routes} />
            ) : (
              <Sidebar
                routes={routes}
                openResponsive={sidebarOpenResponsive}
                closeSidebarResponsive={() => setSidebarOpenResponsive(false)}
                logo={{
                  innerLink: "/index",
                  imgSrc: require("../assets/img/brand/eai.png").default,
                  imgAlt: "...",
                }}
              />
            )}
          </>
        )}

        <Box position="relative" flex="1" className={classes.mainContent}>
          <AdminNavbar
            openSidebarResponsive={() => setSidebarOpenResponsive(true)}
            logo={{
              innerLink: "/activities/",
              imgSrc: require("../assets/img/EducationaiLogos/Group 1540.svg").default,
              imgSrc2: require("../assets/img/EducationaiLogos/Group 1539.svg").default,
              imgAlt: "...",
              className: "first-element",
            }}
            dropdown={[
              <ClassPicker
                data={data}
                status={status}
                isLoading={isLoading}
                isError={isError}
                error={error}
              />,
            ]}
          />

          <div
            style={{
              marginTop:
                location.pathname.includes("/studentPage") ||
                location.pathname.includes("/ActivityPage")
                  ? 10
                  : 100,
            }}
          >
            {status == "success" && data.data.length > 0 ? (
              <Switch>
                {getRoutes(routes)}
                <Redirect from="*" to="/activities/" />
              </Switch>
            ) : null}
          </div>

          <Container maxWidth={false} component={Box} classes={{ root: classes.containerRoot }}>
            <AdminFooter />
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Activities;

function ClassPicker({ data, status, isLoading, isError, error }) {
  const store = useStore((state) => state);
  const [classNavbarId, setClassNavbarId] = React.useState(null);
  const { selectedClassId } = useStore();

  React.useEffect(() => {
    console.log("SS: ", status);
    status == "success" && data.data.length > 0
      ? setClassNavbarId(data.data[0].id)
      : setClassNavbarId(null);
  }, [status]);

  React.useEffect(() => {
    store.setSelectedClassId(classNavbarId);
  }, [classNavbarId]);

  if (isLoading) return <LoadingScreen />;
  if (error) return <h1>error....{error}</h1>;
  if (isError) return <h1>isError....</h1>;

  return (
    <>
      <FormControl
        sx={{
          m: 1,
          minWidth: { xs: 60, sm: 190 },
          maxWidth: { xs: 80, sm: 300 },
        }}
        variant="standard"
        size="small"
      >
        <Select
          value={classNavbarId}
          onChange={(e) => setClassNavbarId(e.target.value)}
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          style={{ color: "#fff" }}
          input={<BootstrapInput />}
        >
          <MenuItem disabled value="">
            <em>Choose a Class</em>
          </MenuItem>
          {status == "success" &&
            data.data.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)}
        </Select>
      </FormControl>
    </>
  );
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    color: "white",
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "transparent",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      // borderColor: 'white',
      // boxShadow: '0 0 0 0.1rem white',
    },
  },
}));
