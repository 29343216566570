import React, { useEffect } from "react";
// @material-ui/core components
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
// @material-ui/icons components
import Clear from "@material-ui/icons/Clear";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Box } from "@material-ui/core";

// core components
import componentStyles from "assets/theme/components/dialog.js";
import FormGroup from "@material-ui/core/FormGroup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormLabel from "@material-ui/core/FormLabel";
import EditIcon from '@mui/icons-material/Edit';
import { useQueryClient } from "react-query";
import componentStylesError from "assets/theme/components/snackbar.js";
import useStore from '../../../../../zustand';


const useStyles = makeStyles(componentStyles);
const useStylesError = makeStyles(componentStylesError);


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function EditProfile({ title, data, name, lastName, email, id, setOpenSnackbar, isSuccess, isError, error, isLoading, mutate }) {
    const classes = { ...useStyles(), ...useStylesError() };
    const tenantId = useStore((state) => state.tenantId);
    const [firstName, setFirstName] = React.useState(null);
    const [surname, setSurname] = React.useState(null);
    const [newEmail, setNewEmail] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const location = useLocation()
    const queryClient = useQueryClient();

    const handleClickOpen = () => {
        setOpen(true);
    };


    // Update the table data after editing a student
    React.useEffect(() => {
        if (isSuccess) {
            queryClient.invalidateQueries();
        }
    }, [isSuccess]);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        firstName !== name && setFirstName(name)
        surname !== lastName && setSurname(lastName)
        newEmail !== email && setNewEmail(email)
    }, [name, lastName, email])

    // Validation 

    const [isValid, setIsValid] = React.useState(true)
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('')
    const [errorMessage, setErrorMessage] = React.useState('')

    // Custom validation
    const [customValidationName, setCustomValidationName] = React.useState(null)
    const [customValidationSurname, setCustomValidationSurname] = React.useState(null)
    const [customValidationEmail, setCustomValidationEmail] = React.useState(null)

    const validateEmail = (email) => {
        const regex = /^(([^<>()[\]\.,;\+:\s@\"]+(\.[^<>()[\]\.,;\+:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

        if (email === '') {
            setCustomValidationEmail('required')
            setEmailErrorMessage('Email is required.')
        } else if (!email || regex.test(email) === false) {
            setCustomValidationEmail('required')
            setEmailErrorMessage('Invalid email type.')
        } else {
            setCustomValidationEmail('valid')
        };
    }

    const errorArr = isError && error.response.data.errors

    useEffect(() => {
        if (errorArr.message === "Duplicate Email") {
            setEmailErrorMessage('Duplicate email')
            setErrorMessage('Duplicate email')
            setCustomValidationEmail('required')
        } else if (errorArr.message !== "Duplicate Email") {
            setErrorMessage('Something went wrong!')
        }
    }, [isError])

    useEffect(() => {
        if (data && name === '') {
            setCustomValidationName('required')
        } else {
            setCustomValidationName('valid')
        }

        if (data && lastName === '') {
            setCustomValidationSurname('required')
        } else {
            setCustomValidationSurname('valid')
        }

        if (data) {
            validateEmail(email)
        }
    }, [data])


    useEffect(() => {
        setIsValid(customValidationName == "valid" && customValidationSurname == "valid" && customValidationEmail == "valid")
    }, [customValidationName, customValidationSurname, customValidationEmail])

    return (
        <>
            <button
                variant='contained'
                onClick={handleClickOpen}
                style={{
                    background: '#05649D',
                    color: 'white',
                    margin: "0px 12px",
                    padding: "7px 10px",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "14px",
                    borderRadius: "6px",
                    fontWeight: "500",
                    boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                    width: 80,
                    height: 35,
                    display: 'flex',
                    justifyContent: 'space-around',
                }}
            >
                <EditIcon style={{ color: 'white', height: 20, width: 20 }} />
                Edit
            </button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
                maxWidth="lg"
                id={id}
            >
                <div className={classes.dialogHeader}>
                    <Typography
                        variant="h5"
                        component="h5"
                        className={classes.dialogTitle}
                    >
                        {title}
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <Clear style={{ height: 30, width: 30 }} />
                    </IconButton>
                </div>
                <DialogContent>
                    <FormGroup>
                        <FormLabel>First Name</FormLabel>
                        <OutlinedInput
                            fullWidth type="text"
                            style={{ border: '1px solid #CBD2D9' }}
                            required
                            value={firstName || ''}
                            classes={{
                                notchedOutline: clsx({
                                    [classes.borderWarning]:
                                        customValidationName === "required",
                                    [classes.borderSuccess]:
                                        customValidationName === "valid",
                                }),
                            }}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                                if (e.target.value === '') {
                                    setCustomValidationName('required')
                                } else {
                                    setCustomValidationName('valid')
                                }
                            }}
                        />
                        {customValidationName === "valid" ? (
                            <FormHelperText
                                component={Box}
                                style={{ color: '#2dce89' }}
                            >
                                Looks good!
                            </FormHelperText>
                        )
                            :
                            (<FormHelperText
                                component={Box}
                                style={{ color: '#fb6340' }}
                            >
                                First Name is required.
                            </FormHelperText>)
                        }
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Last Name</FormLabel>
                        <OutlinedInput
                            fullWidth type="text"
                            style={{ border: '1px solid #CBD2D9' }}
                            required
                            value={surname || ''}
                            classes={{
                                notchedOutline: clsx({
                                    [classes.borderWarning]:
                                        customValidationSurname === "required",
                                    [classes.borderSuccess]:
                                        customValidationSurname === "valid",
                                }),
                            }}
                            onChange={(e) => {
                                setSurname(e.target.value);
                                if (e.target.value == '') {
                                    setCustomValidationSurname('required')
                                } else {
                                    setCustomValidationSurname('valid')
                                }
                            }}
                        />
                        {customValidationSurname === "valid" ? (
                            <FormHelperText
                                component={Box}
                                style={{ color: '#2dce89' }}
                            >
                                Looks good!
                            </FormHelperText>)
                            :
                            (<FormHelperText
                                component={Box}
                                style={{ color: '#fb6340' }}
                            >
                                Last Name is required.
                            </FormHelperText>)
                        }
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Email</FormLabel>
                        <OutlinedInput
                            fullWidth type="email"
                            placeholder="name@example.com"
                            style={{ border: '1px solid #CBD2D9' }}
                            required
                            value={newEmail || ''}
                            classes={{
                                notchedOutline: clsx({
                                    [classes.borderWarning]:
                                        customValidationEmail === "required",
                                    [classes.borderSuccess]:
                                        customValidationEmail === "valid",
                                }),
                            }}
                            onChange={(e) => (setNewEmail(e.target.value), validateEmail(e.target.value))}
                        />
                        {customValidationEmail === "valid" ? (
                            <FormHelperText
                                component={Box}
                                style={{ color: '#2dce89' }}
                            >
                                Looks good!
                            </FormHelperText>)
                            :
                            (<FormHelperText
                                component={Box}
                                style={{ color: '#fb6340' }}
                            >
                                {emailErrorMessage}
                            </FormHelperText>)
                        }
                    </FormGroup>
                </DialogContent>

                <DialogActions>
                    {isLoading ? (
                        'Loading...'
                    ) : (
                        <>
                            <Button
                                disabled={!isValid}
                                variant="contained"
                                style={{
                                    background: "#27AAE1",
                                    borderColor: "#27AAE1",
                                }}
                                onClick={() => (
                                    setOpenSnackbar(true),
                                    mutate(
                                        {
                                            "id": id,
                                            "first_name": firstName,
                                            "last_name": surname,
                                            "email": newEmail,
                                            "school": tenantId
                                        }
                                    ),
                                    handleClose()
                                )}
                            >
                                Update
                            </Button>
                        </>
                    )}

                </DialogActions>
            </Dialog>
        </>
    );
}