import React from 'react';
import clsx from "clsx";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Box } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";


function FrequencyInput({
    editedClassFrequency,
    classes,
    setEditedClassFrequency,
    setCustomValidationFrequency,
    customValidationFrequency
}) {

    return (
        <FormGroup style={{ margin: 5 }}>
            <FormLabel
                style={{
                    fontSize: 14,
                    color: "#52606D",
                    fontFamily: 'Montserrat,sans-serif'
                }}
            >
                Frequency
            </FormLabel>
            <Box sx={{ width: { xs: "260px", sm: "100%" } }}>
                <Select
                    style={{ width: "100%" }}
                    value={editedClassFrequency}
                // class="MuiInput-underline"
                >
                    <MenuItem disabled value=''>
                        Choose Frequency
                    </MenuItem>
                    {[
                        "daily",
                        "weekdays",
                        "weekly",
                        "fortnightly",
                        "monthly",
                    ].map((item, key) => (
                        <MenuItem
                            key={key}
                            value={item}
                            classes={{
                                notchedOutline: clsx({
                                    [classes.borderWarning]:
                                        customValidationFrequency === "required",
                                    [classes.borderSuccess]:
                                        customValidationFrequency === "valid",
                                }),
                            }}
                            onClick={(e) => {
                                setEditedClassFrequency(item);
                                if (e.target.value == null) {
                                    setCustomValidationFrequency("required");
                                } else {
                                    setCustomValidationFrequency("valid");
                                }
                            }}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            {customValidationFrequency === "valid" ? (
                <FormHelperText
                    component={Box}
                    style={{ color: "#2dce89" }}
                >
                    Looks good!
                </FormHelperText>
            ) : (
                <FormHelperText
                    component={Box}
                    style={{ color: "#fb6340" }}
                >
                    Frequency is required.
                </FormHelperText>
            )}
        </FormGroup>
    );
}

export default FrequencyInput;