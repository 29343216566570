import React from "react";
import { Link } from "react-router-dom";
const ItemListComponentLink = ({ data, score }) => {
  return (
    <div>
      <Link to={`/data/student/${data.id}`}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            background: "rgb(255, 255, 255,1)",
            borderRadius: "199px",
            padding: "5px 20px",
            margin: "10px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "85%",
              // justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src={data.icon}
              alt=''
              srcset=''
              style={{
                width: "35px",
                border: "2px solid #05649D",
                background: "rgb(255, 255, 255,1)",
                borderRadius: "100px",
              }}
            />
            <h5
              style={{
                margin: "0 0 0 12px",
                fontSize: "13px",
                fontWeight: "500",
              }}
            >
              {data.full_name}
            </h5>
          </div>
          <p
            style={{
              minWidth: "0%",
              margin: " 0 0 0 0",
              padding: " 0 0 0 0",
              fontSize: "14px",
            }}
          >
            {/* {data.email} */}
          </p>
          <p
            style={{
              width: "10%",
              margin: " 0 0 0 0",
              padding: " 0 20px 0 0",
              textAlign: "start",
            }}
          >
            {/* edit */}
            {/* {score !== undefined ? `${score}` : ""} */}
          </p>
          {/* <p
          style={{
            maxWidth: "5%",

            margin: " 0 0 0 0",
            padding: " 0 0 0 0",
            textAlign: "center",
          }}
        >
          <DeleteIcon />
        </p> */}
        </div>
      </Link>
    </div>
  );
};

export default ItemListComponentLink;
