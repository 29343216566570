import React from "react";

const ActivityPlayerTab = ({
  item,
  currentUrl,
  setCurrentUrl,
  page,
  classModal,
}) => {
  // console.log("item :>> ", item);
  // console.log("item :>> ", item);
  // console.log("item :>> ", item);
  // console.log("item :>> ", item);
  // console.log("item :>> ", item);
  const backgroundColorFuncBtn = () => {
    if (page === "builder" || classModal) {
      if (currentUrl === item.id) {
        return "#05659E";
      } else {
        return "rgba(5, 101, 158,0.5)";
      }
    } else {
      if (currentUrl === item.id) {
        return "#6358A5";
      } else {
        return "rgba(99, 88, 165,0.5)";
      }
    }
  };
  return (
    <button
      style={{
        padding: classModal ? "4px 8px" : "6px 12px",
        fontSize: classModal ? "12px" : "",
        color: "white",
        background: backgroundColorFuncBtn(),
        cursor: "pointer",
        border: "none",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // fontWeight: "500",
        boxShadow: " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        margin: "0 0 0 5px",
      }}
      onClick={() => setCurrentUrl(item.id)}
    >
      {item.type.name}
    </button>
  );
};

export default ActivityPlayerTab;
