import { useLocation } from "react-router-dom";


const ComponentStyles = (theme) => {
  const location = useLocation()

  return (

    {
      collapseRoot: {
        "& $listItemRoot": {
          fontSize: ".8125rem",
          padding: ".65rem 1rem !important",
        },
      },
      listRoot: {
        marginTop: "2rem",
        height: "100%",
      },
      listRootCollapse: {
        padding: "0!important",
      },
      listItemRoot: {
        display: "flex",
        fontSize: ".9rem",
        // color: theme.palette.sidebarLinks.main,
        color: '#BAB9CC',
        padding: ".8125rem 1rem !important",
        borderRadius: ".375rem",
        marginRight: ".5rem",
        marginLeft: ".5rem",
        width: "auto",
        // transition: "all 0.2s ease-in",
        "&:hover": {
          color: '#ffffff',
          backgroundColor: "#808080",
        },
      },
      listItemRootCollapseActive: {
        background: theme.palette.gray[100],

        color: '#BAB9CC',
      },
      listItemRootCollapseIcon: {
        // transition: "all 2s ease-in-out",
      },
      listItemRootCollapseActiveIcon: {
        transform: "rotate(90deg)",
      },
      listItemRootUpgradeToPro: {
        [theme.breakpoints.up("md")]: {
          position: "absolute",
          bottom: "10px",
        },
        "&,&:hover": {
          background: theme.palette.gray[100] + "!important",
          // backgroundColor: "#6358A5 !important",
        },
        "&:before": {
          display: "none",
        },
      },
      listItemSelected: {
        // color: theme.palette.error.light,
        // color: theme.palette.error.light,
        color: '#ffffff',
        // backgroundColor: "#6358A5",
        // transition: "all 12s ease-in-out",
        "&$listItemRoot,&$listItemRoot:hover": {
          backgroundColor: location.pathname.includes('activities') ? "#6358A5" : 'rgb(6, 101, 157)',
          // backgroundColor: "unset",
        },
      },
      listItemIconRoot: {
        minWidth: "2.25rem",
        fontSize: ".9375rem",
        lineHeight: "1.5rem",
        display: "inline-block",
        top: "2px",
      },
      divider: {
        marginBottom: "1rem",
        marginTop: "12rem",
        marginLeft: "1.5rem",
        marginRight: "1.5rem",
        backgroundColor: '#222',
        "&:hover": {
          backgroundColor: '#222'
        }
      },
      title: {
        paddingTop: ".25rem",
        paddingBottom: ".25rem",
        fontSize: ".75rem",
        textTransform: "uppercase",
        letterSpacing: ".04em",
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
        color: theme.palette.success.dark,
        // color: theme.palette.gray[100],
      },
      logoClasses: {
        maxHeight: "3rem",
        maxWidth: "100%",
        verticalAlign: "middle",
        borderStyle: "none",
      },
      logoLinkClasses: {
        fontSize: "1.25rem",
        lineHeight: "inherit",
        whiteSpace: "nowrap",
        textDecoration: "none",
        display: "block",
        textAlign: "center",
      },
      textPrimary: {
        color: theme.palette.primary.main,
        // color: '#EB5757',

      },

      textPrimaryLight: {
        color: theme.palette.primary.light,
      },
      textError: {
        color: theme.palette.error.main,
      },
      textErrorLight: {
        color: theme.palette.error.light,
      },
      textWarning: {
        color: theme.palette.warning.main,
      },
      textWarningLight: {
        color: theme.palette.warning.light,
      },
      textInfo: {
        color: theme.palette.info.main,
      },
      textInfoLight: {
        color: theme.palette.info.light,
      },
      textSuccess: {
        color: theme.palette.success.main,
      },
      textSuccessLight: {
        color: theme.palette.success.light,
      },
      textDefault: {
        // color: '#EB5757',

        color: theme.palette.dark.main,
      },
      menuPaper: {
        width: "calc(100% - 2rem)",
      },
      outlineNone: {
        outline: "none!important",
      },
      drawerDockedMiniActive: {
        width: "62px",
        overflowX: "hidden",
      },
      drawerPaperMiniActive: {
        width: "62px",
        overflowX: "hidden",
      },
    })
};

export default ComponentStyles;
