import React, { useState } from "react";
import useCurriculumApi from "api/curriculum";
import { useQuery } from "react-query";

const SubjectLessonFiles = ({ files, setIframeLessonUrl }) => {
  const [resourceId, setResourceId] = useState(null);
  const { fetchResource } = useCurriculumApi();

  const { isLoading, isError, data, error } = useQuery(
    ["viewResource", resourceId],
    () => (resourceId ? fetchResource(resourceId) : ""),
    {
      refetchOnWindowFocus: false,
    }
  );
  console.log("data && data.data :>> ", data && data.data.url);
  //   if (isLoading) {
  //     return <h1>loading</h1>;
  //   }

  //   if (isError) {
  //     return <h1>error</h1>;
  //   }
  if (resourceId & data?.data) {
    document.cookie = `AuthToken=${data.data.jwt};max-age=604800;domain=educationai.co.uk;path=/`;
    document.cookie = `sas="${data.data.sas}";max-age=604800;domain=educationai.co.uk;path=/`;
  }

  console.log("files :>> ", files);
  return (
    <>
      {files.map((file) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "2px 0",
            cursor: "pointer",
            padding: "5px 0px 5px 20px",
          }}
          onClick={() => {
            setIframeLessonUrl(() => data && data?.data.url);
            setResourceId(file?.id);
          }}
        >
          <img style={{ width: "90px" }} src={file.image} alt="" srcset="" />
          <p style={{ margin: "0 0 0 5px" }}>{file.name}</p>
        </div>
      ))}
    </>
  );
};

export default SubjectLessonFiles;
