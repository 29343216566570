import React from "react";

const DashboardEmptyState = ({ icon, title, text, button, isList = false }) => {
  return (
    <div
      style={{
        padding: "20px 20px",
        border: "0px solid  #05649D",
        borderRadius: "8px",
        boxShadow: "0 5px 15px hsla(0,0%, 0%, .2)",
        background: "rgb(255, 255, 255,0.5)",
        display: "flex",
        height: isList ? "806px" : "440px",
        marginTop: isList ? "50px" : "",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {icon}

      <h3
        className='title'
        style={{
          color: "#7B8794",
          fontSize: "18px",
          fontWeight: 600,
          marginBottom: 5,
          textAlign: "center",
        }}
      >
        {title}
      </h3>

      <p
        className='text'
        style={{
          textAlign: "center",
          maxWidth: 650,
          color: "#9AA5B1",
          fontSize: "16px",
        }}
      >
        {text}
      </p>

      {button}
    </div>
  );
};

export default DashboardEmptyState;
