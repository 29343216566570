import { Avatar, Box, Button, Grid } from "@material-ui/core";
import React from "react";
import componentStyles from "assets/theme/components/cards/sortable/card-light-table-sortable.js";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles(componentStyles);


function EmptyState({
    className,
    height,
    width,
    widthSM,
    widthLG,
    cardHeight,
    title,
    text,
    text2,
    buttonText,
    avatarIcon,
    buttonFunction,
    link,
    buttons,
}) {

    const classes = { ...useStyles() };

    return (
        <Grid container xs={width} sm={widthSM} lg={widthLG} style={{ margin: 'auto', height: height }} className={className}>
            <div
                style={{
                    margin: '20px',
                    boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
                    background: "white",
                    borderRadius: 8,
                    width: '100%',
                    padding: '20px 0px 10px'
                }}
            >
                <Box
                    style={{
                        widht: '100vw',
                        margin: 70,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: cardHeight
                    }}
                >
                    <div>
                        <Avatar
                            classes={{ root: classes.avatarRoot }}
                            alt="..."
                            style={{ width: 80, height: 80, marginBottom: 25 }}
                        >
                            {avatarIcon}
                        </Avatar>
                    </div>
                    <p
                        style={{
                            color: '#7B8794',
                            fontSize: '18px',
                            fontWeight: 600,
                            marginBottom: 5,
                            textAlign: 'center'
                        }}
                    >
                        {title}
                    </p>
                    <p style={{
                        textAlign: 'center',
                        maxWidth: 650,
                        color: '#9AA5B1',
                        fontSize: '16px'
                    }}>
                        {text}
                    </p>
                    <p style={{
                        textAlign: 'center',
                        maxWidth: 650,
                        color: '#9AA5B1',
                        fontSize: '16px'
                    }}>
                        {text2}
                    </p>
                    <div>
                        {link ?
                            (<Link to={link} style={{ textDecoration: 'none' }}>
                                <Button
                                    onClick={buttonFunction}
                                    style={{
                                        color: 'white',
                                        background: '#06659D',
                                        border: '0px solid #06659D',
                                        height: 40,
                                        padding: "0px 10px"
                                    }}
                                >
                                    {buttonText}
                                </Button>
                            </Link>)
                            :
                            (buttonText &&
                                (<Button
                                    onClick={buttonFunction}
                                    style={{
                                        color: 'white',
                                        background: '#06659D',
                                        border: '0px solid #06659D',
                                        height: 40,
                                        padding: "0px 10px"
                                    }}
                                >
                                    {buttonText}
                                </Button>
                                ))}
                        {buttons &&
                            buttons.map((b) => (
                                <Button
                                    onClick={b.action}
                                    style={{
                                        color: "white",
                                        background: "#06659D",
                                        border: "0px solid #06659D",
                                        height: 40,
                                        padding: "10px 10px",
                                        marginLeft: "20px",
                                    }}
                                >
                                    {b.text}
                                </Button>
                            ))}
                    </div>
                </Box>
            </div>
        </Grid>
    );
}

export default EmptyState;
