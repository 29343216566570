import { React, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import componentStyles from "assets/theme/components/cards/charts/card-total-orders.js";
import { Button } from "@material-ui/core";

import GeneralCurriculumCard from "./GeneralCurriculumCard";
import MoreInfoModal from "components/Modals/MoreInfoModal";

const useStyles = makeStyles(componentStyles);

const CurriculumCard = (props) => {
  const [moreInfoOpen, setMoreInfoOpen] = useState(false);
  const secondaryButton = (
    <>
      <MoreInfoModal
        props={props}
        lessonType={props.type}
        open={moreInfoOpen}
        setOpen={setMoreInfoOpen}
        type={props.entityType}
        id={props.id}
        title={props.title}
        subtitle={props.subtitle}
        arrayList={props.arrayList}
        tags={props.tags}
        arrayTitle={props.arrayTitle}
        image={props.image}
        link={props.link}
        lessonList={props.lessonList}
        levelId={props.levelId}
      />
      {props.arrayTitle &&
        <Button
          onClick={() => setMoreInfoOpen(true)}
          variant="outlined"
          size="small"
          color="primary"
        >
          More Info
        </Button>
      }
    </>
  );

  return <GeneralCurriculumCard {...props} secondaryButton={secondaryButton} />;
};

export default CurriculumCard;
