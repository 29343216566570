import React, { useState } from "react";
import SubjectLessonFiles from "./SubjectLessonFiles";

const SubjectLessonComponent = ({ lesson, setIframeLessonUrl, lessonNum }) => {
  const [expandLessons, setExpandLessons] = useState(false);

  return (
    <div>
      <h3
        style={{
          width: "100%",
          padding: "10px 10px 10px 20px",
          background: "#07659a",
          color: "white",
          borderTop: "solid 2px #AFCCE0",
          borderBottom: "solid 2px #AFCCE0",
          cursor: "pointer",
          margin: "0",
        }}
        onClick={() => setExpandLessons((prev) => !prev)}
      >
        Lesson {lessonNum} -{" "}
        {lesson.name
          .replace("Stage 1 - ", "")
          .replace("Stage 2 - ", "")
          .replace("Stage 3 - ", "")}
      </h3>

      <div style={{ display: expandLessons ? "none" : "block" }}>
        <SubjectLessonFiles
          files={lesson.files}
          setIframeLessonUrl={setIframeLessonUrl}
        />
      </div>
    </div>
  );
};

export default SubjectLessonComponent;
