import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
// import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const ActivityPlayerBtn = ({
  quesNum,
  numberOfQuestions,
  btnDisableUntilAnswered,
  setQuesNum,
  setBtnDisableUntilAnswered,
  isLastQuestion,
  refetch,
  isLoading,
  loadingDetails,
  isEndOfQuestions,
  isReviewState,
  setIsReviewState,
  selectQuestionToReview,
  setSelectQuestionToReview,
  setIsEndOfQuestions,
  handleClickAddArray,
}) => {
  return (
    <div
      className=""
      style={{
        width: quesNum === 0 ? "" : quesNum > numberOfQuestions ? "" : "95%",
        display: "flex",
      }}
    >
      {/* {true && quesNum !== 0 ? ( */}
      {isEndOfQuestions && quesNum !== 0 && !btnDisableUntilAnswered ? (
        <>
          {/* <button
            style={{
              padding: "8px 16px",
              color: "white",
              background: quesNum > 1 ? "#6358A5" : "#222",
              opacity: quesNum > 1 ? "1" : "0.2",
              cursor: quesNum > 1 ? "pointer" : "not-allowed",
              border: "none",
              width: "50px",
              borderRadius: "100vw",
              borderTopRightRadius: "0",
              borderBottomRightRadius: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              boxShadow:
                " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            }}
            onClick={() => {
              if (quesNum > 1) {
                setQuesNum((prev) => prev - 1);
              }
            }}
          >
            <ArrowBackIosNewIcon />
          </button>
          <button
            style={{
              padding: "8px 16px",
              color: "white",
              background: quesNum < numberOfQuestions + 1 ? "#6358A5" : "#222",
              opacity: quesNum < numberOfQuestions + 1 ? "1" : "0.2",
              cursor:
                quesNum < numberOfQuestions + 1 ? "pointer" : "not-allowed",
              border: "none",
              width: "50px",
              borderRadius: "100vw",
              borderTopLeftRadius: "0",
              borderBottomLeftRadius: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "600",
              boxShadow:
                " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            }}
            onClick={() => {
              if (quesNum < numberOfQuestions + 1) {
                setQuesNum((prev) => prev + 1);
              }
            }}
          >
            <ArrowForwardIosIcon />
          </button> */}
        </>
      ) : (
        <>
          {!isEndOfQuestions ? (
            <button
              style={{
                padding: "8px 8px",
                color: "white",
                background: btnDisableUntilAnswered ? "#6358A5" : "#222",
                opacity: btnDisableUntilAnswered ? "1" : "0.2",
                cursor: btnDisableUntilAnswered ? "pointer" : "not-allowed",
                border: "none",
                width: "120px",
                margin: "0 3px",
                borderRadius: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "600",
                marginLeft:
                  quesNum === 0 ? "" : quesNum > numberOfQuestions ? "" : "0px",
                boxShadow:
                  " 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              }}
              // disabled={btnDisableUntilAnswered}
              onClick={() => {
                if (btnDisableUntilAnswered && !isLoading && !loadingDetails) {
                  // if (btnDisableUntilAnswered) {
                  // if (!isEndOfQuestions) {
                  if (!isLastQuestion) {
                    setBtnDisableUntilAnswered(false);
                    setQuesNum((prev) => prev + 1);
                  }
                  handleClickAddArray();
                  if (quesNum > 0) {
                    refetch();
                    console.log("Refetch Refetch Refetch Refetch");
                  }

                  // }
                }
              }}
            >
              {isLoading || loadingDetails ? (
                // {true ? (
                <CircularProgress
                  style={{
                    width: "28px",
                    height: "28px",
                    color: "white",
                    margin: "0",
                    padding: "0",
                  }}
                  disableShrink
                />
              ) : quesNum === 0 ? (
                "Start"
              ) : isEndOfQuestions && quesNum > numberOfQuestions ? (
                "Finished"
              ) : (
                "next"
              )}
            </button>
          ) : null}
        </>
      )}
      {/* */}
    </div>
  );
};

export default ActivityPlayerBtn;
