import React from "react";
import { Redirect, Route } from "react-router-dom";
// import ErrorPage from "views/eai/curriculum/ErrorPage";
// import ErrorPage from "views/eai/curriculum/ErrorPage";
import usePersistStore from "../zustandPersist";
const ProtectedRoute = ({ component: Component, ...rest }) => {
  // we capitalized the first letter of component so we could use it as a component to render on line 55
  const { permission } = usePersistStore();


  return (
    <div>
      <Route
        {...rest}
        render={(props) => {
          if (permission && permission.type === "student") {
            return <Redirect to="/activities" />;
            // return <ErrorPage />;
          } else {
            return <Component {...props} />;
          }
        }}
      />
    </div>
  );
};

export default ProtectedRoute;
