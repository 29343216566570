import { Divider, List, ListItem, Typography } from "@material-ui/core";
// import { ListItemButton } from "@mui/material";
import React from "react";
import comp from "../assets/comp.png";
import temp from "../assets/temp.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ListItemButton } from "@mui/material";
import { useHistory } from "react-router-dom";

// import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import useUser from "api/user";
import { useQuery } from "react-query";
// import { LoadingScreen } from "views/eai/curriculum/Components/LoadingScreen";
import { ErrorScreen } from "views/eai/curriculum/Components/ErrorScreen";
import useStore from "../../../../zustand";

import ListComponentSkeleton from "./ListComponentSkeleton";
import ToDoListItemHomePages from "./ToDoListItemHomePages";
// import EmptyState from "components/EmptyState/EmptyState";
import EmptyStateTwo from "./EmptyStateTwo";

const dataM = [
  {
    title: `Character Sets - Stage 4`,
    teacher: "Set by Ms. Samson",
    time: "1 hour exam",
    due: "Due in 2 days",
    image: comp,
  },
  {
    title: `Image - Stage 4`,
    teacher: "Set by Ms. Samson",
    time: "1 hour exam",
    due: "Due in 2 days",
    image: temp,
  },
  {
    title: `Hexidecimal  - Stage 4`,
    teacher: "Set by Ms. Samson",
    time: "1 hour exam",
    due: "Due in 2 days",
    image: comp,
  },
];

const ToDoHomePage = ({ matchesTwo, matchesThree }) => {
  const { selectedClassId } = useStore((state) => state);
  const { fetchActivityTask } = useUser();
  const history = useHistory();

  const { isLoading, isError, data, error } = useQuery(
    ["todoTasks", selectedClassId],
    () => fetchActivityTask(selectedClassId),
    { enabled: selectedClassId != null, refetchOnWindowFocus: false }
  );

  //   const style = {
  //     position: "absolute",
  //     top: "50%",
  //     left: "50%",
  //     transform: "translate(-50%, -50%)",
  //     // width: 4,
  //     bgcolor: "background.paper",
  //     border: "none",
  //     // boxShadow: 24,
  //     // borderRadius:'8px',
  //     // boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  //     // boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
  //     p: 4,
  //     // width:'90vw',
  //   };

  let lengthToDo;
  let lengthArr = 0;
  if (data) {
    // console.log(" todo Home data ==> ", data);
    // console.log(
    //   " todo Home data.data.todo.tasks.length ==> ",
    //   data.data.todo.tasks.length
    // );
    // console.log(
    //   " todo Home data.data.todo.tasks.slice(1, 4) ==> ",
    //   data.data.todo.tasks.slice(1, 4)
    // );
    // console.log(
    //   " todo Home data.data.todo.tasks.slice(0, 4) ==> ",
    //   data.data.todo.tasks.slice(0, 4)
    // );
    if (data.data.todo.tasks.length > 5) {
      lengthToDo = 5;
    } else {
      lengthToDo = data.data.todo.tasks.length;
    }
  }
  if (isLoading) {
    return <ListComponentSkeleton />;
  }
  if (isError) {
    return <ErrorScreen error={error} />;
  }
  if (data && data.data.todo.tasks.length === 0) {
    return (
      <div style={{ width: `${matchesTwo ? "48%" : "100%"}` }}>
        <EmptyStateTwo
          header={"To Do Tasks"}
          title={"To do tasks"}
          height={"565px"}
          isToDo={true}
          text={"Your teacher hasn't set you any task or you have completed all necessary work."}
        />
      </div>
    );
  }
  return (
    <div
      className="activitiesTodo"
      style={{
        width: `${matchesTwo ? "48%" : "100%"}`,
        // padding: "20px 25px 5px",
        // padding: "10px 4px",
      }}
    >
      <Typography
        variant="h3"
        style={{
          color: "#7B8794",
          fontSize: "18px",
          fontWeight: "600",
          marginBottom: "16px",
        }}
      >
        To do List
      </Typography>
      <div
        style={{
          //   width: "50%",
          padding: "12px 0px 0",
          background: "#fff",
          boxShadow: "0 5px 15px hsla(0, 0%, 0%, 0.2)",
          borderRadius: "14px",
          height: "565px",
        }}
      >
        <List
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div>
            {data &&
              data.data.todo.tasks
                .filter((task) => task.exercise.type.toLowerCase() === "h5p" || "blocks")
                .slice(0, 4)
                .map((todo, index) => (
                  <>
                    <ToDoListItemHomePages
                      todo={todo}
                      index={index}
                      lengthToDo={lengthToDo}
                      todoLessonId={todo?.lesson.id ? todo?.lesson?.id : null}
                    />
                    {index < 4 ? <Divider /> : ""}
                  </>
                ))}
          </div>
          <div>
            <Divider />
            <Divider />
            <ListItem disablePadding sx={{ height: "fit-content" }}>
              <ListItemButton
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  // textAlign: 'right',
                  width: "100%",
                  height: "100%",
                  padding: "5px 0px",
                }}
                onClick={() => history.push(`/activities/activities`)}
              >
                <Typography
                  variant="p"
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    padding: "8px 0",
                    color: "#1D6F98",
                  }}
                >
                  View all activity <ArrowForwardIcon pb={0} sx={{ fontSize: "18px" }} />
                </Typography>
                {/* </Box> */}
              </ListItemButton>
            </ListItem>
          </div>
        </List>
      </div>
    </div>
  );
};

export default ToDoHomePage;
