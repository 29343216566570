import React, { useEffect, useState } from "react";
import useCurriculumApi from "api/curriculum";
import { useQuery } from "react-query";
import Iframe from "react-iframe";
import ActivityPlayerTab from "views/eai/activities/ActivityPlayerTab";
// import AddIcon from "@mui/icons-material/Add";
const BuilderCurriculumPlayerView = ({
  lessonResourcesData,
  lessonName,
  learningObjectives,
  addLesson,
  deleteLesson,
  selectedLesson,
  selectedLessons,
  expandLO,
  setExpandLO,
  selectedLessonIdPlayer,
}) => {
  const { fetchResource } = useCurriculumApi();
  const [content, setContent] = React.useState(null);
  const [fileItem, setFileItem] = React.useState(null);
  const [lessonRecource, setLessonRecource] = React.useState(null);
  const [test, setTest] = React.useState(false);
  const [iframeId, setIframId] = React.useState(null);
  const [hide, setHide] = React.useState(true);
  const [urlIframeMain, setUrlIframeMain] = useState("");
  const [urlIframeNew, setUrlIframeNew] = useState({});
  const [currentUrl, setCurrentUrl] = useState(null);

  useEffect(() => {
    if (lessonResourcesData?.length > 0) {
      setUrlIframeMain(lessonResourcesData);
      // console.log("urlIframeMain :>> ", urlIframeMain);
    }
  }, [lessonResourcesData]);

  useEffect(() => {
    if (urlIframeMain) {
      if (currentUrl === null) {
        setCurrentUrl(urlIframeMain[0].id);
      }
    }
  }, [urlIframeMain]);
  // console.log("currentUrl", currentUrl);
  const handleVisibility = () => {
    console.log("selectedLesson :>> ", selectedLesson);
    if (selectedLessons && selectedLessons.filter((l) => l.id === selectedLesson.id).length > 0) {
      deleteLesson(selectedLesson.id);
    } else {
      addLesson({ image: selectedLesson.image, name: selectedLesson.name, id: selectedLesson.id });
    }
  };
  const {
    data: dataFrame,
    status: statusFrame,
    isLoading: isLoadingFrame,
    isError: isErrorFrame,
    error: errorFrame,
  } = useQuery(["viewResource", currentUrl], () => currentUrl && fetchResource(currentUrl), {
    enabled: currentUrl != "",
    refetchOnWindowFocus: false,
  });
  const urlFrame = statusFrame === "success" && dataFrame && dataFrame?.data?.url;

  document.cookie =
    statusFrame == "success" &&
    `AuthToken=${dataFrame?.data?.jwt};max-age=604800;domain=educationai.co.uk;path=/`;
  document.cookie =
    statusFrame == "success" &&
    `sas="${dataFrame?.data?.sas}";max-age=604800;domain=educationai.co.uk;path=/`;
  return (
    <>
      <div
        style={{
          overflow: "hidden",
        }}
      >
        {urlIframeMain?.length > 1 && urlFrame ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <h1 style={{ margin: "0", padding: "0", fontSize: "16px" }}>{lessonName}</h1>

            <div style={{ display: "flex", alignItems: "end" }}>
              {urlIframeMain.map((item) => (
                <ActivityPlayerTab
                  item={item}
                  currentUrl={currentUrl}
                  setCurrentUrl={setCurrentUrl}
                  page={"builder"}
                />
              ))}
            </div>
          </div>
        ) : (
          <h1 style={{ margin: "0", padding: "0", fontSize: "16px" }}>{lessonName}</h1>
        )}
      </div>
      <div style={{}}>
        {isLoadingFrame ? (
          <div
            style={{
              aspectRatio: "2/1",
              width: "480px",
              height: "350px",
              border: "1px solid rgba(0,0,0,0.4)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1 style={{ margin: "0", padding: "0", fontSize: "16px" }}>Loading</h1>
          </div>
        ) : urlFrame ? (
          <div style={{ aspectRatio: "2/1", width: "480px", height: "330px", padding: "0" }}>
            <Iframe
              url={urlFrame}
              width="100%"
              height={learningObjectives?.length > 0 ? "100%" : "100%"}
              allow="fullscreen"
              borderRadius="5px"
              allowtransparency="true"
            />
            {/* <iframe
              src={urlFrame}
              style={{ aspectRatio: "2/1", width: "500px", height: "350px" }}
            /> */}
          </div>
        ) : (
          <div
            style={{
              margin: "auto auto",
              aspectRatio: "2/1",
              width: "480px",
              height: "330px",
              border: "1px solid rgba(0, 0, 0, 0.104)",
              background: "rgba(212, 212, 212, 0.4)",
              padding: "0",
              borderRadius: "2px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1
              style={{
                borderRadius: "12px",
                margin: "0",
              }}
            >
              No lesson available
            </h1>
          </div>
        )}

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              margin: "6px 0",
            }}
          >
            {learningObjectives?.length > 0 ? (
              <h2 style={{ fontSize: "16px" }}>Learning objectives</h2>
            ) : (
              <div></div>
            )}
            <button
              style={{
                background:
                  selectedLessons &&
                  selectedLessons.filter((l) => l.id === selectedLesson.id).length > 0
                    ? "gray"
                    : "#06659D",
                color: "white",
                border: "none",
                cursor: "pointer",
                padding: "6px",
                fontSize: "14px",
                borderRadius: "4px",
                fontWeight: "500",
                // margin: "0 0 0 5px",
                width: "80px",
              }}
              onClick={() => handleVisibility()}
            >
              {selectedLessons &&
              selectedLessons.filter((l) => l.id === selectedLesson.id).length > 0 ? (
                "Remove"
              ) : (
                <>Add</>
              )}
            </button>
          </div>
          {learningObjectives?.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "6px",
                height: "calc(85vh - 440px)",
                overflow: "auto",
              }}
            >
              {learningObjectives &&
                learningObjectives.map((item) => (
                  <div
                    style={{
                      padding: "4px 5px",
                      backgroundColor: "rgba(5, 101, 158, 0.05)",
                      borderRadius: "4px",
                      fontSize: "12px",
                    }}
                  >
                    {item.objective}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BuilderCurriculumPlayerView;
