import React from "react";
import ReactDOM from "react-dom";
//import axios from "axios";

// plugins styles from node_modules
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/plugins/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-material-ui.scss?v1.0.0";
import { App } from "App";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { getMsalConfig, getTenantName, handleHashRedirect } from "auth";
import { fetchTenantId } from "api/user";
import { Box } from "@material-ui/core";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
// console.log("==================================");
// console.log("==================================");
// console.log("==================================");
// If there's a auth redirect incoming, handle that redirect first before doing anything else
handleHashRedirect();

(async () => {
  ReactDOM.render(
    <Box
      style={{
        height: "100vh",
        textAlign: "center",
        paddingTop: "25vh",
        background: "white",
      }}
    >
      <img style={{ width: "150px" }} alt="" src="/loader.gif"></img>
    </Box>,
    document.querySelector("#root")
  );

  const tenantName = getTenantName();
  const tenant = await fetchTenantId(tenantName);

  var msalInstance;

  if (tenant.id) {
    const config = getMsalConfig(tenant.id);

    msalInstance = new PublicClientApplication(config);

    if (msalInstance) {
      ReactDOM.render(
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </BrowserRouter>,
        document.querySelector("#root")
      );
    } else {
      ReactDOM.render(
        <Box
          style={{
            height: "100vh",
            width: "100vw",
            textAlign: "center",
            paddingTop: "25vh",
            background: "white",
          }}
        >
          <img style={{ width: "150px" }} alt="" src="/loader.gif"></img>
        </Box>,
        document.querySelector("#root")
      );
    }
  }
})();
