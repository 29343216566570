import useCurriculumApi from "api/curriculum";
import Iframe from "react-iframe";
import 'assets/css/myStyle.css'
import { useMutation, useQuery } from "react-query";
import Avatar from '@mui/material/Avatar';
import { Box } from "@material-ui/core";
import useStore from "../../zustand";
import { ErrorScreen } from "views/eai/curriculum/Components/ErrorScreen";
import { CircularLoadingScreen } from "views/eai/activities/components/Loading";
// import { useEffect } from "react";
import { LoadingScreen } from "views/eai/curriculum/Components/LoadingScreen";
import { useEffect, useState } from "react";
import CreateActivityForm from "./CreateActivity/CreateActivityForm";
import useUserApi from "api/user";
import NewSnackbar from "components/Snackbar/NewSnackbar";
import { Button, Snackbar, SnackbarContent } from "@mui/material";
import React from "react";
import SaveLesson from "./SaveLesson";
// import CreateActivity from "./CreateActivity/CreateActivity";
import useBuilderApi from "api/builder";



function MoreInfoDescriptionPage({ lessonItem, useEffectDep, lessonType, test }) {
    const { fetchLessonObjectives, fetchResource } = useCurriculumApi();
    const { setLessonLearningObjectives } = useStore()
    const [iframeID, setIframID] = useState(null)
    const [activity, setActivity] = useState(false)
    const { saveLevel, updateLevel } = useBuilderApi();

    const { addQuiz } = useUserApi();

    useEffect(() => {
        lessonType !== 'offline' ? setIframID(lessonItem?.files[0]?.id) : setIframID(lessonItem.id)
    }, [useEffectDep])

    useEffect(() => {
        setActivity(false)
    }, [test])


    const { isLoading, isError, data, error, isSuccess } = useQuery(
        ["viewResource", iframeID],
        () => fetchResource(iframeID)
    );

    const { isLoading: isLoadingLO, isError: isErrorLO, data: learningObjectives, status, isSuccess: isSuccessLO } = useQuery(
        ["lessonLearningObjectives", lessonItem?.id],
        () => fetchLessonObjectives(lessonItem?.id)
    );

    // Create new Quiz
    const { mutate: mutateQuiz, isLoading: isLoadingQuiz, isSuccess: isSuccessQuiz, isError: isErrorQuiz, error: errorQuiz, status: statusQuiz } = useMutation(
        (quiz) => addQuiz(quiz)
    );


    //Add to lesson to lesson plan
    const { mutate: mutateAddLesson, isSuccess: isSuccessAddLesson, isLoading: isLoadingAddLesson, isError: isErrorAddLesson } = useMutation(['savelevel'],
        (body) => saveLevel(body)
    )

    // Update Cutsom Level 
    const { mutate: mutateUpdCusLev, isSuccess: isSuccessUpdCusLev, isLoading: isLoadingUpdCusLev, isError: isErrorUpdCusErr } = useMutation(
        (updatedCustomLevel) => updateLevel(updatedCustomLevel)
    )

    // Snackbar disappear
    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setOpenSnackbar(false);
        }, 3000);

        return () => {
            clearTimeout(timeout);
        };
    }, [openSnackbar]);


    if (isErrorLO) {
        return <ErrorScreen error={error} />;
    }
    if (isLoadingLO || isLoadingQuiz) {
        return <LoadingScreen />;
    }

    const compConceptArray = []
    const learnObjArray = []
    learningObjectives.data.map(item => {
        compConceptArray.push(item.computational_concept)
        learnObjArray.push(item.id)
    })


    document.cookie = isSuccess && `AuthToken=${data?.data?.jwt};max-age=604800;domain=educationai.co.uk;path=/`;
    document.cookie = isSuccess && `sas="${data?.data?.sas}";max-age=604800;domain=educationai.co.uk;path=/`;

    return (
        <Box sx={{
            width: '95%', margin: '20px auto 100px', background: 'white', height: 'calc(90vh - 170px)', borderRadius: '0px', overflowY: 'scroll'
        }}>
            <NewSnackbar
                isLoading={isLoadingQuiz}
                isSuccess={isSuccessQuiz}
                isError={isErrorQuiz}
                Snackbar={Snackbar}
                SnackbarContent={SnackbarContent}
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                message='The activity has been successfully added.'
            />
            <NewSnackbar
                isLoading={isLoadingAddLesson}
                isSuccess={isSuccessAddLesson}
                isError={isErrorAddLesson}
                Snackbar={Snackbar}
                SnackbarContent={SnackbarContent}
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                message='The lesson has been successfully added to the lesson plan.'
            />
            <NewSnackbar
                isLoading={isLoadingUpdCusLev}
                isSuccess={isSuccessUpdCusLev}
                isError={isErrorUpdCusErr}
                Snackbar={Snackbar}
                SnackbarContent={SnackbarContent}
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                message='The lesson has been successfully added to the lesson plan.'
            />

            <Box style={{ padding: '10px 0px', height: '450px', margin: 0, width: '100%', }}>
                {isLoading ?
                    <div style={{ height: '100%', width: '100%', background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularLoadingScreen />
                    </div>
                    : isError ?
                        <ErrorScreen error={error} />
                        :
                        <Iframe
                            url={data?.data?.url}
                            width="100%"
                            height="100%"
                            allow="fullscreen"
                        />
                }
            </Box>

            <Box style={{
                padding: '15px', width: '100%', maxHeight: '200px', margin: 0, background: '#F7F7F7', display: 'flex', borderRadius: 6
            }}>
                {lessonType !== 'offline' ? lessonItem.files.map((file) => (
                    <Avatar
                        key={file.id}
                        alt="Remy Sharp"
                        style={{ width: 176, height: 100, borderRadius: 10, marginRight: '20px', transform: iframeID === file.id ? 'scale(1.05)' : 'scale(1)', boxShadow: iframeID === file.id ? 'rgba(30, 81, 123, 0.59) 0px 10px 20px, rgba(30, 81, 123, 0.63) 0px 6px 6px' : 'rgba(17, 17, 26, 0.1) 0px 1px 0px' }}
                        onClick={() => (setIframID(file.id))}
                    >
                        <img class="image-hover" src={file.image} alt="icon" style={{ background: 'white', width: '100%', height: '100%', objectFit: 'contain', opacity: iframeID === file.id ? 1 : 0.6, borderRadius: 10, cursor: 'pointer' }} />
                    </Avatar>))
                    :
                    <Avatar
                        key={lessonItem.id}
                        alt="Remy Sharp"
                        style={{ width: 176, height: 100, borderRadius: 10, marginRight: '20px', transform: iframeID === lessonItem.id ? 'scale(1.05)' : 'scale(1)', boxShadow: iframeID === lessonItem.id ? 'rgba(30, 81, 123, 0.59) 0px 10px 20px, rgba(30, 81, 123, 0.63) 0px 6px 6px' : 'rgba(17, 17, 26, 0.1) 0px 1px 0px' }}
                        onClick={() => (setIframID(lessonItem.id))}
                    >
                        <img class="image-hover" src={lessonItem.image} alt="icon" style={{ background: 'white', width: '100%', height: '100%', objectFit: 'contain', opacity: iframeID === lessonItem.id ? 1 : 0.6, borderRadius: 10, cursor: 'pointer' }} />
                    </Avatar>
                }
            </Box>
            <Box style={{ padding: '10px 0px', margin: '10px 0px', maxHeight: '200px', maxWidth: 'fit-content' }}>
                {/* <h5 id="button-hover">Description</h5>
                <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut ratione accusamus earum saepe reiciendis adipisci asperiores id illum, repellat aliquid, eligendi fugiat laborum repellendus et libero illo a voluptatum voluptatem!</p> */}
            </Box>
            {activity ?
                <CreateActivityForm
                    hide={() => setActivity(false)}
                    mutate={mutateQuiz}
                    isLoading={isLoadingQuiz}
                    isSuccess={isSuccessQuiz}
                    isError={isErrorQuiz}
                    error={errorQuiz}
                    status={statusQuiz}
                    setOpenSnackbarQuiz={setOpenSnackbar}
                />
                :
                <>
                    <Box style={{ padding: '10px 0px', maxHeight: '200px', margin: 0, minHeight: '200px', maxWidth: 'none', marginBottom: 40 }}>
                        <h5>Learning Objectives</h5>
                        <Box style={{ maxHeight: '200px', overflow: 'scroll' }}>
                            {status == 'success' && learningObjectives.data.map(obj => (
                                <p>{obj.objective}</p>
                            ))}
                        </Box>
                    </Box>

                    <Box style={{ padding: '10px 0px', maxHeight: '200px', margin: 0, maxWidth: 'none', marginBottom: 10, display: 'flex' }}>
                        <Button
                            class="button-css"
                            onClick={() => (setActivity(!activity), setLessonLearningObjectives({ toForm: true, compConceptArray: compConceptArray, learnObjArray: learnObjArray }))}
                            style={{
                                textTransform: 'uppercase', fontFamily: 'Open Sans, sans-serif', height: 40, border: '1px solid rgb(6, 101, 157)',
                                color: 'rgb(6, 101, 157)', width: 170, fontSize: '.875rem', borderRadius: 6, background: 'white', margin: '10px 20px 10px 0px', fontWeight: 600,
                            }}
                        >
                            Assign as Activity
                        </Button>
                        <SaveLesson
                            lessonId={lessonItem?.id}
                            lessonImage={lessonItem?.image}
                            lessonName={lessonItem?.name}
                            mutateAddLesson={mutateAddLesson}
                            isSuccessAddLesson={isSuccessAddLesson}
                            mutateUpdate={mutateUpdCusLev}
                            isSuccessUpdate={isSuccessUpdCusLev}
                            setOpenSnackbar={setOpenSnackbar}
                        />
                    </Box>
                </>
            }
        </Box>
    );
}

export default MoreInfoDescriptionPage;

