import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import StudentStatusCard from "./StudentStatusCard";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import useUser from "api/user";

import Temp from "../assets/temp.png";
// import { relative } from 'path';
import Castle from "../assets/castle.png";
import Award from "../assets/award.png";
import { useQuery } from "react-query";
import useStore from "../../../../zustand";
// import { LoadingScreen } from "views/eai/curriculum/Components/LoadingScreen";
import { ErrorScreen } from "views/eai/curriculum/Components/ErrorScreen";
import HomeStatusLoadingCard from "./HomeStatusLoadingCard";
const theme = createTheme();
theme.typography.h1 = {
  fontSize: "32px",
  "@media (max-width:1000px)": {
    fontSize: "32px",
  },
  "@media (max-width:800px)": {
    fontSize: "28px",
  },
  "@media (max-width:660px)": {
    fontSize: "22px",
  },
};
const HomeStatusCards = () => {
  const matches = useMediaQuery("(min-width:1240px)");
  const { selectedClassId } = useStore((state) => state);
  //   const classes = { ...useStyles(), ...useStylesCardDeck() };
  const { fetchActivityTask } = useUser();
  const { isLoading, isError, data, error } = useQuery(
    ["todoTasks", selectedClassId],
    () => fetchActivityTask(selectedClassId),
    { enabled: selectedClassId != null, refetchOnWindowFocus: false }
  );

  if (isLoading) {
    return <HomeStatusLoadingCard />;
  }
  if (isError) {
    return <ErrorScreen error={error} />;
  }
  return (
    <ThemeProvider theme={theme}>
      <Stack
        direction={matches ? "row" : "column"}
        justifyContent="space-between"
        mt={8}
        mb={6}
      >
        <StudentStatusCard
          title={"Incomplete tasks"}
          stage={data ? data.data.todo.count : 0}
          completion={` tasks to complete`}
          currentTask={data ? data.data.todo.count : 0}
          bgC={"#E7E3F9"}
          image={Temp}
          matches={matches}
        />
        <StudentStatusCard
          title={"Tasks completed"}
          stage={data ? data.data.completed.count : 0}
          completion={` tasks completed`}
          currentTask={data ? data.data.completed.count : 0}
          bgC={"#FEF3E2"}
          image={Castle}
          matches={matches}
        />
        <StudentStatusCard
          title={"Awards earned"}
          stage={""}
          completion={"coming soon"}
          currentTask={"coming soon"}
          image={Award}
          bgC={"#E0F2E3"}
          matches={matches}
        />
      </Stack>
    </ThemeProvider>
  );
};

export default HomeStatusCards;
