import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
// import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
// import CloseIcon from "@mui/icons-material/Close";
const BuilderBasketLessonItem = ({
  selected,
  lesson,
  index,
  item,
  provided,
  snapshot,
  deleteLesson,
  addLesson,
  id,
  expand,
}) => {
  const renameLessonName = (name) => {
    return name
      ?.replace("Stage 1", "")
      ?.replace("Stage 2", "")
      ?.replace("-", "")
      ?.replace("Stage 3", "");
  };
  const handleVisibility = (idVal) => {
    // handleBadgeVisibility();

    deleteLesson(idVal);
  };

  return (
    <div
      style={{
        width: "270px",
        borderRadius: "4px",
        // backgroundColor: "#F3F7FA",
        // overflow: "hidden",
        boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
      }}
    >
      <div
        style={{
          backgroundColor: "#F3F7FA",
          position: "relative",
          // padding: "4px",
        }}
      >
        <div
          style={{
            padding: "0px 0 0 0px",
            fontSize: "12px",
            margin: "0",
            position: "absolute",
            backgroundColor: "rgba(6, 101, 157,1)",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "20px",
            height: "20px",
            borderRadius: "50px",
            top: "0",
            left: "0",
            transform: "translate(-50%,-50%)",
          }}
        >
          {index}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ width: "60px" }}>
            <img src={lesson.image} alt="" style={{ width: "60px" }} />
          </div>
          <div
            style={{
              width: "calc(100% - 64px)",
              display: "flex",
              alignItems: "center",
              padding: "4px 0 ",
            }}
          >
            <div
              style={{
                padding: "0px 0 0 5px",
                fontSize: "12px",
                margin: "0",
              }}
            >
              {renameLessonName(lesson.name).length > 24
                ? `${renameLessonName(lesson.name).slice(0, 24)}...`
                : renameLessonName(lesson.name)}
              {/* {renameLessonName(lesson.name)} */}
            </div>
          </div>
          <DeleteIcon
            style={{
              fontSize: "18px",
              margin: "0 6px 0 0",
              color: "#b3b3b3",
              padding: "0",
              cursor: "pointer",
            }}
            onClick={() => handleVisibility(id)}
          />
        </div>
      </div>

      {/* <div
          style={{
            boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
            borderRadius: "4px",
            // overflow: "hidden",
            background: "rgb(255, 255, 255)",
            paddingRight: "4px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <div
              style={{
                background: "rgba(6, 101, 157,0.9)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "8px",
                height: "100%",
              }}
            >
              <h4
                style={{
                  color: "white",
                  margin: "0",
                  padding: "0 4px 0 4px",
                  // fontSize: "12px",
                }}
              >
                {index}
              </h4>
            </div>
            <div style={{ width: "80px" }}>
              <img src={lesson.image} alt="" style={{ width: "100%", height: "25%" }} />
            </div>
            <h4
              style={{
                margin: "0 auto 0 12px",
                // textAlign: "center",
                // padding: "8px 0",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {renameLessonName(lesson.name)?.length > 16
                ? `${renameLessonName(lesson.name).slice(0, 16)}...`
                : renameLessonName(lesson.name)}
            </h4>
          </div>
          <CloseIcon
            style={{
              fontSize: "16px",
              color: "#000000",
              margin: "0 5px ",
              padding: "0",
              cursor: "pointer",
              // position: "absolute",
              // top: "0",
              // right: "0",
            }}
            onClick={() => handleVisibility(id)}
          />
          </div> 
          */}
    </div>
  );
};

export default BuilderBasketLessonItem;
