import React from "react";
// const styleCircle = ;
const ClassPerformance = ({ classPerformance, gridView }) => {
  return (
    <div
      style={{
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "space-between",
        display: "grid",
        gridTemplateColumns: gridView ? "1fr " : "1fr 1fr 1fr ",
        gap: gridView ? "8px" : "10px",
        margin: "20px 0",
      }}
    >
      <div
        style={{
          padding: gridView ? "8px" : "10px",
          border: "1.5px solid #E4E7EB",
          borderRadius: "12px",
          display: "flex",
          flexDirection: gridView ? "row" : "column",
          alignItems: "center",
          justifyContent: gridView ? "start" : "space-around",
          //   boxShadow: " 0 4px 6px hsla(0,0%, 0%, .2)",
          //   boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgb(238, 194, 124)",
            width: gridView ? "35px" : "50px",
            height: gridView ? "35px" : "50px",
            borderRadius: gridView ? "50%" : "50%",
            color: "black",
            fontSize: gridView ? "15px" : "16px",
            fontWeight: "600",
            // marginRight: gridView ? "8px" : "",
          }}
        >
          {classPerformance.worst}
        </div>
        <h6
          style={{
            margin: gridView ? "3px 0 0 10px" : "5px 0 0 0",
            fontSize: "14px",
          }}
        >
          Help
        </h6>
      </div>
      <div
        style={{
          padding: gridView ? "8px" : "10px",
          border: "1.5px solid #E4E7EB",
          borderRadius: "12px",
          display: "flex",
          flexDirection: gridView ? "row" : "column",
          alignItems: "center",
          justifyContent: gridView ? "start" : "space-around",
          //   boxShadow: " 0 4px 6px hsla(0,0%, 0%, .2)",
          //   boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#7Fcdee",
            width: gridView ? "35px" : "50px",
            height: gridView ? "35px" : "50px",
            borderRadius: gridView ? "50%" : "50%",
            color: "black",
            fontSize: gridView ? "15px" : "16px",
            fontWeight: "600",
          }}
        >
          {classPerformance.middle}
        </div>
        <h6
          style={{
            margin: gridView ? "3px 0 0 10px" : "5px 0 0 0",
            fontSize: "14px",
          }}
        >
          Middle
        </h6>
      </div>
      <div
        style={{
          padding: gridView ? "8px" : "10px",
          border: "1.5px solid #E4E7EB",
          borderRadius: "12px",
          display: "flex",
          flexDirection: gridView ? "row" : "column",
          alignItems: "center",
          justifyContent: gridView ? "start" : "space-around",
          //   boxShadow: " 0 4px 6px hsla(0,0%, 0%, .2)",
          //   boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgb(138, 203, 95)",
            width: gridView ? "35px" : "50px",
            height: gridView ? "35px" : "50px",
            borderRadius: gridView ? "50%" : "50%",
            color: "black",
            fontSize: gridView ? "15px" : "16px",
            fontWeight: "600",
          }}
        >
          {classPerformance.best}
        </div>
        <h6
          style={{
            margin: gridView ? "3px 0 0 10px" : "5px 0 0 0",
            fontSize: "14px",
          }}
        >
          Challenge
        </h6>
      </div>
    </div>
  );
};

export default ClassPerformance;
