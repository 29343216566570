import { Box, Grid } from "@material-ui/core";
import VerticalToggleButtons from "../ColumnPage/TogglePage";
import useStore from '../../../../zustand.js'
import { useLocation } from "react-router-dom";


export const CurriculumGrid = ({ sections }) => {
  const { view, setView } = useStore()
  const location = useLocation()

  return (
    < Grid direction="column" container >
      {(!location.pathname.includes('resources')) &&
        <Box style={{ position: 'absolute', top: 105, right: 50, width: 100, height: 30, zIndex: 9999999 }}>
          <VerticalToggleButtons view={view} setView={setView} />
        </Box>
      }
      {sections}
    </Grid >
  );
};
