import CloseIcon from "@mui/icons-material/Close";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";

const CustomLessonList = ({ levels, mutate }) => {
  const handleDelete = (id) => {
    const newLevelList = levels.lessons.filter((item) => item.id !== id);
    mutate(levels?.id, {
      name: levels?.name,
      type: "online",
      author: levels?.author,
      custom_lessons: [
        ...newLevelList.map((item, index) => ({ lesson: item?.id, position: index })),
      ],
    });
  };

  return (
    <div
      style={{
        maxHeight: "500px",
        overflowY: "auto",
        paddingLeft: 0,
      }}
    >
      {levels.lessons.length > 0 ? (
        levels.lessons.map((item) => (
          <Card style={{ width: "300px", margin: 7 }}>
            <CardActionArea
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <CardContent style={{ display: "flex", alignItems: "center", padding: 10 }}>
                <CardMedia
                  component="img"
                  image={item.image}
                  alt="lesson image"
                  style={{ width: 70, marginLeft: 10 }}
                />
                <Typography
                  gutterBottom
                  variant="h7"
                  component="div"
                  style={{ padding: "0px 10px", margin: 0 }}
                >
                  {item.name}
                </Typography>
              </CardContent>

              <IconButton onClick={() => handleDelete(item.id)} style={{ marginRight: 6 }}>
                <CloseIcon style={{ color: "grey", width: 25, height: 25 }} />
              </IconButton>
            </CardActionArea>
          </Card>
        ))
      ) : (
        <Card style={{ width: "300px", margin: 7 }}>
          <CardActionArea style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h7"
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                  fontSize: 14,
                }}
              >
                <IndeterminateCheckBoxIcon
                  style={{ color: "grey", width: 25, height: 25, marginRight: 12 }}
                />{" "}
                You have no lesson in this Lesson Plan
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
    </div>
  );
};

export default CustomLessonList;
