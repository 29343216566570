import React, { useState } from "react";
// import LearningObjItem from "../DataSiteClassPage/LearningObjItem";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ArrowDropUpSharpIcon from "@mui/icons-material/ArrowDropUpSharp";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import useDataApi from "api/data";
import { useQuery } from "react-query";
import DoneIcon from "@mui/icons-material/Done";
import EmptyState from "components/EmptyState/EmptyState";
import LearningObjItem from "../DataSiteClassPage/LearningObjItem";

const StudentLearningObj = ({ params }) => {
  const [ascendSort, setAscendSort] = useState(false);

  const { fetchStudentLearningObjectives } = useDataApi();

  const { data: learningObjectives, status: learningObjectivesStatus } =
    useQuery(
      ["student_learning_objectives", params.studentId],
      () => fetchStudentLearningObjectives(params.studentId),
      {
        refetchOnWindowFocus: false,
      }
    );

  // Loading state
  if (learningObjectivesStatus === "loading") {
    return (
      <div
        style={{
          boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
          background: "white",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          margin: "20px 0px 20px 20px",
          //   height: 370,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#05649D",
            color: "white",
            //   borderRadius: "199px",
            overflow: "hidden",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            padding: "10px",
            //   margin: "10px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "40%",
              // justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                margin: "0 0 0 12px",
                color: "white",
                fontSize: "14px",
              }}
            >
              Learning Objectives
            </h5>
          </div>
        </div>
        <div
          style={{
            margin: "20px",
            fontSize: "22px",
            height: "325px",
            overflow: "auto",
          }}
        >
          <Skeleton count={7} height={30} />
        </div>
      </div>
    );
  }

  if (
    learningObjectivesStatus === "success" &&
    learningObjectives.data.learning_objectives.length === 0
  ) {
    return (
      <EmptyState
        className="studentLearningObjectve"
        width={12}
        widthLG={12}
        avatarIcon={<DoneIcon style={{ width: 40, height: 40 }} />}
        title="Learning Objectives"
        text="View student’s progress on learning objectives"
        marginTop="30px 0px 5px 20px"
      />
    );
  }

  // Learing objectives
  return (
    <div
      style={{
        margin: "20px 0px 20px 20px",
        boxShadow: "0 1px 3px hsla(0,0%, 0%, .2)",
        background: "white",
        borderRadius: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#05649D",
          color: "white",
          //   borderRadius: "199px",
          overflow: "hidden",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          padding: "10px",
          //   margin: "10px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "70%",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5
            style={{
              margin: "0 0 0 12px",
              color: "white",
              fontSize: "16px",
            }}
          >
            Learning Objectives
          </h5>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => setAscendSort((prev) => !prev)}
          >
            <h5
              style={{
                margin: "0 2px 0 12px",
                color: "white",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              Score
            </h5>

            {ascendSort ? <ArrowDropDownSharpIcon /> : <ArrowDropUpSharpIcon />}
            {/* {ascendSort ? <FileDownloadSharpIcon /> : <FileUploadSharpIcon />} */}
          </div>
        </div>
      </div>
      <div
        style={{
          maxHeight: "325px",
          overflow: "auto",
        }}
      >
        {learningObjectives?.data?.learning_objectives
          .sort((a, b) => (ascendSort ? a.score - b.score : b.score - a.score))
          .map((item) => (
            <div>
              <LearningObjItem
                learningObj={item.learning_objective}
                score={item.score * 100}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default StudentLearningObj;
